<ejs-dialog
		#modal
		cssClass="modal-sm"
		(close)="closeDialog()"
		[attr.data-test-id]="'addSubcategoryModal'">
	<ng-template #header>
		<div class="dlg-template">{{editMode ? "Edit" : "Add"}} Subcategory</div>
	</ng-template>
	<ng-template #content>
		<form *ngIf="formGroup" class="form-horizontal" [formGroup]="formGroup" (ngSubmit)="submit()" #ngForm="ngForm">
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && ngForm.submitted"
					[form]="formGroup"
					[requestObj]="category">
			</gandalf-lib-validation-messages>
			<div class="form-group" [attr.data-test-id]="'addSubcategoryModalNameFormGroup'">
				<label class="col-sm-4 control-label required">Name</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="name"/>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'addSubcategoryModalDescriptionFormGroup'">
				<label class="col-sm-4 control-label">Description</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="description"/>
				</div>
			</div>
			<div class="form-group">
				<div class="col-sm-8 col-sm-offset-4">
					<ejs-checkbox label="Active" formControlName="active"></ejs-checkbox>
				</div>
			</div>

		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeDialog()" [attr.data-test-id]="'addSubcategoryModalCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" type="submit" (click)="submitForm($event)" [attr.data-test-id]="'addSubcategoryModalSubmitButton'">OK</button>
	</ng-template>
</ejs-dialog>
