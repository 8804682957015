import { Injectable, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { TypeSafeModalManagerService } from 'morgana';
import { filter } from 'rxjs/operators';
import { ManageUpdatesSubscriptionService } from '../header/services/manage-updates-subscription.service';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagToastService {
	constructor(
		private toasterService: ToasterService,
		private featureFlagService: FeatureFlagService,
		public authenticationService: AuthenticationService,
		private manageUpdatesSubscriptionService: ManageUpdatesSubscriptionService,
		private router: Router,
		private zone: NgZone,
	) {
	}

	subscribe(typeSafeModalManagerService: TypeSafeModalManagerService) {
		this.onLogin(typeSafeModalManagerService);
		this.authenticationService.loggedOut.subscribe(() => {
			this.clearToasts();
		});
	}

	onLogin(typeSafeModalManagerService: TypeSafeModalManagerService) {
		this.featureFlagService.getNewFeatureFlagCount().subscribe((data) => {
			const numFlags = data.featureFlagCount;
			if (numFlags > 0) {
				this.zone.run(() => {
					this.toasterService.showDefaultNotificationToast({
						title: `${numFlags} New Update(s) Available`,
						buttons: [
							{
								model: {
									content: 'More Info',
									cssClass: 'e-link',
								},
								click: () => {
									this.openModal(typeSafeModalManagerService);
								},
							},
						],
					});
				});
			}

			this.router.events.pipe(filter(
				event => event instanceof NavigationStart))
				.subscribe((event: NavigationStart) => {
					if (event.url.indexOf('home') === -1) {
						this.clearToasts();
					}
				});
		});
	}

	openModal(typeSafeModalManagerService: TypeSafeModalManagerService) {
		this.manageUpdatesSubscriptionService.openManageUpdateModal(typeSafeModalManagerService);
	}

	clearToasts() {
		this.toasterService.hideDefaultNotificationToast();
	}
}
