<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-md"
	[attr.data-test-id]="'askMeaningfulUseModal'">
	<ng-template #header>
		<div class="dlg-template">Ask about MIPS</div>
	</ng-template>
	<ng-template #content>
		<form [formGroup]="formGroup" #templateForm="ngForm" (ngSubmit)="submit()">
			<div class="alert alert-info">
				Ask our team of experts about the Merit-based Incentive Payment System (MIPS). They will provide assistance for any question you ask below.
			</div>
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && templateForm.submitted"
					[form]="formGroup"
					[requestObj]="request">
			</gandalf-lib-validation-messages>
			<div class="form-group" [attr.data-test-id]="'questionFormGroup'">
				<label class="required">Question</label>
				<textarea formControlName="question" class="e-input" rows="10"></textarea>
			</div>
			<div class="form-group" [attr.data-test-id]="'emailAddressFormGroup'">
				<label class="required">Your Email Address</label>
				<input formControlName="emailAddress" class="e-input"/>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<rev-button buttonIcon="times" buttonLabel="Cancel" (buttonClick)="closeModal()" dataTestId="askMeaningfulUseCancelButton"></rev-button>
		<rev-button buttonType="submit" buttonStyle="primary" buttonLabel="Submit" (buttonClick)="submitForm($event)" dataTestId="askMeaningfulUseSubmitButton"></rev-button>
	</ng-template>
</ejs-dialog>
