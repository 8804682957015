import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig, OptionItem } from 'morgana';
import { AddDiscountRequest } from '@gandalf/model/add-discount-request';
import { BaseComponent } from '@shared/component/base.component';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-add-discount-modal',
	templateUrl: './add-discount-modal.component.html',
	styles: [],
})
export class AddDiscountModalComponent extends BaseComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;
	@ViewChild('templateForm')
	templateForm: NgForm;
	componentForm: UntypedFormGroup;
	discountReasons: OptionItem[];
	request: AddDiscountRequest;
	invoiceId: number;
	isSaving = false;

	constructor(
		public ref: DynamicModalRef,
		public accountingService: AccountingService,
		public modalConfig: ModalConfig,
		public invoiceService: InvoiceService,
		public gandalfFormBuilder: GandalfFormBuilder,
	) {
		super();
	}

	ngOnInit() {
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.prepareInitialRequest();
		this.buildForm();
		this.requestData();
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	prepareInitialRequest() {
		this.request = new AddDiscountRequest();
		this.request.invoiceId = this.invoiceId;
	}

	populateDescriptionField(data: any) {
		this.componentForm.get('description').setValue(data.itemData.label);
	}

	buildForm() {
		this.componentForm = this.gandalfFormBuilder.group(this.request);
	}

	requestData() {
		this.componentForm.disable();
		this.findDiscountReasonsForDropdown();
	}

	findDiscountReasonsForDropdown() {
		this.accountingService.findDiscountReasonsForDropdown().subscribe(data => {
			this.discountReasons = data;
			this.componentForm.enable();
		});
	}

	addDiscount() {
		if (this.componentForm.invalid || this.isSaving) {
			return;
		}

		const request = this.componentForm.value as AddDiscountRequest;
		this.isSaving = true;
		this.accountingService.addDiscount(request).subscribe(_data => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.isSaving = false;
			this.closeModal();
		});
	}

	/* istanbul ignore next */
	submitForm($event: MouseEvent) {
		// eslint-disable-next-line  import/no-deprecated
		this.templateForm.onSubmit($event);
	}
}
