<h4 class="section-title bg-default mrgn-btm">Recommendations</h4>
<div *ngIf="eyeglassPrescription; else noData">
	<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsMaterialFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">Material</label>
		<div class="col-sm-7">
			<p class="margin-0">{{ eyeglassPrescription.material?.value}}
				<span
					*ngIf="eyeglassPrescription.materialComment"> - {{eyeglassPrescription.materialComment}}</span>
			</p>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsTintFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">Tint</label>
		<div class="col-sm-7">
			<p class="margin-0">{{ eyeglassPrescription.tint?.value}}
				<span *ngIf="eyeglassPrescription.tintComment"> - {{eyeglassPrescription.tintComment}}</span>
			</p>
		</div>
	</div>
	<div  class="form-group" [attr.data-test-id]="'eyeglassRecommendationsAntiReflectiveFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">AR Coating</label>
		<div class="col-sm-7">
			<p class="margin-0" *ngIf="eyeglassPrescription.arCoating" >Yes
				<span *ngIf="eyeglassPrescription.arCoatingComment"> - {{eyeglassPrescription.arCoatingComment}}</span>
			</p>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsPhotochromicFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">Photochromic</label>
		<div class="col-sm-7">
			<p class="margin-0" *ngIf="eyeglassPrescription.photochromic">Yes
				<span
					*ngIf="eyeglassPrescription.photochromicComment"> - {{eyeglassPrescription.photochromicComment}}</span>
			</p>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsUltraVioletFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">UV Treatment</label>
		<div class="col-sm-7">
			<p class="margin-0" *ngIf="eyeglassPrescription.uvTreatment">Yes
				<span
					*ngIf="eyeglassPrescription.uvTreatmentComment"> - {{eyeglassPrescription.uvTreatmentComment}}</span>
			</p>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsPolarizedFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">Polarized</label>
		<div class="col-sm-7">
			<p class="margin-0"  *ngIf="eyeglassPrescription.polarized">Yes
				<span *ngIf="eyeglassPrescription.polarizedComment"> - {{eyeglassPrescription.polarizedComment}}</span>
			</p>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'eyeglassRecommendationsLensTypeFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">Lens Type</label>
		<div class="col-sm-7">
			<p class="margin-0">{{ eyeglassPrescription.lensType?.value}}
				<span *ngIf="eyeglassPrescription.lensTypeComment"> - {{eyeglassPrescription.lensTypeComment}}</span>
			</p>
		</div>
	</div>
</div>
<ng-template #noData>No Data</ng-template>
