import { Component, OnInit } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { TypeSafeModalManagerService } from 'morgana';
import { PracticeLocation, SecurityManagerService } from '@core/security-manager/security-manager.service';
import { NavigationService } from '@core/navigation/navigation.service';
import { StateManagementService } from '@core/state-management/state-management.service';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { THROTTLE_REFRESH_TIME } from '@shared/constants/filter.constants';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { EVENT_MANAGER_CONSTANTS } from '@core/events-manager/events-manager.constants';
import { NavigationEnd, Router } from '@angular/router';
import { Affiliation, ResourceAuthority } from '@gandalf/constants';
import { HEADER_TAB_VALUES, HEADER_TABS } from './headerTabs.constants';
import { ManageUpdatesModalComponent } from './manage-updates-modal/manage-updates-modal.component';
import { NavigationMenuService } from './services/navigation-menu.service';
import { EditProfileModalComponent } from './edit-profile-modal/edit-profile-modal.component';
import { AskRevAssureModalComponent } from './ask-rev-assure-modal/ask-rev-assure-modal.component';
import { AskMeaningfulUseModalComponent } from './ask-meaningful-use-modal/ask-meaningful-use-modal.component';
import { SendFeedbackModalComponent } from './send-feedback-modal/send-feedback-modal.component';

export interface NavigationTab {
	name: string;
	label: string;
	subNavigationTabs: NavigationTab[];
	displayLabel: string;
	icon: string;
	parentName: string;
	subNavigationDefaultName: string;
}

export interface HeaderTabData {
	activeTab: string;
	activeSubTab: string;
	tabs: NavigationTab[];
}

// 5 minutes
export const NAV_MENU_REFRESH_RATE = 300000;

@Component({
	selector: 'pms-header',
	templateUrl: './header.component.html',
	providers: [TypeSafeModalManagerService],
	styles: [],
})
export class HeaderComponent implements OnInit {

	userFirstName: string;
	userLastName: string;
	isLoggedIn: boolean;
	isLocked: boolean;
	isHeaderFeatureFlagOn: boolean;
	currentUserLocation: BehaviorSubject<PracticeLocation>;
	userActiveLocations: OptionItemResponse<PracticeLocation, number>[];
	tabData: HeaderTabData = {
		activeTab: '',
		activeSubTab: '',
		tabs: [],
	};
	homeTab = HEADER_TABS.TABS.HOME;
	taskTab = HEADER_TABS.TABS.TASKS;
	messageTab = HEADER_TABS.TABS.MESSAGES;
	connectTab = HEADER_TABS.TABS.REVCONNECT;
	taskCount: number;
	messageCount: number;
	knowledgeBaseAndFaq = 'https://help.revehr.com/hc/en-us';
	contactSupport = 'https://help.revehr.com/hc/en-us/articles/360035874154-Contact-Customer-Support';
	mipsResourceCenter = 'https://help.revehr.com/hc/en-us/categories/360001867034-Quality-Reporting';
	recordedWebinars = 'https://help.revehr.com/hc/en-us/sections/360005978154-Recorded-Webinars';
	referAColleague = 'https://www.revolutionehr.com/customer-referrals/?utm_source=RevEHR_App&360id=2182477&360segment=revehrcurrent';
	autoRefreshMessageSubscription: Subscription;
	autoRefreshTaskSubscription: Subscription;
	manualTaskRefresh = new Subject<void>();
	manualMessageRefresh = new Subject<void>();
	showDropdownSearch = false;
	mobileMenu: Element;
	currentMobileDropdown: HTMLLIElement;
	bootstrapFadeIn = 'in';
	unreadConversationsCount = 0;
	viewSchedulePermission = false;
	isWalmart = false;
	displayAgreementsScreen: boolean;

	constructor(
		private featureService: FeatureService,
		private authenticationService: AuthenticationService,
		private typeSafeModalManagerService: TypeSafeModalManagerService,
		private securityManagerService: SecurityManagerService,
		private navigationService: NavigationService,
		private stateManagementService: StateManagementService,
		private navigationMenuService: NavigationMenuService,
		private eventsManagerService: EventsManagerService,
		private userLocationsService: UserLocationsService,
		private router: Router,
	) {
	}

	ngOnInit() {
		this.authenticationService.isAuthenticated.subscribe((isAuthenticated) => {
			this.authenticationChanged(isAuthenticated);
		});
		this.currentUserLocation = this.userLocationsService.currentLocation;
		this.subscribeToUrlChange();
		this.subscribeToTwtUnreadConversationsCount();
	}

	subscribeToTwtUnreadConversationsCount() {
		this.navigationMenuService.observeUnreadConversationsCount().subscribe((count) => {
			this.unreadConversationsCount = count;
		});
	}

	subscribeToUrlChange() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.url.split('/')[1] !== 'legacy') {
					this.tabData.activeTab = event.url.split('/')[1];
				} else {
					this.tabData.activeTab = event.url.split('/')[2];
				}
			}
		});
	}

	authenticationChanged(isAuthenticated: boolean) {
		this.isLoggedIn = isAuthenticated;
		this.isHeaderFeatureFlagOn = this.isLoggedIn ? this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.HEADER) : false;

		if (this.isHeaderFeatureFlagOn) {
			this.isWalmart = this.securityManagerService.hasAffiliation(Affiliation.AFFILIATE_WALMART_EMPLOYED.value);
			this.displayAgreementsScreen = this.securityManagerService.sessionData.displayAgreementsScreen;
			this.getTabs();
			this.getUserData();
			this.initializeRefreshTimers();
			this.subscribeToEvents();
			this.viewSchedulePermission = this.securityManagerService.hasPermission(ResourceAuthority.VIEW_SCHEDULE.value);
		}
	}

	subscribeToEvents() {
		this.manualTaskRefresh.pipe(throttleTime(THROTTLE_REFRESH_TIME)).subscribe(() => {
			this.autoRefreshTaskSubscription.unsubscribe();
			this.initializeTaskTimer();
		});
		this.manualMessageRefresh.pipe(throttleTime(THROTTLE_REFRESH_TIME)).subscribe(() => {
			this.autoRefreshMessageSubscription.unsubscribe();
			this.initializeMessageTimer();
		});
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.MANUAL_POLL.MANUAL_POLL_MESSAGE_TOTALS, () => {
			this.manualMessageRefresh.next();
		});
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.TASKS.TASKS_MODULE_MARK_TASK_READ, () => {
			this.manualTaskRefresh.next();
		});
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_LOCKED, () => {
			this.isLocked = true;
			this.closeMobileMenu();
			this.autoRefreshTaskSubscription.unsubscribe();
			this.autoRefreshMessageSubscription.unsubscribe();
		});
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_UNLOCKED, () => {
			this.isLocked = false;
			this.initializeRefreshTimers();
		});
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.AGREEMENTS.AGREEMENT_ACCEPTED, () => {
			this.displayAgreementsScreen = false;
		});
	}

	initializeRefreshTimers() {
		this.initializeMessageTimer();
		this.initializeTaskTimer();
	}

	initializeMessageTimer() {
		this.autoRefreshMessageSubscription = timer(0, NAV_MENU_REFRESH_RATE).subscribe(() => {
			this.navigationMenuService.getNewMessagesCountForEmployee().subscribe(count => {
				this.messageCount = count;
			});
		});
	}

	initializeTaskTimer() {
		this.autoRefreshTaskSubscription = timer(0, NAV_MENU_REFRESH_RATE).subscribe(() => {
			this.navigationMenuService.getNewTaskCountForEmployee().subscribe(count => {
				this.taskCount = count;
			});
		});
	}

	getUserData() {
		this.userActiveLocations = this.userLocationsService.getUserActiveLocations();
		const userSession = this.securityManagerService.getUserSession();
		this.userFirstName = userSession.userFirstName;
		this.userLastName = userSession.userLastName;
	}

	getTabs() {
		this.tabData.tabs = this.securityManagerService.userData.sessionData.tabs;
		this.initializeTabLabelsAndIcons(this.tabData.tabs);
	}

	initializeTabLabelsAndIcons(tabs: NavigationTab[]) {
		if (_isNil(tabs)) {
			return;
		}

		tabs.forEach(tab => {
			tab.subNavigationTabs.forEach(subTab => {
				this.setTabIconAndLabel(subTab);
			});
			this.setTabIconAndLabel(tab);
		});
	}

	setTabIconAndLabel(tab: NavigationTab) {
		tab.displayLabel = this.getTabLabel(tab.name);
		tab.icon = this.getTabIconString(tab.name);
	}

	shouldShowHeader() {
		return this.isHeaderFeatureFlagOn && this.isLoggedIn && !this.isLocked && !this.displayAgreementsScreen;
	}

	openManageUpdatesModal() {
		this.closeMobileMenu();
		this.typeSafeModalManagerService.open(ManageUpdatesModalComponent, {});
	}

	navigateHome() {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.NAVIGATION.NAVIGATE_HOME);
		this.pollValues();
		return this.navigateToTopLevelModule(this.homeTab.NAME, null);
	}

	async navigateToTopLevelModule(primaryContext: string, secondaryContext: string) {
		await this.navigationService.navigateToTopLevelModule(primaryContext, secondaryContext);
		await this.stateManagementService.navigateFromUrlStore(primaryContext);
		this.tabData.activeTab = primaryContext;
		this.tabData.activeSubTab = secondaryContext;
	}

	async changeTab(tab: NavigationTab, menu: HTMLLIElement) {
		if (this.hasSubTabs(tab)) {
			this.openMenu(menu);
			return;
		}
		if (tab.name === HEADER_TABS.TABS.REVENGAGE.NAME) {
			this.tabData.activeTab = HEADER_TABS.TABS.REVENGAGE.NAME;
			this.tabData.activeSubTab = null;
			this.navigationMenuService.openDoctibleSsoTab();
			return;
		}

		if (_isNil(tab.parentName)) {
			await this.navigateToTopLevelModule(tab.name, tab.subNavigationDefaultName);
		} else {
			await this.navigateToTopLevelModule(tab.parentName, tab.name);
			this.closeMenu(menu);
		}
		this.pollValues();
	}

	pollValues() {
		this.manualMessageRefresh.next();
		this.manualTaskRefresh.next();
	}

	hasSubTabs(tab: NavigationTab): boolean {
		return !_isEmpty(tab.subNavigationTabs);
	}

	getTabLabel(tabName: string) {
		return HEADER_TAB_VALUES[tabName]?.LABEL;
	}

	getTabIconString(tabName: string) {
		return HEADER_TAB_VALUES[tabName]?.ICON;
	}

	isTabActive(tabName: string) {
		return tabName === this.tabData.activeTab;
	}

	isSubTabActive(tabName: string, subTabName: string) {
		return subTabName === this.tabData.activeSubTab && tabName === this.tabData.activeTab;
	}

	openMenu(menu: HTMLLIElement) {
		const menuList = menu.querySelector('.dropdown-menu');
		if (menuList) {
			menuList.classList.add('dropdown-menu-open');
		}
	}

	closeMenu(menu: HTMLLIElement) {
		const menuList = menu.querySelector('.dropdown-menu');
		if (menuList) {
			menuList.classList.remove('dropdown-menu-open');
		}
	}

	async touchClose(menu: HTMLLIElement, tab: NavigationTab, event: TouchEvent) {
		event.preventDefault();
		await this.changeTab(tab, menu);
		this.closeMenu(menu);
	}

	shouldDisplayCount(tabName: string, tabNameToEqual: string, count: number) {
		return tabName === tabNameToEqual && count > 0;
	}

	logout() {
		this.securityManagerService.logout(false, false, false);
	}

	isDropdownOpen(menu: HTMLLIElement) {
		const menuList = menu.querySelector('.dropdown-menu');
		return menuList.classList.contains('dropdown-menu-open');
	}

	lockScreen() {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_IS_LOCKED);
	}

	editProfile() {
		this.closeMobileMenu();
		this.typeSafeModalManagerService.open(EditProfileModalComponent, {});
	}

	closeHelpMenu(event: FocusEvent, menu: HTMLLIElement) {
		if (menu.contains(event.relatedTarget as any)) {
			event.preventDefault();
			return;
		}
		this.closeMenu(menu);
	}

	openAskRevAssureModal() {
		this.closeMobileMenu();
		this.typeSafeModalManagerService.open(AskRevAssureModalComponent, {});
	}

	openAskMeaningfulUseModal() {
		this.closeMobileMenu();
		this.typeSafeModalManagerService.open(AskMeaningfulUseModalComponent, {});
	}

	openSendFeedbackModal() {
		this.closeMobileMenu();
		this.typeSafeModalManagerService.open(SendFeedbackModalComponent, {});
	}

	isCurrentUserLocation(location: PracticeLocation) {
		return location.id === this.currentUserLocation.value.id;
	}

	changeUserLocation(userLocationMenu: HTMLLIElement, location: PracticeLocation) {
		this.closeMenu(userLocationMenu);
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.LOCATION.TRIGGER_LEGACY_LOCATION_CHANGE, location);
	}

	changeUserLocationMobile(location: PracticeLocation) {
		this.closeMobileMenu();
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.LOCATION.TRIGGER_LEGACY_LOCATION_CHANGE, location);
	}

	openTodaysPatientsDockableModal() {
		this.closeMobileMenu();
		this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.HEADER.TODAYS_PATIENTS_OPEN);
	}

	searchIconClick() {
		this.showDropdownSearch = !this.showDropdownSearch;
	}

	blurDropdownSearch(event: FocusEvent) {
		const currentTarget = event.currentTarget as HTMLDivElement;
		// Prevent closing menu when clicking on a child element
		if (!currentTarget.contains(event.relatedTarget as Node)) {
			this.showDropdownSearch = false;
		}
	}

	toggleMobileMenu(menu: HTMLElement) {
		const mobileMenu = menu.querySelector('#header-navbar-mobile');
		if (mobileMenu.classList.contains(this.bootstrapFadeIn)) {
			this.closeMobileMenu();
		} else {
			mobileMenu.classList.add(this.bootstrapFadeIn);
			document.body.classList.add('canvas-slide');
			this.mobileMenu = mobileMenu;
		}
	}

	async changeTabMobile(tab: NavigationTab, menu: HTMLLIElement) {
		if (this.hasSubTabs(tab)) {
			this.toggleDropdownMobile(menu);
			return;
		}
		if (_isNil(tab.parentName)) {
			this.closeMobileMenu();
			await this.navigateToTopLevelModule(tab.name, tab.subNavigationDefaultName);
		} else {
			this.closeMobileMenu();
			await this.navigateToTopLevelModule(tab.parentName, tab.name);
			this.closeMenu(menu);
		}
		this.pollValues();
	}

	toggleDropdownMobile(menu: HTMLLIElement) {
		const mobileMenuDropdown = menu.querySelector('.dropdown-menu');
		if (mobileMenuDropdown.classList.contains('dropdown-menu-open')) {
			menu.classList.remove('active');
			mobileMenuDropdown.classList.remove('dropdown-menu-open');
		} else {
			this.currentMobileDropdown?.classList.remove('active');
			this.currentMobileDropdown?.querySelector('.dropdown-menu').classList.remove('dropdown-menu-open');
			menu.classList.add('active');
			mobileMenuDropdown.classList.add('dropdown-menu-open');
			this.currentMobileDropdown = menu;
		}
	}

	closeMobileMenu() {
		this.mobileMenu?.classList.remove(this.bootstrapFadeIn);
		document.body.classList.remove('canvas-slide');
	}
}
