<form #referenceDataForm="ngForm"
	class="form-horizontal"
	[formGroup]="formGroup"
	(ngSubmit)="saveChanges()">
	<div class="panel-feature">
		<button ejs-button iconCss="fa fa-chevron-left" (click)="returnToList(false)" [attr.data-test-id]="'returnToListViewButton'">
			All {{headerText}}
		</button>
	</div>
	<div class="panel-body">
		<div class="row">
			<div class="col-lg-6">
				<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && referenceDataForm.submitted"
					[form]="formGroup"
					[requestObj]="requestObj">
				</gandalf-lib-validation-messages>
				<div class="form-group" [attr.data-test-id]="'referenceDataNameFormGroup'">
					<label class="col-sm-4 control-label required">Name</label>
					<div class="col-sm-8">
						<input type="text" class="e-input" revFocusInput formControlName="value"/>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'referenceDataDescriptionFormGroup'">
					<label class="col-sm-4 control-label">Description (Internal Use Only)</label>
					<div class="col-sm-8">
						<input type="text" class="e-input"
							   formControlName="description"/>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-8 col-sm-offset-4">
						<ejs-checkbox [attr.data-test-id]="'referenceDataEditableCheckbox'" label="Editable" [checked]="isEditable()" disabled="true">
						</ejs-checkbox>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-8 col-sm-offset-4">
						<ejs-checkbox formControlName="active" label="Active"></ejs-checkbox>
					</div>
				</div>
				<div class="form-group" *ngIf="mapsToMaster">
					<label class="col-sm-4 control-label">Maps to Master Data</label>
					<div class="col-sm-8">
						<p class="form-control-static">{{ mapsToMaster }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="panel-footer">
		<button ejs-button type="submit" [isPrimary]="true" [attr.data-test-id]="'standardDetailsSaveButton'">Save</button>
		<button ejs-button type="button" iconCss="fa fa-close" (click)="returnToList(false)" [attr.data-test-id]="'standardDetailsCancelButton'">Cancel</button>
	</div>
</form>
