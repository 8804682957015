<ejs-dialog
	#modal
	cssClass="modal-sm"
	(close)="closeModal()"
	[attr.data-test-id]="'editPaymentModal'">
	<ng-template #header>
		<div class="dlg-template">Edit Payment</div>
	</ng-template>
	<ng-template #content>
		<form #templateForm="ngForm" class="form-horizontal" [formGroup]="formGroup" (ngSubmit)="save()">
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && templateForm.submitted"
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'editPaymentModalValidationMessage'">
			</gandalf-lib-validation-messages>
			<div class="form-group">
				<label class="col-sm-5 control-label required">Payment Method</label>
				<div class="col-sm-7">
					<pms-constant-dropdown
						#paymentMethodDropdown
						*ngIf="showPaymentMethodDropdown()"
						placeholder="Select Method"
						formControlName="paymentMethodType"
						(selectionChange)="setCreditCardType()"
						[constantList]="paymentMethods"
						[attr.data-test-id]="'editPaymentModalPaymentMethod'">
					</pms-constant-dropdown>
					<p class="form-control-static" *ngIf="paymentMethodIsCredit">
						Credit
					</p>
				</div>
			</div>
			<div *ngIf="isCreditCardPaymentMethod()" class="form-group">
				<label class="col-sm-5 control-label">Card Type</label>
				<div class="col-sm-7">
					<pms-constant-dropdown
						#creditCardDropdown
						*ngIf="creditCardTypes"
						[showClearButton]="true"
						formControlName="paymentMethodCreditCardType"
						[constantList]="creditCardTypes"
						[attr.data-test-id]="'editPaymentModalPaymentMethodCreditCardType'">
					</pms-constant-dropdown>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-5 control-label">Reference/Check #</label>
				<div class="col-sm-7">
					<input type="text" class="e-input" formControlName="referenceNumber" [attr.data-test-id]="'editPaymentModalReferenceNumber'">
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-5 control-label required">Payment Date</label>
				<div class="col-sm-7">
					<ejs-datepicker
							[showClearButton]="true"
							placeholder="mm/dd/yyyy"
							formControlName="paymentDate"
							[format]="dateFormat"
							[attr.data-test-id]="'editPaymentModalPaymentDate'">
					</ejs-datepicker>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<rev-button buttonIcon="times" buttonLabel="Cancel" (buttonClick)="closeModal()" dataTestId="editPaymentModalCloseButton"></rev-button>
		<rev-button buttonStyle="primary" buttonLabel="Save" (buttonClick)="submitForm($event)" dataTestId="editPaymentModalSaveButton"></rev-button>
	</ng-template>
</ejs-dialog>
