// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { TransferItemRequest } from './transfer-item-request';

/** See com.rev360.pms.api.controller.accounting.TransferInvoiceItemsRequest */
/* istanbul ignore next */
@GandalfModel
export class TransferInvoiceItemsRequest extends GandalfModelBase {

	@GandalfLabel('Include All Items')
	@GandalfValidator({ notNull: { message: 'Include All Items in Transfer is required' } })
	@GandalfProperty({ isRequired: true })
	includeAllItems: boolean;

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

	@GandalfProperty()
	isValidTransfer: boolean;

	@GandalfConstantDecorator()
	@GandalfLabel('Payer Type')
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfLabel('Person Insurance')
	@GandalfProperty()
	personInsuranceId: number;

	@GandalfArray(TransferItemRequest)
	transferItems: TransferItemRequest[];

	@GandalfLabel('Transfer Reference')
	@GandalfProperty()
	transferReferenceId: number;

	@GandalfLabel('Transfer To Existing Invoice')
	@GandalfProperty()
	transferToExistingInvoiceId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Transfer Type')
	@GandalfValidator({ notNull: { message: 'Transfer Type is required' } })
	@GandalfProperty({ isRequired: true })
	transferType: constants.TransferType;

	@GandalfLabel('Write-off Reference')
	@GandalfProperty()
	writeoffReferenceId: number;

}
