import {Component, Input, OnInit} from '@angular/core';
import { HumanReadableInsuranceRemittanceResponse } from '@gandalf/model/human-readable-insurance-remittance-response';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import Big from 'big.js';
import { ServiceLineResponse } from '@gandalf/model/service-line-response';
import { _isNil } from '@core/lodash/lodash';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import { PatientNameResponse } from '@gandalf/model/patient-name-response';
import { MoneyUtil } from 'morgana';

export interface TotalData {
	eraIndex: number;
	transactionIndex: number;
	paymentIndex: number;
	billed: number;
	providerPaid: number;
	adjustmentAmount: number;
}

export interface BilledData {
	billed: number;
}

export interface ProviderPaidData {
	providerPaid: number;
}

export interface AdjustmentAmountData {
	adjustmentAmount: number;
}

@Component({
	selector: 'pms-remittance-advice',
	templateUrl: './remittance-advice.component.html',
})
export class RemittanceAdviceComponent implements OnInit {
	@Input()
	eraResponses: HumanReadableInsuranceRemittanceResponse[];

	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	totals: Array<TotalData> = [];

	constructor(
		private patientNamePipe: PatientNamePipe,
	) {
	}

	ngOnInit(): void {
		this.eraResponses.forEach((response, responseIndex) => this.calculatePaymentTotals(response, responseIndex));
	}

	calculateBilledTotal(responses: BilledData[]): number {
		return Number(MoneyUtil.sumCurrencyItems(responses, 'billed'));
	}

	calculateProviderPaidTotal(responses: ProviderPaidData[]): number {
		return Number(MoneyUtil.sumCurrencyItems(responses, 'providerPaid'));
	}

	calculateAdjustmentAmountTotal(responses: AdjustmentAmountData[]): number {
		return Number(MoneyUtil.sumCurrencyItems(responses, 'adjustmentAmount'));
	}

	calculatePaymentAdjustmentTotal(serviceLines: ServiceLineResponse[]) {
		let paymentAdjustmentTotal = Big(0);

		serviceLines?.forEach(serviceLine => {
			serviceLine?.adjustments?.forEach(adjustment => {
				paymentAdjustmentTotal = MoneyUtil.sumCurrencyValues([
					paymentAdjustmentTotal,
					adjustment.adjustmentAmount1,
					adjustment.adjustmentAmount2,
					adjustment.adjustmentAmount3,
					adjustment.adjustmentAmount4,
					adjustment.adjustmentAmount5,
					adjustment.adjustmentAmount6,
				]);
			});
		});
		return Number(paymentAdjustmentTotal);
	}

	defaultIfNull<T>(value: T, defaultValue: T): T {
		return value ?? defaultValue;
	}

	displayAdjustment(code: string, amount: number): boolean {
		return !(_isNil(amount) && _isNil(code));
	}

	calculatePaymentTotals(response: HumanReadableInsuranceRemittanceResponse, responseIndex: number) {
		response.transactions?.forEach((responseTransaction,responseTransactionIndex) =>
			responseTransaction.paymentInfo?.forEach((paymentInfo, paymentInfoIndex) => {
				this.totals.push({
					eraIndex: responseIndex,
					transactionIndex: responseTransactionIndex,
					paymentIndex: paymentInfoIndex,
					billed: this.calculateBilledTotal(paymentInfo.serviceLines),
					providerPaid: this.calculateProviderPaidTotal(paymentInfo.serviceLines),
					adjustmentAmount: this.calculatePaymentAdjustmentTotal(paymentInfo.serviceLines),
				} as TotalData);
			},
			),
		);
	}

	findPaymentTotalByEraTransactionAndPayment(eraIndex: number, transactionIndex: number, paymentIndex: number): TotalData {
		return this.totals.find(total => total.eraIndex === eraIndex && total.transactionIndex === transactionIndex && total.paymentIndex === paymentIndex);
	}

	getTotalsByEraAndTransaction(eraIndex: number, transactionIndex: number): TotalData[] {
		return this.totals.filter(total => total.eraIndex === eraIndex && total.transactionIndex === transactionIndex);
	}

	getTransactionTotalBilled(eraIndex: number, transactionIndex: number): number {
		return this.calculateBilledTotal(this.getTotalsByEraAndTransaction(eraIndex, transactionIndex));
	}

	getTransactionTotalProviderPaid(eraIndex: number, transactionIndex: number): number {
		return this.calculateProviderPaidTotal(this.getTotalsByEraAndTransaction(eraIndex, transactionIndex));
	}

	getTransactionTotalAdjustment(eraIndex: number, transactionIndex: number): number {
		return this.calculateAdjustmentAmountTotal(this.getTotalsByEraAndTransaction(eraIndex, transactionIndex));
	}

	formatName(lastName: string, firstName: string, middleName: string, suffix: string): string {
		const name = new PatientNameResponse();
		name.lastName = lastName;
		name.firstName = firstName;
		name.middleName = middleName;
		name.suffix = suffix;
		return this.patientNamePipe.transform(name, true);
	}
}
