/* eslint-disable @typescript-eslint/naming-convention, max-len */

import { UpdateFormValuePayload } from '@app-store/utils/legacy-stateful-component-utils.service';
import { HeuristicPatientSearchRequest } from '@gandalf-black/model/heuristic-patient-search-request';
import { PatientSearchRequest } from '@gandalf/model/patient-search-request';
import { Action } from '@ngrx/store';
import { SearchMode } from '@shared/interfaces/forms';

export enum PatientSearchActionsTypes {
	SET_SEARCH_MODE = '[Set Patients Search Mode] Set Patients Search Mode',
	UPDATE_PATIENT_SIMPLE_SEARCH_FORM_VALUE = '[Update Patient Simple Search Form State] Updates the value of the Patient Simple Search form within the store.',
	UPDATE_PATIENT_ADVANCED_SEARCH_FORM_VALUE = '[Update Patient Advanced Search Form State] Updates the value of the Patient Advanced Search form within the store.',
	UPDATE_PATIENT_LAST_SEARCH_FORM_VALUE = '[Update Patient Last Search Form State] Updates the value of the Patient Last Search form within the store.',
	UPDATE_PATIENT_SEARCH_TABLE_CONFIG_VALUE = '[Update Patient Search Table Config State] Updates the value of the Patient Search Table Config within the store.',
	UPDATE_PATIENT_SEARCH_TABLE_RESULTS = '[Update Patient Search Table Results] Updates the results of the Patient Search Table within the store.',
}

export class SetPatientSearchMode implements Action {
	readonly type = PatientSearchActionsTypes.SET_SEARCH_MODE;
	constructor(public payload: { searchMode: SearchMode}) {}
}

export class UpdatePatientSimpleSearchFormValue implements Action {
	readonly type = PatientSearchActionsTypes.UPDATE_PATIENT_SIMPLE_SEARCH_FORM_VALUE;
	constructor(public payload: UpdateFormValuePayload<HeuristicPatientSearchRequest>) {}
}

export class UpdatePatientAdvancedSearchFormValue implements Action {
	readonly type = PatientSearchActionsTypes.UPDATE_PATIENT_ADVANCED_SEARCH_FORM_VALUE;
	constructor(public payload: UpdateFormValuePayload<PatientSearchRequest>) {}
}

export class UpdatePatientLastSearchFormValue implements Action {
	readonly type = PatientSearchActionsTypes.UPDATE_PATIENT_LAST_SEARCH_FORM_VALUE;
	constructor(public payload: UpdateFormValuePayload<PatientSearchRequest | HeuristicPatientSearchRequest>) {}
}

export class UpdatePatientSearchTableConfigValue implements Action {
	readonly type = PatientSearchActionsTypes.UPDATE_PATIENT_SEARCH_TABLE_CONFIG_VALUE;
	constructor(public payload: string) {}
}

export class UpdatePatientSearchTableResults implements Action {
	readonly type = PatientSearchActionsTypes.UPDATE_PATIENT_SEARCH_TABLE_RESULTS;
	constructor(public payload: {value: any[], cleared?: boolean}) {}
}

export type PatientSearchActions =
	SetPatientSearchMode
	| UpdatePatientSimpleSearchFormValue
	| UpdatePatientAdvancedSearchFormValue
	| UpdatePatientLastSearchFormValue
	| UpdatePatientSearchTableConfigValue
	| UpdatePatientSearchTableResults;
