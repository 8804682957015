<div class="container-fluid">
	<div class="row">
		<div class="col-sm-12">
			<div class="rev-app-container">
				<div class="rev-app-header-toolbar d-flex flex-row flex-nowrap justify-content-start align-content-stretch with-nav-tabs">
					<div class="rev-app-header-toolbar-tabs-container flex-grow-1">
						<ejs-tab pmsStatefulTab
								 [closeConfirmation]="confirmationFunction"
								 [statefulTabItems]="invoiceTabs"
								 [staticTabs]="staticTabs"
								 [showCloseButton]="true"
								 overflowMode="MultiRow"
								 (created)="onTabsCreated()"
								 (statefulTabRemoved)="onTabRemoved($event)">
						</ejs-tab>
					</div>
					<div class="rev-app-header-toolbar-action-container flex-md-shrink-0 text-right">
						<a
							*ngIf="canCreateInvoiceAndDropdownFeatureFlagOff()"
							ejs-dropdownbutton
							revTooltip
							tooltipContent="Add"
							[items]="items"
							iconCss="fa fa-plus"
							(select)="handleNavAction($event)"
							cssClass="e-caret-hide"
							[attr.data-test-id]="'addNewInvoiceButton'">
						</a>
						<ng-container *ngIf="canCreateInvoiceAndDropdownFeatureFlagOn()">
							<rev-button
								buttonStyle="success"
								buttonIcon="plus"
								buttonLabel="Patient Invoice"
								(buttonClick)="openNewPatientInvoiceModal()"
								dataTestId="addPatientInvoiceButton">
							</rev-button>
							<rev-button
								buttonStyle="success"
								buttonIcon="plus"
								buttonLabel="Insurance Invoice"
								(buttonClick)="openNewInsuranceInvoiceModal()"
								dataTestId="addInsuranceInvoiceButton">
							</rev-button>
							<rev-button
								buttonStyle="success"
								buttonIcon="plus"
								buttonLabel="Guest Invoice"
								(buttonClick)="openNewGuestInvoiceModal()"
								dataTestId="addGuestInvoiceButton">
							</rev-button>
						</ng-container>
					</div>
				</div>
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
