<button
		(click)="receivePayment()"
		*ngIf="canReceivePayment()"
		[isPrimary]="true"
		class="margin-bottom-sm"
		ejs-button type="button"
		[attr.data-test-id]="'invoiceDetailsPaymentHistoryReceivePaymentButton'">
	Receive Payment</button>
<ejs-grid #grid
		  class="table-view table-rowlink"
		  [dataSource]="invoicePayments"
		  [allowSelection]="!isPaymentProcessing"
		  [allowSorting]="true"
		  [sortSettings]="sortOptions"
		  autoFitColumns
		  (rowSelected)="openPayment($event)"
		  [autoFitBlacklist]="['comment']"
		  gridLines="Horizontal"
		  [attr.data-test-id]="'invoiceDetailsPaymentHistoryGrid'">
	<e-columns>
		<e-column headerText="ID" field="id"></e-column>
		<e-column headerText="Group" field="paymentGroupId"></e-column>
		<e-column headerText="Date" field="paymentDate" [format]="tableDateFormat"></e-column>
		<e-column headerText="Payer" field="paymentPayerName"></e-column>
		<e-column headerText="Method" field="paymentMethod"></e-column>
		<e-column headerText="Reference #" field="referenceNumber"></e-column>
		<e-column headerText="Card Type" field="paymentMethodCardType"></e-column>
		<e-column headerText="Status" field="paymentStatus"></e-column>
		<e-column headerText="Comments" field="comment" clipMode="EllipsisWithTooltip"></e-column>
		<e-column headerText="Amount" field="amount" format="C2"></e-column>
		<e-column headerText="" field="action" [visible]="!isReadOnly && !isPaymentProcessing" [allowSorting]="false">
			<ng-template #template let-data>
				<rev-button
						*ngIf="canPrintReceipt(data)"
						buttonIcon="print"
						buttonStyle="primary"
						buttonTooltip="Print credit card receipt"
						revEventStopPropagation
						(buttonClick)="printReceipt(data)"
						dataTestId="paymentHistoryPrintButton">
				</rev-button>
				<rev-button
						*ngIf="canVoidPayment(data)"
						buttonIcon="ban"
						buttonStyle="danger"
						buttonTooltip="Void Payment"
						revEventStopPropagation
						(buttonClick)="voidPayment(data)"
						dataTestId="paymentHistoryVoidButton">
				</rev-button>
			</ng-template>
		</e-column>
	</e-columns>
	<e-aggregates>
		<e-aggregate>
			<e-columns>
				<e-column field="amount" type="Custom" [attr.data-test-id]="'invoiceDetailsPaymentHistoryAmountLabel'">
					<ng-template #footerTemplate>
						{{amountPaid | currency}}
					</ng-template>
				</e-column>
			</e-columns>
		</e-aggregate>
	</e-aggregates>
</ejs-grid>
