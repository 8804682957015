// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.ClaimSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class ClaimSearchRequest extends GandalfModelBase {

	@GandalfLabel('Claim Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDateEnd: Date;

	@GandalfLabel('Claim Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDateStart: Date;

	@GandalfLabel('Claim ID')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Claim ID may not be more than 18 digits' } })
	@GandalfProperty()
	claimId: number;

	@GandalfLabel('Insurance')
	@GandalfProperty()
	insuranceCompanyId: number;

	@GandalfLabel('Invoice Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDateEnd: Date;

	@GandalfLabel('Invoice Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDateStart: Date;

	@GandalfLabel('Invoice ID')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Invoice ID may not be more than 18 digits' } })
	@GandalfProperty()
	invoiceId: number;

	@GandalfLabel('Location')
	@GandalfProperty()
	locationId: number;

	@GandalfLabel('Patient ID')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Patient ID may not be more than 18 digits' } })
	@GandalfProperty()
	patientId: number;

	@GandalfLabel('Patient Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Patient Name', maxLength: 1000, minLength: 0, message: 'Patient Name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	patientName: string;

	@GandalfLabel('Insurance')
	@GandalfArray(Number)
	practiceInsuranceCompanyIds: number[];

	@GandalfConstantDecorator()
	@GandalfLabel('Submit Status')
	@GandalfProperty()
	processingStatus: constants.ClaimProcessingStatus;

	@GandalfLabel('Provider')
	@GandalfProperty()
	providerId: number;

	@GandalfLabel('Service Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDateEnd: Date;

	@GandalfLabel('Service Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDateStart: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('Status')
	@GandalfProperty()
	status: constants.InsuranceClaimStatus;

	@GandalfLabel('Status Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDateEnd: Date;

	@GandalfLabel('Status Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDateStart: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('Submit Method')
	@GandalfProperty()
	statusMethod: constants.ClaimSubmitMechanism;

	@GandalfLabel('Submit Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	submitDateEnd: Date;

	@GandalfLabel('Submit Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	submitDateStart: Date;

}
