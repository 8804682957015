// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { DiagnosisResponse } from './diagnosis-response';

// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './invoice-item-adjustment-response';

// @ts-ignore
import { ModifierResponse } from './modifier-response';

// @ts-ignore
import { TaxDetailResponse } from './tax-detail-response';

/** See com.rev360.legacy.api.controller.accounting.InvoiceDetailsItemResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceDetailsItemResponse extends GandalfModelBase {

	@GandalfArray(DiagnosisResponse)
	additionalDiagnoses: DiagnosisResponse[];

	@GandalfArray(ModifierResponse)
	additionalModifiers: ModifierResponse[];

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentTotal: number;

	@GandalfProperty({ propertyType: 'Money' })
	amountPaid: number;

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfProperty()
	code: string;

	@GandalfProperty()
	description: string;

	@GandalfProperty({ propertyType: 'Money' })
	discountTotal: number;

	@GandalfProperty({ propertyType: 'Money' })
	extendedPrice: number;

	@GandalfProperty()
	invoiceItemId: number;

	@GandalfArray(InvoiceItemAdjustmentResponse)
	itemAdjustments: InvoiceItemAdjustmentResponse[];

	@GandalfArray(TaxDetailResponse)
	itemTaxes: TaxDetailResponse[];

	@GandalfProperty()
	originalItemId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	postedOn: Date;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty()
	split: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InvoiceItemStatus;

	@GandalfProperty({ propertyType: 'Money' })
	taxAmount: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.InvoiceItemType;

	@GandalfProperty({ propertyType: 'Money' })
	unitPrice: number;

	@GandalfProperty()
	wasSplit: boolean;

}
