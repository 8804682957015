// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.ManualProcessPaymentRequest */
/* istanbul ignore next */
@GandalfModel
export class ManualProcessPaymentRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	creditCardType: constants.CreditCardType;

	@GandalfLabel('Reference Number')
	@GandalfValidator({ sizeString: { message: 'Reference Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	referenceNumber: string;

}
