// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AppointmentResponse } from './appointment-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.ValidatedCreateAppointmentLegacyResponse */
/* istanbul ignore next */
@GandalfModel
export class ValidatedCreateAppointmentLegacyResponse extends GandalfModelBase {

	@GandalfArray(AppointmentResponse)
	appointments: AppointmentResponse[];

	@GandalfArray(String)
	messages: string[];

	@GandalfProperty()
	waitingListEntriesDeactivated: boolean;

}
