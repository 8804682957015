import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { MapEmployeeUtilsService } from '@core/map-employee-utils/map-employee-utils.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { EmployeeEditProfileRequest } from '@gandalf/model/employee-edit-profile-request';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { ModalBase } from 'morgana';
import { _assign } from '@core/lodash/lodash';
import { EditProfileService } from '../services/edit-profile.service';


@Component({
	selector: 'pms-edit-profile-modal',
	templateUrl: './edit-profile-modal.component.html',
	styles: [],
})
export class EditProfileModalComponent extends ModalBase<null, EmployeeEditProfileRequest> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	employeeId;

	tabAnimation = TabAnimationDefaults;

	formGroup: UntypedFormGroup;

	isSaving = false;

	request: EmployeeEditProfileRequest;

	constructor(
		private editProfileService: EditProfileService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private securityManagerService: SecurityManagerService,
	) {
		super();
	}

	ngOnInit(): void {
		this.getEmployeeProfile();
	}

	getEmployeeProfile() {
		this.editProfileService.getEmployeeProfileById().subscribe(employee => {
			this.employeeId = employee.employeeId;
			this.createFormGroup(employee);
		});
	}

	createFormGroup(employee) {
		this.request = MapEmployeeUtilsService.mapEmployeeEditProfileToRequest(employee, this.securityManagerService.isCanada());
		this.formGroup = this.gandalfFormBuilder.group(this.request);
	}

	/* istanbul ignore next: closeModal */
	closeModal() {
		this.dynamicModalRef.close(this.modal, _assign(this.request, this.formGroup.value));
	}

	getPersonalDetailFormGroup() {
		return this.formGroup?.get('personDetails') as UntypedFormGroup;
	}

	getEmployeePreferencesFormGroup() {
		return this.formGroup?.get('employeePreferences') as UntypedFormGroup;
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	submit() {
		if (this.formGroup.invalid || this.isSaving) {
			return;
		}
		this.isSaving = true;
		this.editProfileService.updateEmployeeProfile(this.formGroup.value).subscribe(() => {
			this.closeModal();
		});
	}
}
