// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.UpdateAdditionalClaimInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateAdditionalClaimInformationRequest extends GandalfModelBase {

	@GandalfLabel('Alternate Service Location')
	@GandalfProperty()
	alternateServiceLocationId: number;

	@GandalfLabel('Attachment Control Number')
	@GandalfValidator({ sizeString: { message: 'Attachment Control Number cannot be longer than 80 characters', minLength: 0, maxLength: 80 } })
	@GandalfProperty()
	attachmentControlNumber: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Attachment Transmission')
	@GandalfProperty()
	attachmentTransmission: constants.ClaimAttachmentTransmission;

	@GandalfConstantDecorator()
	@GandalfLabel('Attachment Type')
	@GandalfProperty()
	attachmentType: constants.ClaimAttachmentType;

	@GandalfLabel('Authorization')
	@GandalfProperty()
	authorizationId: number;

	@GandalfLabel('Authorization Identifier')
	@GandalfValidator({ sizeString: { message: 'Authorization Identifier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	authorizationNumber: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Claim Frequency Code')
	@GandalfProperty()
	claimFrequencyCode: constants.ClaimFrequencyCode;

	@GandalfLabel('CLIA Number')
	@GandalfValidator({ sizeString: { message: 'CLIA Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	cliaNumber: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Condition Contacts Apply')
	@GandalfProperty()
	conditionContactsApply: constants.ClaimVisionConditionApply;

	@GandalfLabel('Condition Contacts L1')
	@GandalfProperty()
	conditionContactsL1: boolean;

	@GandalfLabel('Condition Contacts L2')
	@GandalfProperty()
	conditionContactsL2: boolean;

	@GandalfLabel('Condition Contacts L3')
	@GandalfProperty()
	conditionContactsL3: boolean;

	@GandalfLabel('Condition Contacts L4')
	@GandalfProperty()
	conditionContactsL4: boolean;

	@GandalfLabel('Condition Contacts L5')
	@GandalfProperty()
	conditionContactsL5: boolean;

	@GandalfConstantDecorator()
	@GandalfLabel('Condition Frames Apply')
	@GandalfProperty()
	conditionFramesApply: constants.ClaimVisionConditionApply;

	@GandalfLabel('Condition Frames L1')
	@GandalfProperty()
	conditionFramesL1: boolean;

	@GandalfLabel('Condition Frames L2')
	@GandalfProperty()
	conditionFramesL2: boolean;

	@GandalfLabel('Condition Frames L3')
	@GandalfProperty()
	conditionFramesL3: boolean;

	@GandalfLabel('Condition Frames L4')
	@GandalfProperty()
	conditionFramesL4: boolean;

	@GandalfLabel('Condition Frames L5')
	@GandalfProperty()
	conditionFramesL5: boolean;

	@GandalfConstantDecorator()
	@GandalfLabel('Condition Lenses Apply')
	@GandalfProperty()
	conditionLensesApply: constants.ClaimVisionConditionApply;

	@GandalfLabel('Condition Lenses L1')
	@GandalfProperty()
	conditionLensesL1: boolean;

	@GandalfLabel('Condition Lenses L2')
	@GandalfProperty()
	conditionLensesL2: boolean;

	@GandalfLabel('Condition Lenses L3')
	@GandalfProperty()
	conditionLensesL3: boolean;

	@GandalfLabel('Condition Lenses L4')
	@GandalfProperty()
	conditionLensesL4: boolean;

	@GandalfLabel('Condition Lenses L5')
	@GandalfProperty()
	conditionLensesL5: boolean;

	@GandalfLabel('Current Illness Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	currentIllnessDate: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('Current Illness Date Qualifier')
	@GandalfProperty()
	currentIllnessDateQualifier: constants.ClaimCurrentIllnessDateQualifier;

	@GandalfConstantDecorator()
	@GandalfLabel('Diagnosis Code Set')
	@GandalfValidator({ notNull: { message: 'Diagnosis Code Set is required' } })
	@GandalfProperty({ isRequired: true })
	diagnosisCodeSet: constants.CodeSet;

	@GandalfLabel('End Care Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	endCareDate: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('EPSDT Apply')
	@GandalfProperty()
	epsdtApply: constants.ClaimEpsdtApply;

	@GandalfConstantDecorator()
	@GandalfLabel('EPSDT Code 1')
	@GandalfProperty()
	epsdtCode1: constants.ClaimEpsdtCode;

	@GandalfConstantDecorator()
	@GandalfLabel('EPSDT Code 2')
	@GandalfProperty()
	epsdtCode2: constants.ClaimEpsdtCode;

	@GandalfConstantDecorator()
	@GandalfLabel('EPSDT Code 3')
	@GandalfProperty()
	epsdtCode3: constants.ClaimEpsdtCode;

	@GandalfLabel('Hospitalization End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	hospitalizationEndDate: Date;

	@GandalfLabel('Hospitalization Start Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	hospitalizationStartDate: Date;

	@GandalfLabel('Additional Claim Information')
	@GandalfValidator({ notNull: { message: 'Additional Claim Information is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfLabel('Initial Treatment Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	initialTreatmentDate: Date;

	@GandalfLabel('Local Use')
	@GandalfValidator({ sizeString: { message: 'Local Use cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	localUse: string;

	@GandalfLabel('Claim Codes')
	@GandalfValidator({ sizeString: { message: 'Claim Codes cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	localUse10d: string;

	@GandalfLabel('Medicaid Resubmit Code')
	@GandalfValidator({ sizeString: { message: 'Medicaid Resubmit Code cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	medicaidResubmitCode: string;

	@GandalfLabel('Ordering Provider')
	@GandalfProperty()
	orderingProviderId: number;

	@GandalfLabel('Original Reference Number')
	@GandalfValidator({ sizeString: { message: 'Original Reference Number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	originalReferenceNumber: string;

	@GandalfLabel('Out of Work End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	outOfWorkEndDate: Date;

	@GandalfLabel('Out of Work Start Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	outOfWorkStartDate: Date;

	@GandalfLabel('Outside Lab')
	@GandalfValidator({ notNull: { message: 'Outside Lab is required' } })
	@GandalfProperty({ isRequired: true })
	outsideLab: boolean;

	@GandalfLabel('Outside Lab Charges')
	@GandalfValidator({ notNull: { message: 'Outside Lab Charges is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	outsideLabCharges: number;

	@GandalfLabel('Prior Authorization Number')
	@GandalfValidator({ sizeString: { message: 'Prior Authorization Number cannot be longer than 40 characters', minLength: 0, maxLength: 40 } })
	@GandalfProperty()
	priorAuthorizationNumber: string;

	@GandalfLabel('Referral Number')
	@GandalfValidator({ sizeString: { message: 'Referral Number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	referralNumber: string;

	@GandalfLabel('Referring Provider')
	@GandalfProperty()
	referringProviderId: number;

	@GandalfLabel('Person Insurance')
	@GandalfProperty()
	secondaryPayerId: number;

	@GandalfLabel('Signature Handwritten')
	@GandalfProperty()
	signatureHandwritten: boolean;

	@GandalfLabel('Similar Illness Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	similarIllnessDate: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('Similar Illness Date Qualifier')
	@GandalfProperty()
	similarIllnessDateQualifier: constants.ClaimSimilarIllnessDateQualifier;

	@GandalfLabel('Start Care Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	startCareDate: Date;

	@GandalfLabel('Version')
	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version: number;

	@GandalfLabel('Vision Prescription Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	visionPrescriptionDate: Date;

}
