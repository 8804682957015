// @ts-ignore
import { Injectable } from '@angular/core';
// @ts-ignore
import { GandalfCacheableConfigurableOptions, GandalfCacheableOptions, GandalfCacheBuster, GandalfHttpUtilsService, GandalfStoreService } from 'gandalf';
// @ts-ignore
import { Observable } from 'rxjs';


// @ts-ignore
import { AccessLogSearchRequest } from './model/access-log-search-request';

// @ts-ignore
import { AccessLogSearchResponse } from './model/access-log-search-response';

// @ts-ignore
import { AccountingCheckIfInvoiceHasDirectPaymentRequest } from './model/accounting-check-if-invoice-has-direct-payment-request';

// @ts-ignore
import { AccountingInvoicePaymentResponse } from './model/accounting-invoice-payment-response';

// @ts-ignore
import { AccountingInvoicesWithDirectPaymentsResponse } from './model/accounting-invoices-with-direct-payments-response';

// @ts-ignore
import { AccountingPaymentPreferencesResponse } from './model/accounting-payment-preferences-response';

// @ts-ignore
import { AccountingUpdatePaymentGroupRequest } from './model/accounting-update-payment-group-request';

// @ts-ignore
import { AddAdhocInvoiceItemRequest } from './model/add-adhoc-invoice-item-request';

// @ts-ignore
import { AddAutoDiagnosisToEncounterRequest } from './model/add-auto-diagnosis-to-encounter-request';

// @ts-ignore
import { AddBillableItemsBillingRequest } from './model/add-billable-items-billing-request';

// @ts-ignore
import { AddDiagnosesToPerformedServicesRequest } from './model/add-diagnoses-to-performed-services-request';

// @ts-ignore
import { AddDiscountItemRequest } from './model/add-discount-item-request';

// @ts-ignore
import { AddDiscountItemsRequest } from './model/add-discount-items-request';

// @ts-ignore
import { AddDiscountRequest } from './model/add-discount-request';

// @ts-ignore
import { AddDynamicTestToDynamicScreenRequest } from './model/add-dynamic-test-to-dynamic-screen-request';

// @ts-ignore
import { AddFamilyMemberRequest } from './model/add-family-member-request';

// @ts-ignore
import { AddFamilyMemberWithEmergencyContactRequest } from './model/add-family-member-with-emergency-contact-request';

// @ts-ignore
import { AddInvoiceItemRequest } from './model/add-invoice-item-request';

// @ts-ignore
import { AddMasterDiagnosisToEncounterRequest } from './model/add-master-diagnosis-to-encounter-request';

// @ts-ignore
import { AddPatientOwnedFrameRequest } from './model/add-patient-owned-frame-request';

// @ts-ignore
import { AddPatientOwnedFrameResponse } from './model/add-patient-owned-frame-response';

// @ts-ignore
import { AddPerformedServicesRequest } from './model/add-performed-services-request';

// @ts-ignore
import { AddPqriAlertsToEncounterRequest } from './model/add-pqri-alerts-to-encounter-request';

// @ts-ignore
import { AdditionalClaimInformationCanadaRequest } from './model/additional-claim-information-canada-request';

// @ts-ignore
import { AdditionalClaimInformationCanadaResponse } from './model/additional-claim-information-canada-response';

// @ts-ignore
import { AdditionalClaimInformationResponse } from './model/additional-claim-information-response';

// @ts-ignore
import { AdditionalPreferencesRequest } from './model/additional-preferences-request';

// @ts-ignore
import { AdditionalPreferencesResponse } from './model/additional-preferences-response';

// @ts-ignore
import { AddressOptionalRequest } from './model/address-optional-request';

// @ts-ignore
import { AddressRequiredRequest } from './model/address-required-request';

// @ts-ignore
import { AddressResponse } from './model/address-response';

// @ts-ignore
import { AdminAccessCodeRequest } from './model/admin-access-code-request';

// @ts-ignore
import { AdminAccessTimeRequest } from './model/admin-access-time-request';

// @ts-ignore
import { AdminAccessTimeResponse } from './model/admin-access-time-response';

// @ts-ignore
import { AdminAddWorkflowTestTemplateFieldOptionRequest } from './model/admin-add-workflow-test-template-field-option-request';

// @ts-ignore
import { AdminCopyEyefinityFeeScheduleToLocationRequest } from './model/admin-copy-eyefinity-fee-schedule-to-location-request';

// @ts-ignore
import { AdminCreateEmployeeExternalCredentialRequest } from './model/admin-create-employee-external-credential-request';

// @ts-ignore
import { AdminCreateEmployeeLoginDetailsRequest } from './model/admin-create-employee-login-details-request';

// @ts-ignore
import { AdminCreateEmployeeRequest } from './model/admin-create-employee-request';

// @ts-ignore
import { AdminCreateEyefinityFeeScheduleRequest } from './model/admin-create-eyefinity-fee-schedule-request';

// @ts-ignore
import { AdminCreatePracticeLocationDetailsRequest } from './model/admin-create-practice-location-details-request';

// @ts-ignore
import { AdminCreatePracticeLocationFileDeviceRequest } from './model/admin-create-practice-location-file-device-request';

// @ts-ignore
import { AdminCreatePracticeLocationRequest } from './model/admin-create-practice-location-request';

// @ts-ignore
import { AdminCreatePracticeLocationSerialDeviceRequest } from './model/admin-create-practice-location-serial-device-request';

// @ts-ignore
import { AdminCreateProviderInsuranceIdentifierRequest } from './model/admin-create-provider-insurance-identifier-request';

// @ts-ignore
import { AdminCreateWorkflowScreenTemplateRequest } from './model/admin-create-workflow-screen-template-request';

// @ts-ignore
import { AdminCreateWorkflowTestTemplateSvcTriggerRequest } from './model/admin-create-workflow-test-template-svc-trigger-request';

// @ts-ignore
import { AdminEditWorkflowTestTemplateFieldOptionRequest } from './model/admin-edit-workflow-test-template-field-option-request';

// @ts-ignore
import { AdminEmployeeDetailedResponse } from './model/admin-employee-detailed-response';

// @ts-ignore
import { AdminEmployeeExternalCredentialResponse } from './model/admin-employee-external-credential-response';

// @ts-ignore
import { AdminEmployeeExternalSystemsRequest } from './model/admin-employee-external-systems-request';

// @ts-ignore
import { AdminEmployeeExternalSystemsResponse } from './model/admin-employee-external-systems-response';

// @ts-ignore
import { AdminEmployeeForEmployeeListResponse } from './model/admin-employee-for-employee-list-response';

// @ts-ignore
import { AdminEmployeeLimitedResponse } from './model/admin-employee-limited-response';

// @ts-ignore
import { AdminEmployeeLocationAccessRequest } from './model/admin-employee-location-access-request';

// @ts-ignore
import { AdminEmployeeLocationAccessResponse } from './model/admin-employee-location-access-response';

// @ts-ignore
import { AdminEmployeeLoginDetailsResponse } from './model/admin-employee-login-details-response';

// @ts-ignore
import { AdminEmployeeLoginRestrictionRequest } from './model/admin-employee-login-restriction-request';

// @ts-ignore
import { AdminEmployeeLoginRestrictionResponse } from './model/admin-employee-login-restriction-response';

// @ts-ignore
import { AdminEmployeeProviderDetailsRequest } from './model/admin-employee-provider-details-request';

// @ts-ignore
import { AdminEmployeeProviderDetailsResponse } from './model/admin-employee-provider-details-response';

// @ts-ignore
import { AdminEmployeeRolesRequest } from './model/admin-employee-roles-request';

// @ts-ignore
import { AdminEmployeeRolesResponse } from './model/admin-employee-roles-response';

// @ts-ignore
import { AdminEncounterTemplateResponse } from './model/admin-encounter-template-response';

// @ts-ignore
import { AdminEyefinityFeeLensRequest } from './model/admin-eyefinity-fee-lens-request';

// @ts-ignore
import { AdminEyefinityFeeLensResponse } from './model/admin-eyefinity-fee-lens-response';

// @ts-ignore
import { AdminEyefinityFeeOptionRequest } from './model/admin-eyefinity-fee-option-request';

// @ts-ignore
import { AdminEyefinityFeeOptionResponse } from './model/admin-eyefinity-fee-option-response';

// @ts-ignore
import { AdminEyefinityFeeScheduleListResponse } from './model/admin-eyefinity-fee-schedule-list-response';

// @ts-ignore
import { AdminEyefinityFeeScheduleRequest } from './model/admin-eyefinity-fee-schedule-request';

// @ts-ignore
import { AdminEyefinityFeeScheduleResponse } from './model/admin-eyefinity-fee-schedule-response';

// @ts-ignore
import { AdminEyefinityFeeScheduleTemplateResponse } from './model/admin-eyefinity-fee-schedule-template-response';

// @ts-ignore
import { AdminEyefinityFeeServiceRequest } from './model/admin-eyefinity-fee-service-request';

// @ts-ignore
import { AdminEyefinityFeeServiceResponse } from './model/admin-eyefinity-fee-service-response';

// @ts-ignore
import { AdminFindWorkflowTestTemplateSvcTriggerRequest } from './model/admin-find-workflow-test-template-svc-trigger-request';

// @ts-ignore
import { AdminInsuranceCreditResponse } from './model/admin-insurance-credit-response';

// @ts-ignore
import { AdminInsuranceRefundResponse } from './model/admin-insurance-refund-response';

// @ts-ignore
import { AdminLocationOrderProcessingResponse } from './model/admin-location-order-processing-response';

// @ts-ignore
import { AdminLocationOrderProcessingVisionwebRequest } from './model/admin-location-order-processing-visionweb-request';

// @ts-ignore
import { AdminLocationOrderProcessingVisionwebResponse } from './model/admin-location-order-processing-visionweb-response';

// @ts-ignore
import { AdminLocationPhoneRequest } from './model/admin-location-phone-request';

// @ts-ignore
import { AdminLocationTaxResponse } from './model/admin-location-tax-response';

// @ts-ignore
import { AdminOpenEdgeCardReaderResponse } from './model/admin-open-edge-card-reader-response';

// @ts-ignore
import { AdminPracticeInsuranceCompanySummaryResponse } from './model/admin-practice-insurance-company-summary-response';

// @ts-ignore
import { AdminPracticeLocationAddressResponse } from './model/admin-practice-location-address-response';

// @ts-ignore
import { AdminPracticeLocationDetailsRequest } from './model/admin-practice-location-details-request';

// @ts-ignore
import { AdminPracticeLocationDetailsResponse } from './model/admin-practice-location-details-response';

// @ts-ignore
import { AdminPracticeLocationDistributionCenterRequest } from './model/admin-practice-location-distribution-center-request';

// @ts-ignore
import { AdminPracticeLocationDistributionCenterResponse } from './model/admin-practice-location-distribution-center-response';

// @ts-ignore
import { AdminPracticeLocationExternalSystemsResponse } from './model/admin-practice-location-external-systems-response';

// @ts-ignore
import { AdminPracticeLocationOfficeHoursResponse } from './model/admin-practice-location-office-hours-response';

// @ts-ignore
import { AdminPracticeLocationOrderProcessingRequest } from './model/admin-practice-location-order-processing-request';

// @ts-ignore
import { AdminPracticeLocationPhrRequest } from './model/admin-practice-location-phr-request';

// @ts-ignore
import { AdminPracticeLocationPhrResponse } from './model/admin-practice-location-phr-response';

// @ts-ignore
import { AdminPracticeLocationResponse } from './model/admin-practice-location-response';

// @ts-ignore
import { AdminSmartApplicationResponse } from './model/admin-smart-application-response';

// @ts-ignore
import { AdminUpdateEmployeeExternalCredentialRequest } from './model/admin-update-employee-external-credential-request';

// @ts-ignore
import { AdminUpdateEmployeeLoginDetailsRequest } from './model/admin-update-employee-login-details-request';

// @ts-ignore
import { AdminUpdateEmployeeRequest } from './model/admin-update-employee-request';

// @ts-ignore
import { AdminUpdateEyefinityFeeScheduleRequest } from './model/admin-update-eyefinity-fee-schedule-request';

// @ts-ignore
import { AdminUpdatePracticeLocationFileDeviceRequest } from './model/admin-update-practice-location-file-device-request';

// @ts-ignore
import { AdminUpdatePracticeLocationRequest } from './model/admin-update-practice-location-request';

// @ts-ignore
import { AdminUpdatePracticeLocationSerialDeviceRequest } from './model/admin-update-practice-location-serial-device-request';

// @ts-ignore
import { AdminUpdateProviderInsuranceIdentifierRequest } from './model/admin-update-provider-insurance-identifier-request';

// @ts-ignore
import { AdminUpdateWorkflowScreenTemplateRequest } from './model/admin-update-workflow-screen-template-request';

// @ts-ignore
import { AdminUpdateWorkflowTestTemplateRequest } from './model/admin-update-workflow-test-template-request';

// @ts-ignore
import { AdminUpdateWorkflowTestTemplateSvcTriggerRequest } from './model/admin-update-workflow-test-template-svc-trigger-request';

// @ts-ignore
import { AdminWorkflowScreenTemplateListResponse } from './model/admin-workflow-screen-template-list-response';

// @ts-ignore
import { AdminWorkflowScreenTemplateResponse } from './model/admin-workflow-screen-template-response';

// @ts-ignore
import { AdminWorkflowScreenTemplateTestResponse } from './model/admin-workflow-screen-template-test-response';

// @ts-ignore
import { AdminWorkflowTestTemplateFormFieldRequest } from './model/admin-workflow-test-template-form-field-request';

// @ts-ignore
import { AdminWorkflowTestTemplateFormFieldsResponse } from './model/admin-workflow-test-template-form-fields-response';

// @ts-ignore
import { AdminWorkflowTestTemplateListResponse } from './model/admin-workflow-test-template-list-response';

// @ts-ignore
import { AdminWorkflowTestTemplateSvcTriggerDetailResponse } from './model/admin-workflow-test-template-svc-trigger-detail-response';

// @ts-ignore
import { AdminWorkflowTestTemplateSvcTriggerResponse } from './model/admin-workflow-test-template-svc-trigger-response';

// @ts-ignore
import { AdvancedAgeReportRequest } from './model/advanced-age-report-request';

// @ts-ignore
import { AdvancedCostReportRequest } from './model/advanced-cost-report-request';

// @ts-ignore
import { AdvancedInventoryMinMaxReportRequest } from './model/advanced-inventory-min-max-report-request';

// @ts-ignore
import { AdvancedInventoryReportRequest } from './model/advanced-inventory-report-request';

// @ts-ignore
import { AdvancedSalesReportRequest } from './model/advanced-sales-report-request';

// @ts-ignore
import { AgingReportRequest } from './model/aging-report-request';

// @ts-ignore
import { AgingReportResponse } from './model/aging-report-response';

// @ts-ignore
import { AgreementDetailsResponse } from './model/agreement-details-response';

// @ts-ignore
import { AlertResponse } from './model/alert-response';

// @ts-ignore
import { AllergyListResponse } from './model/allergy-list-response';

// @ts-ignore
import { AllergyRequest } from './model/allergy-request';

// @ts-ignore
import { AllergyResponse } from './model/allergy-response';

// @ts-ignore
import { AllergyReviewHistoryRequest } from './model/allergy-review-history-request';

// @ts-ignore
import { AllergyReviewHistoryResponse } from './model/allergy-review-history-response';

// @ts-ignore
import { AmendmentListResponse } from './model/amendment-list-response';

// @ts-ignore
import { AmendmentResponse } from './model/amendment-response';

// @ts-ignore
import { AncillaryParticipantResponse } from './model/ancillary-participant-response';

// @ts-ignore
import { AppointmentCalendarResponse } from './model/appointment-calendar-response';

// @ts-ignore
import { AppointmentExamHistoryListResponse } from './model/appointment-exam-history-list-response';

// @ts-ignore
import { AppointmentHistoryResponse } from './model/appointment-history-response';

// @ts-ignore
import { AppointmentInsuranceVerificationResponse } from './model/appointment-insurance-verification-response';

// @ts-ignore
import { AppointmentReportRequest } from './model/appointment-report-request';

// @ts-ignore
import { AppointmentReportResponse } from './model/appointment-report-response';

// @ts-ignore
import { AppointmentResponse } from './model/appointment-response';

// @ts-ignore
import { AppointmentSidebarResponse } from './model/appointment-sidebar-response';

// @ts-ignore
import { AppointmentSlotCalendarResponse } from './model/appointment-slot-calendar-response';

// @ts-ignore
import { AppointmentSlotResponse } from './model/appointment-slot-response';

// @ts-ignore
import { AppointmentSlotSidebarResponse } from './model/appointment-slot-sidebar-response';

// @ts-ignore
import { ApproveInvoiceRequest } from './model/approve-invoice-request';

// @ts-ignore
import { AskQuestionRequest } from './model/ask-question-request';

// @ts-ignore
import { AssessmentAutoDiagnosisResponse } from './model/assessment-auto-diagnosis-response';

// @ts-ignore
import { AssessmentDiagnosisReviewRequest } from './model/assessment-diagnosis-review-request';

// @ts-ignore
import { AssessmentDiagnosisReviewResponse } from './model/assessment-diagnosis-review-response';

// @ts-ignore
import { AssessmentPersonDiagnosisResponse } from './model/assessment-person-diagnosis-response';

// @ts-ignore
import { AssessmentPracticeDiagnosisResponse } from './model/assessment-practice-diagnosis-response';

// @ts-ignore
import { AssessmentResponse } from './model/assessment-response';

// @ts-ignore
import { AssignFeeScheduleItemListRequest } from './model/assign-fee-schedule-item-list-request';

// @ts-ignore
import { AssignFeeScheduleItemRequest } from './model/assign-fee-schedule-item-request';

// @ts-ignore
import { AssignRelatedCategoriesRequest } from './model/assign-related-categories-request';

// @ts-ignore
import { AssociateMeasurementWithOrderRequest } from './model/associate-measurement-with-order-request';

// @ts-ignore
import { AttributeDropdownResponse } from './model/attribute-dropdown-response';

// @ts-ignore
import { AuthorizeContactLensPrescriptionRequest } from './model/authorize-contact-lens-prescription-request';

// @ts-ignore
import { AuthorizeEyeglassPrescriptionsRequest } from './model/authorize-eyeglass-prescriptions-request';

// @ts-ignore
import { AuthorizeMedicationPrescriptionRequest } from './model/authorize-medication-prescription-request';

// @ts-ignore
import { BarcodeSearchRequest } from './model/barcode-search-request';

// @ts-ignore
import { BarcodeSearchResponse } from './model/barcode-search-response';

// @ts-ignore
import { BaseAgeReportResponse } from './model/base-age-report-response';

// @ts-ignore
import { BaseCostReportResponse } from './model/base-cost-report-response';

// @ts-ignore
import { BaseInventoryReportResponse } from './model/base-inventory-report-response';

// @ts-ignore
import { BaseSalesReportResponse } from './model/base-sales-report-response';

// @ts-ignore
import { BasicAgeReportRequest } from './model/basic-age-report-request';

// @ts-ignore
import { BasicCostReportRequest } from './model/basic-cost-report-request';

// @ts-ignore
import { BasicInventoryMinMaxReportRequest } from './model/basic-inventory-min-max-report-request';

// @ts-ignore
import { BasicInventoryReportRequest } from './model/basic-inventory-report-request';

// @ts-ignore
import { BasicSalesReportRequest } from './model/basic-sales-report-request';

// @ts-ignore
import { BillableItemProductResponse } from './model/billable-item-product-response';

// @ts-ignore
import { BillingInvoiceResponse } from './model/billing-invoice-response';

// @ts-ignore
import { BillingPayerResponse } from './model/billing-payer-response';

// @ts-ignore
import { BillingServiceResponse } from './model/billing-service-response';

// @ts-ignore
import { BridgeExportResponse } from './model/bridge-export-response';

// @ts-ignore
import { BridgeExportTokenRequest } from './model/bridge-export-token-request';

// @ts-ignore
import { BulkCancelOrdersRequest } from './model/bulk-cancel-orders-request';

// @ts-ignore
import { BulkNotifyPatientsRequest } from './model/bulk-notify-patients-request';

// @ts-ignore
import { BulkUpdateOrderRequest } from './model/bulk-update-order-request';

// @ts-ignore
import { BusinessPhonesResponse } from './model/business-phones-response';

// @ts-ignore
import { BusyTimeCalendarResponse } from './model/busy-time-calendar-response';

// @ts-ignore
import { BusyTimeResponse } from './model/busy-time-response';

// @ts-ignore
import { BusyTimeSidebarResponse } from './model/busy-time-sidebar-response';

// @ts-ignore
import { CanSendPatientMessageRequest } from './model/can-send-patient-message-request';

// @ts-ignore
import { CancelAppointmentRequest } from './model/cancel-appointment-request';

// @ts-ignore
import { CancelEncounterRequest } from './model/cancel-encounter-request';

// @ts-ignore
import { CancelOrderRequest } from './model/cancel-order-request';

// @ts-ignore
import { CancelPrescriptionRequest } from './model/cancel-prescription-request';

// @ts-ignore
import { CarePlanItemHistoryResponse } from './model/care-plan-item-history-response';

// @ts-ignore
import { CarePlanItemResponse } from './model/care-plan-item-response';

// @ts-ignore
import { CarePlanItemTemplateEducationResponse } from './model/care-plan-item-template-education-response';

// @ts-ignore
import { CarePlanItemTemplateGeneralResponse } from './model/care-plan-item-template-general-response';

// @ts-ignore
import { CarePlanItemTemplateGoalResponse } from './model/care-plan-item-template-goal-response';

// @ts-ignore
import { CarePlanItemTemplateLabOrderResponse } from './model/care-plan-item-template-lab-order-response';

// @ts-ignore
import { CarePlanItemTemplateListResponse } from './model/care-plan-item-template-list-response';

// @ts-ignore
import { CarePlanItemTemplateMedicationPrescriptionResponse } from './model/care-plan-item-template-medication-prescription-response';

// @ts-ignore
import { CarePlanItemTemplateRecallResponse } from './model/care-plan-item-template-recall-response';

// @ts-ignore
import { CarePlanItemTemplateResponse } from './model/care-plan-item-template-response';

// @ts-ignore
import { CarePlanTemplateListResponse } from './model/care-plan-template-list-response';

// @ts-ignore
import { CarePlanTemplateResponse } from './model/care-plan-template-response';

// @ts-ignore
import { CategoryResponse } from './model/category-response';

// @ts-ignore
import { CcdaClinicalSummarySectionPreferencesResponse } from './model/ccda-clinical-summary-section-preferences-response';

// @ts-ignore
import { CcdaPatientRecordSectionPreferencesResponse } from './model/ccda-patient-record-section-preferences-response';

// @ts-ignore
import { CcdaSectionsPreferenceRequest } from './model/ccda-sections-preference-request';

// @ts-ignore
import { CcdaSectionsPreferenceResponse } from './model/ccda-sections-preference-response';

// @ts-ignore
import { ChangeEyefinityOrderRequest } from './model/change-eyefinity-order-request';

// @ts-ignore
import { ChangeEyefinityOrderResponse } from './model/change-eyefinity-order-response';

// @ts-ignore
import { ChangeOrderRequest } from './model/change-order-request';

// @ts-ignore
import { ChangeOrderResponse } from './model/change-order-response';

// @ts-ignore
import { CheckOdOsLocationProductIdsActiveRequest } from './model/check-od-os-location-product-ids-active-request';

// @ts-ignore
import { CheckoutTaskResponse } from './model/checkout-task-response';

// @ts-ignore
import { CityStateProvinceResponse } from './model/city-state-province-response';

// @ts-ignore
import { ClaimProcessingPreferencesResponse } from './model/claim-processing-preferences-response';

// @ts-ignore
import { ClaimSearchRequest } from './model/claim-search-request';

// @ts-ignore
import { ClaimSubmitErrorResponse } from './model/claim-submit-error-response';

// @ts-ignore
import { ClaimSubmitResponse } from './model/claim-submit-response';

// @ts-ignore
import { ClaimsInsuranceClaimResponse } from './model/claims-insurance-claim-response';

// @ts-ignore
import { ClaimsProcessorAttributeResponse } from './model/claims-processor-attribute-response';

// @ts-ignore
import { ClaimsSiteIdResponse } from './model/claims-site-id-response';

// @ts-ignore
import { CodeEncounterRequest } from './model/code-encounter-request';

// @ts-ignore
import { CollectionsInvoiceTransferSplitRequest } from './model/collections-invoice-transfer-split-request';

// @ts-ignore
import { CommunicationsPreferencesResponse } from './model/communications-preferences-response';

// @ts-ignore
import { ConditionRelatedToResponse } from './model/condition-related-to-response';

// @ts-ignore
import { ConfirmAppointmentRequest } from './model/confirm-appointment-request';

// @ts-ignore
import { ConnectCampaignListResponse } from './model/connect-campaign-list-response';

// @ts-ignore
import { ConnectCampaignMessageListResponse } from './model/connect-campaign-message-list-response';

// @ts-ignore
import { ConnectCampaignMessagePagedResponse } from './model/connect-campaign-message-paged-response';

// @ts-ignore
import { ConnectCampaignMessagePreviewRequest } from './model/connect-campaign-message-preview-request';

// @ts-ignore
import { ConnectCampaignPreviewResponse } from './model/connect-campaign-preview-response';

// @ts-ignore
import { ConnectCampaignResponse } from './model/connect-campaign-response';

// @ts-ignore
import { ConnectCampaignSendTestEmailRequest } from './model/connect-campaign-send-test-email-request';

// @ts-ignore
import { ConnectCampaignTemplateResponse } from './model/connect-campaign-template-response';

// @ts-ignore
import { ConnectMessageForPatientAppointmentRequest } from './model/connect-message-for-patient-appointment-request';

// @ts-ignore
import { ConnectMessageForPatientRecallRequest } from './model/connect-message-for-patient-recall-request';

// @ts-ignore
import { ConnectMessageForWaitingListRequest } from './model/connect-message-for-waiting-list-request';

// @ts-ignore
import { ConnectPracticeResponse } from './model/connect-practice-response';

// @ts-ignore
import { ConnectSummaryConnectMessageListResponse } from './model/connect-summary-connect-message-list-response';

// @ts-ignore
import { ConsentFormListResponse } from './model/consent-form-list-response';

// @ts-ignore
import { ConsentFormResponse } from './model/consent-form-response';

// @ts-ignore
import { ContactDemographicsRequest } from './model/contact-demographics-request';

// @ts-ignore
import { ContactInformationRequest } from './model/contact-information-request';

// @ts-ignore
import { ContactLensAgeReportResponse } from './model/contact-lens-age-report-response';

// @ts-ignore
import { ContactLensCostReportResponse } from './model/contact-lens-cost-report-response';

// @ts-ignore
import { ContactLensEyeHybridRequest } from './model/contact-lens-eye-hybrid-request';

// @ts-ignore
import { ContactLensEyeHybridResponse } from './model/contact-lens-eye-hybrid-response';

// @ts-ignore
import { ContactLensEyeOptionsResponse } from './model/contact-lens-eye-options-response';

// @ts-ignore
import { ContactLensEyeRequest } from './model/contact-lens-eye-request';

// @ts-ignore
import { ContactLensEyeResponse } from './model/contact-lens-eye-response';

// @ts-ignore
import { ContactLensEyeRgpRequest } from './model/contact-lens-eye-rgp-request';

// @ts-ignore
import { ContactLensEyeRgpResponse } from './model/contact-lens-eye-rgp-response';

// @ts-ignore
import { ContactLensEyeSoftRequest } from './model/contact-lens-eye-soft-request';

// @ts-ignore
import { ContactLensEyeSoftResponse } from './model/contact-lens-eye-soft-response';

// @ts-ignore
import { ContactLensHistoryResponse } from './model/contact-lens-history-response';

// @ts-ignore
import { ContactLensInventoryReportResponse } from './model/contact-lens-inventory-report-response';

// @ts-ignore
import { ContactLensOrderResponse } from './model/contact-lens-order-response';

// @ts-ignore
import { ContactLensOrderSearchResponse } from './model/contact-lens-order-search-response';

// @ts-ignore
import { ContactLensPrescriptionDetailsResponse } from './model/contact-lens-prescription-details-response';

// @ts-ignore
import { ContactLensPrescriptionLookupResponse } from './model/contact-lens-prescription-lookup-response';

// @ts-ignore
import { ContactLensPrescriptionResponse } from './model/contact-lens-prescription-response';

// @ts-ignore
import { ContactLensPropertyValuesResponse } from './model/contact-lens-property-values-response';

// @ts-ignore
import { ContactLensRxResponse } from './model/contact-lens-rx-response';

// @ts-ignore
import { ContactLensSalesReportResponse } from './model/contact-lens-sales-report-response';

// @ts-ignore
import { ContactLensTrialDetailResponse } from './model/contact-lens-trial-detail-response';

// @ts-ignore
import { ContactLensTrialListResponse } from './model/contact-lens-trial-list-response';

// @ts-ignore
import { ContactLensTrialOrderResponse } from './model/contact-lens-trial-order-response';

// @ts-ignore
import { ContactLensTrialResponse } from './model/contact-lens-trial-response';

// @ts-ignore
import { ContactListResponse } from './model/contact-list-response';

// @ts-ignore
import { ContactPersonNameResponse } from './model/contact-person-name-response';

// @ts-ignore
import { ContactResponse } from './model/contact-response';

// @ts-ignore
import { ContactWithEmergencyContactResponse } from './model/contact-with-emergency-contact-response';

// @ts-ignore
import { ContinuityOfCareImportResultResponse } from './model/continuity-of-care-import-result-response';

// @ts-ignore
import { CopyFeeScheduleRangeRequest } from './model/copy-fee-schedule-range-request';

// @ts-ignore
import { CqmReportRequest } from './model/cqm-report-request';

// @ts-ignore
import { CqmReportResponse } from './model/cqm-report-response';

// @ts-ignore
import { CreateAlertRequest } from './model/create-alert-request';

// @ts-ignore
import { CreateAllergyReviewHistoryRequest } from './model/create-allergy-review-history-request';

// @ts-ignore
import { CreateAmendmentRequest } from './model/create-amendment-request';

// @ts-ignore
import { CreateAncillaryParticipantRequest } from './model/create-ancillary-participant-request';

// @ts-ignore
import { CreateAppointmentRequest } from './model/create-appointment-request';

// @ts-ignore
import { CreateAppointmentSlotRequest } from './model/create-appointment-slot-request';

// @ts-ignore
import { CreateAssessmentDiagnosisReviewRequest } from './model/create-assessment-diagnosis-review-request';

// @ts-ignore
import { CreateAssessmentPersonDiagnosisRequest } from './model/create-assessment-person-diagnosis-request';

// @ts-ignore
import { CreateAttributeRequest } from './model/create-attribute-request';

// @ts-ignore
import { CreateBillingInvoiceRequest } from './model/create-billing-invoice-request';

// @ts-ignore
import { CreateBusyTimeRequest } from './model/create-busy-time-request';

// @ts-ignore
import { CreateCarePlanItemTemplateEducationRequest } from './model/create-care-plan-item-template-education-request';

// @ts-ignore
import { CreateCarePlanItemTemplateGeneralRequest } from './model/create-care-plan-item-template-general-request';

// @ts-ignore
import { CreateCarePlanItemTemplateGoalRequest } from './model/create-care-plan-item-template-goal-request';

// @ts-ignore
import { CreateCarePlanItemTemplateLabOrderRequest } from './model/create-care-plan-item-template-lab-order-request';

// @ts-ignore
import { CreateCarePlanItemTemplateMedicationPrescriptionRequest } from './model/create-care-plan-item-template-medication-prescription-request';

// @ts-ignore
import { CreateCarePlanItemTemplateRecallRequest } from './model/create-care-plan-item-template-recall-request';

// @ts-ignore
import { CreateCarePlanItemTemplateRequest } from './model/create-care-plan-item-template-request';

// @ts-ignore
import { CreateCarePlanTemplateRequest } from './model/create-care-plan-template-request';

// @ts-ignore
import { CreateCategoryRequest } from './model/create-category-request';

// @ts-ignore
import { CreateCheckoutTaskRequest } from './model/create-checkout-task-request';

// @ts-ignore
import { CreateConnectCampaignDraftRequest } from './model/create-connect-campaign-draft-request';

// @ts-ignore
import { CreateConnectCampaignScheduleRequest } from './model/create-connect-campaign-schedule-request';

// @ts-ignore
import { CreateConnectPracticeRequest } from './model/create-connect-practice-request';

// @ts-ignore
import { CreateContactLensOrderRequest } from './model/create-contact-lens-order-request';

// @ts-ignore
import { CreateContactLensPrescriptionRequest } from './model/create-contact-lens-prescription-request';

// @ts-ignore
import { CreateContactLensTrialOrderBasicRequest } from './model/create-contact-lens-trial-order-basic-request';

// @ts-ignore
import { CreateContactLensTrialOrderRequest } from './model/create-contact-lens-trial-order-request';

// @ts-ignore
import { CreateContactLensTrialRequest } from './model/create-contact-lens-trial-request';

// @ts-ignore
import { CreateContactRequest } from './model/create-contact-request';

// @ts-ignore
import { CreateDiagnosisReviewHistoryRequest } from './model/create-diagnosis-review-history-request';

// @ts-ignore
import { CreateDrugAllergyRequest } from './model/create-drug-allergy-request';

// @ts-ignore
import { CreateEmployeeOfficeHoursRequest } from './model/create-employee-office-hours-request';

// @ts-ignore
import { CreateEncounterRequest } from './model/create-encounter-request';

// @ts-ignore
import { CreateEncounterRoleRequest } from './model/create-encounter-role-request';

// @ts-ignore
import { CreateEncounterTemplateRequest } from './model/create-encounter-template-request';

// @ts-ignore
import { CreateExternalCredentialForPracticeLocationRequest } from './model/create-external-credential-for-practice-location-request';

// @ts-ignore
import { CreateExternalDataRequest } from './model/create-external-data-request';

// @ts-ignore
import { CreateExternalProviderRequest } from './model/create-external-provider-request';

// @ts-ignore
import { CreateEyeglassOrderRequest } from './model/create-eyeglass-order-request';

// @ts-ignore
import { CreateEyeglassPrescriptionBaseRequest } from './model/create-eyeglass-prescription-base-request';

// @ts-ignore
import { CreateEyeglassPrescriptionFromRefractionRequest } from './model/create-eyeglass-prescription-from-refraction-request';

// @ts-ignore
import { CreateEyeglassPrescriptionRequest } from './model/create-eyeglass-prescription-request';

// @ts-ignore
import { CreateEyeglassPrescriptionsRequest } from './model/create-eyeglass-prescriptions-request';

// @ts-ignore
import { CreateFamilyHealthHistoryRequest } from './model/create-family-health-history-request';

// @ts-ignore
import { CreateFamilyHealthHistoryReviewSetRequest } from './model/create-family-health-history-review-set-request';

// @ts-ignore
import { CreateFeatureFlagEmployeeRequest } from './model/create-feature-flag-employee-request';

// @ts-ignore
import { CreateFeeScheduleRangeRequest } from './model/create-fee-schedule-range-request';

// @ts-ignore
import { CreateFeeScheduleRequest } from './model/create-fee-schedule-request';

// @ts-ignore
import { CreateFinanceChargePlanPeriodRequest } from './model/create-finance-charge-plan-period-request';

// @ts-ignore
import { CreateFinanceChargePlanRequest } from './model/create-finance-charge-plan-request';

// @ts-ignore
import { CreateFolderRequest } from './model/create-folder-request';

// @ts-ignore
import { CreateFrameOrderRequest } from './model/create-frame-order-request';

// @ts-ignore
import { CreateFramesDataSubscriptionRequest } from './model/create-frames-data-subscription-request';

// @ts-ignore
import { CreateGeneralMedicationPrescriptionRequest } from './model/create-general-medication-prescription-request';

// @ts-ignore
import { CreateGuestInvoiceRequest } from './model/create-guest-invoice-request';

// @ts-ignore
import { CreateInterviewTemplateRequest } from './model/create-interview-template-request';

// @ts-ignore
import { CreateInvoiceRequest } from './model/create-invoice-request';

// @ts-ignore
import { CreateItemQuantityChangePurchaseOrderRequest } from './model/create-item-quantity-change-purchase-order-request';

// @ts-ignore
import { CreateItemQuantityChangeRequest } from './model/create-item-quantity-change-request';

// @ts-ignore
import { CreateLabOrderRequest } from './model/create-lab-order-request';

// @ts-ignore
import { CreateManufacturerRequest } from './model/create-manufacturer-request';

// @ts-ignore
import { CreateMedicationPrescriptionRequest } from './model/create-medication-prescription-request';

// @ts-ignore
import { CreateMedicationReviewHistoryRequest } from './model/create-medication-review-history-request';

// @ts-ignore
import { CreateMessageFolderRequest } from './model/create-message-folder-request';

// @ts-ignore
import { CreateMessageParticipantRequest } from './model/create-message-participant-request';

// @ts-ignore
import { CreateMessageRequest } from './model/create-message-request';

// @ts-ignore
import { CreateNoteRequest } from './model/create-note-request';

// @ts-ignore
import { CreateOfficeClosedRequest } from './model/create-office-closed-request';

// @ts-ignore
import { CreateOpenEdgeCardReaderRequest } from './model/create-open-edge-card-reader-request';

// @ts-ignore
import { CreateOpenEdgeSettingsRequest } from './model/create-open-edge-settings-request';

// @ts-ignore
import { CreateOrUpdateHistoryDiagnosisCarePlanItemRequest } from './model/create-or-update-history-diagnosis-care-plan-item-request';

// @ts-ignore
import { CreateOrderFromEyeglassPrescriptionRequest } from './model/create-order-from-eyeglass-prescription-request';

// @ts-ignore
import { CreateOrderRequest } from './model/create-order-request';

// @ts-ignore
import { CreateOtherAllergyRequest } from './model/create-other-allergy-request';

// @ts-ignore
import { CreatePatientBasicInformationRequest } from './model/create-patient-basic-information-request';

// @ts-ignore
import { CreatePatientCommunicationPreferencesRequest } from './model/create-patient-communication-preferences-request';

// @ts-ignore
import { CreatePatientConsentRequest } from './model/create-patient-consent-request';

// @ts-ignore
import { CreatePatientCreditRequest } from './model/create-patient-credit-request';

// @ts-ignore
import { CreatePatientFamilyDemographicsRequest } from './model/create-patient-family-demographics-request';

// @ts-ignore
import { CreatePatientFamilyInformationRequest } from './model/create-patient-family-information-request';

// @ts-ignore
import { CreatePatientFamilyInsuranceRequest } from './model/create-patient-family-insurance-request';

// @ts-ignore
import { CreatePatientFileRequest } from './model/create-patient-file-request';

// @ts-ignore
import { CreatePatientGeneralBenefitsRequest } from './model/create-patient-general-benefits-request';

// @ts-ignore
import { CreatePatientHealthConcernRequest } from './model/create-patient-health-concern-request';

// @ts-ignore
import { CreatePatientHealthGoalRequest } from './model/create-patient-health-goal-request';

// @ts-ignore
import { CreatePatientImplantableDeviceRequest } from './model/create-patient-implantable-device-request';

// @ts-ignore
import { CreatePatientInsuranceBasicInformationRequest } from './model/create-patient-insurance-basic-information-request';

// @ts-ignore
import { CreatePatientMaterialBenefitsRequest } from './model/create-patient-material-benefits-request';

// @ts-ignore
import { CreatePatientOngoingCareRequest } from './model/create-patient-ongoing-care-request';

// @ts-ignore
import { CreatePatientOtherInformationRequest } from './model/create-patient-other-information-request';

// @ts-ignore
import { CreatePatientPharmacyRequest } from './model/create-patient-pharmacy-request';

// @ts-ignore
import { CreatePatientPhoneInformationRequest } from './model/create-patient-phone-information-request';

// @ts-ignore
import { CreatePatientProviderRequest } from './model/create-patient-provider-request';

// @ts-ignore
import { CreatePatientRecallRequest } from './model/create-patient-recall-request';

// @ts-ignore
import { CreatePatientRequest } from './model/create-patient-request';

// @ts-ignore
import { CreatePatientTargetIopRequest } from './model/create-patient-target-iop-request';

// @ts-ignore
import { CreatePatientVaccinationRequest } from './model/create-patient-vaccination-request';

// @ts-ignore
import { CreatePaymentGroupRequest } from './model/create-payment-group-request';

// @ts-ignore
import { CreatePaymentRequest } from './model/create-payment-request';

// @ts-ignore
import { CreatePersonDiagnosisRequest } from './model/create-person-diagnosis-request';

// @ts-ignore
import { CreatePersonInsuranceRequest } from './model/create-person-insurance-request';

// @ts-ignore
import { CreatePharmacyRequest } from './model/create-pharmacy-request';

// @ts-ignore
import { CreatePracticeConsentFormRequest } from './model/create-practice-consent-form-request';

// @ts-ignore
import { CreatePracticeDiagnosisRequest } from './model/create-practice-diagnosis-request';

// @ts-ignore
import { CreatePracticeDrugRequest } from './model/create-practice-drug-request';

// @ts-ignore
import { CreatePracticeInsuranceCompanyModalRequest } from './model/create-practice-insurance-company-modal-request';

// @ts-ignore
import { CreatePracticeInsuranceCompanyRequest } from './model/create-practice-insurance-company-request';

// @ts-ignore
import { CreatePracticeInsuranceRemittanceCodeAdjustmentRequest } from './model/create-practice-insurance-remittance-code-adjustment-request';

// @ts-ignore
import { CreatePracticeRemittanceCodeAdjustmentRequest } from './model/create-practice-remittance-code-adjustment-request';

// @ts-ignore
import { CreatePrescriptionOrderRequest } from './model/create-prescription-order-request';

// @ts-ignore
import { CreatePresentIllnessRequest } from './model/create-present-illness-request';

// @ts-ignore
import { CreatePresentIllnessesFromDiagnosesRequest } from './model/create-present-illnesses-from-diagnoses-request';

// @ts-ignore
import { CreateProductBrandCollectionRequest } from './model/create-product-brand-collection-request';

// @ts-ignore
import { CreateProductBrandRequest } from './model/create-product-brand-request';

// @ts-ignore
import { CreateProfessionalTimeAdjustmentRequest } from './model/create-professional-time-adjustment-request';

// @ts-ignore
import { CreateProviderInsuranceIdentifierRequest } from './model/create-provider-insurance-identifier-request';

// @ts-ignore
import { CreateQueryRequest } from './model/create-query-request';

// @ts-ignore
import { CreateQueryTriggerRequest } from './model/create-query-trigger-request';

// @ts-ignore
import { CreateRecurPatternDailyRequest } from './model/create-recur-pattern-daily-request';

// @ts-ignore
import { CreateRecurPatternMonthlyRequest } from './model/create-recur-pattern-monthly-request';

// @ts-ignore
import { CreateRecurPatternWeeklyRequest } from './model/create-recur-pattern-weekly-request';

// @ts-ignore
import { CreateRecurPatternYearlyRequest } from './model/create-recur-pattern-yearly-request';

// @ts-ignore
import { CreateRecurringDefinitionRequest } from './model/create-recurring-definition-request';

// @ts-ignore
import { CreateReferenceDataRequest } from './model/create-reference-data-request';

// @ts-ignore
import { CreateRefundPatientRequest } from './model/create-refund-patient-request';

// @ts-ignore
import { CreateRelatedCategoryRequest } from './model/create-related-category-request';

// @ts-ignore
import { CreateRescheduledAppointmentRequest } from './model/create-rescheduled-appointment-request';

// @ts-ignore
import { CreateRevClearExternalCredentialRequest } from './model/create-rev-clear-external-credential-request';

// @ts-ignore
import { CreateSalesRepresentativeRequest } from './model/create-sales-representative-request';

// @ts-ignore
import { CreateSurgeryHistoryRequest } from './model/create-surgery-history-request';

// @ts-ignore
import { CreateThirdPartyAdministratorRequest } from './model/create-third-party-administrator-request';

// @ts-ignore
import { CreateTrizettoExternalCredentialRequest } from './model/create-trizetto-external-credential-request';

// @ts-ignore
import { CreateTwoWayTextConversationRequest } from './model/create-two-way-text-conversation-request';

// @ts-ignore
import { CreateTwoWayTextMessageRequest } from './model/create-two-way-text-message-request';

// @ts-ignore
import { CreateUserRoleRequest } from './model/create-user-role-request';

// @ts-ignore
import { CreateVendorLocationConfigRequest } from './model/create-vendor-location-config-request';

// @ts-ignore
import { CreateVendorRequest } from './model/create-vendor-request';

// @ts-ignore
import { CreateWaitingListRequest } from './model/create-waiting-list-request';

// @ts-ignore
import { CreateWebServiceUserRequest } from './model/create-web-service-user-request';

// @ts-ignore
import { CreateWorkflowStepTemplateRequest } from './model/create-workflow-step-template-request';

// @ts-ignore
import { CreditInsuranceResponse } from './model/credit-insurance-response';

// @ts-ignore
import { CreditPatientResponse } from './model/credit-patient-response';

// @ts-ignore
import { CustomFilterPreferencesResponse } from './model/custom-filter-preferences-response';

// @ts-ignore
import { CustomerAgreementsResponse } from './model/customer-agreements-response';

// @ts-ignore
import { CustomerContactsResponse } from './model/customer-contacts-response';

// @ts-ignore
import { CustomerContactsUpdateRequest } from './model/customer-contacts-update-request';

// @ts-ignore
import { CustomerSmartflowAgreementsResponse } from './model/customer-smartflow-agreements-response';

// @ts-ignore
import { DailyOfficeHoursAdminResponse } from './model/daily-office-hours-admin-response';

// @ts-ignore
import { DailyOfficeHoursResponse } from './model/daily-office-hours-response';

// @ts-ignore
import { DataLogDiffResponse } from './model/data-log-diff-response';

// @ts-ignore
import { DeceasePatientRequest } from './model/decease-patient-request';

// @ts-ignore
import { DeleteClaimsRequest } from './model/delete-claims-request';

// @ts-ignore
import { DeleteContactRequest } from './model/delete-contact-request';

// @ts-ignore
import { DeleteFolderRequest } from './model/delete-folder-request';

// @ts-ignore
import { DeleteMessageParticipantPersonsRequest } from './model/delete-message-participant-persons-request';

// @ts-ignore
import { DeleteProviderInsuranceIdentifierRequest } from './model/delete-provider-insurance-identifier-request';

// @ts-ignore
import { DeleteScheduleItemRequest } from './model/delete-schedule-item-request';

// @ts-ignore
import { DepositSlipSearchRequest } from './model/deposit-slip-search-request';

// @ts-ignore
import { DepositSlipSearchResponse } from './model/deposit-slip-search-response';

// @ts-ignore
import { DepositsReportRequest } from './model/deposits-report-request';

// @ts-ignore
import { DepositsReportResponse } from './model/deposits-report-response';

// @ts-ignore
import { DiagnosisReviewHistoryResponse } from './model/diagnosis-review-history-response';

// @ts-ignore
import { DiagnosisSearchRequest } from './model/diagnosis-search-request';

// @ts-ignore
import { DiagnosisSearchResponse } from './model/diagnosis-search-response';

// @ts-ignore
import { DispenseOrderRequest } from './model/dispense-order-request';

// @ts-ignore
import { DrugAllergyImportResponse } from './model/drug-allergy-import-response';

// @ts-ignore
import { DrugAllergyResponse } from './model/drug-allergy-response';

// @ts-ignore
import { DrugClassResponse } from './model/drug-class-response';

// @ts-ignore
import { DrugSearchRequest } from './model/drug-search-request';

// @ts-ignore
import { DrugSearchResponse } from './model/drug-search-response';

// @ts-ignore
import { DuplicateOrderResponse } from './model/duplicate-order-response';

// @ts-ignore
import { DurationResponse } from './model/duration-response';

// @ts-ignore
import { DynamicScreenResponse } from './model/dynamic-screen-response';

// @ts-ignore
import { DynamicTestHistoryResponse } from './model/dynamic-test-history-response';

// @ts-ignore
import { DynamicTestResponse } from './model/dynamic-test-response';

// @ts-ignore
import { EClaimsInsuranceClaimResponse } from './model/e-claims-insurance-claim-response';

// @ts-ignore
import { EClaimsSearchRequest } from './model/e-claims-search-request';

// @ts-ignore
import { EmergencyContactStatusResponse } from './model/emergency-contact-status-response';

// @ts-ignore
import { EmployeeCanSignAgreementsResponse } from './model/employee-can-sign-agreements-response';

// @ts-ignore
import { EmployeeEditProfileRequest } from './model/employee-edit-profile-request';

// @ts-ignore
import { EmployeeEditProfileResponse } from './model/employee-edit-profile-response';

// @ts-ignore
import { EmployeeEmailResponse } from './model/employee-email-response';

// @ts-ignore
import { EmployeeIsCustomerContactResponse } from './model/employee-is-customer-contact-response';

// @ts-ignore
import { EmployeeNameResponse } from './model/employee-name-response';

// @ts-ignore
import { EmployeeOfficeHoursCalendarResponse } from './model/employee-office-hours-calendar-response';

// @ts-ignore
import { EmployeeOfficeHoursResponse } from './model/employee-office-hours-response';

// @ts-ignore
import { EmployeeOfficeHoursSidebarResponse } from './model/employee-office-hours-sidebar-response';

// @ts-ignore
import { EmployeePreferencesRequest } from './model/employee-preferences-request';

// @ts-ignore
import { EmployeePreferencesResponse } from './model/employee-preferences-response';

// @ts-ignore
import { EmployeeResponse } from './model/employee-response';

// @ts-ignore
import { EmployeeWithSecurityRolesResponse } from './model/employee-with-security-roles-response';

// @ts-ignore
import { EncounterAddCarePlanItemsToPersonDiagnosisRequest } from './model/encounter-add-care-plan-items-to-person-diagnosis-request';

// @ts-ignore
import { EncounterAddCarePlanTemplateToPersonDiagnosesRequest } from './model/encounter-add-care-plan-template-to-person-diagnoses-request';

// @ts-ignore
import { EncounterAutoCodePreviewResponse } from './model/encounter-auto-code-preview-response';

// @ts-ignore
import { EncounterCarePlanItemEducationResponse } from './model/encounter-care-plan-item-education-response';

// @ts-ignore
import { EncounterCarePlanItemGeneralResponse } from './model/encounter-care-plan-item-general-response';

// @ts-ignore
import { EncounterContactLensTrialRequest } from './model/encounter-contact-lens-trial-request';

// @ts-ignore
import { EncounterCreateCarePlanItemForPersonDiagnosisRequest } from './model/encounter-create-care-plan-item-for-person-diagnosis-request';

// @ts-ignore
import { EncounterCreateCarePlanItemsForPersonDiagnosesRequest } from './model/encounter-create-care-plan-items-for-person-diagnoses-request';

// @ts-ignore
import { EncounterCreateContactLensTrialRequest } from './model/encounter-create-contact-lens-trial-request';

// @ts-ignore
import { EncounterMedicalDecisionMakingCodingResponse } from './model/encounter-medical-decision-making-coding-response';

// @ts-ignore
import { EncounterProfessionalTimeCodingResponse } from './model/encounter-professional-time-coding-response';

// @ts-ignore
import { EncounterPullForwardTableResponse } from './model/encounter-pull-forward-table-response';

// @ts-ignore
import { EncounterReportRequest } from './model/encounter-report-request';

// @ts-ignore
import { EncounterReportResponse } from './model/encounter-report-response';

// @ts-ignore
import { EncounterResponse } from './model/encounter-response';

// @ts-ignore
import { EncounterReviewDetailResponse } from './model/encounter-review-detail-response';

// @ts-ignore
import { EncounterReviewGeneralInformationResponse } from './model/encounter-review-general-information-response';

// @ts-ignore
import { EncounterReviewGroupResponse } from './model/encounter-review-group-response';

// @ts-ignore
import { EncounterRoleResponse } from './model/encounter-role-response';

// @ts-ignore
import { EncounterStatusesResponse } from './model/encounter-statuses-response';

// @ts-ignore
import { EncounterStopCarePlanItemForPersonDiagnosisRequest } from './model/encounter-stop-care-plan-item-for-person-diagnosis-request';

// @ts-ignore
import { EncounterTableResponse } from './model/encounter-table-response';

// @ts-ignore
import { EncounterTemplateListResponse } from './model/encounter-template-list-response';

// @ts-ignore
import { EncounterTemplateResponse } from './model/encounter-template-response';

// @ts-ignore
import { EncounterUpdateCarePlanItemEducationRequest } from './model/encounter-update-care-plan-item-education-request';

// @ts-ignore
import { EncounterUpdateCarePlanItemGeneralRequest } from './model/encounter-update-care-plan-item-general-request';

// @ts-ignore
import { EncounterUpdateCarePlanItemRecallRequest } from './model/encounter-update-care-plan-item-recall-request';

// @ts-ignore
import { EncounterUpdateContactLensTrialRequest } from './model/encounter-update-contact-lens-trial-request';

// @ts-ignore
import { EncounterValidationResponse } from './model/encounter-validation-response';

// @ts-ignore
import { ExternalCredentialLocationRequest } from './model/external-credential-location-request';

// @ts-ignore
import { ExternalCredentialLocationResponse } from './model/external-credential-location-response';

// @ts-ignore
import { ExternalDataListResponse } from './model/external-data-list-response';

// @ts-ignore
import { ExternalDataResponse } from './model/external-data-response';

// @ts-ignore
import { ExternalMedicationPrescriptionResponse } from './model/external-medication-prescription-response';

// @ts-ignore
import { ExternalProviderDropdownResponse } from './model/external-provider-dropdown-response';

// @ts-ignore
import { ExternalProviderListResponse } from './model/external-provider-list-response';

// @ts-ignore
import { ExternalProviderResponse } from './model/external-provider-response';

// @ts-ignore
import { EyefinityContactLensOrderResponse } from './model/eyefinity-contact-lens-order-response';

// @ts-ignore
import { EyefinityEyeglassOrderResponse } from './model/eyefinity-eyeglass-order-response';

// @ts-ignore
import { EyefinityFrameOrderResponse } from './model/eyefinity-frame-order-response';

// @ts-ignore
import { EyefinityListItemRequest } from './model/eyefinity-list-item-request';

// @ts-ignore
import { EyefinityListItemResponse } from './model/eyefinity-list-item-response';

// @ts-ignore
import { EyefinityOrderItemRequest } from './model/eyefinity-order-item-request';

// @ts-ignore
import { EyefinityOrderItemResponse } from './model/eyefinity-order-item-response';

// @ts-ignore
import { EyefinityOrderResponse } from './model/eyefinity-order-response';

// @ts-ignore
import { EyeglassFrameAgeReportResponse } from './model/eyeglass-frame-age-report-response';

// @ts-ignore
import { EyeglassFrameCostReportResponse } from './model/eyeglass-frame-cost-report-response';

// @ts-ignore
import { EyeglassFrameInventoryReportResponse } from './model/eyeglass-frame-inventory-report-response';

// @ts-ignore
import { EyeglassFrameSalesReportResponse } from './model/eyeglass-frame-sales-report-response';

// @ts-ignore
import { EyeglassLensMeasurementResponse } from './model/eyeglass-lens-measurement-response';

// @ts-ignore
import { EyeglassLensOrderDetailResponse } from './model/eyeglass-lens-order-detail-response';

// @ts-ignore
import { EyeglassOrderResponse } from './model/eyeglass-order-response';

// @ts-ignore
import { EyeglassOrderSearchResponse } from './model/eyeglass-order-search-response';

// @ts-ignore
import { EyeglassPrescriptionEyeRequest } from './model/eyeglass-prescription-eye-request';

// @ts-ignore
import { EyeglassPrescriptionEyeResponse } from './model/eyeglass-prescription-eye-response';

// @ts-ignore
import { EyeglassPrescriptionListResponse } from './model/eyeglass-prescription-list-response';

// @ts-ignore
import { EyeglassPrescriptionResponse } from './model/eyeglass-prescription-response';

// @ts-ignore
import { FamilyAccountMemberDetailResponse } from './model/family-account-member-detail-response';

// @ts-ignore
import { FamilyAccountMembersDetailResponse } from './model/family-account-members-detail-response';

// @ts-ignore
import { FamilyCreditRequest } from './model/family-credit-request';

// @ts-ignore
import { FamilyDiffResponse } from './model/family-diff-response';

// @ts-ignore
import { FamilyHealthHistoryResponse } from './model/family-health-history-response';

// @ts-ignore
import { FamilyHealthHistoryReviewSetResponse } from './model/family-health-history-review-set-response';

// @ts-ignore
import { FamilyHistoryRequest } from './model/family-history-request';

// @ts-ignore
import { FamilyHistoryResponse } from './model/family-history-response';

// @ts-ignore
import { FamilyMemberContactInformationRequest } from './model/family-member-contact-information-request';

// @ts-ignore
import { FamilyRefundRequest } from './model/family-refund-request';

// @ts-ignore
import { FeatureFlagCountForToasterResponse } from './model/feature-flag-count-for-toaster-response';

// @ts-ignore
import { FeatureFlagDetailResponse } from './model/feature-flag-detail-response';

// @ts-ignore
import { FeatureFlagEmployeeSummaryResponse } from './model/feature-flag-employee-summary-response';

// @ts-ignore
import { FeatureFlagSummaryResponse } from './model/feature-flag-summary-response';

// @ts-ignore
import { FeeScheduleDropdownResponse } from './model/fee-schedule-dropdown-response';

// @ts-ignore
import { FeeScheduleItemProductResponse } from './model/fee-schedule-item-product-response';

// @ts-ignore
import { FeeScheduleItemServiceCptResponse } from './model/fee-schedule-item-service-cpt-response';

// @ts-ignore
import { FeeScheduleItemServiceOtherResponse } from './model/fee-schedule-item-service-other-response';

// @ts-ignore
import { FeeScheduleListResponse } from './model/fee-schedule-list-response';

// @ts-ignore
import { FeeScheduleRangeResponse } from './model/fee-schedule-range-response';

// @ts-ignore
import { FeeScheduleResponse } from './model/fee-schedule-response';

// @ts-ignore
import { FieldDefinitionFamilyHistoryResponse } from './model/field-definition-family-history-response';

// @ts-ignore
import { FieldDefinitionHistoryOfPresentIllnessResponse } from './model/field-definition-history-of-present-illness-response';

// @ts-ignore
import { FieldDefinitionOcularHistoryResponse } from './model/field-definition-ocular-history-response';

// @ts-ignore
import { FieldDefinitionReasonForVisitResponse } from './model/field-definition-reason-for-visit-response';

// @ts-ignore
import { FieldDefinitionResponse } from './model/field-definition-response';

// @ts-ignore
import { FieldDefinitionReviewOfSystemsResponse } from './model/field-definition-review-of-systems-response';

// @ts-ignore
import { FieldOptionResponse } from './model/field-option-response';

// @ts-ignore
import { FieldValueBooleanRequest } from './model/field-value-boolean-request';

// @ts-ignore
import { FieldValueBooleanResponse } from './model/field-value-boolean-response';

// @ts-ignore
import { FieldValueDateRequest } from './model/field-value-date-request';

// @ts-ignore
import { FieldValueDateResponse } from './model/field-value-date-response';

// @ts-ignore
import { FieldValueDecimalRequest } from './model/field-value-decimal-request';

// @ts-ignore
import { FieldValueDecimalResponse } from './model/field-value-decimal-response';

// @ts-ignore
import { FieldValueLargeStringRequest } from './model/field-value-large-string-request';

// @ts-ignore
import { FieldValueLargeStringResponse } from './model/field-value-large-string-response';

// @ts-ignore
import { FieldValueMultiSelectionDiffResponse } from './model/field-value-multi-selection-diff-response';

// @ts-ignore
import { FieldValueMultiSelectionRequest } from './model/field-value-multi-selection-request';

// @ts-ignore
import { FieldValueMultiSelectionResponse } from './model/field-value-multi-selection-response';

// @ts-ignore
import { FieldValueNumberRequest } from './model/field-value-number-request';

// @ts-ignore
import { FieldValueNumberResponse } from './model/field-value-number-response';

// @ts-ignore
import { FieldValueRequest } from './model/field-value-request';

// @ts-ignore
import { FieldValueResponse } from './model/field-value-response';

// @ts-ignore
import { FieldValueSelectionRequest } from './model/field-value-selection-request';

// @ts-ignore
import { FieldValueSelectionResponse } from './model/field-value-selection-response';

// @ts-ignore
import { FieldValueSmallStringRequest } from './model/field-value-small-string-request';

// @ts-ignore
import { FieldValueSmallStringResponse } from './model/field-value-small-string-response';

// @ts-ignore
import { FileFolderResponse } from './model/file-folder-response';

// @ts-ignore
import { FileInfoResponse } from './model/file-info-response';

// @ts-ignore
import { FinanceChargePlanNameRequest } from './model/finance-charge-plan-name-request';

// @ts-ignore
import { FinanceChargePlanNameResponse } from './model/finance-charge-plan-name-response';

// @ts-ignore
import { FinanceChargePlanPeriodResponse } from './model/finance-charge-plan-period-response';

// @ts-ignore
import { FinanceChargePlanResponse } from './model/finance-charge-plan-response';

// @ts-ignore
import { FindActiveLocationProductsRequest } from './model/find-active-location-products-request';

// @ts-ignore
import { FindActivePracticeDiagnosisForAssessmentRequest } from './model/find-active-practice-diagnosis-for-assessment-request';

// @ts-ignore
import { FindAnonymousPaymentInvoicesRequest } from './model/find-anonymous-payment-invoices-request';

// @ts-ignore
import { FindCampaignsForPracticeRequest } from './model/find-campaigns-for-practice-request';

// @ts-ignore
import { FindCarePlanItemsByCategoryRequest } from './model/find-care-plan-items-by-category-request';

// @ts-ignore
import { FindCategoriesRequest } from './model/find-categories-request';

// @ts-ignore
import { FindCollectionsPaymentInvoicesRequest } from './model/find-collections-payment-invoices-request';

// @ts-ignore
import { FindDynamicTestHistoryRequest } from './model/find-dynamic-test-history-request';

// @ts-ignore
import { FindEyefinityOrderVendorsRequest } from './model/find-eyefinity-order-vendors-request';

// @ts-ignore
import { FindFamilyHealthHistoryReviewSetListForPatientEncounterRequest } from './model/find-family-health-history-review-set-list-for-patient-encounter-request';

// @ts-ignore
import { FindFamilyHealthHistoryReviewSetListForPatientRequest } from './model/find-family-health-history-review-set-list-for-patient-request';

// @ts-ignore
import { FindInsurancePaymentInvoicesRequest } from './model/find-insurance-payment-invoices-request';

// @ts-ignore
import { FindManualVendorsRequest } from './model/find-manual-vendors-request';

// @ts-ignore
import { FindNonPagedConnectCampaignMessagesRequest } from './model/find-non-paged-connect-campaign-messages-request';

// @ts-ignore
import { FindNotesByParentEntityRequest } from './model/find-notes-by-parent-entity-request';

// @ts-ignore
import { FindOrderProcessorsRequest } from './model/find-order-processors-request';

// @ts-ignore
import { FindPagedConnectCampaignMessagesRequest } from './model/find-paged-connect-campaign-messages-request';

// @ts-ignore
import { FindPatientPaymentInvoicesRequest } from './model/find-patient-payment-invoices-request';

// @ts-ignore
import { FindPracticeDiagnosesRequest } from './model/find-practice-diagnoses-request';

// @ts-ignore
import { FindQueriesRequest } from './model/find-queries-request';

// @ts-ignore
import { FindQueryTriggersRequest } from './model/find-query-triggers-request';

// @ts-ignore
import { FindRelatedCategoriesRequest } from './model/find-related-categories-request';

// @ts-ignore
import { FindScheduleItemRequest } from './model/find-schedule-item-request';

// @ts-ignore
import { FindScheduleItemsByIdRequest } from './model/find-schedule-items-by-id-request';

// @ts-ignore
import { FindSmartflowOrderVendorsRequest } from './model/find-smartflow-order-vendors-request';

// @ts-ignore
import { FindTodaysPatientsAppointmentsForProviderRequest } from './model/find-todays-patients-appointments-for-provider-request';

// @ts-ignore
import { FindTwoWayTextConversationMessageUpdatesRequest } from './model/find-two-way-text-conversation-message-updates-request';

// @ts-ignore
import { FindTwoWayTextConversationUpdatesRequest } from './model/find-two-way-text-conversation-updates-request';

// @ts-ignore
import { FindUnassignedDeviceMeasurementsRequest } from './model/find-unassigned-device-measurements-request';

// @ts-ignore
import { FindVCodeRequest } from './model/find-v-code-request';

// @ts-ignore
import { FindWorkflowScreenRequest } from './model/find-workflow-screen-request';

// @ts-ignore
import { FindWorkflowScreensRequest } from './model/find-workflow-screens-request';

// @ts-ignore
import { FormComponentPositionResponse } from './model/form-component-position-response';

// @ts-ignore
import { FormComponentResponse } from './model/form-component-response';

// @ts-ignore
import { FormFieldResponse } from './model/form-field-response';

// @ts-ignore
import { FormFieldSelectionResponse } from './model/form-field-selection-response';

// @ts-ignore
import { FormFieldStepperResponse } from './model/form-field-stepper-response';

// @ts-ignore
import { FormLabelResponse } from './model/form-label-response';

// @ts-ignore
import { FormPositionResponse } from './model/form-position-response';

// @ts-ignore
import { ForwardDataResponse } from './model/forward-data-response';

// @ts-ignore
import { FrameColorResponse } from './model/frame-color-response';

// @ts-ignore
import { FrameOrderResponse } from './model/frame-order-response';

// @ts-ignore
import { FrameSizeResponse } from './model/frame-size-response';

// @ts-ignore
import { FramesDataSubscriptionResponse } from './model/frames-data-subscription-response';

// @ts-ignore
import { FullSsnResponse } from './model/full-ssn-response';

// @ts-ignore
import { GenerateClinicalSummaryRequest } from './model/generate-clinical-summary-request';

// @ts-ignore
import { GetLatestFamilyHealthHistoryForPatientRequest } from './model/get-latest-family-health-history-for-patient-request';

// @ts-ignore
import { GetPracticeLaunchUrlRequest } from './model/get-practice-launch-url-request';

// @ts-ignore
import { GlaucomaCareMedicationPrescriptionResponse } from './model/glaucoma-care-medication-prescription-response';

// @ts-ignore
import { GlaucomaCareResponse } from './model/glaucoma-care-response';

// @ts-ignore
import { GlaucomaCareServiceHistoryResponse } from './model/glaucoma-care-service-history-response';

// @ts-ignore
import { GlaucomaDataResponse } from './model/glaucoma-data-response';

// @ts-ignore
import { GrantCreditsRequest } from './model/grant-credits-request';

// @ts-ignore
import { GuarantorNameResponse } from './model/guarantor-name-response';

// @ts-ignore
import { HistoryDiagnosisCarePlanItemResponse } from './model/history-diagnosis-care-plan-item-response';

// @ts-ignore
import { Hl7ValueSetResponse } from './model/hl7-value-set-response';

// @ts-ignore
import { HumanReadableInsuranceRemittanceResponse } from './model/human-readable-insurance-remittance-response';

// @ts-ignore
import { ImportMasterQueriesRequest } from './model/import-master-queries-request';

// @ts-ignore
import { ImportResultResponse } from './model/import-result-response';

// @ts-ignore
import { IncorporateDrugAllergyRequest } from './model/incorporate-drug-allergy-request';

// @ts-ignore
import { IncorporateDrugAllergyUpdateRequest } from './model/incorporate-drug-allergy-update-request';

// @ts-ignore
import { IncorporateExternalDataRequest } from './model/incorporate-external-data-request';

// @ts-ignore
import { IncorporateMedicationPrescriptionRequest } from './model/incorporate-medication-prescription-request';

// @ts-ignore
import { IncorporatePersonDiagnosisRequest } from './model/incorporate-person-diagnosis-request';

// @ts-ignore
import { InsuranceClaimProcessorResponse } from './model/insurance-claim-processor-response';

// @ts-ignore
import { InsuranceClaimStatusResponse } from './model/insurance-claim-status-response';

// @ts-ignore
import { InsuranceCoveragesResponse } from './model/insurance-coverages-response';

// @ts-ignore
import { InsuranceInvoiceTransferSplitRequest } from './model/insurance-invoice-transfer-split-request';

// @ts-ignore
import { InsuranceRefundRequest } from './model/insurance-refund-request';

// @ts-ignore
import { IntakeAllergyListResponse } from './model/intake-allergy-list-response';

// @ts-ignore
import { IntakeAllergyResponse } from './model/intake-allergy-response';

// @ts-ignore
import { IntakeInsuranceListResponse } from './model/intake-insurance-list-response';

// @ts-ignore
import { IntakeInsuranceResponse } from './model/intake-insurance-response';

// @ts-ignore
import { IntakeMedicationListResponse } from './model/intake-medication-list-response';

// @ts-ignore
import { IntakeMedicationResponse } from './model/intake-medication-response';

// @ts-ignore
import { InterviewHistoryResponse } from './model/interview-history-response';

// @ts-ignore
import { InterviewResponse } from './model/interview-response';

// @ts-ignore
import { InterviewTemplateDetailResponse } from './model/interview-template-detail-response';

// @ts-ignore
import { InterviewTemplateResponse } from './model/interview-template-response';

// @ts-ignore
import { InterviewTemplateSummaryResponse } from './model/interview-template-summary-response';

// @ts-ignore
import { InventoryServiceResponse } from './model/inventory-service-response';

// @ts-ignore
import { InvoiceAppointmentResponse } from './model/invoice-appointment-response';

// @ts-ignore
import { InvoiceDashboardResponse } from './model/invoice-dashboard-response';

// @ts-ignore
import { InvoiceDashboardSearchRequest } from './model/invoice-dashboard-search-request';

// @ts-ignore
import { InvoiceDateResponse } from './model/invoice-date-response';

// @ts-ignore
import { InvoiceDiagnosesRequest } from './model/invoice-diagnoses-request';

// @ts-ignore
import { InvoiceInsuranceClaimResponse } from './model/invoice-insurance-claim-response';

// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './model/invoice-item-adjustment-response';

// @ts-ignore
import { InvoiceItemDetailResponse } from './model/invoice-item-detail-response';

// @ts-ignore
import { InvoiceItemDiagnosisResponse } from './model/invoice-item-diagnosis-response';

// @ts-ignore
import { InvoiceItemListResponse } from './model/invoice-item-list-response';

// @ts-ignore
import { InvoiceItemModifierResponse } from './model/invoice-item-modifier-response';

// @ts-ignore
import { InvoiceItemPaymentRequest } from './model/invoice-item-payment-request';

// @ts-ignore
import { InvoiceItemPaymentResponse } from './model/invoice-item-payment-response';

// @ts-ignore
import { InvoiceItemPersonDiagnosisResponse } from './model/invoice-item-person-diagnosis-response';

// @ts-ignore
import { InvoiceItemPracticeDiagnosisResponse } from './model/invoice-item-practice-diagnosis-response';

// @ts-ignore
import { InvoiceItemResponse } from './model/invoice-item-response';

// @ts-ignore
import { InvoiceItemTaxResponse } from './model/invoice-item-tax-response';

// @ts-ignore
import { InvoiceResponse } from './model/invoice-response';

// @ts-ignore
import { InvoiceStatusResponse } from './model/invoice-status-response';

// @ts-ignore
import { InvoiceTransferRequest } from './model/invoice-transfer-request';

// @ts-ignore
import { InvoiceTransferResponse } from './model/invoice-transfer-response';

// @ts-ignore
import { IopReadingResponse } from './model/iop-reading-response';

// @ts-ignore
import { IsPayOnlineUrlPathValidRequest } from './model/is-pay-online-url-path-valid-request';

// @ts-ignore
import { IsUniqueFinanceChargePlanNameResponse } from './model/is-unique-finance-charge-plan-name-response';

// @ts-ignore
import { ItemQuantityChangeResponse } from './model/item-quantity-change-response';

// @ts-ignore
import { KeratometryTestResponse } from './model/keratometry-test-response';

// @ts-ignore
import { LabOrderListResponse } from './model/lab-order-list-response';

// @ts-ignore
import { LabOrderResponse } from './model/lab-order-response';

// @ts-ignore
import { LabVendorResponse } from './model/lab-vendor-response';

// @ts-ignore
import { LaunchRxntResponse } from './model/launch-rxnt-response';

// @ts-ignore
import { LedgerPostingReportRequest } from './model/ledger-posting-report-request';

// @ts-ignore
import { LedgerPostingReportResponse } from './model/ledger-posting-report-response';

// @ts-ignore
import { LocationProductForOrderResponse } from './model/location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForBarcodePrintingResponse } from './model/location-product-item-for-barcode-printing-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './model/location-product-item-for-order-response';

// @ts-ignore
import { LocationProductItemTableResponse } from './model/location-product-item-table-response';

// @ts-ignore
import { LocationProductTableResponse } from './model/location-product-table-response';

// @ts-ignore
import { LocationSelectRequest } from './model/location-select-request';

// @ts-ignore
import { LocationTaxResponse } from './model/location-tax-response';

// @ts-ignore
import { LoginUsingExistingSessionRequest } from './model/login-using-existing-session-request';

// @ts-ignore
import { LoincCodeRequest } from './model/loinc-code-request';

// @ts-ignore
import { LoincCodeResponse } from './model/loinc-code-response';

// @ts-ignore
import { ManufacturerForPatientFrameResponse } from './model/manufacturer-for-patient-frame-response';

// @ts-ignore
import { ManufacturerResponse } from './model/manufacturer-response';

// @ts-ignore
import { ManufacturerSummaryResponse } from './model/manufacturer-summary-response';

// @ts-ignore
import { MappedTestFieldRequest } from './model/mapped-test-field-request';

// @ts-ignore
import { MappedTestRequest } from './model/mapped-test-request';

// @ts-ignore
import { MarkClaimAsSubmittedRequest } from './model/mark-claim-as-submitted-request';

// @ts-ignore
import { MarkClaimsAsCancelledRequest } from './model/mark-claims-as-cancelled-request';

// @ts-ignore
import { MarkMessageParticipantPersonsNewRequest } from './model/mark-message-participant-persons-new-request';

// @ts-ignore
import { MasterBaudRateResponse } from './model/master-baud-rate-response';

// @ts-ignore
import { MasterDeviceResponse } from './model/master-device-response';

// @ts-ignore
import { MasterFileDeviceResponse } from './model/master-file-device-response';

// @ts-ignore
import { MasterQueryResponse } from './model/master-query-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './model/master-reference-data-response';

// @ts-ignore
import { MasterReferenceEthnicityDataResponse } from './model/master-reference-ethnicity-data-response';

// @ts-ignore
import { MasterReferenceLanguageDataResponse } from './model/master-reference-language-data-response';

// @ts-ignore
import { MasterReferenceRaceDataResponse } from './model/master-reference-race-data-response';

// @ts-ignore
import { MasterSerialDeviceResponse } from './model/master-serial-device-response';

// @ts-ignore
import { MeaningfulUseReportRequest } from './model/meaningful-use-report-request';

// @ts-ignore
import { MeaningfulUseReportResponse } from './model/meaningful-use-report-response';

// @ts-ignore
import { MedicalDecisionMakingResponse } from './model/medical-decision-making-response';

// @ts-ignore
import { MedicationPrescriptionImportResponse } from './model/medication-prescription-import-response';

// @ts-ignore
import { MedicationPrescriptionListResponse } from './model/medication-prescription-list-response';

// @ts-ignore
import { MedicationPrescriptionRefillRequest } from './model/medication-prescription-refill-request';

// @ts-ignore
import { MedicationPrescriptionRequest } from './model/medication-prescription-request';

// @ts-ignore
import { MedicationPrescriptionResponse } from './model/medication-prescription-response';

// @ts-ignore
import { MedicationPrescriptionTypeResponse } from './model/medication-prescription-type-response';

// @ts-ignore
import { MedicationReviewHistoryRequest } from './model/medication-review-history-request';

// @ts-ignore
import { MedicationReviewHistoryResponse } from './model/medication-review-history-response';

// @ts-ignore
import { MentalStatusResponse } from './model/mental-status-response';

// @ts-ignore
import { MergePatientsRequest } from './model/merge-patients-request';

// @ts-ignore
import { MessageDetailResponse } from './model/message-detail-response';

// @ts-ignore
import { MessageFolderResponse } from './model/message-folder-response';

// @ts-ignore
import { MessageParticipantNameResponse } from './model/message-participant-name-response';

// @ts-ignore
import { MessageParticipantResponse } from './model/message-participant-response';

// @ts-ignore
import { MessagingDirectProviderResponse } from './model/messaging-direct-provider-response';

// @ts-ignore
import { MinMaxInventoryReportResponse } from './model/min-max-inventory-report-response';

// @ts-ignore
import { MipsQualityReportRequest } from './model/mips-quality-report-request';

// @ts-ignore
import { MipsQualityReportResponse } from './model/mips-quality-report-response';

// @ts-ignore
import { MipsQualityResultResponse } from './model/mips-quality-result-response';

// @ts-ignore
import { ModifierResponse } from './model/modifier-response';

// @ts-ignore
import { MoveFolderRequest } from './model/move-folder-request';

// @ts-ignore
import { MovePatientFileRequest } from './model/move-patient-file-request';

// @ts-ignore
import { NoteResponse } from './model/note-response';

// @ts-ignore
import { OcularDiffResponse } from './model/ocular-diff-response';

// @ts-ignore
import { OcularHistoryRequest } from './model/ocular-history-request';

// @ts-ignore
import { OcularHistoryResponse } from './model/ocular-history-response';

// @ts-ignore
import { OdOsLocationProductIdsActiveResponse } from './model/od-os-location-product-ids-active-response';

// @ts-ignore
import { OfficeClosedCalendarResponse } from './model/office-closed-calendar-response';

// @ts-ignore
import { OfficeClosedResponse } from './model/office-closed-response';

// @ts-ignore
import { OfficeClosedSidebarResponse } from './model/office-closed-sidebar-response';

// @ts-ignore
import { OpenEdgeCardReaderResponse } from './model/open-edge-card-reader-response';

// @ts-ignore
import { OpenEdgeConfigurationResponse } from './model/open-edge-configuration-response';

// @ts-ignore
import { OpenEdgeCreatePayFieldsTransactionRequest } from './model/open-edge-create-pay-fields-transaction-request';

// @ts-ignore
import { OpenEdgeCreateSavedCardRequest } from './model/open-edge-create-saved-card-request';

// @ts-ignore
import { OpenEdgeCreateStoredCardTransactionRequest } from './model/open-edge-create-stored-card-transaction-request';

// @ts-ignore
import { OpenEdgeFinishTransactionRequest } from './model/open-edge-finish-transaction-request';

// @ts-ignore
import { OpenEdgePaymentTransactionResponse } from './model/open-edge-payment-transaction-response';

// @ts-ignore
import { OpenEdgePracticeLocationResponse } from './model/open-edge-practice-location-response';

// @ts-ignore
import { OpenEdgeSaveCardResponse } from './model/open-edge-save-card-response';

// @ts-ignore
import { OpenEdgeSettingsRequest } from './model/open-edge-settings-request';

// @ts-ignore
import { OpenEdgeSettingsResponse } from './model/open-edge-settings-response';

// @ts-ignore
import { OpenEdgeSetupTransportKeysRequest } from './model/open-edge-setup-transport-keys-request';

// @ts-ignore
import { OpenEdgeTransactionDetailsResponse } from './model/open-edge-transaction-details-response';

// @ts-ignore
import { OpenEdgeTransportKeysResponse } from './model/open-edge-transport-keys-response';

// @ts-ignore
import { OpenEdgeVoidTransactionResponse } from './model/open-edge-void-transaction-response';

// @ts-ignore
import { OpenOrderResponse } from './model/open-order-response';

// @ts-ignore
import { OpticalDeviceLensMeasurementResponse } from './model/optical-device-lens-measurement-response';

// @ts-ignore
import { OpticalDeviceMeasurementResponse } from './model/optical-device-measurement-response';

// @ts-ignore
import { OpticalOrderContactLensDetailResponse } from './model/optical-order-contact-lens-detail-response';

// @ts-ignore
import { OpticalOrderDetailResponse } from './model/optical-order-detail-response';

// @ts-ignore
import { OpticalOrderEyeglassDetailResponse } from './model/optical-order-eyeglass-detail-response';

// @ts-ignore
import { OpticalOrderPodContactLensResponse } from './model/optical-order-pod-contact-lens-response';

// @ts-ignore
import { OpticalOrderPodEyeglassResponse } from './model/optical-order-pod-eyeglass-response';

// @ts-ignore
import { OpticalOrderPodFrameResponse } from './model/optical-order-pod-frame-response';

// @ts-ignore
import { OpticalOrderPodOrderResponse } from './model/optical-order-pod-order-response';

// @ts-ignore
import { OpticalOrderPodOrderSearchRequest } from './model/optical-order-pod-order-search-request';

// @ts-ignore
import { OpticalSummaryEncounterHistoryResponse } from './model/optical-summary-encounter-history-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsContactLensResponse } from './model/optical-summary-latest-findings-contact-lens-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsContactLensTrialResponse } from './model/optical-summary-latest-findings-contact-lens-trial-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsEyeglassResponse } from './model/optical-summary-latest-findings-eyeglass-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsRefractionResponse } from './model/optical-summary-latest-findings-refraction-response';

// @ts-ignore
import { OpticalSummaryLatestFindingsResponse } from './model/optical-summary-latest-findings-response';

// @ts-ignore
import { OrderContactLensPackagingResponse } from './model/order-contact-lens-packaging-response';

// @ts-ignore
import { OrderContactLensPrescriptionResponse } from './model/order-contact-lens-prescription-response';

// @ts-ignore
import { OrderContactLensTrialResponse } from './model/order-contact-lens-trial-response';

// @ts-ignore
import { OrderCountsByStatusResponse } from './model/order-counts-by-status-response';

// @ts-ignore
import { OrderDashboardSortRequest } from './model/order-dashboard-sort-request';

// @ts-ignore
import { OrderEyefinityFeeLensResponse } from './model/order-eyefinity-fee-lens-response';

// @ts-ignore
import { OrderEyefinityFeeMappingResponse } from './model/order-eyefinity-fee-mapping-response';

// @ts-ignore
import { OrderEyefinityFeeOptionResponse } from './model/order-eyefinity-fee-option-response';

// @ts-ignore
import { OrderEyefinityFeeScheduleListResponse } from './model/order-eyefinity-fee-schedule-list-response';

// @ts-ignore
import { OrderEyefinityFeeScheduleResponse } from './model/order-eyefinity-fee-schedule-response';

// @ts-ignore
import { OrderEyefinityFeeServiceResponse } from './model/order-eyefinity-fee-service-response';

// @ts-ignore
import { OrderEyeglassPrescriptionResponse } from './model/order-eyeglass-prescription-response';

// @ts-ignore
import { OrderForPrintResponse } from './model/order-for-print-response';

// @ts-ignore
import { OrderOnHoldHistoryResponse } from './model/order-on-hold-history-response';

// @ts-ignore
import { OrderPerformedServiceResponse } from './model/order-performed-service-response';

// @ts-ignore
import { OrderPrescriptionResponse } from './model/order-prescription-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './model/order-processor-config-response';

// @ts-ignore
import { OrderProductColorResponse } from './model/order-product-color-response';

// @ts-ignore
import { OrderProductOptionRequest } from './model/order-product-option-request';

// @ts-ignore
import { OrderProductOptionResponse } from './model/order-product-option-response';

// @ts-ignore
import { OrderQueryResponse } from './model/order-query-response';

// @ts-ignore
import { OrderResponse } from './model/order-response';

// @ts-ignore
import { OrderStatusHistoryResponse } from './model/order-status-history-response';

// @ts-ignore
import { OrderStatusResponse } from './model/order-status-response';

// @ts-ignore
import { OrderStatusUpdateRequest } from './model/order-status-update-request';

// @ts-ignore
import { OrderTwelve84ConfigurationOptionResponse } from './model/order-twelve84-configuration-option-response';

// @ts-ignore
import { OrderTwelve84ConfigurationResponse } from './model/order-twelve84-configuration-response';

// @ts-ignore
import { OrderTwelve84FrameResponse } from './model/order-twelve84-frame-response';

// @ts-ignore
import { OrderTwelve84Response } from './model/order-twelve84-response';

// @ts-ignore
import { OrderVendorConfigResponse } from './model/order-vendor-config-response';

// @ts-ignore
import { OrderVendorResponse } from './model/order-vendor-response';

// @ts-ignore
import { OrdersAdvancedSearchRequest } from './model/orders-advanced-search-request';

// @ts-ignore
import { OrdersSearchResponse } from './model/orders-search-response';

// @ts-ignore
import { OrdersSimpleSearchRequest } from './model/orders-simple-search-request';

// @ts-ignore
import { OtherAllergyResponse } from './model/other-allergy-response';

// @ts-ignore
import { PagingAndSortingRequest } from './model/paging-and-sorting-request';

// @ts-ignore
import { ParseExternalPatientDataFileRequest } from './model/parse-external-patient-data-file-request';

// @ts-ignore
import { PatientAccountBalancesResponse } from './model/patient-account-balances-response';

// @ts-ignore
import { PatientAccountSummaryResponse } from './model/patient-account-summary-response';

// @ts-ignore
import { PatientAddWorkflowScreenToWorkflowStepRequest } from './model/patient-add-workflow-screen-to-workflow-step-request';

// @ts-ignore
import { PatientAdditionalInformationRequest } from './model/patient-additional-information-request';

// @ts-ignore
import { PatientBasicInformationRequest } from './model/patient-basic-information-request';

// @ts-ignore
import { PatientBirthdaySearchRequest } from './model/patient-birthday-search-request';

// @ts-ignore
import { PatientBirthdaySearchResponse } from './model/patient-birthday-search-response';

// @ts-ignore
import { PatientCommentResponse } from './model/patient-comment-response';

// @ts-ignore
import { PatientCommunicationEligibilityResponse } from './model/patient-communication-eligibility-response';

// @ts-ignore
import { PatientCommunicationPreferencesRequest } from './model/patient-communication-preferences-request';

// @ts-ignore
import { PatientCommunicationPreferencesResponse } from './model/patient-communication-preferences-response';

// @ts-ignore
import { PatientCommunicationResponse } from './model/patient-communication-response';

// @ts-ignore
import { PatientConsentResponse } from './model/patient-consent-response';

// @ts-ignore
import { PatientContactEmergencyContactStatusRequest } from './model/patient-contact-emergency-contact-status-request';

// @ts-ignore
import { PatientContactInformationResponse } from './model/patient-contact-information-response';

// @ts-ignore
import { PatientCreditRequest } from './model/patient-credit-request';

// @ts-ignore
import { PatientCvxCodeResponse } from './model/patient-cvx-code-response';

// @ts-ignore
import { PatientDashboardSummaryResponse } from './model/patient-dashboard-summary-response';

// @ts-ignore
import { PatientDataExportResponse } from './model/patient-data-export-response';

// @ts-ignore
import { PatientDocumentResponse } from './model/patient-document-response';

// @ts-ignore
import { PatientDuplicateSearchRequest } from './model/patient-duplicate-search-request';

// @ts-ignore
import { PatientDuplicateSearchResponse } from './model/patient-duplicate-search-response';

// @ts-ignore
import { PatientEmergencyContactResponse } from './model/patient-emergency-contact-response';

// @ts-ignore
import { PatientEmploymentRequest } from './model/patient-employment-request';

// @ts-ignore
import { PatientEmploymentResponse } from './model/patient-employment-response';

// @ts-ignore
import { PatientFamilyInformationResponse } from './model/patient-family-information-response';

// @ts-ignore
import { PatientFileInfoResponse } from './model/patient-file-info-response';

// @ts-ignore
import { PatientFileListRequest } from './model/patient-file-list-request';

// @ts-ignore
import { PatientFileListResponse } from './model/patient-file-list-response';

// @ts-ignore
import { PatientGuarantorResponse } from './model/patient-guarantor-response';

// @ts-ignore
import { PatientHealthConcernRequest } from './model/patient-health-concern-request';

// @ts-ignore
import { PatientHealthConcernResponse } from './model/patient-health-concern-response';

// @ts-ignore
import { PatientHealthGoalResponse } from './model/patient-health-goal-response';

// @ts-ignore
import { PatientHeuristicSearchResponse } from './model/patient-heuristic-search-response';

// @ts-ignore
import { PatientHl7ValueSetResponse } from './model/patient-hl7-value-set-response';

// @ts-ignore
import { PatientHl7ValueSetSearchRequest } from './model/patient-hl7-value-set-search-request';

// @ts-ignore
import { PatientImplantableDeviceResponse } from './model/patient-implantable-device-response';

// @ts-ignore
import { PatientInvoiceDashboardSearchRequest } from './model/patient-invoice-dashboard-search-request';

// @ts-ignore
import { PatientInvoiceTransferSplitRequest } from './model/patient-invoice-transfer-split-request';

// @ts-ignore
import { PatientManagementSearchRequest } from './model/patient-management-search-request';

// @ts-ignore
import { PatientMinerSearchRequest } from './model/patient-miner-search-request';

// @ts-ignore
import { PatientMinerSearchResponse } from './model/patient-miner-search-response';

// @ts-ignore
import { PatientNameResponse } from './model/patient-name-response';

// @ts-ignore
import { PatientOngoingCareResponse } from './model/patient-ongoing-care-response';

// @ts-ignore
import { PatientOrderHistoryResponse } from './model/patient-order-history-response';

// @ts-ignore
import { PatientOtherInformationRequest } from './model/patient-other-information-request';

// @ts-ignore
import { PatientPasswordResponse } from './model/patient-password-response';

// @ts-ignore
import { PatientPaymentResponse } from './model/patient-payment-response';

// @ts-ignore
import { PatientPerformedServiceResponse } from './model/patient-performed-service-response';

// @ts-ignore
import { PatientPharmacyResponse } from './model/patient-pharmacy-response';

// @ts-ignore
import { PatientPhoneInformationRequest } from './model/patient-phone-information-request';

// @ts-ignore
import { PatientPodConnectMessageResponse } from './model/patient-pod-connect-message-response';

// @ts-ignore
import { PatientPodConnectMessagesResponse } from './model/patient-pod-connect-messages-response';

// @ts-ignore
import { PatientPodContactLensEyeResponse } from './model/patient-pod-contact-lens-eye-response';

// @ts-ignore
import { PatientPodContactLensResponse } from './model/patient-pod-contact-lens-response';

// @ts-ignore
import { PatientPodContactResponse } from './model/patient-pod-contact-response';

// @ts-ignore
import { PatientPodEncounterResponse } from './model/patient-pod-encounter-response';

// @ts-ignore
import { PatientPodEyeglassResponse } from './model/patient-pod-eyeglass-response';

// @ts-ignore
import { PatientPodFamilyAccountMemberResponse } from './model/patient-pod-family-account-member-response';

// @ts-ignore
import { PatientPodFamilyMembersResponse } from './model/patient-pod-family-members-response';

// @ts-ignore
import { PatientPodLabOrderResponse } from './model/patient-pod-lab-order-response';

// @ts-ignore
import { PatientPodLastAndNextEncounterResponse } from './model/patient-pod-last-and-next-encounter-response';

// @ts-ignore
import { PatientPodLastEncounterResponse } from './model/patient-pod-last-encounter-response';

// @ts-ignore
import { PatientPodMedicationResponse } from './model/patient-pod-medication-response';

// @ts-ignore
import { PatientPodNextAppointmentResponse } from './model/patient-pod-next-appointment-response';

// @ts-ignore
import { PatientPodNoteResponse } from './model/patient-pod-note-response';

// @ts-ignore
import { PatientPodNotesResponse } from './model/patient-pod-notes-response';

// @ts-ignore
import { PatientPodOrderResponse } from './model/patient-pod-order-response';

// @ts-ignore
import { PatientPodPatientAccountResponse } from './model/patient-pod-patient-account-response';

// @ts-ignore
import { PatientPodPersonDiagnosisResponse } from './model/patient-pod-person-diagnosis-response';

// @ts-ignore
import { PatientPodPersonInsuranceResponse } from './model/patient-pod-person-insurance-response';

// @ts-ignore
import { PatientPodRecallResponse } from './model/patient-pod-recall-response';

// @ts-ignore
import { PatientPodTaskResponse } from './model/patient-pod-task-response';

// @ts-ignore
import { PatientPopoverResponse } from './model/patient-popover-response';

// @ts-ignore
import { PatientPortalAccessRequest } from './model/patient-portal-access-request';

// @ts-ignore
import { PatientPortalAuthorizedPatientResponse } from './model/patient-portal-authorized-patient-response';

// @ts-ignore
import { PatientPortionRequest } from './model/patient-portion-request';

// @ts-ignore
import { PatientProviderResponse } from './model/patient-provider-response';

// @ts-ignore
import { PatientQuickViewResponse } from './model/patient-quick-view-response';

// @ts-ignore
import { PatientRecallDetailResponse } from './model/patient-recall-detail-response';

// @ts-ignore
import { PatientRecallResponse } from './model/patient-recall-response';

// @ts-ignore
import { PatientRecallSearchRequest } from './model/patient-recall-search-request';

// @ts-ignore
import { PatientRecallSearchResponse } from './model/patient-recall-search-response';

// @ts-ignore
import { PatientReferralRequest } from './model/patient-referral-request';

// @ts-ignore
import { PatientReferralResponse } from './model/patient-referral-response';

// @ts-ignore
import { PatientRefundRequest } from './model/patient-refund-request';

// @ts-ignore
import { PatientResponse } from './model/patient-response';

// @ts-ignore
import { PatientSchedulingInfoResponse } from './model/patient-scheduling-info-response';

// @ts-ignore
import { PatientSchedulingPreferenceResponse } from './model/patient-scheduling-preference-response';

// @ts-ignore
import { PatientSearchByIdsRequest } from './model/patient-search-by-ids-request';

// @ts-ignore
import { PatientSearchRequest } from './model/patient-search-request';

// @ts-ignore
import { PatientSearchResponse } from './model/patient-search-response';

// @ts-ignore
import { PatientSearchStringRequest } from './model/patient-search-string-request';

// @ts-ignore
import { PatientSimpleSearchRequest } from './model/patient-simple-search-request';

// @ts-ignore
import { PatientTargetIopResponse } from './model/patient-target-iop-response';

// @ts-ignore
import { PatientTaskResponse } from './model/patient-task-response';

// @ts-ignore
import { PatientTriggerOutcomeResponse } from './model/patient-trigger-outcome-response';

// @ts-ignore
import { PatientVaccinationListResponse } from './model/patient-vaccination-list-response';

// @ts-ignore
import { PatientVaccinationRequest } from './model/patient-vaccination-request';

// @ts-ignore
import { PatientVaccinationResponse } from './model/patient-vaccination-response';

// @ts-ignore
import { PatientVaccineManufacturerResponse } from './model/patient-vaccine-manufacturer-response';

// @ts-ignore
import { PatientWorkflowMenuResponse } from './model/patient-workflow-menu-response';

// @ts-ignore
import { PatientWorkflowScreenMenuResponse } from './model/patient-workflow-screen-menu-response';

// @ts-ignore
import { PatientWorkflowScreenTemplateListResponse } from './model/patient-workflow-screen-template-list-response';

// @ts-ignore
import { PatientWorkflowStepMenuResponse } from './model/patient-workflow-step-menu-response';

// @ts-ignore
import { PatientWorkflowTestTemplateListResponse } from './model/patient-workflow-test-template-list-response';

// @ts-ignore
import { PaymentDashboardResponse } from './model/payment-dashboard-response';

// @ts-ignore
import { PaymentDashboardSearchRequest } from './model/payment-dashboard-search-request';

// @ts-ignore
import { PaymentGroupResponse } from './model/payment-group-response';

// @ts-ignore
import { PaymentInfoResponse } from './model/payment-info-response';

// @ts-ignore
import { PaymentInvoiceListResponse } from './model/payment-invoice-list-response';

// @ts-ignore
import { PaymentManualInvoiceSearchRequest } from './model/payment-manual-invoice-search-request';

// @ts-ignore
import { PaymentPreferencesResponse } from './model/payment-preferences-response';

// @ts-ignore
import { PaymentProcessorPracticeLocationResponse } from './model/payment-processor-practice-location-response';

// @ts-ignore
import { PaymentResponse } from './model/payment-response';

// @ts-ignore
import { PaymentStoredTokenResponse } from './model/payment-stored-token-response';

// @ts-ignore
import { PaymentTransactionProcessorResponse } from './model/payment-transaction-processor-response';

// @ts-ignore
import { PaymentTransactionReceiptResponse } from './model/payment-transaction-receipt-response';

// @ts-ignore
import { PaymentTransactionResponse } from './model/payment-transaction-response';

// @ts-ignore
import { PaymentTransferInvoiceItemsResponse } from './model/payment-transfer-invoice-items-response';

// @ts-ignore
import { PerformedServiceResponse } from './model/performed-service-response';

// @ts-ignore
import { PersonCoverageResponse } from './model/person-coverage-response';

// @ts-ignore
import { PersonDetailsRequest } from './model/person-details-request';

// @ts-ignore
import { PersonDetailsResponse } from './model/person-details-response';

// @ts-ignore
import { PersonDiagnosisImportResponse } from './model/person-diagnosis-import-response';

// @ts-ignore
import { PersonDiagnosisResponse } from './model/person-diagnosis-response';

// @ts-ignore
import { PersonDiagnosisWithCarePlansResponse } from './model/person-diagnosis-with-care-plans-response';

// @ts-ignore
import { PersonGeneralHistoryResponse } from './model/person-general-history-response';

// @ts-ignore
import { PersonInsuranceCompanyResponse } from './model/person-insurance-company-response';

// @ts-ignore
import { PersonInsuranceDetailsResponse } from './model/person-insurance-details-response';

// @ts-ignore
import { PersonInsuranceDropdownResponse } from './model/person-insurance-dropdown-response';

// @ts-ignore
import { PersonInsurancePolicyResponse } from './model/person-insurance-policy-response';

// @ts-ignore
import { PersonInsuranceResponse } from './model/person-insurance-response';

// @ts-ignore
import { PersonInsuranceSummaryResponse } from './model/person-insurance-summary-response';

// @ts-ignore
import { PersonInsuranceTableResponse } from './model/person-insurance-table-response';

// @ts-ignore
import { PersonInsuranceTooltipResponse } from './model/person-insurance-tooltip-response';

// @ts-ignore
import { PersonNameResponse } from './model/person-name-response';

// @ts-ignore
import { PersonsToBeNotifiedResponse } from './model/persons-to-be-notified-response';

// @ts-ignore
import { PfshRequest } from './model/pfsh-request';

// @ts-ignore
import { PfshResponse } from './model/pfsh-response';

// @ts-ignore
import { PharmacyBrandResponse } from './model/pharmacy-brand-response';

// @ts-ignore
import { PharmacyResponse } from './model/pharmacy-response';

// @ts-ignore
import { PharmacySummaryResponse } from './model/pharmacy-summary-response';

// @ts-ignore
import { PhoneInformationRequest } from './model/phone-information-request';

// @ts-ignore
import { PhoneNumbersResponse } from './model/phone-numbers-response';

// @ts-ignore
import { PostalCodeSearchRequest } from './model/postal-code-search-request';

// @ts-ignore
import { PqriAlertRequest } from './model/pqri-alert-request';

// @ts-ignore
import { PqriAlertResponse } from './model/pqri-alert-response';

// @ts-ignore
import { PracticeAndLocationsIdentifiersResponse } from './model/practice-and-locations-identifiers-response';

// @ts-ignore
import { PracticeConsentFormResponse } from './model/practice-consent-form-response';

// @ts-ignore
import { PracticeDiagnosisListResponse } from './model/practice-diagnosis-list-response';

// @ts-ignore
import { PracticeDiagnosisResponse } from './model/practice-diagnosis-response';

// @ts-ignore
import { PracticeDrugListResponse } from './model/practice-drug-list-response';

// @ts-ignore
import { PracticeDrugResponse } from './model/practice-drug-response';

// @ts-ignore
import { PracticeFamilyHealthHistoryFilterListResponse } from './model/practice-family-health-history-filter-list-response';

// @ts-ignore
import { PracticeFamilyHealthHistoryFilterRequest } from './model/practice-family-health-history-filter-request';

// @ts-ignore
import { PracticeFamilyHistoryFiltersSearchRequest } from './model/practice-family-history-filters-search-request';

// @ts-ignore
import { PracticeFileInfoResponse } from './model/practice-file-info-response';

// @ts-ignore
import { PracticeFileRequest } from './model/practice-file-request';

// @ts-ignore
import { PracticeFileResponse } from './model/practice-file-response';

// @ts-ignore
import { PracticeGeneralInfoResponse } from './model/practice-general-info-response';

// @ts-ignore
import { PracticeIdentifiersResponse } from './model/practice-identifiers-response';

// @ts-ignore
import { PracticeInsuranceCompanyDropdownResponse } from './model/practice-insurance-company-dropdown-response';

// @ts-ignore
import { PracticeInsuranceCompanyModalResponse } from './model/practice-insurance-company-modal-response';

// @ts-ignore
import { PracticeInsuranceCompanyResponse } from './model/practice-insurance-company-response';

// @ts-ignore
import { PracticeInsuranceCompanySummaryResponse } from './model/practice-insurance-company-summary-response';

// @ts-ignore
import { PracticeInsuranceRemittanceCodeAdjustmentResponse } from './model/practice-insurance-remittance-code-adjustment-response';

// @ts-ignore
import { PracticeLaunchUrlResponse } from './model/practice-launch-url-response';

// @ts-ignore
import { PracticeLocationAccessCodeResponse } from './model/practice-location-access-code-response';

// @ts-ignore
import { PracticeLocationDefaultLabelPrinterResponse } from './model/practice-location-default-label-printer-response';

// @ts-ignore
import { PracticeLocationDeviceResponse } from './model/practice-location-device-response';

// @ts-ignore
import { PracticeLocationExternalAccessSummaryResponse } from './model/practice-location-external-access-summary-response';

// @ts-ignore
import { PracticeLocationFileDeviceResponse } from './model/practice-location-file-device-response';

// @ts-ignore
import { PracticeLocationIdentifiersResponse } from './model/practice-location-identifiers-response';

// @ts-ignore
import { PracticeLocationOfficeHoursResponse } from './model/practice-location-office-hours-response';

// @ts-ignore
import { PracticeLocationSerialDeviceResponse } from './model/practice-location-serial-device-response';

// @ts-ignore
import { PracticeLocationSubscriberResponse } from './model/practice-location-subscriber-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './model/practice-location-summary-response';

// @ts-ignore
import { PracticeLocationTaxRequest } from './model/practice-location-tax-request';

// @ts-ignore
import { PracticeLocationTransferTaxResponse } from './model/practice-location-transfer-tax-response';

// @ts-ignore
import { PracticeLocationValidDirectMessageResponse } from './model/practice-location-valid-direct-message-response';

// @ts-ignore
import { PracticeRemittanceCodeAdjustmentResponse } from './model/practice-remittance-code-adjustment-response';

// @ts-ignore
import { PracticeSmartApplicationResponse } from './model/practice-smart-application-response';

// @ts-ignore
import { PracticeTemplateSummaryResponse } from './model/practice-template-summary-response';

// @ts-ignore
import { PreferenceResponse } from './model/preference-response';

// @ts-ignore
import { PrescriptionSelectContactLensEyeResponse } from './model/prescription-select-contact-lens-eye-response';

// @ts-ignore
import { PrescriptionSelectContactLensResponse } from './model/prescription-select-contact-lens-response';

// @ts-ignore
import { PresentIllnessResponse } from './model/present-illness-response';

// @ts-ignore
import { PreviewBillableItemResponse } from './model/preview-billable-item-response';

// @ts-ignore
import { PreviewMappedTestsRequest } from './model/preview-mapped-tests-request';

// @ts-ignore
import { PreviewMappedTestsResponse } from './model/preview-mapped-tests-response';

// @ts-ignore
import { PreviousAddressRequest } from './model/previous-address-request';

// @ts-ignore
import { PreviousAddressResponse } from './model/previous-address-response';

// @ts-ignore
import { ProductBrandCollectionResponse } from './model/product-brand-collection-response';

// @ts-ignore
import { ProductBrandForPatientFrameResponse } from './model/product-brand-for-patient-frame-response';

// @ts-ignore
import { ProductBrandResponse } from './model/product-brand-response';

// @ts-ignore
import { ProductCategoryByMasterProductCategoryRequest } from './model/product-category-by-master-product-category-request';

// @ts-ignore
import { ProductCategoryResponse } from './model/product-category-response';

// @ts-ignore
import { ProductCollectionForPatientFrameResponse } from './model/product-collection-for-patient-frame-response';

// @ts-ignore
import { ProductColorResponse } from './model/product-color-response';

// @ts-ignore
import { ProductForPrescriptionResponse } from './model/product-for-prescription-response';

// @ts-ignore
import { ProfessionalTimeAdjustmentResponse } from './model/professional-time-adjustment-response';

// @ts-ignore
import { ProfessionalTimeResponse } from './model/professional-time-response';

// @ts-ignore
import { ProfessionalTimeSessionResponse } from './model/professional-time-session-response';

// @ts-ignore
import { PropertyValuesResponse } from './model/property-values-response';

// @ts-ignore
import { ProviderInsuranceIdentifierResponse } from './model/provider-insurance-identifier-response';

// @ts-ignore
import { ProviderResponse } from './model/provider-response';

// @ts-ignore
import { PullForwardEncounterRequest } from './model/pull-forward-encounter-request';

// @ts-ignore
import { PupillaryDistanceHistoryResponse } from './model/pupillary-distance-history-response';

// @ts-ignore
import { QueryCriteriaFieldRequest } from './model/query-criteria-field-request';

// @ts-ignore
import { QueryCriteriaFieldResponse } from './model/query-criteria-field-response';

// @ts-ignore
import { QueryFieldCategoryResponse } from './model/query-field-category-response';

// @ts-ignore
import { QueryFieldOptionResponse } from './model/query-field-option-response';

// @ts-ignore
import { QueryFieldResponse } from './model/query-field-response';

// @ts-ignore
import { QueryResponse } from './model/query-response';

// @ts-ignore
import { QueryResultFieldResponse } from './model/query-result-field-response';

// @ts-ignore
import { QueryResultResponse } from './model/query-result-response';

// @ts-ignore
import { QueryResultValueResponse } from './model/query-result-value-response';

// @ts-ignore
import { QueryResultsResponse } from './model/query-results-response';

// @ts-ignore
import { QuerySelectFieldRequest } from './model/query-select-field-request';

// @ts-ignore
import { QuerySelectFieldResponse } from './model/query-select-field-response';

// @ts-ignore
import { QuerySortFieldRequest } from './model/query-sort-field-request';

// @ts-ignore
import { QuerySortFieldResponse } from './model/query-sort-field-response';

// @ts-ignore
import { QuerySummaryResponse } from './model/query-summary-response';

// @ts-ignore
import { QueryTemplateCategoryResponse } from './model/query-template-category-response';

// @ts-ignore
import { QueryTriggerCarePlanItemTemplateResponse } from './model/query-trigger-care-plan-item-template-response';

// @ts-ignore
import { QueryTriggerCarePlanTemplateResponse } from './model/query-trigger-care-plan-template-response';

// @ts-ignore
import { QueryTriggerResponse } from './model/query-trigger-response';

// @ts-ignore
import { QueryTriggerSourceResponse } from './model/query-trigger-source-response';

// @ts-ignore
import { QueryTriggerSummaryResponse } from './model/query-trigger-summary-response';

// @ts-ignore
import { QuickAddContactRequest } from './model/quick-add-contact-request';

// @ts-ignore
import { QuickAddFamilyMemberRequest } from './model/quick-add-family-member-request';

// @ts-ignore
import { QuickAddPatientRequest } from './model/quick-add-patient-request';

// @ts-ignore
import { ReasonForVisitResponse } from './model/reason-for-visit-response';

// @ts-ignore
import { ReceiptsReportRequest } from './model/receipts-report-request';

// @ts-ignore
import { ReceiptsReportResponse } from './model/receipts-report-response';

// @ts-ignore
import { ReceivePaymentPayerRequest } from './model/receive-payment-payer-request';

// @ts-ignore
import { ReceivePaymentPayerResponse } from './model/receive-payment-payer-response';

// @ts-ignore
import { ReceivePaymentTransferInvoiceItemsRequest } from './model/receive-payment-transfer-invoice-items-request';

// @ts-ignore
import { ReconciliationBatchResponse } from './model/reconciliation-batch-response';

// @ts-ignore
import { ReconciliationItemResponse } from './model/reconciliation-item-response';

// @ts-ignore
import { ReconciliationReportResponse } from './model/reconciliation-report-response';

// @ts-ignore
import { RecurPatternDailyResponse } from './model/recur-pattern-daily-response';

// @ts-ignore
import { RecurPatternMonthlyResponse } from './model/recur-pattern-monthly-response';

// @ts-ignore
import { RecurPatternWeeklyResponse } from './model/recur-pattern-weekly-response';

// @ts-ignore
import { RecurPatternYearlyResponse } from './model/recur-pattern-yearly-response';

// @ts-ignore
import { RecurringDefinitionResponse } from './model/recurring-definition-response';

// @ts-ignore
import { ReferenceDataResponse } from './model/reference-data-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './model/reference-data-summary-response';

// @ts-ignore
import { RefillContactLensPrescriptionRequest } from './model/refill-contact-lens-prescription-request';

// @ts-ignore
import { RefillEyeglassPrescriptionRequest } from './model/refill-eyeglass-prescription-request';

// @ts-ignore
import { RefractionEyeRequest } from './model/refraction-eye-request';

// @ts-ignore
import { RefractionEyeResponse } from './model/refraction-eye-response';

// @ts-ignore
import { RefractionResponse } from './model/refraction-response';

// @ts-ignore
import { RefractiveCareResponse } from './model/refractive-care-response';

// @ts-ignore
import { RefundInsuranceResponse } from './model/refund-insurance-response';

// @ts-ignore
import { RefundPatientResponse } from './model/refund-patient-response';

// @ts-ignore
import { RefundsReportRequest } from './model/refunds-report-request';

// @ts-ignore
import { RefundsReportResponse } from './model/refunds-report-response';

// @ts-ignore
import { RelatedCategoryResponse } from './model/related-category-response';

// @ts-ignore
import { RemakeEyefinityOrderRequest } from './model/remake-eyefinity-order-request';

// @ts-ignore
import { RemakeEyefinityOrderResponse } from './model/remake-eyefinity-order-response';

// @ts-ignore
import { RemakeOrderRequest } from './model/remake-order-request';

// @ts-ignore
import { RemakeOrderResponse } from './model/remake-order-response';

// @ts-ignore
import { RemoveInvoiceItemRequest } from './model/remove-invoice-item-request';

// @ts-ignore
import { ReplyAllDataResponse } from './model/reply-all-data-response';

// @ts-ignore
import { ReplyDataResponse } from './model/reply-data-response';

// @ts-ignore
import { ResetClaimsRequest } from './model/reset-claims-request';

// @ts-ignore
import { ResolvePersonDiagnosisRequest } from './model/resolve-person-diagnosis-request';

// @ts-ignore
import { ReturnOrderRequest } from './model/return-order-request';

// @ts-ignore
import { RevClearConfigurationResponse } from './model/rev-clear-configuration-response';

// @ts-ignore
import { RevClearExternalCredentialResponse } from './model/rev-clear-external-credential-response';

// @ts-ignore
import { RevDirectRegisterLocationRequest } from './model/rev-direct-register-location-request';

// @ts-ignore
import { RevDirectResponse } from './model/rev-direct-response';

// @ts-ignore
import { ReviewOfSystemsDiffResponse } from './model/review-of-systems-diff-response';

// @ts-ignore
import { ReviewOfSystemsRequest } from './model/review-of-systems-request';

// @ts-ignore
import { ReviewOfSystemsResponse } from './model/review-of-systems-response';

// @ts-ignore
import { ReviewPatientFamilyHealthHistoryReviewSetRequest } from './model/review-patient-family-health-history-review-set-request';

// @ts-ignore
import { RunPatientTriggersRequest } from './model/run-patient-triggers-request';

// @ts-ignore
import { RunQueryRequest } from './model/run-query-request';

// @ts-ignore
import { RxSelectContactLensResponse } from './model/rx-select-contact-lens-response';

// @ts-ignore
import { RxSelectEyeglassResponse } from './model/rx-select-eyeglass-response';

// @ts-ignore
import { RxntCompanyMapResponse } from './model/rxnt-company-map-response';

// @ts-ignore
import { RxntPostResponse } from './model/rxnt-post-response';

// @ts-ignore
import { SalesReportRequest } from './model/sales-report-request';

// @ts-ignore
import { SalesReportResponse } from './model/sales-report-response';

// @ts-ignore
import { SalesRepresentativeResponse } from './model/sales-representative-response';

// @ts-ignore
import { SalesRepresentativesSummaryResponse } from './model/sales-representatives-summary-response';

// @ts-ignore
import { SaveMessageFileAsPatientFileRequest } from './model/save-message-file-as-patient-file-request';

// @ts-ignore
import { ScheduleFilterRequest } from './model/schedule-filter-request';

// @ts-ignore
import { ScheduleFilterResponse } from './model/schedule-filter-response';

// @ts-ignore
import { ScheduleItemCalendarResponse } from './model/schedule-item-calendar-response';

// @ts-ignore
import { ScheduleItemPrintResponse } from './model/schedule-item-print-response';

// @ts-ignore
import { ScheduleItemResponse } from './model/schedule-item-response';

// @ts-ignore
import { ScheduleItemSidebarResponse } from './model/schedule-item-sidebar-response';

// @ts-ignore
import { ScheduleItemsWithDeletedResponse } from './model/schedule-items-with-deleted-response';

// @ts-ignore
import { ScheduleViewPreferencesResponse } from './model/schedule-view-preferences-response';

// @ts-ignore
import { SchedulingPreferencesResponse } from './model/scheduling-preferences-response';

// @ts-ignore
import { SearchActivePracticeDiagnosesForAssessmentRequest } from './model/search-active-practice-diagnoses-for-assessment-request';

// @ts-ignore
import { SearchAppointmentSlotRequest } from './model/search-appointment-slot-request';

// @ts-ignore
import { SearchFeeScheduleItemsRequest } from './model/search-fee-schedule-items-request';

// @ts-ignore
import { SearchLoincCodesRequest } from './model/search-loinc-codes-request';

// @ts-ignore
import { SearchMessageParticipantPersonsRequest } from './model/search-message-participant-persons-request';

// @ts-ignore
import { SearchPatientFilesRequest } from './model/search-patient-files-request';

// @ts-ignore
import { SearchPracticeDiagnosesRequest } from './model/search-practice-diagnoses-request';

// @ts-ignore
import { SearchRefractionTestsRequest } from './model/search-refraction-tests-request';

// @ts-ignore
import { SearchSnomedCodesRequest } from './model/search-snomed-codes-request';

// @ts-ignore
import { SecureResourceCategoryResponse } from './model/secure-resource-category-response';

// @ts-ignore
import { SecureResourceResponse } from './model/secure-resource-response';

// @ts-ignore
import { SecurityRoleResponse } from './model/security-role-response';

// @ts-ignore
import { SendFeedbackRequest } from './model/send-feedback-request';

// @ts-ignore
import { SendOrderConnectMessageRequest } from './model/send-order-connect-message-request';

// @ts-ignore
import { ServiceAdjustmentResponse } from './model/service-adjustment-response';

// @ts-ignore
import { ServiceLineResponse } from './model/service-line-response';

// @ts-ignore
import { ServiceSummaryResponse } from './model/service-summary-response';

// @ts-ignore
import { SignEncounterRequest } from './model/sign-encounter-request';

// @ts-ignore
import { SignEncounterSsoRequest } from './model/sign-encounter-sso-request';

// @ts-ignore
import { SmartflowMasterManufacturerResponse } from './model/smartflow-master-manufacturer-response';

// @ts-ignore
import { SmartflowPracticeSupplierResponse } from './model/smartflow-practice-supplier-response';

// @ts-ignore
import { SmartflowSupplierLocationResponse } from './model/smartflow-supplier-location-response';

// @ts-ignore
import { SmartflowSupplierResponse } from './model/smartflow-supplier-response';

// @ts-ignore
import { SnomedCodeResponse } from './model/snomed-code-response';

// @ts-ignore
import { SocialDiffResponse } from './model/social-diff-response';

// @ts-ignore
import { SocialHistoryRequest } from './model/social-history-request';

// @ts-ignore
import { SocialHistoryResponse } from './model/social-history-response';

// @ts-ignore
import { SortingRequest } from './model/sorting-request';

// @ts-ignore
import { SplitPaymentRequest } from './model/split-payment-request';

// @ts-ignore
import { StartAppointmentCalendarResponse } from './model/start-appointment-calendar-response';

// @ts-ignore
import { StartAppointmentRequest } from './model/start-appointment-request';

// @ts-ignore
import { StateProvinceResponse } from './model/state-province-response';

// @ts-ignore
import { StatementInvoiceResponse } from './model/statement-invoice-response';

// @ts-ignore
import { StatementPayerResponse } from './model/statement-payer-response';

// @ts-ignore
import { StopPrescriptionRequest } from './model/stop-prescription-request';

// @ts-ignore
import { StringAdditionalPreferenceRequest } from './model/string-additional-preference-request';

// @ts-ignore
import { StringAdditionalPreferenceResponse } from './model/string-additional-preference-response';

// @ts-ignore
import { StringValueDiffResponse } from './model/string-value-diff-response';

// @ts-ignore
import { SubmitClaimsRequest } from './model/submit-claims-request';

// @ts-ignore
import { SubscribeTwelve84SubscriptionRequest } from './model/subscribe-twelve84-subscription-request';

// @ts-ignore
import { SurgeryHistoryResponse } from './model/surgery-history-response';

// @ts-ignore
import { SystemPreferencesResponse } from './model/system-preferences-response';

// @ts-ignore
import { TargetIopResponse } from './model/target-iop-response';

// @ts-ignore
import { TaxResponse } from './model/tax-response';

// @ts-ignore
import { TemplateCategorySearchRequest } from './model/template-category-search-request';

// @ts-ignore
import { TemporaryPasswordResponse } from './model/temporary-password-response';

// @ts-ignore
import { ThirdPartyAdministratorResponse } from './model/third-party-administrator-response';

// @ts-ignore
import { TodaysPatientsAppointmentResponse } from './model/todays-patients-appointment-response';

// @ts-ignore
import { TransactionResponse } from './model/transaction-response';

// @ts-ignore
import { TransferInvoiceBalanceToPatientRequest } from './model/transfer-invoice-balance-to-patient-request';

// @ts-ignore
import { TransferInvoiceItemsRequest } from './model/transfer-invoice-items-request';

// @ts-ignore
import { TransferItemRequest } from './model/transfer-item-request';

// @ts-ignore
import { TransferItemResponse } from './model/transfer-item-response';

// @ts-ignore
import { TransferPatientCreditRequest } from './model/transfer-patient-credit-request';

// @ts-ignore
import { TrizettoExternalCredentialResponse } from './model/trizetto-external-credential-response';

// @ts-ignore
import { Twelve84AgreementResponse } from './model/twelve84-agreement-response';

// @ts-ignore
import { Twelve84SubscriptionResponse } from './model/twelve84-subscription-response';

// @ts-ignore
import { Twelve84SubscriptionTableResponse } from './model/twelve84-subscription-table-response';

// @ts-ignore
import { TwoWayTextAutomatedMessageResponse } from './model/two-way-text-automated-message-response';

// @ts-ignore
import { TwoWayTextConversationListResponse } from './model/two-way-text-conversation-list-response';

// @ts-ignore
import { TwoWayTextConversationOrPatientMatchesResponse } from './model/two-way-text-conversation-or-patient-matches-response';

// @ts-ignore
import { TwoWayTextConversationResponse } from './model/two-way-text-conversation-response';

// @ts-ignore
import { TwoWayTextFindConversationsByClassificationRequest } from './model/two-way-text-find-conversations-by-classification-request';

// @ts-ignore
import { TwoWayTextFindNewConversationPatientsRequest } from './model/two-way-text-find-new-conversation-patients-request';

// @ts-ignore
import { TwoWayTextMessageResponse } from './model/two-way-text-message-response';

// @ts-ignore
import { TwoWayTextMessageSummaryResponse } from './model/two-way-text-message-summary-response';

// @ts-ignore
import { TwoWayTextNewConversationPatientResponse } from './model/two-way-text-new-conversation-patient-response';

// @ts-ignore
import { TwoWayTextPatientMatchResponse } from './model/two-way-text-patient-match-response';

// @ts-ignore
import { TwoWayTextPatientMatchesResponse } from './model/two-way-text-patient-matches-response';

// @ts-ignore
import { TwoWayTextSimpleSearchRequest } from './model/two-way-text-simple-search-request';

// @ts-ignore
import { UnassignedServiceResponse } from './model/unassigned-service-response';

// @ts-ignore
import { UnassignedServicesRequest } from './model/unassigned-services-request';

// @ts-ignore
import { UnlockSessionRequest } from './model/unlock-session-request';

// @ts-ignore
import { UnsignEncounterRequest } from './model/unsign-encounter-request';

// @ts-ignore
import { UnsignEncounterSsoRequest } from './model/unsign-encounter-sso-request';

// @ts-ignore
import { UpdateAdditionalClaimInformationRequest } from './model/update-additional-claim-information-request';

// @ts-ignore
import { UpdateAlertRequest } from './model/update-alert-request';

// @ts-ignore
import { UpdateAllergyReviewHistoryRequest } from './model/update-allergy-review-history-request';

// @ts-ignore
import { UpdateAmendmentRequest } from './model/update-amendment-request';

// @ts-ignore
import { UpdateAppointmentDescriptionRequest } from './model/update-appointment-description-request';

// @ts-ignore
import { UpdateAppointmentSlotRequest } from './model/update-appointment-slot-request';

// @ts-ignore
import { UpdateAssessmentPersonDiagnosisRequest } from './model/update-assessment-person-diagnosis-request';

// @ts-ignore
import { UpdateAssessmentPrimaryDiagnosisForEncounterRequest } from './model/update-assessment-primary-diagnosis-for-encounter-request';

// @ts-ignore
import { UpdateAssessmentRequest } from './model/update-assessment-request';

// @ts-ignore
import { UpdateBusyTimeRequest } from './model/update-busy-time-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateEducationRequest } from './model/update-care-plan-item-template-education-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateGeneralRequest } from './model/update-care-plan-item-template-general-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateGoalRequest } from './model/update-care-plan-item-template-goal-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateLabOrderRequest } from './model/update-care-plan-item-template-lab-order-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateMedicationPrescriptionRequest } from './model/update-care-plan-item-template-medication-prescription-request';

// @ts-ignore
import { UpdateCarePlanItemTemplateRecallRequest } from './model/update-care-plan-item-template-recall-request';

// @ts-ignore
import { UpdateCarePlanTemplateRequest } from './model/update-care-plan-template-request';

// @ts-ignore
import { UpdateCategoryRequest } from './model/update-category-request';

// @ts-ignore
import { UpdateCategorySortOrderRequest } from './model/update-category-sort-order-request';

// @ts-ignore
import { UpdateCheckoutTaskRequest } from './model/update-checkout-task-request';

// @ts-ignore
import { UpdateClaimProcessingPreferencesRequest } from './model/update-claim-processing-preferences-request';

// @ts-ignore
import { UpdateClaimsProcessorAttributeRequest } from './model/update-claims-processor-attribute-request';

// @ts-ignore
import { UpdateCommunicationsPreferencesRequest } from './model/update-communications-preferences-request';

// @ts-ignore
import { UpdateConditionRelatedToRequest } from './model/update-condition-related-to-request';

// @ts-ignore
import { UpdateConnectCampaignDraftRequest } from './model/update-connect-campaign-draft-request';

// @ts-ignore
import { UpdateConnectCampaignScheduleRequest } from './model/update-connect-campaign-schedule-request';

// @ts-ignore
import { UpdateConnectPracticeRequest } from './model/update-connect-practice-request';

// @ts-ignore
import { UpdateContactLensHistoryRequest } from './model/update-contact-lens-history-request';

// @ts-ignore
import { UpdateContactLensOrderRequest } from './model/update-contact-lens-order-request';

// @ts-ignore
import { UpdateContactLensPrescriptionDeliveryMethodRequest } from './model/update-contact-lens-prescription-delivery-method-request';

// @ts-ignore
import { UpdateContactLensPrescriptionRequest } from './model/update-contact-lens-prescription-request';

// @ts-ignore
import { UpdateContactLensTrialOrderRequest } from './model/update-contact-lens-trial-order-request';

// @ts-ignore
import { UpdateContactLensTrialRequest } from './model/update-contact-lens-trial-request';

// @ts-ignore
import { UpdateContactRequest } from './model/update-contact-request';

// @ts-ignore
import { UpdateCustomFilterPreferencesRequest } from './model/update-custom-filter-preferences-request';

// @ts-ignore
import { UpdateCustomQueryRequest } from './model/update-custom-query-request';

// @ts-ignore
import { UpdateDescriptionAndCommentsCheckoutTaskRequest } from './model/update-description-and-comments-checkout-task-request';

// @ts-ignore
import { UpdateDiagnosesOnPerformedServiceRequest } from './model/update-diagnoses-on-performed-service-request';

// @ts-ignore
import { UpdateDrugAllergyRequest } from './model/update-drug-allergy-request';

// @ts-ignore
import { UpdateEmployeeOfficeHoursRequest } from './model/update-employee-office-hours-request';

// @ts-ignore
import { UpdateEncounterProviderRequest } from './model/update-encounter-provider-request';

// @ts-ignore
import { UpdateEncounterRoleRequest } from './model/update-encounter-role-request';

// @ts-ignore
import { UpdateEncounterTemplateRequest } from './model/update-encounter-template-request';

// @ts-ignore
import { UpdateExternalCredentialRequest } from './model/update-external-credential-request';

// @ts-ignore
import { UpdateExternalDataRequest } from './model/update-external-data-request';

// @ts-ignore
import { UpdateExternalProviderRequest } from './model/update-external-provider-request';

// @ts-ignore
import { UpdateEyefinityContactLensOrderRequest } from './model/update-eyefinity-contact-lens-order-request';

// @ts-ignore
import { UpdateEyefinityEyeglassOrderRequest } from './model/update-eyefinity-eyeglass-order-request';

// @ts-ignore
import { UpdateEyefinityFrameOrderRequest } from './model/update-eyefinity-frame-order-request';

// @ts-ignore
import { UpdateEyefinityItemsAsBillableRequest } from './model/update-eyefinity-items-as-billable-request';

// @ts-ignore
import { UpdateEyefinityItemsAsNonBillableRequest } from './model/update-eyefinity-items-as-non-billable-request';

// @ts-ignore
import { UpdateEyefinityOrderRequest } from './model/update-eyefinity-order-request';

// @ts-ignore
import { UpdateEyeglassLensMeasurementsRequest } from './model/update-eyeglass-lens-measurements-request';

// @ts-ignore
import { UpdateEyeglassLensOrderDetailRequest } from './model/update-eyeglass-lens-order-detail-request';

// @ts-ignore
import { UpdateEyeglassOrderRequest } from './model/update-eyeglass-order-request';

// @ts-ignore
import { UpdateEyeglassPrescriptionRequest } from './model/update-eyeglass-prescription-request';

// @ts-ignore
import { UpdateFamilyAccountMemberRequest } from './model/update-family-account-member-request';

// @ts-ignore
import { UpdateFamilyAccountMemberWithEmergencyContactRequest } from './model/update-family-account-member-with-emergency-contact-request';

// @ts-ignore
import { UpdateFeatureFlagEmployeeRequest } from './model/update-feature-flag-employee-request';

// @ts-ignore
import { UpdateFeeScheduleRangeRequest } from './model/update-fee-schedule-range-request';

// @ts-ignore
import { UpdateFeeScheduleRequest } from './model/update-fee-schedule-request';

// @ts-ignore
import { UpdateFolderRequest } from './model/update-folder-request';

// @ts-ignore
import { UpdateFrameOrderRequest } from './model/update-frame-order-request';

// @ts-ignore
import { UpdateFramesDataSubscriptionRequest } from './model/update-frames-data-subscription-request';

// @ts-ignore
import { UpdateGeneralMedicationPrescriptionRequest } from './model/update-general-medication-prescription-request';

// @ts-ignore
import { UpdateIntakeAllergiesRequest } from './model/update-intake-allergies-request';

// @ts-ignore
import { UpdateIntakeMedicationRequest } from './model/update-intake-medication-request';

// @ts-ignore
import { UpdateInterviewRequest } from './model/update-interview-request';

// @ts-ignore
import { UpdateInterviewTemplateRequest } from './model/update-interview-template-request';

// @ts-ignore
import { UpdateInvoiceAppointmentRequest } from './model/update-invoice-appointment-request';

// @ts-ignore
import { UpdateInvoiceDateRequest } from './model/update-invoice-date-request';

// @ts-ignore
import { UpdateInvoiceEncounterRequest } from './model/update-invoice-encounter-request';

// @ts-ignore
import { UpdateInvoiceFinanceChargePlanRequest } from './model/update-invoice-finance-charge-plan-request';

// @ts-ignore
import { UpdateInvoiceItemQuantityRequest } from './model/update-invoice-item-quantity-request';

// @ts-ignore
import { UpdateInvoiceItemRequest } from './model/update-invoice-item-request';

// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonListRequest } from './model/update-invoice-item-responsible-person-list-request';

// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonRequest } from './model/update-invoice-item-responsible-person-request';

// @ts-ignore
import { UpdateInvoiceLocationRequest } from './model/update-invoice-location-request';

// @ts-ignore
import { UpdateInvoiceProviderRequest } from './model/update-invoice-provider-request';

// @ts-ignore
import { UpdateLabOrderRequest } from './model/update-lab-order-request';

// @ts-ignore
import { UpdateManufacturerRequest } from './model/update-manufacturer-request';

// @ts-ignore
import { UpdateMedicalDecisionMakingRequest } from './model/update-medical-decision-making-request';

// @ts-ignore
import { UpdateMedicationPrescriptionRequest } from './model/update-medication-prescription-request';

// @ts-ignore
import { UpdateMedicationReviewHistoryRequest } from './model/update-medication-review-history-request';

// @ts-ignore
import { UpdateMentalStatusRequest } from './model/update-mental-status-request';

// @ts-ignore
import { UpdateMessageParticipantPersonFolderRequest } from './model/update-message-participant-person-folder-request';

// @ts-ignore
import { UpdateNonBillableRequest } from './model/update-non-billable-request';

// @ts-ignore
import { UpdateNoteRequest } from './model/update-note-request';

// @ts-ignore
import { UpdateNotificationPersonsRequest } from './model/update-notification-persons-request';

// @ts-ignore
import { UpdateOfficeClosedRequest } from './model/update-office-closed-request';

// @ts-ignore
import { UpdateOfficeHoursDailyRequest } from './model/update-office-hours-daily-request';

// @ts-ignore
import { UpdateOfficeHoursWeeklyRequest } from './model/update-office-hours-weekly-request';

// @ts-ignore
import { UpdateOpenEdgeCardReaderRequest } from './model/update-open-edge-card-reader-request';

// @ts-ignore
import { UpdateOpenEdgeSettingsRequest } from './model/update-open-edge-settings-request';

// @ts-ignore
import { UpdateOrderOnHoldRequest } from './model/update-order-on-hold-request';

// @ts-ignore
import { UpdateOrderRequest } from './model/update-order-request';

// @ts-ignore
import { UpdateOrderStatusRequest } from './model/update-order-status-request';

// @ts-ignore
import { UpdateOtherAllergyRequest } from './model/update-other-allergy-request';

// @ts-ignore
import { UpdatePasswordPolicyRequest } from './model/update-password-policy-request';

// @ts-ignore
import { UpdatePatientCommentRequest } from './model/update-patient-comment-request';

// @ts-ignore
import { UpdatePatientConsentRequest } from './model/update-patient-consent-request';

// @ts-ignore
import { UpdatePatientDocumentContentRequest } from './model/update-patient-document-content-request';

// @ts-ignore
import { UpdatePatientFileRequest } from './model/update-patient-file-request';

// @ts-ignore
import { UpdatePatientGeneralBenefitsRequest } from './model/update-patient-general-benefits-request';

// @ts-ignore
import { UpdatePatientGeneralBenefitsSelfPolicyRequest } from './model/update-patient-general-benefits-self-policy-request';

// @ts-ignore
import { UpdatePatientHealthConcernRequest } from './model/update-patient-health-concern-request';

// @ts-ignore
import { UpdatePatientHealthGoalRequest } from './model/update-patient-health-goal-request';

// @ts-ignore
import { UpdatePatientImplantableDeviceRequest } from './model/update-patient-implantable-device-request';

// @ts-ignore
import { UpdatePatientInsuranceBasicInformationRequest } from './model/update-patient-insurance-basic-information-request';

// @ts-ignore
import { UpdatePatientInsuranceBasicInformationSelfPolicyRequest } from './model/update-patient-insurance-basic-information-self-policy-request';

// @ts-ignore
import { UpdatePatientMaterialBenefitsRequest } from './model/update-patient-material-benefits-request';

// @ts-ignore
import { UpdatePatientMaterialBenefitsSelfPolicyRequest } from './model/update-patient-material-benefits-self-policy-request';

// @ts-ignore
import { UpdatePatientOngoingCareRequest } from './model/update-patient-ongoing-care-request';

// @ts-ignore
import { UpdatePatientProviderRequest } from './model/update-patient-provider-request';

// @ts-ignore
import { UpdatePatientQuickViewRequest } from './model/update-patient-quick-view-request';

// @ts-ignore
import { UpdatePatientRecallRequest } from './model/update-patient-recall-request';

// @ts-ignore
import { UpdatePatientRequest } from './model/update-patient-request';

// @ts-ignore
import { UpdatePatientTriggerRequest } from './model/update-patient-trigger-request';

// @ts-ignore
import { UpdatePatientVaccinationRequest } from './model/update-patient-vaccination-request';

// @ts-ignore
import { UpdatePaymentGroupRequest } from './model/update-payment-group-request';

// @ts-ignore
import { UpdatePaymentPreferencesRequest } from './model/update-payment-preferences-request';

// @ts-ignore
import { UpdatePersonDiagnosisLocationAndQualifierRequest } from './model/update-person-diagnosis-location-and-qualifier-request';

// @ts-ignore
import { UpdatePersonDiagnosisRequest } from './model/update-person-diagnosis-request';

// @ts-ignore
import { UpdatePersonGeneralHistoryRequest } from './model/update-person-general-history-request';

// @ts-ignore
import { UpdatePersonInsuranceAndPolicyRequest } from './model/update-person-insurance-and-policy-request';

// @ts-ignore
import { UpdatePersonInsuranceRequest } from './model/update-person-insurance-request';

// @ts-ignore
import { UpdatePharmacyRequest } from './model/update-pharmacy-request';

// @ts-ignore
import { UpdatePracticeConsentFormRequest } from './model/update-practice-consent-form-request';

// @ts-ignore
import { UpdatePracticeDiagnosisRequest } from './model/update-practice-diagnosis-request';

// @ts-ignore
import { UpdatePracticeDrugRequest } from './model/update-practice-drug-request';

// @ts-ignore
import { UpdatePracticeFamilyHealthFiltersRequest } from './model/update-practice-family-health-filters-request';

// @ts-ignore
import { UpdatePracticeFileRequest } from './model/update-practice-file-request';

// @ts-ignore
import { UpdatePracticeGeneralInfoRequest } from './model/update-practice-general-info-request';

// @ts-ignore
import { UpdatePracticeInsuranceCompanyRequest } from './model/update-practice-insurance-company-request';

// @ts-ignore
import { UpdatePracticeInsuranceRemittanceCodeAdjustmentRequest } from './model/update-practice-insurance-remittance-code-adjustment-request';

// @ts-ignore
import { UpdatePracticeLocationTaxesRequest } from './model/update-practice-location-taxes-request';

// @ts-ignore
import { UpdatePracticeLocationTransferTaxRequest } from './model/update-practice-location-transfer-tax-request';

// @ts-ignore
import { UpdatePracticeLocationTransferTaxesRequest } from './model/update-practice-location-transfer-taxes-request';

// @ts-ignore
import { UpdatePracticeModifiersRequest } from './model/update-practice-modifiers-request';

// @ts-ignore
import { UpdatePracticeRemittanceCodeAdjustmentRequest } from './model/update-practice-remittance-code-adjustment-request';

// @ts-ignore
import { UpdatePresentIllnessRequest } from './model/update-present-illness-request';

// @ts-ignore
import { UpdateProviderInsuranceIdentifierRequest } from './model/update-provider-insurance-identifier-request';

// @ts-ignore
import { UpdateQueryRequest } from './model/update-query-request';

// @ts-ignore
import { UpdateQueryTriggerRequest } from './model/update-query-trigger-request';

// @ts-ignore
import { UpdateReasonForVisitForSignedEncounterRequest } from './model/update-reason-for-visit-for-signed-encounter-request';

// @ts-ignore
import { UpdateReasonForVisitRequest } from './model/update-reason-for-visit-request';

// @ts-ignore
import { UpdateRecurringAppointmentSlotRequest } from './model/update-recurring-appointment-slot-request';

// @ts-ignore
import { UpdateRecurringBusyTimeRequest } from './model/update-recurring-busy-time-request';

// @ts-ignore
import { UpdateRecurringDefinitionRequest } from './model/update-recurring-definition-request';

// @ts-ignore
import { UpdateRecurringEmployeeOfficeHoursRequest } from './model/update-recurring-employee-office-hours-request';

// @ts-ignore
import { UpdateRecurringOfficeClosedRequest } from './model/update-recurring-office-closed-request';

// @ts-ignore
import { UpdateReferenceDataRequest } from './model/update-reference-data-request';

// @ts-ignore
import { UpdateReferenceDataSortOrderRequest } from './model/update-reference-data-sort-order-request';

// @ts-ignore
import { UpdateRelatedCategoryRequest } from './model/update-related-category-request';

// @ts-ignore
import { UpdateRevClearConfigurationRequest } from './model/update-rev-clear-configuration-request';

// @ts-ignore
import { UpdateRevClearExternalCredentialRequest } from './model/update-rev-clear-external-credential-request';

// @ts-ignore
import { UpdateSalesRepresentativeRequest } from './model/update-sales-representative-request';

// @ts-ignore
import { UpdateScheduleViewPreferencesRequest } from './model/update-schedule-view-preferences-request';

// @ts-ignore
import { UpdateSchedulingPreferenceRequest } from './model/update-scheduling-preference-request';

// @ts-ignore
import { UpdateSurgeryHistoryRequest } from './model/update-surgery-history-request';

// @ts-ignore
import { UpdateThirdPartyAdministratorRequest } from './model/update-third-party-administrator-request';

// @ts-ignore
import { UpdateTrizettoExternalCredentialRequest } from './model/update-trizetto-external-credential-request';

// @ts-ignore
import { UpdateTwelve84SubscriptionRequest } from './model/update-twelve84-subscription-request';

// @ts-ignore
import { UpdateTwoWayTextConversationClassificationRequest } from './model/update-two-way-text-conversation-classification-request';

// @ts-ignore
import { UpdateTwoWayTextConversationPatientRequest } from './model/update-two-way-text-conversation-patient-request';

// @ts-ignore
import { UpdateUserRoleRequest } from './model/update-user-role-request';

// @ts-ignore
import { UpdateVendorLocationConfigRequest } from './model/update-vendor-location-config-request';

// @ts-ignore
import { UpdateVendorRequest } from './model/update-vendor-request';

// @ts-ignore
import { UpdateWaitingListRequest } from './model/update-waiting-list-request';

// @ts-ignore
import { UpdateWorkflowStepTemplateRequest } from './model/update-workflow-step-template-request';

// @ts-ignore
import { UpdateWorkflowTestsRequest } from './model/update-workflow-tests-request';

// @ts-ignore
import { UpdatedAssessmentPersonDiagnosisResponse } from './model/updated-assessment-person-diagnosis-response';

// @ts-ignore
import { UrlResponse } from './model/url-response';

// @ts-ignore
import { UserSessionLogRequest } from './model/user-session-log-request';

// @ts-ignore
import { UserSessionLogResponse } from './model/user-session-log-response';

// @ts-ignore
import { UserSessionLogSearchRequest } from './model/user-session-log-search-request';

// @ts-ignore
import { UserSummaryResponse } from './model/user-summary-response';

// @ts-ignore
import { ValidatePracticeLocationAddressResponse } from './model/validate-practice-location-address-response';

// @ts-ignore
import { ValidatePracticeRemittanceX12CodeRequest } from './model/validate-practice-remittance-x12-code-request';

// @ts-ignore
import { ValidateUsernameRequest } from './model/validate-username-request';

// @ts-ignore
import { ValidatedCreateAppointmentLegacyResponse } from './model/validated-create-appointment-legacy-response';

// @ts-ignore
import { ValidatedCreateAppointmentResponse } from './model/validated-create-appointment-response';

// @ts-ignore
import { VendorLocationConfigResponse } from './model/vendor-location-config-response';

// @ts-ignore
import { VendorResponse } from './model/vendor-response';

// @ts-ignore
import { VendorSearchRequest } from './model/vendor-search-request';

// @ts-ignore
import { VendorSummaryResponse } from './model/vendor-summary-response';

// @ts-ignore
import { VerifyInsuranceRequest } from './model/verify-insurance-request';

// @ts-ignore
import { VisionWebSupplierResponse } from './model/vision-web-supplier-response';

// @ts-ignore
import { VoidInsuranceCreditRequest } from './model/void-insurance-credit-request';

// @ts-ignore
import { VoidInsuranceRefundRequest } from './model/void-insurance-refund-request';

// @ts-ignore
import { VoidInvoiceRequest } from './model/void-invoice-request';

// @ts-ignore
import { VoidPatientCreditRequest } from './model/void-patient-credit-request';

// @ts-ignore
import { VoidPatientRefundRequest } from './model/void-patient-refund-request';

// @ts-ignore
import { VoidPaymentRequest } from './model/void-payment-request';

// @ts-ignore
import { WaitingListResponse } from './model/waiting-list-response';

// @ts-ignore
import { WebServiceUserResponse } from './model/web-service-user-response';

// @ts-ignore
import { WorkflowScreenTemplatePresentIllnessResponse } from './model/workflow-screen-template-present-illness-response';

// @ts-ignore
import { WorkflowScreenTestTemplateLayoutRequest } from './model/workflow-screen-test-template-layout-request';

// @ts-ignore
import { WorkflowStepTemplateResponse } from './model/workflow-step-template-response';

// @ts-ignore
import { WorkflowStepTemplateScreenResponse } from './model/workflow-step-template-screen-response';

// @ts-ignore
import { WorkflowTemplateStepRequest } from './model/workflow-template-step-request';

// @ts-ignore
import { WorkflowTestRequest } from './model/workflow-test-request';

// @ts-ignore
import { WorkflowTestTriggersResultResponse } from './model/workflow-test-triggers-result-response';

// @ts-ignore
import { WriteoffInvoiceTransferSplitRequest } from './model/writeoff-invoice-transfer-split-request';

// @ts-ignore
import { X12CodeExistsRequest } from './model/x12-code-exists-request';

/** See com.rev360.pms.api.controller.accounting.AccountingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AccountingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findActiveFinanceChargePlansForDropdown(): Observable<FinanceChargePlanNameResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findActiveFinanceChargePlansForDropdown`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FinanceChargePlanNameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveInsuranceByPatientId(patientId: number): Observable<PersonInsuranceSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findActiveInsuranceByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findAdditionalClaimInformationCanadaByInvoiceId(invoiceId: number): Observable<AdditionalClaimInformationCanadaResponse> {
		const endpointUrl = `/pms2/ws/accounting/findAdditionalClaimInformationCanadaByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdditionalClaimInformationCanadaResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findAlternateServiceLocations(): Observable<VendorSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findAlternateServiceLocations`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findCollectionAgencies(): Observable<VendorSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findCollectionAgencies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInsuranceByPatientId(patientId: number): Observable<PersonInsuranceTableResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findInsuranceByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceTableResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMasterPersonDiagnosesByInvoiceId(invoiceId: number): Observable<PersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findMasterPersonDiagnosesByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findNonCanceledEncountersByPatientIdForTable(patientId: number): Observable<EncounterTableResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findNonCanceledEncountersByPatientIdForTable/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterTableResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPaymentPreferences(): Observable<AccountingPaymentPreferencesResponse> {
		const endpointUrl = `/pms2/ws/accounting/findPaymentPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AccountingPaymentPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeModifiers(): Observable<ModifierResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findPracticeModifiers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ModifierResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeStatementsProcessorId(): Observable<number> {
		const endpointUrl = `/pms2/ws/accounting/findPracticeStatementsProcessorId`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, null, null );
		
		return configuredEndpoint;
	}

	
	findTaxesByPracticeLocationId(practiceLocationId: number): Observable<LocationTaxResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/findTaxesByPracticeLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationTaxResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getActivePracticeLocationsExternalCredentials(): Observable<PracticeLocationExternalAccessSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/getActivePracticeLocationsExternalCredentials`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationExternalAccessSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getActiveProductCategories(): Observable<ProductCategoryResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/getActiveProductCategories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductCategoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getFinanceChargePlanById(financeChargePlanId: number): Observable<FinanceChargePlanNameResponse> {
		const endpointUrl = `/pms2/ws/accounting/getFinanceChargePlanById/${financeChargePlanId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FinanceChargePlanNameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getModifierByInvoiceItemId(invoiceItemId: number): Observable<ModifierResponse> {
		const endpointUrl = `/pms2/ws/accounting/getModifierByInvoiceItemId/${invoiceItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ModifierResponse, null, null );
		
		return configuredEndpoint;
	}

	
	savePayments(request: CreatePaymentGroupRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/savePayments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

	
	updateAdditionalClaimInformationCanada(request: AdditionalClaimInformationCanadaRequest ): Observable<AdditionalClaimInformationCanadaResponse> {
		const endpointUrl = `/pms2/ws/accounting/updateAdditionalClaimInformationCanada`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdditionalClaimInformationCanadaResponse, AdditionalClaimInformationCanadaRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceDate(request: UpdateInvoiceDateRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/pms2/ws/accounting/updateInvoiceDate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, UpdateInvoiceDateRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceFinanceChargePlan(request: UpdateInvoiceFinanceChargePlanRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/updateInvoiceFinanceChargePlan`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateInvoiceFinanceChargePlanRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePayments(request: UpdatePaymentGroupRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/updatePayments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.accounting.payment.AccountingPaymentController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AccountingPaymentGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePaymentGroup(paymentGroupId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/payment/activatePaymentGroup/${paymentGroupId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deactivatePaymentGroup(paymentGroupId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/payment/deactivatePaymentGroup/${paymentGroupId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findAnonymousInvoicesForPayment(request: FindAnonymousPaymentInvoicesRequest ): Observable<PaymentInvoiceListResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findAnonymousInvoicesForPayment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentInvoiceListResponse, FindAnonymousPaymentInvoicesRequest, request );
		
		return configuredEndpoint;
	}

	
	findByPaymentDashboardSearch(request: PaymentDashboardSearchRequest ): Observable<PaymentDashboardResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findByPaymentDashboardSearch`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentDashboardResponse, PaymentDashboardSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findByPaymentManualInvoiceSearch(request: PaymentManualInvoiceSearchRequest ): Observable<InvoiceDashboardResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findByPaymentManualInvoiceSearch`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceDashboardResponse, PaymentManualInvoiceSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findCollectionsInvoicesForPayment(request: FindCollectionsPaymentInvoicesRequest ): Observable<PaymentInvoiceListResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findCollectionsInvoicesForPayment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentInvoiceListResponse, FindCollectionsPaymentInvoicesRequest, request );
		
		return configuredEndpoint;
	}

	
	findInsuranceInvoicesForPayment(request: FindInsurancePaymentInvoicesRequest ): Observable<PaymentInvoiceListResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findInsuranceInvoicesForPayment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentInvoiceListResponse, FindInsurancePaymentInvoicesRequest, request );
		
		return configuredEndpoint;
	}

	
	findInsuranceRemittancesByPaymentGroup(paymentGroupId: number): Observable<HumanReadableInsuranceRemittanceResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findInsuranceRemittancesByPaymentGroup/${paymentGroupId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, HumanReadableInsuranceRemittanceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findNonCanceledByInvoiceId(invoiceId: number): Observable<AccountingInvoicePaymentResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findNonCanceledByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AccountingInvoicePaymentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientInvoicesForPayment(request: FindPatientPaymentInvoicesRequest ): Observable<PaymentInvoiceListResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findPatientInvoicesForPayment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentInvoiceListResponse, FindPatientPaymentInvoicesRequest, request );
		
		return configuredEndpoint;
	}

	
	findPaymentProcessorForPaymentGroup(paymentGroupId: number): Observable<PaymentTransactionProcessorResponse> {
		const endpointUrl = `/pms2/ws/accounting/payment/findPaymentProcessorForPaymentGroup/${paymentGroupId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentTransactionProcessorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findReceiptsRelatedToPaymentTransaction(paymentTransactionId: number): Observable<PaymentTransactionReceiptResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findReceiptsRelatedToPaymentTransaction/${paymentTransactionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentTransactionReceiptResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findReceivePaymentPayers(request: ReceivePaymentPayerRequest ): Observable<ReceivePaymentPayerResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/payment/findReceivePaymentPayers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReceivePaymentPayerResponse, ReceivePaymentPayerRequest, request );
		
		return configuredEndpoint;
	}

	
	getPaymentGroupById(paymentGroupId: number): Observable<PaymentGroupResponse> {
		const endpointUrl = `/pms2/ws/accounting/payment/getPaymentGroupById/${paymentGroupId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentGroupResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePaymentGroup(request: AccountingUpdatePaymentGroupRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/payment/updatePaymentGroup`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AccountingUpdatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.additionalpreferences.AdditionalPreferencesController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdditionalPreferencesGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findCcdaClinicalSummarySectionPreferences(): Observable<CcdaClinicalSummarySectionPreferencesResponse> {
		const endpointUrl = `/pms2/ws/additionalPreferences/findCcdaClinicalSummarySectionPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CcdaClinicalSummarySectionPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findCcdaPatientRecordSectionPreferences(): Observable<CcdaPatientRecordSectionPreferencesResponse> {
		const endpointUrl = `/pms2/ws/additionalPreferences/findCcdaPatientRecordSectionPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CcdaPatientRecordSectionPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.shared.common.AddressController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AddressGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	findCityStateProvinceByPostalCode(request: PostalCodeSearchRequest ): Observable<CityStateProvinceResponse> {
		const endpointUrl = `/pms2/ws/common/address/findCityStateProvinceByPostalCode`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CityStateProvinceResponse, PostalCodeSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findStateProvinces(cacheOptions?: GandalfCacheableConfigurableOptions<ReferenceDataResponse[]>): Observable<ReferenceDataResponse[]> {
		const endpointUrl = `/pms2/ws/common/address/findStateProvinces`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['state_provinces'], cacheableOptions);
	}

	
	getPatientAddress(patientId: number): Observable<AddressResponse> {
		const endpointUrl = `/pms2/ws/common/address/getPatientAddress/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AddressResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeLocationAddress(practiceLocationId: number): Observable<AddressResponse> {
		const endpointUrl = `/pms2/ws/common/address/getPracticeLocationAddress/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AddressResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getStateProvinceByPracticeLocation(practiceLocationId: number): Observable<StateProvinceResponse> {
		const endpointUrl = `/pms2/ws/common/address/getStateProvinceByPracticeLocation/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, StateProvinceResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.accounting.AdminAccountingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminAccountingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateFinanceChargePlan(financeChargePlanId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/activateFinanceChargePlanById/${financeChargePlanId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createFinanceChargePlan(request: CreateFinanceChargePlanRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/createFinanceChargePlan`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateFinanceChargePlanRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateFinanceChargePlanById(financeChargePlanId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/deactivateFinanceChargePlanById/${financeChargePlanId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findFinanceChargePlans(): Observable<FinanceChargePlanResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/findFinanceChargePlans`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FinanceChargePlanResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findTaxesByPracticeLocationId(practiceLocationId: number): Observable<TaxResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/findTaxesByPracticeLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TaxResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findTransferTaxesByPracticeLocationId(practiceLocationId: number): Observable<PracticeLocationTransferTaxResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/findTransferTaxesByPracticeLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationTransferTaxResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getFinanceChargePlanById(financeChargePlanId: number): Observable<FinanceChargePlanResponse> {
		const endpointUrl = `/pms2/ws/admin/accounting/getFinanceChargePlan/${financeChargePlanId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FinanceChargePlanResponse, null, null );
		
		return configuredEndpoint;
	}

	
	isPlanNameUnique(request: FinanceChargePlanNameRequest ): Observable<IsUniqueFinanceChargePlanNameResponse> {
		const endpointUrl = `/pms2/ws/admin/accounting/isPlanNameUnique`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, IsUniqueFinanceChargePlanNameResponse, FinanceChargePlanNameRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePracticeLocationTransferTaxes(request: UpdatePracticeLocationTransferTaxesRequest ): Observable<PracticeLocationTransferTaxResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/updatePracticeLocationTransferTaxes`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationTransferTaxResponse, UpdatePracticeLocationTransferTaxesRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CarePlanItemTemplateController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminCarePlanItemTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createEducationTemplate(request: CreateCarePlanItemTemplateEducationRequest ): Observable<CarePlanItemTemplateEducationResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/createEducationTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateEducationResponse, CreateCarePlanItemTemplateEducationRequest, request );
		
		return configuredEndpoint;
	}

	
	createGeneralTemplate(request: CreateCarePlanItemTemplateGeneralRequest ): Observable<CarePlanItemTemplateGeneralResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/createGeneralTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateGeneralResponse, CreateCarePlanItemTemplateGeneralRequest, request );
		
		return configuredEndpoint;
	}

	
	createGoalTemplate(request: CreateCarePlanItemTemplateGoalRequest ): Observable<CarePlanItemTemplateGoalResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/createGoalTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateGoalResponse, CreateCarePlanItemTemplateGoalRequest, request );
		
		return configuredEndpoint;
	}

	
	createLabOrderTemplate(request: CreateCarePlanItemTemplateLabOrderRequest ): Observable<CarePlanItemTemplateLabOrderResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/createLabOrderTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateLabOrderResponse, CreateCarePlanItemTemplateLabOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	createMedicationPrescriptionTemplate(request: CreateCarePlanItemTemplateMedicationPrescriptionRequest ): Observable<CarePlanItemTemplateMedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/createMedicationPrescriptionTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateMedicationPrescriptionResponse, CreateCarePlanItemTemplateMedicationPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	createRecallTemplate(request: CreateCarePlanItemTemplateRecallRequest ): Observable<CarePlanItemTemplateRecallResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/createRecallTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateRecallResponse, CreateCarePlanItemTemplateRecallRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteCarePlanItemTemplate(carePlanItemTemplateId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/deleteCarePlanItemTemplate/${carePlanItemTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findCarePlanItemTemplates(): Observable<CarePlanItemTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/findCarePlanItemTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findCarePlanItemTemplatesByCategory(request: FindCarePlanItemsByCategoryRequest ): Observable<CarePlanItemTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/findCarePlanItemTemplatesByCategory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateListResponse, FindCarePlanItemsByCategoryRequest, request );
		
		return configuredEndpoint;
	}

	
	getCarePlanItemTemplate(carePlanItemTemplateId: number): Observable<CarePlanItemTemplateResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/getCarePlanItemTemplate/${carePlanItemTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateEducationTemplate(request: UpdateCarePlanItemTemplateEducationRequest ): Observable<CarePlanItemTemplateEducationResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/updateEducationTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateEducationResponse, UpdateCarePlanItemTemplateEducationRequest, request );
		
		return configuredEndpoint;
	}

	
	updateGeneralTemplate(request: UpdateCarePlanItemTemplateGeneralRequest ): Observable<CarePlanItemTemplateGeneralResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/updateGeneralTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateGeneralResponse, UpdateCarePlanItemTemplateGeneralRequest, request );
		
		return configuredEndpoint;
	}

	
	updateGoalTemplate(request: UpdateCarePlanItemTemplateGoalRequest ): Observable<CarePlanItemTemplateGoalResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/updateGoalTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateGoalResponse, UpdateCarePlanItemTemplateGoalRequest, request );
		
		return configuredEndpoint;
	}

	
	updateLabOrderTemplate(request: UpdateCarePlanItemTemplateLabOrderRequest ): Observable<CarePlanItemTemplateLabOrderResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/updateLabOrderTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateLabOrderResponse, UpdateCarePlanItemTemplateLabOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateMedicationPrescriptionTemplate(request: UpdateCarePlanItemTemplateMedicationPrescriptionRequest ): Observable<CarePlanItemTemplateMedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/updateMedicationPrescriptionTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateMedicationPrescriptionResponse, UpdateCarePlanItemTemplateMedicationPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updateRecallTemplate(request: UpdateCarePlanItemTemplateRecallRequest ): Observable<CarePlanItemTemplateRecallResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlanItem/updateRecallTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateRecallResponse, UpdateCarePlanItemTemplateRecallRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.AdminCarePlanTemplateController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminCarePlanTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createCarePlanTemplate(request: CreateCarePlanTemplateRequest ): Observable<CarePlanTemplateListResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlan/createCarePlanTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanTemplateListResponse, CreateCarePlanTemplateRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteCarePlanTemplate(carePlanTemplateId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlan/deleteCarePlanTemplate/${carePlanTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findCarePlanTemplates(): Observable<CarePlanTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlan/findCarePlanTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getCarePlanTemplate(carePlanTemplateId: number): Observable<CarePlanTemplateListResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlan/getCarePlanTemplate/${carePlanTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateCarePlanTemplate(request: UpdateCarePlanTemplateRequest ): Observable<CarePlanTemplateListResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/carePlan/updateCarePlanTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanTemplateListResponse, UpdateCarePlanTemplateRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.communicationtemplate.AdminCommunicationTemplateController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminCommunicationTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePracticeTemplateById(templateId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/communicationTemplate/activatePracticeTemplateById/${templateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deactivatePracticeTemplateById(templateId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/communicationTemplate/deactivatePracticeTemplateById/${templateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeTemplates(request: TemplateCategorySearchRequest ): Observable<PracticeTemplateSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/communicationTemplate/findPracticeTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeTemplateSummaryResponse, TemplateCategorySearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.dataconfiguration.DataConfigurationController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminDataConfigurationGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	familyHistoryFiltersSnomedCodesSearch(request: SearchSnomedCodesRequest ): Observable<SnomedCodeResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/familyHistoryFiltersSnomedCodesSearch`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SnomedCodeResponse, SearchSnomedCodesRequest, request );
		
		return configuredEndpoint;
	}

	
	findExtendedEthnicityReferenceData(): Observable<ReferenceDataResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/findExtendedEthnicityReferenceData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findExtendedRaceReferenceData(): Observable<ReferenceDataResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/findExtendedRaceReferenceData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMasterReferenceEthnicityData(): Observable<MasterReferenceEthnicityDataResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/findMasterReferenceEthnicityData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MasterReferenceEthnicityDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMasterReferenceLanguageData(): Observable<MasterReferenceLanguageDataResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/findMasterReferenceLanguageData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MasterReferenceLanguageDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMasterReferenceRaceData(): Observable<MasterReferenceRaceDataResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/findMasterReferenceRaceData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MasterReferenceRaceDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchFamilyHistoryFilters(request: PracticeFamilyHistoryFiltersSearchRequest ): Observable<PracticeFamilyHealthHistoryFilterListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/searchFamilyHistoryFilters`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeFamilyHealthHistoryFilterListResponse, PracticeFamilyHistoryFiltersSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePracticeFamilyHealthHistoryFilters(request: UpdatePracticeFamilyHealthFiltersRequest ): Observable<PracticeFamilyHealthHistoryFilterListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/updatePracticeFamilyHealthHistoryFilters`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeFamilyHealthHistoryFilterListResponse, UpdatePracticeFamilyHealthFiltersRequest, request );
		
		return configuredEndpoint;
	}

	
	updateReferenceDataSortOrder(request: UpdateReferenceDataSortOrderRequest[] ): Observable<ReferenceDataResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/updateReferenceDataSortOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, UpdateReferenceDataSortOrderRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.dataconfiguration.modifiers.CommonModifiersController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminDataConfigurationModifiersGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findAllModifiers(): Observable<ModifierResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/modifiers/findAllModifiers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ModifierResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeModifiers(): Observable<ModifierResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/modifiers/findPracticeModifiers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ModifierResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePracticeModifiers(request: UpdatePracticeModifiersRequest ): Observable<ModifierResponse[]> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/modifiers/updatePracticeModifiers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ModifierResponse, UpdatePracticeModifiersRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.general.location.device.AdminDeviceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminDeviceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createPracticeLocationFileDevice(request: AdminCreatePracticeLocationFileDeviceRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/device/createPracticeLocationFileDevice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminCreatePracticeLocationFileDeviceRequest, request );
		
		return configuredEndpoint;
	}

	
	createPracticeLocationSerialDevice(request: AdminCreatePracticeLocationSerialDeviceRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/device/createPracticeLocationSerialDevice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminCreatePracticeLocationSerialDeviceRequest, request );
		
		return configuredEndpoint;
	}

	
	deletePracticeLocationDevice(practiceLocationDeviceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/device/deletePracticeLocationDevice/${practiceLocationDeviceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findEmrMasterDevices(): Observable<MasterDeviceResponse[]> {
		const endpointUrl = `/pms2/ws/admin/device/findEmrMasterDevices`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MasterDeviceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeLocationDevices(practiceLocationId: number): Observable<PracticeLocationDeviceResponse[]> {
		const endpointUrl = `/pms2/ws/admin/device/findPracticeLocationDevices/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationDeviceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePracticeLocationFileDevice(request: AdminUpdatePracticeLocationFileDeviceRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/device/updatePracticeLocationFileDevice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminUpdatePracticeLocationFileDeviceRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePracticeLocationSerialDevice(request: AdminUpdatePracticeLocationSerialDeviceRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/device/updatePracticeLocationSerialDevice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminUpdatePracticeLocationSerialDeviceRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.dataconfiguration.diagnosis.DiagnosisController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminDiagnosisGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePracticeDiagnosis(practiceDiagnosisId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/diagnosis/activatePracticeDiagnosis/${practiceDiagnosisId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createPracticeDiagnosis(request: CreatePracticeDiagnosisRequest ): Observable<PracticeDiagnosisResponse> {
		const endpointUrl = `/pms2/ws/admin/diagnosis/createPracticeDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeDiagnosisResponse, CreatePracticeDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePracticeDiagnosis(practiceDiagnosisId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/diagnosis/deactivatePracticeDiagnosis/${practiceDiagnosisId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findCarePlanTemplates(): Observable<CarePlanTemplateResponse[]> {
		const endpointUrl = `/pms2/ws/admin/diagnosis/findCarePlanTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMasterDiagnoses(request: DiagnosisSearchRequest ): Observable<DiagnosisSearchResponse[]> {
		const endpointUrl = `/pms2/ws/admin/diagnosis/findMasterDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DiagnosisSearchResponse, DiagnosisSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findPracticeDiagnosesForPractice(request: FindPracticeDiagnosesRequest ): Observable<PracticeDiagnosisListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/diagnosis/findPracticeDiagnosesForPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeDiagnosisListResponse, FindPracticeDiagnosesRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePracticeDiagnosis(request: UpdatePracticeDiagnosisRequest ): Observable<PracticeDiagnosisResponse> {
		const endpointUrl = `/pms2/ws/admin/diagnosis/updatePracticeDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeDiagnosisResponse, UpdatePracticeDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.employee.AdminEmployeeController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminEmployeeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	@GandalfCacheBuster({cacheKeys: ['providers', 'employees']})
	activateEmployee(employeeId: number): Observable<AdminEmployeeForEmployeeListResponse> {
		const endpointUrl = `/pms2/ws/admin/employee/activateEmployee/${employeeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEmployeeForEmployeeListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	addProviderInsuranceIdentifier(request: AdminCreateProviderInsuranceIdentifierRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/employee/addProviderInsuranceIdentifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminCreateProviderInsuranceIdentifierRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['providers', 'employees']})
	createEmployee(request: AdminCreateEmployeeRequest ): Observable<AdminEmployeeDetailedResponse> {
		const endpointUrl = `/pms2/ws/admin/employee/createEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEmployeeDetailedResponse, AdminCreateEmployeeRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['providers', 'employees']})
	deactivateEmployee(employeeId: number): Observable<AdminEmployeeForEmployeeListResponse> {
		const endpointUrl = `/pms2/ws/admin/employee/deactivateEmployee/${employeeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEmployeeForEmployeeListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	deleteProviderInsuranceIdentifier(providerInsuranceIdentifierId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/employee/deleteProviderInsuranceIdentifier/${providerInsuranceIdentifierId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findDefaultEmployeePreferences(): Observable<EmployeePreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/employee/findDefaultEmployeePreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeePreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeEmployeesForEmployeeList(): Observable<AdminEmployeeForEmployeeListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/employee/findPracticeEmployeesForEmployeeList`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEmployeeForEmployeeListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getDetailedEmployeeById(employeeId: number): Observable<AdminEmployeeDetailedResponse> {
		const endpointUrl = `/pms2/ws/admin/employee/getDetailedEmployeeById/${employeeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEmployeeDetailedResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeRxntStatus(): Observable<boolean> {
		const endpointUrl = `/pms2/ws/admin/employee/getPracticeRxntStatus`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, null, null );
		
		return configuredEndpoint;
	}

	
	resetEmployeePassword(employeeId: number): Observable<TemporaryPasswordResponse> {
		const endpointUrl = `/pms2/ws/admin/employee/resetEmployeePassword/${employeeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TemporaryPasswordResponse, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['providers', 'employees']})
	updateEmployee(request: AdminUpdateEmployeeRequest ): Observable<AdminEmployeeDetailedResponse> {
		const endpointUrl = `/pms2/ws/admin/employee/updateEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEmployeeDetailedResponse, AdminUpdateEmployeeRequest, request );
		
		return configuredEndpoint;
	}

	
	updateProviderInsuranceIdentifier(request: AdminUpdateProviderInsuranceIdentifierRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/employee/updateProviderInsuranceIdentifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminUpdateProviderInsuranceIdentifierRequest, request );
		
		return configuredEndpoint;
	}

	
	validatePracticeLocationInformationForRevDirectRegistration(practiceLocationId: number): Observable<boolean> {
		const endpointUrl = `/pms2/ws/admin/employee/validatePracticeLocationInformationForRevDirectRegistration/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.employee.credential.AdminEmployeeExternalCredentialController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminEmployeeExternalCredentialGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createEmployeeExternalCredential(request: AdminCreateEmployeeExternalCredentialRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/employee/externalCredential/createEmployeeExternalCredential`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminCreateEmployeeExternalCredentialRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateEmployeeExternalCredential(employeeExternalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/employee/externalCredential/deactivateEmployeeExternalCredential/${employeeExternalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findEmployeeExternalCredentials(employeeId: number): Observable<AdminEmployeeExternalCredentialResponse[]> {
		const endpointUrl = `/pms2/ws/admin/employee/externalCredential/findEmployeeExternalCredentials/${employeeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEmployeeExternalCredentialResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateEmployeeExternalCredential(request: AdminUpdateEmployeeExternalCredentialRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/employee/externalCredential/updateEmployeeExternalCredential`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminUpdateEmployeeExternalCredentialRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.employee.AdminEmployeeLimitedViewController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminEmployeeLimitedViewGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findActiveEmployeesForEmployeeList(): Observable<AdminEmployeeLimitedResponse[]> {
		const endpointUrl = `/pms2/ws/admin/employeelimitedView/findActiveEmployeesForEmployeeList`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEmployeeLimitedResponse, null, null );
		
		return configuredEndpoint;
	}

	
	resetEmployeePassword(employeeId: number): Observable<TemporaryPasswordResponse> {
		const endpointUrl = `/pms2/ws/admin/employeelimitedView/resetEmployeePassword/${employeeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TemporaryPasswordResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.encounter.AdminEncounterAndInterviewController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminEncounterAndInterviewGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	@GandalfCacheBuster({cacheKeys: ['encounter_templates']})
	activateEncounterTemplateById(encounterTemplateId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/activateEncounterTemplateById/${encounterTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	activateInterviewTemplate(interviewTemplateId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/activateInterviewTemplate/${interviewTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['encounter_templates']})
	createEncounterTemplate(request: CreateEncounterTemplateRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/createEncounterTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateEncounterTemplateRequest, request );
		
		return configuredEndpoint;
	}

	
	createInterviewTemplate(request: CreateInterviewTemplateRequest ): Observable<InterviewTemplateDetailResponse> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/createInterviewTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewTemplateDetailResponse, CreateInterviewTemplateRequest, request );
		
		return configuredEndpoint;
	}

	
	createWorkflowStepTemplate(request: CreateWorkflowStepTemplateRequest ): Observable<WorkflowStepTemplateResponse> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/createWorkflowStepTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WorkflowStepTemplateResponse, CreateWorkflowStepTemplateRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['encounter_templates']})
	deactivateEncounterTemplateById(encounterTemplateId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/deactivateEncounterTemplateById/${encounterTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deactivateInterviewTemplate(interviewTemplateId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/deactivateInterviewTemplate/${interviewTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveInterviewTemplates(): Observable<InterviewTemplateSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/findActiveInterviewTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewTemplateSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInterviewTemplates(): Observable<InterviewTemplateSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/findInterviewTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewTemplateSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeEncounterTemplates(): Observable<EncounterTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/findEncounterTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findWorkflowStepTemplates(): Observable<WorkflowStepTemplateResponse[]> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/findWorkflowStepTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WorkflowStepTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEncounterTemplateById(encounterTemplateId: number): Observable<AdminEncounterTemplateResponse> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/findEncounterTemplateById/${encounterTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEncounterTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getInterviewTemplate(interviewTemplateId: number): Observable<InterviewTemplateDetailResponse> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/getInterviewTemplateById/${interviewTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewTemplateDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getWorkflowStepTemplateById(workflowStepTemplateId: number): Observable<WorkflowStepTemplateResponse> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/getWorkflowStepTemplateById/${workflowStepTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WorkflowStepTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['encounter_templates']})
	updateEncounterTemplate(request: UpdateEncounterTemplateRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/updateEncounterTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateEncounterTemplateRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInterviewTemplate(request: UpdateInterviewTemplateRequest ): Observable<InterviewTemplateDetailResponse> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/updateInterviewTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewTemplateDetailResponse, UpdateInterviewTemplateRequest, request );
		
		return configuredEndpoint;
	}

	
	updateWorkflowStepTemplate(request: UpdateWorkflowStepTemplateRequest ): Observable<WorkflowStepTemplateResponse> {
		const endpointUrl = `/pms2/ws/admin/encountersAndInterviews/updateWorkflowStepTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WorkflowStepTemplateResponse, UpdateWorkflowStepTemplateRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.encounter.examtestlibrary.AdminExamTestLibraryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminExamTestLibraryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createServiceTrigger(request: AdminCreateWorkflowTestTemplateSvcTriggerRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encounter/examTestLibrary/createServiceTrigger`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminCreateWorkflowTestTemplateSvcTriggerRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteServiceTriggerById(serviceTriggerId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encounter/examTestLibrary/deleteServiceTrigger/${serviceTriggerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findFormFieldsByWorkflowTestTemplateId(workflowTestTemplateId: number): Observable<AdminWorkflowTestTemplateFormFieldsResponse> {
		const endpointUrl = `/pms2/ws/admin/encounter/examTestLibrary/findFormFieldsByWorkflowTestTemplateId/${workflowTestTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowTestTemplateFormFieldsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findServiceTriggersByTemplateAndPracticeLocation(request: AdminFindWorkflowTestTemplateSvcTriggerRequest ): Observable<AdminWorkflowTestTemplateSvcTriggerResponse[]> {
		const endpointUrl = `/pms2/ws/admin/encounter/examTestLibrary/findServiceTriggersByTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowTestTemplateSvcTriggerResponse, AdminFindWorkflowTestTemplateSvcTriggerRequest, request );
		
		return configuredEndpoint;
	}

	
	getServicetriggerById(serviceTriggerId: number): Observable<AdminWorkflowTestTemplateSvcTriggerDetailResponse> {
		const endpointUrl = `/pms2/ws/admin/encounter/examTestLibrary/getServiceTriggerById/${serviceTriggerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowTestTemplateSvcTriggerDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateServiceTrigger(request: AdminUpdateWorkflowTestTemplateSvcTriggerRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/encounter/examTestLibrary/updateServiceTrigger`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminUpdateWorkflowTestTemplateSvcTriggerRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.externalprovider.AdminExternalProviderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminExternalProviderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	@GandalfCacheBuster({cacheKeys: ['providers']})
	activateProvider(providerId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/activateProvider/${providerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	addProviderInsuranceIdentifier(request: CreateProviderInsuranceIdentifierRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/addProviderInsuranceIdentifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, CreateProviderInsuranceIdentifierRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['providers']})
	createExternalProvider(request: CreateExternalProviderRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/createExternalProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, CreateExternalProviderRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['providers']})
	deactivateProvider(providerId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/deactivateProvider/${providerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deleteProviderInsuranceIdentifier(request: DeleteProviderInsuranceIdentifierRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/deleteProviderInsuranceIdentifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, DeleteProviderInsuranceIdentifierRequest, request );
		
		return configuredEndpoint;
	}

	
	findExternalProviders(): Observable<ExternalProviderResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/findExternalProviders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getProviderById(providerId: number): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/getProviderById/${providerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['providers']})
	updateExternalProvider(request: UpdateExternalProviderRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/updateExternalProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, UpdateExternalProviderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateProviderInsuranceIdentifier(request: UpdateProviderInsuranceIdentifierRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/externalProvider/updateProviderInsuranceIdentifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, UpdateProviderInsuranceIdentifierRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeScheduleController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminEyefinityGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateEyefinityFeeSchedule(eyefinityFeeScheduleId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/eyefinity/activateEyefinityFeeSchedule/${eyefinityFeeScheduleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	copyEyefinityFeeScheduleToLocation(request: AdminCopyEyefinityFeeScheduleToLocationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/eyefinity/copyEyefinityFeeScheduleToLocation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminCopyEyefinityFeeScheduleToLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	createEyefinityFeeSchedule(request: AdminCreateEyefinityFeeScheduleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/eyefinity/createEyefinityFeeSchedule`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminCreateEyefinityFeeScheduleRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateEyefinityFeeSchedule(eyefinityFeeScheduleId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/eyefinity/deactivateEyefinityFeeSchedule/${eyefinityFeeScheduleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveFeeScheduleTemplates(): Observable<AdminEyefinityFeeScheduleTemplateResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/eyefinity/findActiveFeeScheduleTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEyefinityFeeScheduleTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findByPracticeLocationId(practiceLocationId: number): Observable<AdminEyefinityFeeScheduleListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/eyefinity/findByPracticeLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEyefinityFeeScheduleListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEyefinityFeeScheduleById(eyefinityFeeScheduleId: number): Observable<AdminEyefinityFeeScheduleResponse> {
		const endpointUrl = `/pms2/ws/admin/accounting/eyefinity/getEyefinityFeeScheduleById/${eyefinityFeeScheduleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminEyefinityFeeScheduleResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateEyefinityFeeSchedule(request: AdminUpdateEyefinityFeeScheduleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/eyefinity/updateEyefinityFeeSchedule`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdminUpdateEyefinityFeeScheduleRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.FeeScheduleController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminFeeScheduleGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateFeeScheduleById(feeScheduleId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/activateFeeScheduleById/${feeScheduleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	assignFeeScheduleItems(request: AssignFeeScheduleItemListRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/assignFeeScheduleItems`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AssignFeeScheduleItemListRequest, request );
		
		return configuredEndpoint;
	}

	
	copyFeeScheduleRange(request: CopyFeeScheduleRangeRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/copyFeeScheduleRange`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CopyFeeScheduleRangeRequest, request );
		
		return configuredEndpoint;
	}

	
	createFeeSchedule(request: CreateFeeScheduleRequest ): Observable<FeeScheduleResponse> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/createFeeSchedule`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeeScheduleResponse, CreateFeeScheduleRequest, request );
		
		return configuredEndpoint;
	}

	
	createFeeScheduleRange(request: CreateFeeScheduleRangeRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/createFeeScheduleRange`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateFeeScheduleRangeRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateFeeScheduleById(feeScheduleId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/deactivateFeeScheduleById/${feeScheduleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findFeeScheduleItemProducts(request: SearchFeeScheduleItemsRequest ): Observable<FeeScheduleItemProductResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/findFeeScheduleItemProducts`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeeScheduleItemProductResponse, SearchFeeScheduleItemsRequest, request );
		
		return configuredEndpoint;
	}

	
	findFeeScheduleItemServiceCpts(request: SearchFeeScheduleItemsRequest ): Observable<FeeScheduleItemServiceCptResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/findFeeScheduleItemServiceCpts`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeeScheduleItemServiceCptResponse, SearchFeeScheduleItemsRequest, request );
		
		return configuredEndpoint;
	}

	
	findFeeScheduleItemServiceOthers(request: SearchFeeScheduleItemsRequest ): Observable<FeeScheduleItemServiceOtherResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/findFeeScheduleItemServiceOthers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeeScheduleItemServiceOtherResponse, SearchFeeScheduleItemsRequest, request );
		
		return configuredEndpoint;
	}

	
	findFeeSchedulesForPractice(): Observable<FeeScheduleListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/findFeeSchedulesForPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeeScheduleListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMainCatalogProductCategories(): Observable<ProductCategoryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/findMainCatalogProductCategories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductCategoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getFeeScheduleById(feeScheduleId: number): Observable<FeeScheduleResponse> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/getFeeScheduleById/${feeScheduleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeeScheduleResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getFeeScheduleRangeById(feeScheduleRangeId: number): Observable<FeeScheduleRangeResponse> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/getFeeScheduleRangeById/${feeScheduleRangeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeeScheduleRangeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateFeeSchedule(request: UpdateFeeScheduleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/updateFeeSchedule`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateFeeScheduleRequest, request );
		
		return configuredEndpoint;
	}

	
	updateFeeScheduleRange(request: UpdateFeeScheduleRangeRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/accounting/feeSchedule/updateFeeScheduleRange`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateFeeScheduleRangeRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.general.AdminGeneralController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminGeneralGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	employeeCanSignAgreements(): Observable<EmployeeCanSignAgreementsResponse> {
		const endpointUrl = `/pms2/ws/admin/general/employeeCanSignAgreements`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeCanSignAgreementsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPractice(): Observable<PracticeGeneralInfoResponse> {
		const endpointUrl = `/pms2/ws/admin/general/findPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeGeneralInfoResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeSystemPreferences(): Observable<SystemPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/general/findPracticeSystemPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SystemPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getCustomerAgreements(): Observable<CustomerAgreementsResponse> {
		const endpointUrl = `/pms2/ws/admin/general/getCustomerAgreements`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CustomerAgreementsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getCustomerContacts(): Observable<CustomerContactsResponse> {
		const endpointUrl = `/pms2/ws/admin/general/getCustomerContacts`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CustomerContactsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	signBusinessAgreement(): Observable<CustomerAgreementsResponse> {
		const endpointUrl = `/pms2/ws/admin/general/signBusinessAgreement`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CustomerAgreementsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	signSoftwareAgreement(): Observable<CustomerAgreementsResponse> {
		const endpointUrl = `/pms2/ws/admin/general/signSoftwareAgreement`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CustomerAgreementsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateCustomerContacts(request: CustomerContactsUpdateRequest ): Observable<CustomerContactsResponse> {
		const endpointUrl = `/pms2/ws/admin/general/updateCustomerContacts`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CustomerContactsResponse, CustomerContactsUpdateRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePractice(request: UpdatePracticeGeneralInfoRequest ): Observable<PracticeGeneralInfoResponse> {
		const endpointUrl = `/pms2/ws/admin/general/updatePractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeGeneralInfoResponse, UpdatePracticeGeneralInfoRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePracticeSystemPreferences(request: UpdatePasswordPolicyRequest ): Observable<SystemPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/general/updatePracticeSystemPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SystemPreferencesResponse, UpdatePasswordPolicyRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.AdminInsuranceCompaniesController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminInsuranceCompaniesGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePracticeInsuranceCompany(practiceInsuranceCompanyId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/insuranceCompanies/activatePracticeInsuranceCompany/${practiceInsuranceCompanyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['insurance_companies']})
	createPracticeInsuranceCompany(request: CreatePracticeInsuranceCompanyRequest ): Observable<PracticeInsuranceCompanyResponse> {
		const endpointUrl = `/pms2/ws/admin/insuranceCompanies/createPracticeInsuranceCompany`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceCompanyResponse, CreatePracticeInsuranceCompanyRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePracticeInsuranceCompany(practiceInsuranceCompanyId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/insuranceCompanies/deactivatePracticeInsuranceCompany/${practiceInsuranceCompanyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findCreditsByInsuranceCompany(practiceInsuranceCompanyId: number): Observable<AdminInsuranceCreditResponse> {
		const endpointUrl = `/pms2/ws/admin/insuranceCompanies/findCreditsByInsuranceCompany/${practiceInsuranceCompanyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminInsuranceCreditResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeInsuranceCompanies(): Observable<AdminPracticeInsuranceCompanySummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/insuranceCompanies/findPracticeInsuranceCompanies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminPracticeInsuranceCompanySummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findRefundsByInsuranceCompany(practiceInsuranceCompanyId: number): Observable<AdminInsuranceRefundResponse> {
		const endpointUrl = `/pms2/ws/admin/insuranceCompanies/findRefundsByInsuranceCompany/${practiceInsuranceCompanyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminInsuranceRefundResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeInsuranceCompany(practiceInsuranceCompanyId: number): Observable<PracticeInsuranceCompanyResponse> {
		const endpointUrl = `/pms2/ws/admin/insuranceCompanies/getPracticeInsuranceCompany/${practiceInsuranceCompanyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceCompanyResponse, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['insurance_companies']})
	updatePracticeInsuranceCompany(request: UpdatePracticeInsuranceCompanyRequest ): Observable<PracticeInsuranceCompanyResponse> {
		const endpointUrl = `/pms2/ws/admin/insuranceCompanies/updatePracticeInsuranceCompany`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceCompanyResponse, UpdatePracticeInsuranceCompanyRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.general.location.AdminLocationController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminLocationGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	@GandalfCacheBuster({cacheKeys: ['locations']})
	createPracticeLocation(request: AdminCreatePracticeLocationRequest ): Observable<AdminPracticeLocationResponse> {
		const endpointUrl = `/pms2/ws/admin/location/createPracticeLocation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminPracticeLocationResponse, AdminCreatePracticeLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveFinanceChargePlansForDropdown(): Observable<FinanceChargePlanNameResponse[]> {
		const endpointUrl = `/pms2/ws/admin/location/findActiveFinanceChargePlansForDropdown`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FinanceChargePlanNameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeLocationAndSubscribers(practiceLocationId: number): Observable<AdminPracticeLocationResponse> {
		const endpointUrl = `/pms2/ws/admin/location/findPracticeLocationAndSubscribers/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminPracticeLocationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeLocationPhrAccessCodes(practiceLocationId: number): Observable<PracticeLocationAccessCodeResponse[]> {
		const endpointUrl = `/pms2/ws/admin/location/findPracticeLocationPhrAccessCodes/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationAccessCodeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeLocationsForList(): Observable<AdminPracticeLocationAddressResponse[]> {
		const endpointUrl = `/pms2/ws/admin/location/findPracticeLocationsForList`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminPracticeLocationAddressResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findTaxesByPracticeLocationId(practiceLocationId: number): Observable<AdminLocationTaxResponse[]> {
		const endpointUrl = `/pms2/ws/admin/location/findTaxesByPracticeLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminLocationTaxResponse, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['get_office_hours', 'locations', 'visionweb_suppliers']})
	updatePracticeLocation(request: AdminUpdatePracticeLocationRequest ): Observable<AdminPracticeLocationResponse> {
		const endpointUrl = `/pms2/ws/admin/location/updatePracticeLocation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminPracticeLocationResponse, AdminUpdatePracticeLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePracticeLocationTaxes(request: UpdatePracticeLocationTaxesRequest ): Observable<AdminLocationTaxResponse[]> {
		const endpointUrl = `/pms2/ws/admin/location/updatePracticeLocationTaxes`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminLocationTaxResponse, UpdatePracticeLocationTaxesRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.manufacturer.AdminManufacturerController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminManufacturerGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateManufacturer(manufacturerId: number): Observable<ManufacturerSummaryResponse> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/activateManufacturer/${manufacturerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ManufacturerSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	activateProductBrand(productBrandId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/activateProductBrand/${productBrandId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	activateProductBrandCollection(productBrandCollectionId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/activateProductBrandCollection/${productBrandCollectionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	addProductBrand(request: CreateProductBrandRequest ): Observable<ManufacturerResponse> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/addProductBrand`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ManufacturerResponse, CreateProductBrandRequest, request );
		
		return configuredEndpoint;
	}

	
	addProductBrandCollection(request: CreateProductBrandCollectionRequest ): Observable<ProductBrandResponse> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/addProductBrandCollection`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductBrandResponse, CreateProductBrandCollectionRequest, request );
		
		return configuredEndpoint;
	}

	
	createManufacturer(request: CreateManufacturerRequest ): Observable<ManufacturerResponse> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/createManufacturer`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ManufacturerResponse, CreateManufacturerRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateManufacturer(manufacturerId: number): Observable<ManufacturerSummaryResponse> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/deactivateManufacturer/${manufacturerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ManufacturerSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	deactivateProductBrand(productBrandId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/deactivateProductBrand/${productBrandId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deactivateProductBrandCollection(productBrandCollectionId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/deactivateProductBrandCollection/${productBrandCollectionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveSmartflowMasterManufacturers(): Observable<SmartflowMasterManufacturerResponse[]> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/findActiveSmartflowMasterManufacturers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SmartflowMasterManufacturerResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeManufacturers(): Observable<ManufacturerSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/findPracticeManufacturers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ManufacturerSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findProductBrandsByManufacturerId(manufacturerId: number): Observable<ProductBrandResponse[]> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/findProductBrandsByManufacturerId/${manufacturerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductBrandResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findProductCategories(): Observable<ProductCategoryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/findProductCategories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductCategoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getManufacturerById(manufacturerId: number): Observable<ManufacturerResponse> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/getManufacturerById/${manufacturerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ManufacturerResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateManufacturer(request: UpdateManufacturerRequest ): Observable<ManufacturerResponse> {
		const endpointUrl = `/pms2/ws/admin/manufacturers/updateManufacturer`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ManufacturerResponse, UpdateManufacturerRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.dataconfiguration.medication.MedicationController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminMedicationGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createPracticeDrug(request: CreatePracticeDrugRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/medication/createPracticeDrug`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePracticeDrugRequest, request );
		
		return configuredEndpoint;
	}

	
	deletePracticeDrug(practiceDrugId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/medication/deletePracticeDrug/${practiceDrugId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeDrugById(practiceDrugId: number): Observable<PracticeDrugResponse> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/medication/getPracticeDrugById/${practiceDrugId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeDrugResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePracticeDrug(request: UpdatePracticeDrugRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/dataConfiguration/medication/updatePracticeDrug`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePracticeDrugRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.solutions.openedge.AdminOpenEdgeController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminOpenEdgeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createOpenEdgeSettings(request: CreateOpenEdgeSettingsRequest ): Observable<OpenEdgeSettingsResponse> {
		const endpointUrl = `/pms2/ws/admin/solutions/openedge/createOpenEdgeSettings`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeSettingsResponse, CreateOpenEdgeSettingsRequest, request );
		
		return configuredEndpoint;
	}

	
	disableOpenEdgeSettings(openEdgeSettingsId: number): Observable<OpenEdgeSettingsResponse> {
		const endpointUrl = `/pms2/ws/admin/solutions/openedge/disableOpenEdgeSettings/${openEdgeSettingsId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeSettingsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	enableOpenEdgeSettings(openEdgeSettingsId: number): Observable<OpenEdgeSettingsResponse> {
		const endpointUrl = `/pms2/ws/admin/solutions/openedge/enableOpenEdgeSettings/${openEdgeSettingsId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeSettingsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findLocationsByPaymentProcessor(request: ExternalCredentialLocationRequest ): Observable<ExternalCredentialLocationResponse[]> {
		const endpointUrl = `/pms2/ws/admin/solutions/openedge/findLocationsByPaymentProcessor`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalCredentialLocationResponse, ExternalCredentialLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	findPracticeLocationsForOpenEdge(): Observable<OpenEdgePracticeLocationResponse[]> {
		const endpointUrl = `/pms2/ws/admin/solutions/openedge/findPracticeLocationsForOpenEdge`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgePracticeLocationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getOpenEdgeSettingsById(openEdgeSettingsId: number): Observable<OpenEdgeSettingsResponse> {
		const endpointUrl = `/pms2/ws/admin/solutions/openedge/getOpenEdgeSettingsById/${openEdgeSettingsId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeSettingsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	isPayOnlineUrlPathValid(request: IsPayOnlineUrlPathValidRequest ): Observable<boolean> {
		const endpointUrl = `/pms2/ws/admin/solutions/openedge/isPayOnlineUrlPathValid`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, IsPayOnlineUrlPathValidRequest, request );
		
		return configuredEndpoint;
	}

	
	updateOpenEdgeSettings(request: UpdateOpenEdgeSettingsRequest ): Observable<OpenEdgeSettingsResponse> {
		const endpointUrl = `/pms2/ws/admin/solutions/openedge/updateOpenEdgeSettings`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeSettingsResponse, UpdateOpenEdgeSettingsRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.practiceinsuranceremittancecodeadjustment.PracticeInsuranceRemittanceCodeAdjustmentController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminPracticeInsuranceRemittanceCodeAdjustmentGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePracticeInsuranceRemittanceCodeAdjustment(practiceInsuranceRemittanceCodeAdjustmentId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/practiceInsuranceRemittanceCodeAdjustment/activatePracticeInsuranceRemittanceCodeAdjustment/${practiceInsuranceRemittanceCodeAdjustmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createPracticeInsuranceRemittanceCodeAdjustment(request: CreatePracticeInsuranceRemittanceCodeAdjustmentRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/practiceInsuranceRemittanceCodeAdjustment/createPracticeInsuranceRemittanceCodeAdjustment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePracticeInsuranceRemittanceCodeAdjustmentRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePracticeInsuranceRemittanceCodeAdjustment(practiceInsuranceRemittanceCodeAdjustmentId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/practiceInsuranceRemittanceCodeAdjustment/deactivatePracticeInsuranceRemittanceCodeAdjustment/${practiceInsuranceRemittanceCodeAdjustmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeInsuranceRemittanceCodeAdjustmentByPracticeInsuranceCompany(practiceInsuranceCompanyId: number): Observable<PracticeInsuranceRemittanceCodeAdjustmentResponse[]> {
		const endpointUrl = `/pms2/ws/admin/practiceInsuranceRemittanceCodeAdjustment/findPracticeInsuranceRemittanceCodeAdjustmentByPracticeInsuranceCompany/${practiceInsuranceCompanyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceRemittanceCodeAdjustmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePracticeInsuranceRemittanceCodeAdjustment(request: UpdatePracticeInsuranceRemittanceCodeAdjustmentRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/practiceInsuranceRemittanceCodeAdjustment/updatePracticeInsuranceRemittanceCodeAdjustment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePracticeInsuranceRemittanceCodeAdjustmentRequest, request );
		
		return configuredEndpoint;
	}

	
	x12CodeExists(request: X12CodeExistsRequest ): Observable<boolean> {
		const endpointUrl = `/pms2/ws/admin/practiceInsuranceRemittanceCodeAdjustment/x12CodeExists`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, X12CodeExistsRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.practicepreferences.PracticePreferencesController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminPracticePreferencesGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createConnectPractice(request: CreateConnectPracticeRequest ): Observable<ConnectPracticeResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/createConnectPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectPracticeResponse, CreateConnectPracticeRequest, request );
		
		return configuredEndpoint;
	}

	
	findAdditionalPracticePreferences(): Observable<AdditionalPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/findAdditionalPracticePreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdditionalPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findClaimProcessingPreferenceForPractice(): Observable<ClaimProcessingPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/findClaimProcessingPreferenceForPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ClaimProcessingPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findCommunicationsPreferences(): Observable<CommunicationsPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/findCommunicationsPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CommunicationsPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findConnectPracticeForCurrentPractice(): Observable<ConnectPracticeResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/findConnectPracticeByPracticeId`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectPracticeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInsuranceClaimProcessors(): Observable<InsuranceClaimProcessorResponse[]> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/findInsuranceClaimProcessors`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InsuranceClaimProcessorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPaymentPreferences(): Observable<PaymentPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/findPaymentPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateAdditionalPreferences(request: AdditionalPreferencesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/updateAdditionalPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AdditionalPreferencesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateClaimProcessingPreferenceForPractice(request: UpdateClaimProcessingPreferencesRequest ): Observable<ClaimProcessingPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/updateClaimProcessingPreferenceForPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ClaimProcessingPreferencesResponse, UpdateClaimProcessingPreferencesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCommunicationsPreferences(request: UpdateCommunicationsPreferencesRequest ): Observable<CommunicationsPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/updateCommunicationsPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CommunicationsPreferencesResponse, UpdateCommunicationsPreferencesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateConnectPractice(request: UpdateConnectPracticeRequest ): Observable<ConnectPracticeResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/updateConnectPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectPracticeResponse, UpdateConnectPracticeRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePaymentPreferences(request: UpdatePaymentPreferencesRequest ): Observable<PaymentPreferencesResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/updatePaymentPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentPreferencesResponse, UpdatePaymentPreferencesRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.solutions.revclear.AdminRevClearController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminRevClearGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createRevClearExternalCredential(request: CreateRevClearExternalCredentialRequest ): Observable<RevClearExternalCredentialResponse> {
		const endpointUrl = `/pms2/ws/admin/revClear/createRevClearExternalCredential`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RevClearExternalCredentialResponse, CreateRevClearExternalCredentialRequest, request );
		
		return configuredEndpoint;
	}

	
	disableRevClearExternalCredential(revClearExternalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/revClear/disableRevClearExternalCredential/${revClearExternalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	enableRevClearExternalCredential(revClearExternalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/revClear/enableRevClearExternalCredential/${revClearExternalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findAllRevClearExternalCredentials(): Observable<RevClearExternalCredentialResponse[]> {
		const endpointUrl = `/pms2/ws/admin/revClear/findAllRevClearExternalCredentials`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RevClearExternalCredentialResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findEmployeePersonIdsForNotifications(revClearExternalCredentialId: number): Observable<PersonsToBeNotifiedResponse> {
		const endpointUrl = `/pms2/ws/admin/revClear/findEmployeePersonIdsForNotifications/${revClearExternalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonsToBeNotifiedResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findRevClearConfiguration(): Observable<RevClearConfigurationResponse> {
		const endpointUrl = `/pms2/ws/admin/revClear/findRevClearConfiguration`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RevClearConfigurationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateNotificationPersons(request: UpdateNotificationPersonsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/revClear/updateNotificationPersons`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateNotificationPersonsRequest, request );
		
		return configuredEndpoint;
	}

	
	updateRevClearConfiguration(request: UpdateRevClearConfigurationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/revClear/updateRevClearConfiguration`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateRevClearConfigurationRequest, request );
		
		return configuredEndpoint;
	}

	
	updateRevClearExternalCredential(request: UpdateRevClearExternalCredentialRequest ): Observable<RevClearExternalCredentialResponse> {
		const endpointUrl = `/pms2/ws/admin/revClear/updateRevClearExternalCredential`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RevClearExternalCredentialResponse, UpdateRevClearExternalCredentialRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.solutions.smartflow.AdminSmartflowController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminSmartflowGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findSmartflowPartners(): Observable<SmartflowSupplierResponse[]> {
		const endpointUrl = `/pms2/ws/admin/smartflow/findSmartflowPartners`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SmartflowSupplierResponse, null, null );
		
		return configuredEndpoint;
	}

	
	isEmployeeCustomerContact(): Observable<EmployeeIsCustomerContactResponse> {
		const endpointUrl = `/pms2/ws/admin/smartflow/isEmployeeCustomerContact`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeIsCustomerContactResponse, null, null );
		
		return configuredEndpoint;
	}

	
	optInToSmartflow(smartflowMasterSupplierId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/smartflow/optInToSmartflow/${smartflowMasterSupplierId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	optOutOfSmartflow(smartflowPracticeSupplierId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/smartflow/optOutOfSmartflow/${smartflowPracticeSupplierId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	signGpoAgreement(): Observable<CustomerSmartflowAgreementsResponse> {
		const endpointUrl = `/pms2/ws/admin/smartflow/signGpoAgreement`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CustomerSmartflowAgreementsResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.encounter.screenlibrary.AdminWorkflowScreenTemplateController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminWorkflowScreenTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createWorkflowScreenTemplate(request: AdminCreateWorkflowScreenTemplateRequest ): Observable<AdminWorkflowScreenTemplateResponse> {
		const endpointUrl = `/pms2/ws/admin/encounter/screenlibrary/createWorkflowScreenTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowScreenTemplateResponse, AdminCreateWorkflowScreenTemplateRequest, request );
		
		return configuredEndpoint;
	}

	
	findScreenTemplatesByPractice(): Observable<AdminWorkflowScreenTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/encounter/screenlibrary/findScreenTemplatesByPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowScreenTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getById(workflowScreenTemplateId: number): Observable<AdminWorkflowScreenTemplateResponse> {
		const endpointUrl = `/pms2/ws/admin/encounter/screenlibrary/getById/${workflowScreenTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowScreenTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateWorkflowScreenTemplate(request: AdminUpdateWorkflowScreenTemplateRequest ): Observable<AdminWorkflowScreenTemplateResponse> {
		const endpointUrl = `/pms2/ws/admin/encounter/screenlibrary/updateWorkflowScreenTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowScreenTemplateResponse, AdminUpdateWorkflowScreenTemplateRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.encounter.testlibrary.AdminWorkflowTestTemplateController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AdminWorkflowTestTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findTestTemplatesByPractice(): Observable<AdminWorkflowTestTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/admin/encounter/testlibrary/findTestTemplatesByPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowTestTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateWorkflowTestTemplate(request: AdminUpdateWorkflowTestTemplateRequest ): Observable<AdminWorkflowTestTemplateListResponse> {
		const endpointUrl = `/pms2/ws/admin/encounter/testlibrary/updateWorkflowTestTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminWorkflowTestTemplateListResponse, AdminUpdateWorkflowTestTemplateRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.person.AllergyController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AllergyGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	acceptIntakeAllergies(request: UpdateIntakeAllergiesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/acceptIntakeAllergies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateIntakeAllergiesRequest, request );
		
		return configuredEndpoint;
	}

	
	activateAllergy(allergyId: number): Observable<AllergyListResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/activateAllergy/${allergyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AllergyListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	createDrugAllergy(request: CreateDrugAllergyRequest ): Observable<DrugAllergyResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/createDrugAllergy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DrugAllergyResponse, CreateDrugAllergyRequest, request );
		
		return configuredEndpoint;
	}

	
	createOtherAllergy(request: CreateOtherAllergyRequest ): Observable<OtherAllergyResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/createOtherAllergy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OtherAllergyResponse, CreateOtherAllergyRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateAllergy(allergyId: number): Observable<AllergyListResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/deactivateAllergy/${allergyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AllergyListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	declineIntakeAllergies(request: UpdateIntakeAllergiesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/declineIntakeAllergies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateIntakeAllergiesRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveDrugAllergiesByPatientId(patientId: number): Observable<DrugAllergyResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/findActiveDrugAllergiesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DrugAllergyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findAllergiesByPatientId(patientId: number): Observable<AllergyListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/findAllergiesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AllergyListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findDrugAllergiesByPatientId(patientId: number): Observable<DrugAllergyResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/findDrugAllergiesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DrugAllergyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMostRecentIntakeByPatientId(patientId: number): Observable<IntakeAllergyResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/findMostRecentIntakeByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, IntakeAllergyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getDrugAllergyById(allergyId: number): Observable<DrugAllergyResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/getDrugAllergyById/${allergyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DrugAllergyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getOtherAllergyById(allergyId: number): Observable<OtherAllergyResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/getOtherAllergyById/${allergyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OtherAllergyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateDrugAllergy(request: UpdateDrugAllergyRequest ): Observable<DrugAllergyResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/updateDrugAllergy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DrugAllergyResponse, UpdateDrugAllergyRequest, request );
		
		return configuredEndpoint;
	}

	
	updateOtherAllergy(request: UpdateOtherAllergyRequest ): Observable<OtherAllergyResponse> {
		const endpointUrl = `/pms2/ws/patient/person/allergy/updateOtherAllergy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OtherAllergyResponse, UpdateOtherAllergyRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.allergy.AllergyReviewHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AllergyReviewHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createAllergyReviewHistory(request: CreateAllergyReviewHistoryRequest ): Observable<AllergyReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/patient/emr/history/createAllergyReviewHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AllergyReviewHistoryResponse, CreateAllergyReviewHistoryRequest, request );
		
		return configuredEndpoint;
	}

	
	findMostRecentAllergyReviewByEncounterId(encounterId: number): Observable<AllergyReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/patient/emr/history/findMostRecentAllergyReviewByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AllergyReviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMostRecentAllergyReviewByPatientId(patientId: number): Observable<AllergyReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/patient/emr/history/findMostRecentAllergyReviewByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AllergyReviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getById(allergyReviewHistoryId: number): Observable<AllergyReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/patient/emr/history/getById/${allergyReviewHistoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AllergyReviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateAllergyReviewHistory(request: UpdateAllergyReviewHistoryRequest ): Observable<AllergyReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/patient/emr/history/updateAllergyReviewHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AllergyReviewHistoryResponse, UpdateAllergyReviewHistoryRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.coding.AncillaryParticipantController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AncillaryParticipantGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createAncillaryParticipantToEncounter(request: CreateAncillaryParticipantRequest ): Observable<AncillaryParticipantResponse> {
		const endpointUrl = `/pms2/ws/encounter/ancillaryParticipant/createAncillaryParticipantToEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AncillaryParticipantResponse, CreateAncillaryParticipantRequest, request );
		
		return configuredEndpoint;
	}

	
	findAllAncillaryParticipantsByEncounter(encounterId: number): Observable<AncillaryParticipantResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/ancillaryParticipant/findAllAncillaryParticipantsByEncounter/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AncillaryParticipantResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findAncillaryParticipantByEncounter(encounterId: number): Observable<AncillaryParticipantResponse> {
		const endpointUrl = `/pms2/ws/encounter/ancillaryParticipant/findAncillaryParticipantByEncounter/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AncillaryParticipantResponse, null, null );
		
		return configuredEndpoint;
	}

	
	removeAncillaryParticipantToEncounter(ancillaryParticipantId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/ancillaryParticipant/removeAncillaryParticipantFromEncounter/${ancillaryParticipantId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.appointmenthistory.AppointmentHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AppointmentHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findAppointmentsByPatientId(patientId: number): Observable<AppointmentExamHistoryListResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/appointmentHistory/findAppointmentsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentExamHistoryListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getAppointmentById(appointmentId: number): Observable<AppointmentResponse> {
		const endpointUrl = `/pms2/ws/encounter/appointmentHistory/getAppointmentById/${appointmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateAppointmentDescription(request: UpdateAppointmentDescriptionRequest ): Observable<AppointmentResponse> {
		const endpointUrl = `/pms2/ws/encounter/appointmentHistory/updateAppointmentDescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentResponse, UpdateAppointmentDescriptionRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.assessment.AssessmentController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AssessmentGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getAssessmentByEncounterId(encounterId: number): Observable<AssessmentResponse> {
		const endpointUrl = `/pms2/ws/assessment/getAssessmentByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getAssessmentById(assessmentId: number): Observable<AssessmentResponse> {
		const endpointUrl = `/pms2/ws/assessment/getAssessmentById/${assessmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateAssessment(request: UpdateAssessmentRequest ): Observable<AssessmentResponse> {
		const endpointUrl = `/pms2/ws/assessment/updateAssessment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentResponse, UpdateAssessmentRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.assessmentandplan.AssessmentAndPlanController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AssessmentAndPlanGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	updatePatientTriggerOutcomeStatuses(request: UpdatePatientTriggerRequest[] ): Observable<void> {
		const endpointUrl = `/pms2/ws/assessmentAndPlan/updatePatientTriggerStatuses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientTriggerRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.assessment.AssessmentDiagnosisReviewHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AssessmentDiagnosisReviewHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addAssessmentAutoDiagnosisToEncounter(request: AddAutoDiagnosisToEncounterRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/addAssessmentAutoDiagnosisToEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddAutoDiagnosisToEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	addAssessmentMasterDiagnosisToEncounter(request: AddMasterDiagnosisToEncounterRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/addAssessmentMasterDiagnosisToEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddMasterDiagnosisToEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	createAssessmentDiagnosisReviewHistory(request: CreateAssessmentDiagnosisReviewRequest ): Observable<AssessmentDiagnosisReviewResponse> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/createAssessmentDiagnosisReviewHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentDiagnosisReviewResponse, CreateAssessmentDiagnosisReviewRequest, request );
		
		return configuredEndpoint;
	}

	
	createPersonDiagnosis(request: CreateAssessmentPersonDiagnosisRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/createPersonDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateAssessmentPersonDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	deletePersonDiagnosis(personDiagnosisId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/deletePersonDiagnosis/${personDiagnosisId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePracticeDiagnoses(request: FindActivePracticeDiagnosisForAssessmentRequest ): Observable<AssessmentPracticeDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/findActivePracticeDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentPracticeDiagnosisResponse, FindActivePracticeDiagnosisForAssessmentRequest, request );
		
		return configuredEndpoint;
	}

	
	findAutoDiagnosesByEncounterId(encounterId: number): Observable<AssessmentAutoDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/findAutoDiagnosesByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentAutoDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMasterDiagnosesByPatientId(patientId: number): Observable<AssessmentPersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/findMasterDiagnosesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentPersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMostRecentDiagnosisReviewByEncounterId(encounterId: number): Observable<AssessmentDiagnosisReviewResponse> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/findMostRecentDiagnosisReviewByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentDiagnosisReviewResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPersonDiagnosesByEncounterId(encounterId: number): Observable<AssessmentPersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/findPersonDiagnosesByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentPersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchActivePracticeDiagnoses(request: SearchActivePracticeDiagnosesForAssessmentRequest ): Observable<AssessmentPracticeDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/searchActivePracticeDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AssessmentPracticeDiagnosisResponse, SearchActivePracticeDiagnosesForAssessmentRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePersonDiagnosis(request: UpdateAssessmentPersonDiagnosisRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/updatePersonDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateAssessmentPersonDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePrimaryDiagnosisForEncounter(request: UpdateAssessmentPrimaryDiagnosisForEncounterRequest ): Observable<UpdatedAssessmentPersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/assessmentDiagnosisReviewHistory/updatePrimaryDiagnosisForEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UpdatedAssessmentPersonDiagnosisResponse, UpdateAssessmentPrimaryDiagnosisForEncounterRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.shared.category.AssignCategoryModalController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class AssignCategoryModalGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	assignRelatedCategories(request: AssignRelatedCategoriesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/assignCategoryModal/assignRelatedCategories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AssignRelatedCategoriesRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveByEntityType(request: FindCategoriesRequest ): Observable<CategoryResponse[]> {
		const endpointUrl = `/pms2/ws/assignCategoryModal/findActiveByEntityType`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CategoryResponse, FindCategoriesRequest, request );
		
		return configuredEndpoint;
	}

	
	findRelatedCategoriesForEntity(request: FindRelatedCategoriesRequest ): Observable<RelatedCategoryResponse[]> {
		const endpointUrl = `/pms2/ws/assignCategoryModal/findRelatedCategoriesForEntity`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RelatedCategoryResponse, FindRelatedCategoriesRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.barcode.BarcodeController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class BarcodeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	searchBarcodes(request: BarcodeSearchRequest ): Observable<BarcodeSearchResponse[]> {
		const endpointUrl = `/pms2/ws/barcode/searchBarcodes`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BarcodeSearchResponse, BarcodeSearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.accounting.billing.BillingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class BillingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addBillableItemsToInvoice(request: AddBillableItemsBillingRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/billing/addBillableItemsToInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddBillableItemsBillingRequest, request );
		
		return configuredEndpoint;
	}

	
	createBillingInvoice(request: CreateBillingInvoiceRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/billing/createBillingInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateBillingInvoiceRequest, request );
		
		return configuredEndpoint;
	}

	
	findInvoicesByEncounterId(encounterId: number): Observable<BillingInvoiceResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/billing/findInvoicesByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BillingInvoiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPayersByPatientId(patientId: number): Observable<BillingPayerResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/billing/findPayersByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BillingPayerResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPerformedServicesByEncounterId(encounterId: number): Observable<BillingServiceResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/billing/findPerformedServicesByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BillingServiceResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.bridge.BridgeController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class BridgeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getBridgeExport(request: BridgeExportTokenRequest ): Observable<BridgeExportResponse> {
		const endpointUrl = `/pms2/ws/bridge/getBridgeExport`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BridgeExportResponse, BridgeExportTokenRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.person.diagnosis.CarePlanItemController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class CarePlanItemGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findCarePlanItemsByPersonDiagnosisId(personDiagnosisId: number): Observable<CarePlanItemHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/person/diagnosis/careplanitem/findCarePlanItemsByPersonDiagnosisId/${personDiagnosisId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.shared.diagnosis.CarePlanTemplateController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class CarePlanTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getCarePlanTemplateById(carePlanTemplateId: number): Observable<CarePlanTemplateListResponse> {
		const endpointUrl = `/pms2/ws/diagnosis/carePlanTemplate/getCarePlanTemplateById/${carePlanTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.category.CategoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class CategoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createCategory(request: CreateCategoryRequest ): Observable<CategoryResponse> {
		const endpointUrl = `/pms2/ws/category/createCategory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CategoryResponse, CreateCategoryRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveByEntityType(request: FindCategoriesRequest ): Observable<CategoryResponse[]> {
		const endpointUrl = `/pms2/ws/category/findActiveByEntityType`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CategoryResponse, FindCategoriesRequest, request );
		
		return configuredEndpoint;
	}

	
	findByEntityType(request: FindCategoriesRequest ): Observable<CategoryResponse[]> {
		const endpointUrl = `/pms2/ws/category/findByEntityType`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CategoryResponse, FindCategoriesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCategory(request: UpdateCategoryRequest ): Observable<CategoryResponse> {
		const endpointUrl = `/pms2/ws/category/updateCategory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CategoryResponse, UpdateCategoryRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCategoryOrder(request: UpdateCategorySortOrderRequest[] ): Observable<CategoryResponse[]> {
		const endpointUrl = `/pms2/ws/category/updateCategoryOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CategoryResponse, UpdateCategorySortOrderRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.checkin.CheckinDemographicsController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class CheckinDemographicsGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	generatePassword(): Observable<PatientPasswordResponse> {
		const endpointUrl = `/pms2/ws/encounter/checkin/demographics/generatePassword`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPasswordResponse, null, null );
		
		return configuredEndpoint;
	}

	
	validateUsername(request: ValidateUsernameRequest ): Observable<boolean> {
		const endpointUrl = `/pms2/ws/encounter/checkin/demographics/validateUsername`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, ValidateUsernameRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.checkout.CheckoutTaskController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class CheckoutTaskGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createCheckoutTask(request: CreateCheckoutTaskRequest ): Observable<CheckoutTaskResponse> {
		const endpointUrl = `/pms2/ws/encounter/checkoutTask/createCheckoutTask`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CheckoutTaskResponse, CreateCheckoutTaskRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteCheckoutTask(checkoutTaskId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/checkoutTask/deleteCheckoutTask/${checkoutTaskId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findCheckoutTasksByEncounterId(encounterId: number): Observable<CheckoutTaskResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/checkoutTask/findCheckoutTasksByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CheckoutTaskResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateCheckoutTask(request: UpdateCheckoutTaskRequest ): Observable<CheckoutTaskResponse> {
		const endpointUrl = `/pms2/ws/encounter/checkoutTask/updateCheckoutTask`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CheckoutTaskResponse, UpdateCheckoutTaskRequest, request );
		
		return configuredEndpoint;
	}

	
	updateDescriptionAndCommentsCheckoutTask(request: UpdateDescriptionAndCommentsCheckoutTaskRequest ): Observable<CheckoutTaskResponse> {
		const endpointUrl = `/pms2/ws/encounter/checkoutTask/updateDescriptionAndCommentsCheckoutTask`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CheckoutTaskResponse, UpdateDescriptionAndCommentsCheckoutTaskRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.accounting.claims.ClaimController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ClaimGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	markClaimsAsCancelled(request: MarkClaimsAsCancelledRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/claim/markClaimsAsCancelled`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, MarkClaimsAsCancelledRequest, request );
		
		return configuredEndpoint;
	}

	
	markClaimsAsDeleted(request: DeleteClaimsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/claim/markClaimsAsDeleted`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeleteClaimsRequest, request );
		
		return configuredEndpoint;
	}

	
	markClaimsAsSubmitted(request: MarkClaimAsSubmittedRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/claim/markClaimsAsSubmitted`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, MarkClaimAsSubmittedRequest, request );
		
		return configuredEndpoint;
	}

	
	resetClaims(request: ResetClaimsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/claim/resetClaims`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, ResetClaimsRequest, request );
		
		return configuredEndpoint;
	}

	
	searchClaims(request: ClaimSearchRequest ): Observable<ClaimsInsuranceClaimResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/claim/searchClaims`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ClaimsInsuranceClaimResponse, ClaimSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	searchClaimsWithStatus(request: EClaimsSearchRequest ): Observable<EClaimsInsuranceClaimResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/claim/searchClaimsWithStatus`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EClaimsInsuranceClaimResponse, EClaimsSearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.document.CommunicationTemplateController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class CommunicationTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findPracticeTemplates(request: TemplateCategorySearchRequest ): Observable<PracticeTemplateSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/communicationTemplate/findPracticeTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeTemplateSummaryResponse, TemplateCategorySearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.conditionrelatedto.ConditionRelatedToController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ConditionRelatedToGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findOrCreateConditionRelatedToByEncounterId(encounterId: number): Observable<ConditionRelatedToResponse> {
		const endpointUrl = `/pms2/ws/conditionRelatedTo/findOrCreateConditionRelatedToByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConditionRelatedToResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateConditionRelatedTo(request: UpdateConditionRelatedToRequest ): Observable<ConditionRelatedToResponse> {
		const endpointUrl = `/pms2/ws/conditionRelatedTo/updateConditionRelatedTo`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConditionRelatedToResponse, UpdateConditionRelatedToRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.connect.campaign.ConnectCampaignController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ConnectCampaignGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	cancelCampaign(connectCampaignId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/connect/campaign/cancelCampaign/${connectCampaignId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createConnectCampaignDraft(request: CreateConnectCampaignDraftRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/connect/campaign/createConnectCampaignDraft`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateConnectCampaignDraftRequest, request );
		
		return configuredEndpoint;
	}

	
	createConnectCampaignSchedule(request: CreateConnectCampaignScheduleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/connect/campaign/createConnectCampaignSchedule`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateConnectCampaignScheduleRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteCampaign(connectCampaignId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/connect/campaign/deleteCampaign/${connectCampaignId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findConnectCampaigns(request: FindCampaignsForPracticeRequest ): Observable<ConnectCampaignListResponse[]> {
		const endpointUrl = `/pms2/ws/connect/campaign/findConnectCampaigns`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectCampaignListResponse, FindCampaignsForPracticeRequest, request );
		
		return configuredEndpoint;
	}

	
	findNonPagedConnectCampaignMessages(request: FindNonPagedConnectCampaignMessagesRequest ): Observable<ConnectCampaignMessageListResponse[]> {
		const endpointUrl = `/pms2/ws/connect/campaign/findNonPagedConnectCampaignMessages`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectCampaignMessageListResponse, FindNonPagedConnectCampaignMessagesRequest, request );
		
		return configuredEndpoint;
	}

	
	findPagedConnectCampaignMessages(request: FindPagedConnectCampaignMessagesRequest ): Observable<ConnectCampaignMessagePagedResponse> {
		const endpointUrl = `/pms2/ws/connect/campaign/findPagedConnectCampaignMessages`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectCampaignMessagePagedResponse, FindPagedConnectCampaignMessagesRequest, request );
		
		return configuredEndpoint;
	}

	
	findPatientQueriesForConnectCampaign(): Observable<QuerySummaryResponse[]> {
		const endpointUrl = `/pms2/ws/connect/campaign/findPatientQueriesForConnectCampaign`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QuerySummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findTemplates(): Observable<ConnectCampaignTemplateResponse[]> {
		const endpointUrl = `/pms2/ws/connect/campaign/findTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectCampaignTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getConnectCampaignById(connectCampaignId: number): Observable<ConnectCampaignResponse> {
		const endpointUrl = `/pms2/ws/connect/campaign/getConnectCampaignById/${connectCampaignId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectCampaignResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getCurrentEmployeeEmail(): Observable<EmployeeEmailResponse> {
		const endpointUrl = `/pms2/ws/connect/campaign/getCurrentEmployeeEmail`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeEmailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	previewConnectCampaign(request: ConnectCampaignMessagePreviewRequest ): Observable<ConnectCampaignPreviewResponse> {
		const endpointUrl = `/pms2/ws/connect/campaign/previewConnectCampaign`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectCampaignPreviewResponse, ConnectCampaignMessagePreviewRequest, request );
		
		return configuredEndpoint;
	}

	
	sendTestCampaignEmail(request: ConnectCampaignSendTestEmailRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/connect/campaign/sendTestCampaignEmail`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, ConnectCampaignSendTestEmailRequest, request );
		
		return configuredEndpoint;
	}

	
	updateConnectCampaignDraft(request: UpdateConnectCampaignDraftRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/connect/campaign/updateConnectCampaignDraft`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateConnectCampaignDraftRequest, request );
		
		return configuredEndpoint;
	}

	
	updateConnectCampaignSchedule(request: UpdateConnectCampaignScheduleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/connect/campaign/updateConnectCampaignSchedule`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateConnectCampaignScheduleRequest, request );
		
		return configuredEndpoint;
	}

	
	validateActivePracticeLocationAddresses(): Observable<ValidatePracticeLocationAddressResponse[]> {
		const endpointUrl = `/pms2/ws/connect/campaign/validateActivePracticeLocationAddresses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ValidatePracticeLocationAddressResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.connectsummary.ConnectSummaryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ConnectSummaryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findConnectSummaryByPatientId(patientId: number): Observable<ConnectSummaryConnectMessageListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/connectSummary/findConnectSummaryByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConnectSummaryConnectMessageListResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.document.ConsentFormController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ConsentFormsGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateConsentForm(practiceConsentFormId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/consentForms/activateConsentForm/${practiceConsentFormId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createConsentForm(request: CreatePracticeConsentFormRequest ): Observable<ConsentFormResponse> {
		const endpointUrl = `/pms2/ws/consentForms/createConsentForm`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConsentFormResponse, CreatePracticeConsentFormRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateConsentForm(practiceConsentFormId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/consentForms/deactivateConsentForm/${practiceConsentFormId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findConsentFormsByPractice(): Observable<ConsentFormListResponse[]> {
		const endpointUrl = `/pms2/ws/consentForms/findConsentFormsByPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConsentFormListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getConsentFormById(consentFormId: number): Observable<ConsentFormResponse> {
		const endpointUrl = `/pms2/ws/consentForms/getConsentFormById/${consentFormId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ConsentFormResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateConsentForm(request: UpdatePracticeConsentFormRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/consentForms/updateConsentForm`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePracticeConsentFormRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.contactlens.ContactLensHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ContactLensHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findOrCreateContactLensHistoryByEncounterId(encounterId: number): Observable<ContactLensHistoryResponse> {
		const endpointUrl = `/pms2/ws/encounter/history/contactLens/findOrCreateContactLensHistoryByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateContactLensHistory(request: UpdateContactLensHistoryRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/history/contactLens/updateContactLensHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateContactLensHistoryRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.prescription.ContactLensPrescriptionController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ContactLensPrescriptionGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	authorizeContactLensPrescription(request: AuthorizeContactLensPrescriptionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/authorizeContactLensPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AuthorizeContactLensPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	cancelContactLensPrescription(request: CancelPrescriptionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/cancelContactLensPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CancelPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	checkOdOsLocationProductIdsActive(request: CheckOdOsLocationProductIdsActiveRequest ): Observable<OdOsLocationProductIdsActiveResponse> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/checkOdOsLocationProductIdsActive`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OdOsLocationProductIdsActiveResponse, CheckOdOsLocationProductIdsActiveRequest, request );
		
		return configuredEndpoint;
	}

	
	createContactLensPrescription(request: CreateContactLensPrescriptionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/createContactLensPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateContactLensPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	disablePhr(contactLensPrescriptionId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/disablePhr/${contactLensPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	enablePhr(contactLensPrescriptionId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/enablePhr/${contactLensPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findByPatient(patientId: number): Observable<ContactLensPrescriptionResponse[]> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/findByPatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensPrescriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findContactLensForOrdersByPatient(patientId: number): Observable<PrescriptionSelectContactLensResponse[]> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/findContactLensForOrdersByPatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PrescriptionSelectContactLensResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findContactLensWithTrialsForOrdersByPatient(patientId: number): Observable<PrescriptionSelectContactLensResponse[]> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/findContactLensWithTrialsForOrdersByPatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PrescriptionSelectContactLensResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getContactLensEyeOptions(): Observable<ContactLensEyeOptionsResponse> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/getContactLensEyeOptions`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensEyeOptionsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getContactLensPrescriptionById(prescriptionId: number): Observable<ContactLensPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/getContactLensPrescriptionById/${prescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensPrescriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getContactLensTrialById(trialId: number): Observable<ContactLensTrialResponse> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/getContactLensTrialById/${trialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensTrialResponse, null, null );
		
		return configuredEndpoint;
	}

	
	refillContactLensPrescription(request: RefillContactLensPrescriptionRequest ): Observable<ContactLensPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/refillContactLensPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensPrescriptionResponse, RefillContactLensPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updateContactLensPrescription(request: UpdateContactLensPrescriptionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/updateContactLensPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateContactLensPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updateContactLensPrescriptionDeliveryMethod(request: UpdateContactLensPrescriptionDeliveryMethodRequest ): Observable<ContactLensPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/contactlens/updateContactLensPrescriptionDeliveryMethod`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensPrescriptionResponse, UpdateContactLensPrescriptionDeliveryMethodRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.contactlens.ContactLensTrialController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ContactLensTrialGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	authorizeContactLensPrescription(request: AuthorizeContactLensPrescriptionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/authorizeContactLensPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AuthorizeContactLensPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	createContactLensPrescriptionFromTrial(contactLensTrialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/createContactLensPrescriptionFromTrial/${contactLensTrialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createContactLensTrial(request: EncounterCreateContactLensTrialRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/createContactLensTrial`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterCreateContactLensTrialRequest, request );
		
		return configuredEndpoint;
	}

	
	createOrderFromContactLensTrial(request: CreateContactLensTrialOrderRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/createOrderFromContactLensTrial`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, CreateContactLensTrialOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	createOrderFromPrescription(request: CreatePrescriptionOrderRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/createOrderFromPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, CreatePrescriptionOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteContactLensTrialById(contactLensTrialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/deleteContactLensTrialById/${contactLensTrialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findContactLensTrialsForEncounter(encounterId: number): Observable<ContactLensTrialListResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/findContactLensTrialsForEncounter/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensTrialListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findContactLensTrialsForPatient(patientId: number): Observable<ContactLensTrialListResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/findContactLensTrialsForPatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensTrialListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findKeratometryTestByPatientId(patientId: number): Observable<KeratometryTestResponse> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/findKeratometryTestByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, KeratometryTestResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findProductColorsForLocationProduct(locationProductId: number): Observable<ProductColorResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/findProductColorsForLocationProduct/${locationProductId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductColorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getContactLensPrescriptionById(contactLensPrescriptionId: number): Observable<ContactLensPrescriptionDetailsResponse> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/getContactLensPrescriptionById/${contactLensPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensPrescriptionDetailsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getContactLensTrialById(contactLensTrialId: number): Observable<ContactLensTrialDetailResponse> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/getContactLensTrialById/${contactLensTrialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensTrialDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEmptyPerformanceScreen(): Observable<DynamicScreenResponse> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/getEmptyPerformanceScreen`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicScreenResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEyeDockUrl(locationProductId: number): Observable<UrlResponse> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/getEyeDockUrl/${locationProductId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UrlResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateContactLensTrial(request: EncounterUpdateContactLensTrialRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/contactLensTrial/updateContactLensTrial`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterUpdateContactLensTrialRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.datalog.DataLogController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class DataLogGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getDataLogDiff(dataLogId: number): Observable<DataLogDiffResponse> {
		const endpointUrl = `/pms2/ws/dataLog/getDataLogDiff/${dataLogId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DataLogDiffResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.order.devicemeasurement.OrderDeviceMeasurementController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class DeviceMeasurementGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	associateDeviceMeasurementWithOrder(request: AssociateMeasurementWithOrderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/order/deviceMeasurement/associateDeviceMeasurementWithOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AssociateMeasurementWithOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	disassociateDeviceMeasurementFromOrder(deviceMeasurementId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/order/deviceMeasurement/disassociateDeviceMeasurementFromOrder/${deviceMeasurementId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findOpticalDeviceMeasurementByOrderId(orderId: number): Observable<OpticalDeviceMeasurementResponse> {
		const endpointUrl = `/pms2/ws/order/deviceMeasurement/findOpticalDeviceMeasurementByOrderId/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpticalDeviceMeasurementResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findUnassignedDeviceMeasurements(request: FindUnassignedDeviceMeasurementsRequest ): Observable<OpticalDeviceMeasurementResponse[]> {
		const endpointUrl = `/pms2/ws/order/deviceMeasurement/findUnassignedDeviceMeasurements`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpticalDeviceMeasurementResponse, FindUnassignedDeviceMeasurementsRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.drug.DrugController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class DrugGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findActiveDrugClasses(): Observable<DrugClassResponse[]> {
		const endpointUrl = `/pms2/ws/drug/findActiveDrugClasses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DrugClassResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeDrugs(): Observable<PracticeDrugListResponse[]> {
		const endpointUrl = `/pms2/ws/drug/findPracticeDrugs`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeDrugListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchDrugs(request: DrugSearchRequest ): Observable<DrugSearchResponse[]> {
		const endpointUrl = `/pms2/ws/drug/searchDrugs`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DrugSearchResponse, DrugSearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.dynamic.DynamicScreenController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class DynamicScreenGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addDynamicTestToDynamicScreen(request: AddDynamicTestToDynamicScreenRequest ): Observable<DynamicTestResponse> {
		const endpointUrl = `/pms2/ws/dynamicScreen/addDynamicTestToDynamicScreen`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicTestResponse, AddDynamicTestToDynamicScreenRequest, request );
		
		return configuredEndpoint;
	}

	
	createEyeglassPrescriptionFromRefraction(request: CreateEyeglassPrescriptionFromRefractionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/dynamicScreen/createEyeglassPrescriptionFromRefraction`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateEyeglassPrescriptionFromRefractionRequest, request );
		
		return configuredEndpoint;
	}

	
	createPresentIllnessFromTriggeredScreen(request: UpdateWorkflowTestsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/dynamicScreen/createPresentIllnessFromTriggeredScreen`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateWorkflowTestsRequest, request );
		
		return configuredEndpoint;
	}

	
	createPresentIllnessFromWizard(request: UpdateWorkflowTestsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/dynamicScreen/createPresentIllnessFromWizard`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateWorkflowTestsRequest, request );
		
		return configuredEndpoint;
	}

	
	findDynamicScreenByOrderId(orderId: number): Observable<DynamicScreenResponse> {
		const endpointUrl = `/pms2/ws/dynamicScreen/findDynamicScreenByOrderId/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicScreenResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getDeviceMeasurementScreenById(deviceMeasurementId: number): Observable<DynamicScreenResponse> {
		const endpointUrl = `/pms2/ws/dynamicScreen/getDeviceMeasurementScreenById/${deviceMeasurementId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicScreenResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getDynamicScreenByWorkflowScreenId(request: FindWorkflowScreenRequest ): Observable<DynamicScreenResponse> {
		const endpointUrl = `/pms2/ws/dynamicScreen/getDynamicScreenByWorkflowScreenId`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicScreenResponse, FindWorkflowScreenRequest, request );
		
		return configuredEndpoint;
	}

	
	getDynamicScreenByWorkflowScreenTemplateId(workflowScreenTemplateId: number): Observable<DynamicScreenResponse> {
		const endpointUrl = `/pms2/ws/dynamicScreen/getDynamicScreenByWorkflowScreenTemplateId/${workflowScreenTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicScreenResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getDynamicScreensByWorkflowScreenIds(request: FindWorkflowScreensRequest ): Observable<DynamicScreenResponse[]> {
		const endpointUrl = `/pms2/ws/dynamicScreen/getDynamicScreensByWorkflowScreenIds`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicScreenResponse, FindWorkflowScreensRequest, request );
		
		return configuredEndpoint;
	}

	
	getDynamicTestPreviewByTemplateId(workflowTestTemplateId: number): Observable<DynamicTestResponse> {
		const endpointUrl = `/pms2/ws/dynamicScreen/getDynamicTestPreviewByTemplateId/${workflowTestTemplateId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicTestResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateWorkflowTests(request: UpdateWorkflowTestsRequest ): Observable<WorkflowTestTriggersResultResponse> {
		const endpointUrl = `/pms2/ws/dynamicScreen/updateWorkflowTests`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WorkflowTestTriggersResultResponse, UpdateWorkflowTestsRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.dynamic.DynamicTestController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class DynamicTestGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findTestHistory(request: FindDynamicTestHistoryRequest ): Observable<DynamicTestHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/dynamicTest/findTestHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicTestHistoryResponse, FindDynamicTestHistoryRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.employee.EmployeeController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EmployeeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	findActiveEmployees(cacheOptions?: GandalfCacheableConfigurableOptions<EmployeeResponse[]>): Observable<EmployeeResponse[]> {
		const endpointUrl = `/pms2/ws/employee/findActiveEmployees`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['employees'], cacheableOptions);
	}

	
	findActiveEmployeesWithSecurityRolesByPractice(): Observable<EmployeeWithSecurityRolesResponse[]> {
		const endpointUrl = `/pms2/ws/employee/findActiveEmployeesWithSecurityRolesByPracticeLocationId`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeWithSecurityRolesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveEmployeesWithSecurityRolesByPracticeLocationId(practiceLocationId: number): Observable<EmployeeWithSecurityRolesResponse[]> {
		const endpointUrl = `/pms2/ws/employee/findActiveEmployeesWithSecurityRolesByPracticeLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeWithSecurityRolesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeEmployees(cacheOptions?: GandalfCacheableConfigurableOptions<EmployeeResponse[]>): Observable<EmployeeResponse[]> {
		const endpointUrl = `/pms2/ws/employee/findPracticeEmployees`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['employees'], cacheableOptions);
	}

	
	getLoggedInEmployeeWithSecurityRoles(): Observable<EmployeeWithSecurityRolesResponse> {
		const endpointUrl = `/pms2/ws/employee/getLoggedInEmployeeWithSecurityRoles`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeWithSecurityRolesResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.employeeroles.EmployeeRolesController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EmployeeRolesGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	@GandalfCacheBuster({cacheKeys: ['security_roles']})
	createSecurityRole(request: CreateUserRoleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/employeeRoles/createSecurityRole`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateUserRoleRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['security_roles']})
	deleteRole(roleId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/employeeRoles/deleteRole/${roleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findSecureResourceCategories(): Observable<SecureResourceCategoryResponse[]> {
		const endpointUrl = `/pms2/ws/employeeRoles/findSecurityResourceCategories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SecureResourceCategoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getSecurityRoleById(roleId: number): Observable<SecurityRoleResponse> {
		const endpointUrl = `/pms2/ws/employeeRoles/getSecurityRoleById/${roleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SecurityRoleResponse, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['security_roles']})
	updateSecurityRole(request: UpdateUserRoleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/employeeRoles/updateSecurityRole`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateUserRoleRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.employee.security.EmployeeSecurityController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EmployeeSecurityGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	keepAlive(): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/keepAlive`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	livenessCheck(): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/livenessCheck`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	lockEmployeeSession(): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/lockEmployeeSession`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	loginUsingExistingSession(request: LoginUsingExistingSessionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/loginUsingExistingSession`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, LoginUsingExistingSessionRequest, request );
		
		return configuredEndpoint;
	}

	
	logout(): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/logout`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	readinessCheck(): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/readinessCheck`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	setCurrentLocation(locationId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/setCurrentLocation/${locationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	testIdRequest(id: number): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/testIdRequest/${id}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	unlockEmployeeSession(request: UnlockSessionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/employee/security/unlockEmployeeSession`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UnlockSessionRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.employee.task.EmployeeTaskController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EmployeeTaskGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getActiveTaskCountForEmployee(): Observable<number> {
		const endpointUrl = `/pms2/ws/employee/task/getActiveTaskCountForEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.EncounterController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EncounterGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	autoCodeEncounter(encounterId: number): Observable<boolean> {
		const endpointUrl = `/pms2/ws/encounter/autoCodeEncounter/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, null, null );
		
		return configuredEndpoint;
	}

	
	autoCodeEncounterForPreview(encounterId: number): Observable<EncounterAutoCodePreviewResponse> {
		const endpointUrl = `/pms2/ws/encounter/autoCodeEncounterForPreview/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterAutoCodePreviewResponse, null, null );
		
		return configuredEndpoint;
	}

	
	cancelEncounter(request: CancelEncounterRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/cancelEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CancelEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	clearExamFindings(encounterId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/clearExamFindings/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	codeEncounter(request: CodeEncounterRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/codeEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CodeEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	completeEncounter(encounterId: number): Observable<EncounterStatusesResponse> {
		const endpointUrl = `/pms2/ws/encounter/completeEncounter/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterStatusesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	createEncounter(request: CreateEncounterRequest ): Observable<EncounterResponse> {
		const endpointUrl = `/pms2/ws/encounter/createEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterResponse, CreateEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	findEncountersByPatientId(patientId: number): Observable<EncounterResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/findEncountersByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findNonCanceledEncountersByPatientIdForPullForward(patientId: number, encounterId: number): Observable<EncounterPullForwardTableResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/findNonCanceledEncountersByPatientIdForPullForward/${patientId}/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterPullForwardTableResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findNonCanceledEncountersByPatientIdForTable(patientId: number): Observable<EncounterTableResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/findNonCanceledEncountersByPatientIdForTable/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterTableResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEncounterById(encounterId: number): Observable<EncounterResponse> {
		const endpointUrl = `/pms2/ws/encounter/getEncounterById/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEncounterStatusesById(encounterId: number): Observable<EncounterStatusesResponse> {
		const endpointUrl = `/pms2/ws/encounter/getEncounterStatusesById/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterStatusesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	pullForwardExamFindings(request: PullForwardEncounterRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/pullForwardExamFindings`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, PullForwardEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	signEncounter(request: SignEncounterRequest ): Observable<EncounterValidationResponse> {
		const endpointUrl = `/pms2/ws/encounter/signEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterValidationResponse, SignEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	signEncounterSso(request: SignEncounterSsoRequest ): Observable<EncounterValidationResponse> {
		const endpointUrl = `/pms2/ws/encounter/signEncounterSso`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterValidationResponse, SignEncounterSsoRequest, request );
		
		return configuredEndpoint;
	}

	
	unsignEncounter(request: UnsignEncounterRequest ): Observable<EncounterValidationResponse> {
		const endpointUrl = `/pms2/ws/encounter/unsignEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterValidationResponse, UnsignEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	unsignEncounterSso(request: UnsignEncounterSsoRequest ): Observable<EncounterValidationResponse> {
		const endpointUrl = `/pms2/ws/encounter/unsignEncounterSso`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterValidationResponse, UnsignEncounterSsoRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEncounterProvider(request: UpdateEncounterProviderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/updateEncounterProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateEncounterProviderRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.reporting.schedule.EncounterReportController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EncounterReportGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	searchEncounters(request: EncounterReportRequest ): Observable<EncounterReportResponse[]> {
		const endpointUrl = `/pms2/ws/encounterReport/searchEncounters`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterReportResponse, EncounterReportRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.review.EncounterReviewController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EncounterReviewGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	deactivateEncounterReviewDetail(encounterReviewDetailId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/encounter/review/deactivateEncounterReviewDetail/${encounterReviewDetailId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	getEncounterReviewGeneralInformationByEncounterId(encounterId: number): Observable<EncounterReviewGeneralInformationResponse> {
		const endpointUrl = `/pms2/ws/patient/encounter/review/getEncounterReviewGeneralInformationByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterReviewGeneralInformationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEncounterReviewGroupsByEncounterId(encounterId: number): Observable<EncounterReviewGroupResponse[]> {
		const endpointUrl = `/pms2/ws/patient/encounter/review/getEncounterReviewGroupsByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterReviewGroupResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.EncounterRoleController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EncounterRoleGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createEncounterRole(request: CreateEncounterRoleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounterRole/createEncounterRole`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateEncounterRoleRequest, request );
		
		return configuredEndpoint;
	}

	
	findEncounterRolesForCurrentEmployee(): Observable<EncounterRoleResponse[]> {
		const endpointUrl = `/pms2/ws/encounterRole/findEncounterRolesForCurrentEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterRoleResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findEncounterRolesForCurrentPractice(): Observable<EncounterRoleResponse[]> {
		const endpointUrl = `/pms2/ws/encounterRole/findEncounterRolesForCurrentPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterRoleResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateEncounterRole(request: UpdateEncounterRoleRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounterRole/updateEncounterRole`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateEncounterRoleRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.template.EncounterTemplateController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EncounterTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	findActiveEncounterTemplates(cacheOptions?: GandalfCacheableConfigurableOptions<EncounterTemplateResponse[]>): Observable<EncounterTemplateResponse[]> {
		const endpointUrl = `/pms2/ws/encounterTemplate/findActiveEncounterTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterTemplateResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['encounter_templates'], cacheableOptions);
	}

	
	findEncounterTemplates(cacheOptions?: GandalfCacheableConfigurableOptions<EncounterTemplateResponse[]>): Observable<EncounterTemplateResponse[]> {
		const endpointUrl = `/pms2/ws/encounterTemplate/findEncounterTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterTemplateResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['encounter_templates'], cacheableOptions);
	}

}

/** See com.rev360.pms.api.controller.data.EnvironmentDataController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EnvironmentDataGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findMasterProductCategories(): Observable<ProductCategoryResponse[]> {
		const endpointUrl = `/pms2/ws/data/environmentData/findMasterProductCategories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductCategoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getBluefinUrl(): Observable<UrlResponse> {
		const endpointUrl = `/pms2/ws/data/environmentData/getBluefinUrl`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UrlResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEpayBaseUrl(): Observable<UrlResponse> {
		const endpointUrl = `/pms2/ws/data/environmentData/getEpayBaseUrl`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UrlResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getGudidBaseUrl(): Observable<UrlResponse> {
		const endpointUrl = `/pms2/ws/data/environmentData/getGudidBaseUrl`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UrlResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getOpenEdgeConfiguration(): Observable<OpenEdgeConfigurationResponse> {
		const endpointUrl = `/pms2/ws/data/environmentData/getOpenEdgeConfiguration`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeConfigurationResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.claimsprocessor.ExternalCredentialController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ExternalCredentialGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createClearingHouseForPracticeLocation(request: CreateExternalCredentialForPracticeLocationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/externalCredential/createClearingHouseForPracticeLocation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateExternalCredentialForPracticeLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	createPaymentProcessorForPracticeLocation(request: CreateExternalCredentialForPracticeLocationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/externalCredential/createPaymentProcessorForPracticeLocation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateExternalCredentialForPracticeLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	disableClearingHouseCredential(externalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/externalCredential/disableClearingHouseCredential/${externalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	disablePaymentProcessorCredential(externalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/externalCredential/disablePaymentProcessorCredential/${externalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	enableClearingHouseCredential(externalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/externalCredential/enableClearingHouseCredential/${externalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	enablePaymentProcessorCredential(externalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/externalCredential/enablePaymentProcessorCredential/${externalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findLocationsByClearingHouse(request: ExternalCredentialLocationRequest ): Observable<ExternalCredentialLocationResponse[]> {
		const endpointUrl = `/pms2/ws/externalCredential/findLocationsByClearingHouse`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalCredentialLocationResponse, ExternalCredentialLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	findLocationsByPaymentProcessor(request: ExternalCredentialLocationRequest ): Observable<ExternalCredentialLocationResponse[]> {
		const endpointUrl = `/pms2/ws/externalCredential/findLocationsByPaymentProcessor`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalCredentialLocationResponse, ExternalCredentialLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	findPracticeLocationsForPaymentProcessor(): Observable<PaymentProcessorPracticeLocationResponse[]> {
		const endpointUrl = `/pms2/ws/externalCredential/findPracticeLocationsForPaymentProcessor`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentProcessorPracticeLocationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateClearingHouseCredential(request: UpdateExternalCredentialRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/externalCredential/updateClearingHouseCredential`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateExternalCredentialRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePaymentProcessorCredential(request: UpdateExternalCredentialRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/externalCredential/updatePaymentProcessorCredential`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateExternalCredentialRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.order.eyefinity.EyefinityOrderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EyefinityOrderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	approveEyefinityOrder(eyefinityOrderDetailId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/eyefinity/order/approveEyefinityOrder/${eyefinityOrderDetailId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	changeEyefinityOrder(request: ChangeEyefinityOrderRequest ): Observable<ChangeEyefinityOrderResponse> {
		const endpointUrl = `/pms2/ws/eyefinity/order/changeEyefinityOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ChangeEyefinityOrderResponse, ChangeEyefinityOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	findEyefinityOrderVendors(request: FindEyefinityOrderVendorsRequest ): Observable<OrderVendorResponse[]> {
		const endpointUrl = `/pms2/ws/eyefinity/order/findEyefinityOrderVendors`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderVendorResponse, FindEyefinityOrderVendorsRequest, request );
		
		return configuredEndpoint;
	}

	
	remakeEyefinityOrder(request: RemakeEyefinityOrderRequest ): Observable<RemakeEyefinityOrderResponse> {
		const endpointUrl = `/pms2/ws/eyefinity/order/remakeEyefinityOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RemakeEyefinityOrderResponse, RemakeEyefinityOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEyefinityBillableItemsBillable(request: UpdateEyefinityItemsAsBillableRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/eyefinity/order/updateEyefinityBillableItemsBillable`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateEyefinityItemsAsBillableRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEyefinityBillableItemsNonBillable(request: UpdateEyefinityItemsAsNonBillableRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/eyefinity/order/updateEyefinityBillableItemsNonBillable`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateEyefinityItemsAsNonBillableRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.prescription.EyeglassPrescriptionController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class EyeglassPrescriptionGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	authorizeEyeglassPrescriptions(request: AuthorizeEyeglassPrescriptionsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/authorizeEyeglassPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AuthorizeEyeglassPrescriptionsRequest, request );
		
		return configuredEndpoint;
	}

	
	cancelEyeglassPrescription(request: CancelPrescriptionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/cancelEyeglassPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CancelPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	convertCylinder(eyeglassPrescriptionId: number): Observable<EyeglassPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/convertCylinder/${eyeglassPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassPrescriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	createEyeglassPrescription(request: CreateEyeglassPrescriptionRequest ): Observable<EyeglassPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/createEyeglassPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassPrescriptionResponse, CreateEyeglassPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	createEyeglassPrescriptions(request: CreateEyeglassPrescriptionsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/createEyeglassPrescriptions`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateEyeglassPrescriptionsRequest, request );
		
		return configuredEndpoint;
	}

	
	createOrderFromEyeglassPrescription(request: CreateOrderFromEyeglassPrescriptionRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/createOrderFromEyeglassPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, CreateOrderFromEyeglassPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	disablePhr(eyeglassPrescriptionId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/disablePhr/${eyeglassPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	enablePhr(eyeglassPrescriptionId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/enablePhr/${eyeglassPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findEyeglassPrescriptionHistoryByPatientId(patientId: number): Observable<EyeglassPrescriptionListResponse[]> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/findEyeglassPrescriptionHistoryByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassPrescriptionListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findLastNotStoppedEyeglassPrescription(patientId: number): Observable<EyeglassPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/findLastNotStoppedEyeglassPrescription/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassPrescriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEyeglassPrescriptionById(eyeglassPrescriptionId: number): Observable<EyeglassPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/getEyeglassPrescriptionById/${eyeglassPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassPrescriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	refillEyeglassPrescription(request: RefillEyeglassPrescriptionRequest ): Observable<EyeglassPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/refillEyeglassPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassPrescriptionResponse, RefillEyeglassPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEyeglassPrescription(request: UpdateEyeglassPrescriptionRequest ): Observable<EyeglassPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/eyeglass/updateEyeglassPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassPrescriptionResponse, UpdateEyeglassPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.familyhealthhistory.FamilyHealthHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class FamilyHealthHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createFamilyHealthHistories(request: CreateFamilyHealthHistoryRequest[] ): Observable<FamilyHealthHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/familyHealthHistory/createFamilyHealthHistories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHealthHistoryResponse, CreateFamilyHealthHistoryRequest, request );
		
		return configuredEndpoint;
	}

	
	createFamilyHealthHistory(request: CreateFamilyHealthHistoryRequest ): Observable<FamilyHealthHistoryResponse> {
		const endpointUrl = `/pms2/ws/familyHealthHistory/createFamilyHealthHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHealthHistoryResponse, CreateFamilyHealthHistoryRequest, request );
		
		return configuredEndpoint;
	}

	
	getFamilyHealthHistoryById(familyHealthHistoryId: number): Observable<FamilyHealthHistoryResponse> {
		const endpointUrl = `/pms2/ws/familyHealthHistory/getFamilyHealthHistoryById/${familyHealthHistoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHealthHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getLatestFamilyHealthHistoryForPatient(request: GetLatestFamilyHealthHistoryForPatientRequest ): Observable<FamilyHealthHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/familyHealthHistory/getLatestFamilyHealthHistoryForPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHealthHistoryResponse, GetLatestFamilyHealthHistoryForPatientRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.familyhealthhistory.FamilyHealthHistoryReviewSetController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class FamilyHealthHistoryReviewSetGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createFamilyHealthHistoryReviewSet(request: CreateFamilyHealthHistoryReviewSetRequest ): Observable<FamilyHealthHistoryReviewSetResponse> {
		const endpointUrl = `/pms2/ws/familyHealthHistoryReviewSet/createFamilyHealthHistoryReviewSet`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHealthHistoryReviewSetResponse, CreateFamilyHealthHistoryReviewSetRequest, request );
		
		return configuredEndpoint;
	}

	
	findFamilyHealthHistoryReviewSetListForPatient(request: FindFamilyHealthHistoryReviewSetListForPatientRequest ): Observable<FamilyHealthHistoryReviewSetResponse[]> {
		const endpointUrl = `/pms2/ws/familyHealthHistoryReviewSet/findFamilyHealthHistoryReviewSetListForPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHealthHistoryReviewSetResponse, FindFamilyHealthHistoryReviewSetListForPatientRequest, request );
		
		return configuredEndpoint;
	}

	
	findLatestFamilyHealthHistoryReviewSetForPatientEncounter(request: FindFamilyHealthHistoryReviewSetListForPatientEncounterRequest ): Observable<FamilyHealthHistoryReviewSetResponse> {
		const endpointUrl = `/pms2/ws/familyHealthHistoryReviewSet/findLatestFamilyHealthHistoryReviewSetForPatientEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHealthHistoryReviewSetResponse, FindFamilyHealthHistoryReviewSetListForPatientEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	reviewPatientFamilyHealthHistoryReviewSet(request: ReviewPatientFamilyHealthHistoryReviewSetRequest ): Observable<FamilyHealthHistoryReviewSetResponse> {
		const endpointUrl = `/pms2/ws/familyHealthHistoryReviewSet/reviewPatientFamilyHealthHistoryReviewSet`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHealthHistoryReviewSetResponse, ReviewPatientFamilyHealthHistoryReviewSetRequest, request );
		
		return configuredEndpoint;
	}

	
	viewFamilyHealthHistoryReviewSetById(familyHealthHistoryReviewSetId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/familyHealthHistoryReviewSet/viewFamilyHealthHistoryReviewSetById/${familyHealthHistoryReviewSetId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.feature.FeatureFlagController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class FeatureFlagGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createFeatureFlagEmployee(request: CreateFeatureFlagEmployeeRequest ): Observable<FeatureFlagEmployeeSummaryResponse> {
		const endpointUrl = `/pms2/ws/featureFlag/createFeatureFlagEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeatureFlagEmployeeSummaryResponse, CreateFeatureFlagEmployeeRequest, request );
		
		return configuredEndpoint;
	}

	
	findFeatureFlagsCountForToasterMessage(): Observable<FeatureFlagCountForToasterResponse> {
		const endpointUrl = `/pms2/ws/featureFlag/findFeatureFlagsCountForToasterMessage`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeatureFlagCountForToasterResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFeatureFlagsForManageUpdates(): Observable<FeatureFlagDetailResponse[]> {
		const endpointUrl = `/pms2/ws/featureFlag/findFeatureFlagsForManageUpdates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeatureFlagDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateFeatureFlagEmployeeAccess(request: UpdateFeatureFlagEmployeeRequest ): Observable<FeatureFlagEmployeeSummaryResponse> {
		const endpointUrl = `/pms2/ws/featureFlag/updateFeatureFlagEmployeeAccess`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeatureFlagEmployeeSummaryResponse, UpdateFeatureFlagEmployeeRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.dynamic.form.FieldController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class FieldGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findFamilyHistoryFieldDefinitions(): Observable<FieldDefinitionFamilyHistoryResponse> {
		const endpointUrl = `/pms2/ws/field/findFamilyHistoryFieldDefinitions`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FieldDefinitionFamilyHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFieldDefinitionByMasterId(masterId: number): Observable<FieldDefinitionResponse> {
		const endpointUrl = `/pms2/ws/field/findFieldDefinitionByMasterId/${masterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FieldDefinitionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFieldDefinitionHistoryOfPresentIllness(): Observable<FieldDefinitionHistoryOfPresentIllnessResponse> {
		const endpointUrl = `/pms2/ws/field/findFieldDefinitionHistoryOfPresentIllness`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FieldDefinitionHistoryOfPresentIllnessResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFieldDefinitionReviewOfSystemsByMasterId(): Observable<FieldDefinitionReviewOfSystemsResponse> {
		const endpointUrl = `/pms2/ws/field/findFieldDefinitionReviewOfSystemsByMasterId`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FieldDefinitionReviewOfSystemsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findOcularHistoryFieldDefinition(): Observable<FieldDefinitionOcularHistoryResponse> {
		const endpointUrl = `/pms2/ws/field/findOcularHistoryFieldDefinition`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FieldDefinitionOcularHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findReasonForVisitFieldDefinitions(): Observable<FieldDefinitionReasonForVisitResponse> {
		const endpointUrl = `/pms2/ws/field/findReasonForVisitFieldDefinitions`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FieldDefinitionReasonForVisitResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.file.folder.FolderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class FolderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createPatientFolder(request: CreateFolderRequest ): Observable<FileFolderResponse> {
		const endpointUrl = `/pms2/ws/folder/createPatientFolder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FileFolderResponse, CreateFolderRequest, request );
		
		return configuredEndpoint;
	}

	
	deletePatientFolder(request: DeleteFolderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/folder/deletePatientFolder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeleteFolderRequest, request );
		
		return configuredEndpoint;
	}

	
	getEmployeeFolders(): Observable<FileFolderResponse[]> {
		const endpointUrl = `/pms2/ws/folder/getEmployeeFolders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FileFolderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientFolders(): Observable<FileFolderResponse[]> {
		const endpointUrl = `/pms2/ws/folder/getPatientFolders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FileFolderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeFolders(): Observable<FileFolderResponse[]> {
		const endpointUrl = `/pms2/ws/folder/getPracticeFolders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FileFolderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	movePatientFolder(request: MoveFolderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/folder/movePatientFolder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, MoveFolderRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePatientFolder(request: UpdateFolderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/folder/updatePatientFolder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateFolderRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.header.HeaderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class HeaderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	askMeaningfulUseQuestion(request: AskQuestionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/header/askMeaningfulUseQuestion`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AskQuestionRequest, request );
		
		return configuredEndpoint;
	}

	
	askRevAssureQuestion(request: AskQuestionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/header/askRevAssureQuestion`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AskQuestionRequest, request );
		
		return configuredEndpoint;
	}

	
	findTodaysPatientsAppointments(practiceLocationId: number): Observable<TodaysPatientsAppointmentResponse[]> {
		const endpointUrl = `/pms2/ws/header/findTodaysPatientsAppointments/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TodaysPatientsAppointmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findTodaysPatientsAppointmentsForProvider(request: FindTodaysPatientsAppointmentsForProviderRequest ): Observable<TodaysPatientsAppointmentResponse[]> {
		const endpointUrl = `/pms2/ws/header/findTodaysPatientsAppointmentsForProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TodaysPatientsAppointmentResponse, FindTodaysPatientsAppointmentsForProviderRequest, request );
		
		return configuredEndpoint;
	}

	
	getCurrentEmployeeProfile(): Observable<EmployeeEditProfileResponse> {
		const endpointUrl = `/pms2/ws/header/getCurrentEmployeeProfile`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EmployeeEditProfileResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getDoctibleSsoUrl(): Observable<UrlResponse> {
		const endpointUrl = `/pms2/ws/header/getDoctibleSsoUrl`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UrlResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getNewMessageParticipantCountForEmployee(): Observable<number> {
		const endpointUrl = `/pms2/ws/header/getNewMessageParticipantCountForEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, null, null );
		
		return configuredEndpoint;
	}

	
	getUnreadConversationsCount(): Observable<number> {
		const endpointUrl = `/pms2/ws/header/getUnreadConversationsCount`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, null, null );
		
		return configuredEndpoint;
	}

	
	sendFeedback(request: SendFeedbackRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/header/sendFeedback`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, SendFeedbackRequest, request );
		
		return configuredEndpoint;
	}

	
	startEncounterByAppointment(request: StartAppointmentRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/header/startAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, StartAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCurrentEmployeeProfile(request: EmployeeEditProfileRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/header/updateCurrentEmployeeProfile`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EmployeeEditProfileRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.healthgoal.PatientHealthGoalController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class HealthGoalGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePatientHealthGoal(patientHealthGoalId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/healthGoal/activatePatientHealthGoal/${patientHealthGoalId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createPatientHealthGoal(request: CreatePatientHealthGoalRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/healthGoal/createPatientHealthGoal`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePatientHealthGoalRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePatientHealthGoal(patientHealthGoalId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/healthGoal/deactivatePatientHealthGoal/${patientHealthGoalId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientHealthGoalsByPatientId(patientId: number): Observable<PatientHealthGoalResponse[]> {
		const endpointUrl = `/pms2/ws/healthGoal/findPatientHealthGoalsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientHealthGoalResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientHealthGoalById(patientHealthGoalId: number): Observable<PatientHealthGoalResponse> {
		const endpointUrl = `/pms2/ws/healthGoal/getPatientHealthGoalById/${patientHealthGoalId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientHealthGoalResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePatientHealthGoal(request: UpdatePatientHealthGoalRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/healthGoal/updatePatientHealthGoal`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientHealthGoalRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.HistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class HistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findOrCreatePfshByEncounterId(encounterId: number): Observable<PfshResponse> {
		const endpointUrl = `/pms2/ws/history/findOrCreatePfshByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PfshResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findOrCreateReasonForVisitByEncounterId(encounterId: number): Observable<ReasonForVisitResponse> {
		const endpointUrl = `/pms2/ws/history/findOrCreateReasonForVisitByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReasonForVisitResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findOrCreateReviewOfSystemsByEncounterId(encounterId: number): Observable<ReviewOfSystemsResponse> {
		const endpointUrl = `/pms2/ws/history/findOrCreateReviewOfSystemsByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReviewOfSystemsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPreviousFamilyHistoryByEncounterId(encounterId: number): Observable<FamilyHistoryResponse> {
		const endpointUrl = `/pms2/ws/history/findPreviousFamilyHistoryByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPreviousOcularHistoryByEncounterId(encounterId: number): Observable<OcularHistoryResponse> {
		const endpointUrl = `/pms2/ws/history/findPreviousOcularHistoryByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OcularHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPreviousReviewOfSystemsByEncounterId(encounterId: number): Observable<ReviewOfSystemsResponse> {
		const endpointUrl = `/pms2/ws/history/findPreviousReviewOfSystemsByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReviewOfSystemsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPreviousSocialHistoryByEncounterId(encounterId: number): Observable<SocialHistoryResponse> {
		const endpointUrl = `/pms2/ws/history/findPreviousSocialHistoryByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SocialHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getFamilyDiffByEncounterId(encounterId: number): Observable<FamilyDiffResponse> {
		const endpointUrl = `/pms2/ws/history/getFamilyDiffByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyDiffResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getOcularDiffByEncounterId(encounterId: number): Observable<OcularDiffResponse> {
		const endpointUrl = `/pms2/ws/history/getOcularDiffByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OcularDiffResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getReviewOfSystemsDiffByEncounterId(encounterId: number): Observable<ReviewOfSystemsDiffResponse> {
		const endpointUrl = `/pms2/ws/history/getReviewOfSystemsDiffByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReviewOfSystemsDiffResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getSocialDiffByEncounterId(encounterId: number): Observable<SocialDiffResponse> {
		const endpointUrl = `/pms2/ws/history/getSocialDiffByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SocialDiffResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePfsh(request: PfshRequest ): Observable<PfshResponse> {
		const endpointUrl = `/pms2/ws/history/updatePfsh`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PfshResponse, PfshRequest, request );
		
		return configuredEndpoint;
	}

	
	updateReasonForVisit(request: UpdateReasonForVisitRequest ): Observable<ReasonForVisitResponse> {
		const endpointUrl = `/pms2/ws/history/updateReasonForVisit`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReasonForVisitResponse, UpdateReasonForVisitRequest, request );
		
		return configuredEndpoint;
	}

	
	updateReasonForVisitForSignedEncounter(request: UpdateReasonForVisitForSignedEncounterRequest ): Observable<ReasonForVisitResponse> {
		const endpointUrl = `/pms2/ws/history/updateReasonForVisitForSignedEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReasonForVisitResponse, UpdateReasonForVisitForSignedEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	updateReviewOfSystem(request: ReviewOfSystemsRequest ): Observable<ReviewOfSystemsResponse> {
		const endpointUrl = `/pms2/ws/history/updateReviewOfSystems`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReviewOfSystemsResponse, ReviewOfSystemsRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.incorporate.IncorporateExternalDataController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class IncorporateExternalDataGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	incorporateExternalData(request: IncorporateExternalDataRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/incorporate/incorporateExternalData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, IncorporateExternalDataRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.infobutton.InfoButtonController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class InfoButtonGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getDiagnosisInfoButtonUrl(personDiagnosisId: number): Observable<UrlResponse> {
		const endpointUrl = `/pms2/ws/patient/infoButton/getDiagnosisInfoButtonUrl/${personDiagnosisId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UrlResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getMedicationInfoButtonUrl(medicationPrescriptionId: number): Observable<UrlResponse> {
		const endpointUrl = `/pms2/ws/patient/infoButton/getMedicationInfoButtonUrl/${medicationPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UrlResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeDiagnosisInfoButtonUrl(practiceDiagnosisId: number): Observable<UrlResponse> {
		const endpointUrl = `/pms2/ws/patient/infoButton/getPracticeDiagnosisInfoButtonUrl/${practiceDiagnosisId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UrlResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.insurance.InsuranceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class InsuranceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	acceptIntakeInsurance(intakeInsuranceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/insurance/acceptIntakeInsurance/${intakeInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	activatePersonInsuranceById(personInsuranceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/insurance/activatePersonInsuranceById/${personInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createPersonInsurance(request: CreatePersonInsuranceRequest ): Observable<PersonInsuranceDropdownResponse> {
		const endpointUrl = `/pms2/ws/insurance/createPersonInsurance`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceDropdownResponse, CreatePersonInsuranceRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['insurance_companies']})
	createPracticeInsuranceCompany(request: CreatePracticeInsuranceCompanyModalRequest ): Observable<PracticeInsuranceCompanyModalResponse> {
		const endpointUrl = `/pms2/ws/insurance/createPracticeInsuranceCompany`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceCompanyModalResponse, CreatePracticeInsuranceCompanyModalRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePersonInsuranceById(personInsuranceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/insurance/deactivatePersonInsuranceById/${personInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	declineIntakeInsurance(intakeInsuranceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/insurance/declineIntakeInsurance/${intakeInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePersonInsurancesByPersonId(personId: number): Observable<InsuranceCoveragesResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findActivePersonInsurancesByPersonId/${personId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InsuranceCoveragesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePoliciesForPatientContacts(patientId: number): Observable<PersonCoverageResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findActivePoliciesForPatientContacts/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonCoverageResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePoliciesForPatientFamilyMembers(patientId: number): Observable<PersonCoverageResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findActivePoliciesForPatientFamilyMembers/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonCoverageResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePracticeFeeSchedules(): Observable<FeeScheduleDropdownResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findActivePracticeFeeSchedules`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FeeScheduleDropdownResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePracticeInsuranceCompanies(cacheOptions?: GandalfCacheableConfigurableOptions<PracticeInsuranceCompanyDropdownResponse[]>): Observable<PracticeInsuranceCompanyDropdownResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findActivePracticeInsuranceCompanies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceCompanyDropdownResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['insurance_companies'], cacheableOptions);
	}

	
	findContactsWithRoleByPatientId(patientId: number): Observable<PersonCoverageResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findContactsWithRoleByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonCoverageResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFamilyMembersWithRoleByPatientId(patientId: number): Observable<PersonCoverageResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findFamilyMembersWithRoleByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonCoverageResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMostRecentIntakeByPatientId(patientId: number): Observable<IntakeInsuranceResponse> {
		const endpointUrl = `/pms2/ws/insurance/findMostRecentIntakeByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, IntakeInsuranceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPaymentPreferences(): Observable<PaymentPreferencesResponse> {
		const endpointUrl = `/pms2/ws/insurance/findPaymentPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPersonInsurancesByPatientId(patientId: number): Observable<PersonInsuranceDetailsResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findPersonInsurancesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceDetailsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPersonInsurancesByPersonId(personId: number): Observable<PersonInsuranceDetailsResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findPersonInsurancesByPersonId/${personId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceDetailsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeInsuranceCompanies(cacheOptions?: GandalfCacheableConfigurableOptions<PracticeInsuranceCompanyDropdownResponse[]>): Observable<PracticeInsuranceCompanyDropdownResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/findPracticeInsuranceCompanies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceCompanyDropdownResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['insurance_companies'], cacheableOptions);
	}

	
	getCompanyNameByPersonInsuranceId(personInsuranceId: number): Observable<PersonInsuranceCompanyResponse> {
		const endpointUrl = `/pms2/ws/insurance/getCompanyNameByPersonInsuranceId/${personInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceCompanyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getIntakeInsuranceByIdWithPatientFile(intakeInsuranceId: number): Observable<IntakeInsuranceListResponse> {
		const endpointUrl = `/pms2/ws/insurance/getIntakeInsuranceByIdWithPatientFile/${intakeInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, IntakeInsuranceListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPersonInsuranceById(personInsuranceId: number): Observable<PersonInsuranceResponse> {
		const endpointUrl = `/pms2/ws/insurance/getPersonInsuranceById/${personInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPersonInsurancePolicyById(policyId: number): Observable<PersonInsurancePolicyResponse> {
		const endpointUrl = `/pms2/ws/insurance/getPersonInsurancePolicyById/${policyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsurancePolicyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPersonInsuranceTooltip(patientId: number): Observable<PersonInsuranceTooltipResponse[]> {
		const endpointUrl = `/pms2/ws/insurance/getPersonInsuranceTooltip/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceTooltipResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeInsuranceCompanyByPersonInsuranceId(personInsuranceId: number): Observable<PracticeInsuranceCompanyDropdownResponse> {
		const endpointUrl = `/pms2/ws/insurance/getPracticeInsuranceCompanyByPersonInsuranceId/${personInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceCompanyDropdownResponse, null, null );
		
		return configuredEndpoint;
	}

	
	hasPatientPQRSInsurance(patientId: number): Observable<boolean> {
		const endpointUrl = `/pms2/ws/insurance/hasPatientPQRSInsurance/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, null, null );
		
		return configuredEndpoint;
	}

	
	updatePersonInsurance(request: UpdatePersonInsuranceRequest ): Observable<PersonInsuranceResponse> {
		const endpointUrl = `/pms2/ws/insurance/updatePersonInsurance`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceResponse, UpdatePersonInsuranceRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePersonInsuranceAndPolicy(request: UpdatePersonInsuranceAndPolicyRequest ): Observable<PersonInsuranceResponse> {
		const endpointUrl = `/pms2/ws/insurance/updatePersonInsuranceAndPolicy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceResponse, UpdatePersonInsuranceAndPolicyRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.interview.InterviewController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class InterviewGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findAvailableInterviewsByPatientId(patientId: number): Observable<InterviewHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/interview/findAvailableInterviewsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInterviewByEncounterId(encounterId: number): Observable<InterviewResponse> {
		const endpointUrl = `/pms2/ws/interview/findInterviewByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInterviewHistoryByPatientId(patientId: number): Observable<InterviewHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/interview/findInterviewHistoryByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInterviewWithIntakeByEncounterId(encounterId: number): Observable<InterviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/interview/findInterviewWithIntakeByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateEncounterInterview(request: UpdateInterviewRequest ): Observable<InterviewResponse> {
		const endpointUrl = `/pms2/ws/interview/updateEncounterInterview`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InterviewResponse, UpdateInterviewRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.inventory.InventoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class InventoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findActiveCptServices(practiceLocationId: number): Observable<InventoryServiceResponse[]> {
		const endpointUrl = `/pms2/ws/inventory/findActiveCptServices/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InventoryServiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveLocationProductItems(locationProductId: number): Observable<LocationProductItemTableResponse[]> {
		const endpointUrl = `/pms2/ws/inventory/findActiveLocationProductItems/${locationProductId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductItemTableResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveLocationProducts(request: FindActiveLocationProductsRequest ): Observable<LocationProductTableResponse[]> {
		const endpointUrl = `/hulk/ws/inventory/findActiveLocationProducts`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductTableResponse, FindActiveLocationProductsRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveOtherServices(practiceLocationId: number): Observable<InventoryServiceResponse[]> {
		const endpointUrl = `/pms2/ws/inventory/findActiveOtherServices/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InventoryServiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getLocationProductById(locationProductId: number): Observable<LocationProductTableResponse> {
		const endpointUrl = `/pms2/ws/inventory/getLocationProductById/${locationProductId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductTableResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getLocationProductItemById(locationProductItemId: number): Observable<LocationProductItemTableResponse> {
		const endpointUrl = `/pms2/ws/inventory/getLocationProductItemById/${locationProductItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductItemTableResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.inventory.item.InventoryItemController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class InventoryItemGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createItemQuantityChange(request: CreateItemQuantityChangeRequest ): Observable<ItemQuantityChangeResponse> {
		const endpointUrl = `/pms2/ws/inventory/item/inventoryItem/createItemQuantityChange`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ItemQuantityChangeResponse, CreateItemQuantityChangeRequest, request );
		
		return configuredEndpoint;
	}

	
	createItemQuantityChangePurchaseOrder(request: CreateItemQuantityChangePurchaseOrderRequest ): Observable<ItemQuantityChangeResponse> {
		const endpointUrl = `/pms2/ws/inventory/item/inventoryItem/createItemQuantityChangePurchaseOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ItemQuantityChangeResponse, CreateItemQuantityChangePurchaseOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	findItemQuantityChangesByLocationProductItemId(locationProductItemId: number): Observable<ItemQuantityChangeResponse[]> {
		const endpointUrl = `/pms2/ws/inventory/item/inventoryItem/findItemQuantityChangesByLocationProductItemId/${locationProductItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ItemQuantityChangeResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.accounting.InvoiceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class InvoiceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addDiscount(request: AddDiscountRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/addDiscount`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddDiscountRequest, request );
		
		return configuredEndpoint;
	}

	
	addDiscountItems(request: AddDiscountItemsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/addDiscountItems`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddDiscountItemsRequest, request );
		
		return configuredEndpoint;
	}

	
	addInvoiceItem(request: AddInvoiceItemRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/addInvoiceItem`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddInvoiceItemRequest, request );
		
		return configuredEndpoint;
	}

	
	addInvoiceItemAdhoc(request: AddAdhocInvoiceItemRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/addInvoiceItemAdhoc`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddAdhocInvoiceItemRequest, request );
		
		return configuredEndpoint;
	}

	
	addPatientPortion(request: PatientPortionRequest ): Observable<number[]> {
		const endpointUrl = `/pms2/ws/invoice/addPatientPortion`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, PatientPortionRequest, request );
		
		return configuredEndpoint;
	}

	
	checkIfInvoiceHasDirectPayment(request: AccountingCheckIfInvoiceHasDirectPaymentRequest ): Observable<AccountingInvoicesWithDirectPaymentsResponse> {
		const endpointUrl = `/pms2/ws/invoice/checkIfInvoiceHasDirectPayment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AccountingInvoicesWithDirectPaymentsResponse, AccountingCheckIfInvoiceHasDirectPaymentRequest, request );
		
		return configuredEndpoint;
	}

	
	createGuestInvoice(request: CreateGuestInvoiceRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/pms2/ws/invoice/createGuestInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, CreateGuestInvoiceRequest, request );
		
		return configuredEndpoint;
	}

	
	existsOnlinePendingPaymentForInvoice(invoiceId: number): Observable<boolean> {
		const endpointUrl = `/pms2/ws/invoice/existsOnlinePendingPaymentForInvoice/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveAppointmentsByPatientId(patientId: number): Observable<InvoiceAppointmentResponse[]> {
		const endpointUrl = `/pms2/ws/invoice/findActiveAppointmentsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceAppointmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findByInvoiceDashboardSearch(request: InvoiceDashboardSearchRequest ): Observable<InvoiceDashboardResponse[]> {
		const endpointUrl = `/pms2/ws/invoice/findByInvoiceDashboardSearch`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceDashboardResponse, InvoiceDashboardSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findInvoiceItemsByInvoiceId(invoiceId: number): Observable<InvoiceItemListResponse[]> {
		const endpointUrl = `/pms2/ws/invoice/findInvoiceItemsByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceItemListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInvoiceStatementsByInvoiceId(invoiceId: number): Observable<StatementInvoiceResponse[]> {
		const endpointUrl = `/pms2/ws/invoice/findInvoiceStatementsByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, StatementInvoiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInvoiceStatusById(invoiceId: number): Observable<InvoiceStatusResponse> {
		const endpointUrl = `/pms2/ws/invoice/findInvoiceStatusById/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceStatusResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPendingPatientInvoicesByPatientId(patientId: number): Observable<InvoiceDateResponse[]> {
		const endpointUrl = `/pms2/ws/invoice/findPendingPatientInvoicesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceDateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findProcessingPaymentGroupId(invoiceId: number): Observable<number> {
		const endpointUrl = `/pms2/ws/invoice/findProcessingPaymentGroupId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, null, null );
		
		return configuredEndpoint;
	}

	
	getInvoiceById(invoiceId: number): Observable<InvoiceResponse> {
		const endpointUrl = `/pms2/ws/invoice/getInvoiceById/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getInvoiceForDashboardById(invoiceId: number): Observable<InvoiceDashboardResponse> {
		const endpointUrl = `/pms2/ws/invoice/getInvoiceForDashboardById/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceDashboardResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getInvoiceItemDetailById(invoiceItemId: number): Observable<InvoiceItemDetailResponse> {
		const endpointUrl = `/pms2/ws/invoice/getInvoiceItemDetailById/${invoiceItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceItemDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	grantInvoiceCredits(request: GrantCreditsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/grantInvoiceCredits`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, GrantCreditsRequest, request );
		
		return configuredEndpoint;
	}

	
	markInvoicePaid(invoiceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/markInvoicePaid/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	markInvoiceUnpaid(invoiceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/markInvoiceUnpaid/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	putInvoiceOnHold(invoiceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/putInvoiceOnHold/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	removeInvoiceItem(request: RemoveInvoiceItemRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/removeInvoiceItem`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, RemoveInvoiceItemRequest, request );
		
		return configuredEndpoint;
	}

	
	splitInvoiceItemPayment(request: SplitPaymentRequest ): Observable<InvoiceResponse[]> {
		const endpointUrl = `/pms2/ws/invoice/splitInvoiceItemPayment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, SplitPaymentRequest, request );
		
		return configuredEndpoint;
	}

	
	transferInvoice(request: InvoiceTransferRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/pms2/ws/invoice/transferInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, InvoiceTransferRequest, request );
		
		return configuredEndpoint;
	}

	
	transferInvoiceBalanceToPatient(request: TransferInvoiceBalanceToPatientRequest ): Observable<InvoiceTransferResponse> {
		const endpointUrl = `/pms2/ws/invoice/transferInvoiceBalanceToPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceTransferResponse, TransferInvoiceBalanceToPatientRequest, request );
		
		return configuredEndpoint;
	}

	
	transferInvoiceItems(request: TransferInvoiceItemsRequest ): Observable<InvoiceResponse[]> {
		const endpointUrl = `/pms2/ws/invoice/transferInvoiceItems`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, TransferInvoiceItemsRequest, request );
		
		return configuredEndpoint;
	}

	
	unapproveInvoice(invoiceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/unapproveInvoice/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	unassignInvoiceAppointment(invoiceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/unassignInvoiceAppointment/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	unassignInvoiceEncounter(invoiceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/unassignInvoiceEncounter/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	unassignInvoiceProvider(invoiceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/unassignInvoiceProvider/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	updateInvoiceAppointment(request: UpdateInvoiceAppointmentRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/updateInvoiceAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateInvoiceAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceDiagnoses(request: InvoiceDiagnosesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/updateInvoiceDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, InvoiceDiagnosesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceEncounter(request: UpdateInvoiceEncounterRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/updateInvoiceEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateInvoiceEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceItem(request: UpdateInvoiceItemRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/updateInvoiceItem`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateInvoiceItemRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceItemQuantity(request: UpdateInvoiceItemQuantityRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/updateInvoiceItemQuantity`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateInvoiceItemQuantityRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceProvider(request: UpdateInvoiceProviderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/updateInvoiceProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateInvoiceProviderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateItemResponsiblePersons(request: UpdateInvoiceItemResponsiblePersonListRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/invoice/updateItemResponsiblePersons`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateInvoiceItemResponsiblePersonListRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.accounting.claims.InvoiceClaimController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class InvoiceClaimGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findAdditionalClaimInformationByInvoiceId(invoiceId: number): Observable<AdditionalClaimInformationResponse> {
		const endpointUrl = `/pms2/ws/accounting/invoice/claim/findAdditionalClaimInformationByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdditionalClaimInformationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findClaimsByInvoiceId(invoiceId: number): Observable<InvoiceInsuranceClaimResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/invoice/claim/findClaimsByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceInsuranceClaimResponse, null, null );
		
		return configuredEndpoint;
	}

	
	markClaimAsDeleted(insuranceClaimId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/invoice/claim/markClaimAsDeleted/${insuranceClaimId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	resetClaim(insuranceClaimId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/invoice/claim/resetClaim/${insuranceClaimId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	updateAdditionalClaimInformation(request: UpdateAdditionalClaimInformationRequest ): Observable<AdditionalClaimInformationResponse> {
		const endpointUrl = `/pms2/ws/accounting/invoice/claim/updateAdditionalClaimInformation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdditionalClaimInformationResponse, UpdateAdditionalClaimInformationRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.laborder.LabOrderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LabOrderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	cancelLabOrder(labOrderId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/laborder/cancelLabOrder/${labOrderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	completeLabOrder(labOrderId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/laborder/completeLabOrder/${labOrderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createLabOrder(request: CreateLabOrderRequest ): Observable<LabOrderResponse> {
		const endpointUrl = `/pms2/ws/laborder/createLabOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LabOrderResponse, CreateLabOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	findLabOrderById(labOrderId: number): Observable<LabOrderResponse> {
		const endpointUrl = `/pms2/ws/laborder/findLabOrderById/${labOrderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LabOrderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findLabOrdersByPatientId(patientId: number): Observable<LabOrderListResponse[]> {
		const endpointUrl = `/pms2/ws/laborder/findLabOrdersByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LabOrderListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findLabVendors(): Observable<LabVendorResponse[]> {
		const endpointUrl = `/pms2/ws/laborder/findLabVendors`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LabVendorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getLoincCodeByNum(request: LoincCodeRequest ): Observable<LoincCodeResponse> {
		const endpointUrl = `/pms2/ws/laborder/getLoincCodeByNum`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LoincCodeResponse, LoincCodeRequest, request );
		
		return configuredEndpoint;
	}

	
	searchLoincCodes(request: SearchLoincCodesRequest ): Observable<LoincCodeResponse[]> {
		const endpointUrl = `/pms2/ws/laborder/searchLoincCodes`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LoincCodeResponse, SearchLoincCodesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateLabOrder(request: UpdateLabOrderRequest ): Observable<LabOrderResponse> {
		const endpointUrl = `/pms2/ws/laborder/updateLabOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LabOrderResponse, UpdateLabOrderRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.license.LicenseController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LicenseGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getAgreementDetails(): Observable<AgreementDetailsResponse> {
		const endpointUrl = `/pms2/ws/license/getAgreementDetails`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AgreementDetailsResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.barcode.LocationProductItemForBarcodePrintingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LocationProductItemBarcodeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getLocationProductItemForBarcodePrinting(locationProductItemId: number): Observable<LocationProductItemForBarcodePrintingResponse> {
		const endpointUrl = `/pms2/ws/barcode/getLocationProductItemForBarcodePrintingById/${locationProductItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductItemForBarcodePrintingResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.data.MasterReferenceDataController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class MasterReferenceDataGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findMasterReferenceDataByCategoryId(categoryId: number): Observable<MasterReferenceDataResponse[]> {
		const endpointUrl = `/pms2/ws/masterReferenceData/findMasterReferenceDataByCategoryId/${categoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MasterReferenceDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getMasterReferenceDataById(masterReferenceDataId: number): Observable<MasterReferenceDataResponse> {
		const endpointUrl = `/pms2/ws/masterReferenceData/getMasterReferenceDataById/${masterReferenceDataId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MasterReferenceDataResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.assessment.MedicalDecisionMakingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class MedicalDecisionMakingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findQueryTriggerSourceByPatientTriggerOutcomeId(patientTriggerOutcomeId: number): Observable<QueryTriggerSourceResponse> {
		const endpointUrl = `/pms2/ws/medicalDecisionMaking/findQueryTriggerSourceByPatientTriggerOutcomeId/${patientTriggerOutcomeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryTriggerSourceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getMedicalDecisionMakingByEncounterId(encounterId: number): Observable<MedicalDecisionMakingResponse> {
		const endpointUrl = `/pms2/ws/medicalDecisionMaking/getMedicalDecisionMakingByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicalDecisionMakingResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getMedicalDecisionMakingById(medicalDecisionMakingId: number): Observable<MedicalDecisionMakingResponse> {
		const endpointUrl = `/pms2/ws/medicalDecisionMaking/getMedicalDecisionMakingById/${medicalDecisionMakingId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicalDecisionMakingResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateMedicalDecisionMaking(request: UpdateMedicalDecisionMakingRequest ): Observable<MedicalDecisionMakingResponse> {
		const endpointUrl = `/pms2/ws/medicalDecisionMaking/updateMedicalDecisionMaking`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicalDecisionMakingResponse, UpdateMedicalDecisionMakingRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.prescription.MedicationPrescriptionController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class MedicationGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	acceptIntakeMedications(request: UpdateIntakeMedicationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/medication/acceptIntakeMedications`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateIntakeMedicationRequest, request );
		
		return configuredEndpoint;
	}

	
	authorizeMedicationPrescription(request: AuthorizeMedicationPrescriptionRequest ): Observable<MedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/authorizeMedicationPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionResponse, AuthorizeMedicationPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	createGeneralMedicationPrescription(request: CreateGeneralMedicationPrescriptionRequest ): Observable<MedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/createGeneralMedicationPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionResponse, CreateGeneralMedicationPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	createMedicationPrescription(request: CreateMedicationPrescriptionRequest ): Observable<MedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/createMedicationPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionResponse, CreateMedicationPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	declineIntakeMedications(request: UpdateIntakeMedicationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/prescription/medication/declineIntakeMedications`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateIntakeMedicationRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveByPatientId(patientId: number): Observable<MedicationPrescriptionListResponse[]> {
		const endpointUrl = `/pms2/ws/prescription/medication/findActiveByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findByPatientId(patientId: number): Observable<MedicationPrescriptionListResponse[]> {
		const endpointUrl = `/pms2/ws/prescription/medication/findByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMostRecentIntakeMedicationByPatientId(patientId: number): Observable<IntakeMedicationResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/findMostRecentIntakeMedicationByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, IntakeMedicationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getExternalMedicationPrescriptionById(externalMedicationPrescriptionId: number): Observable<ExternalMedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/getExternalMedicationPrescriptionById/${externalMedicationPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalMedicationPrescriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getMedicationPrescriptionById(medicationPrescriptionId: number): Observable<MedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/getMedicationPrescriptionById/${medicationPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getMedicationPrescriptionType(medicationPrescriptionId: number): Observable<MedicationPrescriptionTypeResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/getMedicationPrescriptionType/${medicationPrescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionTypeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	refillMedicationPrescription(request: MedicationPrescriptionRefillRequest ): Observable<MedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/refillMedicationPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionResponse, MedicationPrescriptionRefillRequest, request );
		
		return configuredEndpoint;
	}

	
	stopMedicationPrescription(request: StopPrescriptionRequest ): Observable<MedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/stopMedicationPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionResponse, StopPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updateGeneralMedicationPrescription(request: UpdateGeneralMedicationPrescriptionRequest ): Observable<MedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/updateGeneralMedicationPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionResponse, UpdateGeneralMedicationPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updateMedicationPrescription(request: UpdateMedicationPrescriptionRequest ): Observable<MedicationPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/prescription/medication/updateMedicationPrescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationPrescriptionResponse, UpdateMedicationPrescriptionRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.medication.MedicationReviewHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class MedicationReviewHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createMedicationReviewHistory(request: CreateMedicationReviewHistoryRequest ): Observable<MedicationReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/history/medicationReview/createMedicationReviewHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationReviewHistoryResponse, CreateMedicationReviewHistoryRequest, request );
		
		return configuredEndpoint;
	}

	
	findMostRecentMedicationReviewByEncounterId(encounterId: number): Observable<MedicationReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/history/medicationReview/findMostRecentMedicationReviewByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationReviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMostRecentMedicationReviewByPatientId(patientId: number): Observable<MedicationReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/history/medicationReview/findMostRecentMedicationReviewByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationReviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateMedicationReviewHistory(request: UpdateMedicationReviewHistoryRequest ): Observable<MedicationReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/history/medicationReview/updateMedicationReviewHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MedicationReviewHistoryResponse, UpdateMedicationReviewHistoryRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.mentalstatus.MentalStatusController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class MentalStatusGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findOrCreateMentalStatusByEncounterId(encounterId: number): Observable<MentalStatusResponse> {
		const endpointUrl = `/pms2/ws/mentalStatus/findOrCreateMentalStatusByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MentalStatusResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateMentalStatus(request: UpdateMentalStatusRequest ): Observable<MentalStatusResponse> {
		const endpointUrl = `/pms2/ws/mentalStatus/updateMentalStatus`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MentalStatusResponse, UpdateMentalStatusRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.messaging.MessagingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class MessagingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	createMessage(): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/createMessage`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createMessageFolderForEmployee(request: CreateMessageFolderRequest ): Observable<MessageFolderResponse> {
		const endpointUrl = `/pms2/ws/messaging/createMessageFolderForEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MessageFolderResponse, CreateMessageFolderRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteMessageParticipantPersonById(messageParticipantPersonId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/deleteMessageParticipantById/${messageParticipantPersonId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deleteMessageParticipantPersonsByIds(request: DeleteMessageParticipantPersonsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/deleteMessageParticipantPersonsByIds`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeleteMessageParticipantPersonsRequest, request );
		
		return configuredEndpoint;
	}

	
	findExternalProvidersWithDirect(cacheOptions?: GandalfCacheableConfigurableOptions<MessagingDirectProviderResponse[]>): Observable<MessagingDirectProviderResponse[]> {
		const endpointUrl = `/pms2/ws/messaging/findExternalProvidersWithDirect`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MessagingDirectProviderResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['providers'], cacheableOptions);
	}

	
	getForwardData(forwardingMessageParticipantPersonId: number): Observable<ForwardDataResponse> {
		const endpointUrl = `/pms2/ws/messaging/getForwardData/${forwardingMessageParticipantPersonId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ForwardDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getMessagingFoldersForEmployee(): Observable<MessageFolderResponse[]> {
		const endpointUrl = `/pms2/ws/messaging/getMessagingFoldersForEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MessageFolderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getNewMessageParticipantCountForEmployee(): Observable<number> {
		const endpointUrl = `/pms2/ws/messaging/getNewMessageParticipantCountForEmployee`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, null, null );
		
		return configuredEndpoint;
	}

	
	getReplyAllData(replyingAllMessageParticipantPersonId: number): Observable<ReplyAllDataResponse> {
		const endpointUrl = `/pms2/ws/messaging/getReplyAllData/${replyingAllMessageParticipantPersonId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReplyAllDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getReplyData(replyingMessageParticipantPersonId: number): Observable<ReplyDataResponse> {
		const endpointUrl = `/pms2/ws/messaging/getReplyData/${replyingMessageParticipantPersonId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReplyDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	markMessageParticipantPersonsNewByIds(request: MarkMessageParticipantPersonsNewRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/markMessageParticipantPersonsNewByIds`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, MarkMessageParticipantPersonsNewRequest, request );
		
		return configuredEndpoint;
	}

	
	readMessageByMessageParticipantPersonIdAndMarkRead(messageParticipantPersonId: number): Observable<MessageDetailResponse> {
		const endpointUrl = `/pms2/ws/messaging/readMessageByMessageParticipantPersonIdAndMarkRead/${messageParticipantPersonId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MessageDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	saveMessageFileAsPatientFile(request: SaveMessageFileAsPatientFileRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/saveMessageFileAsPatientFile`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, SaveMessageFileAsPatientFileRequest, request );
		
		return configuredEndpoint;
	}

	
	searchMessageParticipantPersons(request: SearchMessageParticipantPersonsRequest ): Observable<MessageParticipantResponse[]> {
		const endpointUrl = `/pms2/ws/messaging/searchMessageParticipantPersons`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MessageParticipantResponse, SearchMessageParticipantPersonsRequest, request );
		
		return configuredEndpoint;
	}

	
	starMessageParticipant(messageParticipantPersonId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/starMessageParticipant/${messageParticipantPersonId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	unstarMessageParticipant(messageParticipantPersonId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/unstarMessageParticipant/${messageParticipantPersonId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	updateMessageParticipantPersonFolder(request: UpdateMessageParticipantPersonFolderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/updateMessageParticipantPersonFolder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateMessageParticipantPersonFolderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateMessageParticipantPersonFolders(request: UpdateMessageParticipantPersonFolderRequest[] ): Observable<void> {
		const endpointUrl = `/pms2/ws/messaging/updateMessageParticipantPersonFolders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateMessageParticipantPersonFolderRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.note.NoteController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class NoteGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateNote(noteId: number): Observable<NoteResponse> {
		const endpointUrl = `/pms2/ws/note/activateNote/${noteId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, NoteResponse, null, null );
		
		return configuredEndpoint;
	}

	
	create(request: CreateNoteRequest ): Observable<NoteResponse> {
		const endpointUrl = `/pms2/ws/note/create`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, NoteResponse, CreateNoteRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateNote(noteId: number): Observable<NoteResponse> {
		const endpointUrl = `/pms2/ws/note/deactivateNote/${noteId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, NoteResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findByParentIdAndEntityType(request: FindNotesByParentEntityRequest ): Observable<NoteResponse[]> {
		const endpointUrl = `/pms2/ws/note/findByParentIdAndEntityType`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, NoteResponse, FindNotesByParentEntityRequest, request );
		
		return configuredEndpoint;
	}

	
	getNoteById(noteId: number): Observable<NoteResponse> {
		const endpointUrl = `/pms2/ws/note/getNoteById/${noteId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, NoteResponse, null, null );
		
		return configuredEndpoint;
	}

	
	update(request: UpdateNoteRequest ): Observable<NoteResponse> {
		const endpointUrl = `/pms2/ws/note/update`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, NoteResponse, UpdateNoteRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.accounting.openedge.OpenEdgePaymentsController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class OpenEdgePaymentGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createSaleTransaction(request: OpenEdgeCreatePayFieldsTransactionRequest ): Observable<OpenEdgeTransactionDetailsResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/createSaleTransaction`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeTransactionDetailsResponse, OpenEdgeCreatePayFieldsTransactionRequest, request );
		
		return configuredEndpoint;
	}

	
	createSavedCard(request: OpenEdgeCreateSavedCardRequest ): Observable<OpenEdgeSaveCardResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/createSavedCard`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeSaveCardResponse, OpenEdgeCreateSavedCardRequest, request );
		
		return configuredEndpoint;
	}

	
	createStoredTokenRefundTransaction(request: OpenEdgeCreateStoredCardTransactionRequest ): Observable<OpenEdgeTransactionDetailsResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/createStoredTokenRefundTransaction`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeTransactionDetailsResponse, OpenEdgeCreateStoredCardTransactionRequest, request );
		
		return configuredEndpoint;
	}

	
	createStoredTokenSaleTransaction(request: OpenEdgeCreateStoredCardTransactionRequest ): Observable<OpenEdgeTransactionDetailsResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/createStoredTokenSaleTransaction`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeTransactionDetailsResponse, OpenEdgeCreateStoredCardTransactionRequest, request );
		
		return configuredEndpoint;
	}

	
	existsActiveOpenEdgePaymentByInvoice(invoiceId: number): Observable<boolean> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/existsActiveOpenEdgePaymentByInvoice/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveOpenEdgeCardReadersByLocationId(practiceLocationId: number): Observable<OpenEdgeCardReaderResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/findActiveOpenEdgeCardReadersByLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeCardReaderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findOpenEdgeReturnTransaction(refundId: number): Observable<OpenEdgePaymentTransactionResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/findOpenEdgeReturnTransaction/${refundId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgePaymentTransactionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findOpenEdgeSaleTransaction(paymentId: number): Observable<OpenEdgePaymentTransactionResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/findOpenEdgeSaleTransaction/${paymentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgePaymentTransactionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findSavedCardsByPersonId(personId: number): Observable<PaymentStoredTokenResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/findSavedCardsByPersonId/${personId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentStoredTokenResponse, null, null );
		
		return configuredEndpoint;
	}

	
	finishTransaction(request: OpenEdgeFinishTransactionRequest ): Observable<OpenEdgeTransactionDetailsResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/finishTransaction`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeTransactionDetailsResponse, OpenEdgeFinishTransactionRequest, request );
		
		return configuredEndpoint;
	}

	
	getActiveSavedTokensForPatientById(patientId: number): Observable<PaymentStoredTokenResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/getActiveSavedTokensForPatientById/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentStoredTokenResponse, null, null );
		
		return configuredEndpoint;
	}

	
	removeStoredToken(paymentStoredTokenId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/removeStoredToken/${paymentStoredTokenId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	setupTransportKeysForReturn(request: OpenEdgeSetupTransportKeysRequest ): Observable<OpenEdgeTransportKeysResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/setupTransportKeysForReturn`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeTransportKeysResponse, OpenEdgeSetupTransportKeysRequest, request );
		
		return configuredEndpoint;
	}

	
	setupTransportKeysForSale(request: OpenEdgeSetupTransportKeysRequest ): Observable<OpenEdgeTransportKeysResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/setupTransportKeysForSale`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeTransportKeysResponse, OpenEdgeSetupTransportKeysRequest, request );
		
		return configuredEndpoint;
	}

	
	voidReturnTransaction(refundId: number): Observable<OpenEdgeVoidTransactionResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/voidReturnTransaction/${refundId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeVoidTransactionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	voidSaleTransaction(paymentId: number): Observable<OpenEdgeVoidTransactionResponse> {
		const endpointUrl = `/pms2/ws/accounting/openEdge/voidSaleTransaction/${paymentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenEdgeVoidTransactionResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class OpticalSummaryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findEncounterHistoryNonCancelled(patientId: number): Observable<OpticalSummaryEncounterHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/patient/opticalSummary/findEncounterHistoryNonCancelled/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpticalSummaryEncounterHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getLatestOpticalFindings(encounterId: number): Observable<OpticalSummaryLatestFindingsResponse> {
		const endpointUrl = `/pms2/ws/patient/opticalSummary/getLatestOpticalFindings/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpticalSummaryLatestFindingsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getOrderDetailById(orderId: number): Observable<OpticalOrderDetailResponse> {
		const endpointUrl = `/pms2/ws/patient/opticalSummary/getOrderDetailById/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpticalOrderDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchOrdersOptical(request: OpticalOrderPodOrderSearchRequest ): Observable<OpticalOrderPodOrderResponse[]> {
		const endpointUrl = `/pms2/ws/patient/opticalSummary/searchOrdersOptical`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpticalOrderPodOrderResponse, OpticalOrderPodOrderSearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.order.OrderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class OrderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	addPatientOwnedFrame(request: AddPatientOwnedFrameRequest ): Observable<AddPatientOwnedFrameResponse> {
		const endpointUrl = `/pms2/ws/order/addPatientOwnedFrame`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AddPatientOwnedFrameResponse, AddPatientOwnedFrameRequest, request );
		
		return configuredEndpoint;
	}

	
	cancelOrder(request: CancelOrderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/order/cancelOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CancelOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	changeOrder(request: ChangeOrderRequest ): Observable<ChangeOrderResponse> {
		const endpointUrl = `/pms2/ws/order/changeOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ChangeOrderResponse, ChangeOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	completeOrder(orderId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/order/completeOrder/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createContactLensOrder(request: CreateContactLensOrderRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/order/createContactLensOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, CreateContactLensOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	createContactLensTrialOrder(request: CreateContactLensTrialOrderBasicRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/order/createContactLensTrialOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, CreateContactLensTrialOrderBasicRequest, request );
		
		return configuredEndpoint;
	}

	
	createEyeglassOrder(request: CreateEyeglassOrderRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/order/createEyeglassOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, CreateEyeglassOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	createFrameOrder(request: CreateFrameOrderRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/order/createFrameOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, CreateFrameOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	dispenseOrder(request: DispenseOrderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/order/dispenseOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DispenseOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	doesPersonHaveActiveEyefinityInsurance(personId: number): Observable<boolean> {
		const endpointUrl = `/pms2/ws/order/doesPersonHaveActiveEyefinityInsurance/${personId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, null, null );
		
		return configuredEndpoint;
	}

	
	duplicateOrder(orderId: number): Observable<DuplicateOrderResponse> {
		const endpointUrl = `/pms2/ws/order/duplicateOrder/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DuplicateOrderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findBillableItemsByOrderId(orderId: number): Observable<BillableItemProductResponse[]> {
		const endpointUrl = `/pms2/ws/order/findBillableItemsByOrderId/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BillableItemProductResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFrameColorsByFrameId(frameId: number): Observable<FrameColorResponse[]> {
		const endpointUrl = `/pms2/ws/order/findFrameColorsByFrameId/${frameId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FrameColorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFrameSizesByFrameId(frameId: number): Observable<FrameSizeResponse[]> {
		const endpointUrl = `/pms2/ws/order/findFrameSizesByFrameId/${frameId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FrameSizeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInvoicesByOrderId(orderId: number): Observable<BillingInvoiceResponse[]> {
		const endpointUrl = `/pms2/ws/order/findInvoicesByOrderId/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BillingInvoiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findManualVendors(request: FindManualVendorsRequest ): Observable<OrderVendorResponse[]> {
		const endpointUrl = `/pms2/ws/order/findManualVendors`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderVendorResponse, FindManualVendorsRequest, request );
		
		return configuredEndpoint;
	}

	
	findOrderProcessors(request: FindOrderProcessorsRequest ): Observable<OrderProcessorConfigResponse[]> {
		const endpointUrl = `/pms2/ws/order/findOrderProcessors`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderProcessorConfigResponse, FindOrderProcessorsRequest, request );
		
		return configuredEndpoint;
	}

	
	findOrderStatusHistory(orderId: number): Observable<OrderStatusHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/order/findOrderStatusHistory/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderStatusHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPerformedServicesByEncounterId(encounterId: number): Observable<OrderPerformedServiceResponse[]> {
		const endpointUrl = `/pms2/ws/order/findPerformedServicesByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderPerformedServiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findSingleProductForVCode(request: FindVCodeRequest ): Observable<LocationProductForOrderResponse> {
		const endpointUrl = `/pms2/ws/order/findSingleProductForVCode`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductForOrderResponse, FindVCodeRequest, request );
		
		return configuredEndpoint;
	}

	
	findSmartflowOrderVendorsExcludingTwelve84(request: FindSmartflowOrderVendorsRequest ): Observable<OrderVendorResponse[]> {
		const endpointUrl = `/pms2/ws/order/findSmartflowOrderVendorsExcludingTwelve84`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderVendorResponse, FindSmartflowOrderVendorsRequest, request );
		
		return configuredEndpoint;
	}

	
	findTwelve84ConfigurationForLocation(locationId: number, cacheOptions?: GandalfCacheableConfigurableOptions<OrderTwelve84ConfigurationResponse>): Observable<OrderTwelve84ConfigurationResponse> {
		const endpointUrl = `/pms2/ws/order/findTwelve84ConfigurationForLocation/${locationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderTwelve84ConfigurationResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['twelve84'], cacheableOptions);
	}

	
	findVendorConfigsByLocation(locationId: number): Observable<OrderVendorConfigResponse[]> {
		const endpointUrl = `/pms2/ws/order/findVendorConfigsByLocation/${locationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderVendorConfigResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findVisionWebOrderSuppliers(practiceLocationId: number, cacheOptions?: GandalfCacheableConfigurableOptions<VisionWebSupplierResponse[]>): Observable<VisionWebSupplierResponse[]> {
		const endpointUrl = `/pms2/ws/order/findVisionWebOrderSuppliers/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VisionWebSupplierResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['visionweb_suppliers'], cacheableOptions);
	}

	
	getActiveManufacturersForFrames(): Observable<ManufacturerForPatientFrameResponse[]> {
		const endpointUrl = `/pms2/ws/order/getActiveManufacturersForFrames`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ManufacturerForPatientFrameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getBrandsByManufacturerId(manufacturerId: number): Observable<ProductBrandForPatientFrameResponse[]> {
		const endpointUrl = `/pms2/ws/order/getBrandsByManufacturerId/${manufacturerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductBrandForPatientFrameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getCollectionsByBrandId(brandId: number): Observable<ProductCollectionForPatientFrameResponse[]> {
		const endpointUrl = `/pms2/ws/order/getCollectionsByBrandId/${brandId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductCollectionForPatientFrameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getContactLensTrialById(trialId: number): Observable<OrderContactLensTrialResponse> {
		const endpointUrl = `/pms2/ws/order/getContactLensTrialById/${trialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderContactLensTrialResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getOpenOrderById(orderId: number): Observable<OpenOrderResponse> {
		const endpointUrl = `/pms2/ws/order/getOpenOrderById/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OpenOrderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getOrderById(orderId: number): Observable<OrderResponse> {
		const endpointUrl = `/pms2/ws/order/getOrderById/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getOrderByIdForPrint(orderId: number): Observable<OrderForPrintResponse> {
		const endpointUrl = `/pms2/ws/order/getOrderByIdForPrint/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderForPrintResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPrescriptionById(prescriptionId: number): Observable<OrderPrescriptionResponse> {
		const endpointUrl = `/pms2/ws/order/getPrescriptionById/${prescriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderPrescriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPreviewBillableItems(orderId: number): Observable<PreviewBillableItemResponse[]> {
		const endpointUrl = `/pms2/ws/order/getPreviewBillableItems/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PreviewBillableItemResponse, null, null );
		
		return configuredEndpoint;
	}

	
	notifyPatientWithConnectMessage(request: SendOrderConnectMessageRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/order/notifyPatientWithConnectMessage`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, SendOrderConnectMessageRequest, request );
		
		return configuredEndpoint;
	}

	
	putOrderOnHold(request: UpdateOrderOnHoldRequest ): Observable<OrderOnHoldHistoryResponse> {
		const endpointUrl = `/pms2/ws/order/putOrderOnHold`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderOnHoldHistoryResponse, UpdateOrderOnHoldRequest, request );
		
		return configuredEndpoint;
	}

	
	receiveOrder(orderId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/order/receiveOrder/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	remakeOrder(request: RemakeOrderRequest ): Observable<RemakeOrderResponse> {
		const endpointUrl = `/pms2/ws/order/remakeOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RemakeOrderResponse, RemakeOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	removeOrderOnHold(request: UpdateOrderOnHoldRequest ): Observable<OrderOnHoldHistoryResponse> {
		const endpointUrl = `/pms2/ws/order/removeOrderOnHold`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderOnHoldHistoryResponse, UpdateOrderOnHoldRequest, request );
		
		return configuredEndpoint;
	}

	
	returnOrder(request: ReturnOrderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/order/returnOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, ReturnOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateBillableItemsNonBillable(request: UpdateNonBillableRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/order/updateBillableItemsNonBillable`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateNonBillableRequest, request );
		
		return configuredEndpoint;
	}

	
	updateContactLensOrder(request: UpdateContactLensOrderRequest ): Observable<ContactLensOrderResponse> {
		const endpointUrl = `/pms2/ws/order/updateContactLensOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensOrderResponse, UpdateContactLensOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateContactLensTrialOrder(request: UpdateContactLensTrialOrderRequest ): Observable<ContactLensTrialOrderResponse> {
		const endpointUrl = `/pms2/ws/order/updateContactLensTrialOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensTrialOrderResponse, UpdateContactLensTrialOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEyefinityContactLensOrder(request: UpdateEyefinityContactLensOrderRequest ): Observable<EyefinityContactLensOrderResponse> {
		const endpointUrl = `/pms2/ws/order/updateEyefinityContactLensOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyefinityContactLensOrderResponse, UpdateEyefinityContactLensOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEyefinityEyeglassOrder(request: UpdateEyefinityEyeglassOrderRequest ): Observable<EyefinityEyeglassOrderResponse> {
		const endpointUrl = `/pms2/ws/order/updateEyefinityEyeglassOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyefinityEyeglassOrderResponse, UpdateEyefinityEyeglassOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEyefinityFrameOrder(request: UpdateEyefinityFrameOrderRequest ): Observable<EyefinityFrameOrderResponse> {
		const endpointUrl = `/pms2/ws/order/updateEyefinityFrameOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyefinityFrameOrderResponse, UpdateEyefinityFrameOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEyeglassOrder(request: UpdateEyeglassOrderRequest ): Observable<EyeglassOrderResponse> {
		const endpointUrl = `/pms2/ws/order/updateEyeglassOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassOrderResponse, UpdateEyeglassOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateFrameOrder(request: UpdateFrameOrderRequest ): Observable<FrameOrderResponse> {
		const endpointUrl = `/pms2/ws/order/updateFrameOrder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FrameOrderResponse, UpdateFrameOrderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateOrderStatus(request: UpdateOrderStatusRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/order/updateOrderStatus`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateOrderStatusRequest, request );
		
		return configuredEndpoint;
	}

	
	updateStatusToQuoted(orderId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/order/updateStatusToQuoted/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	vendorShipOrder(orderId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/order/vendorShipOrder/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	verifyOrder(orderId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/order/verifyOrder/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.order.eyefinity.OrderEyefinityFeeMappingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class OrderEyefinityFeeMappingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findByPracticeLocation(practiceLocationId: number): Observable<OrderEyefinityFeeMappingResponse[]> {
		const endpointUrl = `/pms2/ws/orderEyefinityFeeMapping/findByPracticeLocation/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderEyefinityFeeMappingResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.order.eyefinity.OrderEyefinityFeeScheduleController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class OrderEyefinityFeeScheduleGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findActiveByPracticeLocationId(practiceLocationId: number): Observable<OrderEyefinityFeeScheduleListResponse[]> {
		const endpointUrl = `/pms2/ws/orderEyefinityFeeSchedule/findActiveByPracticeLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderEyefinityFeeScheduleListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getEyefinityFeeScheduleById(eyefinityFeeScheduleId: number): Observable<OrderEyefinityFeeScheduleResponse> {
		const endpointUrl = `/pms2/ws/orderEyefinityFeeSchedule/getEyefinityFeeScheduleById/${eyefinityFeeScheduleId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, OrderEyefinityFeeScheduleResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.order.pupillarydistance.OrderPatientPupillaryDistanceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class OrderPatientPupillaryDistanceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findPupillaryDistanceHistoryByPatientId(patientId: number): Observable<PupillaryDistanceHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/order/patientPupillaryDistance/findPupillaryDistanceHistoryByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PupillaryDistanceHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.ordersoptical.OrdersOpticalController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class OrdersOpticalGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findOrderHistoryByPatientId(patientId: number): Observable<PatientOrderHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/patient/order/optical/findOrderHistoryByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientOrderHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.practicepreferences.PartnerIntegrationsController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PartnerIntegrationsGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateWebServiceUser(webServiceUserId: number): Observable<WebServiceUserResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/partnerIntegrations/activateWebServiceUser/${webServiceUserId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WebServiceUserResponse, null, null );
		
		return configuredEndpoint;
	}

	
	createWebServiceUser(request: CreateWebServiceUserRequest ): Observable<WebServiceUserResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/partnerIntegrations/createWebServiceUser`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WebServiceUserResponse, CreateWebServiceUserRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateWebServiceUser(webServiceUserId: number): Observable<WebServiceUserResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/partnerIntegrations/deactivateWebServiceUser/${webServiceUserId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WebServiceUserResponse, null, null );
		
		return configuredEndpoint;
	}

	
	disablePracticeSmartApplication(masterSmartApplicationId: number): Observable<AdminSmartApplicationResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/partnerIntegrations/disablePracticeSmartApplication/${masterSmartApplicationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminSmartApplicationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	enablePracticeSmartApplication(masterSmartApplicationId: number): Observable<AdminSmartApplicationResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/partnerIntegrations/enablePracticeSmartApplication/${masterSmartApplicationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminSmartApplicationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMasterSmartApplications(): Observable<AdminSmartApplicationResponse[]> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/partnerIntegrations/findMasterSmartApplications`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdminSmartApplicationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findWebServiceUsersForCurrentPractice(): Observable<WebServiceUserResponse[]> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/partnerIntegrations/findWebServiceUsersForCurrentPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WebServiceUserResponse, null, null );
		
		return configuredEndpoint;
	}

	
	resetWebServiceUserPassword(webServiceUserId: number): Observable<WebServiceUserResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/partnerIntegrations/resetWebServiceUserPassword/${webServiceUserId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WebServiceUserResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.PatientController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePatient(patientId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/activatePatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createPatient(request: CreatePatientRequest ): Observable<number> {
		const endpointUrl = `/pms2/ws/patient/createPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, CreatePatientRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePatient(patientId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/deactivatePatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findBySimpleSearch(request: PatientSimpleSearchRequest ): Observable<PatientSearchResponse[]> {
		const endpointUrl = `/pms2/ws/patient/findBySimpleSearch`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientSearchResponse, PatientSimpleSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findDuplicatePatients(request: PatientDuplicateSearchRequest ): Observable<PatientDuplicateSearchResponse[]> {
		const endpointUrl = `/pms2/ws/patient/findDuplicatePatients`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientDuplicateSearchResponse, PatientDuplicateSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findPatientEmergencyContacts(patientId: number): Observable<PatientEmergencyContactResponse[]> {
		const endpointUrl = `/pms2/ws/patient/findPatientEmergencyContacts/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientEmergencyContactResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientFilesInFolder(request: PatientFileListRequest ): Observable<PatientFileListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/findPatientFilesInFolder`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientFileListResponse, PatientFileListRequest, request );
		
		return configuredEndpoint;
	}

	
	findPatients(request: PatientSearchRequest ): Observable<PatientSearchResponse[]> {
		const endpointUrl = `/pms2/ws/patient/findPatients`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientSearchResponse, PatientSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findPatientsByIds(request: PatientSearchByIdsRequest ): Observable<PatientSearchResponse[]> {
		const endpointUrl = `/pms2/ws/patient/findPatientsByIds`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientSearchResponse, PatientSearchByIdsRequest, request );
		
		return configuredEndpoint;
	}

	
	findPatientsHeuristic(request: PatientSearchStringRequest ): Observable<PatientHeuristicSearchResponse> {
		const endpointUrl = `/pms2/ws/patient/findPatientsHeuristic`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientHeuristicSearchResponse, PatientSearchStringRequest, request );
		
		return configuredEndpoint;
	}

	
	findSchedulingPreferencesByPatientId(patientId: number): Observable<PatientSchedulingPreferenceResponse> {
		const endpointUrl = `/pms2/ws/patient/findSchedulingPreferencesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientSchedulingPreferenceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getAlertsByPatientId(patientId: number): Observable<AlertResponse[]> {
		const endpointUrl = `/pms2/ws/patient/getAlertsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AlertResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getGuarantorForPatientById(patientId: number): Observable<PatientGuarantorResponse> {
		const endpointUrl = `/pms2/ws/patient/getGuarantorForPatientById/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientGuarantorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getGuarantorsByPatientId(patientId: number): Observable<GuarantorNameResponse[]> {
		const endpointUrl = `/pms2/ws/patient/getGuarantorsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, GuarantorNameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientAccount(patientId: number): Observable<PatientAccountSummaryResponse> {
		const endpointUrl = `/pms2/ws/patient/getPatientAccount/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientAccountSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientById(patientId: number): Observable<PatientResponse> {
		const endpointUrl = `/pms2/ws/patient/getPatientById/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientDashboardSummaryById(patientId: number): Observable<PatientDashboardSummaryResponse> {
		const endpointUrl = `/pms2/ws/patient/getPatientDashboardSummaryById/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientDashboardSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientForPopoverById(patientId: number): Observable<PatientPopoverResponse> {
		const endpointUrl = `/pms2/ws/patient/getPatientForPopoverById/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPopoverResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientFullSsnById(patientId: number): Observable<FullSsnResponse> {
		const endpointUrl = `/pms2/ws/patient/getPatientFullSsnById/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FullSsnResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientNameById(patientId: number): Observable<PatientNameResponse> {
		const endpointUrl = `/pms2/ws/patient/getPatientNameById/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientNameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientPersonId(patientId: number): Observable<PersonNameResponse> {
		const endpointUrl = `/pms2/ws/patient/getPatientPersonId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonNameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientQuickViewById(patientId: number): Observable<PatientQuickViewResponse> {
		const endpointUrl = `/pms2/ws/patient/getPatientQuickViewById/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientQuickViewResponse, null, null );
		
		return configuredEndpoint;
	}

	
	movePatientFile(request: MovePatientFileRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/movePatientFile`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, MovePatientFileRequest, request );
		
		return configuredEndpoint;
	}

	
	patientHasAccessToMessages(patientId: number): Observable<boolean> {
		const endpointUrl = `/pms2/ws/patient/patientHasAccessToMessages/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, null, null );
		
		return configuredEndpoint;
	}

	
	quickAddPatient(request: QuickAddPatientRequest ): Observable<PatientNameResponse> {
		const endpointUrl = `/pms2/ws/patient/quickAddPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientNameResponse, QuickAddPatientRequest, request );
		
		return configuredEndpoint;
	}

	
	reactivatePatientCellPhoneIfExists(patientId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/reactivatePatientCellPhoneIfExists/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	updatePatient(request: UpdatePatientRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/updatePatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePatientComment(request: UpdatePatientCommentRequest ): Observable<PatientCommentResponse> {
		const endpointUrl = `/pms2/ws/patient/updatePatientComment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientCommentResponse, UpdatePatientCommentRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePatientFile(request: UpdatePatientFileRequest ): Observable<PatientFileListResponse> {
		const endpointUrl = `/pms2/ws/patient/updatePatientFile`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientFileListResponse, UpdatePatientFileRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePatientQuickView(request: UpdatePatientQuickViewRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/updatePatientQuickView`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientQuickViewRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.accounting.PatientAccountController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientAccountGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	creditPatient(request: CreatePatientCreditRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/accounting/patientAccount/creditPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePatientCreditRequest, request );
		
		return configuredEndpoint;
	}

	
	findPayerStatementsByPersonId(personId: number): Observable<StatementPayerResponse[]> {
		const endpointUrl = `/pms2/ws/patient/accounting/patientAccount/findPayerStatementsByPersonId/${personId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, StatementPayerResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPaymentsByPatient(patientId: number): Observable<PatientPaymentResponse[]> {
		const endpointUrl = `/pms2/ws/patient/accounting/patientAccount/findPaymentsByPatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPaymentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientAccountBalances(patientId: number): Observable<PatientAccountBalancesResponse> {
		const endpointUrl = `/pms2/ws/patient/accounting/patientAccount/getPatientAccountBalances/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientAccountBalancesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	refundPatient(request: CreateRefundPatientRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/accounting/patientAccount/refundPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateRefundPatientRequest, request );
		
		return configuredEndpoint;
	}

	
	transferPatientCredit(request: TransferPatientCreditRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/accounting/patientAccount/transferPatientCredit`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, TransferPatientCreditRequest, request );
		
		return configuredEndpoint;
	}

	
	voidPatientCredit(request: VoidPatientCreditRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/accounting/patientAccount/voidPatientCredit`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, VoidPatientCreditRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.alert.PatientAlertController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientAlertGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateAlertById(alertId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/alert/activateAlertById/${alertId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createAlert(request: CreateAlertRequest ): Observable<AlertResponse> {
		const endpointUrl = `/pms2/ws/patient/alert/createAlert`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AlertResponse, CreateAlertRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateAlertById(alertId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/alert/deactivateAlertById/${alertId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	getAlertById(alertId: number): Observable<AlertResponse> {
		const endpointUrl = `/pms2/ws/patient/alert/getAlertById/${alertId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AlertResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateAlert(request: UpdateAlertRequest ): Observable<AlertResponse> {
		const endpointUrl = `/pms2/ws/patient/alert/updateAlert`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AlertResponse, UpdateAlertRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.amendment.PatientAmendmentController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientAmendmentGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createAmendment(request: CreateAmendmentRequest ): Observable<AmendmentResponse> {
		const endpointUrl = `/pms2/ws/patient/amendment/createAmendment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AmendmentResponse, CreateAmendmentRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteAmendmentById(amendmentId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/amendment/deleteAmendmentById/${amendmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findAmendmentsByPatientId(patientId: number): Observable<AmendmentListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/amendment/findAmendmentsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AmendmentListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getAmendmentById(amendmentId: number): Observable<AmendmentResponse> {
		const endpointUrl = `/pms2/ws/patient/amendment/getAmendmentById/${amendmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AmendmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateAmendment(request: UpdateAmendmentRequest ): Observable<AmendmentResponse> {
		const endpointUrl = `/pms2/ws/patient/amendment/updateAmendment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AmendmentResponse, UpdateAmendmentRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.consent.PatientConsentController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientConsentGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createPatientConsent(request: CreatePatientConsentRequest ): Observable<PatientConsentResponse> {
		const endpointUrl = `/pms2/ws/encounter/patientConsent/createPatientConsent`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientConsentResponse, CreatePatientConsentRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePatientConsent(patientConsentId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/patientConsent/deactivatePatientConsent/${patientConsentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePracticeConsentForms(): Observable<PracticeConsentFormResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/patientConsent/findActivePracticeConsentForms`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeConsentFormResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findAllConsentsByPatientId(patientId: number): Observable<PatientConsentResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/patientConsent/findAllPatientConsentsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientConsentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientConsent(consentId: number): Observable<PatientConsentResponse> {
		const endpointUrl = `/pms2/ws/encounter/patientConsent/getPatientConsent/${consentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientConsentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePatientConsent(request: UpdatePatientConsentRequest ): Observable<PatientConsentResponse> {
		const endpointUrl = `/pms2/ws/encounter/patientConsent/updatePatientConsent`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientConsentResponse, UpdatePatientConsentRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.contact.PatientContactController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientContactGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createContact(request: CreateContactRequest ): Observable<ContactResponse> {
		const endpointUrl = `/pms2/ws/patient/contact/createContact`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactResponse, CreateContactRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteContact(request: DeleteContactRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/contact/deleteContact`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeleteContactRequest, request );
		
		return configuredEndpoint;
	}

	
	findContactListByPatientId(patientId: number): Observable<ContactListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/contact/findContactListByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findContactsByPatientId(patientId: number): Observable<ContactPersonNameResponse[]> {
		const endpointUrl = `/pms2/ws/patient/contact/findContactsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactPersonNameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getContactById(contactId: number): Observable<ContactResponse> {
		const endpointUrl = `/pms2/ws/patient/contact/getContactById/${contactId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getContactByWithEmergencyContact(request: PatientContactEmergencyContactStatusRequest ): Observable<ContactWithEmergencyContactResponse> {
		const endpointUrl = `/pms2/ws/patient/contact/getContactByWithEmergencyContact`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactWithEmergencyContactResponse, PatientContactEmergencyContactStatusRequest, request );
		
		return configuredEndpoint;
	}

	
	getPatientContactInformationByPatientId(patientId: number): Observable<PatientContactInformationResponse> {
		const endpointUrl = `/pms2/ws/patient/contact/getPatientContactInformationByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientContactInformationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	quickAddContact(request: QuickAddContactRequest ): Observable<PersonNameResponse> {
		const endpointUrl = `/pms2/ws/patient/contact/quickAddContact`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonNameResponse, QuickAddContactRequest, request );
		
		return configuredEndpoint;
	}

	
	updateContact(request: UpdateContactRequest ): Observable<ContactResponse> {
		const endpointUrl = `/pms2/ws/patient/contact/updateContact`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactResponse, UpdateContactRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.accounting.PatientCreditHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientCreditHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findCreditsForPatient(patientId: number): Observable<CreditPatientResponse[]> {
		const endpointUrl = `/pms2/ws/accounting/patientCreditHistory/findCreditsForPatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CreditPatientResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.document.PatientDocumentController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientDocumentGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getPatientDocument(patientDocumentId: number): Observable<PatientDocumentResponse> {
		const endpointUrl = `/pms2/ws/patientDocument/getPatientDocument/${patientDocumentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientDocumentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePatientDocumentContent(request: UpdatePatientDocumentContentRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patientDocument/updatePatientDocumentContent`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientDocumentContentRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.externaldata.PatientExternalDataController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientExternalDataGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createExternalData(request: CreateExternalDataRequest ): Observable<ExternalDataResponse> {
		const endpointUrl = `/pms2/ws/patient/externalData/createExternalData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalDataResponse, CreateExternalDataRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteExternalDataById(externalDataId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/externalData/deleteExternalDataById/${externalDataId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findExternalDataByPatientId(patientId: number): Observable<ExternalDataListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/externalData/findExternalDataByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalDataListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getExternalDataById(externalDataId: number): Observable<ExternalDataResponse> {
		const endpointUrl = `/pms2/ws/patient/externalData/getExternalDataById/${externalDataId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalDataResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateExternalData(request: UpdateExternalDataRequest ): Observable<ExternalDataResponse> {
		const endpointUrl = `/pms2/ws/patient/externalData/updateExternalData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalDataResponse, UpdateExternalDataRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.provider.PatientExternalProviderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientExternalProviderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addProviderInsuranceIdentifier(request: CreateProviderInsuranceIdentifierRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/patient/provider/externalProvider/addProviderInsuranceIdentifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, CreateProviderInsuranceIdentifierRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['providers']})
	createExternalProvider(request: CreateExternalProviderRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/patient/provider/externalProvider/createExternalProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, CreateExternalProviderRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteProviderInsuranceIdentifier(request: DeleteProviderInsuranceIdentifierRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/patient/provider/externalProvider/deleteProviderInsuranceIdentifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, DeleteProviderInsuranceIdentifierRequest, request );
		
		return configuredEndpoint;
	}

	
	findExternalProviders(): Observable<ExternalProviderListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/provider/externalProvider/findExternalProviders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeInsuranceCompanies(): Observable<PracticeInsuranceCompanySummaryResponse[]> {
		const endpointUrl = `/pms2/ws/patient/provider/externalProvider/findPracticeInsuranceCompanies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeInsuranceCompanySummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getProviderById(providerId: number): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/patient/provider/externalProvider/getProviderById/${providerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['providers']})
	updateExternalProvider(request: UpdateExternalProviderRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/patient/provider/externalProvider/updateExternalProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, UpdateExternalProviderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateProviderInsuranceIdentifier(request: UpdateProviderInsuranceIdentifierRequest ): Observable<ExternalProviderResponse> {
		const endpointUrl = `/pms2/ws/patient/provider/externalProvider/updateProviderInsuranceIdentifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderResponse, UpdateProviderInsuranceIdentifierRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.family.PatientFamilyController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientFamilyGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addFamilyMemberWithEmergencyContactToFamilyAccount(request: AddFamilyMemberWithEmergencyContactRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/family/addFamilyMemberWithEmergencyContactToFamilyAccount`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddFamilyMemberWithEmergencyContactRequest, request );
		
		return configuredEndpoint;
	}

	
	addPatientToFamilyAccount(request: AddFamilyMemberRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/family/addPatientToFamilyAccount`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddFamilyMemberRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteFamilyAccountMemberById(familyAccountMemberId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/family/deleteFamilyAccountMemberById/${familyAccountMemberId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deletePatientFromFamilyAccount(patientId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/family/deletePatientFromFamilyAccount/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	getFamilyAccountMemberDetailsByPatientId(patientId: number): Observable<FamilyAccountMembersDetailResponse> {
		const endpointUrl = `/pms2/ws/patient/family/getFamilyAccountMemberDetailsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FamilyAccountMembersDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getFamilyMembersByPatientId(patientId: number): Observable<PatientNameResponse[]> {
		const endpointUrl = `/pms2/ws/patient/family/getFamilyMembersByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientNameResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientFamilyInformationByPatientId(patientId: number): Observable<PatientFamilyInformationResponse> {
		const endpointUrl = `/pms2/ws/patient/family/getPatientFamilyInformationByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientFamilyInformationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	quickAddFamilyMember(request: QuickAddFamilyMemberRequest ): Observable<PatientNameResponse> {
		const endpointUrl = `/pms2/ws/patient/family/quickAddFamilyMember`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientNameResponse, QuickAddFamilyMemberRequest, request );
		
		return configuredEndpoint;
	}

	
	updateFamilyAccountMember(request: UpdateFamilyAccountMemberRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/family/updateFamilyAccountMember`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateFamilyAccountMemberRequest, request );
		
		return configuredEndpoint;
	}

	
	updateFamilyAccountMemberWithEmergencyContact(request: UpdateFamilyAccountMemberWithEmergencyContactRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/family/updateFamilyAccountMemberWithEmergencyContact`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateFamilyAccountMemberWithEmergencyContactRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.file.PatientFileController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientFileGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	markPatientFileAsDeleted(patientFileId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patientFile/markPatientFileAsDeleted/${patientFileId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.healthconcern.PatientHealthConcernController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientHealthConcernGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePatientHealthConcern(patientHealthConcernId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/healthConcern/activatePatientHealthConcern/${patientHealthConcernId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createPatientHealthConcern(request: CreatePatientHealthConcernRequest ): Observable<PatientHealthConcernResponse> {
		const endpointUrl = `/pms2/ws/patient/healthConcern/createPatientHealthConcern`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientHealthConcernResponse, CreatePatientHealthConcernRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePatientHealthConcern(patientHealthConcernId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/healthConcern/deactivatePatientHeathConcern/${patientHealthConcernId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientHealthConcernsByPatientId(patientId: number): Observable<PatientHealthConcernResponse[]> {
		const endpointUrl = `/pms2/ws/patient/healthConcern/findPatientHealthConcernsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientHealthConcernResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientHealthConcernById(patientHealthConcernId: number): Observable<PatientHealthConcernResponse> {
		const endpointUrl = `/pms2/ws/patient/healthConcern/getPatientHealthConcernById/${patientHealthConcernId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientHealthConcernResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePatientHealthConcern(request: UpdatePatientHealthConcernRequest ): Observable<PatientHealthConcernResponse> {
		const endpointUrl = `/pms2/ws/patient/healthConcern/updatePatientHealthConcern`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientHealthConcernResponse, UpdatePatientHealthConcernRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.immunization.PatientImmunizationController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientImmunizationGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateVaccination(vaccinationId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/immunization/activateVaccination/${vaccinationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createVaccination(request: CreatePatientVaccinationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/immunization/createVaccination`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePatientVaccinationRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateVaccination(vaccinationId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/immunization/deactivateVaccination/${vaccinationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveCvxCodes(): Observable<PatientCvxCodeResponse[]> {
		const endpointUrl = `/pms2/ws/patient/immunization/findActiveCvxCodes`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientCvxCodeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveVaccineManufacturers(): Observable<PatientVaccineManufacturerResponse[]> {
		const endpointUrl = `/pms2/ws/patient/immunization/findActiveVaccineManufacturers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientVaccineManufacturerResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findHl7ValueSetsByOid(request: PatientHl7ValueSetSearchRequest ): Observable<PatientHl7ValueSetResponse[]> {
		const endpointUrl = `/pms2/ws/patient/immunization/findHl7ValueSetsByOid`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientHl7ValueSetResponse, PatientHl7ValueSetSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findVaccinationsByPatientId(patientId: number): Observable<PatientVaccinationListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/immunization/findVaccinationsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientVaccinationListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getVaccinationById(vaccinationId: number): Observable<PatientVaccinationResponse> {
		const endpointUrl = `/pms2/ws/patient/immunization/getVaccinationById/${vaccinationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientVaccinationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateVaccination(request: UpdatePatientVaccinationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/immunization/updateVaccination`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientVaccinationRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.implantabledevice.PatientImplantableDeviceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientImplantableDeviceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateImplantableDeviceById(implantableDeviceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/implantableDevice/activateImplantableDeviceById/${implantableDeviceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createImplantableDevice(request: CreatePatientImplantableDeviceRequest ): Observable<PatientImplantableDeviceResponse> {
		const endpointUrl = `/pms2/ws/patient/implantableDevice/createImplantableDevice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientImplantableDeviceResponse, CreatePatientImplantableDeviceRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateImplantableDevice(implantableDeviceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/implantableDevice/deactivateImplantableDevice/${implantableDeviceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findImplantableDevicesByPatientId(patientId: number): Observable<PatientImplantableDeviceResponse[]> {
		const endpointUrl = `/pms2/ws/patient/implantableDevice/findImplantableDevicesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientImplantableDeviceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getImplantableDeviceById(implantableDeviceId: number): Observable<PatientImplantableDeviceResponse> {
		const endpointUrl = `/pms2/ws/patient/implantableDevice/getImplantableDeviceById/${implantableDeviceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientImplantableDeviceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateImplantableDevice(request: UpdatePatientImplantableDeviceRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/implantableDevice/updateImplantableDevice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientImplantableDeviceRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.accounting.PatientInvoiceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientInvoiceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findByPatientInvoiceDashboardSearch(request: PatientInvoiceDashboardSearchRequest ): Observable<InvoiceDashboardResponse[]> {
		const endpointUrl = `/pms2/ws/patient/accounting/patientInvoice/findByPatientInvoiceDashboardSearch`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceDashboardResponse, PatientInvoiceDashboardSearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.patientmanagement.PatientManagementController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientManagementGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	deceasePatient(request: DeceasePatientRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patientManagement/deceasePatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeceasePatientRequest, request );
		
		return configuredEndpoint;
	}

	
	exportPatientData(patientId: number): Observable<PatientDataExportResponse> {
		const endpointUrl = `/pms2/ws/patientManagement/exportPatientData/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientDataExportResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientsForManagement(request: PatientManagementSearchRequest ): Observable<PatientSearchResponse[]> {
		const endpointUrl = `/pms2/ws/patientManagement/findPatientsForManagement`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientSearchResponse, PatientManagementSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	mergePatients(request: MergePatientsRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patientManagement/mergePatients`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, MergePatientsRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.ongoingcare.PatientOngoingCareController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientOngoingCareGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createOngoingCare(request: CreatePatientOngoingCareRequest ): Observable<PatientOngoingCareResponse> {
		const endpointUrl = `/pms2/ws/patient/ongoingcare/createPatientOngoingCare`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientOngoingCareResponse, CreatePatientOngoingCareRequest, request );
		
		return configuredEndpoint;
	}

	
	createTargetIop(request: CreatePatientTargetIopRequest ): Observable<PatientTargetIopResponse> {
		const endpointUrl = `/pms2/ws/patient/ongoingcare/createPatientTargetIop`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientTargetIopResponse, CreatePatientTargetIopRequest, request );
		
		return configuredEndpoint;
	}

	
	findPatientOngoingCareByPatientId(patientId: number): Observable<PatientOngoingCareResponse> {
		const endpointUrl = `/pms2/ws/patient/ongoingcare/findPatientOngoingCareByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientOngoingCareResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchGlaucomaCare(patientId: number): Observable<GlaucomaCareResponse> {
		const endpointUrl = `/pms2/ws/patient/ongoingcare/searchGlaucomaCare/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, GlaucomaCareResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchRefractionTests(request: SearchRefractionTestsRequest ): Observable<RefractiveCareResponse[]> {
		const endpointUrl = `/pms2/ws/patient/ongoingcare/searchRefractionTests`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RefractiveCareResponse, SearchRefractionTestsRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePatientOngoingCare(request: UpdatePatientOngoingCareRequest ): Observable<PatientOngoingCareResponse> {
		const endpointUrl = `/pms2/ws/patient/ongoingcare/updatePatientOngoingCare`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientOngoingCareResponse, UpdatePatientOngoingCareRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.performedservice.PatientPerformedServiceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientPerformedServiceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findPerformedServicesByPatientId(patientId: number): Observable<PatientPerformedServiceResponse[]> {
		const endpointUrl = `/pms2/ws/patientPerformedService/findPerformedServicesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPerformedServiceResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.pharmacy.PatientPharmacyController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientPharmacyGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePatientPharmacy(personPharmacyId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/activatePatientPharmacy/${personPharmacyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createPatientPharmacy(request: CreatePatientPharmacyRequest ): Observable<PatientPharmacyResponse> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/createPatientPharmacy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPharmacyResponse, CreatePatientPharmacyRequest, request );
		
		return configuredEndpoint;
	}

	
	createPharmacy(request: CreatePharmacyRequest ): Observable<PharmacyResponse> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/createPharmacy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PharmacyResponse, CreatePharmacyRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePatientPharmacy(personPharmacyId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/deactivatePatientPharmacy/${personPharmacyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findAllPharmacies(): Observable<PharmacySummaryResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/findAllPharmacies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PharmacySummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientPharmacies(patientId: number): Observable<PatientPharmacyResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/findPatientPharmacies/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPharmacyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPharmacyBrands(): Observable<PharmacyBrandResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/findPharmacyBrands`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PharmacyBrandResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientPharmacyById(personPharmacyId: number): Observable<PatientPharmacyResponse> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/getPatientPharmacyById/${personPharmacyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPharmacyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePharmacy(request: UpdatePharmacyRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/pharmacy/updatePharmacy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePharmacyRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.pod.PatientPodController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientPodGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findActiveMasterDiagnosesByPatientId(patientId: number): Observable<PatientPodPersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findActiveMasterDiagnosesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodPersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveNotesByPatientId(patientId: number): Observable<PatientPodNotesResponse> {
		const endpointUrl = `/pms2/ws/patient/pod/findActiveNotesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodNotesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePatientInsurancesByPatientId(patientId: number): Observable<PatientPodPersonInsuranceResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findActivePatientInsurancesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodPersonInsuranceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveRecallsByPatientId(patientId: number): Observable<PatientPodRecallResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findActiveRecallsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodRecallResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveUnassignedTasksByPatientId(patientId: number): Observable<PatientPodTaskResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findActiveUnassignedTasksByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodTaskResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findContactLensPrescriptionsAndTrialsByPatientId(patientId: number): Observable<PatientPodContactLensResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findContactLensPrescriptionsAndTrialsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodContactLensResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findContactsByPatientId(patientId: number): Observable<PatientPodContactResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findContactsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodContactResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findEyeglassPrescriptionsByPatientId(patientId: number): Observable<PatientPodEyeglassResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findEyeglassPrescriptionsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodEyeglassResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFamilyAccountMembersByPatientId(patientId: number): Observable<PatientPodFamilyMembersResponse> {
		const endpointUrl = `/pms2/ws/patient/pod/findFamilyAccountMembersByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodFamilyMembersResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findLastAndNextEncounterByPatientId(patientId: number): Observable<PatientPodLastAndNextEncounterResponse> {
		const endpointUrl = `/pms2/ws/patient/pod/findLastAndNextEncounterByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodLastAndNextEncounterResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMedicationPrescriptionsByPatientId(patientId: number): Observable<PatientPodMedicationResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findMedicationPrescriptionsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodMedicationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findNewAndCompletedLabOrdersByPatientId(patientId: number): Observable<PatientPodLabOrderResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findNewAndCompletedLabOrdersByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodLabOrderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findNonCanceledEncountersByPatientId(patientId: number): Observable<PatientPodEncounterResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findNonCanceledEncountersByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodEncounterResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findNonCanceledOrdersByPatientId(patientId: number): Observable<PatientPodOrderResponse[]> {
		const endpointUrl = `/pms2/ws/patient/pod/findNonCanceledOrdersByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodOrderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findRecentConnectMessagesByPatientId(patientId: number): Observable<PatientPodConnectMessagesResponse> {
		const endpointUrl = `/pms2/ws/patient/pod/findRecentConnectMessagesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodConnectMessagesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getAccountBalancesAndCreditsByPatientId(patientId: number): Observable<PatientPodPatientAccountResponse> {
		const endpointUrl = `/pms2/ws/patient/pod/getAccountBalancesAndCreditsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPodPatientAccountResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.preferences.PatientPreferencesController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientPreferencesGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findPatientSchedulingPreferencesByPatientId(patientId: number): Observable<PatientSchedulingPreferenceResponse> {
		const endpointUrl = `/pms2/ws/patientPreferences/findPatientSchedulingPreferencesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientSchedulingPreferenceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateSchedulingPreference(request: UpdateSchedulingPreferenceRequest ): Observable<PatientSchedulingPreferenceResponse> {
		const endpointUrl = `/pms2/ws/patientPreferences/updateSchedulingPreference`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientSchedulingPreferenceResponse, UpdateSchedulingPreferenceRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.provider.PatientProviderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientProviderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createPatientProvider(request: CreatePatientProviderRequest ): Observable<PatientProviderResponse> {
		const endpointUrl = `/pms2/ws/patient/providers/createPatientProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientProviderResponse, CreatePatientProviderRequest, request );
		
		return configuredEndpoint;
	}

	
	deletePatientProvider(personProviderId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/providers/deletePatientProvider/${personProviderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientProviders(patientId: number): Observable<PatientProviderResponse[]> {
		const endpointUrl = `/pms2/ws/patient/providers/findPatientProviders/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientProviderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePatientProvider(request: UpdatePatientProviderRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/providers/updatePatientProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientProviderRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.recalls.PatientRecallController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientRecallGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createPatientRecall(request: CreatePatientRecallRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/patientRecalls/createPatientRecall`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePatientRecallRequest, request );
		
		return configuredEndpoint;
	}

	
	deletePatientRecall(patientRecallId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/patientRecalls/deletePatientRecall/${patientRecallId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientRecalls(patientId: number): Observable<PatientRecallResponse[]> {
		const endpointUrl = `/pms2/ws/patient/patientRecalls/findPatientRecalls/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientRecallResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPatientRecall(recallId: number): Observable<PatientRecallDetailResponse> {
		const endpointUrl = `/pms2/ws/patient/patientRecalls/getPatientRecall/${recallId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientRecallDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	isPatientEmailCommunicationEligible(patientId: number): Observable<PatientCommunicationEligibilityResponse> {
		const endpointUrl = `/pms2/ws/patient/patientRecalls/isPatientEmailCommunicationEligible/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientCommunicationEligibilityResponse, null, null );
		
		return configuredEndpoint;
	}

	
	isPatientTextCommunicationEligible(patientId: number): Observable<PatientCommunicationEligibilityResponse> {
		const endpointUrl = `/pms2/ws/patient/patientRecalls/isPatientTextCommunicationEligible/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientCommunicationEligibilityResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePatientRecall(request: UpdatePatientRecallRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/patientRecalls/updatePatientRecall`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePatientRecallRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.accounting.PatientRefundHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientRefundHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findPatientRefunds(patientId: number): Observable<RefundPatientResponse[]> {
		const endpointUrl = `/pms2/ws/PatientRefundHistory/findPatientRefunds/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RefundPatientResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.reporting.patient.PatientReportingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientReportingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	patientMinerSearch(request: PatientMinerSearchRequest ): Observable<PatientMinerSearchResponse[]> {
		const endpointUrl = `/pms2/ws/patientReporting/patientMinerSearch`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientMinerSearchResponse, PatientMinerSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	searchBirthdays(request: PatientBirthdaySearchRequest ): Observable<PatientBirthdaySearchResponse[]> {
		const endpointUrl = `/pms2/ws/patientReporting/searchBirthdays`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientBirthdaySearchResponse, PatientBirthdaySearchRequest, request );
		
		return configuredEndpoint;
	}

	
	searchPatientRecalls(request: PatientRecallSearchRequest ): Observable<PatientRecallSearchResponse[]> {
		const endpointUrl = `/pms2/ws/patientReporting/searchPatientRecalls`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientRecallSearchResponse, PatientRecallSearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.task.PatientTaskController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientTaskGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findByPatientId(patientId: number): Observable<PatientTaskResponse[]> {
		const endpointUrl = `/pms2/ws/patient/task/findByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientTaskResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getTaskById(taskId: number): Observable<PatientTaskResponse> {
		const endpointUrl = `/pms2/ws/patient/task/getTaskById/${taskId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientTaskResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.encounter.workflow.PatientWorkflowController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientWorkflowGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addWorkflowScreenToWorkflowStep(request: PatientAddWorkflowScreenToWorkflowStepRequest ): Observable<PatientWorkflowStepMenuResponse> {
		const endpointUrl = `/pms2/ws/patient/encounter/workflow/addWorkflowScreenToWorkflowStep`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientWorkflowStepMenuResponse, PatientAddWorkflowScreenToWorkflowStepRequest, request );
		
		return configuredEndpoint;
	}

	
	findScreenTemplatesByCategoryId(categoryId: number): Observable<PatientWorkflowScreenTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/encounter/workflow/findScreenTemplatesByCategoryId/${categoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientWorkflowScreenTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findTestTemplatesByCategoryId(categoryId: number): Observable<PatientWorkflowTestTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/patient/encounter/workflow/findTestTemplatesByCategoryId/${categoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientWorkflowTestTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findWorkflowMenuByEncounterId(encounterId: number): Observable<PatientWorkflowMenuResponse> {
		const endpointUrl = `/pms2/ws/patient/encounter/workflow/findWorkflowMenuByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientWorkflowMenuResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.encounter.workflow.device.PatientWorkflowDeviceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PatientWorkflowDeviceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findPracticeLocationDevices(): Observable<PracticeLocationDeviceResponse[]> {
		const endpointUrl = `/pms2/ws/patient/encounter/workflow/device/findPracticeLocationDevices`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationDeviceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	previewWorkflowFromDevice(request: PreviewMappedTestsRequest ): Observable<DynamicTestResponse[]> {
		const endpointUrl = `/pms2/ws/patient/encounter/workflow/device/previewWorkflowFromDevice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DynamicTestResponse, PreviewMappedTestsRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.performedservice.PerformedServiceController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PerformedServiceServiceGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addDiagnosesToPerformedServices(request: AddDiagnosesToPerformedServicesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/performedService/addDiagnosesToPerformedServices`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddDiagnosesToPerformedServicesRequest, request );
		
		return configuredEndpoint;
	}

	
	addPerformedServices(request: AddPerformedServicesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/performedService/addPerformedServices`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddPerformedServicesRequest, request );
		
		return configuredEndpoint;
	}

	
	deletePerformedService(performedServiceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/performedService/deletePerformedService/${performedServiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findCommonServices(practiceLocationId: number): Observable<ServiceSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/performedService/findCommonServices/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ServiceSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPerformedServicesByEncounterId(encounterId: number): Observable<PerformedServiceResponse[]> {
		const endpointUrl = `/pms2/ws/performedService/findPerformedServicesByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PerformedServiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateDiagnosesOnPerformedService(request: UpdateDiagnosesOnPerformedServiceRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/performedService/updateDiagnosesOnPerformedService`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateDiagnosesOnPerformedServiceRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.shared.person.PersonDetailsController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PersonDetailsGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getPersonNameByPersonId(personId: number): Observable<PersonNameResponse> {
		const endpointUrl = `/pms2/ws/shared/person/personDetails/getPersonNameByPersonId/${personId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonNameResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.person.diagnosis.PersonDiagnosisController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PersonDiagnosisGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createDiagnosisReview(request: CreateDiagnosisReviewHistoryRequest ): Observable<DiagnosisReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/createDiagnosisReview`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DiagnosisReviewHistoryResponse, CreateDiagnosisReviewHistoryRequest, request );
		
		return configuredEndpoint;
	}

	
	createPersonDiagnosis(request: CreatePersonDiagnosisRequest ): Observable<PersonDiagnosisResponse> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/createPersonDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, CreatePersonDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveMasterDiagnosesByPatientId(patientId: number): Observable<PersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/findActiveMasterDiagnosesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActivePracticeDiagnoses(request: FindPracticeDiagnosesRequest ): Observable<PracticeDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/findActivePracticeDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeDiagnosisResponse, FindPracticeDiagnosesRequest, request );
		
		return configuredEndpoint;
	}

	
	findMasterDiagnosesByPatientId(patientId: number): Observable<PersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/findMasterDiagnosesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findMostRecentDiagnosisReviewByPatientId(patientId: number): Observable<DiagnosisReviewHistoryResponse> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/findMostRecentDiagnosisReviewByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DiagnosisReviewHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPersonDiagnosesByEncounterId(encounterId: number): Observable<PersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/findPersonDiagnosesByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPersonDiagnosesByPatientId(patientId: number): Observable<PersonDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/findPersonDiagnosesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPersonDiagnosis(personDiagnosisId: number): Observable<PersonDiagnosisResponse> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/getPersonDiagnosis/${personDiagnosisId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, null, null );
		
		return configuredEndpoint;
	}

	
	resolvePersonDiagnosis(request: ResolvePersonDiagnosisRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/resolvePersonDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, ResolvePersonDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	searchActivePracticeDiagnoses(request: SearchPracticeDiagnosesRequest ): Observable<PracticeDiagnosisResponse[]> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/searchActivePracticeDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeDiagnosisResponse, SearchPracticeDiagnosesRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePersonDiagnosis(request: UpdatePersonDiagnosisRequest ): Observable<PersonDiagnosisResponse> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/updatePersonDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, UpdatePersonDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePersonDiagnosisLocationAndQualifier(request: UpdatePersonDiagnosisLocationAndQualifierRequest ): Observable<PersonDiagnosisResponse> {
		const endpointUrl = `/pms2/ws/patient/person/diagnosis/updatePersonDiagnosisLocationAndQualifier`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisResponse, UpdatePersonDiagnosisLocationAndQualifierRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.shared.person.PersonGeneralHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PersonGeneralHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findPersonGeneralHistoryByPatientId(patientId: number): Observable<PersonGeneralHistoryResponse> {
		const endpointUrl = `/pms2/ws/shared/person/personGeneralHistory/findPersonGeneralHistoryByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonGeneralHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateInsurance(request: UpdatePersonGeneralHistoryRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/shared/person/personGeneralHistory/updateInsurance`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePersonGeneralHistoryRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.plan.PlanController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PlanGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addAdhocCarePlanItemToPersonDiagnosis(request: EncounterCreateCarePlanItemForPersonDiagnosisRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/addAdhocCarePlanItemToPersonDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterCreateCarePlanItemForPersonDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	addCarePlanTemplateToPersonDiagnoses(request: EncounterAddCarePlanTemplateToPersonDiagnosesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/addCarePlanTemplateToPersonDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterAddCarePlanTemplateToPersonDiagnosesRequest, request );
		
		return configuredEndpoint;
	}

	
	addHistoricCarePlanItemsToPersonDiagnosis(request: EncounterAddCarePlanItemsToPersonDiagnosisRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/addHistoricCarePlanItemsToPersonDiagnosis`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterAddCarePlanItemsToPersonDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	createCarePlanItemsForPersonDiagnoses(request: EncounterCreateCarePlanItemsForPersonDiagnosesRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/createCarePlanItemsForPersonDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterCreateCarePlanItemsForPersonDiagnosesRequest, request );
		
		return configuredEndpoint;
	}

	
	findCarePlanItemTemplates(): Observable<CarePlanItemTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/plan/findCarePlanItemTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanItemTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findCarePlanTemplates(): Observable<CarePlanTemplateListResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/plan/findCarePlanTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CarePlanTemplateListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPersonDiagnosesWithCarePlans(encounterId: number): Observable<PersonDiagnosisWithCarePlansResponse[]> {
		const endpointUrl = `/pms2/ws/encounter/plan/findPersonDiagnosesWithCarePlansByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonDiagnosisWithCarePlansResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getCarePlanItemEducationById(carePlanItemId: number): Observable<EncounterCarePlanItemEducationResponse> {
		const endpointUrl = `/pms2/ws/encounter/plan/getCarePlanItemEducationById/${carePlanItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterCarePlanItemEducationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getCarePlanItemGeneralById(carePlanItemId: number): Observable<EncounterCarePlanItemGeneralResponse> {
		const endpointUrl = `/pms2/ws/encounter/plan/getCarePlanItemGeneralById/${carePlanItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EncounterCarePlanItemGeneralResponse, null, null );
		
		return configuredEndpoint;
	}

	
	removeCarePlanItemFromPersonDiagnosisById(carePlanItemId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/removeCarePlanItemFromPersonDiagnosisById/${carePlanItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	stopCarePlanItem(request: EncounterStopCarePlanItemForPersonDiagnosisRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/stopCarePlanItem`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterStopCarePlanItemForPersonDiagnosisRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCarePlanItemEducation(request: EncounterUpdateCarePlanItemEducationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/updateCarePlanItemEducation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterUpdateCarePlanItemEducationRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCarePlanItemGeneral(request: EncounterUpdateCarePlanItemGeneralRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/updateCarePlanItemGeneral`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterUpdateCarePlanItemGeneralRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCarePlanItemRecall(request: EncounterUpdateCarePlanItemRecallRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/plan/updateCarePlanItemRecall`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, EncounterUpdateCarePlanItemRecallRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.pqri.PqriController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PqriGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	addPqriAlertsToEncounter(request: AddPqriAlertsToEncounterRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/pqri/addPqriAlertsToEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddPqriAlertsToEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	getPqriAlerts(encounterId: number): Observable<PqriAlertResponse[]> {
		const endpointUrl = `/pms2/ws/pqri/getPqriAlerts/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PqriAlertResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.practice.PracticeController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PracticeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	findPracticeAndLocationIdentifiers(): Observable<PracticeAndLocationsIdentifiersResponse> {
		const endpointUrl = `/pms2/ws/practice/findPracticeAndLocationIdentifiers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeAndLocationsIdentifiersResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeUsers(): Observable<UserSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/practice/findPracticeUsers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UserSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findSecurityRoles(cacheOptions?: GandalfCacheableConfigurableOptions<SecurityRoleResponse[]>): Observable<SecurityRoleResponse[]> {
		const endpointUrl = `/pms2/ws/practice/findSecurityRoles`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SecurityRoleResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['security_roles'], cacheableOptions);
	}

	
	getPracticeIdentifiers(): Observable<PracticeIdentifiersResponse> {
		const endpointUrl = `/pms2/ws/practice/getPracticeIdentifiers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeIdentifiersResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.practice.PracticeFileController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PracticeFileGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	deletePracticeFile(practiceFileId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/practiceFile/deletePracticeFile/${practiceFileId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeFiles(request: PracticeFileRequest ): Observable<PracticeFileResponse[]> {
		const endpointUrl = `/pms2/ws/practiceFile/getPracticeFiles`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeFileResponse, PracticeFileRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePracticeFile(request: UpdatePracticeFileRequest ): Observable<PracticeFileResponse> {
		const endpointUrl = `/pms2/ws/practiceFile/updatePracticeFile`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeFileResponse, UpdatePracticeFileRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.inventory.item.PracticeLocationLabelPrinterController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PracticeLocationLabelPrinterGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findDefaultPrinterByPracticeLocationId(practiceLocationId: number): Observable<PracticeLocationDefaultLabelPrinterResponse> {
		const endpointUrl = `/pms2/ws/inventory/item/practiceLocationLabelPrinter/findDefaultPrinterByPracticeLocationId/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationDefaultLabelPrinterResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.dataconfiguration.accounting.PracticeRemittanceCodeAdjustmentController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PracticeRemittanceCodeAdjustmentGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePracticeRemittanceCodeAdjustment(practiceRemittanceCodeAdjustmentId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/practiceRemittanceCodeAdjustment/activatePracticeRemittanceCodeAdjustment/${practiceRemittanceCodeAdjustmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createPracticeRemittanceCodeAdjustment(request: CreatePracticeRemittanceCodeAdjustmentRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/practiceRemittanceCodeAdjustment/createPracticeRemittanceCodeAdjustment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePracticeRemittanceCodeAdjustmentRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePracticeRemittanceCodeAdjustment(practiceRemittanceCodeAdjustmentId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/practiceRemittanceCodeAdjustment/deactivatePracticeRemittanceCodeAdjustment/${practiceRemittanceCodeAdjustmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findAllRemittanceCodeAdjustment(): Observable<PracticeRemittanceCodeAdjustmentResponse[]> {
		const endpointUrl = `/pms2/ws/practiceRemittanceCodeAdjustment/findAllRemittanceCodeAdjustment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeRemittanceCodeAdjustmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getRemittanceCodeAdjustmentById(practiceRemittanceCodeAdjustmentId: number): Observable<PracticeRemittanceCodeAdjustmentResponse> {
		const endpointUrl = `/pms2/ws/practiceRemittanceCodeAdjustment/getRemittanceCodeAdjustmentById/${practiceRemittanceCodeAdjustmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeRemittanceCodeAdjustmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePracticeRemittanceCodeAdjustment(request: UpdatePracticeRemittanceCodeAdjustmentRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/practiceRemittanceCodeAdjustment/updatePracticeRemittanceCodeAdjustment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePracticeRemittanceCodeAdjustmentRequest, request );
		
		return configuredEndpoint;
	}

	
	validatePracticeRemittanceCodeAdjustmentUniqueX12Code(request: ValidatePracticeRemittanceX12CodeRequest ): Observable<boolean> {
		const endpointUrl = `/pms2/ws/practiceRemittanceCodeAdjustment/validatePracticeRemittanceCodeAdjustmentUniqueX12Code`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Boolean, ValidatePracticeRemittanceX12CodeRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.smartapplication.LaunchSmartApplicationController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PracticeSmartApplicationGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findEnabledSmartApplications(): Observable<PracticeSmartApplicationResponse[]> {
		const endpointUrl = `/pms2/ws/launchSmartApplication/findEnabledSmartApplications`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeSmartApplicationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findProviderDetails(): Observable<ProviderResponse> {
		const endpointUrl = `/pms2/ws/launchSmartApplication/findProviderDetails`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProviderResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPracticeLaunchUrl(request: GetPracticeLaunchUrlRequest ): Observable<PracticeLaunchUrlResponse> {
		const endpointUrl = `/pms2/ws/launchSmartApplication/getPracticeLaunchUrl`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLaunchUrlResponse, GetPracticeLaunchUrlRequest, request );
		
		return configuredEndpoint;
	}

	
	updateOrCreatePatientCcdaExport(patientId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/launchSmartApplication/updateOrCreatePatientCcdaExport/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.history.presentillness.PresentIllnessController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class PresentIllnessGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createPresentIllness(request: CreatePresentIllnessRequest ): Observable<PresentIllnessResponse> {
		const endpointUrl = `/pms2/ws/history/presentIllness/createPresentIllness`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PresentIllnessResponse, CreatePresentIllnessRequest, request );
		
		return configuredEndpoint;
	}

	
	createPresentIllnessesFromDiagnoses(request: CreatePresentIllnessesFromDiagnosesRequest ): Observable<PresentIllnessResponse[]> {
		const endpointUrl = `/pms2/ws/history/presentIllness/createPresentIllnessesFromDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PresentIllnessResponse, CreatePresentIllnessesFromDiagnosesRequest, request );
		
		return configuredEndpoint;
	}

	
	deletePresentIllness(presentIllnessId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/history/presentIllness/deletePresentIllness/${presentIllnessId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findPresentIllnessScreenTemplates(): Observable<WorkflowScreenTemplatePresentIllnessResponse[]> {
		const endpointUrl = `/pms2/ws/history/presentIllness/findPresentIllnessScreenTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WorkflowScreenTemplatePresentIllnessResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPresentIllnessesByEncounterId(encounterId: number): Observable<PresentIllnessResponse[]> {
		const endpointUrl = `/pms2/ws/history/presentIllness/findPresentIllnessesByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PresentIllnessResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updatePresentIllness(request: UpdatePresentIllnessRequest ): Observable<PresentIllnessResponse> {
		const endpointUrl = `/pms2/ws/history/presentIllness/updatePresentIllness`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PresentIllnessResponse, UpdatePresentIllnessRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.encounter.timer.ProfessionalTimeController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ProfessionalTimeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	adjustProfessionalTime(request: CreateProfessionalTimeAdjustmentRequest ): Observable<ProfessionalTimeAdjustmentResponse> {
		const endpointUrl = `/pms2/ws/encounter/professionalTime/adjustProfessionalTime`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProfessionalTimeAdjustmentResponse, CreateProfessionalTimeAdjustmentRequest, request );
		
		return configuredEndpoint;
	}

	
	findByEncounterId(encounterId: number): Observable<ProfessionalTimeResponse> {
		const endpointUrl = `/pms2/ws/encounter/professionalTime/findByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProfessionalTimeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getProfessionalTimeById(professionalTimeId: number): Observable<ProfessionalTimeResponse> {
		const endpointUrl = `/pms2/ws/encounter/professionalTime/getProfessionalTimeById/${professionalTimeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProfessionalTimeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	pingTimer(professionalTimeSessionId: number): Observable<ProfessionalTimeSessionResponse> {
		const endpointUrl = `/pms2/ws/encounter/professionalTime/pingTimer/${professionalTimeSessionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProfessionalTimeSessionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	startTimer(encounterId: number): Observable<ProfessionalTimeSessionResponse> {
		const endpointUrl = `/pms2/ws/encounter/professionalTime/startTimer/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProfessionalTimeSessionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	stopTimer(professionalTimeSessionId: number): Observable<ProfessionalTimeSessionResponse> {
		const endpointUrl = `/pms2/ws/encounter/professionalTime/stopTimer/${professionalTimeSessionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProfessionalTimeSessionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	stopTimersForEncounter(encounterId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/encounter/professionalTime/stopTimersForEncounter/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.provider.ProviderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ProviderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	findAllActiveProviders(cacheOptions?: GandalfCacheableConfigurableOptions<ProviderResponse[]>): Observable<ProviderResponse[]> {
		const endpointUrl = `/pms2/ws/provider/findAllActiveProviders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProviderResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['providers'], cacheableOptions);
	}

	
	findExternalProvidersForDropdown(cacheOptions?: GandalfCacheableConfigurableOptions<ExternalProviderDropdownResponse[]>): Observable<ExternalProviderDropdownResponse[]> {
		const endpointUrl = `/pms2/ws/provider/findExternalProvidersForDropdown`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ExternalProviderDropdownResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['providers'], cacheableOptions);
	}

	
	findPracticeProviders(cacheOptions?: GandalfCacheableConfigurableOptions<ProviderResponse[]>): Observable<ProviderResponse[]> {
		const endpointUrl = `/pms2/ws/provider/findPracticeProviders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProviderResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['providers'], cacheableOptions);
	}

	
	findPracticeVisionProviders(cacheOptions?: GandalfCacheableConfigurableOptions<ProviderResponse[]>): Observable<ProviderResponse[]> {
		const endpointUrl = `/pms2/ws/provider/findPracticeVisionProviders`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProviderResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['providers'], cacheableOptions);
	}

}

/** See com.rev360.pms.api.controller.query.QueryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class QueryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createQuery(request: CreateQueryRequest ): Observable<QuerySummaryResponse> {
		const endpointUrl = `/pms2/ws/query/createQuery`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QuerySummaryResponse, CreateQueryRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteById(queryId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/query/deleteById/${queryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findByTemplateCategoryAndCustomReportsMasterCategory(request: FindQueriesRequest ): Observable<QuerySummaryResponse[]> {
		const endpointUrl = `/pms2/ws/query/findByTemplateCategoryAndCustomReportsMasterCategory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QuerySummaryResponse, FindQueriesRequest, request );
		
		return configuredEndpoint;
	}

	
	findMasterQueriesByTemplateCategoryAndCustomReportsMasterCategory(request: FindQueriesRequest ): Observable<MasterQueryResponse[]> {
		const endpointUrl = `/pms2/ws/query/findMasterQueriesByTemplateCategoryAndCustomReportsMasterCategory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, MasterQueryResponse, FindQueriesRequest, request );
		
		return configuredEndpoint;
	}

	
	getQueryById(queryId: number): Observable<QueryResponse> {
		const endpointUrl = `/pms2/ws/query/getQueryById/${queryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getTemplateCategories(): Observable<QueryTemplateCategoryResponse[]> {
		const endpointUrl = `/pms2/ws/query/getTemplateCategories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryTemplateCategoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	importMasterQueries(request: ImportMasterQueriesRequest ): Observable<QuerySummaryResponse[]> {
		const endpointUrl = `/pms2/ws/query/importMasterQueries`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QuerySummaryResponse, ImportMasterQueriesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCustomQuery(request: UpdateCustomQueryRequest ): Observable<QuerySummaryResponse> {
		const endpointUrl = `/pms2/ws/query/updateCustomQuery`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QuerySummaryResponse, UpdateCustomQueryRequest, request );
		
		return configuredEndpoint;
	}

	
	updateQuery(request: UpdateQueryRequest ): Observable<QuerySummaryResponse> {
		const endpointUrl = `/pms2/ws/query/updateQuery`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QuerySummaryResponse, UpdateQueryRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.query.QueryTriggerController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class QueryTriggerGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activate(queryTriggerId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/queryTrigger/activate/${queryTriggerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createQueryTrigger(request: CreateQueryTriggerRequest ): Observable<QueryTriggerSummaryResponse> {
		const endpointUrl = `/pms2/ws/queryTrigger/createQueryTrigger`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryTriggerSummaryResponse, CreateQueryTriggerRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivate(queryTriggerId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/queryTrigger/deactivate/${queryTriggerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findCarePlanItemTemplates(): Observable<QueryTriggerCarePlanItemTemplateResponse[]> {
		const endpointUrl = `/pms2/ws/queryTrigger/findCarePlanItemTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryTriggerCarePlanItemTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findCarePlanTemplates(): Observable<QueryTriggerCarePlanTemplateResponse[]> {
		const endpointUrl = `/pms2/ws/queryTrigger/findCarePlanTemplates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryTriggerCarePlanTemplateResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeQueryTriggersByTemplateCategory(request: FindQueryTriggersRequest ): Observable<QueryTriggerSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/queryTrigger/findPracticeQueryTriggersByTemplateCategory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryTriggerSummaryResponse, FindQueryTriggersRequest, request );
		
		return configuredEndpoint;
	}

	
	getQueryTriggerById(queryTriggerId: number): Observable<QueryTriggerResponse> {
		const endpointUrl = `/pms2/ws/queryTrigger/getQueryTriggerById/${queryTriggerId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryTriggerResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateQueryTrigger(request: UpdateQueryTriggerRequest ): Observable<QueryTriggerSummaryResponse> {
		const endpointUrl = `/pms2/ws/queryTrigger/updateQueryTrigger`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryTriggerSummaryResponse, UpdateQueryTriggerRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.data.ReferenceDataController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ReferenceDataGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	@GandalfCacheBuster({cacheKeys: ['reference_data', 'state_provinces']})
	createReferenceData(request: CreateReferenceDataRequest ): Observable<ReferenceDataResponse> {
		const endpointUrl = `/pms2/ws/referenceData/createReferenceData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, CreateReferenceDataRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveReferenceDataByCategoryId(categoryId: number, cacheOptions?: GandalfCacheableConfigurableOptions<ReferenceDataResponse[]>): Observable<ReferenceDataResponse[]> {
		const endpointUrl = `/pms2/ws/referenceData/findActiveReferenceDataByCategoryId/${categoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['reference_data'], cacheableOptions);
	}

	
	findReferenceDataByCategoryId(categoryId: number, cacheOptions?: GandalfCacheableConfigurableOptions<ReferenceDataResponse[]>): Observable<ReferenceDataResponse[]> {
		const endpointUrl = `/pms2/ws/referenceData/findReferenceDataByCategoryId/${categoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['reference_data'], cacheableOptions);
	}

	
	getReferenceDataById(referenceDataId: number, cacheOptions?: GandalfCacheableConfigurableOptions<ReferenceDataResponse>): Observable<ReferenceDataResponse> {
		const endpointUrl = `/pms2/ws/referenceData/getReferenceDataById/${referenceDataId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['reference_data'], cacheableOptions);
	}

	@GandalfCacheBuster({cacheKeys: ['reference_data', 'state_provinces']})
	updateReferenceData(request: UpdateReferenceDataRequest ): Observable<ReferenceDataResponse> {
		const endpointUrl = `/pms2/ws/referenceData/updateReferenceData`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ReferenceDataResponse, UpdateReferenceDataRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.prescription.RefractionController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class RefractionGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findRefractionsByEncounterId(encounterId: number): Observable<RefractionResponse[]> {
		const endpointUrl = `/pms2/ws/prescription/refraction/findRefractionsByEncounterId/${encounterId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RefractionResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.reporting.accounting.ReportingAccountingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ReportingAccountingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findUnassignedServices(request: UnassignedServicesRequest ): Observable<UnassignedServiceResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/accounting/findUnassignedServices`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UnassignedServiceResponse, UnassignedServicesRequest, request );
		
		return configuredEndpoint;
	}

	
	searchAgingReport(request: AgingReportRequest ): Observable<AgingReportResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/accounting/searchAgingReport`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AgingReportResponse, AgingReportRequest, request );
		
		return configuredEndpoint;
	}

	
	searchDepositSlip(request: DepositSlipSearchRequest ): Observable<DepositSlipSearchResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/accounting/searchDepositSlip`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, DepositSlipSearchResponse, DepositSlipSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	searchLedgerPostingReport(request: LedgerPostingReportRequest ): Observable<LedgerPostingReportResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/accounting/searchLedgerPostingReport`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LedgerPostingReportResponse, LedgerPostingReportRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.reporting.admin.ReportingAdminController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ReportingAdminGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findByUserSessionId(userSessionId: number): Observable<UserSessionLogResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/admin/findByUserSessionId/${userSessionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UserSessionLogResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchAccessLog(request: AccessLogSearchRequest ): Observable<AccessLogSearchResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/admin/searchAccessLog`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AccessLogSearchResponse, AccessLogSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	searchUserSessionLogs(request: UserSessionLogSearchRequest ): Observable<UserSessionLogResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/admin/searchUserSessionLogs`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, UserSessionLogResponse, UserSessionLogSearchRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.reporting.inventory.ReportingInventoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ReportingInventoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findProductCategories(): Observable<ProductCategoryResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/inventory/findProductCategories`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ProductCategoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchAdvancedInventory(request: AdvancedInventoryReportRequest ): Observable<BaseInventoryReportResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/inventory/searchAdvancedInventory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BaseInventoryReportResponse, AdvancedInventoryReportRequest, request );
		
		return configuredEndpoint;
	}

	
	searchBasicInventory(request: BasicInventoryReportRequest ): Observable<BaseInventoryReportResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/inventory/searchBasicInventory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, BaseInventoryReportResponse, BasicInventoryReportRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.solutions.revdirect.RevDirectController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class RevDirectGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateDirectAddressForLocation(directAddressId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/revDirect/activateDirectAddressForLocation/${directAddressId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deactivateDirectAddressForLocation(directAddressId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/revDirect/deactivateDirectAddressForLocation/${directAddressId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveLocationsWithDirectAddress(): Observable<RevDirectResponse[]> {
		const endpointUrl = `/pms2/ws/revDirect/findActiveLocationsWithDirectAddress`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RevDirectResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeLocationsForDirectMessageValidation(): Observable<PracticeLocationValidDirectMessageResponse[]> {
		const endpointUrl = `/pms2/ws/revDirect/findPracticeLocationsForDirectMessageValidation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationValidDirectMessageResponse, null, null );
		
		return configuredEndpoint;
	}

	
	registerDirectLocationAccount(request: RevDirectRegisterLocationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/revDirect/registerDirectLocationAccount`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, RevDirectRegisterLocationRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.practicepreferences.RxntIntegrationController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class RxntIntegrationGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	disableForCurrentPractice(): Observable<RxntCompanyMapResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/rxntIntegration/disableForCurrentPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RxntCompanyMapResponse, null, null );
		
		return configuredEndpoint;
	}

	
	enableForCurrentPractice(rxntCompanyId: number): Observable<RxntCompanyMapResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/rxntIntegration/enableForCurrentPractice/${rxntCompanyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RxntCompanyMapResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findForCurrentPractice(): Observable<RxntCompanyMapResponse> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/rxntIntegration/findForCurrentPractice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, RxntCompanyMapResponse, null, null );
		
		return configuredEndpoint;
	}

	
	resetRxnt(): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/practicePreferences/rxntIntegration/resetRxnt`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.schedule.ScheduleController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ScheduleGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService, private gandalfStoreService: GandalfStoreService) {}

	
	cancelAppointment(request: CancelAppointmentRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/cancelAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CancelAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	confirmAppointment(request: ConfirmAppointmentRequest ): Observable<AppointmentResponse> {
		const endpointUrl = `/pms2/ws/schedule/confirmAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentResponse, ConfirmAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	confirmAppointmentCalendar(request: ConfirmAppointmentRequest ): Observable<AppointmentCalendarResponse> {
		const endpointUrl = `/pms2/ws/schedule/confirmAppointmentCalendar`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentCalendarResponse, ConfirmAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	createAppointment(request: CreateAppointmentRequest ): Observable<ValidatedCreateAppointmentResponse> {
		const endpointUrl = `/pms2/ws/schedule/createAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ValidatedCreateAppointmentResponse, CreateAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	createAppointmentLegacy(request: CreateAppointmentRequest ): Observable<ValidatedCreateAppointmentLegacyResponse> {
		const endpointUrl = `/pms2/ws/schedule/createAppointmentLegacy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ValidatedCreateAppointmentLegacyResponse, CreateAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	createAppointmentSlot(request: CreateAppointmentSlotRequest ): Observable<Date> {
		const endpointUrl = `/pms2/ws/schedule/createAppointmentSlot`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Date, CreateAppointmentSlotRequest, request );
		
		return configuredEndpoint;
	}

	
	createBusyTime(request: CreateBusyTimeRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/createBusyTime`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateBusyTimeRequest, request );
		
		return configuredEndpoint;
	}

	
	createEmployeeOfficeHours(request: CreateEmployeeOfficeHoursRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/createEmployeeOfficeHours`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateEmployeeOfficeHoursRequest, request );
		
		return configuredEndpoint;
	}

	
	createOfficeClosed(request: CreateOfficeClosedRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/createOfficeClosed`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateOfficeClosedRequest, request );
		
		return configuredEndpoint;
	}

	
	createRescheduledAppointment(request: CreateRescheduledAppointmentRequest ): Observable<ValidatedCreateAppointmentResponse> {
		const endpointUrl = `/pms2/ws/schedule/createRescheduledAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ValidatedCreateAppointmentResponse, CreateRescheduledAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	createRescheduledAppointmentLegacy(request: CreateRescheduledAppointmentRequest ): Observable<ValidatedCreateAppointmentLegacyResponse> {
		const endpointUrl = `/pms2/ws/schedule/createRescheduledAppointmentLegacy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ValidatedCreateAppointmentLegacyResponse, CreateRescheduledAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteAppointmentSlot(request: DeleteScheduleItemRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/deleteAppointmentSlot`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeleteScheduleItemRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteBusyTime(request: DeleteScheduleItemRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/deleteBusyTime`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeleteScheduleItemRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteEmployeeOfficeHours(request: DeleteScheduleItemRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/deleteEmployeeOfficeHours`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeleteScheduleItemRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteOfficeClosed(request: DeleteScheduleItemRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/deleteOfficeClosed`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, DeleteScheduleItemRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveLocations(cacheOptions?: GandalfCacheableConfigurableOptions<PracticeLocationSummaryResponse[]>): Observable<PracticeLocationSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/findActiveLocations`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationSummaryResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['locations'], cacheableOptions);
	}

	
	findAppointmentsByPatientId(patientId: number): Observable<AppointmentHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/findAppointmentsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findAvailableAppointmentSlots(request: SearchAppointmentSlotRequest ): Observable<AppointmentSlotResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/findAvailableAppointmentSlots`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentSlotResponse, SearchAppointmentSlotRequest, request );
		
		return configuredEndpoint;
	}

	
	findCustomFilterPreferences(): Observable<CustomFilterPreferencesResponse> {
		const endpointUrl = `/pms2/ws/schedule/findCustomFilterPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CustomFilterPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFullScheduleItems(request: FindScheduleItemRequest ): Observable<ScheduleItemResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/findFullScheduleItems`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemResponse, FindScheduleItemRequest, request );
		
		return configuredEndpoint;
	}

	
	findPatientAlertsByPatientId(patientId: number): Observable<AlertResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/findPatientAlertsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AlertResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientSchedulingInfoByPatientId(patientId: number): Observable<PatientSchedulingInfoResponse> {
		const endpointUrl = `/pms2/ws/schedule/findPatientSchedulingInfoByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientSchedulingInfoResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeLocationOfficeHours(practiceLocationId: number, cacheOptions?: GandalfCacheableConfigurableOptions<PracticeLocationOfficeHoursResponse>): Observable<PracticeLocationOfficeHoursResponse> {
		const endpointUrl = `/pms2/ws/schedule/findPracticeLocationOfficeHours/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationOfficeHoursResponse, null, null );
		const cacheableOptions = new GandalfCacheableOptions(null);
		cacheableOptions.mergeConfigurableOptions(cacheOptions);
		return this.gandalfStoreService.buildCacheableEndpoint(endpointUrl, configuredEndpoint, ['get_office_hours'], cacheableOptions);
	}

	
	findScheduleItemCalendarById(scheduleItemId: number): Observable<ScheduleItemCalendarResponse> {
		const endpointUrl = `/pms2/ws/schedule/findScheduleItemCalendarById/${scheduleItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemCalendarResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findScheduleItemSidebarById(scheduleItemId: number): Observable<ScheduleItemSidebarResponse> {
		const endpointUrl = `/pms2/ws/schedule/findScheduleItemSidebarById/${scheduleItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemSidebarResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findScheduleItems(request: FindScheduleItemRequest ): Observable<ScheduleItemsWithDeletedResponse> {
		const endpointUrl = `/pms2/ws/schedule/findScheduleItems`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemsWithDeletedResponse, FindScheduleItemRequest, request );
		
		return configuredEndpoint;
	}

	
	findScheduleItemsById(request: FindScheduleItemsByIdRequest ): Observable<ScheduleItemPrintResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/findScheduleItemsById`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemPrintResponse, FindScheduleItemsByIdRequest, request );
		
		return configuredEndpoint;
	}

	
	findScheduleViewPreferences(): Observable<ScheduleViewPreferencesResponse> {
		const endpointUrl = `/pms2/ws/schedule/findScheduleViewPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleViewPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findSchedulingPreferencesByPatientId(patientId: number): Observable<SchedulingPreferencesResponse> {
		const endpointUrl = `/pms2/ws/schedule/findSchedulingPreferencesByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SchedulingPreferencesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findSecurityRoles(): Observable<SecurityRoleResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/findSecurityRoles`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SecurityRoleResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getScheduleItemById(scheduleItemId: number): Observable<ScheduleItemResponse> {
		const endpointUrl = `/pms2/ws/schedule/getScheduleItemById/${scheduleItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemResponse, null, null );
		
		return configuredEndpoint;
	}

	
	noShowAppointment(appointmentId: number): Observable<AppointmentCalendarResponse> {
		const endpointUrl = `/pms2/ws/schedule/noShowAppointment/${appointmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentCalendarResponse, null, null );
		
		return configuredEndpoint;
	}

	
	noShowAppointmentLegacy(appointmentId: number): Observable<AppointmentResponse> {
		const endpointUrl = `/pms2/ws/schedule/noShowAppointmentLegacy/${appointmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	startAppointment(request: StartAppointmentRequest ): Observable<ScheduleItemResponse> {
		const endpointUrl = `/pms2/ws/schedule/startAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemResponse, StartAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	startAppointmentCalendar(request: StartAppointmentRequest ): Observable<StartAppointmentCalendarResponse> {
		const endpointUrl = `/pms2/ws/schedule/startAppointmentCalendar`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, StartAppointmentCalendarResponse, StartAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	unassociateAppointmentSlot(appointmentSlotId: number): Observable<AppointmentSlotResponse> {
		const endpointUrl = `/pms2/ws/schedule/unassociateAppointmentSlot/${appointmentSlotId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentSlotResponse, null, null );
		
		return configuredEndpoint;
	}

	
	unassociateAppointmentSlotCalendar(appointmentSlotId: number): Observable<AppointmentSlotCalendarResponse> {
		const endpointUrl = `/pms2/ws/schedule/unassociateAppointmentSlotCalendar/${appointmentSlotId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentSlotCalendarResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateAppointmentDescription(request: UpdateAppointmentDescriptionRequest ): Observable<ScheduleItemResponse> {
		const endpointUrl = `/pms2/ws/schedule/updateAppointmentDescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemResponse, UpdateAppointmentDescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updateAppointmentDescriptionCalendarResponse(request: UpdateAppointmentDescriptionRequest ): Observable<ScheduleItemCalendarResponse> {
		const endpointUrl = `/pms2/ws/schedule/updateAppointmentDescriptionCalendarResponse`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemCalendarResponse, UpdateAppointmentDescriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updateAppointmentSlot(request: UpdateAppointmentSlotRequest ): Observable<Date> {
		const endpointUrl = `/pms2/ws/schedule/updateAppointmentSlot`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Date, UpdateAppointmentSlotRequest, request );
		
		return configuredEndpoint;
	}

	
	updateBusyTime(request: UpdateBusyTimeRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/updateBusyTime`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateBusyTimeRequest, request );
		
		return configuredEndpoint;
	}

	
	updateCustomFilterPreferences(request: UpdateCustomFilterPreferencesRequest ): Observable<CustomFilterPreferencesResponse> {
		const endpointUrl = `/pms2/ws/schedule/updateCustomFilterPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, CustomFilterPreferencesResponse, UpdateCustomFilterPreferencesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateEmployeeOfficeHours(request: UpdateEmployeeOfficeHoursRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/updateEmployeeOfficeHours`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateEmployeeOfficeHoursRequest, request );
		
		return configuredEndpoint;
	}

	
	updateOfficeClosed(request: UpdateOfficeClosedRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/updateOfficeClosed`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateOfficeClosedRequest, request );
		
		return configuredEndpoint;
	}

	
	updateRecurringAppointmentSlot(request: UpdateRecurringAppointmentSlotRequest ): Observable<ScheduleItemResponse> {
		const endpointUrl = `/pms2/ws/schedule/updateRecurringAppointmentSlot`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleItemResponse, UpdateRecurringAppointmentSlotRequest, request );
		
		return configuredEndpoint;
	}

	
	updateRecurringBusyTime(request: UpdateRecurringBusyTimeRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/updateRecurringBusyTime`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateRecurringBusyTimeRequest, request );
		
		return configuredEndpoint;
	}

	
	updateRecurringEmployeeOfficeHours(request: UpdateRecurringEmployeeOfficeHoursRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/updateRecurringEmployeeOfficeHours`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateRecurringEmployeeOfficeHoursRequest, request );
		
		return configuredEndpoint;
	}

	
	updateRecurringOfficeClosed(request: UpdateRecurringOfficeClosedRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/updateRecurringOfficeClosed`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateRecurringOfficeClosedRequest, request );
		
		return configuredEndpoint;
	}

	
	updateScheduleViewPreferences(request: UpdateScheduleViewPreferencesRequest ): Observable<ScheduleViewPreferencesResponse> {
		const endpointUrl = `/pms2/ws/schedule/updateScheduleViewPreferences`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ScheduleViewPreferencesResponse, UpdateScheduleViewPreferencesRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.reporting.schedule.ScheduleReportingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ScheduleReportingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	confirmAppointment(request: ConfirmAppointmentRequest ): Observable<AppointmentResponse> {
		const endpointUrl = `/pms2/ws/reporting/scheduleReporting/confirmAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentResponse, ConfirmAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	findAppointmentsByPatientId(patientId: number): Observable<AppointmentHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/scheduleReporting/findAppointmentsByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getAppointmentById(appointmentId: number): Observable<AppointmentResponse> {
		const endpointUrl = `/pms2/ws/reporting/scheduleReporting/getAppointmentById/${appointmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	searchAppointments(request: AppointmentReportRequest ): Observable<AppointmentReportResponse[]> {
		const endpointUrl = `/pms2/ws/reporting/scheduleReporting/searchAppointments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentReportResponse, AppointmentReportRequest, request );
		
		return configuredEndpoint;
	}

	
	updateAppointmentDescription(request: UpdateAppointmentDescriptionRequest ): Observable<AppointmentResponse> {
		const endpointUrl = `/pms2/ws/reporting/scheduleReporting/updateAppointmentDescription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentResponse, UpdateAppointmentDescriptionRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.schedule.sidebardetails.SidebarDetailsController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class ScheduleSidebarDetailsGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activateInsuranceById(personInsuranceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/sidebarDetails/activateInsuranceById/${personInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deactivateInsuranceById(personInsuranceId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/sidebarDetails/deactivateInsuranceById/${personInsuranceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findInsuranceByPatientId(patientId: number): Observable<PersonInsuranceDetailsResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/sidebarDetails/findInsuranceByPatientId/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceDetailsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInsuranceByPersonId(personId: number): Observable<PersonInsuranceDetailsResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/sidebarDetails/findInsuranceByPersonId/${personId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PersonInsuranceDetailsResponse, null, null );
		
		return configuredEndpoint;
	}

	
	removeInsuranceVerificationByAppointmentId(appointmentId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/sidebarDetails/removeInsuranceVerificationByAppointmentId/${appointmentId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	verifyInsuranceByAppointmentId(request: VerifyInsuranceRequest ): Observable<AppointmentCalendarResponse> {
		const endpointUrl = `/pms2/ws/schedule/sidebarDetails/verifyInsuranceByAppointmentId`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentCalendarResponse, VerifyInsuranceRequest, request );
		
		return configuredEndpoint;
	}

	
	verifyInsuranceByAppointmentIdLegacy(request: VerifyInsuranceRequest ): Observable<AppointmentResponse> {
		const endpointUrl = `/pms2/ws/schedule/sidebarDetails/verifyInsuranceByAppointmentIdLegacy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AppointmentResponse, VerifyInsuranceRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.shared.eyefinity.SharedEyefinityListController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class SharedEyefinityListGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	getItemsByCategoryType(request: EyefinityListItemRequest ): Observable<EyefinityListItemResponse[]> {
		const endpointUrl = `/pms2/ws/shared/eyefinity/list/getItemsByCategoryType`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyefinityListItemResponse, EyefinityListItemRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.shared.patient.order.SharedPatientOrderController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class SharedPatientOrderGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findContactLensPrescriptionsForOrders(patientId: number): Observable<ContactLensOrderSearchResponse[]> {
		const endpointUrl = `/pms2/ws/shared/patient/order/findContactLensPrescriptionsForOrders/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensOrderSearchResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findContactLensTrialsAndPrescriptionsForOrders(patientId: number): Observable<ContactLensOrderSearchResponse[]> {
		const endpointUrl = `/pms2/ws/shared/patient/order/findContactLensTrialsAndPrescriptionsForOrders/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ContactLensOrderSearchResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findEyeglassPrescriptionsForOrders(patientId: number): Observable<EyeglassOrderSearchResponse[]> {
		const endpointUrl = `/pms2/ws/shared/patient/order/findEyeglassPrescriptionsForOrders/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, EyeglassOrderSearchResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.shared.snomed.SnomedController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class SnomedCodeGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	searchSnomedCodes(request: SearchSnomedCodesRequest ): Observable<SnomedCodeResponse[]> {
		const endpointUrl = `/pms2/ws/snomedCode/searchSnomedCodes`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SnomedCodeResponse, SearchSnomedCodesRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.patient.surgeryhistory.SurgeryHistoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class SurgeryHistoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createSurgeryHistory(request: CreateSurgeryHistoryRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/surgeryhistory/createSurgeryHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateSurgeryHistoryRequest, request );
		
		return configuredEndpoint;
	}

	
	deleteSurgeryHistory(surgeryHistoryId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/surgeryhistory/deleteSurgeryHistory/${surgeryHistoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findSurgeryHistoriesByPatient(patientId: number): Observable<SurgeryHistoryResponse[]> {
		const endpointUrl = `/pms2/ws/surgeryhistory/findSurgeryHistoriesByPatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SurgeryHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getSurgeryHistoryById(surgeryHistoryId: number): Observable<SurgeryHistoryResponse> {
		const endpointUrl = `/pms2/ws/surgeryhistory/getSurgeryHistoryById/${surgeryHistoryId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SurgeryHistoryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateSurgeryHistory(request: UpdateSurgeryHistoryRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/surgeryhistory/updateSurgeryHistory`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateSurgeryHistoryRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.trizetto.TrizettoExternalCredentialController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class TrizettoExternalCredentialGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createTrizettoExternalCredential(request: CreateTrizettoExternalCredentialRequest ): Observable<TrizettoExternalCredentialResponse> {
		const endpointUrl = `/pms2/ws/trizettoExternalCredential/createTrizettoExternalCredential`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TrizettoExternalCredentialResponse, CreateTrizettoExternalCredentialRequest, request );
		
		return configuredEndpoint;
	}

	
	disableTrizettoExternalCredential(trizettoExternalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/trizettoExternalCredential/disableTrizettoExternalCredential/${trizettoExternalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	enableTrizettoExternalCredential(trizettoExternalCredentialId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/trizettoExternalCredential/enableTrizettoExternalCredential/${trizettoExternalCredentialId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findAllTrizettoExternalCredentials(): Observable<TrizettoExternalCredentialResponse[]> {
		const endpointUrl = `/pms2/ws/trizettoExternalCredential/findAllTrizettoExternalCredentials`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TrizettoExternalCredentialResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateTrizettoExternalCredential(request: UpdateTrizettoExternalCredentialRequest ): Observable<TrizettoExternalCredentialResponse> {
		const endpointUrl = `/pms2/ws/trizettoExternalCredential/updateTrizettoExternalCredential`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TrizettoExternalCredentialResponse, UpdateTrizettoExternalCredentialRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.solutions.twelve84.Twelve84Controller */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class TwelveEightyFourGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findActiveTwelve84Subscriptions(): Observable<Twelve84SubscriptionTableResponse[]> {
		const endpointUrl = `/pms2/ws/admin/twelve84/findActiveTwelve84Subscriptions`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Twelve84SubscriptionTableResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getTwelve84Agreement(): Observable<Twelve84AgreementResponse> {
		const endpointUrl = `/pms2/ws/admin/twelve84/getTwelve84Agreement`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Twelve84AgreementResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getTwelve84Subscription(twelve84SubscriptionId: number): Observable<Twelve84SubscriptionResponse> {
		const endpointUrl = `/pms2/ws/admin/twelve84/getTwelve84Subscription/${twelve84SubscriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Twelve84SubscriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	receiveKit(practiceLocationId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/twelve84/receiveKit/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	signTwelve84Agreement(): Observable<Twelve84AgreementResponse> {
		const endpointUrl = `/pms2/ws/admin/twelve84/signTwelve84Agreement`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Twelve84AgreementResponse, null, null );
		
		return configuredEndpoint;
	}

	
	subscribeToTwelve84(request: SubscribeTwelve84SubscriptionRequest ): Observable<Twelve84SubscriptionResponse> {
		const endpointUrl = `/pms2/ws/admin/twelve84/subscribeToTwelve84`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Twelve84SubscriptionResponse, SubscribeTwelve84SubscriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	unsignTwelve84Agreement(): Observable<Twelve84AgreementResponse> {
		const endpointUrl = `/pms2/ws/admin/twelve84/unsignTwelve84Agreement`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Twelve84AgreementResponse, null, null );
		
		return configuredEndpoint;
	}

	
	unsubscribeFromTwelve84(twelve84SubscriptionId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/twelve84/unsubscribeFromTwelve84/${twelve84SubscriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	updateTwelve84Subscription(request: UpdateTwelve84SubscriptionRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/twelve84/updateTwelve84Subscription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateTwelve84SubscriptionRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class TwoWayTextGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createConversation(request: CreateTwoWayTextConversationRequest ): Observable<TwoWayTextConversationResponse> {
		const endpointUrl = `/pms2/ws/twoWayText/createConversation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextConversationResponse, CreateTwoWayTextConversationRequest, request );
		
		return configuredEndpoint;
	}

	
	createMessageAndResolveConversation(request: CreateTwoWayTextMessageRequest ): Observable<TwoWayTextConversationResponse> {
		const endpointUrl = `/pms2/ws/twoWayText/createMessageAndResolveConversation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextConversationResponse, CreateTwoWayTextMessageRequest, request );
		
		return configuredEndpoint;
	}

	
	findBySimpleSearch(request: TwoWayTextSimpleSearchRequest ): Observable<TwoWayTextConversationListResponse[]> {
		const endpointUrl = `/pms2/ws/twoWayText/findBySimpleSearch`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextConversationListResponse, TwoWayTextSimpleSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	findConversationMessageUpdates(request: FindTwoWayTextConversationMessageUpdatesRequest ): Observable<TwoWayTextMessageResponse[]> {
		const endpointUrl = `/pms2/ws/twoWayText/findConversationMessageUpdates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextMessageResponse, FindTwoWayTextConversationMessageUpdatesRequest, request );
		
		return configuredEndpoint;
	}

	
	findConversationOrPatientMatchesForPatientCellPhone(patientId: number): Observable<TwoWayTextConversationOrPatientMatchesResponse> {
		const endpointUrl = `/pms2/ws/twoWayText/findConversationOrPatientMatchesForPatientCellPhone/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextConversationOrPatientMatchesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findConversationUpdates(request: FindTwoWayTextConversationUpdatesRequest ): Observable<TwoWayTextConversationListResponse[]> {
		const endpointUrl = `/pms2/ws/twoWayText/findConversationUpdates`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextConversationListResponse, FindTwoWayTextConversationUpdatesRequest, request );
		
		return configuredEndpoint;
	}

	
	findConversationsByClassification(request: TwoWayTextFindConversationsByClassificationRequest ): Observable<TwoWayTextConversationListResponse[]> {
		const endpointUrl = `/pms2/ws/twoWayText/findConversationsByClassification`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextConversationListResponse, TwoWayTextFindConversationsByClassificationRequest, request );
		
		return configuredEndpoint;
	}

	
	findNewConversationPatients(request: TwoWayTextFindNewConversationPatientsRequest ): Observable<TwoWayTextNewConversationPatientResponse[]> {
		const endpointUrl = `/pms2/ws/twoWayText/findNewConversationPatients`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextNewConversationPatientResponse, TwoWayTextFindNewConversationPatientsRequest, request );
		
		return configuredEndpoint;
	}

	
	findPatientMatchesForConversation(connectConversationId: number): Observable<TwoWayTextPatientMatchesResponse> {
		const endpointUrl = `/pms2/ws/twoWayText/findPatientMatchesForConversation/${connectConversationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextPatientMatchesResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findRecentSmsMessagesSentToPatientCellPhone(patientId: number): Observable<TwoWayTextAutomatedMessageResponse[]> {
		const endpointUrl = `/pms2/ws/twoWayText/findRecentAutomatedMessages/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextAutomatedMessageResponse, null, null );
		
		return configuredEndpoint;
	}

	
	markConversationAsUnread(connectConversationId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/twoWayText/markConversationAsUnread/${connectConversationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	readConversationDetailsById(connectConversationId: number): Observable<TwoWayTextConversationResponse> {
		const endpointUrl = `/pms2/ws/twoWayText/getConversationByIdAndMarkRead/${connectConversationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, TwoWayTextConversationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateConversationClassification(request: UpdateTwoWayTextConversationClassificationRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/twoWayText/updateConversationClassification`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateTwoWayTextConversationClassificationRequest, request );
		
		return configuredEndpoint;
	}

	
	updateConversationPatient(request: UpdateTwoWayTextConversationPatientRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/twoWayText/updateConversationPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateTwoWayTextConversationPatientRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.VendorsAndPartnersController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class VendorsAndPartnersGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePharmacyById(pharmacyId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/activatePharmacyById/${pharmacyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	activateSalesRepresentative(salesRepresentativeId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/activateSalesRepresentative/${salesRepresentativeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	activateThirdPartyAdministrator(thirdPartyAdministratorId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/activateThirdPartyAdministrator/${thirdPartyAdministratorId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['order_processor_vendors']})
	activateVendor(vendorId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/activateVendor/${vendorId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	createFramesDataSubscription(request: CreateFramesDataSubscriptionRequest ): Observable<FramesDataSubscriptionResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/createFramesDataSubscription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FramesDataSubscriptionResponse, CreateFramesDataSubscriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	createPharmacy(request: CreatePharmacyRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/createPharmacy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePharmacyRequest, request );
		
		return configuredEndpoint;
	}

	
	createSalesRepresentative(request: CreateSalesRepresentativeRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/createSalesRepresentative`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateSalesRepresentativeRequest, request );
		
		return configuredEndpoint;
	}

	
	createThirdPartyAdministrator(request: CreateThirdPartyAdministratorRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/createThirdPartyAdministrator`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateThirdPartyAdministratorRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['order_processor_vendors']})
	createVendor(request: CreateVendorRequest ): Observable<VendorResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/createVendor`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorResponse, CreateVendorRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['order_processor_vendors']})
	createVendorLocationConfig(request: CreateVendorLocationConfigRequest ): Observable<VendorLocationConfigResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/createVendorLocationConfig`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorLocationConfigResponse, CreateVendorLocationConfigRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePharmacyById(pharmacyId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/deactivatePharmacyById/${pharmacyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deactivateSalesRepresentative(salesRepresentativeId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/deactivateSalesRepresentative/${salesRepresentativeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	deactivateThirdPartyAdministrator(thirdPartyAdministratorId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/deactivateThirdPartyAdministrator/${thirdPartyAdministratorId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['order_processor_vendors']})
	deactivateVendor(vendorId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/deactivateVendor/${vendorId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['order_processor_vendors']})
	deleteVendorLocationConfig(vendorLocationConfigId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/deleteVendorLocationConfig/${vendorLocationConfigId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	disableFramesDataSubscription(framesDataSubscriptionId: number): Observable<FramesDataSubscriptionResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/disableFramesDataSubscription/${framesDataSubscriptionId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FramesDataSubscriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveSmartflowPracticeSuppliers(): Observable<SmartflowPracticeSupplierResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findActiveSmartflowPracticeSuppliers`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SmartflowPracticeSupplierResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findAllPharmacies(): Observable<PharmacySummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findAllPharmacies`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PharmacySummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findAllThirdPartyAdministrators(): Observable<ThirdPartyAdministratorResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findAllThirdPartyAdministrators`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ThirdPartyAdministratorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findFramesDataSubscription(): Observable<FramesDataSubscriptionResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findFramesDataSubscription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FramesDataSubscriptionResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findLocationsWithoutVendorConfigsForVendor(vendorId: number): Observable<PracticeLocationSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findLocationsWithoutVendorConfigsForVendor/${vendorId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PracticeLocationSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPharmacyBrands(): Observable<PharmacyBrandResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findPharmacyBrands`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PharmacyBrandResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPracticeSalesRepresentatives(): Observable<SalesRepresentativesSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findPracticeSalesRepresentatives`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SalesRepresentativesSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findSmartflowMasterSupplierLocations(smartflowMasterSupplierId: number): Observable<SmartflowSupplierLocationResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findSmartflowMasterSupplierLocations/${smartflowMasterSupplierId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SmartflowSupplierLocationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findVendorLocationConfigsByVendor(vendorId: number): Observable<VendorLocationConfigResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findVendorLocationConfigsByVendor/${vendorId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorLocationConfigResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findVendors(request: VendorSearchRequest ): Observable<VendorSummaryResponse[]> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/findVendors`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorSummaryResponse, VendorSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	getPharmacyById(pharmacyId: number): Observable<PharmacyResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/getPharmacyById/${pharmacyId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PharmacyResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getSalesRepresentativeById(salesRepresentativeId: number): Observable<SalesRepresentativeResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/getSalesRepresentativeById/${salesRepresentativeId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SalesRepresentativeResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getThirdPartyAdministratorById(thirdPartyAdministratorId: number): Observable<ThirdPartyAdministratorResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/getThirdPartyAdministratorById/${thirdPartyAdministratorId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ThirdPartyAdministratorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getVendor(vendorId: number): Observable<VendorResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/getVendor/${vendorId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getVendorLocationConfigById(vendorLocationConfigId: number): Observable<VendorLocationConfigResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/getVendorLocationConfigById/${vendorLocationConfigId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorLocationConfigResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateFramesDataSubscription(request: UpdateFramesDataSubscriptionRequest ): Observable<FramesDataSubscriptionResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/updateFramesDataSubscription`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, FramesDataSubscriptionResponse, UpdateFramesDataSubscriptionRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePharmacy(request: UpdatePharmacyRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/updatePharmacy`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePharmacyRequest, request );
		
		return configuredEndpoint;
	}

	
	updateSalesRepresentative(request: UpdateSalesRepresentativeRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/updateSalesRepresentative`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateSalesRepresentativeRequest, request );
		
		return configuredEndpoint;
	}

	
	updateThirdPartyAdministrator(request: UpdateThirdPartyAdministratorRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/updateThirdPartyAdministrator`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateThirdPartyAdministratorRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['order_processor_vendors']})
	updateVendor(request: UpdateVendorRequest ): Observable<VendorResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/updateVendor`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorResponse, UpdateVendorRequest, request );
		
		return configuredEndpoint;
	}

	@GandalfCacheBuster({cacheKeys: ['order_processor_vendors']})
	updateVendorLocationConfig(request: UpdateVendorLocationConfigRequest ): Observable<VendorLocationConfigResponse> {
		const endpointUrl = `/pms2/ws/admin/vendorsAndPartners/updateVendorLocationConfig`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, VendorLocationConfigResponse, UpdateVendorLocationConfigRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.pms.api.controller.schedule.waitinglist.WaitingListController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class WaitingListGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createWaitingList(request: CreateWaitingListRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/waitingList/createWaitingList`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateWaitingListRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivateWaitingListEntry(waitingListId: number): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/waitingList/deactivateWaitingListEntry/${waitingListId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findEmployeeAccessibleWaitingList(): Observable<WaitingListResponse[]> {
		const endpointUrl = `/pms2/ws/schedule/waitingList/findEmployeeAccessibleWaitingList`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, WaitingListResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getWaitingListCountForLocation(locationId: number): Observable<number> {
		const endpointUrl = `/pms2/ws/schedule/waitingList/getWaitingListCountForLocation/${locationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, Number, null, null );
		
		return configuredEndpoint;
	}

	
	sendWaitingListConnectMessage(request: ConnectMessageForWaitingListRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/waitingList/sendWaitingListConnectMessage`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, ConnectMessageForWaitingListRequest, request );
		
		return configuredEndpoint;
	}

	
	updateWaitingList(request: UpdateWaitingListRequest ): Observable<void> {
		const endpointUrl = `/pms2/ws/schedule/waitingList/updateWaitingList`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdateWaitingListRequest, request );
		
		return configuredEndpoint;
	}

}


