<form class="form-horizontal" [formGroup]="componentForm">
	<div class="form-group" [attr.data-test-id]="'dobFormGroup'">
		<label class="col-sm-3 col-md-4 control-label required">DOB</label>
		<div class="col-sm-9 col-md-8">
			<ejs-datepicker placeholder="mm/dd/yyyy"
							[format]="dateFormat"
							formControlName="dob">
			</ejs-datepicker>
		</div>
	</div>
	<div *pmsUsaOnly class="form-group" [attr.data-test-id]="'ssnFormGroup'">
		<label class="col-sm-3 col-md-4 control-label">SSN</label>
		<pms-social-security-number-input
			[last4Ssn]="patientResponse?.last4ssn"
			[fullSsnAvailable]="patientResponse?.fullSsnAvailable"
			[last4SsnControl]="this.componentForm.get('last4Ssn')"
			[ssnControl]="this.componentForm.get('ssn')"
			[disabled]="controlContainer.control.disabled"
			[patientId]="patientId">
		</pms-social-security-number-input>
	</div>
	<div *pmsCanadaOnly class="form-group">
		<label class="col-sm-3 col-md-4 control-label">SIN/PHN</label>
		<div class="col-sm-9 col-md-8">
			<input pmsSinInput class="e-input" type="text" formControlName="sin"/>
		</div>
	</div>
	<div [attr.data-test-id]="'alternateIdFormGroup'" class="form-group" *ngIf="displayAlternateId">
		<label class="col-sm-3 col-md-4 control-label">Alternate ID</label>
		<div class="col-sm-9 col-md-8">
			<input class="e-input" type="text" formControlName="alternateId"/>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'guarantorFormGroup'">
		<label class="col-sm-3 col-md-4 control-label">Guarantor</label>
		<ng-container *ngIf="isNewPatient; then StaticGuarantor; else GuarantorInput"></ng-container>
		<ng-template #GuarantorInput>
			<div class="col-sm-9 col-md-8">
				<ejs-dropdownlist *ngIf="guarantorOptions"
								  formControlName="guarantorId"
								  [dataSource]="guarantorOptions">
				</ejs-dropdownlist>
			</div>
		</ng-template>
		<ng-template #StaticGuarantor>
			<p class="form-control-static">Self</p>
		</ng-template>
	</div>
	<div class="form-group" [attr.data-test-id]="'sexFormGroup'">
		<label class="col-sm-3 col-md-4 control-label required">Sex</label>
		<div class="col-sm-9 col-md-8">
			<pms-enum-select-button
				[enumList]="genderOptions"
				[allOption]="false"
				formControlName="gender">
			</pms-enum-select-button>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'sexualOrientationFormGroup'">
		<label class="col-sm-3 col-md-4 control-label">Sexual Orientation</label>
		<div class="col-sm-9 col-md-8">
			<pms-constant-dropdown
				[constantList]="sexualOrientationOptions"
				[showClearButton]="true"
				placeholder="Select Sexual Orientation"
				formControlName="sexualOrientation">
			</pms-constant-dropdown>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'genderIdentityFormGroup'">
		<label class="col-sm-3 col-md-4 control-label">Gender Identity</label>
		<div class="col-sm-9 col-md-8">
			<pms-constant-dropdown
				[constantList]="genderIdentityOptions"
				[showClearButton]="true"
				placeholder="Select Gender Identity"
				formControlName="genderIdentity">
			</pms-constant-dropdown>
		</div>
	</div>

	<div class="form-group" [attr.data-test-id]="'statusFormGroup'">
		<label class="col-sm-3 col-md-4 control-label">Status</label>
		<div class="col-sm-9 col-md-8">
			<pms-enum-select-button
				[enumList]="statusOptions"
				[allOption]="false"
				formControlName="establishedStatus">
			</pms-enum-select-button>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'languageFormGroup'">
		<label class="col-xs-12 col-sm-3 col-md-4 control-label" [class.required]="isLanguageRequired">Language</label>
		<div class="col-xs-7 col-sm-5 col-md-5">
			<ejs-dropdownlist [dataSource]="languageOptions"
							  *ngIf="languageOptions"
							  placeholder="Select Language"
							  [showClearButton]="true"
							  formControlName="languageId">
			</ejs-dropdownlist>
		</div>
		<div class="col-xs-5 col-sm-4 col-md-3">
			<ejs-checkbox label="Declined" formControlName="languageDeclined">
			</ejs-checkbox>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'interpreterFormGroup'">
		<label class="col-sm-3 col-md-4 control-label" [class.required]="isInterpreterRequired">Interpreter</label>
		<div class="col-sm-9 col-md-8">
			<pms-enum-select-button
				[enumList]="interpreterOptions"
				[allOption]="false"
				formControlName="interpreterAssistance">
			</pms-enum-select-button>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'racesFormGroup'">
		<label class="col-xs-12 col-sm-3 col-md-4 control-label" [class.required]="isRaceRequired">Races</label>
		<div class="col-xs-7 col-sm-5 col-md-5">
			<ejs-multiselect
				[dataSource]="raceOptions"
				[showDropDownIcon]="true"
				*ngIf="raceOptions"
				formControlName="raceIds"
				showSelectAll="true"
				filterBarPlaceholder="Search"
				placeholder="Select Races"
				mode="CheckBox"
				[fields]="{text: 'label', value: 'value'}">
			</ejs-multiselect>
		</div>
		<div class="col-xs-5 col-sm-4 col-md-3">
			<ejs-checkbox label="Declined" formControlName="raceDeclined">
			</ejs-checkbox>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'ethnicityFormGroup'">
		<label class="col-xs-12 col-sm-3 col-md-4 control-label"
			   [class.required]="isEthnicityRequired">Ethnicity</label>
		<div class="col-xs-7 col-sm-5 col-md-5">
			<ejs-multiselect
				[dataSource]="ethnicityOptions"
				*ngIf="ethnicityOptions"
				[showDropDownIcon]="true"
				formControlName="ethnicityIds"
				showSelectAll="true"
				filterBarPlaceholder="Search"
				placeholder="Select Ethnicities"
				mode="CheckBox"
				[fields]="{text: 'label', value: 'value'}">
			</ejs-multiselect>
		</div>
		<div class="col-xs-5 col-sm-4 col-md-3">
			<ejs-checkbox label="Declined" formControlName="ethnicityDeclined">
			</ejs-checkbox>
		</div>
	</div>
	<div class="form-group" *ngIf="patientResponse?.dod">
		<label class="col-xs-12 col-sm-3 col-md-4 control-label">DOD</label>
		<div class="col-xs-7 col-sm-5 col-md-5">
			<p class="form-control-static">{{patientResponse?.dod | date: dateFormat}}</p>
		</div>
	</div>
</form>
