<div class="col-sm-6 col-md-5">
	<ng-container *ngIf="showFullSsnInput then FullSsnInput; else FullSsnLookup"></ng-container>
</div>
<div class="col-sm-3 col-md-3">
	<input class="e-input" revDigitOnlyInput type="text" placeholder="Last 4" [formControl]="last4SsnControl" [attr.data-test-id]="'last4'"/>
</div>

<ng-template #FullSsnInput>
	<ejs-maskedtextbox [formControl]="ssnControl" mask="000-00-0000" [attr.data-test-id]="'fullSsnInput'"></ejs-maskedtextbox>
</ng-template>

<ng-template #FullSsnLookup>
	<div class="e-input-group">
		<input class="e-input" type="text"
			   placeholder="___-__-____"
			   [disabled]="true"
			   [value]="fullSsnDisplay"
			   [attr.data-test-id]="'fullSsnLookupNumberInput'"/>
		<span *ngIf="showSsnLookupButton"
			  class="e-input-group-icon fa fa-pencil"
			  (click)="getPatientSSN()"
			  [attr.data-test-id]="'fullSsnLookupNumberButton'">
		</span>
	</div>
</ng-template>
