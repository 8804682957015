// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.PrintStatementsRequest */
/* istanbul ignore next */
@GandalfModel
export class PrintStatementsRequest extends GandalfModelBase {

	@GandalfProperty()
	dueDateText: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Format is required' } })
	@GandalfProperty({ isRequired: true })
	format: constants.StatementFormat;

	@GandalfProperty()
	messageText: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	printDate: Date;

}
