// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { LegacyAddressResponse } from './legacy-address-response';

// @ts-ignore
import { LegacyPatientNameResponse } from './legacy-patient-name-response';

// @ts-ignore
import { LegacyProviderResponse } from './legacy-provider-response';

/** See com.rev360.legacy.api.controller.patient.LegacyPatientSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class LegacyPatientSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	addressResponse: LegacyAddressResponse;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateOfBirth: Date;

	@GandalfProperty()
	email: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	gender: constants.Gender;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	patientNameResponse: LegacyPatientNameResponse;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	photoId: number;

	@GandalfProperty()
	preferredPhoneNumber: string;

	@GandalfProperty()
	providerResponse: LegacyProviderResponse;

}
