<rev-loading-overlay [loading]="_isLoading">
	<div class="margin-bottom-md text-right">
		<rev-button
			buttonIcon="print"
			buttonStyle="primary"
			buttonLabel="Print"
			revEventStopPropagation
			(buttonClick)="printRemittanceAdvice()"
			dataTestId="printRemittanceAdviceButton">
		</rev-button>
	</div>
	<pms-remittance-advice *ngIf="!_isLoading" [eraResponses]="eraResponses"></pms-remittance-advice>
</rev-loading-overlay>
