/* eslint-disable max-len */
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, CurrencyPipe, DatePipe, PercentPipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { StatefulBaseComponent } from '@app-store/stateful-base.component';
import { BasicInformationFormComponent } from '@shared/component/add-patient/basic-information-form/basic-information-form.component';
import { CommunicationPreferencesFormComponent } from '@shared/component/add-patient/communication-preferences-form/communication-preferences-form.component';
import { ContactDemographicsFormComponent } from '@shared/component/add-patient/contact-demographics-form/contact-demographics-form.component';
import { DuplicatePatientsModalComponent } from '@shared/component/add-patient/duplicate-patients-modal/duplicate-patients-modal.component';
import { FamilyDemographicsFormComponent } from '@shared/component/add-patient/family-demographics-form/family-demographics-form.component';
import { FamilyInformationFormComponent } from '@shared/component/add-patient/family-information-form/family-information-form.component';
import { FamilyPatientInsuranceFormComponent } from '@shared/component/add-patient/family-patient-insurance-form/family-patient-insurance-form.component';
import { OtherInformationFormComponent } from '@shared/component/add-patient/other-information-form/other-information-form.component';
import { PhoneInformationFormComponent } from '@shared/component/add-patient/phone-information-form/phone-information-form.component';
import { QuickAddContactModalComponent } from '@shared/component/add-patient/quick-add-contact-modal/quick-add-contact-modal.component';
import { QuickAddFamilyMemberModalComponent } from '@shared/component/add-patient/quick-add-family-member-modal/quick-add-family-member-modal.component';
import { QuickAddPatientModalComponent } from '@shared/component/add-patient/quick-add-patient-modal/quick-add-patient-modal.component';
import { AdditionalPreferenceLabelComponent } from '@shared/component/additional-preference-label/additional-preference-label.component';
import { AddressDisplayComponent } from '@shared/component/address-display/address-display.component';
import { AddressFormComponent } from '@shared/component/address-form/address-form.component';
import { AlertsModalComponent } from '@shared/component/alerts/alerts-modal.component';
import { AlwaysSetCurrencyInputComponent } from '@shared/component/always-set-currency-input/always-set-currency-input.component';
import { AppointmentReminderButtonLegacyComponent } from '@shared/component/appointment-reminder-button/appointment-reminder-button-legacy.component';
import {
	AppointmentSendMessageConfirmModalLegacyComponent
} from '@shared/component/appointment-reminder-button/appointment-send-message-confirm-modal-legacy.component';
import { AssignCategoriesModalComponent } from '@shared/component/assign-categories-modal/assign-categories-modal.component';
import { BarcodeSearchComponent } from '@shared/component/barcode-search/barcode-search.component';
import {
	ConfirmBarcodeSelectionModalComponent
} from '@shared/component/barcode-search/confirm-barcode-selection-modal/confirm-barcode-selection-modal.component';
import { BaseComponent } from '@shared/component/base.component';
import { BucketComponent } from '@shared/component/bucket/bucket.component';
import { CancelAppointmentModalComponent } from '@shared/component/cancel-appointment-modal/cancel-appointment-modal.component';
import { CancelPrescriptionModalComponent } from '@shared/component/cancel-prescription-modal/cancel-prescription-modal.component';
import { CarePlanTemplateModalComponent } from '@shared/component/care-plan-template-modal/care-plan-template-modal.component';
import { CurrencyCellEditorComponent } from '@shared/component/cell-editors/currency/currency-cell-editor.component';
import { NumericCellEditorComponent } from '@shared/component/cell-editors/numeric/numeric-cell-editor.component';
import { ConfirmedStatusIconComponent } from '@shared/component/confirmed-status-icon/confirmed-status-icon.component';
import { ConstantDropdownComponent } from '@shared/component/constant-dropdown/constant-dropdown.component';
import {
	ContactLensElectronicDeliveryMethodComponent
} from '@shared/component/contact-lens-prescription-details/contact-lens-electronic-delivery-method/contact-lens-electronic-delivery-method.component';
import { ContactLensPrescriptionDetailsComponent } from '@shared/component/contact-lens-prescription-details/contact-lens-prescription-details.component';
import {
	ContactLensPrescriptionLookupModalComponent
} from '@shared/component/contact-lens-prescription-details/contact-lens-prescription-lookup-modal/contact-lens-prescription-lookup-modal.component';
import {
	ContactLensTypeHybridComponent
} from '@shared/component/contact-lens-prescription-details/contact-lens-type-hybrid/contact-lens-type-hybrid.component';
import { ContactLensTypeRgpComponent } from '@shared/component/contact-lens-prescription-details/contact-lens-type-rgp/contact-lens-type-rgp.component';
import { ContactLensTypeSoftComponent } from '@shared/component/contact-lens-prescription-details/contact-lens-type-soft/contact-lens-type-soft.component';
import { CreateEyeglassPrescriptionsModalComponent } from '@shared/component/create-eyeglass-prescriptions-modal/create-eyeglass-prescriptions-modal.component';
import { CreditCardFormComponent } from '@shared/component/credit-card-form/credit-card-form.component';
import { CurrencyInputComponent } from '@shared/component/currency-input/currency-input.component';
import { CarePlanItemHistoryModalComponent } from '@shared/component/diagnosis/care-plan-item-history-modal/care-plan-item-history-modal.component';
import { DiagnosisCodeComponent } from '@shared/component/diagnosis/diagnosis-code/diagnosis-code.component';
import { DiagnosisDetailsModalComponent } from '@shared/component/diagnosis/diagnosis-details-modal/diagnosis-details-modal.component';
import { ResolvePersonDiagnosisModalComponent } from '@shared/component/diagnosis/resolve-person-diagnosis-modal/resolve-person-diagnosis-modal.component';
import { SelectDiagnosisModalComponent } from '@shared/component/diagnosis/select-diagnosis-modal/select-diagnosis-modal.component';
import { UpdatePatientDirectoryModalComponent } from '@shared/component/directory-tree/add-patient-directory/update-patient-directory-modal.component';
import { PatientFileUploadModalComponent } from '@shared/component/directory-tree/file-uploader-modal/patient-file-upload-modal.component';
import { PatientDirectoryTreeComponent } from '@shared/component/directory-tree/patient-directory-tree.component';
import { DropdownComponent } from '@shared/component/dropdown/dropdown.component';
import { DrugSearchComponent } from '@shared/component/drug-search/drug-search.component';
import { DrugSelectionInputComponent } from '@shared/component/drug-selection-input/drug-selection-input.component';
import { EmployeeListWithGroupFilterComponent } from '@shared/component/employee-list-with-group-filter/employee-list-with-group-filter.component';
import { EmployeePersonalDetailsComponent } from '@shared/component/employee-personal-details/employee-personal-details.component';
import { EmployeePreferencesComponent } from '@shared/component/employee-preferences/employee-preferences.component';
import { EncounterSelectByPatientModalComponent } from '@shared/component/encounter-select-by-patient-modal/encounter-select-by-patient-modal.component';
import {
	EncounterSelectByPatientNonCancelledModalComponent
} from '@shared/component/encounter-select-by-patient-non-cancelled-modal/encounter-select-by-patient-non-cancelled-modal.component';
import {
	EncounterSelectByPatientNonCancelledPullForwardModalComponent
} from '@shared/component/encounter-select-by-patient-non-cancelled-pull-forward-modal/encounter-select-by-patient-non-cancelled-pull-forward-modal.component';
import { EncounterStatusIconComponent } from '@shared/component/encounter-status-icon/encounter-status-icon.component';
import { EnumSelectButtonComponent } from '@shared/component/enum-select-button/enum-select-button.component';
import { EyeglassLensRxContainerComponent } from '@shared/component/eyeglass-lens-rx-container/eyeglass-lens-rx-container.component';
import { EyeglassRxAuthorizationComponent } from '@shared/component/eyeglass-lens-rx-container/eyeglass-rx-authorization/eyeglass-rx-authorization.component';
import { EyeglassRxDatesComponent } from '@shared/component/eyeglass-lens-rx-container/eyeglass-rx-dates/eyeglass-rx-dates.component';
import {
	EyeglassRxOtherInformationComponent
} from '@shared/component/eyeglass-lens-rx-container/eyeglass-rx-other-information/eyeglass-rx-other-information.component';
import {
	EyeglassRxRecommendationsComponent
} from '@shared/component/eyeglass-lens-rx-container/eyeglass-rx-recommendations/eyeglass-rx-recommendations.component';
import { EyeglassRxComponent } from '@shared/component/eyeglass-lens-rx-container/eyeglass-rx/eyeglass-rx.component';
import { FileScanModalComponent } from '@shared/component/file-scan-modal/file-scan-modal.component';
import { FileUploadModalComponent } from '@shared/component/file-upload-modal/file-upload-modal.component';
import { FolderFileListComponent } from '@shared/component/folder-file-list/folder-file-list.component';
import { FormSpinnerComponent } from '@shared/component/form-spinner/form-spinner.component';
import { HeaderCellRendererComponent } from '@shared/component/header-cell-renderer/header-cell-renderer.component';
import { ImagePreviewModalComponent } from '@shared/component/image-preview-modal/image-preview-modal.component';
import { IncorporateDocumentModalComponent } from '@shared/component/incorporate-document-modal/incorporate-document-modal.component';
import { InsuranceStatusIconComponent } from '@shared/component/insurance-status-icon/insurance-status-icon.component';
import { AddCompanyModalComponent } from '@shared/component/insurance/add-company/add-company-modal.component';
import { AddInsuranceModalComponent } from '@shared/component/insurance/add-insurance/add-insurance-modal.component';
import {
	InsuranceBasicInformationFormComponent
} from '@shared/component/insurance/insurance-details/basic-information/insurance-basic-information-form.component';
import { GeneralBenefitsFormComponent } from '@shared/component/insurance/insurance-details/general-benefits-form/general-benefits-form.component';
import { IndividualBenefitsFormComponent } from '@shared/component/insurance/insurance-details/individual-benefits-form/individual-benefits-form.component';
import { PolicyHolderModalComponent } from '@shared/component/insurance/policy-holder/policy-holder-modal.component';
import { InterviewStatusIconComponent } from '@shared/component/interview-status-icon/interview-status-icon.component';
import { InventoryReconciliationPrintComponent } from '@shared/component/inventory-reconciliation-print/inventory-reconciliation-print.component';
import { LoadingModalComponent } from '@shared/component/loading-modal/loading-modal.component';
import { MultiselectComponent } from '@shared/component/multiselect/multiselect.component';
import { NoShowAppointmentModalComponent } from '@shared/component/no-show-appointment-modal/no-show-appointment-modal.component';
import { EditNoteModalComponent } from '@shared/component/notes/edit-note-modal/edit-note-modal.component';
import { NotesComponent } from '@shared/component/notes/notes.component';
import { NumberStepperComponent } from '@shared/component/number-stepper/number-stepper.component';
import { ContactLensHistoryTableComponent } from '@shared/component/optical-history/contact-lens-history-table/contact-lens-history-table.component';
import { OpticalHistoryModalComponent } from '@shared/component/optical-history/optical-history-modal/optical-history-modal.component';
import { PanelMenuSubComponent } from '@shared/component/panel-menu/panel-menu-sub.component';
import { PanelMenuComponent } from '@shared/component/panel-menu/panel-menu.component';
import { PatientDocumentsModalComponent } from '@shared/component/patient-documents/patient-documents-modal.component';
import { PatientDocumentsComponent } from '@shared/component/patient-documents/patient-documents.component';
import { PdfViewerModalComponent } from '@shared/component/pdf-viewer-modal/pdf-viewer-modal.component';
import { PermissionEnabledLinkComponent } from '@shared/component/permission-enabled-link/permission-enabled-link.component';
import { PhotoFileTransferComponent } from '@shared/component/photo-file-transfer/photo-file-transfer.component';
import { AgGridPrintTemplateComponent } from '@shared/component/print-templates/ag-grid-print-template/ag-grid-print-template.component';
import { LogoPrintTemplateComponent } from '@shared/component/print-templates/logo-template/logo-print-template.component';
import { ProductSelectionComponent } from '@shared/component/product-selection/product-selection.component';
import { QueryBuilderComponent } from '@shared/component/query/query-builder/query-builder.component';
import { QueryTriggerSearchResultsComponent } from '@shared/component/query/query-builder/query-trigger-search-results/query-trigger-search-results.component';
import { ImportQueriesComponent } from '@shared/component/query/query-dashboard/import-queries.component';
import { QueryDashboardComponent } from '@shared/component/query/query-dashboard/query-dashboard.component';
import { QueryTriggerDashboardComponent } from '@shared/component/query/query-trigger-dashboard/query-trigger-dashboard.component';
import { RadioButtonGroupComponent } from '@shared/component/radio-button-group/radio-button-group.component';
import { RichTextEditorComponent } from '@shared/component/rich-text-editor/rich-text-editor.component';
import { RxSelectModalComponent } from '@shared/component/rx-select-modal/rx-select-modal.component';
import { ScheduleFilterModalComponent } from '@shared/component/schedule-filter-modal/schedule-filter-modal.component';
import { FamilyMemberAddModalComponent } from '@shared/component/search-patient/family-member-add-modal/family-member-add-modal.component';
import { PatientSearchModalComponent } from '@shared/component/search-patient/patient-search-modal/patient-search-modal.component';
import { SearchPatientComponent } from '@shared/component/search-patient/search-patient.component';
import { SelectExternalProviderModalComponent } from '@shared/component/select-external-provider-modal/select-external-provider-modal.component';
import { SelectPdHistoryModalComponent } from '@shared/component/select-pd-history-modal/select-pd-history-modal.component';
import { SelectProviderModalComponent } from '@shared/component/select-provider-modal/select-provider-modal.component';
import { SelectSnomedCodeModalComponent } from '@shared/component/select-snomed-code-modal/select-snomed-code-modal.component';
import { ServiceSelectionComponent } from '@shared/component/service-selection/service-selection.component';
import { SignedStatusIconComponent } from '@shared/component/signed-status-icon/signed-status-icon.component';
import { SocialSecurityNumberInputComponent } from '@shared/component/social-security-number-input/social-security-number-input.component';
import { TooltipCellRendererComponent } from '@shared/component/tooltip-cell-renderer/tooltip-cell-renderer.component';
import { UnsavedChangesComponent } from '@shared/component/unsaved-changes/unsaved-changes.component';
import { UpdateFileModalComponent } from '@shared/component/update-file-modal/update-file-modal.component';
import { UploadDocumentToFolderModalComponent } from '@shared/component/upload-document-to-folder-modal/upload-document-to-folder-modal.component';
import { WaitlistAddModalComponent } from '@shared/component/waitlist-add-modal/waitlist-add-modal.component';
import { WebcamModalComponent } from '@shared/component/webcam-modal/webcam-modal.component';
import { AutoFormatDateDirective } from '@shared/directives/auto-format-date.directive';
import { CanadaOnlyDirective } from '@shared/directives/canada-only/canada-only.directive';
import { CapitalizeFirstLetterDirective } from '@shared/directives/capitalize-first-letter/capitalize-first-letter.directive';
import { ConvertZeroToNullEjsNumericDirective } from '@shared/directives/convert-zero-to-null-ejs-numeric/convert-zero-to-null-ejs-numeric.directive';
import { ConvertZeroToNullDirective } from '@shared/directives/convert-zero-to-null/convert-zero-to-null.directive';
import { MorganaModule } from 'morgana';
import { AutoSelectDirective } from '@shared/directives/dropdownlist/auto-select.directive';
import { MultiSelectionFieldDirective } from '@shared/directives/field/multi-selection-field/multi-selection-field.directive';
import { AgAutoFitColumnsDirective } from '@shared/directives/grid/ag-auto-fit-columns/ag-auto-fit-columns.directive';
import { AlignRightCurrencyDirective } from '@shared/directives/grid/align-right-currency/align-right-currency.directive';
import { AutoFitColumnsDirective } from '@shared/directives/grid/auto-fit-columns/auto-fit-columns.directive';
import { GridResizeDirective } from '@shared/directives/grid/grid-resize/grid-resize.directive';
import { InputSpecialKeyHandlerDirective } from '@shared/directives/grid/input-tab-key-handler/input-special-key-handler.directive';
import { NormalizeEmptyStringsDirective } from '@shared/directives/grid/normalize-empty/normalize-empty-strings.directive';
import { AgGridSubmitDirective } from '@shared/directives/grid/submit/ag-grid-submit.directive';
import { GridSubmitDirective } from '@shared/directives/grid/submit/grid-submit.directive';
import { MaskedInputDirective } from '@shared/directives/input-mask/masked-input.directive';
import { MultiselectFilteringDirective } from '@shared/directives/multiselect-dropdownlist/multiselect-filtering.directive';
import { MultiselectTooltipDirective } from '@shared/directives/multiselect-dropdownlist/tooltip/multiselect-tooltip.directive';
import { SinInputDirective } from '@shared/directives/sin-input/sin-input.directive';
import { StatefulTabDirective } from '@shared/directives/stateful-tab/stateful-tab.directive';
import { TabDefaultsDirective } from '@shared/directives/tabs/tab-defaults.directive';
import { ThrottledClickDirective } from '@shared/directives/throttled-click.directive';
import { TimepickerFormatterDirective } from '@shared/directives/timepicker-formatter/timepicker-formatter.directive';
import { UsaOnlyDirective } from '@shared/directives/usa-only/usa-only.directive';
import { ValidationCssDirective } from '@shared/directives/validation/validation-css.directive';
import { ValidationScrollDirective } from '@shared/directives/validation/validation-scroll.directive';
import { BaseCurvePipe } from '@shared/pipes/base-curve/base-curve.pipe';
import { BusinessNamePipe } from '@shared/pipes/business-name/business-name.pipe';
import { CanadaLabelPipe } from '@shared/pipes/canada-label/canada-label.pipe';
import { DiagnosisLocationPipe } from '@shared/pipes/diagnosis/diagnosis-location.pipe';
import { EncounterDiagnosisLocationPipe } from '@shared/pipes/diagnosis/encounter-diagnosis-location/encounter-diagnosis-location.pipe';
import { FileIconPipe } from '@shared/pipes/file-icon/file-icon.pipe';
import { FileSizePipe } from '@shared/pipes/file-size/file-size.pipe';
import { ForceDecimalNullSafePipe } from '@shared/pipes/force-decimal-null-safe/force-decimal-null-safe.pipe';
import { HexToIntColorPipe } from '@shared/pipes/hex-to-int-color/hex-to-int-color.pipe';
import { NumberCustomFormatterPipe } from '@shared/pipes/number-custom-formatter/number-custom-formatter.pipe';
import { OxfordCommaPipe } from '@shared/pipes/oxford-comma/oxford-comma.pipe';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import { PersonNameTooltipPipe } from '@shared/pipes/patient-name/person-name-tooltip.pipe';
import { PlusMinusNoRoundingPipe } from '@shared/pipes/plus-minus-no-rounding/plus-minus-no-rounding.pipe';
import { PlusMinusPipe } from '@shared/pipes/plus-minus/plus-minus.pipe';
import { PracticeLocationNamePipe } from '@shared/pipes/practice-location-name/practice-location-name.pipe';
import { PrintableOnlyPipe } from '@shared/pipes/printable-only/printable-only.pipe';
import { SafeHtmlPipe } from '@shared/pipes/sanitize-html/sanitize-html.pipe';
import { SecondsToFormattedTimePipe } from '@shared/pipes/seconds-to-formatted-time/seconds-to-formatted-time.pipe';
import { StreetAddressPipe } from '@shared/pipes/street-address/street-address.pipe';
import { AutoCompleteModule, CheckBoxSelectionService as SyncfusionCheckboxSelectionService } from '@syncfusion/ej2-angular-dropdowns';
import {
	FilterService as SyncfusionFilterService,
	GroupService as SyncfusionGroupService,
	PageService as SyncfusionPageService,
	SelectionService as SyncfusionSelectionService,
	SortService as SyncfusionSortService
} from '@syncfusion/ej2-angular-grids';
import { ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { AgGridModule } from 'ag-grid-angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { WebcamModule } from 'ngx-webcam';
import { PatientEstablishedPipe } from '@shared/pipes/patient-established/patient-established.pipe';
import { InsurancePolicyPriorityTypePipe } from '@shared/pipes/insurance-policy-priority-type/insurance-policy-priority-type.pipe';
import { WeekPipe } from '@shared/pipes/week/week.pipe';
import { AppointmentReminderButtonComponent } from '@shared/component/appointment-reminder-button/appointment-reminder-button.component';
import { PatientPopoverAgGridComponent } from '@shared/component/patient-popover/patient-popover-ag-grid.component';
import { PatientPopoverComponent } from '@shared/component/patient-popover/patient-popover.component';
import {
	PatientSchedulingPreferencePopoverComponent
} from '@shared/component/patient-scheduling-preference-popover/patient-scheduling-preference-popover.component';
import { ContactInformationComponent } from '@shared/component/contact-information/contact-information.component';
import {
	FamilyMemberPhoneInformationFormComponent
} from '@shared/component/add-patient/family-member-phone-information-form/family-member-phone-information-form.component';
import { LaunchSmartApplicationModalComponent } from '@shared/component/encounter-launch-smart-application-modal/launch-smart-application-modal.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { TextEditorComponent } from '@shared/component/text-editor/text-editor.component';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import { DatabaseIdsPipe } from '@shared/pipes/database-ids/database-ids.pipe';
import { CamelCasePipe } from '@shared/pipes/camel-case/camel-case.pipe';
import { AdvancedCategoryDetailComponent } from '../admin/core/category/advanced-category-detail/advanced-category-detail.component';
import { CategoryDetailComponent } from '../admin/core/category/category-detail/category-detail.component';
import { CategoryListComponent } from '../admin/core/category/category-list/category-list.component';
import { StandardCategoriesComponent } from '../admin/core/category/standard-categories.component';
import { SubCategoryModalComponent } from '../admin/core/category/sub-category-modal/sub-category-modal.component';
import { ReferenceDataDetailComponent } from '../admin/core/reference-data/reference-data-detail/reference-data-detail.component';
import { ReferenceDataListComponent } from '../admin/core/reference-data/reference-data-list/reference-data-list.component';
import { StandardReferenceDataComponent } from '../admin/core/reference-data/standard-reference-data.component';
import {
	LocationConfigConfirmDeleteComponent
} from '../admin/core/vendors-and-partners/location-config/location-config-list/location-config-confirm-delete.component';
import { LocationConfigModalComponent } from '../admin/core/vendors-and-partners/location-config/location-config-modal/location-config-modal.component';
import { DiagnosisDetailComponent } from '../admin/data-configuration/diagnosis/diagnosis-detail/diagnosis-detail.component';
import { DiagnosisListComponent } from '../admin/data-configuration/diagnosis/diagnosis-list/diagnosis-list.component';
import { SnomedDiagnosisAddModalComponent } from '../admin/data-configuration/diagnosis/modals/snomed-diagnosis-add-modal/snomed-diagnosis-add-modal.component';
import {
	CommonMedicationsDrugSearchModalComponent
} from '../admin/data-configuration/medications/common-medications/common-medications-drug-search-modal/common-medications-drug-search-modal.component';
import { LogoUploadComponent } from '../admin/general/locations/locations-detail/location-detail-tab/logo-upload.component.ts/logo-upload.component';
import { LocationTaxModalComponent } from '../admin/general/locations/locations-detail/location-taxes/location-tax-modal/location-tax-modal.component';
import {
	VoidInsuranceRefundModalComponent
} from '../admin/vendors-partners/insurance-companies/insurance-companies-detail/insurance-company-history/refund-history/refund-history-comments-modal.component';
import {
	ProductBrandCollectionModalComponent
} from '../admin/vendors-partners/manufacturer/manufacturer-detail/product-brand/product-brand-collection-modal/product-brand-collection-modal.component';
import {
	ProductBrandModalComponent
} from '../admin/vendors-partners/manufacturer/manufacturer-detail/product-brand/product-brand-modal/product-brand-modal.component';
import {
	LabOrderLookupModalComponent
} from '../patients/orders-medical/orders-medical-details/medical-information/lab-order-lookup/lab-order-lookup-modal/lab-order-lookup-modal.component';
import { LabOrderLookupComponent } from '../patients/orders-medical/orders-medical-details/medical-information/lab-order-lookup/lab-order-lookup.component';
import { AlertSeverityClassPipe } from '../patients/shared/pipes/alert-severity-class.pipe';
import { AddressLabelModalComponent } from '../reports/reports-patients/address-label-modal/address-label-modal.component';
import { RevClearTemplateComponent } from '../admin/core/solutions/rev-clear/rev-clear-template.component';
import {
	RevClearExternalCredentialModalComponent
} from '../admin/core/solutions/rev-clear/rev-clear-external-credential-modal/rev-clear-external-credential-modal.component';
import { TrizettoTemplateComponent } from '../admin/core/vendors-and-partners/trizetto/trizetto-template.component';
import {
	TrizettoExternalCredentialModalComponent
} from '../admin/core/vendors-and-partners/trizetto/trizetto-external-credential-modal/trizetto-external-credential-modal.component';
import {
	AutopostingConfigurationRevClearTemplateComponent
} from '../admin/core/solutions/autoposting-configuration-rev-clear/autoposting-configuration-rev-clear-template.component';
import {
	AutopostingConfigurationRevClearExternalCredentialModalComponent
} from '../admin/core/solutions/autoposting-configuration-rev-clear/autoposting-configuration-rev-clear-external-credential-modal/autoposting-configuration-rev-clear-external-credential-modal.component';
import { AppWrapperComponent } from './component/app-wrapper/app-wrapper.component';
import { ClinicalSummaryComponent } from './component/clinical-summary/clinical-summary.component';
import { CommentModalComponent } from './component/comment-modal/comment-modal.component';
import { InsuranceVerificationModalComponent } from './component/insurance-verification-modal/insurance-verification-modal.component';
import { InsuranceCompanyInformationComponent } from './component/insurance/company-details/insurance-company-information.component';
import { LabelPrintingComponent } from './component/label-printing/label-printing.component';
import { LocationSelectModalComponent } from './component/location-select-modal/location-select-modal.component';
import { NoAccessComponent } from './component/no-access/no-access.component';
import { OpenEdgeSaveCardModalComponent } from './component/open-edge-save-card-modal/open-edge-save-card-modal.component';
import { PhoneNumberInputComponent } from './component/phone-number-input/phone-number-input.component';
import { BasicPrintTemplateComponent } from './component/print-templates/basic-template/basic-print-template.component';
import { StartAppointmentModalComponent } from './component/start-appointment-modal/start-appointment-modal.component';
import { EmployeeNamePipe } from './pipes/employee-dropdown-name/employee-name.pipe';
import { FormattedNamePipe } from './pipes/formatted-name/formatted-name.pipe';
import { FormattedSingleLineAddressPipe } from './pipes/formatted-single-line-address/formatted-single-line-address.pipe';
import { IntToHexColorPipe } from './pipes/int-to-hex-color/int-to-hex-color.pipe';
import { NavIconPipe } from './pipes/nav-icon/nav-icon.pipe';
import { PadAxisPipe } from './pipes/pad-axis/pad-axis.pipe';
import { PersonAgePipe } from './pipes/person-age/person-age.pipe';
import { PersonNamePipe } from './pipes/person-name/person-name.pipe';
import { PersonPhotoPipe } from './pipes/person-photo/person-photo.pipe';
import { PhoneExtensionPipe } from './pipes/phone-extension/phone-extension.pipe';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { ProviderNamePipe } from './pipes/provider-name/provider-name.pipe';
import { RelativeDateDisplayPipe } from './pipes/relative-date-display/relative-date-display.pipe';
import { SsnPipe } from './pipes/ssn/ssn.pipe';
import { TextProperCasePipe } from './pipes/text-proper-case/text-proper-case.pipe';
import { TextTruncatePipe } from './pipes/text-truncate/text-truncate.pipe';
import { YearsDiffPipe } from './pipes/years-diff/years-diff.pipe';

@NgModule({
	declarations: [
		AddressLabelModalComponent,
		PadAxisPipe,
		OxfordCommaPipe,
		AlertSeverityClassPipe,
		YearsDiffPipe,
		PhoneExtensionPipe,
		TextProperCasePipe,
		TextTruncatePipe,
		PhonePipe,
		SsnPipe,
		FormattedSingleLineAddressPipe,
		StreetAddressPipe,
		PersonAgePipe,
		PersonNamePipe,
		PersonPhotoPipe,
		RelativeDateDisplayPipe,
		SafeHtmlPipe,
		NavIconPipe,
		AppWrapperComponent,
		NoAccessComponent,
		EmployeeNamePipe,
		ProviderNamePipe,
		CanadaLabelPipe,
		ClinicalSummaryComponent,
		DrugSearchComponent,
		AlertsModalComponent,
		IntToHexColorPipe,
		HexToIntColorPipe,
		FormattedNamePipe,
		PatientNamePipe,
		BusinessNamePipe,
		PersonNameTooltipPipe,
		DiagnosisLocationPipe,
		EncounterDiagnosisLocationPipe,
		AddressDisplayComponent,
		AddressFormComponent,
		EnumSelectButtonComponent,
		LabelPrintingComponent,
		ConfirmedStatusIconComponent,
		EncounterSelectByPatientModalComponent,
		EncounterSelectByPatientNonCancelledModalComponent,
		EncounterSelectByPatientNonCancelledPullForwardModalComponent,
		EncounterStatusIconComponent,
		SignedStatusIconComponent,
		InsuranceStatusIconComponent,
		InsuranceCompanyInformationComponent,
		InterviewStatusIconComponent,
		BaseComponent,
		StatefulBaseComponent,
		StatefulTabDirective,
		ConstantDropdownComponent,
		PanelMenuComponent,
		PanelMenuSubComponent,
		CurrencyInputComponent,
		AlwaysSetCurrencyInputComponent,
		PhoneNumberInputComponent,
		InsuranceVerificationModalComponent,
		AutoFitColumnsDirective,
		MultiSelectionFieldDirective,
		NormalizeEmptyStringsDirective,
		AlignRightCurrencyDirective,
		SinInputDirective,
		LocationConfigModalComponent,
		LocationTaxModalComponent,
		LocationConfigConfirmDeleteComponent,
		PermissionEnabledLinkComponent,
		GridSubmitDirective,
		TimepickerFormatterDirective,
		QuickAddPatientModalComponent,
		BasicInformationFormComponent,
		PatientSearchModalComponent,
		AutoSelectDirective,
		ProductBrandModalComponent,
		ProductBrandCollectionModalComponent,
		QueryDashboardComponent,
		QueryTriggerDashboardComponent,
		ImportQueriesComponent,
		QueryBuilderComponent,
		QueryTriggerSearchResultsComponent,
		SearchPatientComponent,
		GridResizeDirective,
		OtherInformationFormComponent,
		PhoneInformationFormComponent,
		MultiselectTooltipDirective,
		CommunicationPreferencesFormComponent,
		QuickAddFamilyMemberModalComponent,
		FamilyDemographicsFormComponent,
		FamilyInformationFormComponent,
		FamilyPatientInsuranceFormComponent,
		AddInsuranceModalComponent,
		InsuranceBasicInformationFormComponent,
		GeneralBenefitsFormComponent,
		IndividualBenefitsFormComponent,
		PatientDocumentsComponent,
		PatientDocumentsModalComponent,
		FileUploadModalComponent,
		AddCompanyModalComponent,
		PolicyHolderModalComponent,
		SelectPdHistoryModalComponent,
		SelectProviderModalComponent,
		SelectExternalProviderModalComponent,
		SelectSnomedCodeModalComponent,
		StandardCategoriesComponent,
		CategoryListComponent,
		StandardReferenceDataComponent,
		ReferenceDataListComponent,
		ReferenceDataDetailComponent,
		DiagnosisListComponent,
		DiagnosisDetailComponent,
		DuplicatePatientsModalComponent,
		VoidInsuranceRefundModalComponent,
		CommentModalComponent,
		MaskedInputDirective,
		ContactDemographicsFormComponent,
		QuickAddContactModalComponent,
		ContactInformationComponent,
		CategoryDetailComponent,
		AdvancedCategoryDetailComponent,
		SubCategoryModalComponent,
		FamilyMemberAddModalComponent,
		FileScanModalComponent,
		ValidationCssDirective,
		ValidationScrollDirective,
		RxSelectModalComponent,
		ThrottledClickDirective,
		LocationSelectModalComponent,
		BarcodeSearchComponent,
		ConfirmBarcodeSelectionModalComponent,
		ProductSelectionComponent,
		ServiceSelectionComponent,
		PlusMinusPipe,
		BucketComponent,
		DrugSelectionInputComponent,
		NotesComponent,
		EditNoteModalComponent,
		ContactLensPrescriptionDetailsComponent,
		BaseCurvePipe,
		ForceDecimalNullSafePipe,
		ContactLensTypeSoftComponent,
		ContactLensTypeRgpComponent,
		ContactLensTypeHybridComponent,
		PlusMinusNoRoundingPipe,
		ContactLensPrescriptionLookupModalComponent,
		EyeglassLensRxContainerComponent,
		EyeglassRxComponent,
		EyeglassRxAuthorizationComponent,
		EyeglassRxDatesComponent,
		EyeglassRxOtherInformationComponent,
		EyeglassRxRecommendationsComponent,
		NumberCustomFormatterPipe,
		NumberStepperComponent,
		StartAppointmentModalComponent,
		NoShowAppointmentModalComponent,
		AppointmentReminderButtonLegacyComponent,
		AppointmentSendMessageConfirmModalLegacyComponent,
		CancelAppointmentModalComponent,
		CarePlanTemplateModalComponent,
		CommonMedicationsDrugSearchModalComponent,
		SnomedDiagnosisAddModalComponent,
		SocialSecurityNumberInputComponent,
		LabOrderLookupComponent,
		LabOrderLookupModalComponent,
		MultiselectFilteringDirective,
		InputSpecialKeyHandlerDirective,
		FileSizePipe,
		FileIconPipe,
		ImagePreviewModalComponent,
		DiagnosisDetailsModalComponent,
		ResolvePersonDiagnosisModalComponent,
		SelectDiagnosisModalComponent,
		FormSpinnerComponent,
		CanadaOnlyDirective,
		UsaOnlyDirective,
		ConvertZeroToNullDirective,
		ConvertZeroToNullEjsNumericDirective,
		CarePlanItemHistoryModalComponent,
		PhotoFileTransferComponent,
		WebcamModalComponent,
		UploadDocumentToFolderModalComponent,
		OpticalHistoryModalComponent,
		CreateEyeglassPrescriptionsModalComponent,
		ContactLensHistoryTableComponent,
		CancelPrescriptionModalComponent,
		LogoUploadComponent,
		PatientDirectoryTreeComponent,
		RichTextEditorComponent,
		FolderFileListComponent,
		UpdateFileModalComponent,
		PracticeLocationNamePipe,
		UpdatePatientDirectoryModalComponent,
		PatientFileUploadModalComponent,
		DiagnosisCodeComponent,
		IncorporateDocumentModalComponent,
		CapitalizeFirstLetterDirective,
		PrintableOnlyPipe,
		EmployeePersonalDetailsComponent,
		EmployeePreferencesComponent,
		AdditionalPreferenceLabelComponent,
		ScheduleFilterModalComponent,
		ContactLensElectronicDeliveryMethodComponent,
		EmployeeListWithGroupFilterComponent,
		UnsavedChangesComponent,
		MultiselectComponent,
		DropdownComponent,
		AssignCategoriesModalComponent,
		TooltipCellRendererComponent,
		CurrencyCellEditorComponent,
		NumericCellEditorComponent,
		AgAutoFitColumnsDirective,
		SecondsToFormattedTimePipe,
		AgGridSubmitDirective,
		PdfViewerModalComponent,
		RadioButtonGroupComponent,
		BasicPrintTemplateComponent,
		LogoPrintTemplateComponent,
		AgGridPrintTemplateComponent,
		TabDefaultsDirective,
		CreditCardFormComponent,
		HeaderCellRendererComponent,
		OpenEdgeSaveCardModalComponent,
		LoadingModalComponent,
		AutoFormatDateDirective,
		InventoryReconciliationPrintComponent,
		WaitlistAddModalComponent,
		PatientEstablishedPipe,
		InsurancePolicyPriorityTypePipe,
		WeekPipe,
		AppointmentReminderButtonComponent,
		PatientPopoverAgGridComponent,
		PatientPopoverComponent,
		PatientSchedulingPreferencePopoverComponent,
		FamilyMemberPhoneInformationFormComponent,
		LaunchSmartApplicationModalComponent,
		RevClearTemplateComponent,
		RevClearExternalCredentialModalComponent,
		AutopostingConfigurationRevClearTemplateComponent,
		AutopostingConfigurationRevClearExternalCredentialModalComponent,
		TrizettoTemplateComponent,
		TrizettoExternalCredentialModalComponent,
		TextEditorComponent,
		DatabaseIdsPipe,
		CamelCasePipe,
	],
	imports: [
		RouterModule,
		MorganaModule,
		ContextMenuModule,
		AutoCompleteModule,
		WebcamModule,
		ClipboardModule,
		// eslint-disable-next-line deprecation/deprecation
		MatSelectModule,
		// eslint-disable-next-line deprecation/deprecation
		MatCheckboxModule,
		// eslint-disable-next-line deprecation/deprecation
		MatListModule,
		MatTreeModule,
		MatBadgeModule,
		NgxExtendedPdfViewerModule,
		FroalaEditorModule,
	],
	exports: [
		MorganaModule,
		PadAxisPipe,
		OxfordCommaPipe,
		AlertSeverityClassPipe,
		YearsDiffPipe,
		PhonePipe,
		PhoneExtensionPipe,
		TextProperCasePipe,
		TextTruncatePipe,
		SsnPipe,
		FormattedSingleLineAddressPipe,
		StreetAddressPipe,
		PersonAgePipe,
		PersonNamePipe,
		PersonPhotoPipe,
		RelativeDateDisplayPipe,
		SafeHtmlPipe,
		ProviderNamePipe,
		PersonNameTooltipPipe,
		NavIconPipe,
		CommonModule,
		AppWrapperComponent,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
		NoAccessComponent,
		ClinicalSummaryComponent,
		DrugSearchComponent,
		LocationSelectModalComponent,
		AlertsModalComponent,
		EnumSelectButtonComponent,
		PanelMenuComponent,
		PanelMenuSubComponent,
		EmployeeNamePipe,
		IntToHexColorPipe,
		FormattedNamePipe,
		PatientNamePipe,
		BusinessNamePipe,
		DiagnosisLocationPipe,
		EncounterDiagnosisLocationPipe,
		AddressDisplayComponent,
		AddressFormComponent,
		LabelPrintingComponent,
		ConfirmedStatusIconComponent,
		EncounterSelectByPatientModalComponent,
		EncounterSelectByPatientNonCancelledModalComponent,
		EncounterSelectByPatientNonCancelledPullForwardModalComponent,
		EncounterStatusIconComponent,
		SignedStatusIconComponent,
		InsuranceStatusIconComponent,
		InsuranceCompanyInformationComponent,
		InterviewStatusIconComponent,
		BaseComponent,
		StatefulBaseComponent,
		StatefulTabDirective,
		AutoCompleteModule,
		ConstantDropdownComponent,
		CurrencyInputComponent,
		AlwaysSetCurrencyInputComponent,
		PhoneNumberInputComponent,
		AutoFitColumnsDirective,
		MultiSelectionFieldDirective,
		NormalizeEmptyStringsDirective,
		InsuranceVerificationModalComponent,
		AlignRightCurrencyDirective,
		SinInputDirective,
		PermissionEnabledLinkComponent,
		TimepickerFormatterDirective,
		BasicInformationFormComponent,
		GridSubmitDirective,
		QueryDashboardComponent,
		QueryTriggerDashboardComponent,
		ImportQueriesComponent,
		QueryBuilderComponent,
		QueryTriggerSearchResultsComponent,
		AutoSelectDirective,
		SearchPatientComponent,
		GridResizeDirective,
		MultiselectTooltipDirective,
		OtherInformationFormComponent,
		PhoneInformationFormComponent,
		QuickAddFamilyMemberModalComponent,
		AddInsuranceModalComponent,
		InsuranceBasicInformationFormComponent,
		GeneralBenefitsFormComponent,
		IndividualBenefitsFormComponent,
		PatientDocumentsComponent,
		PatientDocumentsModalComponent,
		FileUploadModalComponent,
		PolicyHolderModalComponent,
		CommunicationPreferencesFormComponent,
		SelectPdHistoryModalComponent,
		SelectProviderModalComponent,
		SelectExternalProviderModalComponent,
		SelectSnomedCodeModalComponent,
		StandardCategoriesComponent,
		CategoryListComponent,
		StandardReferenceDataComponent,
		ReferenceDataListComponent,
		ReferenceDataDetailComponent,
		DiagnosisListComponent,
		DiagnosisDetailComponent,
		CommunicationPreferencesFormComponent,
		DuplicatePatientsModalComponent,
		VoidInsuranceRefundModalComponent,
		CommentModalComponent,
		MaskedInputDirective,
		FamilyDemographicsFormComponent,
		FamilyInformationFormComponent,
		FamilyPatientInsuranceFormComponent,
		ContactDemographicsFormComponent,
		QuickAddContactModalComponent,
		ContactInformationComponent,
		AddCompanyModalComponent,
		CategoryDetailComponent,
		AdvancedCategoryDetailComponent,
		SubCategoryModalComponent,
		FileScanModalComponent,
		FamilyMemberAddModalComponent,
		ValidationCssDirective,
		ValidationScrollDirective,
		RxSelectModalComponent,
		ThrottledClickDirective,
		BarcodeSearchComponent,
		ProductSelectionComponent,
		BucketComponent,
		ServiceSelectionComponent,
		PlusMinusPipe,
		DrugSelectionInputComponent,
		NotesComponent,
		EditNoteModalComponent,
		ContactLensPrescriptionDetailsComponent,
		BaseCurvePipe,
		ForceDecimalNullSafePipe,
		ContactLensTypeSoftComponent,
		ContactLensTypeRgpComponent,
		ContactLensTypeHybridComponent,
		PlusMinusNoRoundingPipe,
		ContactLensPrescriptionLookupModalComponent,
		EyeglassLensRxContainerComponent,
		StartAppointmentModalComponent,
		NumberCustomFormatterPipe,
		NumberStepperComponent,
		NoShowAppointmentModalComponent,
		AppointmentReminderButtonLegacyComponent,
		AppointmentSendMessageConfirmModalLegacyComponent,
		CancelAppointmentModalComponent,
		CarePlanTemplateModalComponent,
		CommonMedicationsDrugSearchModalComponent,
		SnomedDiagnosisAddModalComponent,
		SocialSecurityNumberInputComponent,
		LabOrderLookupComponent,
		LabOrderLookupModalComponent,
		MultiselectFilteringDirective,
		InputSpecialKeyHandlerDirective,
		FileSizePipe,
		FileIconPipe,
		ImagePreviewModalComponent,
		DiagnosisDetailsModalComponent,
		ResolvePersonDiagnosisModalComponent,
		SelectDiagnosisModalComponent,
		FormSpinnerComponent,
		CanadaOnlyDirective,
		UsaOnlyDirective,
		ConvertZeroToNullDirective,
		ConvertZeroToNullEjsNumericDirective,
		CarePlanItemHistoryModalComponent,
		PhotoFileTransferComponent,
		WebcamModalComponent,
		UploadDocumentToFolderModalComponent,
		OpticalHistoryModalComponent,
		CreateEyeglassPrescriptionsModalComponent,
		ContactLensHistoryTableComponent,
		CancelPrescriptionModalComponent,
		LogoUploadComponent,
		PatientDirectoryTreeComponent,
		RichTextEditorComponent,
		CanadaLabelPipe,
		FolderFileListComponent,
		PracticeLocationNamePipe,
		CapitalizeFirstLetterDirective,
		PrintableOnlyPipe,
		EmployeePersonalDetailsComponent,
		EmployeePreferencesComponent,
		AdditionalPreferenceLabelComponent,
		ScheduleFilterModalComponent,
		EmployeeListWithGroupFilterComponent,
		ClipboardModule,
		UnsavedChangesComponent,
		MultiselectComponent,
		DropdownComponent,
		AssignCategoriesModalComponent,
		AgGridModule,
		TooltipCellRendererComponent,
		CurrencyCellEditorComponent,
		NumericCellEditorComponent,
		AgAutoFitColumnsDirective,
		SecondsToFormattedTimePipe,
		AgGridSubmitDirective,
		PdfViewerModalComponent,
		RadioButtonGroupComponent,
		BasicPrintTemplateComponent,
		LogoPrintTemplateComponent,
		AgGridPrintTemplateComponent,
		TabDefaultsDirective,
		CreditCardFormComponent,
		HeaderCellRendererComponent,
		OpenEdgeSaveCardModalComponent,
		LoadingModalComponent,
		AutoFormatDateDirective,
		InventoryReconciliationPrintComponent,
		WaitlistAddModalComponent,
		PatientEstablishedPipe,
		InsurancePolicyPriorityTypePipe,
		WeekPipe,
		AppointmentReminderButtonComponent,
		LaunchSmartApplicationModalComponent,
		DiagnosisCodeComponent,
		// eslint-disable-next-line deprecation/deprecation
		MatCheckboxModule,
		// eslint-disable-next-line deprecation/deprecation
		MatListModule,
		MatTreeModule,
		MatBadgeModule,
		RevClearTemplateComponent,
		RevClearExternalCredentialModalComponent,
		AutopostingConfigurationRevClearTemplateComponent,
		AutopostingConfigurationRevClearExternalCredentialModalComponent,
		TrizettoTemplateComponent,
		TrizettoExternalCredentialModalComponent,
		PatientPopoverAgGridComponent,
		PatientPopoverComponent,
		TextEditorComponent,
		CamelCasePipe,
	],
	providers: [
		DatePipe,
		AlertSeverityClassPipe,
		PercentPipe,
		CurrencyPipe,
		ProviderNamePipe,
		IntToHexColorPipe,
		FormattedNamePipe,
		PadAxisPipe,
		OxfordCommaPipe,
		PersonAgePipe,
		PersonNamePipe,
		PatientNamePipe,
		BusinessNamePipe,
		PersonNameTooltipPipe,
		PhonePipe,
		PlusMinusPipe,
		SsnPipe,
		SyncfusionSelectionService,
		SyncfusionPageService,
		SyncfusionSortService,
		SyncfusionFilterService,
		SyncfusionGroupService,
		SyncfusionCheckboxSelectionService,
		BaseCurvePipe,
		ForceDecimalNullSafePipe,
		PlusMinusNoRoundingPipe,
		NumberCustomFormatterPipe,
		DiagnosisLocationPipe,
		EncounterDiagnosisLocationPipe,
		TitleCasePipe,
		CanadaLabelPipe,
		PracticeLocationNamePipe,
		PatientPopoverAgGridComponent,
		PatientPopoverComponent,
		PatientSchedulingPreferencePopoverComponent,
	],
})
export class SharedModule {
}
