// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.document.CreatePatientDocumentRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientDocumentRequest extends GandalfModelBase {

	@GandalfProperty()
	encounterId: number;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfProperty()
	recipientId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	recipientType: constants.PatientDocumentRecipientType;

	@GandalfValidator({ notNull: { message: '{javax.validation.constraints.NotNull.message}' } })
	@GandalfValidator({ sizeString: { min: 1, max: 255, message: '{javax.validation.constraints.Size.message}' } })
	@GandalfProperty({ isRequired: true })
	saveToFolder: string;

	@GandalfValidator({ notNull: { message: 'Template is required' } })
	@GandalfProperty({ isRequired: true })
	templateId: number;

}
