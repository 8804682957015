<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-sm"
		[attr.data-test-id]="'transferCollectionsModal'">
	<ng-template #header>
		<div class="dlg-template">Transfer Invoice</div>
	</ng-template>
		<ng-template #content>
			<form #templateForm="ngForm" [formGroup]="componentForm" class="form-horizontal" (ngSubmit)="save()">
				<gandalf-lib-validation-messages
					*ngIf="componentForm.invalid && templateForm.submitted"
					[form]="componentForm"
					[requestObj]="transferInvoiceBalanceToPatientRequest"
					[attr.data-test-id]="'transferCollectionsValidationMessages'">
				</gandalf-lib-validation-messages>
				<p>Enter the transfer reason:</p>
				<div class="form-group">
					<label class="control-label required col-sm-4">Comments</label>
					<div class="col-sm-8">
						<ejs-textbox class="h-rows-5" [multiline]="true" formControlName="reason"></ejs-textbox>
					</div>
				</div>
			</form>
		</ng-template>
		<ng-template #footerTemplate>
			<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'transferCollectionsModalCancelButton'">Cancel</button>
			<button type="button" ejs-button [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'transferCollectionsModalSaveButton'">Save</button>
		</ng-template>
</ejs-dialog>
