import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingOverlayMethod, ModalBase } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PatientDocumentResponse } from '@gandalf/model/patient-document-response';
import { Observable } from 'rxjs';
import { TextEditorComponent } from '@shared/component/text-editor/text-editor.component';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { UpdatePatientDocumentContentRequest } from '@gandalf/model/update-patient-document-content-request';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { ToasterService } from '@core/toaster/toaster.service';
import { LetterEditorDockableModalService } from './letter-editor-dockable-modal.service';

export interface LetterEditorDockableModalData {
	patientDocumentId: number;
	existingTask: boolean;
}

@Component({
	selector: 'pms-letter-editor-dockable-modal',
	templateUrl: './letter-editor-dockable-modal.component.html',
	styles: [],
})
export class LetterEditorDockableModalComponent extends ModalBase<LetterEditorDockableModalData, boolean> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('textEditor')
	textEditor: TextEditorComponent;

	patientDocumentResponse: PatientDocumentResponse;
	maximized = true;

	_isLoading: boolean;
	froalaDataLoading = true;

	constructor(
		private letterEditorDockableModalService: LetterEditorDockableModalService,
		private eventsManagerService: EventsManagerService,
		private toasterService: ToasterService,
	) {
		super();
	}

	ngOnInit(): void {
		this.getData();
	}

	getData(): void {
		this.getPatientDocument().subscribe(response => {
			this.patientDocumentResponse = response;
			this.froalaDataLoading = false;
		});
	}

	@LoadingOverlayMethod()
	getPatientDocument(): Observable<PatientDocumentResponse> {
		return this.letterEditorDockableModalService.getPatientDocument(this.data.patientDocumentId);
	}

	closeModal(): void {
		this.dynamicModalRef.close(this.modal, null);
	}

	maximize(): void {
		this.modal.position = {X: 'right', Y: 'top'};
		this.modal.height = '93%';
		this.maximized = true;
	}

	minimize(): void {
		this.modal.position = {X: 'right', Y: 'bottom'};
		this.modal.height = 44;
		this.maximized = false;
	}

	updatePatientDocument(): void {
		const request = new UpdatePatientDocumentContentRequest();
		request.patientDocumentId = this.patientDocumentResponse.patientDocumentId;
		request.content = this.textEditor.content;
		this.letterEditorDockableModalService.updatePatientDocumentContent(request).subscribe(() => {
			this.saveTaskLogic();
		});
	}

	saveTaskLogic() {
		if (!this.data.existingTask) {
			this.saveAsTask();
		} else {
			this.toasterService.showSavedSuccess();
		}
	}

	saveAsTask(): void {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.TASKS.TASKS_MODULE_ADD_DRAFT_TASK, {
			patientId: this.patientDocumentResponse.patientId,
			useModal: true,
			lockPatient: true,
			notifyOnCompletionChecked: true,
			notifyOnUpdateChecked: true,
			disableCategorySelect: true,
			defaultTaskCategory: this.patientDocumentResponse.categoryId,
			disablePatientSelect: true,
			patientDocumentId: this.patientDocumentResponse.patientDocumentId,
			onCreateTask: (() => this.closeModal()),
		});
	}

}
