// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { InvoiceItemPaymentRequest } from './invoice-item-payment-request';

// @ts-ignore
import { ReceivePaymentTransferInvoiceItemsRequest } from './receive-payment-transfer-invoice-items-request';

/** See com.rev360.legacy.api.controller.accounting.CreatePaymentRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePaymentRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount: number;

	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

	@GandalfArray(InvoiceItemPaymentRequest)
	paymentItems: InvoiceItemPaymentRequest[];

	@GandalfArray(ReceivePaymentTransferInvoiceItemsRequest)
	paymentTransfers: ReceivePaymentTransferInvoiceItemsRequest[];

}
