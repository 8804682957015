<ng-container *ngIf="personalFormGroup" [formGroup]="personalFormGroup">
	<div class="row">
		<div class="col-sm-4 col-lg-2">
			<h4 class="section-title bg-default mrgn-btm">Photograph</h4>
			<pms-photo-file-transfer
				formControlName="photoId"
				[folderId]="employeeFolder"
				[uploadUrl]="uploadUrl"
				[fileName]="uploadName"
			></pms-photo-file-transfer>
		</div>
		<div class="col-sm-8 col-lg-10">
			<div class="row">
				<div class="col-lg-6">
					<h4 class="section-title bg-default mrgn-btm">Personal Details</h4>
					<div class="form-group">
						<label class="col-sm-4 control-label">Title</label>
						<div class="col-sm-8">
							<ejs-dropdownlist
								formControlName="titleId"
								placeholder="Select Title"
								[dataSource]="titles">
							</ejs-dropdownlist>
						</div>
					</div>
					<div [attr.data-test-id]="'firstNameGroup'" class="form-group">
						<label class="col-sm-4 control-label required">First Name</label>
						<div class="col-sm-8">
							<input type="text" class="e-input" formControlName="firstName" pmsCapitalizeFirstLetter/>
						</div>
					</div>

					<div [attr.data-test-id]="'lastNameGroup'" class="form-group">
						<label class="col-sm-4 control-label required">Last Name</label>
						<div class="col-sm-8">
							<input type="text" class="e-input" formControlName="lastName" pmsCapitalizeFirstLetter/>
						</div>
					</div>

					<div class="form-group">
						<label class="col-sm-4 control-label">Middle Name</label>
						<div class="col-sm-8">
							<input type="text" class="e-input" formControlName="middleName" pmsCapitalizeFirstLetter/>
						</div>
					</div>

					<div class="form-group">
						<label class="col-sm-4 control-label">Suffix</label>
						<div class="col-sm-5">
							<input type="text" class="e-input" formControlName="suffix"/>
						</div>
						<div class="col-sm-3">
							<p class="form-control-static">(e.g. Jr, Sr)</p>
						</div>
					</div>

					<div class="form-group">
						<label class="col-sm-4 control-label">Credentials</label>
						<div class="col-sm-5">
							<input type="text" class="e-input" formControlName="credentials"/>
						</div>
						<div class="col-sm-3">
							<p class="form-control-static">(e.g. OD, MD)</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Nickname</label>
						<div class="col-sm-8">
							<input type="text" class="e-input" formControlName="nickName" pmsCapitalizeFirstLetter/>
						</div>
				</div>

				<div *pmsUsaOnly class="form-group">
					<label class="col-sm-4 control-label">SSN</label>
					<div class="col-sm-8">
						<ejs-maskedtextbox
							mask="000-00-0000"
							formControlName="ssn">
						</ejs-maskedtextbox>
					</div>
				</div>

				<div *pmsCanadaOnly class="form-group">
					<label class="col-sm-4 control-label">SIN</label>
					<div class="col-sm-8">
						<input pmsSinInput class="e-input" type="text" formControlName="sin"/>
					</div>
				</div>

					<div class="form-group">
						<label class="col-sm-4 control-label">Date of Birth</label>
						<div class="col-sm-8">
							<ejs-datepicker formControlName="dateOfBirth" placeholder="mm/dd/yyyy" [format]="dateFormat"></ejs-datepicker>
						</div>
					</div>

					<div class="form-group">
						<label class="col-sm-4 control-label">Sex</label>
						<div class="col-sm-8">
							<pms-enum-select-button formControlName="gender" [enumList]="genders"></pms-enum-select-button>
						</div>
					</div>

				</div>

				<div class="col-lg-6">
					<h4 class="section-title bg-default mrgn-btm">Address</h4>
					<pms-address-form [parentFormGroup]="addressFormGroup"
									  [isCreating]="isCreating"
									  [autoselectStateProvince]="true"></pms-address-form>

					<h4 class="section-title bg-default mrgn-btm">Phone/Email</h4>

					<div class="form-group">
						<label class="col-sm-4 control-label">Preferred Phone</label>
						<div class="col-sm-8">
							<pms-enum-select-button formControlName="preferredPhoneType" [enumList]="preferredPhone"></pms-enum-select-button>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Home</label>
						<div class="col-sm-8">
							<pms-phone-number-input formControlName="homePhone"></pms-phone-number-input>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Work</label>
						<div class="col-sm-8">
							<pms-phone-number-input formControlName="workPhone"></pms-phone-number-input>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Cell</label>
						<div class="col-sm-8">
							<pms-phone-number-input formControlName="cellPhone"></pms-phone-number-input>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Pager</label>
						<div class="col-sm-8">
							<pms-phone-number-input formControlName="pager"></pms-phone-number-input>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Email</label>
						<div class="col-sm-8">
							<input type="email" class="e-input" formControlName="email"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
