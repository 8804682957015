import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'pmsSafeHtml'})
@Injectable({
	providedIn: 'root',
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private domSanitizer: DomSanitizer) {
	}

	/**
	 * Runs the Angular sanitizers for scripts, styles, URLs,
	 * and resource URLs, and marks the HTML as good to go.
	 * @param value The HTML string you want sanitized
	 */
	transform(value: string) {
		return this.domSanitizer.bypassSecurityTrustHtml(value);
	}
}
