import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { DynamicModalRef, GridUtil, ModalConfig } from 'morgana';
import { _find } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { InvoiceDiagnosesRequest } from '@gandalf/model/invoice-diagnoses-request';
import { CodeSet } from '@gandalf/constants';
import { PersonDiagnosisResponse } from '@gandalf/model/person-diagnosis-response';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GridComponent, RowDataBoundEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { GandalfFormBuilder } from 'gandalf';
import { BACKGROUND_COLOR_CONSTANTS } from '@shared/constants/background-colors.constants';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-diagnoses-modal',
	templateUrl: './diagnoses-modal.component.html',
	styles: [],
})
export class DiagnosesModalComponent extends BaseComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('availableDiagnosesGrid')
	availableDiagnosesGrid: GridComponent;

	@ViewChild('selectedDiagnosesGrid')
	selectedDiagnosesGrid: GridComponent;

	@ViewChild('selectedDiagnosesAgGrid')
	selectedDiagnosesAgGrid: AgGridAngular;

	@ViewChild('availableDiagnosesAgGrid')
	availableDiagnosesAgGrid: AgGridAngular;

	@ViewChild('diagnosesCodeColumnTemplate')
	diagnosesCodeColumnTemplate: TemplateRef<any>;

	@ViewChild('diagnosesRemoveButtonColumnTemplate')
	diagnosesRemoveButtonColumnTemplate: TemplateRef<any>;

	@ViewChild('diagnosesAddButtonColumnTemplate')
	diagnosesAddButtonColumnTemplate: TemplateRef<any>;

	@ViewChild('templateForm')
	templateForm: NgForm;

	invoiceId: number;
	selectedDiagnoses: PersonDiagnosisResponse[] = [];
	availableDiagnoses: PersonDiagnosisResponse[] = [];
	disableForm = true;
	isSearching = true;
	sortQuery: Query;
	invoiceDiagnosesRequest: InvoiceDiagnosesRequest;
	componentForm: UntypedFormGroup;
	openedFromInvoiceDetailsModal: boolean;
	agGridFlagOn = false;
	icd9FilterFlagOn = false;
	isCanada = false;
	gridIcd9Filter = false;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	selectedDxGridOptions: GridOptions = GridUtil.buildGridOptions({
		rowClass: 'row-link',
		getRowClass: params =>  this.applyBackgroundColorAgGrid(params),
	});
	availableDxGridOptions: GridOptions = GridUtil.buildGridOptions({
		rowClass: 'row-link',
		getRowClass: params => this.applyBackgroundColorAgGrid(params),
	});
	selectedDiagnosesGridColumns: ColDef[];
	availableDiagnosesGridColumns: ColDef[];

	constructor(
		public ref: DynamicModalRef,
		public invoiceService: InvoiceService,
		public modalConfig: ModalConfig,
		public accountingService: AccountingService,
		private gandalfFormBuilder: GandalfFormBuilder,
		public featureService: FeatureService,
		private securityManagerService: SecurityManagerService,
	) {
		super();
	}

	ngOnInit() {
		this.isCanada = this.securityManagerService.isCanada();
		this.icd9FilterFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.DIAGNOSES.ICD9_FILTER);
		this.agGridFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.INVOICE.DIAGNOSIS.AG_GRID);
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.openedFromInvoiceDetailsModal = this.modalConfig.data.openedFromInvoiceDetailsModal;
		this.invoiceDiagnosesRequest = this.prepareInitialRequest();
		this.requestData();
		this.sortQuery = new Query().sortBy('masterCodeSetId').sortBy('Id');
		this.initializeForm(this.invoiceDiagnosesRequest);
	}

	showIcd9Filter() {
		return !this.isCanada && this.icd9FilterFlagOn;
	}

	updateInvoiceDetailsModalInvoice() {
		if (this.openedFromInvoiceDetailsModal) {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoiceId);
		}
	}

	prepareInitialRequest(): InvoiceDiagnosesRequest {
		const request = new InvoiceDiagnosesRequest();
		request.invoiceId = this.invoiceId;
		return request;
	}

	requestData() {
		this.disableForm = true;
		this.accountingService.findAvailableDiagnosesByInvoiceId(this.invoiceId).subscribe((data) => {
			this.availableDiagnoses = data;
			this.disableForm = false;
			this.isSearching = false;
		});
	}

	initializeForm(request) {
		this.componentForm = this.gandalfFormBuilder.group(request);
	}

	/**
	 * Uses SyncFusion api to add a row to the specified grid.
	 */
	addToSelected(data: any) {
		if (!_find(this.selectedDiagnoses, diagnosis => diagnosis.id === data.id)) {
			if (!this.agGridFlagOn) {
				this.selectedDiagnosesGrid.addRecord(data);
			} else {
				this.selectedDiagnoses.push(data);
				this.selectedDiagnosesAgGrid.api.setRowData(this.selectedDiagnoses);
			}
			this.updateForm();
		}
	}

	/**
	 * Uses SyncFusion api to remove a row to the specified grid.
	 */
	removeFromSelected(data: any) {
		if (!this.agGridFlagOn) {
			this.selectedDiagnosesGrid.deleteRecord('id', data);
		} else {
			const posision = this.selectedDiagnoses.map((item) => item['id']).indexOf(data['id']);
			this.selectedDiagnoses.splice(posision, 1);
			this.selectedDiagnosesAgGrid.api.setRowData(this.selectedDiagnoses);
		}
		this.updateForm();
	}

	updateForm() {
		this.componentForm.get('personDiagnosisIds').setValue(this.selectedDiagnoses.map(diagnosis => diagnosis.id));
	}

	/**
	 * Called when a grid item is doubleclicked in the view. Since the cursor isn't removed from the item we first need to close the tooltip
	 */
	availableDiagnosesGridDoubleclick(event: any) {
		if (event.rowData) {
			(this.availableDiagnosesGrid as any).toolTipObj.close();
			this.addToSelected(event.rowData);
		} else if (event.data) {
			this.addToSelected(event.data);
		}
	}

	/**
	 * Called when a grid item is doubleclicked in the view. Since the cursor isn't removed from the item we first need to close the tooltip
	 */
	selectedDiagnosesGridDoubleclick(event: any) {
		if (event.rowData) {
			(this.selectedDiagnosesGrid as any).toolTipObj.close();
			this.removeFromSelected(event.rowData);
		} else if (event.data) {
			this.removeFromSelected(event.data);
		}
	}

	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	/**
	 * Used Gandalf the Black to call the legacy java code to update the selected diagnoses. Once complete
	 * the invoice is refreshed with the updated data and the modal is closed.
	 * Updates invoiceDetailsModalInvoice observable if modal was opened from invoiceDetails modal.
	 */
	saveDiagnosesAndRefreshInvoice() {
		if (this.componentForm.invalid) {
			return;
		}

		const request = this.componentForm.value as InvoiceDiagnosesRequest;
		this.accountingService.updateInvoiceDiagnosis(request).subscribe(() => {
			this.invoiceService.refreshInvoice(this.invoiceId);
			this.updateInvoiceDetailsModalInvoice();
			this.closeModal();
		});
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	/**
	 * Used to determine if a diagnosis is ICD10 and disabled.  If it is the tooltip in the view read differently.
	 */
	isCodeIcd10AndDisabled(personDiagnosis: PersonDiagnosisResponse) {
		return personDiagnosis.practiceDiagnosis.masterCodeSetId === CodeSet.ICD10.value && !personDiagnosis.practiceDiagnosis.active;
	}

	/**
	 * Used to determine if a diagnosis is ICD10 and disabled.  If it is this sets the background color of the row in the grid.
	 */
	applyBackgroundColor(args: RowDataBoundEventArgs) {
		if (this.isCodeIcd10AndDisabled(args.data as PersonDiagnosisResponse)) {
			args.row.classList.add(BACKGROUND_COLOR_CONSTANTS.DANGER);
		}
	}

	applyBackgroundColorAgGrid(params: any) {
		if (this.isCodeIcd10AndDisabled(params.node.data as PersonDiagnosisResponse)) {
			return BACKGROUND_COLOR_CONSTANTS.DANGER;
		}
	}

	filterAvailableDiagnosesAgGrid() {
		GridUtil.applyFilter(this.availableDiagnosesAgGrid, this.applyCodeSetFilter, 'practiceDiagnosisCodeSet', CodeSet.ICD9.label, 'notEqual');
	}

	applyCodeSetFilter = () => this.showIcd9Filter() && !this.gridIcd9Filter;

	onSelectedDiagnosesAgGridReady(_params: GridReadyEvent) {
		this.buildSelectedDiagnosesGridColumns();
		this.selectedDiagnosesAgGrid.api.setColumnDefs(this.selectedDiagnosesGridColumns);
	}

	onAvailableDiagnosesAgGridReady(_params: GridReadyEvent) {
		this.buildAvailableDiagnosesGridColumns();
		this.availableDiagnosesAgGrid.api.setColumnDefs(this.availableDiagnosesGridColumns);
	}

	buildSelectedDiagnosesGridColumns() {
		this.selectedDiagnosesGridColumns = [
			GridUtil.buildTemplateColumn('Selected Diagnoses', 'id', this.diagnosesCodeColumnTemplate, {
				sortable: false,
				resizable: false,
				flex: 1,
			}),
			GridUtil.buildButtonColumn('', this.diagnosesRemoveButtonColumnTemplate, {
				sortable: false,
				resizable: false,
				width: 45,
			}),
		];
	}

	buildAvailableDiagnosesGridColumns() {
		this.availableDiagnosesGridColumns = [
			GridUtil.buildTemplateColumn('Available Diagnoses', 'id', this.diagnosesCodeColumnTemplate, {
				sortable: false,
				resizable: false,
				flex: 1,
			}),
			GridUtil.buildButtonColumn('', this.diagnosesAddButtonColumnTemplate, {
				sortable: false,
				resizable: false,
				width: 45,
			}),
			GridUtil.buildColumn('Code Set', 'practiceDiagnosis.codeSet', {
				colId: 'practiceDiagnosisCodeSet',
				hide: true,
			}),
		];

	}
}
