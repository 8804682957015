<ejs-dialog
	#modal
	(close)="closeModal()"
	[attr.data-test-id]="'uploadDocumentModal'">
	<ng-template #header>
		<div class="dlg-template" [attr.data-test-id]="'uploadDocumentModalTitle'">Upload File(s)</div>
	</ng-template>
	<ng-template #content>
		<div *ngIf="errorMessage" class="alert alert-danger">
			{{errorMessage}}
		</div>
		<div #uploadDropArea [attr.data-test-id]="'uploadModalDropPanel'">
			<ejs-uploader
					#uploader
					*ngIf="fileUploadSettings"
					[asyncSettings]="fileUploadSettings"
					[multiple]="true"
					[maxFileSize]="maxFileUploadSize"
					[buttons]="{browse: 'Browse...'}"
					[dropArea]="uploadDropArea"
					(success)="uploadSuccess($event, uploader)"
					(selected)="filesSelected($event)"
			>
			</ejs-uploader>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'uploadModalCloseButton'">Cancel</button>
	</ng-template>

</ejs-dialog>
