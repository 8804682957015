// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { LegacyReferenceDataResponse } from './legacy-reference-data-response';

/** See com.rev360.legacy.api.controller.patient.LegacyPatientNameResponse */
/* istanbul ignore next */
@GandalfModel
export class LegacyPatientNameResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	activeStatus: constants.PatientStatus;

	@GandalfProperty()
	credentials: string;

	@GandalfProperty()
	firstName: string;

	@GandalfProperty()
	lastName: string;

	@GandalfProperty()
	middleName: string;

	@GandalfProperty()
	nickname: string;

	@GandalfProperty()
	patientId: number;

	@GandalfProperty()
	personId: number;

	@GandalfProperty()
	pronunciation: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.EstablishedStatus;

	@GandalfProperty()
	suffix: string;

	@GandalfProperty()
	title: LegacyReferenceDataResponse;

}
