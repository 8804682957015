// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.InvoiceItemResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceItemResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentTotal: number;

	@GandalfProperty({ propertyType: 'Money' })
	amountPaid: number;

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfProperty()
	code: string;

	@GandalfProperty()
	description: string;

	@GandalfProperty({ propertyType: 'Money' })
	discountTotal: number;

	@GandalfProperty({ propertyType: 'Money' })
	extendedPrice: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty()
	responsiblePersonId: number;

	@GandalfProperty()
	split: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InvoiceItemStatus;

	@GandalfProperty({ propertyType: 'Money' })
	taxAmount: number;

	@GandalfProperty()
	taxAppliedId: number;

	@GandalfProperty()
	taxRate: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.InvoiceItemType;

	@GandalfProperty({ propertyType: 'Money' })
	unitPrice: number;

	@GandalfProperty()
	wasSplit: boolean;

}
