// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.TransferPatientCreditRequest */
/* istanbul ignore next */
@GandalfModel
export class TransferPatientCreditRequest extends GandalfModelBase {

	@GandalfLabel('Amount')
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount: number;

	@GandalfLabel('Destination Patient')
	@GandalfValidator({ notNull: { message: 'Destination Patient is required' } })
	@GandalfProperty({ isRequired: true })
	destinationPatientId: number;

	@GandalfLabel('Source Patient')
	@GandalfValidator({ notNull: { message: 'Source Patient is required' } })
	@GandalfProperty({ isRequired: true })
	sourcePatientId: number;

}
