// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.patient.LegacyAddressResponse */
/* istanbul ignore next */
@GandalfModel
export class LegacyAddressResponse extends GandalfModelBase {

	@GandalfProperty()
	address1: string;

	@GandalfProperty()
	address2: string;

	@GandalfProperty()
	city: string;

	@GandalfProperty()
	postalCode: string;

	@GandalfProperty()
	stateProvince: string;

}
