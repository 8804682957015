// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.PaymentManualInvoiceSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PaymentManualInvoiceSearchRequest extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfLabel('Approval')
	@GandalfProperty()
	authorized: constants.InvoiceSearchAuthorization;

	@GandalfLabel('Claim Submit Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDateEnd: Date;

	@GandalfLabel('Claim Submit Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDateStart: Date;

	@GandalfLabel('Claim #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Claim # may not be more than 18 digits' } })
	@GandalfProperty()
	claimId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Invoice Age')
	@GandalfProperty()
	invoiceAge: constants.InvoiceSearchAgeRange;

	@GandalfLabel('Invoice Age End')
	@GandalfValidator({ max: { max: 9999, message: 'Invoice Age End may not be more than 4 digits' } })
	@GandalfProperty()
	invoiceAgeCustomEnd: number;

	@GandalfLabel('Invoice Age Start')
	@GandalfValidator({ max: { max: 9999, message: 'Invoice Age Start may not be more than 4 digits' } })
	@GandalfProperty()
	invoiceAgeCustomStart: number;

	@GandalfLabel('Invoice Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDateEnd: Date;

	@GandalfLabel('Invoice Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDateStart: Date;

	@GandalfLabel('Invoice #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Invoice # may not be more than 18 digits' } })
	@GandalfProperty()
	invoiceId: number;

	@GandalfLabel('Location')
	@GandalfProperty()
	locationId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Origin')
	@GandalfProperty()
	origin: constants.InvoiceSearchOrigin;

	@GandalfLabel('Patient #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Patient # may not be more than 18 digits' } })
	@GandalfProperty()
	patientId: number;

	@GandalfLabel('Patient Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Patient Name', maxLength: 1000, minLength: 0, message: 'Patient Name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	patientLastName: string;

	@GandalfLabel('Insurance Type')
	@GandalfProperty()
	payerInsuranceType: number;

	@GandalfLabel('Payer Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Payer Name', maxLength: 1000, minLength: 0, message: 'Payer Name must be less than or equal to 1000 characters' } })
	@GandalfValidator({ notNull: { message: 'Payer Name is required' } })
	@GandalfProperty({ isRequired: true })
	payerName: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Payer Type')
	@GandalfValidator({ notNull: { message: 'Payer Type is required' } })
	@GandalfProperty({ isRequired: true })
	payerType: constants.PayerType;

	@GandalfLabel('Stmt. Print Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	printDateEnd: Date;

	@GandalfLabel('Stmt. Print Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	printDateStart: Date;

	@GandalfLabel('Provider')
	@GandalfProperty()
	providerId: number;

	@GandalfLabel('Service Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDateEnd: Date;

	@GandalfLabel('Service Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDateStart: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('Status')
	@GandalfProperty()
	status: constants.InvoiceSearchStatus;

}
