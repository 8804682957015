import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { DynamicModalRef, EnumUtil, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _filter, _includes, _isNull } from '@core/lodash/lodash';
import { ProviderService } from '@core/provider/provider.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { PaymentManualInvoiceSearchRequest } from '@gandalf/model/payment-manual-invoice-search-request';
import { InvoiceSearchAuthorization, InvoiceSearchStatus, PayerType, ReferenceDataMasterCategory } from '@gandalf/constants';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { AggregateRowModel, GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { InvoiceDashboardResponse } from '@gandalf/model/invoice-dashboard-response';
import { AccountingService, TableFormattedInvoiceSummary } from '../../core/accounting/accounting.service';

@Component({
	selector: 'pms-select-invoice-modal',
	templateUrl: './select-invoice-modal.component.html',
	styles: [],
})
export class SelectInvoiceModalComponent extends BaseComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('grid')
	gridComponent: GridComponent;

	formGroup: UntypedFormGroup;
	isSearching = false;
	locationId: number;
	payerType: PayerType;
	payerName: string;
	aggregates: AggregateRowModel[];
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	dateFormatter = {type: 'date', format: DATE_FORMATS.MM_DD_YYYY};
	locations: OptionItemResponse<PracticeLocation, number>[];
	providers = [];
	filteredInvoices: TableFormattedInvoiceSummary[] = [];
	checkedInvoices: InvoiceDashboardResponse[] = [];
	invoiceSearchRequest: PaymentManualInvoiceSearchRequest;
	payerOptions = [PayerType.PATIENT, PayerType.INSURANCE, PayerType.ANONYMOUS, PayerType.COLLECTIONS];
	payerInsuranceTypes: ReferenceDataResponse[];
	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};
	private invoiceIds: number[];
	private outputSubject: Subject<InvoiceDashboardResponse[]>;
	private invoiceList: TableFormattedInvoiceSummary[];

	constructor(
		private ref: DynamicModalRef,
		public modalConfig: ModalConfig,
		private userLocationsService: UserLocationsService,
		public gandalfFormBuilder: GandalfFormBuilder,
		public providerService: ProviderService,
		public accountingService: AccountingService,
		public referenceDataService: ReferenceDataService,
	) {
		super();
	}

	ngOnInit() {
		this.parseInputs(this.modalConfig.data);
		this.setupAggregates();
		this.getData();
		this.buildForm();
	}

	parseInputs(data: any) {
		this.locationId = data.locationId;
		this.payerType = data.payer.type;
		this.payerName = data.payer.name;
		this.invoiceIds = data.invoiceIds;
		this.outputSubject = data.outputSubject;
	}

	setupAggregates() {
		this.aggregates = [{
			columns: [
				{
					type: 'Sum',
					field: 'invoice.balance',
					format: 'C2',
					footerTemplate: '${Sum}',
				},
			],
		}];
	}

	getData() {
		this.locations = this.userLocationsService.getUserActiveLocations();
		combineLatest([
			this.providerService.findPracticeProvidersForDropdown(),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.INSURANCE_TYPE.value),
		]).subscribe(([providers, insuranceTypes]) => {
			this.handleDataResults(providers, insuranceTypes);
		});
	}

	handleDataResults(providers, insuranceTypes) {
		this.providers = providers;
		this.payerInsuranceTypes = insuranceTypes;
	}

	buildForm() {
		this.formGroup = this.createFormGroup();
		this.formGroup.addControl('payerName', new UntypedFormControl());
		this.formGroup.addControl('status', new UntypedFormControl());
		this.formGroup.addControl('authorized', new UntypedFormControl());
	}

	createFormGroup() {
		this.invoiceSearchRequest = new PaymentManualInvoiceSearchRequest();
		const formGroup = this.gandalfFormBuilder.group(this.invoiceSearchRequest);
		formGroup.get('payerType').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
			this.handlePayerType(value, formGroup);
		});
		this.setInitialFormValues(formGroup);
		return formGroup;
	}

	setInitialFormValues(formGroup) {
		formGroup.get('payerType').setValue(this.payerType);
		formGroup.get('locationId').setValue(this.locationId);
		formGroup.get('payerName').setValue(this.payerName);
		formGroup.get('status').setValue(InvoiceSearchStatus.ACTIVE);
		formGroup.get('authorized').setValue(InvoiceSearchAuthorization.AUTHORIZED);

		if (this.locationId !== 0) { // Only disable if a location was provided
			FormUtilsService.disabledWhen(formGroup.get('locationId'), !_isNull(formGroup.get('locationId').value));
		}
		FormUtilsService.disabledWhen(formGroup.get('payerType'), !_isNull(formGroup.get('payerType').value));
	}

	handlePayerType(value: number, formGroup: UntypedFormGroup) {
		const control = formGroup.get('payerInsuranceType');
		FormUtilsService.disabledWhen(control, value !== PayerType.INSURANCE.value);
		if (control.disabled) {
			control.setValue(null);
		}
	}

	submitForm() {
		if (this.formGroup.invalid) {
			return;
		}
		this.isSearching = true;
		this.accountingService.searchInvoicesForManualSelection(this.formGroup.getRawValue()).subscribe(invoices => {
			this.handleSearchResults(invoices);
		});
	}

	handleSearchResults(invoices) {
		this.invoiceList = invoices;
		this.filterInvoices();
		this.isSearching = false;
	}

	filterInvoices(triggerTableRefresh = false) {
		this.filteredInvoices = _filter(this.invoiceList, (invoice: TableFormattedInvoiceSummary) => !_includes(this.invoiceIds, invoice.invoice.id));
		if (triggerTableRefresh) {
			this.gridComponent.refreshColumns();
		}
	}

	clear(templateForm: FormGroupDirective) {
		templateForm.resetForm({});
		this.setInitialFormValues(this.formGroup);
		this.filteredInvoices = [];
	}

	isGuestPayer(data: InvoiceDashboardResponse) {
		return EnumUtil.equals(data.payerType, PayerType.ANONYMOUS);
	}

	updateCheckedInvoices(_event) {
		this.checkedInvoices = [];
		this.gridComponent.getSelectedRecords().forEach(invoice => {
			const selectedInvoice = invoice as TableFormattedInvoiceSummary;
			this.checkedInvoices.push(selectedInvoice.invoice);
		});
	}

	addInvoice(event) {
		this.addInvoices([event.rowData.invoice]);
	}

	addInvoices(invoices: InvoiceDashboardResponse[]) {
		this.invoiceIds = [...this.invoiceIds, ...invoices.map(invoice => invoice.id)];
		const invoiceOutput = this.invoiceList
			.filter(invoice => _includes(this.invoiceIds, invoice.invoice.id))
			.map(formattedInvoice => formattedInvoice.invoice);
		this.outputSubject.next(invoiceOutput);

		this.removeInvoiceFromGrid();
	}

	removeInvoiceFromGrid() {
		this.filterInvoices(true);
		this.gridComponent.clearSelection();
		this.checkedInvoices = [];
	}

	addSelectedInvoices() {
		this.addInvoices(this.checkedInvoices);
	}

	/* istanbul ignore next */
	closeModal() {
		this.ref.close(this.modal);
	}
}
