import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { APP_ROUTING_CONSTANTS } from '../../app-routing.constants';
import { FEATURE_FLAGS } from '../feature/feature.constants';
import { FeatureService } from '../feature/feature.service';
import { LegacyNavigationService } from './legacy-navigation.service';

export const canActivateLegacyGuard: CanActivateFn = async (
	next: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot,
): Promise<boolean> => {
	const featureService = inject(FeatureService);
	const router = inject(Router);
	const legacyNavigationService = inject(LegacyNavigationService);
	const pathKey = next.data.key;
	const legacyPath = 'legacy';

	let primaryContext;
	let subContext = [];
	let hideLegacy = true;
	switch (pathKey) {
		case APP_ROUTING_CONSTANTS.ACCOUNTING.INVOICES:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.ACCOUNTING.INVOICES)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'accounting', 'invoices']);
			}
			primaryContext = 'accounting';
			break;
		case APP_ROUTING_CONSTANTS.ACCOUNTING.CLAIMS:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.ACCOUNTING.CLAIMS)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'accounting', 'claims']);
			}
			primaryContext = 'accounting';
			break;
		case APP_ROUTING_CONSTANTS.ACCOUNTING.PAYMENTS:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.ACCOUNTING.PAYMENTS)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'accounting', 'payments']);
			}
			primaryContext = 'accounting';
			break;
		case APP_ROUTING_CONSTANTS.CONNECT.DASHBOARD:
			hideLegacy = false;
			await router.navigate([legacyPath, 'connect']);
			break;
		case APP_ROUTING_CONSTANTS.CONNECT.CAMPAIGN:
			primaryContext = 'connect';
			subContext = ['connectCampaign'];
			hideLegacy = true;
			break;
		case APP_ROUTING_CONSTANTS.CONNECT.TWO_WAY_TEXT:
			if (featureService.isFeatureOff(FEATURE_FLAGS.FEATURES.REVCONNECT.TWO_WAY_TEXT)) {
				return false;
			}
			primaryContext = 'connect';
			subContext = ['connectTwoWayText'];
			hideLegacy = true;
			break;
		case APP_ROUTING_CONSTANTS.HOME:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.HOME)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'home']);
			}
			primaryContext = 'home';
			break;
		case APP_ROUTING_CONSTANTS.INVENTORY.PRODUCTS:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.INVENTORY.PRODUCTS)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'inventory', 'products']);
			}
			primaryContext = 'inventory';
			subContext = ['products'];
			break;
		case APP_ROUTING_CONSTANTS.INVENTORY.SERVICES:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.INVENTORY.SERVICES)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'inventory', 'services']);
			}
			primaryContext = 'inventory';
			subContext = ['services'];
			break;
		case APP_ROUTING_CONSTANTS.INVENTORY.TRANSFERS:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.INVENTORY.TRANSFERS)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'inventory', 'transfers']);
			}
			primaryContext = 'inventory';
			subContext = ['transfers'];
			break;
		case APP_ROUTING_CONSTANTS.INVENTORY.RECONCILIATION:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.INVENTORY.RECONCILIATION)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'inventory', 'reconciliation']);
			}
			primaryContext = 'inventory';
			subContext = ['reconciliation'];
			break;
		case APP_ROUTING_CONSTANTS.INVENTORY.VSP:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.INVENTORY.VSP)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'inventory', 'vsp']);
			}
			primaryContext = 'inventory';
			subContext = ['vsp'];
			break;
		case APP_ROUTING_CONSTANTS.MESSAGING:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.MESSAGING)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'messaging']);
			}
			primaryContext = 'messaging';
			break;
		case APP_ROUTING_CONSTANTS.ORDERS:
			if (featureService.isFeatureOn(FEATURE_FLAGS.MODULES.ORDERS) ||
					featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ORDERS.DASHBOARD)) {
				primaryContext = 'orders';
			} else {
				hideLegacy = false;
				await router.navigate([legacyPath, 'orders']);
			}
			break;
		case APP_ROUTING_CONSTANTS.LEGACY_ORDERS:
			primaryContext = 'orders';
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.ORDERS)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'orders']);
			}
			break;
		case APP_ROUTING_CONSTANTS.PATIENTS:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.PATIENTS)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'patients']);
			}
			primaryContext = 'patients';
			break;
		case APP_ROUTING_CONSTANTS.TASKS:
			if (featureService.isFeatureOff(FEATURE_FLAGS.MODULES.TASKS)) {
				hideLegacy = false;
				await router.navigate([legacyPath, 'tasks']);
			}
			primaryContext = 'tasks';
			break;
		default:
			hideLegacy = false;
			break;
	}

	if (hideLegacy) {
		legacyNavigationService.hideLegacy(primaryContext, subContext);
	}
	return true;
};
