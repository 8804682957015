import { createSelector } from '@ngrx/store';
import { selectPatientsState } from '@patients-store/reducers';

export const selectPatientSearchState =
	createSelector(selectPatientsState, state => state.patientSearchState);

export const selectPatientSimpleSearchFormState =
	createSelector(selectPatientsState, state => state.patientSearchState.simpleSearchForm.formState);

export const selectPatientAdvancedSearchFormState =
	createSelector(selectPatientsState, state => state.patientSearchState.advancedSearchForm.formState);

export const selectPatientLastSearchedFormState =
	createSelector(selectPatientsState, state => state.patientSearchState.lastSearchedForm.formState);

export const selectPatientSearchTableState =
	createSelector(selectPatientsState, state => state.patientSearchState.tableState);

export const selectPatientSearchTableConfigState =
	createSelector(selectPatientsState, state => state.patientSearchState.tableState.tableConfigurationJson);

export const selectPatientSearchTableResultsState =
	createSelector(selectPatientsState, state => state.patientSearchState.tableState.tableResults);
