// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.CreateRefundPatientRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRefundPatientRequest extends GandalfModelBase {

	@GandalfLabel('Amount')
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount: number;

	@GandalfLabel('Invoice')
	@GandalfProperty()
	invoiceId: number;

	@GandalfLabel('Location')
	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId: number;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId: number;

	@GandalfLabel('Payment Transaction')
	@GandalfProperty()
	paymentTransactionId: number;

	@GandalfLabel('Description')
	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	reason: string;

	@GandalfLabel('Reference Number')
	@GandalfValidator({ sizeString: { message: 'Reference Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	referenceNumber: string;

	@GandalfLabel('Refund Date')
	@GandalfValidator({ notNull: { message: 'Refund Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	refundDate: Date;

	@GandalfConstantDecorator()
	@GandalfLabel('Method')
	@GandalfValidator({ notNull: { message: 'Method is required' } })
	@GandalfProperty({ isRequired: true })
	refundFrom: constants.RefundFrom;

}
