// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.SearchPaymentGroupRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchPaymentGroupRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfValidator({ sizeString: { fieldLabel: 'Payer Name', maxLength: 1000, minLength: 0, message: 'Payer Name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	payerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentMethodType: constants.PaymentMethodType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentSearchStatus: constants.PaymentSearchStatus;

	@GandalfProperty()
	practiceLocationId: number;

	@GandalfValidator({ sizeString: { fieldLabel: 'Reference #', maxLength: 1000, minLength: 0, message: 'Reference # must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	referenceNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	sourceTypeSearch: constants.PaymentGroupSourceTypeSearch;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

}
