import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { EmployeeDropdownResponse, EmployeeService } from '@core/employee/employee.service';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { NavigationService } from '@core/navigation/navigation.service';
import { ProviderDropdownResponse, ProviderService } from '@core/provider/provider.service';
import { AppointmentResponse } from '@gandalf/model/appointment-response';
import { StartAppointmentRequest } from '@gandalf/model/start-appointment-request';
import { atLeastOne } from '@shared/validators/atleastOne.validation';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest } from 'rxjs';
import { StartAppointmentCalendarResponse } from '@gandalf/model/start-appointment-calendar-response';
import { ScheduleService } from '../../../schedule/schedule-main/schedule.service';

@Component({
	selector: 'pms-start-appointment-modal',
	templateUrl: './start-appointment-modal.component.html',
	styles: [],
})
export class StartAppointmentModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('ngForm')
	ngForm: NgForm;

	formGroup: UntypedFormGroup;
	employeeList: EmployeeDropdownResponse[];
	providerList: ProviderDropdownResponse[];
	providerId: number;
	employeeId: number;
	appointmentId: number;
	isSearching: boolean;
	returnCalendarResponse = false;
	request: StartAppointmentRequest;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		private gandalfFormBuilder: GandalfFormBuilder,
		private modalConfig: ModalConfig,
		private employeeService: EmployeeService,
		private providerService: ProviderService,
		private scheduleService: ScheduleService,
		public eventsManager: EventsManagerService,
		public navigationService: NavigationService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig);
		this.createForm();
		this.requestData();
	}

	requestData() {
		this.isSearching = true;
		combineLatest([
			this.employeeService.findPracticeEmployeesForDropdown(),
			this.providerService.findPracticeProvidersForDropdown(),
		]).subscribe(([employeeList, providerList]) => {
			this.employeeList = employeeList;
			this.providerList = providerList;
			this.isSearching = false;
			this.formGroup.enable();
		});
	}

	parseModalConfig(modalConfig: ModalConfig) {
		this.providerId = modalConfig.data.providerId;
		this.employeeId = modalConfig.data.employeeId;
		this.appointmentId = modalConfig.data.appointmentId;
		this.returnCalendarResponse = modalConfig.data.returnCalendarResponse ?? false;
	}

	createForm() {
		this.request = new StartAppointmentRequest();
		this.request.providerId = this.providerId;
		this.request.employeeId = this.employeeId;
		this.request.appointmentId = this.appointmentId;
		this.formGroup = this.gandalfFormBuilder.group(
			this.request, {
				validators: [atLeastOne(
					Validators.required,
					['providerId', 'employeeId'],
					`Provider or Employee is required`),
				],
			});
		this.formGroup.disable();
	}

	startAppointment() {
		if (this.formGroup.invalid) {
			return;
		}
		if (this.returnCalendarResponse) {
			this.scheduleService.startAppointmentCalendar(this.formGroup.value).subscribe(data => {
				this.closeModal(data);
			});
		} else {
			this.scheduleService.startAppointment(this.formGroup.value).subscribe(data => {
				this.closeModal(data);
			});
		}
	}

	/* istanbul ignore next: closeModal */
	closeModal(data?: AppointmentResponse | StartAppointmentCalendarResponse) {
		this.dynamicModalRef.close(this.modal, data);
	}

}
