// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { FamilyCreditRequest } from './family-credit-request';

// @ts-ignore
import { FamilyRefundRequest } from './family-refund-request';

// @ts-ignore
import { InsuranceRefundRequest } from './insurance-refund-request';

// @ts-ignore
import { PatientCreditRequest } from './patient-credit-request';

// @ts-ignore
import { PatientRefundRequest } from './patient-refund-request';

/** See com.rev360.legacy.api.controller.accounting.GrantCreditsRequest */
/* istanbul ignore next */
@GandalfModel
export class GrantCreditsRequest extends GandalfModelBase {

	@GandalfProperty()
	familyCredit: FamilyCreditRequest;

	@GandalfProperty()
	familyRefund: FamilyRefundRequest;

	@GandalfProperty()
	insuranceRefund: InsuranceRefundRequest;

	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

	@GandalfProperty()
	patientCredit: PatientCreditRequest;

	@GandalfProperty()
	patientRefund: PatientRefundRequest;

}
