<div class="ag-cell-label-container" (click)="onHeaderClick()">
	<div class="ag-header-cell-label">
		<ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container>
		<span *ngIf="isFiltering" class="ag-header-icon ag-header-label-icon ag-filter-icon"><span class="ag-icon ag-icon-filter"></span></span>
		<ng-container *ngIf="params.enableSorting">
			<span *ngIf="isAscSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon"><span class="ag-icon ag-icon-asc"></span></span>
			<span *ngIf="isDescSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon"><span class="ag-icon ag-icon-desc"></span></span>
		</ng-container>
	</div>
</div>
