import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { InvoiceItemResponse } from '@gandalf-black/model/invoice-item-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { SplitPaymentRequest } from '@gandalf/model/split-payment-request';
import { CurrencyInputComponent } from '@shared/component/currency-input/currency-input.component';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InvoiceItemListResponse } from '@gandalf/model/invoice-item-list-response';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { InvoiceItemPricingUtil } from '../../core/accounting/invoice-item-pricing/invoice-item-pricing-util';
import { AccountingService, FormattedInvoiceDateResponse } from '../../core/accounting/accounting.service';

@Component({
	selector: 'pms-split-payment-modal',
	templateUrl: './split-payment-modal.component.html',
	styles: [],
})
export class SplitPaymentModalComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('patientPortionInput')
	patientPortionInput: CurrencyInputComponent;

	request: SplitPaymentRequest;
	formGroup: UntypedFormGroup;
	invoiceId: number;
	patientId: number;
	transferToInvoiceList: FormattedInvoiceDateResponse[];
	TRANSFER_TO_NEW_INVOICE_LABEL = 'new';
	TRANSFER_TO_EXISTING_INVOICE_LABEL = 'existing';
	showTransferToExistingInvoiceOption = false;
	insuranceCompanyName: string;
	invoiceItem: InvoiceItemResponse | InvoiceItemListResponse;
	invoiceItemId: number;
	encounterId: number;
	orderId: number;
	readonly accountingUpliftFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.ACCOUNTING.UPLIFT_ACCOUNTING));

	constructor(
		private ref: DynamicModalRef,
		public formBuilder: GandalfFormBuilder,
		public modalConfig: ModalConfig,
		private accountingService: AccountingService,
		private invoiceService: InvoiceService,
	) {
	}

	ngOnInit() {
		this.parseConfigData(this.modalConfig.data);

		this.buildRequest();
		this.buildForm();
		this.getData();
	}

	parseConfigData(data: any) {
		this.invoiceId = data.invoiceId;
		this.invoiceItemId = data.itemId;
		this.patientId = data.patientId;
		this.encounterId = data.encounterId;
		this.orderId = data.orderId;
		if (this.accountingUpliftFeatureOn) {
			this.invoiceItem = data.invoiceItem;
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getData() {
		combineLatest([
			this.accountingService.findPatientPendingInvoicesForDropdown(this.patientId),
			this.accountingUpliftFeatureOn ? of(null) : this.accountingService.findInvoiceItemById(this.invoiceItemId),
		]).subscribe(([patientPendingInvoices, invoiceItem]) => {
			this.transferToInvoiceList = patientPendingInvoices;
			if (!this.accountingUpliftFeatureOn) {
				this.invoiceItem = invoiceItem;
			}
			this.handlePendingPatientInvoices();
			this.initForm();
			this.handleFormState();
		});
	}

	handlePendingPatientInvoices() {
		if (this.transferToInvoiceList.length > 0) {
			this.showTransferToExistingInvoiceOption = true;
		}
	}

	/* istanbul ignore next */
	closeModal() {
		this.ref.close(this.modal);
	}

	buildRequest() {
		this.request = new SplitPaymentRequest();
		this.request.invoiceId = this.invoiceId;
		this.request.patientId = this.patientId;
		this.request.invoiceItemId = this.invoiceItemId;
		this.request.encounterId = this.encounterId;
		this.request.orderId = this.orderId;
	}

	buildForm() {
		this.formGroup = this.formBuilder.group(this.request);
		this.formGroup.addControl('transferToNewOrExistingInvoice', new UntypedFormControl());
	}

	initForm() {
		this.formGroup.get('insurancePortion').setValue(this.invoiceItem.extendedPrice);

		if (this.showTransferToExistingInvoiceOption) {
			this.formGroup.get('transferToNewOrExistingInvoice').setValue(this.TRANSFER_TO_EXISTING_INVOICE_LABEL);
			this.formGroup.get('transferToExistingInvoiceId').setValue(this.transferToInvoiceList[0].value);
		} else {
			this.formGroup.get('transferToNewOrExistingInvoice').setValue(this.TRANSFER_TO_NEW_INVOICE_LABEL);
		}
		this.patientPortionInput.setFocus();
	}

	handleFormState() {
		this.formGroup.get('patientPortion').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				if (this.formGroup.get('insurancePortion').value !== Number(InvoiceItemPricingUtil.calculateTaxableAmount(this.invoiceItem.extendedPrice, val))) {
					this.formGroup.get('insurancePortion').setValue(InvoiceItemPricingUtil.calculateTaxableAmount(this.invoiceItem.extendedPrice, val));
				}
			});

		this.formGroup.get('insurancePortion').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				if (this.formGroup.get('patientPortion').value !== Number(InvoiceItemPricingUtil.calculateTaxableAmount(this.invoiceItem.extendedPrice, val))) {
					this.formGroup.get('patientPortion').setValue(InvoiceItemPricingUtil.calculateTaxableAmount(this.invoiceItem.extendedPrice, val));
				}
			});

		this.formGroup.get('transferToNewOrExistingInvoice').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('transferToExistingInvoiceId').enable();
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferToExistingInvoiceId'),
					val === this.TRANSFER_TO_NEW_INVOICE_LABEL,
				);
			});
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	saveSplitPayment() {
		if (this.formGroup.invalid) {
			return;
		}

		const finalSplitPaymentRequest: SplitPaymentRequest = this.formGroup.getRawValue();
		// if transfer to new or existing is 'new' don't send the existing invoice id
		finalSplitPaymentRequest.transferToExistingInvoiceId = finalSplitPaymentRequest['transferToNewOrExistingInvoice'] !== this.TRANSFER_TO_NEW_INVOICE_LABEL
			? finalSplitPaymentRequest.transferToExistingInvoiceId
			: null;

		this.accountingService.splitInvoiceItemPayment(finalSplitPaymentRequest).subscribe((data) => {
			this.refreshInvoices(data);
			this.closeModal();
		});
	}

	refreshInvoices(invoices: InvoiceResponse[]) {
		invoices.forEach(invoiceResponse => {
			this.invoiceService.refreshInvoice(invoiceResponse.id);
		});
	}

}
