import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FolderService, SimpleFolder } from '@core/folder/folder.service';
import { _isNil, _isUndefined } from '@core/lodash/lodash';
import { ModalManagerService } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { UpdatePatientDirectoryModalComponent } from '@shared/component/directory-tree/add-patient-directory/update-patient-directory-modal.component';
import { TreeNode } from '@shared/interfaces/treeview';
import { ContextMenuComponent, TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { MenuItemModel } from '@syncfusion/ej2-navigations';
import { FieldsSettingsModel } from '@syncfusion/ej2-navigations/src/treeview/treeview-model';
import { PatientFolderIdentifier } from '@gandalf/constants';
import { EnumUtil } from 'morgana';

enum FOLDER_ACTIONS {
	RENAME = 'RENAME',
	CUT = 'CUT',
	PASTE = 'PASTE',
	DELETE = 'REMOVE',
	ADD = 'ADD',
}

@Component({
	selector: 'pms-directory-tree',
	templateUrl: './patient-directory-tree.component.html',
})
export class PatientDirectoryTreeComponent implements OnInit {
	@Input()
	set selectedFolder(value: SimpleFolder) {
		if (value && value.folderId !== this.selectedFolderId) {
			this._selectedFolder = value;
			this.showFolderSelection(value.folderId);
		} else {
			this._selectedFolder = value;
		}
	}

	get selectedFolder(): SimpleFolder {
		return this._selectedFolder;
	}
	private _selectedFolder: SimpleFolder;

	@Output()
	folderSelected = new EventEmitter<SimpleFolder>();

	@ViewChild('treeViewComponent')
	treeViewComponent: TreeViewComponent;

	menuItems: MenuItemModel[] = [
		{
			text: 'Rename Folder',
			id: FOLDER_ACTIONS.RENAME,
		},
		{
			text: 'Cut Folder',
			id: FOLDER_ACTIONS.CUT,
		},
		{
			text: 'Paste Folder',
			id: FOLDER_ACTIONS.PASTE,
		},
		{
			text: 'Delete Folder',
			id: FOLDER_ACTIONS.DELETE,
		},
		{
			text: 'Add Subfolder',
			id: FOLDER_ACTIONS.ADD,
		},
	];

	fieldSettingsModel: FieldsSettingsModel;
	hideTree = false;
	mostRecentlyCutFolder: SimpleFolder;
	canAddFolder = false;
	canDeleteFolder = false;
	canCutPasteFolder = false;
	canRenameFolder = false;
	expanded = false;
	defaultFolder: SimpleFolder;

	constructor(
		public folderService: FolderService,
		private changeDetectorRef: ChangeDetectorRef,
		private modalManagerService: ModalManagerService,
		public securityManagerService: SecurityManagerService,
	) {
	}

	ngOnInit() {
		this.refreshPatientFolders();
		this.preparePermissions();
	}

	preparePermissions() {
		this.canAddFolder = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_ADD_FOLDER);
		this.canDeleteFolder = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_DELETE_FOLDER);
		this.canCutPasteFolder = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_CUT_PASTE_FOLDER);
		this.canRenameFolder = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_RENAME_FOLDER);
	}

	refreshPatientFolders(folderId?: string) {
		this.folderService.getPatientFolders(true).subscribe(folders => {
			this.handlePatientFolderData(folders);
			if (_isNil(this.selectedFolderId) && _isNil(folderId)) {
				this.selectedFolder = this.defaultFolder;
			} else {
				this.showFolderSelection(folderId ? folderId : this.selectedFolderId);
			}
		});
	}

	handlePatientFolderData(folders: TreeNode[]) {
		this.fieldSettingsModel = FolderService.convertToFieldsSettingsModel(folders);
		if (folders.length > 0) {
			this.defaultFolder = this.treeNodeToSimpleFolder(folders[0]);
			this.fieldSettingsModel.dataSource[0].expanded = true;
		}
		this.fieldSettingsModel.iconCss = 'icon';
		this.changeDetectorRef.markForCheck();
	}

	expandAll() {
		this.fieldSettingsModel = {...this.fieldSettingsModel};
		this.expanded = true;
		this.fieldSettingsModel.dataSource = this.changeFolderAndChildrenExpansion(this.fieldSettingsModel.dataSource as TreeNode[], true);
		this.changeDetectorRef.markForCheck();
	}

	changeFolderAndChildrenExpansion(folders: TreeNode[], expanded: boolean) {
		const newFolders = folders.map(folder => ({...folder}));
		for (const folder of newFolders) {
			folder.expanded = expanded;
			if (folder.children) {
				folder.children = this.changeFolderAndChildrenExpansion(folder.children, expanded);
			}
		}
		return newFolders;
	}

	collapseAll() {
		this.fieldSettingsModel = {...this.fieldSettingsModel};
		this.expanded = false;
		this.fieldSettingsModel.dataSource = this.changeFolderAndChildrenExpansion(this.fieldSettingsModel.dataSource as TreeNode[], false);
		this.changeDetectorRef.markForCheck();
	}

	nodeSelected(folder) {
		this.searchTreeAndSetFolderSelection(folder.id);
		this.folderSelected.emit(this.selectedFolder);
	}

	forceTreeReRender() {
		this.hideTree = true;
		setTimeout(() => this.hideTree = false);
	}

	menuItemSelected(event) {
		switch (event.item.id) {
			case FOLDER_ACTIONS.DELETE:
				this.removeCurrentlySelectedFolder();
				break;
			case FOLDER_ACTIONS.ADD:
				this.addFolderToSelectedFolder();
				break;
			case FOLDER_ACTIONS.CUT:
				this.cutSelectedFolder();
				break;
			case FOLDER_ACTIONS.PASTE:
				this.pasteCutFolder();
				break;
			case FOLDER_ACTIONS.RENAME:
				this.renameFolder();
				break;
		}
	}

	removeCurrentlySelectedFolder() {
		if (!_isNil(this.selectedFolder)) {
			this.folderService.deleteFolder(this.selectedFolder.folderId, this.selectedFolder.name).subscribe(() => {
				this.searchTreeAndSetFolderSelection(this.selectedFolder.parentId);
				this.refreshPatientFolders();
			});
		}
	}

	showFolderSelection(value: string) {
		if (!_isNil(value) && this.fieldSettingsModel) {
			this.searchTreeAndSetFolderSelection(value);
			this.folderSelected.emit(this.selectedFolder);
			this.forceTreeReRender();
		}
	}

	searchTreeAndSetFolderSelection(folderId: string) {
		const folderTreeNode = this.folderService.searchTree(
			this.fieldSettingsModel.dataSource as TreeNode[],
			folderId,
			true,
			false,
		);
		this._selectedFolder = this.treeNodeToSimpleFolder(folderTreeNode);
	}

	treeNodeToSimpleFolder(folderTreeNode) {
		return {
			folderId: folderTreeNode.data as string,
			name: folderTreeNode.text || folderTreeNode.label,
			parentId: folderTreeNode.parentId,
			children: folderTreeNode.children,
		};
	}

	addFolderToSelectedFolder() {
		if (!_isNil(this.selectedFolder)) {
			this.modalManagerService.open(
				UpdatePatientDirectoryModalComponent,
				{data: {selectedFolderId: this.selectedFolder.folderId}},
			).onClose.subscribe(result => {
				this.onAddOrUpdateResult(result);
			});
		}
	}

	private onAddOrUpdateResult(result: SimpleFolder) {
		if (!_isNil(result)) {
			this.refreshPatientFolders(result.folderId);
		}
	}

	cutSelectedFolder() {
		this.mostRecentlyCutFolder = this.selectedFolder;
	}

	pasteCutFolder() {
		const cutFolderId = this.mostRecentlyCutFolder.folderId;
		const pasteLocationFolderId = this.selectedFolder.folderId;
		// If the cut folder is the folder we are attempting to paste into, do nothing.
		if (cutFolderId === pasteLocationFolderId) {
			return;
		}
		this.folderService.moveFolder(
			cutFolderId,
			pasteLocationFolderId,
			null,
		).subscribe(() => {
			this.selectedFolder = this.mostRecentlyCutFolder;
			this.mostRecentlyCutFolder = null;
			this.refreshPatientFolders();
		});
	}

	disableContextMenuItems(contextmenu: ContextMenuComponent): void {
		_isUndefined(EnumUtil.findEnumByValue(this.selectedFolder.folderId, PatientFolderIdentifier))
			? contextmenu.showItems([FOLDER_ACTIONS.DELETE, FOLDER_ACTIONS.CUT, FOLDER_ACTIONS.PASTE, FOLDER_ACTIONS.RENAME], true)
			: contextmenu.hideItems([FOLDER_ACTIONS.DELETE, FOLDER_ACTIONS.CUT, FOLDER_ACTIONS.PASTE, FOLDER_ACTIONS.RENAME], true);

		contextmenu.enableItems([FOLDER_ACTIONS.DELETE], this.canDeleteFolder, true);
		contextmenu.enableItems([FOLDER_ACTIONS.CUT], this.canCutPasteFolder, true);
		contextmenu.enableItems([FOLDER_ACTIONS.PASTE], this.canCutPasteFolder && !_isNil(this.mostRecentlyCutFolder), true);
		contextmenu.enableItems([FOLDER_ACTIONS.RENAME], this.canRenameFolder, true);
		contextmenu.enableItems([FOLDER_ACTIONS.ADD], this.canAddFolder, true);
	}

	private renameFolder() {
		this.modalManagerService.open(
			UpdatePatientDirectoryModalComponent,
			{data: {selectedFolderId: this.selectedFolder.folderId, isUpdate: true, folderName: this.selectedFolder.name}},
		).onClose.subscribe(result => {
			this.onAddOrUpdateResult(result);
		});
	}

	get selectedFolderId(): string {
		return this.selectedFolder ? this.selectedFolder.folderId : null;
	}
}
