// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { TransferItemRequest } from './transfer-item-request';

/** See com.rev360.legacy.api.controller.accounting.ReceivePaymentTransferInvoiceItemsRequest */
/* istanbul ignore next */
@GandalfModel
export class ReceivePaymentTransferInvoiceItemsRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Include All Items in Transfer is required' } })
	@GandalfProperty({ isRequired: true })
	includeAllItems: boolean;

	@GandalfProperty()
	payerEntityId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfProperty()
	personInsuranceId: number;

	@GandalfProperty({ propertyType: 'Money' })
	transferAmount: number;

	@GandalfArray(TransferItemRequest)
	transferItems: TransferItemRequest[];

	@GandalfProperty()
	transferReferenceId: number;

	@GandalfProperty()
	transferToExistingInvoiceId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Transfer Type is required' } })
	@GandalfProperty({ isRequired: true })
	transferType: constants.TransferType;

	@GandalfProperty()
	writeoffReferenceId: number;

}
