<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-md"
		[attr.data-test-id]="'transferBalanceModal'">
	<ng-template #header>
		<div class="dlg-template">Transfer Balance</div>
	</ng-template>
		<ng-template #content>
			<form #templateForm="ngForm" [formGroup]="componentForm" class="form-horizontal" (ngSubmit)="save()">
				<gandalf-lib-validation-messages
					*ngIf="componentForm.invalid && templateForm.submitted"
					[form]="componentForm"
					[requestObj]="invoiceTransferRequest"
					[attr.data-test-id]="'transferBalanceModalValidationMessages'">
				</gandalf-lib-validation-messages>
				<h4 class="section-title bg-default mrgn-btm">Invoice #{{invoiceId}} for <strong>{{patientName | patientName}}</strong></h4>
				<div class="form-group">
					<label for="balance" class="col-sm-3 control-label">Current Balance</label>
					<div class="col-sm-9">
						<div class="row">
							<div class="col-sm-4">
								<p class="form-control-static" id="balance" [attr.data-test-id]="'transferBalanceLabel'">${{formattedInvoiceBalance}}</p>
							</div>
						</div>
					</div>
				</div>
				<hr>
				<div class="form-group" formGroupName="patientInvoiceTransferSplitRequest" *ngIf="showPatientFields">
					<label for="patientAmount" class="col-sm-3 control-label">Patient</label>
					<div class="col-sm-9">
						<div class="row">
							<div class="col-sm-4">
								<pms-always-set-currency-input
									#patientAmountInput
									htmlId="patientAmount"
									formControlName="amount"
									min="0">
								</pms-always-set-currency-input>
							</div>
							<div class="col-sm-4">
								<ejs-dropdownlist
									[dataSource]="transferReasons"
									formControlName="reasonId"
									placeholder="Select Reason">
								</ejs-dropdownlist>
							</div>
						</div>
					</div>
				</div>
				<hr *ngIf="showPatientFields">
				<div *ngIf="showAltInsuranceOption" class="form-group" formGroupName="insuranceInvoiceTransferSplitRequest">
					<label for="insuranceAmount" class="col-sm-3 control-label">Alt Insurance</label>
					<div class="col-sm-9">
						<div class="row">
							<div class="col-sm-4">
								<pms-always-set-currency-input
									htmlId="insuranceAmount"
									formControlName="amount"
									min="0">
								</pms-always-set-currency-input>
							</div>
							<div class="col-sm-4">
								<ejs-dropdownlist
									[dataSource]="insurances"
									(change)="setPracticeInsuranceCompanyId($event)"
									formControlName="personInsuranceId"
									placeholder="Select Insurance">
								</ejs-dropdownlist>
							</div>
							<div class="col-sm-4">
								<ejs-dropdownlist
									[dataSource]="transferReasons"
									formControlName="reasonId"
									placeholder="Select Reason">
								</ejs-dropdownlist>
							</div>
						</div>
					</div>
				</div>
				<hr *ngIf="showAltInsuranceOption">
				<div class="form-group" formGroupName="collectionsInvoiceTransferSplitRequest">
					<label for="collectionsAmount" class="col-sm-3 control-label">Collections</label>
					<div class="col-sm-9">
						<div class="row">
							<div class="col-sm-4">
								<pms-always-set-currency-input
									htmlId="collectionsAmount"
									formControlName="amount"
									min="0">
								</pms-always-set-currency-input>
							</div>
							<div class="col-sm-4">
								<ejs-dropdownlist
									[dataSource]="collectionAgencies"
									formControlName="collectionsAgencyId"
									placeholder="Select Collections Agency">
								</ejs-dropdownlist>
							</div>
						</div>
					</div>
				</div>
				<hr>
				<div class="form-group" formGroupName="writeoffInvoiceTransferSplitRequest" *ngIf="showWriteoffOption">
					<label for="writeoffAmount" class="col-sm-3 control-label">Write-off</label>
					<div class="col-sm-9">
						<div class="row">
							<div class="col-sm-4">
								<pms-always-set-currency-input
									htmlId="writeOffAmount"
									formControlName="amount"
									min="0">
								</pms-always-set-currency-input>
							</div>
							<div class="col-sm-4">
								<ejs-dropdownlist
									[dataSource]="writeoffReasons"
									formControlName="reasonId"
									placeholder="Select Reason">
								</ejs-dropdownlist>
							</div>
						</div>
					</div>
				</div>
				<hr>
				<div class="form-group" [attr.data-test-id]="'transferBalanceModalAmountTransferredLabelGroup'">
					<label for="transferAmount" class="col-sm-3 control-label">Amt Transferred</label>
					<div class="col-sm-9">
						<div class="row">
							<div class="col-sm-4">
								<p class="form-control-static">{{formattedTransferAmount}}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'transferBalanceModalEndingBalanceLabelGroup'">
					<label for="endingBalance" class="col-sm-3 control-label">Ending Balance</label>
					<div class="col-sm-9">
						<div class="row">
							<div class="col-sm-4">
								<p class="form-control-static" [class.text-danger]="displayEndingBalanceRed">{{formattedEndingBalance}}</p>
							</div>
						</div>
					</div>
				</div>
			</form>
		</ng-template>
		<ng-template #footerTemplate>
			<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()"  [attr.data-test-id]="'transferBalanceCancelButton'">Cancel</button>
			<button type="button" ejs-button [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'transferBalanceSaveButton'">Save</button>
		</ng-template>
</ejs-dialog>
