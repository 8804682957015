// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.AddAdhocInvoiceItemRequest */
/* istanbul ignore next */
@GandalfModel
export class AddAdhocInvoiceItemRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Code cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	code: string;

	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	description: string;

	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

	@GandalfValidator({ notNull: { message: 'Person is required' } })
	@GandalfProperty({ isRequired: true })
	personId: number;

	@GandalfValidator({ notNull: { message: 'Post Date is required' } })
	@GandalfProperty({ isRequired: true })
	postDate: Date;

	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfValidator({ max: { max: 999, message: 'Quantity may not be more than 999' } })
	@GandalfProperty({ isRequired: true })
	quantity: number;

	@GandalfProperty()
	taxId: number;

	@GandalfValidator({ notNull: { message: 'Unit Price is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	unitPrice: number;

}
