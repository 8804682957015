<div tabindex="-1" (focusout)="onBlur($event)">
	<div class="form-group has-feedback" [attr.data-test-id]="'headerPatientSearch'">
		<input type="text" class="form-control" revFocusInput [(ngModel)]="searchString" (input)="debounceSearch()"  placeholder="Patient Search"/>
		<span class="fa fa-search form-control-feedback" aria-hidden="true"></span>
	</div>
	<ul *ngIf="showResults" class="navbar-search-results dropdown-menu" (mouseenter)="stopTimeoutTimer()" (mouseleave)="startTimeoutTimer()" [attr.data-test-id]="'navbarSearchResultMenu'">
		<li class="search-results-heading dropdown-header">PATIENTS</li>
		<li class="search-results-item" *ngFor="let patient of patientResults; let index = index" [attr.data-test-id]="'headerSearchResult_' + index">
			<a revEventStopPropagation (click)="navigateToPatient(patient.patientId)">
				<span class="search-results-name">{{patient.patientNameResponse | patientName: true}}</span>
				<span class="search-results-dob">{{patient.dateOfBirth | date : dateFormat}}</span>
				<div class="search-results-info">
					<span class="search-results-phone">{{patient.preferredPhoneNumber}}</span>
					<span class="search-results-address">
						<span *ngIf="patient.addressResponse?.address1">{{patient.addressResponse.address1}} </span>
						<span *ngIf="patient.addressResponse?.address2">{{patient.addressResponse.address2}}, </span>
						<span *ngIf="patient.addressResponse?.city">{{patient.addressResponse.city}}, </span>
						<span *ngIf="patient.addressResponse?.stateProvince">{{patient.addressResponse.stateProvince}} </span>
						<span *ngIf="patient.addressResponse?.postalCode">{{patient.addressResponse.postalCode }}</span>
					</span>
				</div>
			</a>
		</li>
		<li *ngIf="showNoResultsText(patientResults)" class="search-results-item">
			<a><span class="search-results-name">No results found</span></a>
		</li>
		<li *ngIf="showMaxResultsText" class="search-results-item">
			<a><span class="search-results-name">Top 10 matches. Refine search for additional results.</span></a>
		</li>
	</ul>
</div>
