<div class="e-control e-toolbar e-lib e-keyboard" *ngIf="shouldShowToolbar()">
	<div class="e-toolbar-items e-tbar-pos">
		<div class="e-toolbar-left">
			<ng-content select="[rev-table-action-menu-left]"></ng-content>
		</div>
		<div class="e-toolbar-center">
			<ng-content select="[rev-table-action-menu-center]"></ng-content>
		</div>
		<div class="e-toolbar-right">
			<ng-content select="[rev-table-action-menu-right]"></ng-content>
			<div *ngIf="hasToolBarItems()" class="e-toolbar-item" aria-disabled="false">
				<button *ngFor="let item of items" type="button" ejs-button iconCss="{{item.iconCss}}" (click)="runItemAction(item)" revTooltip tooltipContent="{{item.text}}" [attr.data-test-id]="'toolbar' + item.dataTestId + 'Button'">{{item.buttonText}}</button>
			</div>

		</div>
	</div>
</div>

<ng-content select="[rev-container-grid]"></ng-content>

<rev-ag-grid-pagination [agGrid]="agGrid"></rev-ag-grid-pagination>
