<rev-loading-overlay [loading]="_isLoading">
	<form *ngIf="formGroup" #templateForm="ngForm" class="form-horizontal" [formGroup]="formGroup">
		<div class="row margin-bottom-sm">
			<div class="col-sm-12 col-md-6 " [ngClass]="showBulkWriteOff() ? 'col-lg-3' : 'col-lg-4'">
				<div class="form-group">
					<label class="col-sm-4 control-label required">Payment Amount</label>
					<div class="col-sm-8">
						<pms-always-set-currency-input
								formControlName="paymentAmount"
								revFocusInput
								[highlightInputOnFocus]="true">
						</pms-always-set-currency-input>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-8 col-sm-offset-4">
						<ejs-checkbox
								label="Apply in Full"
								formControlName="applyFull">
						</ejs-checkbox>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 control-label required">Payment Method</label>
					<div class="col-sm-8">
						<pms-constant-dropdown
								placeholder="Select Method"
								formControlName="paymentMethodType"
								[constantList]="paymentMethods">
						</pms-constant-dropdown>
						<div class="margin-top-sm" *ngIf="paymentComponentService.isCreditCardPaymentMethod()">
							<ng-container *ngIf="paymentComponentService.showCardProcessing() else creditCardType">
								<rev-status-select-button
										[options]="creditCardInputOptions"
										formControlName="selectedCreditCardInput">
								</rev-status-select-button>
								<ejs-dropdownlist
										*ngIf="showCardReaders()"
										placeholder="Select Reader"
										class="margin-top-sm"
										formControlName="selectedOpenEdgeCardReader"
										[dataSource]="paymentComponentService.openEdgeCardReaders">
								</ejs-dropdownlist>
								<ejs-dropdownlist
										*ngIf="showOnFileCards()"
										placeholder="Select Credit Card"
										pmsAutoSelect
										[autoSelectOnlyOption]="true"
										class="margin-top-sm"
										formControlName="selectedOpenEdgeOnFileCard"
										[dataSource]="payerSavedCards">
								</ejs-dropdownlist>
							</ng-container>
							<ng-template #creditCardType>
								<pms-constant-dropdown
										placeholder="Select Type"
										class="margin-top-sm"
										[showClearButton]="true"
										formControlName="creditCardType"
										[constantList]="creditCardTypes">
								</pms-constant-dropdown>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6 " [ngClass]="showBulkWriteOff() ? 'col-lg-3' : 'col-lg-4'">
				<div class="form-group">
					<label class="col-sm-4 control-label">Reference/Check #</label>
					<div class="col-sm-8">
						<input type="text" class="e-input" formControlName="referenceNumber">
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 control-label">Payment Date</label>
					<div class="col-sm-8">
						<ejs-datepicker
								placeholder="mm/dd/yyyy"
								formControlName="paymentDate"
								[format]="dateFormat">
						</ejs-datepicker>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 control-label required">Location</label>
					<div class="col-sm-8">
						<ejs-dropdownlist
								formControlName="paymentLocationId"
								[dataSource]="paymentComponentService.locations"
								(select)="confirmPaymentLocationChange($event)">
						</ejs-dropdownlist>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6" [ngClass]="showBulkWriteOff() ? 'col-lg-3' : 'col-lg-4'">
				<div class="form-group">
					<label class="col-sm-4 control-label">Comments</label>
					<div class="col-sm-8">
						<ejs-textbox [multiline]="true" class="h-rows-5" formControlName="comment" (keydown.tab)="onTabKeydown($event, false)"></ejs-textbox>
					</div>
				</div>
			</div>
			<div *ngIf="showBulkWriteOff()" class="col-sm-12 col-md-6 col-lg-3">
				<div class="form-group">
					<label class="col-sm-4 control-label">Write-Off</label>
					<div class="col-sm-8">
						<div class="row margin-bottom-sm">
							<div class="col-xs-8">
								<ejs-dropdownlist
										formControlName="writeOffReason"
										[showClearButton]="true"
										placeholder="Select Reason"
										(keydown.tab)="onTabKeydown($event, true)"
										[dataSource]="filteredBulkWriteOffReasons">
								</ejs-dropdownlist>
							</div>
							<div class="col-xs-4">
								<button
										ejs-button
										cssClass="e-success"
										[disabled]="!formGroup.get('writeOffReason').value"
										type="button"
										(click)="addBulkWriteOff()"
										[attr.data-test-id]="'bulkWriteOffButton'">
									Add
								</button>
							</div>
						</div>
						<ag-grid-angular #writeOffReasonGrid
										 *ngIf="writeOffReasonGridOptions"
										 class="ag-theme-alpine"
										 pmsAgAutoFitColumns
										 [gridOptions]="writeOffReasonGridOptions"
										 [rowData]="bulkWriteOffs"
										 [attr.data-test-id]="'writeOffReasonGrid'">
						</ag-grid-angular>
					</div>
				</div>
			</div>
		</div>
		<div class="h385">
			<ag-grid-angular #invoicePaymentsGrid
							 *ngIf="invoicePaymentsGridOptions"
							 class="ag-theme-alpine hFull"
							 pmsAgAutoFitColumns
							 [gridOptions]="invoicePaymentsGridOptions"
							 [attr.data-test-id]="'invoicePaymentGrid'">
			</ag-grid-angular>
		</div>
		<button *ngIf="allowManualSelectInvoice()" ejs-button cssClass="e-success margin-top-sm" type="button" iconCss="fa fa-plus"
				(click)="openSelectInvoiceModal()" [attr.data-test-id]="'addInvoiceButton'">
			Add Invoices
		</button>

	</form>
</rev-loading-overlay>

<ng-template #paymentColumn let-data>
	<ng-container *ngIf="data.processingPaymentGroupId">
		<span class="text-right">{{getInvoicePaymentAmountControl(data.invoiceId).value | currency}}</span>
	</ng-container>
	<ng-container *ngIf="!data.processingPaymentGroupId">
		<pms-always-set-currency-input
			*ngIf="!isAggregateRow(data)"
			[formControl]="getInvoicePaymentAmountControl(data.invoiceId)"
			[revFocusInput]="focusInvoicePaymentAmountId === data.invoiceId"
			[highlightInputOnFocus]="true"
			(inputBlur)="focusInvoicePaymentAmountId = null">
		</pms-always-set-currency-input>
		<span *ngIf="isAggregateRow(data)" class="text-right" [class.text-danger]="!paymentTotalMatchesPaymentAmount()">
			{{paymentAmountSum | currency}}
		</span>
	</ng-container>
</ng-template>

<ng-template #itemsColumn
			 let-data>
	<ng-template #itemsColumnInner
				 [ngTemplateOutlet]="itemsColumnInner"
				 let-invoicePayment="invoicePayment"
				 [ngTemplateOutletContext]="{ invoicePayment: getInvoicePaymentById(data.invoiceId) }">
		<ng-container *ngIf="data.processingPaymentGroupId">
			<span class="text-right" [class.text-danger]="invoicePaymentItemsExceedAmount(invoicePayment)">
				{{invoicePayment?.itemsTotal | currency}}
			</span>
		</ng-container>
		<ng-container *ngIf="!data.processingPaymentGroupId">
			<a *ngIf="!isAggregateRow(data)"
			   [class.text-danger]="invoicePaymentItemsExceedAmount(invoicePayment)"
			   (click)="openTransferModal(invoicePayment)">
				{{invoicePayment?.itemsTotal | currency}}
			</a>
			<span *ngIf="isAggregateRow(data)" class="text-right">{{data.itemsTotal | currency}}</span>
		</ng-container>
	</ng-template>
</ng-template>

<ng-template #newBalanceColumn
			 let-data>
	<ng-template #newBalanceColumnInner
				 [ngTemplateOutlet]="newBalanceColumnInner"
				 let-balance="balance"
				 [ngTemplateOutletContext]="{ balance: getInvoicePaymentBalanceById(data.invoiceId) }">
		<div *ngIf="!isAggregateRow(data)"
			 [class.text-danger]="balance < 0">{{balance | currency}}</div>
		<span *ngIf="isAggregateRow(data)" class="text-right">{{data.finalBalance | currency}}</span>
	</ng-template>
</ng-template>

<ng-template #bulkWriteOffButtonTemplate let-data>
	<rev-button
			[revGridButton]="'remove'"
			(buttonClick)="removeBulkWriteOff(data)"
			dataTestId="receivePaymentsWriteOffRemoveButton">
	</rev-button>
</ng-template>
