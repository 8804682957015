<div class="media">
	<div class="media-body">
		<h4 class="media-heading display-inline-block vertical-align-top margin-top-0 margin-bottom-0 margin-right-lg" [attr.data-test-id]="'invoiceHeaderLabel'">{{invoice?.payerType?.label}} Invoice #{{invoice?.id}}
			<pms-invoice-status
				[invoice]="invoice"
				[isReadOnly]="isReadOnly"
				[isPaymentProcessing]="isPaymentProcessing">
			</pms-invoice-status>
		</h4>
		<ul class="list-inline display-inline-block vertical-align-top margin-bottom-0">
			<li>
				<i class="fa fa-user text-info margin-right-xs"></i>
				<ng-container *ngIf="!isGuestInvoice()">
					<a *ngIf="hasPermissionToGoToPatient()" (click)="navigateToPatient()" [attr.data-test-id]="'invoiceHeaderPatientNameLink'">{{patientName | patientName}}</a>
					<span *ngIf="!hasPermissionToGoToPatient()" >{{patientName | patientName}}</span>
					<a class="margin-left-sm" *ngIf="firstSortedActiveAlert"><i class="fa fa-flag text-{{firstSortedActiveAlert?.severity?.label | severityClass}}" revTooltip tooltipContent="Alerts" (click)="displayStatusAlertsModal()" [attr.data-test-id]="'invoiceDetailsHeaderAlertIcon'"></i></a>
				</ng-container>
				<ng-container *ngIf="isGuestInvoice()">
					{{invoice.payerName}}
				</ng-container>
			</li>
			<li>
				<i class="fa fa-building text-info margin-right-xs"></i>{{invoice?.locationName}}
				<a (click)="editLocation()" *ngIf="canChangeLocation()" class="margin-left-xs" revTooltip tooltipContent="Edit" [attr.data-test-id]="'invoiceLocationButton'"><i class="fa fa-pencil"></i></a>
			<li>
				<i class="fa fa-user-md text-info margin-right-xs"></i>{{invoice?.providerName ? invoice.providerName : "None"}}
				<a (click)="editProvider()" *ngIf="canChangeProvider()" class="margin-left-xs" revTooltip tooltipContent="Edit" [attr.data-test-id]="'invoiceProviderEditButton'"><i class="fa fa-pencil"></i></a>
				<a class="margin-left-xs" *ngIf="canRemoveProvider()" (click)="checkRemoveProvider()" revTooltip tooltipContent="Remove" [attr.data-test-id]="'invoiceProviderRemoveButton'"><i class="fa fa-close"></i></a>
			</li>
			<li>
				<pms-invoice-date [invoice]="invoice" [isReadOnly]="isReadOnly" [isPaymentProcessing]="isPaymentProcessing"></pms-invoice-date>
			</li>
		</ul>
	</div>
</div>
