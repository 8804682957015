// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.LegacyPracticeDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class LegacyPracticeDiagnosisResponse extends GandalfModelBase {

	@GandalfProperty()
	active: boolean;

	@GandalfProperty()
	code: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	disabledDate: Date;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	masterCodeSetId: number;

	@GandalfProperty()
	shortDescription: string;

}
