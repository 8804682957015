// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.patient.ParseExternalPatientDataFileRequest */
/* istanbul ignore next */
@GandalfModel
export class ParseExternalPatientDataFileRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'CDA validation is required' } })
	@GandalfProperty({ isRequired: true })
	isCdaValidation: boolean;

	@GandalfValidator({ notNull: { message: 'Patient file is required' } })
	@GandalfProperty({ isRequired: true })
	patientFileId: number;

}
