<form class="form-inline"
	  [formGroup]="componentForm"
	  (ngSubmit)="search()"
      #barcodeForm="ngForm"
	  [attr.data-test-id]="'barcodeComponent'">
	<div class="row">
		<div class="col-sm-12">
			<gandalf-lib-validation-messages
				*ngIf="componentForm.invalid && barcodeForm.submitted"
				[form]="componentForm"
				[requestObj]="barcodeSearchRequest"
				[attr.data-test-id]="'barcodeComponentValidationMessages'">
			</gandalf-lib-validation-messages>
		</div>
	</div>
	<div class="form-group">
		<label *ngIf="label">{{ label }}</label>
		<input
			[revFocusInput]="focusInput"
			formControlName="barcodeValue"
			class="e-input"/>
		<button ejs-button type="submit" cssClass="e-success margin-left-sm">{{ buttonLabel }}</button>
	</div>
</form>
