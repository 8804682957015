<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'invoiceItemDetailModal'">
	<ng-template #header>
		<div class="dlg-template">Invoice Item Detail</div>
	</ng-template>
	<ng-template #content>
		<form #templateForm="ngForm" class="form-horizontal"
			  (ngSubmit)="updateInvoiceItemDetail()"
			  [formGroup]="componentForm">
			<gandalf-lib-validation-messages
					*ngIf="componentForm.invalid && templateForm.submitted"
					[form]="componentForm"
					[requestObj]="request"
					[attr.data-test-id]="'invoiceItemDetailModalValidationMessage'"
			></gandalf-lib-validation-messages>
			<h4 class="section-title bg-default mrgn-btm">Invoice Item #{{invoiceItemDetails?.invoiceItemId}}</h4>
			<div class="row margin-bottom-md">
				<div class="col-md-6 col-lg-4">
					<div class="form-group" [attr.data-test-id]="'invoiceItemDetailModalCodeLabel'">
						<label class="col-sm-4 control-label">Code</label>
						<div class="col-sm-8">
							<ng-container *ngIf="!isAdhoc">
								<p class="form-control-static">{{invoiceItemDetails?.code}}</p>
							</ng-container>
							<ng-container *ngIf="isAdhoc">
								<input formControlName="code" maxlength="50" class="e-input" type="text">
							</ng-container>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Description</label>
						<div class="col-sm-8" [attr.data-test-id]="'invoiceItemDetailModalDescriptionLabel'">
							<ng-container *ngIf="!isAdhoc">
								<p class="form-control-static">{{invoiceItemDetails?.description}}</p>
							</ng-container>
							<ng-container *ngIf="isAdhoc">
								<ejs-textbox formControlName="description" [multiline]="true"></ejs-textbox>
							</ng-container>
						</div>
					</div>
					<div class="form-group"
						 revTooltip
						 tooltipContent="Loop 2400 SV105"
						 [attr.data-test-id]="'invoiceItemDetailModalFacilityTypeLabel'">
						<label class="col-sm-4 control-label">Facility Type</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
									[constantList]="facilityTypes"
									showClearButton="true"
									placeholder="Select Facility Type"
									formControlName="facilityType">
							</pms-constant-dropdown>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label"
							   revTooltip
							   tooltipContent="Loop 2420C"
							   [attr.data-test-id]="'invoiceItemDetailModalServiceLocationLabel'">Service Location</label>
						<div class="col-sm-8">
							<ejs-dropdownlist
									revTooltip
									[tooltipContent]="serviceLocationTooltip"
									showClearButton="true"
									[dataSource]="allVendors"
									placeholder="Select Service Location"
									formControlName="serviceLocationId"></ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label"
							   revTooltip
							   tooltipContent="Loop 2400 SV111"
							   [attr.data-test-id]="'invoiceItemDetailModalEpsdtLabel'"
						>EPSDT</label>
						<div class="col-sm-3">
							<input class="e-input" formControlName="epsdt" type="text" maxlength="2">
						</div>
						<div class="col-sm-5">
							<div class="row">
								<label
										class="col-sm-5 col-lg-6 control-label"
										revTooltip
										tooltipContent="Loop 2400 SV109"
										[attr.data-test-id]="'invoiceItemDetailModalEmergencySvcLabel'"
									>Emerg. Svc.</label>
								<div class="col-sm-7 col-lg-6">
									<input class="e-input" formControlName="emergencyService" maxlength="2">
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label"
							   revTooltip
							   tooltipContent="Loop 2400 Segment NTE"
							   [attr.data-test-id]="'invoiceItemDetailModalClaimNoteLabel'"
						>Claim Note</label>
						<div class="col-sm-3"
							 revTooltip
							 [tooltipContent]="claimNoteCodeTooltip">
							<pms-constant-dropdown
									showClearButton="true"
									placeholder="Select Code"
									[constantList]="claimNoteCodes"
									label="value"
									formControlName="claimNoteCode">
							</pms-constant-dropdown>
						</div>
						<div class="col-sm-5" revTooltip [tooltipContent]="claimNoteTooltip">
							<input class="e-input" formControlName="claimNote" maxlength="80">
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-4">
					<div class="form-group">
						<label class="col-sm-4 control-label"
							   revTooltip
							   tooltipContent="2410 LIN03 (N4)"
							   [attr.data-test-id]="'invoiceItemDetailModalNpcLabel'"
						>NDC</label>
						<div class="col-sm-8">
							<input class="e-input" formControlName="nationalDrugCode" maxlength="13">
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label"
							   revTooltip
							   tooltipContent="2410 CPT04"
							   [attr.data-test-id]="'invoiceItemDetailModalDosageAmountLabel'"
						>Dosage</label>
						<div class="col-sm-4">
							<input class="e-input" formControlName="dosageAmount" maxlength="17">
						</div>
						<div class="col-sm-4" revTooltip tooltipContent="2410 CPT05-01" [attr.data-test-id]="'invoiceItemDetailModalUnitCodesLabel'">
							<pms-constant-dropdown
									showClearButton="true"
									placeholder="Select Unit"
									[constantList]="dosageUnitCodes"
									formControlName="dosageUnit">
							</pms-constant-dropdown>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label"
							   revTooltip
							   tooltipContent="Loop 2410 REF02"
							   [attr.data-test-id]="'invoiceItemDetailModalRxNumberLabel'"
						>Rx #</label>
						<div class="col-sm-8">
							<input class="e-input" formControlName="rxNumber" maxlength="50">
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label"
							   revTooltip
							   tooltipContent="Loop 2410 REF01"
							   [attr.data-test-id]="'invoiceItemDetailModalRxQualifierLabel'"
						>Rx Qualifier</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
									showClearButton="true"
									placeholder="Select Rx Qualifier"
									[constantList]="rxQualifiers"
									formControlName="rxQualifier">
							</pms-constant-dropdown>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-4">
					<div class="form-group">
						<label class="col-sm-4 control-label"
							   revTooltip
							   tooltipContent="Loop 2400 DTP (472)"
							   [attr.data-test-id]="'invoiceItemDetailModalServiceDateLabel'"
						>Service Dates</label>
						<div class="col-sm-8">
							<div class="input-range">
								<div class="input-range-input">
									<ejs-datepicker
											placeholder="mm/dd/yyyy"
											[format]="dateFormat"
											formControlName="serviceStartDate">
									</ejs-datepicker>
								</div>
								<div class="input-range-label">
									<label class="control-label">to</label>
								</div>
								<div class="input-range-input">
									<ejs-datepicker
											placeholder="mm/dd/yyyy"
											[format]="dateFormat"
											formControlName="serviceEndDate">
									</ejs-datepicker>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Additional Modifiers</label>
						<div class="col-sm-8">
							<div class="margin-bottom-sm" *ngIf="hasServiceModifier">
								<!-- This is only used for display purposes, no form field is needed -->
								<ejs-dropdownlist
										disabled="disabled"
										revTooltip
										[tooltipContent]="modifierTooltip"
										[dataSource]="modifiers"
										placeholder="Select Modifier"
										[(ngModel)]="serviceModifier"
										[ngModelOptions]="{standalone: true}"
										[attr.data-test-id]="'invoiceItemDetailModalModifiersDropdown'">
								</ejs-dropdownlist>
							</div>
							<div class="margin-bottom-sm">
								<ejs-dropdownlist
										revTooltip
										[tooltipContent]="additionalModifierTooltip0"
										showClearButton="true"
										[dataSource]="modifiers"
										placeholder="Select Modifier"
										formControlName="additionalModifierId0"></ejs-dropdownlist>
							</div>
							<div class="margin-bottom-sm">
								<ejs-dropdownlist
										revTooltip
										[tooltipContent]="additionalModifierTooltip1"
										showClearButton="true"
										[dataSource]="modifiers"
										placeholder="Select Modifier"
										formControlName="additionalModifierId1"></ejs-dropdownlist>
							</div>
							<div class="margin-bottom-sm">
								<ejs-dropdownlist
										revTooltip
										[tooltipContent]="additionalModifierTooltip2"
										showClearButton="true"
										[dataSource]="modifiers"
										placeholder="Select Modifier"
										formControlName="additionalModifierId2"></ejs-dropdownlist>
							</div>
							<div *ngIf="!hasServiceModifier">
								<ejs-dropdownlist
										revTooltip
										[tooltipContent]="additionalModifierTooltip3"
										showClearButton="true"
										[dataSource]="modifiers"
										placeholder="Select Modifier"
										formControlName="additionalModifierId3"></ejs-dropdownlist>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Employee</label>
						<div class="col-sm-8">
							<ejs-dropdownlist
									[dataSource]="employeeListByPersonId"
									formControlName="responsiblePersonId"
									[showClearButton]="true">
							</ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group" *ngIf="showBetaFeatureExternalProvider()">
						<label class="col-sm-4 control-label">External Provider</label>
						<div class="col-sm-8">
							<p class="form-control-static">
								<a *ngIf="showDefaultExternalProviderSelection" (click)="openSelectProviderModal()">Select External Provider</a>
								<ng-container *ngIf="invoiceItemDetails?.externalProviderId">
									<span>{{invoiceItemDetails.formattedExternalProviderName}}</span>
									<ng-container *ngIf="canEditExternalProvider">
										<a (click)="openSelectProviderModal()" class="margin-left-xs" revTooltip tooltipContent="Edit"><i
												class="fa fa-pencil" [attr.data-test-id]="'invoiceItemDetailModalEditButton'"></i></a>
										<a (click)="promptToClearExternalProvider()" class="margin-left-xs" revTooltip tooltipContent="Remove"><i
												class="fa fa-close" [attr.data-test-id]="'invoiceItemDetailModalRemoveButton'"></i></a>
									</ng-container>
								</ng-container>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="margin-bottom-md">
				<ejs-grid
						class="table-rowlink"
						[dataSource]="itemAdjustments"
						[allowSelection]="false"
						[allowSorting]="false"
						[sortSettings]="adjustmentSortSettings"
						autoFitColumns
						[staticColumns]="['id', 'updatedOn', 'amount']"
						[autoFitBlacklist]="['details']"
						gridLines="Horizontal"
						[attr.data-test-id]="'invoiceItemDetailModalAdjustmentGrid'">
					<e-columns>
						<e-column headerText="Remove" field="id" width="90">
							<ng-template #template let-adjustment>
								<ejs-checkbox
										(checkedChange)="toggleRemoveAdjustment($event, adjustment.id)"
										*ngIf="isAdjustmentRemovable(adjustment)">
								</ejs-checkbox>
							</ng-template>
						</e-column>
						<e-column headerText="Adjustment" field="type"></e-column>
						<e-column headerText="Details" field="details"></e-column>
						<e-column headerText="Date" width="90" field="updatedOn" [format]="tableDateFormat"></e-column>
						<e-column headerText="Amount" minWidth="120" field="amount" format="C2"></e-column>
					</e-columns>
				</ejs-grid>
			</div>
			<div class="row" *ngIf="hasPatient">
				<div class="col-md-6 margin-bottom-md">
					<ejs-grid *ngIf="!agGridFlagOn" id="selectedDiagnosesGrid" #selectedDiagnosesGrid
							  height="450"
							  class="table-rowlink"
							  [dataSource]="selectedDiagnoses"
							  [editSettings]="{ allowAdding: true, allowDeleting: true }"
							  [allowRowDragAndDrop]="false"
							  [rowDropSettings]="{ targetID: 'masterModifiersGrid' }"
							  (recordDoubleClick)="selectedDiagnosesGridDoubleclick($event)"
							  (rowDataBound)="applyBackgroundColor($event)">
						<e-columns>
							<e-column field="personDiagnosisId" headerText="Selected Diagnoses" isPrimaryKey="true" width="80%">
								<ng-template #template let-data>
									<div *ngIf="isCodeIcd10AndDisabled(data)">
										<span revTooltip
											  tooltipContent="This code is no longer billable on/after {{data.practiceDiagnosis.disabledDate | date:dateFormat}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
									</div>
									<div *ngIf="!isCodeIcd10AndDisabled(data)">
										<span revTooltip
											  tooltipContent="{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
									</div>
								</ng-template>
							</e-column>
							<e-column headerText="" width="10%" textAlign="Right">
								<ng-template #template let-data>
									<rev-button
											[revGridButton]="'remove'"
											(buttonClick)="removeFromSelected(data)"
											dataTestId="invoiceItemDetailsDiagnosesRemoveButton">
									</rev-button>
								</ng-template>
							</e-column>
						</e-columns>
					</ejs-grid>
					<div *ngIf="agGridFlagOn" class="margin-bottom-sm">
						<ag-grid-angular #selectedDiagnosesAgGrid
										 class="ag-theme-alpine"
										 pmsAgAutoFitColumns
										 [domLayout]="'autoHeight'"
										 [gridOptions]="selectedDxGridOptions"
										 [rowData]="selectedDiagnoses"
										 (rowDoubleClicked)="selectedDiagnosesGridDoubleclick($event)"
										 (gridReady)="onSelectedDiagnosesAgGridReady($event)"
										 [pagination]="false"
										 [attr.data-test-id]="'invoiceItemDetailModalSelectedDiagnosesGrid'">
						</ag-grid-angular>
					</div>
				</div>
				<div class="col-md-6 margin-bottom-md">
					<ejs-grid *ngIf="!agGridFlagOn" id="availableDiagnosesGrid" #availableDiagnosesGrid
							  height="450"
							  class="table-rowlink"
							  [dataSource]="availableDiagnoses"
							  [pmsGridSubmit]="isSearching"
							  [editSettings]="{ allowAdding: true, allowDeleting: true }"
							  [rowDropSettings]="{ targetID: 'selectedDiagnosesGrid' }"
							  (recordDoubleClick)="availableDiagnosesGridDoubleclick($event)"
							  (rowDataBound)="applyBackgroundColor($event)">
						<e-columns>
							<e-column field="id" headerText="Available Diagnoses" isPrimaryKey="true" width="80%">
								<ng-template #template let-data>
									<div *ngIf="isCodeIcd10AndDisabled(data)">
										<span revTooltip
											  tooltipContent="This code is no longer billable on/after {{data.practiceDiagnosis.disabledDate | date: dateFormat}}">{{ data.practiceDiagnosis.code }}
											- {{data.practiceDiagnosis.shortDescription}}</span>
									</div>
									<div *ngIf="!isCodeIcd10AndDisabled(data)">
										<span revTooltip tooltipContent="{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
									</div>
								</ng-template>
							</e-column>
							<e-column headerText="" width="10%" textAlign="Right">
								<ng-template #template let-data>
									<rev-button
											[revGridButton]="'add'"
											(buttonClick)="addToSelected(data)"
											dataTestId="invoiceItemDetailsDiagnosesAddButton">
									</rev-button>
								</ng-template>
							</e-column>
						</e-columns>
					</ejs-grid>
					<ng-container *ngIf="agGridFlagOn">
						<div class="margin-bottom-sm" *ngIf="showIcd9Filter()" rev-table-action-menu-left>
							<ejs-checkbox [ngModelOptions]="{standalone: true}"
										  [(ngModel)]="gridIcd9Filter"
										  (ngModelChange)="filterAvailableDiagnosesAgGrid()"
										  label="Show ICD-9 Codes"
										  [attr.data-test-id]="'invoiceItemDetailModalGridIcd9FilterCheckBox'"
							></ejs-checkbox>
						</div>
						<ag-grid-angular #availableDiagnosesAgGrid
										 class="ag-theme-alpine"
										 pmsAgAutoFitColumns76m
										 [domLayout]="'autoHeight'"
										 [gridOptions]="availableDxGridOptions"
										 [rowData]="availableDiagnoses"
										 (rowDoubleClicked)="availableDiagnosesGridDoubleclick($event)"
										 (gridReady)="onAvailableDiagnosesAgGridReady($event)"
										 [pagination]="false"
										 (rowDataUpdated)="filterAvailableDiagnosesAgGrid()"
										 [attr.data-test-id]="'invoiceItemDetailModalAvailableDiagnosesGrid'">
						</ag-grid-angular>
					</ng-container>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'invoiceItemDetailModalCancelButton'">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'invoiceItemDetailModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>

<ng-template #diagnosesCodeColumnTemplate let-data>
	<span *ngIf="isCodeIcd10AndDisabled(data)" revTooltip tooltipContent="This code is no longer billable on/after {{data.practiceDiagnosis.disabledDate | date:dateFormat}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
	<span *ngIf="!isCodeIcd10AndDisabled(data)" revTooltip tooltipContent="{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
</ng-template>
<ng-template #diagnosesRemoveButtonColumnTemplate let-data>
	<rev-button
			[revGridButton]="'remove'"
			(buttonClick)="removeFromSelected(data)"
			dataTestId="invoiceItemDetailsDiagnosesRemoveButton">
	</rev-button>
</ng-template>
<ng-template #diagnosesAddButtonColumnTemplate let-data>
	<rev-button
			[revGridButton]="'add'"
			(buttonClick)="addToSelected(data)"
			dataTestId="invoiceItemDetailsDiagnosesAddButton">
	</rev-button>
</ng-template>
