// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.patient.HeuristicPatientSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class HeuristicPatientSearchRequest extends GandalfModelBase {

	@GandalfLabel('Search Criteria')
	@GandalfValidator({ sizeString: { fieldLabel: 'Search Criteria', maxLength: 1000, minLength: 1, message: 'Search Criteria must be between 1 and 1000 characters' } })
	@GandalfValidator({ notNull: { message: 'Search Criteria is required' } })
	@GandalfProperty({ isRequired: true })
	queryString: string;

}
