import { inject, InjectionToken } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ResourceAuthority } from '@gandalf/constants';

const permissionTokensMap = new Map<ResourceAuthority, InjectionToken<boolean>>();

function createPermissionToken(resourceAuthority: ResourceAuthority) {
	return new InjectionToken<boolean>(resourceAuthority.label, {
		providedIn: 'root',
		factory: () => inject(SecurityManagerService).hasPermission(resourceAuthority.value),
	});
}

export function permissionToken(resourceAuthority: ResourceAuthority): InjectionToken<boolean> {
	if (permissionTokensMap.has(resourceAuthority)) {
		return permissionTokensMap.get(resourceAuthority);
	}
	const token = createPermissionToken(resourceAuthority);
	permissionTokensMap.set(resourceAuthority, token);
	return token;
}
