import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import tippy, { delegate, Props } from 'tippy.js';
import { TooltipEventArgs } from '@syncfusion/ej2-angular-popups';
import { _isNil, _isString } from '../../utils/lodash/lodash';

@Directive({
	selector: '[revTooltip]',
	exportAs: 'revTooltip',
})
export class TooltipDirective implements AfterViewInit, OnDestroy {

	@Input()
	tooltipTarget: string;

	@Input()
	tooltipContent: string | HTMLElement;

	@Input()
	tooltipSettings: Partial<Props> = {};

	@Input()
	tooltipDisabled = false;

	@Input()
	allowHtml = false;

	@Output()
	beforeRender = new EventEmitter<any>();

	tooltip: any;

	constructor(
		private elementRef: ElementRef,
	) {
	}

	/* istanbul ignore next */
	ngAfterViewInit() {
		this.createTooltip();
	}

	createTooltip() {
		const tippyContent = this.tooltipContent instanceof HTMLElement ? this.tooltipContent.innerHTML : this.tooltipContent;

		const tooltipProps: Partial<Props> = {
			content: tippyContent,
			allowHTML: this.allowHtml || this.tooltipContent instanceof HTMLElement,
			theme: 'light-border',
			...this.tooltipSettings,
			onShow: ($event: any) => {
				if (this.shouldSuppressTooltip()) {
					return false;
				}

				const tooltipEventArgs: TooltipEventArgs = {
					event: $event,
					target: $event.reference,
					cancel: false,
					element: this.elementRef.nativeElement,
					type: 'tooltip',
				};
				this.beforeRender.emit(tooltipEventArgs);

				this.updateTooltipContent($event.reference);
			},
		};
		this.tooltip = _isNil(this.tooltipTarget)
			? tippy(this.elementRef.nativeElement, tooltipProps)
			: delegate(this.elementRef.nativeElement, {...tooltipProps, target: this.tooltipTarget});
	}

	/**
	 * Don't show the tooltip if it is disabled or empty
	 */
	shouldSuppressTooltip() {
		return this.tooltipDisabled || _isNil(this.tooltipContent) || (_isString(this.tooltipContent) && !this.tooltipContent);
	}

	/* istanbul ignore next */
	updateTooltipContent(targetElement: HTMLElement) {
		if (_isNil(targetElement['_tippy'])) {
			return;
		}

		if (this.tooltipContent instanceof HTMLElement) {
			if (this.tooltipContent.innerHTML !== targetElement['_tippy'].props.content) {
				targetElement['_tippy'].setContent(this.tooltipContent.innerHTML);
			}
		} else {
			if (this.tooltipContent !== targetElement['_tippy'].props.content) {
				targetElement['_tippy'].setContent(this.tooltipContent);
			}
		}
	}

	/* istanbul ignore next */
	ngOnDestroy() {
		if (this.tooltip) {
			this.tooltip.destroy();
		}
	}
}
