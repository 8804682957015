// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { AdditionalDiagnosisResponse } from './additional-diagnosis-response';

// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './invoice-item-adjustment-response';

/** See com.rev360.legacy.api.controller.accounting.InvoiceItemDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceItemDetailResponse extends GandalfModelBase {

	@GandalfArray(AdditionalDiagnosisResponse)
	additionalDiagnoses: AdditionalDiagnosisResponse[];

	@GandalfProperty()
	additionalModifierId0: number;

	@GandalfProperty()
	additionalModifierId1: number;

	@GandalfProperty()
	additionalModifierId2: number;

	@GandalfProperty()
	additionalModifierId3: number;

	@GandalfProperty()
	claimNote: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	claimNoteCode: constants.ClaimNoteCode;

	@GandalfProperty()
	code: string;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	dosageAmount: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	dosageUnit: constants.DosageUnitCode;

	@GandalfProperty()
	emergencyService: string;

	@GandalfProperty()
	epsdt: string;

	@GandalfProperty()
	externalProviderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	facilityType: constants.FacilityType;

	@GandalfProperty()
	formattedExternalProviderName: string;

	@GandalfProperty()
	invoiceItemId: number;

	@GandalfArray(InvoiceItemAdjustmentResponse)
	itemAdjustments: InvoiceItemAdjustmentResponse[];

	@GandalfProperty()
	modifier: string;

	@GandalfProperty()
	nationalDrugCode: string;

	@GandalfProperty()
	postedOn: Date;

	@GandalfProperty()
	quantity: number;

	@GandalfProperty()
	responsiblePersonId: number;

	@GandalfProperty()
	rxNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	rxQualifier: constants.RxQualifier;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceEndDate: Date;

	@GandalfProperty()
	serviceLocationId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceStartDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InvoiceItemStatus;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.InvoiceItemType;

}
