import { Injectable } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { LockManagerService } from '@core/lock-manager/lock-manager.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { LoginUsingExistingSessionRequest } from '@gandalf/model/login-using-existing-session-request';
import { EmployeeSecurityGandalfService, EnvironmentDataGandalfService } from '@gandalf/services';
import { BehaviorSubject, combineLatest, interval, Subject } from 'rxjs';
import { HIT_PMS_HTML_EVENTS } from '../legacy/hit-pms-html.constants';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	isAuthenticated = new BehaviorSubject(false);
	loggedOut = new Subject<void>();
	private readonly HEARTBEAT_INTERVAL_MS = 10 * 60 * 1000; // 10 minutes

	constructor(
		private employeeSecurityService: EmployeeSecurityGandalfService,
		private securityManager: SecurityManagerService,
		private eventsManager: EventsManagerService,
		private lockManager: LockManagerService,
		private userLocationsService: UserLocationsService,
		private environmentDataGandalfService: EnvironmentDataGandalfService,
	) {
		this.observeUserLocation();
	}

	observeUserLocation() {
		this.userLocationsService.observeCurrentUserLocation()
			.subscribe((location) => this.updateCurrentLocation(location.id));
	}

	subscribeToSessionEvents() {
		this.eventsManager.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_STARTED, () => this.loginUsingExistingSession(), this);
		this.eventsManager.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_STOPPING, () => this.logout(), this);
	}

	/**
	 * Log in to the pms2 backend using the existing user session ID and HTTP session ID. This will set a new
	 * session cookie (JSESSIONID) from the pms2 backend, which will allow the application to call service
	 * endpoints in pms2.
	 */
	loginUsingExistingSession() {
		/**
		 * This is temporary code to sync sessions between the two backend layers
		 */
		if (this.securityManager.checkLogin()) {
			const request = new LoginUsingExistingSessionRequest();
			request.userSessionId = this.securityManager.getUserSession().id;
			request.httpSessionId = this.securityManager.getUserSession().httpSessionId;
			this.employeeSecurityService.loginUsingExistingSession(request).subscribe(() => {
				setTimeout(() => {
					this.processLogin();
				});
			});
		}
	}

	/**
	 * Initiates subjects, events, and subscriptions that should happen after successful login
	 */
	processLogin() {
		this.isAuthenticated.next(true);
		this.eventsManager.publish(HIT_PMS_HTML_EVENTS.LOCATION.LOCATION_CHANGE);

		this.getEnvironmentData();
		this.startKeepAliveInterval();
		this.observeLockedState();
	}

	/**
	 * Call the security service to update the Rev360 backend's current user location to match the Legacy PMS location
	 */
	updateCurrentLocation(locationId) {
		this.employeeSecurityService.setCurrentLocation(locationId).subscribe();
	}

	/**
	 * Get environment-specific data
	 */
	getEnvironmentData() {
		combineLatest([
			this.environmentDataGandalfService.getBluefinUrl(),
			this.environmentDataGandalfService.getOpenEdgeConfiguration(),
			this.environmentDataGandalfService.getEpayBaseUrl(),
			this.environmentDataGandalfService.getGudidBaseUrl(),
			this.environmentDataGandalfService.findMasterProductCategories(),
		]).subscribe((
			[
				bluefinUrl,
				openEdgeConfiguration,
				epayBaseUrl,
				gudidBaseUrl,
				masterProductCategories,
			],
		) => {
			this.securityManager.environmentData.bluefinUrl = bluefinUrl.url;
			this.securityManager.environmentData.openEdgeConfiguration = openEdgeConfiguration;
			this.securityManager.environmentData.epayBaseUrl = epayBaseUrl.url;
			this.securityManager.environmentData.gudidBaseUrl = gudidBaseUrl.url;
			this.securityManager.environmentData.masterProductCategories = masterProductCategories;
		});
	}

	/**
	 * Repeatedly calls keepAliveHeartbeat on a configured interval
	 */
	startKeepAliveInterval() {
		interval(this.HEARTBEAT_INTERVAL_MS).subscribe(() => this.keepAliveHeartbeat());
	}

	/**
	 * This ensures that the heartbeat is triggered immediately upon unlock.  Since we are only making a heartbeat call
	 * every 10 minutes we need to ensure there is no chance that the delay until the next heartbeat would allow a timeout of the session.
	 */
	observeLockedState() {
		this.lockManager.isLocked.subscribe(() => this.keepAliveHeartbeat());
	}

	/**
	 * Pings the pms2's keepAlive endpoint, only if the screen is not locked
	 */
	keepAliveHeartbeat() {
		if (!this.lockManager.isLocked.getValue()) {
			this.employeeSecurityService.keepAlive().subscribe();
		}
	}

	/**
	 * Log out of the pms2 backend (expire the session cookie that was set by loginUsingExistingSession)
	 */
	logout() {
		if (this.securityManager.checkLogin()) {
			this.employeeSecurityService.logout().subscribe(() => {
				this.isAuthenticated.next(false);
				this.loggedOut.next();
			});
		}
	}
}
