<form
	#categoryForm="ngForm"
	class="form-horizontal"
	[formGroup]="formGroup"
	(ngSubmit)="saveChanges()">
	<div class="panel-feature">
		<button ejs-button iconCss="fa fa-chevron-left" (click)="returnToList(false)">
			All {{categoryTitle}}
		</button>
	</div>
	<div class="panel-body">
		<div class="row">
			<div class="col-sm-12 col-lg-6">
				<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && categoryForm.submitted"
					[form]="formGroup"
					[requestObj]="createOrUpdateRequest">
				</gandalf-lib-validation-messages>
				<div class="form-group">
					<label class="col-sm-4 col-md-3 control-label required">Name</label>
					<div class="col-sm-8 col-md-9">
						<input type="text" class="e-input" revFocusInput formControlName="name"/>
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 col-md-3 control-label">Description</label>
					<div class="col-sm-8 col-md-9">
						<input type="text" class="e-input"
							   formControlName="description"/>
					</div>
				</div>
				<div class="form-group">
					<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
						<ejs-checkbox formControlName="active" label="Active"></ejs-checkbox>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="panel-footer">
		<button ejs-button type="submit" [isPrimary]="true">Save</button>
		<button ejs-button type="button" iconCss="fa fa-close" (click)="returnToList(false)">Cancel</button>
	</div>
</form>
