// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.AddDiscountRequest */
/* istanbul ignore next */
@GandalfModel
export class AddDiscountRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Discount cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount: number;

	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfProperty({ isRequired: true })
	description: string;

	@GandalfProperty()
	discountReferenceId: number;

	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

}
