import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { ManualProcessPaymentRequest } from '@gandalf-black/model/manual-process-payment-request';
import { CreditCardType } from '@gandalf/constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';

@Component({
	selector: 'pms-manual-process-payment-modal',
	templateUrl: './manual-process-payment-modal.component.html',
})
export class ManualProcessPaymentModalComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('ngForm')
	ngForm: NgForm;

	totalAmount: number;
	request: ManualProcessPaymentRequest;
	formGroup: UntypedFormGroup;
	creditCardTypes: CreditCardType[];
	referenceNumber: string;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		private modalConfig: ModalConfig,
		private gandalfFormBuilder: GandalfFormBuilder,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.modalConfig);
		this.createForm();
	}

	createForm() {
		this.request = new ManualProcessPaymentRequest();
		this.request.referenceNumber = this.referenceNumber;
		this.formGroup = this.gandalfFormBuilder.group(this.request);
	}

	parseModalConfig(modalConfig: ModalConfig) {
		this.totalAmount = modalConfig.data.totalAmount;
		this.creditCardTypes = modalConfig.data.creditCardTypes;
		this.referenceNumber = modalConfig.data.referenceNumber;
	}

	/* istanbul ignore next: submitForm */
	submitForm(event: any) {
		this.ngForm.onSubmit(event);
	}

	/* istanbul ignore next: submit */
	submit() {
		if (this.formGroup.invalid) {
			return;
		}
		this.closeModal(this.formGroup.value);
	}

	/* istanbul ignore next: closeModal */
	closeModal(request?) {
		this.dynamicModalRef.close(this.modal, request);
	}
}
