// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.UpdateInvoiceItemRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateInvoiceItemRequest extends GandalfModelBase {

	@GandalfLabel('Selected Diagnoses')
	@GandalfValidator({ notNull: { message: 'Selected Diagnoses is required' } })
	@GandalfArray(Number)
	additionalDiagnoses: number[];

	@GandalfLabel('Additional Modifier 1')
	@GandalfProperty()
	additionalModifierId0: number;

	@GandalfLabel('Additional Modifier 2')
	@GandalfProperty()
	additionalModifierId1: number;

	@GandalfLabel('Additional Modifier 3')
	@GandalfProperty()
	additionalModifierId2: number;

	@GandalfLabel('Additional Modifier 4')
	@GandalfProperty()
	additionalModifierId3: number;

	@GandalfLabel('Claim Note')
	@GandalfValidator({ sizeString: { message: 'Claim Note cannot be longer than 80 characters', minLength: 0, maxLength: 80 } })
	@GandalfProperty()
	claimNote: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Claim Note Code')
	@GandalfProperty()
	claimNoteCode: constants.ClaimNoteCode;

	@GandalfLabel('Code')
	@GandalfValidator({ sizeString: { message: 'Code cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	code: string;

	@GandalfLabel('Description')
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	description: string;

	@GandalfLabel('Dosage')
	@GandalfValidator({ sizeString: { message: 'Dosage cannot be longer than 17 characters', minLength: 0, maxLength: 17 } })
	@GandalfProperty()
	dosageAmount: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Dosage Unit')
	@GandalfProperty()
	dosageUnit: constants.DosageUnitCode;

	@GandalfLabel('Emerg. Svc.')
	@GandalfValidator({ sizeString: { message: 'Emerg. Svc. cannot be longer than 2 characters', minLength: 0, maxLength: 2 } })
	@GandalfProperty()
	emergencyService: string;

	@GandalfLabel('EPSDT')
	@GandalfValidator({ sizeString: { message: 'EPSDT cannot be longer than 2 characters', minLength: 0, maxLength: 2 } })
	@GandalfProperty()
	epsdt: string;

	@GandalfLabel('External Provider')
	@GandalfProperty()
	externalProviderId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Facility Type')
	@GandalfProperty()
	facilityType: constants.FacilityType;

	@GandalfLabel('Invoice Item')
	@GandalfValidator({ notNull: { message: 'Invoice Item is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceItemId: number;

	@GandalfLabel('Remove Adjustments')
	@GandalfValidator({ notNull: { message: 'Remove Adjustments is required' } })
	@GandalfArray(Number)
	itemAdjustmentIdsToRemove: number[];

	@GandalfLabel('NDC')
	@GandalfValidator({ sizeString: { message: 'NDC cannot be longer than 13 characters', minLength: 0, maxLength: 13 } })
	@GandalfProperty()
	nationalDrugCode: string;

	@GandalfLabel('Posted On')
	@GandalfProperty()
	postedOn: Date;

	@GandalfLabel('Quantity')
	@GandalfProperty()
	quantity: number;

	@GandalfLabel('Employee')
	@GandalfProperty()
	responsiblePersonId: number;

	@GandalfLabel('Rx #')
	@GandalfValidator({ sizeString: { message: 'Rx # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	rxNumber: string;

	@GandalfConstantDecorator()
	@GandalfLabel('Rx Qualifier')
	@GandalfProperty()
	rxQualifier: constants.RxQualifier;

	@GandalfLabel('Service End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceEndDate: Date;

	@GandalfLabel('Service Location')
	@GandalfProperty()
	serviceLocationId: number;

	@GandalfLabel('Service Start Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceStartDate: Date;

}
