<div id="successToast" #successToast></div>
<div id="errorToast" #errorToast></div>
<div id="infoToast" #infoToast></div>
<div id="warningToast" #warningToast></div>
<div id="flagModificationToast" #featureFlagModificationToast></div>
<div id="defaultNotificationToast" #defaultNotificationToast></div>
<div id="toastElement" #toastElement></div>
<div id="printSection" #printSection></div>
<div class="patient-container-storage ng-hide"></div>
<div *ngIf="headerFeatureFlagOrAgreements()">
	<router-outlet></router-outlet>
</div>
