import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { CategoryResponseForDropdown, CategoryService } from '@core/category/category.service';
import { EmployeeDropdownResponse, EmployeeService } from '@core/employee/employee.service';
import { EncounterTemplateForDropdown, EncounterTemplateService } from '@core/encounterTemplate/encounterTemplate.service';
import { EnumUtil } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { ProviderDropdownResponse, ProviderService } from '@core/provider/provider.service';
import { QueryTriggerService } from '@core/query-trigger/query-trigger.service';
import { QueryService, TreeNode } from '@core/query/query.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { SecurityRoleDropdownResponse, SecurityRoleService } from '@core/security/security-role.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import {
	CategoryEntityType,
	DateRangeType,
	QueryCriteriaBoolean,
	QueryCriteriaOperator,
	QueryCriteriaOuterOperator,
	QueryCriteriaWithinUnit,
	QueryFieldDataSource,
	QueryFieldType,
	QuerySortDirection,
	QueryTemplateCategory,
	QueryType
} from '@gandalf/constants';
import { CreateQueryRequest } from '@gandalf/model/create-query-request';
import { CreateQueryTriggerRequest } from '@gandalf/model/create-query-trigger-request';
import { QueryCriteriaFieldRequest } from '@gandalf/model/query-criteria-field-request';
import { QueryCriteriaFieldResponse } from '@gandalf/model/query-criteria-field-response';
import { QueryFieldResponse } from '@gandalf/model/query-field-response';
import { QueryResponse } from '@gandalf/model/query-response';
import { QuerySelectFieldRequest } from '@gandalf/model/query-select-field-request';
import { QuerySortFieldRequest } from '@gandalf/model/query-sort-field-request';
import { QuerySummaryResponse } from '@gandalf/model/query-summary-response';
import { QueryTriggerResponse } from '@gandalf/model/query-trigger-response';
import { QueryTriggerSummaryResponse } from '@gandalf/model/query-trigger-summary-response';
import { UpdateCustomQueryRequest } from '@gandalf/model/update-custom-query-request';
import { UpdateQueryRequest } from '@gandalf/model/update-query-request';
import { UpdateQueryTriggerRequest } from '@gandalf/model/update-query-trigger-request';
import {
	QUERY_BUILDER_DATA_SOURCE_CONFIGS,
	QUERY_BUILDER_FIELD_CONFIGS,
	QueryCriteriaOperandComponent
} from '@shared/component/query/query-builder/query-builder.constants';
import { QueryTriggerSearchResultsComponent } from '@shared/component/query/query-builder/query-trigger-search-results/query-trigger-search-results.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { arrayLengthValidator } from '@shared/validators/array-length-validation';
import { atLeastOne } from '@shared/validators/atleastOne.validation';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { DragAndDropEventArgs } from '@syncfusion/ej2-navigations';
import { FieldsSettingsModel } from '@syncfusion/ej2-navigations/src/treeview/treeview-model';
import { GandalfConstant, GandalfConstantList, GandalfFormBuilder } from 'gandalf';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';

export interface QueryCriteriaOption {
	label: string;
	value: string;
}

export class QueryCriteriaFieldData {
	id: string;
	label: string;
	type: QueryFieldType;
	operator: QueryCriteriaOperator;
	operand1: any;
	operand2: any;
	operators: GandalfConstantList<GandalfConstant<string>>;
	operandComponent: QueryCriteriaOperandComponent;
	options: QueryCriteriaOption[];
	uid: string;
}

@Component({
	selector: 'pms-query-builder',
	templateUrl: './query-builder.component.html',
	styles: [],
})
export class QueryBuilderComponent implements OnInit {

	@Input()
	queryTemplateCategory: QueryTemplateCategory;

	@Input()
	categoryEntityType: CategoryEntityType;

	@Input()
	backButtonText: string;

	@Input()
	categories: CategoryResponseForDropdown[];

	@Output()
	queryUpdated = new EventEmitter<QuerySummaryResponse>();

	@Output()
	queryTriggerUpdated = new EventEmitter<QueryTriggerSummaryResponse>();

	@ViewChild('avaiableFieldsTree')
	avaiableFieldsTree: TreeViewComponent;

	@ViewChild('searchCriteriaGrid')
	searchCriteriaGrid: GridComponent;

	@ViewChild('resultFieldsGrid')
	resultFieldsGrid: GridComponent;

	@ViewChild('sortFieldsGrid')
	sortFieldsGrid: GridComponent;

	@ViewChild('form')
	form: NgForm;

	@ViewChild('queryTriggerSearchResults')
	queryTriggerSearchResults: QueryTriggerSearchResultsComponent;

	open = false;
	roles: SecurityRoleDropdownResponse[];
	availableFields: TreeNode[];
	availableFieldsModel: FieldsSettingsModel;
	formGroup: UntypedFormGroup;
	criteriaFields: QueryCriteriaFieldData[];
	querySortDirectionValues = QuerySortDirection.VALUES.values;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	queryCriteriaWithinUnitValues = QueryCriteriaWithinUnit.VALUES;
	dateRangeTypeValues: GandalfConstantList<DateRangeType> = {
		label: 'Date Range Type',
		values: [
			DateRangeType.TODAY,
			DateRangeType.YESTERDAY,
			DateRangeType.WEEK_TO_DATE,
			DateRangeType.MONTH_TO_DATE,
			DateRangeType.QUARTER_TO_DATE,
			DateRangeType.YEAR_TO_DATE,
			DateRangeType.LAST_WEEK,
			DateRangeType.LAST_MONTH,
			DateRangeType.LAST_QUARTER,
			DateRangeType.LAST_YEAR,
		],
	};
	queryBooleanValues = QueryCriteriaBoolean.VALUES.values;
	providers: ProviderDropdownResponse[];
	employees: EmployeeDropdownResponse[];
	templates: EncounterTemplateForDropdown[];
	locations: OptionItemResponse<PracticeLocation, number>[];
	operandComponent = QueryCriteriaOperandComponent;
	request: CreateQueryRequest | UpdateQueryRequest | CreateQueryTriggerRequest | UpdateQueryTriggerRequest;
	isNormalQuery: boolean;
	isTrigger: boolean;
	isPatientTrigger: boolean;
	queryTrigger: QueryTriggerResponse;

	constructor(
		private categoryService: CategoryService,
		private securityRoleService: SecurityRoleService,
		private queryService: QueryService,
		private queryTriggerService: QueryTriggerService,
		private gandalfFormBuilder: GandalfFormBuilder,
		private referenceDataService: ReferenceDataService,
		private userLocationService: UserLocationsService,
		private providerService: ProviderService,
		private employeeService: EmployeeService,
		private encounterTemplateService: EncounterTemplateService,
		private datePipe: DatePipe,
	) {
	}

	ngOnInit() {
		combineLatest([
			this.securityRoleService.findSecurityRolesForDropdown(),
			this.queryService.getTemplateCategoryTree(this.queryTemplateCategory),
			this.providerService.findPracticeProvidersForDropdown(),
			this.employeeService.findActiveEmployeesForDropdown(),
			this.encounterTemplateService.findActiveEncounterTemplatesForDropdown(),
		]).subscribe(([roles, templateCategoryTree, providers, employees, templates]) => {
			this.roles = roles;
			this.availableFields = templateCategoryTree.treeNodes;
			this.availableFieldsModel = {
				dataSource: this.availableFields,
				id: 'id',
				text: 'label',
				tooltip: 'label',
				parentID: 'parentId',
				hasChildren: 'hasChildren',
			};
			this.providers = providers;
			this.employees = employees;
			this.templates = templates;
		});
		this.locations = this.userLocationService.getUserLocations();
	}

	newQuery() {
		const request = new CreateQueryRequest();
		request.templateCategory = this.queryTemplateCategory;
		request.roleIds = this.roles.map(role => role.id);
		request.criteriaFields = [];
		request.selectFields = [];
		request.sortFields = [];
		this.openForm(request, [], true, false);
	}

	newQueryTrigger() {
		const request = new CreateQueryTriggerRequest();
		request.templateCategory = this.queryTemplateCategory;
		request.criteriaFields = [];
		request.carePlanTemplateIds = [];
		request.carePlanItemTemplateIds = [];
		this.initTrigger(null);
		this.openForm(request, [], true, true);
	}

	initTrigger(trigger: QueryTriggerResponse) {
		this.queryTrigger = trigger;
		if (this.queryTriggerSearchResults) {
			this.queryTriggerSearchResults.updateTrigger(trigger);
		}
	}

	editQuery(queryId) {
		this.queryService.getQueryById(queryId).subscribe(query =>
			this.loadCriteriaFields(query).subscribe(criteriaFields => this.openQuery(query, criteriaFields, false)),
		);
	}

	editTrigger(queryTriggerId) {
		this.queryTriggerService.getQueryTriggerById(queryTriggerId).subscribe(trigger =>
			this.loadCriteriaFields(trigger.query).subscribe(criteriaFields => this.openTrigger(trigger, criteriaFields, false)),
		);
	}

	copyQuery(queryId) {
		this.queryService.getQueryById(queryId).subscribe(query =>
			this.loadCriteriaFields(query).subscribe(criteriaFields => this.openQuery(query, criteriaFields, true)),
		);
	}

	copyTrigger(queryTriggerId) {
		this.queryTriggerService.getQueryTriggerById(queryTriggerId).subscribe(trigger =>
			this.loadCriteriaFields(trigger.query).subscribe(criteriaFields => this.openTrigger(trigger, criteriaFields, true)),
		);
	}

	save() {
		// sync search criteria data to the form since it is managed in a separate variable
		this.updateGridFormValue(this.searchCriteriaGrid);
		if (this.formGroup.invalid) {
			return;
		}
		if (this.isTrigger) {
			let updatedTrigger: Observable<QueryTriggerSummaryResponse>;
			if (_isNil(this.formGroup.value.id)) {
				updatedTrigger = this.queryTriggerService.createQueryTrigger(this.formGroup.value);
			} else {
				updatedTrigger = this.queryTriggerService.updateQueryTrigger(this.formGroup.value);
			}
			updatedTrigger.subscribe(trigger => this.close(null, trigger));
		} else {
			let updatedQuery: Observable<QuerySummaryResponse>;
			if (this.queryTemplateCategory === QueryTemplateCategory.CUSTOM_REPORTS) {
				// a custom report can only be updated; and only some of the fields may be changed
				const request = new UpdateCustomQueryRequest();
				request.id = this.formGroup.value.id;
				request.roleIds = this.formGroup.value.roleIds;
				request.criteriaFields = this.formGroup.value.criteriaFields;
				updatedQuery = this.queryService.updateCustomQuery(request);
			} else if (_isNil(this.formGroup.value.id)) {
				updatedQuery = this.queryService.createQuery(this.formGroup.value);
			} else {
				updatedQuery = this.queryService.updateQuery(this.formGroup.value);
			}
			updatedQuery.subscribe(query => this.close(query));
		}
	}

	close(updatedQuery?: QuerySummaryResponse, updatedTrigger?: QueryTriggerSummaryResponse) {
		this.open = false;
		if (updatedQuery) {
			this.queryUpdated.emit(updatedQuery);
		}
		if (updatedTrigger) {
			this.queryTriggerUpdated.emit(updatedTrigger);
		}
	}

	loadCriteriaFields(query: QueryResponse): Observable<QueryCriteriaFieldData[]> {
		if (query.criteriaFields && query.criteriaFields.length) {
			// wait for all query criteria selection options to load
			/* eslint-disable-next-line deprecation/deprecation */
			return combineLatest(query.criteriaFields.map(queryCriteriaField => {
				const fieldNode = this.availableFields.find(node => node.id === queryCriteriaField.id);
				// some custom report fields may not map to a field definition
				return this.buildCriteriaFieldData(fieldNode ? fieldNode.data : null, queryCriteriaField);
			}));
		} else {
			return of([]);
		}
	}

	openQuery(query: QueryResponse, criteriaFields: QueryCriteriaFieldData[], copy: boolean) {
		let request: CreateQueryRequest | UpdateQueryRequest;
		if (copy) {
			request = new CreateQueryRequest();
			request.templateCategory = this.queryTemplateCategory;
			request.name = `Copy of ${query.name}`;
		} else {
			request = new UpdateQueryRequest();
			request.id = query.id;
			request.name = query.name;
		}
		request.description = query.description;
		request.roleIds = query.roleIds;
		request.criteriaFields = this.getQueryCriteriaFields(criteriaFields);
		request.selectFields = query.selectFields.map(selectResponse => {
			const querySelectField = new QuerySelectFieldRequest();
			querySelectField.id = selectResponse.id;
			querySelectField.label = selectResponse.label;
			querySelectField.align = selectResponse.align;
			querySelectField.addressLabel = selectResponse.addressLabel;
			querySelectField.width = selectResponse.width;
			return querySelectField;
		});
		request.sortFields = query.sortFields.map(sortResponse => {
			const querySortField = new QuerySortFieldRequest();
			querySortField.id = sortResponse.id;
			querySortField.label = sortResponse.label;
			// assume ascending if sort direction not specified
			querySortField.direction = sortResponse.direction || QuerySortDirection.ASCENDING;
			return querySortField;
		});
		request.categoryId = query.categoryId;
		this.openForm(request, criteriaFields, query.type === QueryType.NORMAL, false);
	}

	openTrigger(trigger: QueryTriggerResponse, criteriaFields: QueryCriteriaFieldData[], copy: boolean) {
		let request: CreateQueryTriggerRequest | UpdateQueryTriggerRequest;
		if (copy) {
			request = new CreateQueryTriggerRequest();
			request.templateCategory = this.queryTemplateCategory;
			request.name = `Copy of ${trigger.query.name}`;
		} else {
			request = new UpdateQueryTriggerRequest();
			request.id = trigger.id;
			request.name = trigger.query.name;
		}
		request.description = trigger.query.description;
		request.criteriaFields = this.getQueryCriteriaFields(criteriaFields);
		request.categoryId = trigger.query.categoryId;
		request.carePlanTemplateIds = trigger.carePlanTemplates.map(template => template.id);
		request.carePlanItemTemplateIds = trigger.carePlanItemTemplates.map(item => item.id);
		request.interventionBibliographicCitation = trigger.interventionBibliographicCitation;
		request.interventionDeveloper = trigger.interventionDeveloper;
		request.interventionDeveloperFunding = trigger.interventionDeveloperFunding;
		request.interventionReleaseDate = trigger.interventionReleaseDate;
		request.interventionRevisionDate = trigger.interventionRevisionDate;
		this.initTrigger(trigger);
		this.openForm(request, criteriaFields, true, true);
	}

	openForm(
		request: CreateQueryRequest | UpdateQueryRequest | CreateQueryTriggerRequest | UpdateQueryTriggerRequest,
		criteriaFields: QueryCriteriaFieldData[],
		isNormalQuery: boolean,
		isTrigger: boolean,
	) {
		if (this.open) {
			// need to reset form & validation if query builder already open
			this.form.resetForm(this.request);
		}
		this.request = request;
		this.criteriaFields = criteriaFields;
		this.isNormalQuery = isNormalQuery;
		this.isTrigger = isTrigger;
		this.isPatientTrigger = isTrigger && EnumUtil.equals(this.queryTemplateCategory, QueryTemplateCategory.PATIENT_TRIGGER);
		// currently a trigger can only contain a normal query
		if (this.isTrigger) {
			// at least one search criteria and care plan template/item are required to save a query trigger
			this.formGroup = this.gandalfFormBuilder.group(request, {
				validators: [
					arrayLengthValidator('criteriaFields', 1, 'criteriaFieldsLength', 'Search Criteria Field is required'),
					atLeastOne(Validators.required, ['carePlanTemplateIds', 'carePlanItemTemplateIds'], 'Care Plan Template or Care Plan Item required'),
				],
			}, true, false);
		} else if (this.isNormalQuery) {
			// at least one search criteria and result field are required to save a normal query
			this.formGroup = this.gandalfFormBuilder.group(request, {
				validators: [
					arrayLengthValidator('criteriaFields', 1, 'criteriaFieldsLength', 'Search Criteria Field is required'),
					arrayLengthValidator('selectFields', 1, 'selectFieldsLength', 'Results Field is required'),
				],
			}, true, false);
		} else {
			// search criteria and result field validation not required for sql queries
			this.formGroup = this.gandalfFormBuilder.group(request, {}, true, false);
		}
		this.open = true;
	}

	availableFieldDragStart(event: DragAndDropEventArgs) {
		// select the dragged node if it is not selected
		const draggedId = event.draggedNodeData.id as string;
		if (!this.avaiableFieldsTree.selectedNodes.includes(draggedId)) {
			this.avaiableFieldsTree.selectedNodes = [draggedId];
		}
		// cancel the drag event if dragging a category (has children fields)
		if (this.getSelectedNodes().find(node => node.hasChildren)) {
			event.cancel = true;
		}
	}

	getSelectedNodes() {
		return this.avaiableFieldsTree.selectedNodes.map(nodeId => this.availableFields.find(node => node.id === nodeId));
	}

	availableFieldDragging(event: DragAndDropEventArgs) {
		// only show drop-in indicator if drop allowed on target grid; dropping within the available fields tree is not allowed.
		const grid = this.findTargetGrid(event);
		event.dropIndicator = this.dropAllowed(grid) ? 'e-drop-in' : 'e-no-drop';
	}

	availableFieldDragStop(event: DragAndDropEventArgs) {
		// always cancel the drag event: adding to the grid will be handled below
		// and dropping within the available fields tree is not allowed.
		event.cancel = true;
		const grid = this.findTargetGrid(event);
		if (this.dropAllowed(grid)) {
			let index = 0;
			const targetRowInfo = grid.getRowInfo(event.target);
			if (targetRowInfo) {
				// insert selected field(s) below the target grid row
				index = targetRowInfo.rowIndex + 1;
			}
			this.getSelectedNodes().forEach(node => this.addField(grid, index++, node.data));
		}
	}

	findTargetGrid(event: DragAndDropEventArgs) {
		// find the droppable area that contains the target and return its grid
		const droppableTarget = event.target.closest('#searchCriteria,#resultFields,#sortFields');
		switch (droppableTarget ? droppableTarget.id : null) {
			case 'searchCriteria':
				return this.searchCriteriaGrid;
			case 'resultFields':
				return this.resultFieldsGrid;
			case 'sortFields':
				return this.sortFieldsGrid;
			default:
				return null;
		}
	}

	dropAllowed(grid: GridComponent) {
		switch (grid) {
			case this.searchCriteriaGrid:
				return !this.getSelectedNodes().find(node => !node.data.criteria);
			case this.resultFieldsGrid:
				return !this.getSelectedNodes().find(node => !node.data.selectable);
			case this.sortFieldsGrid:
				return !this.getSelectedNodes().find(node => !node.data.orderable);
			default:
				return false;
		}
	}

	addField(grid: GridComponent, index, queryField: QueryFieldResponse) {
		switch (grid) {
			case this.searchCriteriaGrid:
				// wait for criteria select options to load
				this.buildCriteriaFieldData(queryField, null).subscribe(criteriaField => this.addGridRecord(grid, criteriaField, index));
				break;
			case this.resultFieldsGrid: {
				const selectField = new QuerySelectFieldRequest();
				selectField.id = queryField.id;
				selectField.label = queryField.label;
				this.addGridRecord(grid, selectField, index);
				break;
			}
			case this.sortFieldsGrid: {
				const sortField = new QuerySortFieldRequest();
				sortField.id = queryField.id;
				sortField.label = queryField.label;
				sortField.direction = QuerySortDirection.ASCENDING;
				this.addGridRecord(grid, sortField, index);
				break;
			}
		}
	}

	addGridRecord(grid: GridComponent, field: any, index) {
		grid.addRecord(field, index);
		this.updateGridFormValue(grid);
	}

	/**
	 * Set the appropriate control value for the given grid. This is required for validation because changes to the grid data source do not
	 * trigger form validation updates.
	 */
	updateGridFormValue(grid: GridComponent) {
		switch (grid) {
			case this.searchCriteriaGrid:
				// search criteria data is in a separate variable so we need to manually build the request objects
				this.formGroup.controls.criteriaFields.setValue(this.getQueryCriteriaFields(this.criteriaFields));
				break;
			case this.resultFieldsGrid:
				// result fields grid data source contains the request objects
				this.formGroup.controls.selectFields.setValue(grid.dataSource);
				break;
			case this.sortFieldsGrid:
				// sort fields grid data source contains the request objects
				this.formGroup.controls.sortFields.setValue(grid.dataSource);
				break;
		}
	}

	getQueryCriteriaFields(criteriaFields: QueryCriteriaFieldData[]) {
		return criteriaFields.map(criteriaField => {
			const queryCriteriaField = new QueryCriteriaFieldRequest();
			queryCriteriaField.id = criteriaField.id;
			queryCriteriaField.type = criteriaField.type;
			queryCriteriaField.label = criteriaField.label;
			queryCriteriaField.operator = criteriaField.operator;
			// convert operand objects to strings
			queryCriteriaField.operand1 = this.formatOperand(criteriaField.operand1);
			queryCriteriaField.operand2 = this.formatOperand(criteriaField.operand2);
			queryCriteriaField.outerOperator = QueryCriteriaOuterOperator.AND;
			queryCriteriaField.operand1Label = null;
			return queryCriteriaField;
		});
	}

	buildCriteriaFieldData(queryField: QueryFieldResponse, queryCriteriaField: QueryCriteriaFieldResponse) {
		const criteriaField = new QueryCriteriaFieldData();
		// create unique ID for each field; required to refresh the row when changing the operator
		criteriaField.uid = this.generateUid();
		if (queryCriteriaField) {
			// loading an existing criteria field; note queryField may be null if the field has no definition (custom report field)
			criteriaField.id = queryCriteriaField.id;
			criteriaField.label = queryCriteriaField.label;
			criteriaField.type = queryCriteriaField.type;
			criteriaField.operator = queryCriteriaField.operator;
		} else {
			// adding new criteria via drag and drop; we only have the definition to work with
			criteriaField.id = queryField.id;
			criteriaField.label = queryField.label;
			criteriaField.type = queryField.type;
			criteriaField.operator = null;
			criteriaField.operand1 = null;
			criteriaField.operand2 = null;
		}
		// determines list of valid operators
		const fieldConfig = QUERY_BUILDER_FIELD_CONFIGS.find(config => config.fieldType === criteriaField.type);
		criteriaField.operators = {
			label: QueryCriteriaOperator.VALUES.label,
			values: fieldConfig.operatorConfigs.map(operatorConfig => operatorConfig.operator),
		};
		// determines which component(s) to display
		criteriaField.operandComponent = criteriaField.operator
			? fieldConfig.operatorConfigs.find(config => config.operator === criteriaField.operator).operandComponent
			: null;
		if (queryCriteriaField) {
			// convert operands from strings to the correct types for the component
			criteriaField.operand1 = this.parseOperand(criteriaField.operandComponent, queryCriteriaField.operand1, true);
			criteriaField.operand2 = this.parseOperand(criteriaField.operandComponent, queryCriteriaField.operand2, false);
		}
		if (criteriaField.type === QueryFieldType.SELECT) {
			// potentially wait for dynamic select options to load
			return this.getSelectOptions(queryField).pipe(
				map(options => {
					// add field that contains static or dynamic options list
					criteriaField.options = options;
					return criteriaField;
				}),
			);
		} else {
			// only SELECT field can have options
			criteriaField.options = [];
			return of(criteriaField);
		}
	}

	parseOperand(component: QueryCriteriaOperandComponent, operand: string, firstOperand: boolean) {
		// empty string means operand was not entered in the legacy code
		if (_isNil(operand) || operand === '') {
			return null;
		}
		switch (component) {
			case QueryCriteriaOperandComponent.NUMBER:
			case QueryCriteriaOperandComponent.NUMBER_RANGE:
				return Number(operand);
			case QueryCriteriaOperandComponent.DATE:
			case QueryCriteriaOperandComponent.DATE_RANGE:
				// format is MM/dd/yyyy
				return new Date(operand);
			case QueryCriteriaOperandComponent.DATE_STANDARD_RANGE:
				return EnumUtil.findEnumByValue(Number(operand), DateRangeType);
			case QueryCriteriaOperandComponent.DATE_WITHIN:
				return firstOperand ? Number(operand) : EnumUtil.findEnumByValue(Number(operand), QueryCriteriaWithinUnit);
			case QueryCriteriaOperandComponent.BOOLEAN:
				// any value except 'true' will be treated as FALSE
				return EnumUtil.findEnumByValue(operand, QueryCriteriaBoolean) || QueryCriteriaBoolean.FALSE;
			case QueryCriteriaOperandComponent.SELECT:
			case QueryCriteriaOperandComponent.TEXT:
			default:
				return operand;
		}
	}

	/**
	 * Format an operand object to a string for a QueryCriteriaFieldRequest operand.
	 */
	formatOperand(operand: any) {
		if (_isNil(operand)) {
			// empty string means operand was not entered in the legacy code
			return '';
		} else if (operand instanceof Date) {
			return this.datePipe.transform(operand, this.dateFormat);
		} else if (operand instanceof GandalfConstant) {
			return operand.value.toString();
		} else {
			return operand.toString();
		}
	}

	removeField(grid: GridComponent, data: any) {
		// grid has no primary key column; manually remove from data source and refresh
		(grid.dataSource as []).splice(data.index, 1);
		grid.refresh();
		this.updateGridFormValue(grid);
	}

	onSortFieldChange(data: any) {
		// column template has a modified copy of the field; need to update the source as well
		const field = this.formGroup.value.sortFields[data.index];
		field.direction = data.direction;
	}

	onCriteriaOperatorChange(data: any) {
		// column template has a modified copy of the field; need to update the source as well
		const field = this.criteriaFields[data.index];
		if (field.operator !== data.operator) {
			field.operator = data.operator;
			// clear out existing operands; except for boolean fields default to FALSE a.k.a. "No"
			field.operand1 = field.type === QueryFieldType.BOOLEAN ? QueryCriteriaBoolean.FALSE : null;
			field.operand2 = null;
			// update field that determines which operand component(s) to display
			const fieldConfig = QUERY_BUILDER_FIELD_CONFIGS.find(config => config.fieldType === field.type);
			field.operandComponent = fieldConfig.operatorConfigs.find(config => config.operator === field.operator).operandComponent;
			// operand components are in a different custom column: need to refresh the row to show/hide them
			this.searchCriteriaGrid.setRowData(field.uid, field);
		}
	}

	onCriteriaOperandChange(data: any) {
		// column template has a modified copy of the field; need to update the source as well
		const field = this.criteriaFields[data.index];
		field.operand1 = data.operand1;
		field.operand2 = data.operand2;
	}

	getSelectOptions(queryField: QueryFieldResponse): Observable<QueryCriteriaOption[]> {
		const dataSourceConfig = queryField.dataSourceList ?
			QUERY_BUILDER_DATA_SOURCE_CONFIGS.find(config => config.dataSourceList === queryField.dataSourceList) :
			null;
		let optionsSource: Observable<any[]>;
		switch (queryField.dataSource) {
			case QueryFieldDataSource.INLINE:
				optionsSource = of(queryField.options);
				break;
			case QueryFieldDataSource.PROVIDER:
				optionsSource = of(this.providers);
				break;
			case QueryFieldDataSource.TEMPLATE:
				optionsSource = of(this.templates);
				break;
			case QueryFieldDataSource.EMPLOYEE:
				optionsSource = of(this.employees);
				break;
			case QueryFieldDataSource.LOCATION:
				optionsSource = of(this.locations);
				break;
			case QueryFieldDataSource.REFERENCE_DATA:
				optionsSource = this.flattenAll(dataSourceConfig.referenceDataCategories.map(category =>
					this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(category.value)));
				break;
			case QueryFieldDataSource.MASTER_REFERENCE_DATA:
				optionsSource = this.flattenAll(dataSourceConfig.referenceDataCategories.map(category =>
					this.referenceDataService.getActiveMasterReferenceDataByCategory(category)));
				break;
			case QueryFieldDataSource.CATEGORY:
				optionsSource = this.flattenAll(dataSourceConfig.categoryEntityTypes.map(entityType =>
					this.categoryService.findActiveByEntityType(entityType)));
				break;
			default:
				optionsSource = of([]);
				break;
		}
		// prepend All option if needed
		if (queryField.allowAll) {
			optionsSource = optionsSource.pipe(
				map(options => [{label: 'All', value: 0}].concat(options)),
			);
		}
		// convert all option values to strings
		return optionsSource.pipe(
			map(options => options.map(option => ({label: option.label, value: option.value.toString()}))),
		);
	}

	// flatten an array of Observable arrays into a single Observable array
	flattenAll<T>(sources: Observable<T[]>[]): Observable<T[]> {
		return combineLatest(sources).pipe(
			map(results => [].concat(...results)),
		);
	}

	generateUid() {
		return uuidv4();
	}

}
