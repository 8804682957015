<ejs-dialog #diagnosisAddModal
			(close)="closeModal()">
	<ng-template #header>
		<div class="dlg-template">Select Diagnosis</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature">
			<form #diagnosisAddForm="ngForm"
				  class="form-inline"
				  [formGroup]="formGroup"
				  (ngSubmit)="searchSnomedCode()">
				<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && diagnosisAddForm.submitted"
					[form]="formGroup" [requestObj]="snomedSearchRequest">
				</gandalf-lib-validation-messages>
				<div class="form-group">
					<label>Code</label>
					<input
						class="e-input"
						type="text"
						revDigitOnlyInput
						placeholder="Search"
						(keyup.enter)="searchSnomedCode()"
						formControlName="code">
				</div>
				<div class="form-group">
					<label>Description</label>
					<input
						class="e-input"
						type="text"
						placeholder="Search"
						(keyup.enter)="searchSnomedCode()"
						formControlName="description">
				</div>
				<div class="form-group">
					<button ejs-button type="submit" iconCss="fa fa-search" [isPrimary]="true">Search</button>
					<button ejs-button type="button" iconCss="fa fa-close"
							(click)="clearSearch()">
						Clear
					</button>
				</div>
			</form>
		</div>
		<div *ngIf="snomedCodes?.length >= 100" class="alert alert-warning">
			Search returned more than 100 results, please refine your search.
		</div>
		<ejs-grid #searchResultsGrid
				  [allowFiltering]="false"
				  [allowExcelExport]="false"
				  [allowPaging]="true"
				  [pageSettings]="pageSettings"
				  [allowReordering]="false"
				  [allowResizing]="false"
				  [allowRowDragAndDrop]="false"
				  [allowSelection]="true"
				  [allowSorting]="true"
				  class="table-rowlink"
				  [dataSource]="snomedCodes"
				  autoFitColumns
				  [autoFitBlacklist]="['description']"
				  gridLines="Horizontal"
				  [pmsGridSubmit]="isSearching"
				  (rowSelected)="closeModal($event.data)">
			<e-columns>
				<e-column field="code" headerText="Code"></e-column>
				<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<div>
			<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()">Cancel</button>
		</div>
	</ng-template>
</ejs-dialog>

