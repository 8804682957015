// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.PaymentGroupListResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentGroupListResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount: number;

	@GandalfProperty()
	payerName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	paymentDate: Date;

	@GandalfProperty()
	paymentGroupId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentGroupSourceTypeSearch: constants.PaymentGroupSourceTypeSearch;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentMethodType: constants.PaymentMethodType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentStatus: constants.PaymentGroupStatus;

	@GandalfProperty()
	referenceNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	sourceType: constants.PaymentGroupSourceType;

}
