/* eslint-disable max-len */

import { ITableState, TableState } from '@app-store/reducers';
import { LegacyStatefulComponentUtilsService } from '@app-store/utils/legacy-stateful-component-utils.service';
import { _cloneDeep } from '@core/lodash/lodash';
import { PatientSearchActions, PatientSearchActionsTypes } from '@patients-store/actions';
import { LegacyStatefulComponentBaseState } from '@shared/decorators/legacy-stateful-component.decorator';
import { SearchMode } from '@shared/interfaces/forms';

export class PatientSimpleSearchState extends LegacyStatefulComponentBaseState {}
export class PatientAdvancedSearchState extends LegacyStatefulComponentBaseState {}
export class PatientLastSearchedState extends LegacyStatefulComponentBaseState {}

export interface PatientSearchState {
	searchMode: SearchMode;
	simpleSearchForm: PatientSimpleSearchState;
	advancedSearchForm: PatientAdvancedSearchState;
	lastSearchedForm: PatientLastSearchedState;
	tableState: ITableState;
}

export const initialState = {
	searchMode: SearchMode.SIMPLE,
	simpleSearchForm: new PatientSimpleSearchState(),
	advancedSearchForm: new PatientAdvancedSearchState(),
	lastSearchedForm: new PatientLastSearchedState(),
	tableState: new TableState(),
};

export function reducer(state = initialState, action: PatientSearchActions): PatientSearchState {
	switch (action.type) {
		case PatientSearchActionsTypes.SET_SEARCH_MODE: {
			const patientSearchState: PatientSearchState = _cloneDeep(state);
			patientSearchState.searchMode = action.payload.searchMode;

			return patientSearchState;
		}

		case PatientSearchActionsTypes.UPDATE_PATIENT_SIMPLE_SEARCH_FORM_VALUE: {
			const patientSearchState = _cloneDeep(state);
			patientSearchState.simpleSearchForm = LegacyStatefulComponentUtilsService.updateFormValue(state.simpleSearchForm, PatientSimpleSearchState, action.payload);
			return patientSearchState;
		}

		case PatientSearchActionsTypes.UPDATE_PATIENT_ADVANCED_SEARCH_FORM_VALUE: {
			const patientSearchState = _cloneDeep(state);
			patientSearchState.advancedSearchForm = LegacyStatefulComponentUtilsService.updateFormValue(state.advancedSearchForm, PatientAdvancedSearchState, action.payload);
			return patientSearchState;
		}

		case PatientSearchActionsTypes.UPDATE_PATIENT_LAST_SEARCH_FORM_VALUE: {
			const patientSearchState = _cloneDeep(state);
			patientSearchState.lastSearchedForm = LegacyStatefulComponentUtilsService.updateFormValue(state.lastSearchedForm, PatientAdvancedSearchState, action.payload);
			return patientSearchState;
		}

		case PatientSearchActionsTypes.UPDATE_PATIENT_SEARCH_TABLE_CONFIG_VALUE: {
			const patientSearchState = _cloneDeep(state);
			patientSearchState.tableState.tableConfigurationJson = action.payload;
			return patientSearchState;
		}

		case PatientSearchActionsTypes.UPDATE_PATIENT_SEARCH_TABLE_RESULTS: {
			const patientSearchState = _cloneDeep(state);
			patientSearchState.tableState.tableResults = action.payload.value;
			patientSearchState.tableState.isSearched = !action.payload.cleared;

			return patientSearchState;
		}

		default: {
			return state as PatientSearchState;
		}
	}
}
