<ejs-dialog
	#modal
	(close)="closeDialog()"
    [attr.data-test-id]="'alertHistoryModal'">
	<ng-template #header>
		<div class="dlg-template">Alert History</div>
	</ng-template>
	<ng-template #content>
		<ejs-grid
			#grid
			[dataSource]="patientAlerts"
			[allowSorting]="true"
			[allowSelection]="false"
			[allowFiltering]="true"
			(created)="(applyFilter())"
			gridLines="Horizontal"
			[attr.data-test-id]="'alertHistoryModalTable'">
			<ng-template #toolbarTemplate>
				<rev-table-action-menu [table]="grid">
					<div rev-table-action-menu-left class="e-toolbar-item">
						<ejs-checkbox label="Modal Alerts Only" (change)="filterAlertsByModalDisplayType($event)" *ngIf="modalType === modalTypes.DISPLAY_TYPE" [checked]="modalFiltered"></ejs-checkbox>
						<ejs-checkbox label="Active Alerts Only" (change)="filterAlertsByActiveStatus($event)" *ngIf="modalType === modalTypes.STATUS" [checked]="modalFiltered" [attr.data-test-id]="'alertHistoryModalActiveAlertsCheckbox'"></ejs-checkbox>
					</div>
				</rev-table-action-menu>
			</ng-template>
			<e-columns>
				<e-column field="severity.label" headerText="" [width]="35">
					<ng-template #template let-alert>
						<i class="fa fa-flag text-{{alert.severity.label | severityClass}}" revTooltip tooltipContent="{{alert.severity.label}}"></i>
					</ng-template>
				</e-column>
				<e-column field="createdOn" headerText="Created On" format="yMd" [width]="120"></e-column>
				<e-column field="category" headerText="Category" [width]="120"></e-column>
				<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
				<e-column field='displayType.label' headerText='DisplayType' [visible]='false'></e-column>
				<e-column field='status.label' headerText='Status' [visible]='false'></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button
				ejs-button
				type="button"
				iconCss="fa fa-times"
				(click)="closeDialog()"
				class="pull-right"
				[attr.data-test-id]="'alertHistoryModalCloseButton'">Close
		</button>
	</ng-template>
</ejs-dialog>
