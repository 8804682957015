import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { StatefulRouteService } from '@app-store/services/stateful-route.service';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { HIT_PMS_HTML_EVENTS, LOAD_MODULE_WITH_ACTION } from '@core/legacy/hit-pms-html.constants';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { _isNil } from '@core/lodash/lodash';
import { MedicationPrescriptionType, OrderProcessorType, OrderType, SmartflowMasterSupplierType } from '@gandalf/constants';
import { EnumUtil } from 'morgana';
import { RouteConstants } from '@core/router-utils/route-constants';
import { APP_ROUTING_CONSTANTS, APP_ROUTING_URL_CONSTANTS } from '../../app-routing.constants';
import { MedicationPrescriptionService } from '../../patients/medication-prescription/medication-prescription.service';
import { PatientMedicationRoutes, PatientViews, PatientViewTabs } from '../../patients/patient-views.constant';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {

	constructor(
		public eventsManagerService: EventsManagerService,
		public featureService: FeatureService,
		private router: Router,
		private ngZone: NgZone,
		public medicationPrescriptionService: MedicationPrescriptionService,
	) {
	}

	navigateToTopLevelModule(primaryContext: string, subContext: string) {
		const urlList = [primaryContext];
		if (!_isNil(subContext)) {
			urlList.push(subContext);
		}
		return this.router.navigate(urlList);
	}

	navigateToPatient(patientId: number): Promise<void> {
		return this.eventsManagerService.hideContainerAndPublish(
			HIT_PMS_HTML_EVENTS.PATIENTS.PATIENTS_MODULE_OPEN_PATIENT,
			patientId,
		);
	}

	navigateToInvoice(invoiceId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.ACCOUNTING_INVOICES.url, 'invoice', invoiceId.toString()]);
	}

	async openOrder(orderId: number, orderType: OrderType, isNew: boolean, processorType: OrderProcessorType, vendorSmartflowCode: string): Promise<boolean> {
		if (this.canAccessOrder(orderType, processorType, vendorSmartflowCode)) {
			return this.navigateToOrder(orderId);
		} else {
			await this.eventsManagerService.hideContainerAndPublish(HIT_PMS_HTML_EVENTS.ORDERS.OPEN_ORDER_TO_FLEX, {
				orderId,
				isNew,
			});
			return Promise.resolve(true);
		}
	}

	canAccessOrder(orderType: OrderType, processorType: OrderProcessorType, vendorSmartflowCode: string) {
		let smartflow = false;
		let visionweb = false;
		const flags = [];
		switch (processorType ? processorType.value : null) {
			case OrderProcessorType.SMARTFLOW.value:
				smartflow = true;
				break;
			case OrderProcessorType.VISIONWEB.value:
				visionweb = true;
				break;
			case OrderProcessorType.EYEFINITY.value:
			case OrderProcessorType.EYEFINITY_REV_ASSISTANT.value:
				flags.push(FEATURE_FLAGS.FEATURES.VSP_ESTIMATOR);
				break;
		}
		const twelve84 = vendorSmartflowCode === SmartflowMasterSupplierType.TWELVE84.value;

		switch (orderType.value) {
			case OrderType.FRAME_ONLY.value:
				flags.push(FEATURE_FLAGS.FEATURES.ORDERS.FRAME_ONLY_ORDER);
				break;
			case OrderType.EYEGLASS.value:
				flags.push(FEATURE_FLAGS.FEATURES.ORDERS.EYEGLASS_ORDER);
				if (smartflow) {
					flags.push(FEATURE_FLAGS.FEATURES.ORDERS.SMARTFLOW_EYEGLASS_ORDER);
				}
				if (visionweb) {
					flags.push(FEATURE_FLAGS.FEATURES.ORDERS.VISION_WEB_EYEGLASS_ORDER);
				}
				if (twelve84) {
					flags.push(FEATURE_FLAGS.FEATURES.ORDERS.TWELVE_84);
				}
				break;
			case OrderType.CONTACT_LENS.value:
				flags.push(FEATURE_FLAGS.FEATURES.ORDERS.CONTACT_LENS_ORDER);
				if (smartflow) {
					flags.push(FEATURE_FLAGS.FEATURES.ORDERS.SMARTFLOW_CONTACT_LENS_ORDER);
				}
				if (visionweb) {
					flags.push(FEATURE_FLAGS.FEATURES.ORDERS.VISION_WEB_CONTACT_LENS_ORDER);
				}
				break;
			case OrderType.CONTACT_LENS_TRIAL.value:
				flags.push(FEATURE_FLAGS.FEATURES.ORDERS.CONTACT_LENS_TRIAL_ORDER);
				if (smartflow) {
					flags.push(FEATURE_FLAGS.FEATURES.ORDERS.SMARTFLOW_CONTACT_LENS_TRIAL_ORDER);
				}
				if (visionweb) {
					flags.push(FEATURE_FLAGS.FEATURES.ORDERS.VISION_WEB_CONTACT_LENS_TRIAL_ORDER);
				}
				break;
		}
		// all flags must be on
		return !flags.map(flag => this.featureService.isFeatureOn(flag)).includes(false);
	}

	navigateToOrder(orderId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.ORDERS.url, 'order', orderId.toString()]);
	}

	async navigateToEncounter(encounterId: number, patientId: number): Promise<void> {
		const isEncountersFeatureOn: boolean = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.EMR);
		const isPatientModuleOn: boolean = this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.PATIENTS);
		const patientIdString = patientId.toString();
		const encounterIdString = !_isNil(encounterId) ? encounterId.toString() : undefined;

		if (isEncountersFeatureOn && isPatientModuleOn) {
			if (_isNil(encounterIdString)) {
				await StatefulRouteService.routePastPersistedRoute(
					this.router,
					`${APP_ROUTING_URL_CONSTANTS.PATIENTS.url}/patient/${patientIdString}/encounters`,
				);
			} else {
				await StatefulRouteService.routePastPersistedRoute(
					this.router,
					`${APP_ROUTING_URL_CONSTANTS.PATIENTS.url}/patient/${patientIdString}/encounters/encounter/${encounterIdString}`,
				);
			}
		} else {
			if (isPatientModuleOn) {
				await this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientIdString]);
				await this.eventsManagerService.hideContainerAndPublish(
					HIT_PMS_HTML_EVENTS.PATIENTS.PATIENT_MODULE_OPEN_ENCOUNTER_NO_HTML,
					{
						patientId,
						encounterId,
					},
				);
			} else {
				await this.eventsManagerService.hideContainerAndPublish(
					HIT_PMS_HTML_EVENTS.PATIENTS.PATIENT_MODULE_OPEN_ENCOUNTER_NO_HTML,
					{
						patientId,
						encounterId,
					},
				);
			}
		}
	}

	async navigateToPatientAppointment(patientId: number, appointmentId: number): Promise<void> {
		const patientIdString = patientId.toString();
		const appointmentIdString = appointmentId.toString();

		if (this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.PATIENTS) && this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.EMR)) {
			await this.ngZone.run(() =>
				StatefulRouteService.routePastPersistedRoute(
					this.router,
					`${APP_ROUTING_URL_CONSTANTS.PATIENTS.url}/patient/${patientIdString}/encounters/appointment/${appointmentIdString}`,
				),
			);
		} else {
			await this.eventsManagerService.hideContainerAndPublish(
				HIT_PMS_HTML_EVENTS.PATIENTS.PATIENT_MODULE_OPEN_APPOINTMENT,
				{
					patientId,
					appointmentId,
				},
			);
		}
	}

	navigateToScheduleAppointment(appointmentId: number) {
		this.eventsManagerService.publish(LOAD_MODULE_WITH_ACTION, {
			module: APP_ROUTING_CONSTANTS.SCHEDULE,
			action: INTER_APP_CONSTANTS.REV360.SCHEDULE.OPEN_SCHEDULE_TO_APPOINTMENT,
			routePastPersistedRoute: true,
			payload: {appointmentId},
		});
	}

	navigateToOrdersOptical(patientId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), 'orders-optical']);
	}

	navigateToCreateEyeglassPrescriptionEMR(route: ActivatedRoute) {
		return this.router.navigate(['../', 'eyeglass-prescription-detail'], {relativeTo: route});
	}

	navigateToParentEyeglassPrescription(route: ActivatedRoute) {
		return this.router.navigate(['./'], {relativeTo: route.parent});
	}

	navigateToUpdateEyeglassPrescription(prescriptionId: number, route: ActivatedRoute) {
		return this.router.navigate(['../', 'eyeglass-prescription-detail', prescriptionId], {relativeTo: route});
	}

	navigateToRefillEyeglassPrescription(prescriptionId: number, route: ActivatedRoute) {
		return this.router.navigate(['../', 'eyeglass-prescription-detail', prescriptionId, 'refill'], {relativeTo: route});
	}

	navigateToDiagnosisHistory(patientId: number, diagnosisId?: number) {
		const queryParams = diagnosisId ? {diagnosisId} : null;

		return this.router.navigate(
			[APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), 'diagnosis-history'],
			{
				queryParams,
			});
	}

	navigateToInsurance(patientId: number, insuranceId?: number) {
		const queryParams = insuranceId ? {insuranceId} : null;

		return this.router.navigate(
			[APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), 'insurance'],
			{
				queryParams,
			});
	}

	navigateToPrescription(prescriptionTabIndex: number, patientId: number, prescriptionId?: number): Promise<boolean> {
		const patientIdString = patientId.toString();
		const prescriptionIdSting = prescriptionId ? prescriptionId.toString() : undefined;

		const selectedTab = Object.values(PatientViewTabs.RX).find(tab => tab.tabIndex === prescriptionTabIndex);
		const urlTabSegments = selectedTab.url.split('/');
		if (prescriptionId) {
			if (selectedTab.detailUrl === PatientViewTabs.RX.MEDICATION.detailUrl) {
				this.medicationPrescriptionService.getMedicationPrescriptionType(prescriptionId).subscribe((response) => {
					if (EnumUtil.equals(response.type, MedicationPrescriptionType.EXTERNAL)) {
						return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
							'patient', patientIdString, ...urlTabSegments, PatientMedicationRoutes.EXTERNAL, prescriptionIdSting]);
					} else if (EnumUtil.equals(response.type, MedicationPrescriptionType.GENERAL)) {
						return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
							'patient', patientIdString, ...urlTabSegments, PatientMedicationRoutes.GENERAL, prescriptionIdSting]);
					} else {
						return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
							'patient', patientIdString, ...urlTabSegments, selectedTab.detailUrl, prescriptionIdSting]);
					}
				});
			} else {
				return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientIdString, ...urlTabSegments, selectedTab.detailUrl, prescriptionIdSting]);
			}
		} else {
			return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientIdString, ...urlTabSegments]);
		}
	}

	navigateToFamilyContacts(patientId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.FAMILY_CONTACTS.url]);
	}

	navigateToOrdersMedical(patientId: number, orderId?: number) {
		const orderIdString = orderId ? orderId.toString() : null;

		return this.router.navigate(
			[APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.ORDERS_MEDICAL.url],
			{
				queryParams: {
					orderId: orderIdString,
				},
			},
		);
	}

	navigateToAddMedicalOrder(patientId: number) {
		return this.router.navigate(
			[APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.ORDERS_MEDICAL.url],
			{
				queryParams: {
					[RouteConstants.CREATE]: patientId,
				},
			},
		);
	}

	navigateToPatientSummary(patientId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.PATIENT_SUMMARY.url]);
	}

	navigateToOpticalSummary(patientId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.OPTICAL_SUMMARY.url]);
	}

	async navigateToPatientRecalls(patientId: number) {
		await this.navigateToPatient(patientId);
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.RECALL.url]);
	}

	navigateToPatientAccountTab(patientId: number, accountingTab: number) {
		return this.ngZone.run(() =>
			StatefulRouteService.routePastPersistedRoute(
				this.router,
				[
					APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
					'patient',
					patientId.toString(),
					PatientViews.ACCOUNT.url,
					'dashboard',
					accountingTab.toString(),
				],
			),
		);
	}

	navigateToPatientOpticalSummaryLatestFindingsTab(patientId: number, latestFindingsTab: number) {
		return this.ngZone.run(() =>
			StatefulRouteService.routePastPersistedRoute(
				this.router,
				[
					APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
					'patient',
					patientId.toString(),
					PatientViews.OPTICAL_SUMMARY.url,
					'latest-findings',
					latestFindingsTab.toString(),
				],
			),
		);
	}

	navigateToLocation(locationId: number) {
		const timestamp = new Date();
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.ADMIN.url, 'general', 'locations'], {
			queryParams: {
				locationId: locationId.toString(),
				timestamp: timestamp.getTime(),
			},
		});
	}

	navigateToPatientTasks(patientId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.TASKS.url]);
	}

	navigateToTask(patientId: number, taskId: number) {
		return this.ngZone.run(() =>
			StatefulRouteService.routePastPersistedRoute(
				this.router,
				[
					APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
					'patient',
					patientId.toString(),
					PatientViews.TASKS.url,
					taskId.toString(),
				],
			),
		);
	}

	navigateToPatientRevConnect(patientId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.CONNECT.url]);
	}

	navigateToRecallList(patientId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.RECALL.url]);
	}

	navigateToRecall(patientId: number, recallId: number) {
		return this.ngZone.run(() =>
			StatefulRouteService.routePastPersistedRoute(
				this.router,
				[
					APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
					'patient',
					patientId.toString(),
					PatientViews.RECALL.url,
					recallId.toString(),
				],
			),
		);
	}

	navigateToNotes(patientId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.PATIENTS.url, 'patient', patientId.toString(), PatientViews.NOTES.url]);
	}

	navigateToAddNote(patientId: number) {
		return this.ngZone.run(() =>
			StatefulRouteService.routePastPersistedRoute(
				this.router,
				[
					APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
					'patient',
					patientId.toString(),
					PatientViews.NOTES.url,
					RouteConstants.CREATE,
				],
			),
		);
	}

	navigateToNote(patientId: number, noteId: number) {
		return this.ngZone.run(() =>
			StatefulRouteService.routePastPersistedRoute(
				this.router,
				[
					APP_ROUTING_URL_CONSTANTS.PATIENTS.url,
					'patient',
					patientId.toString(),
					PatientViews.NOTES.url,
					noteId.toString(),
				],
			),
		);
	}

	navigateToTwoWayText() {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.CONNECT_TWO_WAY_TEXT.url]);
	}

	/**
	 * When router.navigate is called with events, angular doesn't go inside ngzone
	 * it must be wrapped with ngZone.run for it to run properly and load everything
	 * @param commands URL fragments with which to construct the target URL
	 * @param extras object that determines how the URL should be constructed
	 */
	wrappedNavigation(commands: any[], extras?: NavigationExtras) {
		return this.ngZone.run(() => this.router.navigate(commands, extras));
	}
}
