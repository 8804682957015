import {
	ReceivePaymentModalData,
	ReceivePaymentsModalComponent
} from '@accounting/invoices/receive-payments/receive-payments-modal/receive-payments-modal.component';
import { inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UPDATE_PATIENT_INVOICES } from '@app-store/constants/event.constants';
import { StatefulPropertyService } from '@app-store/services/stateful-property.service';
import { StatefulEventUtil } from '@app-store/utils/stateful-event-util';
import { EventService } from '@core/event/event.service';
import { EVENT_MANAGER_CONSTANTS } from '@core/events-manager/events-manager.constants';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _forEach, _isNil } from '@core/lodash/lodash';
import { DialogUtil, ModalManagerService, SortingService, TypeSafeModalManagerService } from 'morgana';
import { PatientService } from '@core/patient/patient.service';
import { SummaryPodService } from '@core/summary-pod/summary-pod.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { PayerType, PaymentGroupSourceType } from '@gandalf/constants';
import { AccountingInvoicePaymentResponse } from '@gandalf/model/accounting-invoice-payment-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { AddInvoicePaymentTab, RemoveInvoiceDetailsState, UpdateInvoiceDetailsShowAll, UpsertInvoiceDetails } from '@invoices-store/actions';
import { InvoiceDetailsState } from '@invoices-store/reducers/invoice-details.reducer';
import {
	selectInvoiceDetailsCurrentTabState,
	selectInvoiceDetailsInvoiceState,
	selectInvoiceDetailsShowAllState,
	selectInvoiceDetailsState,
	selectInvoiceTabStateById
} from '@invoices-store/selectors/invoice.selector';
import { Store } from '@ngrx/store';
import { State } from '@patients-store/reducers';
import { EncounterSelectByPatientModalComponent } from '@shared/component/encounter-select-by-patient-modal/encounter-select-by-patient-modal.component';
import { PatientSearchModalComponent } from '@shared/component/search-patient/patient-search-modal/patient-search-modal.component';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { Observable, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { InvoiceClaimGandalfService, InvoiceGandalfService } from '@gandalf/services';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { InvoiceDashboardSearchRequest } from '@gandalf/model/invoice-dashboard-search-request';
import { CreateInvoiceRequest } from '@gandalf/model/create-invoice-request';
import { UpdateInvoiceProviderRequest } from '@gandalf/model/update-invoice-provider-request';
import { APP_ROUTING_URL_CONSTANTS } from '../../../../app-routing.constants';
import { NewEntityUniqueIdService } from '../../../../services/new-entity-unique-id-service/new-entity-unique-id.service';
import { InsuranceSelectModalComponent } from '../../../invoices/insurance-select-modal/insurance-select-modal.component';
import { NewGuestInvoiceModalComponent } from '../../../invoices/new-guest-invoice-modal/new-guest-invoice-modal.component';
import { AccountingService, TableFormattedInvoiceSummary } from '../accounting.service';

// This MUST match the name property in the StatefulComponent decorator in InvoiceDashboardComponent
export const INVOICES_DASHBOARD_COMPONENT = 'InvoicesDashboardComponent';

@Injectable({
	providedIn: 'root',
})
export class InvoiceService implements OnDestroy {
	private NO_PROVIDER_INDICATOR = 0;
	protected unsubscribe$ = new Subject<void>();
	refreshedInvoice = new Subject<number>();
	readonly accountingUpliftFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.ACCOUNTING.UPLIFT_ACCOUNTING));

	constructor(
		public modalManagerService: ModalManagerService,
		public accountingService: AccountingService,
		private featureService: FeatureService,
		public zone: NgZone,
		public patientService: PatientService,
		public router: Router,
		public eventsManagerService: EventsManagerService,
		private store: Store<State>,
		private newEntityUniqueIdService: NewEntityUniqueIdService,
		private summaryPodService: SummaryPodService,
		private eventService: EventService,
		private invoiceGandalfService: InvoiceGandalfService,
		public invoiceClaimGandalfService: InvoiceClaimGandalfService,
	) {
	}

	/**
	 * This is a chain of modals for creating a new Insurance Invoice
	 * It first gives the user an opportunity to search for a patient, then it allows the user to select an insurance policy,
	 * then it allows the user to select an optional encounter which that patient has had which can be associated with the new invoice.
	 * After which the invoice will be generated.
	 *
	 * @param event used to start the modal
	 */
	/* istanbul ignore next */
	newInsuranceInvoiceModalWizard(event: { locationId: number, patientId?: number }) {
		let personInsuranceId = null;
		let patientId = event.patientId;
		const locationId = event.locationId;
		this.zone.run(() => {
			if (_isNil(patientId)) {
				this.modalManagerService.open(PatientSearchModalComponent, {data: event}).onClose.subscribe((patientData) => {
					if (!_isNil(patientData)) {
						patientId = patientData.patient.patientId;
						this.modalManagerService.open(InsuranceSelectModalComponent, {
							data: {
								patientId,
							},
						}).onClose.subscribe((personInsuranceData) => {
							if (!_isNil(personInsuranceData)) {
								personInsuranceId = personInsuranceData.insurance.id;
								this.selectEncounterModal(patientId, locationId, personInsuranceId);
							}
						});
					}
				});
			} else {
				this.modalManagerService.open(InsuranceSelectModalComponent, {
					data: {
						patientId,
					},
				}).onClose.subscribe((personInsuranceData) => {
					if (!_isNil(personInsuranceData)) {
						personInsuranceId = personInsuranceData.insurance.id;
						this.selectEncounterModal(patientId, locationId, personInsuranceId);
					}
				});
			}
		});
	}

	/**
	 * This is a chain of modals for creating a new Patient Invoice
	 * It first gives the user an opportunity to search for a patient, then it allows the user to select an optional
	 * encounter which that patient has had which can be associated with the new invoice. After which the invoice will
	 * be generated.
	 *
	 * @param event used to start the modal
	 */
	/* istanbul ignore next */
	newPatientInvoiceModalWizard(event: { locationId: number }) {
		let patientId = null;
		const locationId = event.locationId;
		this.zone.run(() => {
			this.modalManagerService.open(PatientSearchModalComponent, {data: event}).onClose.subscribe((patientData) => {
				if (!_isNil(patientData)) {
					patientId = patientData.patient.patientId;
					this.selectEncounterModal(patientId, locationId, undefined);
				}
			});
		});
	}

	newGuestInvoiceModal() {
		this.modalManagerService.open(NewGuestInvoiceModalComponent, {}).onClose.subscribe(async (invoice) => {
			if (invoice) {
				await this.openInvoiceTab(invoice.id);
			}
		});
	}

	openInvoiceTab(invoiceId: number): Promise<boolean> {
		if (this.isInvoiceDashboardFeatureEnabled()) {
			return this.openHTMLInvoiceTab(invoiceId);
		}

		return Promise.resolve(true);
	}

	/**
	 * Refreshes the invoice in the flex and in the new dashboard if it's activated
	 * The flex is required because in other modules the modals that call this might be opened from flex
	 */
	refreshInvoice(invoiceId: number, patientId?: number) {
		if (this.isInvoiceDashboardFeatureEnabled()) {
			this.refreshInvoiceOnHTMLDashboard(invoiceId);
		}
		this.refreshedInvoice.next(invoiceId);
		this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.ACCOUNTING.INVOICES_UPDATED);
		this.accountingService.getInvoiceById(invoiceId).subscribe((invoice) => {
			this.eventService.publishUpdatePatientAccount(invoice.patientId);
			this.summaryPodService.updateAccountingSummaryPod(invoice.patientId);
			StatefulEventUtil.publish(UPDATE_PATIENT_INVOICES, {payload: {invoiceId, patientId}});
			this.store.dispatch(new UpsertInvoiceDetails({invoiceId, invoice}));
		});
	}

	/**
	 * The selectEncounterModal is a shared across two new invoice workflow therefore it is broken out into it's own method
	 * @param patientId: the patient used to create the new invoice
	 * @param locationId: the location used to create the new invoice
	 * @param personInsuranceId: the personInsurance used to create the new invoice
	 */
	/* istanbul ignore next */
	selectEncounterModal(patientId: number, locationId: number, personInsuranceId?: number) {
		this.modalManagerService.open(EncounterSelectByPatientModalComponent, {
			data: {
				patientId,
				locationId,
			},
		}).onClose.subscribe((encounterData) => {
			let encounterId = null;
			if (!_isNil(encounterData)) {
				if (!encounterData.skipped) {
					encounterId = encounterData.encounter.id;
				}
				const request: CreateInvoiceRequest = new CreateInvoiceRequest();
				request.patientId = patientId;
				request.encounterId = encounterId;
				request.locationId = locationId;
				request.personInsuranceId = !_isNil(personInsuranceId) ? personInsuranceId : null;
				this.accountingService.createNewInvoice(request).subscribe(async (newInvoice) => {
					await this.openInvoiceTab(newInvoice.id);
					StatefulEventUtil.publish(UPDATE_PATIENT_INVOICES, {payload: {patientId}});
				});
			}
		});
	}

	openHTMLInvoiceTab(newInvoiceId: number) {
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.ACCOUNTING_INVOICES.url, 'invoice', newInvoiceId]);
	}

	openHTMLInvoicePaymentTab(payerType: PayerType, locationId: number) {
		const newestId = this.newEntityUniqueIdService.nextId;
		this.store.dispatch(new AddInvoicePaymentTab({paymentId: newestId, locationId, payerType}));
		return this.router.navigate([APP_ROUTING_URL_CONSTANTS.ACCOUNTING_INVOICES.url, 'payment', newestId]);
	}

	/**
	 * Opens the receive payments modal. If a payment is included here, then it will open that payment, otherwise it is creating a new payment
	 */
	openReceivePaymentModal(typeSafeModalManagerService: TypeSafeModalManagerService, invoice: InvoiceResponse, payment?: AccountingInvoicePaymentResponse) {
		const payload: ReceivePaymentModalData = {
			paymentGroupId: payment?.paymentGroupId,
			payer: {
				payerId: invoice.payerId,
				entityId: invoice.payerEntityId,
				type: invoice.payerType,
				personInsuranceId: invoice.personInsuranceId,
				defaultPaymentMethod: invoice.payerDefaultPaymentMethod,
				name: invoice.payerName,
				sourceType: PaymentGroupSourceType.LOCATION,
				sourcePracticeLocationId: invoice.locationId,
			},
		};
		typeSafeModalManagerService.open(ReceivePaymentsModalComponent, payload).onClose.subscribe(() => {
			this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.ACCOUNTING.PAYMENTS_UPDATED);
			this.refreshInvoiceDetailsInvoice(invoice.id);
		});
	}

	confirmDisassociateProviderFromInvoice(invoiceId: number) {
		const dialog = DialogUtil.confirm({
			title: 'Remove Provider',
			content: `Are you sure you want to remove this provider from the invoice?`,
			okButton: {
				click: () => this.disassociateProvider(invoiceId, dialog),
			},
		});
	}

	disassociateProvider(invoiceId: number, dialog: Dialog) {
		if (this.accountingUpliftFeatureOn) {
			this.accountingService.unassignInvoiceProvider(invoiceId).subscribe(() => {
				this.refreshInvoiceDetailsInvoice(invoiceId);
				dialog.close();
			});
		} else {
			const request: UpdateInvoiceProviderRequest = new UpdateInvoiceProviderRequest();
			request.invoiceId = invoiceId;
			request.providerId = this.NO_PROVIDER_INDICATOR;
			this.accountingService.updateInvoiceProvider(request).subscribe(() => {
				this.refreshInvoiceDetailsInvoice(invoiceId);
				dialog.close();
			});
		}
	}

	isInvoiceDashboardFeatureEnabled() {
		return this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.ACCOUNTING.INVOICES);
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	refreshInvoiceOnHTMLDashboard(invoiceId: number) {
		if (this.accountingUpliftFeatureOn) {
			this.invoiceGandalfService.getInvoiceForDashboardById(invoiceId)
				.pipe(map(invoice => this.accountingService.formatForTable([invoice])))
				.subscribe(updatedInvoices => this.refreshInvoiceDashboardComponent(updatedInvoices));
		} else {
			const invoiceSearch = new InvoiceDashboardSearchRequest();
			invoiceSearch.invoiceId = invoiceId;
			this.accountingService.searchInvoices(invoiceSearch).subscribe(updatedInvoices => this.refreshInvoiceDashboardComponent(updatedInvoices));
		}
	}

	/* istanbul ignore next */
	refreshInvoiceDashboardComponent(updatedInvoices: TableFormattedInvoiceSummary[]) {
		StatefulPropertyService.selectPropertyState('invoices', this.store, INVOICES_DASHBOARD_COMPONENT).pipe(take(1)).subscribe((currentInvoices: any[]) => {
			if (!currentInvoices) {
				currentInvoices = [];
			}
			const resultingInvoices = this.mergeUpdatesIntoCurrentInvoices(currentInvoices, updatedInvoices);
			StatefulPropertyService.updatePropertyValueWithStoreKey('invoices', resultingInvoices, this.store, INVOICES_DASHBOARD_COMPONENT);
		});
	}

	private mergeUpdatesIntoCurrentInvoices(currentInvoices: any[], updatedInvoices: any[]) {
		const resultingInvoices = [...currentInvoices];
		updatedInvoices.forEach(updatedInvoice => {
			// try to replace current with update in place
			const index = resultingInvoices.findIndex((currentInvoice) => currentInvoice.id === updatedInvoice.id);
			if (index !== -1) {
				resultingInvoices[index] = updatedInvoice;
			} else { // if not in the current list put at the end of the list
				resultingInvoices.push(updatedInvoice);
			}
		});
		return resultingInvoices;
	}

	getInvoiceDetailsInvoiceState(invoiceId: number): Observable<InvoiceResponse> {
		return this.store.select(selectInvoiceDetailsInvoiceState({invoiceId}));
	}

	getInvoiceDetailsShowAllState(invoiceId: number): Observable<boolean> {
		return this.store.select(selectInvoiceDetailsShowAllState({invoiceId}));
	}

	setInvoiceDetailsShowAllState(invoiceId: number, showAll: boolean) {
		return this.store.dispatch(new UpdateInvoiceDetailsShowAll({invoiceId, showAll}));
	}

	getInvoiceDetailsState(invoiceId: number): Observable<InvoiceDetailsState> {
		return this.store.select(selectInvoiceDetailsState({invoiceId}));
	}

	getInvoiceDetailsStateCurrentTab(invoiceId: number): Observable<number> {
		return this.store.select(selectInvoiceDetailsCurrentTabState({invoiceId}));
	}

	refreshInvoiceDetailsInvoice(invoiceId: number) {
		this.refreshInvoice(invoiceId);
	}

	refreshInvoiceDetails(invoice: InvoiceResponse, currentTab: number) {
		this.store.dispatch(new UpsertInvoiceDetails({invoiceId: invoice.id, invoice, currentTab}));
	}

	refreshInvoicesDetailsInvoiceTabs(invoiceIds: number[]) {
		_forEach(invoiceIds, id => {
			this.refreshInvoiceDetailsInvoiceTab(id);
		});
	}

	refreshInvoiceDetailsInvoiceTab(invoiceId: number) {
		this.store.select(selectInvoiceTabStateById({id: invoiceId})).subscribe(invoiceTabState => {
			if (!_isNil(invoiceTabState)) {
				this.refreshInvoiceDetailsInvoice(invoiceId);
			}
		});
	}

	removeInvoiceDetailsState(invoiceId: number) {
		this.store.dispatch(new RemoveInvoiceDetailsState({invoiceId}));
	}

	/* istanbul ignore next */
	findProcessingPaymentGroupId(invoiceId: number) {
		return this.invoiceGandalfService.findProcessingPaymentGroupId(invoiceId);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	resetClaim(claimId: number): Observable<void> {
		return this.invoiceClaimGandalfService.resetClaim(claimId);
	}

	findClaimsByInvoiceId(invoiceId: number) {
		return this.invoiceClaimGandalfService.findClaimsByInvoiceId(invoiceId).pipe(
			map(claims => SortingService.sortBy(claims, ['claimDate', 'id'], ['asc', 'asc'])),
			tap(claims => claims.forEach(claim => claim.claimStatusMessages = SortingService.sortBy(claim.claimStatusMessages, ['claimStatusId'], ['desc']))),
		);
	}

	@ShowSavedSuccessToast()
	deleteClaim(claimId: number): Observable<void> {
		return this.invoiceClaimGandalfService.markClaimAsDeleted(claimId);
	}
}
