import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileService } from '@core/file/file.service';
import { _isNil } from '@core/lodash/lodash';
import { DialogUtil, ModalManagerService } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PatientFileEntityType, PreferenceName } from '@gandalf/constants';
import { PatientFileListResponse } from '@gandalf/model/patient-file-list-response';
import { SearchPatientFilesRequest } from '@gandalf/model/search-patient-files-request';
import { FileScanModalComponent } from '@shared/component/file-scan-modal/file-scan-modal.component';
import { FileUploadModalComponent } from '@shared/component/file-upload-modal/file-upload-modal.component';
import { PatientDocumentsService } from '@shared/component/patient-documents/patient-documents.service';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { Dialog } from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'pms-patient-documents',
	templateUrl: './patient-documents.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class PatientDocumentsComponent implements OnInit {

	files: PatientFileListResponse[] = [];
	canScan = false;
	@Input()
	patientId: number;

	@Input()
	parentId: number;

	@Input()
	disabled = false;

	@Input()
	entityType: PatientFileEntityType;

	@Input()
	encounterId: number;

	@Output()
	update: EventEmitter<boolean> = new EventEmitter();

	@Output()
	fileCountUpdated: EventEmitter<number> = new EventEmitter<number>();

	dayFormatter = TABLE_DATE_FORMATS.MM_DD_YYYY;
	confirmDialog: Dialog;

	constructor(
		private securityManagerService: SecurityManagerService,
		private modalManagerService: ModalManagerService,
		private patientFileService: PatientDocumentsService,
		private fileService: FileService,
	) { }

	get fileCount() {
		return !_isNil(this.files) ? this.files.length : 0;
	}

	ngOnInit() {
		this.canScan = this.securityManagerService.preferenceValueIsOn(PreferenceName.SCANNING_ENABLED.value, 'true');
		this.getPatientFilesList();
	}

	getPatientFilesList() {
		if (this.parentId) {
			const req = new SearchPatientFilesRequest();
			req.patientId = this.patientId;
			req.parentId = this.parentId;
			req.entityType = this.entityType;
			this.patientFileService.getPatientFiles(req).subscribe(files => {
				this.files = files;
				this.fileCountUpdated.emit(this.fileCount);
			});
		}
	}

	/*istanbul ignore next */
	openUploadModal() {
		this.modalManagerService.open(FileUploadModalComponent, {
			data: {
				parentId: this.parentId,
				patientId: this.patientId,
				entityType: this.entityType,
				encounterId: this.encounterId,
			},
		}).onClose.subscribe(
			(refresh) => {
				this.onModalClose(refresh);
			},
		);
	}

	openScanModal() {
		this.modalManagerService.open(FileScanModalComponent, {
			data: {
				parentId: this.parentId,
				patientId: this.patientId,
				entityType: this.entityType,
				encounterId: this.encounterId,
			},
		}).onClose.subscribe(
			(refresh) => {
				this.onModalClose(refresh);
			},
		);
	}

	onModalClose(refresh) {
		if (refresh) {
			this.update.emit(refresh);
			this.getPatientFilesList();
		}
	}

	downloadImage(data: PatientFileListResponse) {
		this.fileService.downloadPatientFile(this.patientId, data.patientFileId);
	}

	isPreviewable(data: PatientFileListResponse) {
		return this.fileService.isMimeTypePreviewable(data.mimeType);
	}

	openPreview(data: PatientFileListResponse) {
		this.fileService.viewOrDownloadPatientFile(this.patientId, data);
	}

	/*istanbul ignore next */
	showDeleteModal(data) {
		const patientFileId = data.patientFileId;
		this.confirmDialog = DialogUtil.confirm({
			title: 'Delete File',
			content: 'Are you sure you want to delete this file?',
			okButton: {
				click: this.deleteItemCloseModal.bind(this, patientFileId),
			},
		});
	}

	deleteItemCloseModal(patientFileId) {
		this.confirmDialog.close();
		this.patientFileService.deleteFile(patientFileId).subscribe(() => {
			this.getPatientFilesList();
			this.update.emit(true);
		});
	}
}
