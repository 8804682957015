// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.InvoicePayerResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoicePayerResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	defaultPaymentMethod: constants.PaymentMethodType;

	@GandalfProperty()
	entityId: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	insurancePayerId: string;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	personInsuranceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	type: constants.PayerType;

}
