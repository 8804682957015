<form
	  [formGroup]="formGroup"
	  #templateForm="ngForm"
	  (ngSubmit)="save()">
	<gandalf-lib-validation-messages
			*ngIf="formGroup.invalid && templateForm.submitted"
			[form]="formGroup"
			[requestObj]="request"
			[attr.data-test-id]="'additionalClaimValidationMessages'">
	</gandalf-lib-validation-messages>
	<div class="row">
		<div class="col-md-6" [attr.data-test-id]="'additionalClaimPanel'">
			<h4 class="section-title bg-default mrgn-btm">Additional Claim Information</h4>
			<div class="form-group" [attr.data-test-id]="'codeSetFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300, HI01-12">Code Set</label>
				<div class="col-sm-8">
					<pms-enum-select-button
							*ngIf="!isReadOnly"
							[enumList]="pendingCodeSetOptions"
							formControlName="diagnosisCodeSet">
					</pms-enum-select-button>
					<p *ngIf="isReadOnly" class="form-control-static">{{addlClaimInfo?.diagnosisCodeSet}}</p>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'additionalClaimClaimCodesFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2000B Segment SBR05">(10d) Claim Codes</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="localUse10d" />
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'currentIllnessFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment DTP03 (431)">(14) Current Illness</label>
				<div class="col-sm-3">
					<ejs-datepicker
							class="e-input"
							formControlName="currentIllnessDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
				<div class="col-sm-5">
					<div class="row">
						<label class="col-sm-4 control-label">Qualifier</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
									[showClearButton]="true"
									[constantList]="currentIllnessQualifierList"
									formControlName="currentIllnessDateQualifier">
							</pms-constant-dropdown>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'otherDateFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment DTP">(15) Other Date</label>
				<div class="col-sm-3">
					<ejs-datepicker
							class="e-input"
							formControlName="similarIllnessDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
				<div class="col-sm-5">
					<div class="row">
						<label class="col-sm-4 control-label">Qualifier</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
									[showClearButton]="true"
									[constantList]="similarIllnessQualifierList"
									formControlName="similarIllnessDateQualifier">
							</pms-constant-dropdown>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'outOfWorkFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment DTP03 (360/361)">(16) Out of Work</label>
				<div class="col-sm-4">
					<ejs-datepicker
							class="e-input"
							formControlName="outOfWorkStartDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
				<div class="col-sm-4">
					<ejs-datepicker
							class="e-input"
							formControlName="outOfWorkEndDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'referringProviderFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2310A">(17) Referring Provider</label>
				<div class="col-sm-8">
					<ejs-dropdownlist
							[dataSource]="providerList"
							formControlName="referringProviderId"
							[showClearButton]="true"
							placeholder="Select Provider">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'orderingProviderFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2420E">Ordering Provider</label>
				<div class="col-sm-8">
					<ejs-dropdownlist
							[dataSource]="providerList"
							[showClearButton]="true"
							formControlName="orderingProviderId"
							placeholder="Select Provider">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'hospitalizedFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment DTP03 (435/096)">(18) Hospitalized</label>
				<div class="col-sm-4">
					<ejs-datepicker
							class="e-input"
							formControlName="hospitalizationStartDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
				<div class="col-sm-4">
					<ejs-datepicker
							class="e-input"
							formControlName="hospitalizationEndDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'localUseFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment NTE02 (ADD)">(19) Local Use</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="localUse" />
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'outsideLabFormGroup'">
				<label class="col-sm-4 control-label">(20) Outside Lab</label>
				<div class="col-sm-3">
					<div class="margin-top-xs">
						<ejs-checkbox label="Outside Lab" name="outsideLab" formControlName="outsideLab"></ejs-checkbox>
					</div>
				</div>
				<div class="col-sm-5">
					<div class="row">
						<label class="col-sm-4 control-label">Lab Charges</label>
						<div class="col-sm-8">
							<pms-currency-input
									htmlId='outsideLabCharges'
									formControlName="outsideLabCharges"
							></pms-currency-input>
						</div>
					</div>
				</div>
			</div>
			<span *ngIf="displayRequiresMessage()" class="text-danger pull-right padding-right-sm">Requires an Orig Ref Number</span>
			<div class="form-group" [attr.data-test-id]="'resubmissionCodeFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment CLM05-3">(22) Resubmission Code</label>
				<div class="col-sm-3">
					<pms-constant-dropdown
							[constantList]="claimFrequencyCodeList"
							formControlName="claimFrequencyCode">
					</pms-constant-dropdown>
				</div>
				<div class="col-sm-5">
					<div class="row">
						<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment REF02 (F8)">Orig Ref #</label>
						<div class="col-sm-8">
							<input type="text" class="e-input" formControlName="originalReferenceNumber" />
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'priorAuthNumberFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment REF02 (G1)">(23) Prior Auth. Number</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="priorAuthorizationNumber" />
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'secondaryPayerFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2320">Secondary Payer</label>
				<div class="col-sm-8">
					<ejs-dropdownlist
							[dataSource]="personInsuranceList"
							formControlName="secondaryPayerId"
							[showClearButton]="true"
							placeholder="Select Insurance">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group" *ngIf="addlClaimInfo?.authorizationNumber" [attr.data-test-id]="'additionalClaimAuthorizationNumberFormGroup'">
				<label class="col-sm-4 control-label">Insurance Auth.</label>
				<div class="col-sm-8">
					<p class="form-control-static">{{ addlClaimInfo?.authorizationNumber }}</p>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'signatureHandwrittenFormGroup'">
				<label class="col-sm-4 control-label">(31) Signature Handwritten</label>
				<div class="col-sm-8">
					<div class="margin-top-xs">
						<ejs-checkbox name="signatureHandwritten" label="Signature Handwritten" formControlName="signatureHandwritten"></ejs-checkbox>
					</div>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'alternateServiceLocationFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2310C">(32) Alt. Service Location</label>
				<div class="col-sm-8">
					<ejs-dropdownlist
							[dataSource]="vendorsList"
							formControlName="alternateServiceLocationId"
							[showClearButton]="true"
							placeholder="Select Vendor">
					</ejs-dropdownlist>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<h4 class="section-title bg-default mrgn-btm">Electronic Claim Codes</h4>
			<div class="form-group" [attr.data-test-id]="'initialTreatmentFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment DTP03 (454)">Initial Treatment Date</label>
				<div class="col-sm-8">
					<ejs-datepicker
							class="e-input"
							formControlName="initialTreatmentDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'visionPrescriptionFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment DTP03 (471)">Vision Prescription Date</label>
				<div class="col-sm-8">
					<ejs-datepicker
							class="e-input"
							formControlName="visionPrescriptionDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'referralNumberFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment REF02 (9F)">Referral Number</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="referralNumber" />
					<span class="help-block">May require a Referring Provider</span>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'attachedPaperWorkFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment PWK">Attached Paperwork</label>
				<div class="col-sm-8">
					<div class="row">
						<div class="col-sm-12 col-lg-4 margin-bottom-sm">
							<label class="control-label margin-bottom-sm">Type</label>
							<pms-constant-dropdown
									[showClearButton]="true"
									[constantList]="attachmentTypeList"
									formControlName="attachmentType">
							</pms-constant-dropdown>
						</div>
						<div class="col-sm-12 col-lg-4 margin-bottom-sm">
							<label class="control-label margin-bottom-sm">Transmission</label>
							<pms-constant-dropdown
									[showClearButton]="true"
									[constantList]="attachmentTransmissionList"
									formControlName="attachmentTransmission">
							</pms-constant-dropdown>
						</div>
						<div class="col-sm-12 col-lg-4 margin-bottom-sm">
							<label class="control-label margin-bottom-sm">Control #</label>
							<input type="text" class="e-input" formControlName="attachmentControlNumber" />
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'startCareDateFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment DTP03 (090)">Report Care Start Date</label>
				<div class="col-sm-8">
					<ejs-datepicker
							class="e-input"
							formControlName="startCareDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'endCareDateFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment DTP03 (091)">Report Care End Date</label>
				<div class="col-sm-8">
					<ejs-datepicker
							class="e-input"
							formControlName="endCareDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy">
					</ejs-datepicker>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'cliaNumberFormGroup'">
				<label class="col-sm-4 control-label" revTooltip tooltipContent="Loop 2300 Segment REF (X4) - Clinical Laboratory Improvement Amendment Number">CLIA Number</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="cliaNumber" />
				</div>
			</div>
			<h4 class="section-title bg-default mrgn-btm">Vision Condition Codes</h4>
			<div class="table-responsive">
				<table class="table margin-bottom-sm">
					<thead>
					<tr>
						<th class="nowrap"></th>
						<th class="nowrap">Codes Apply</th>
						<th revTooltip tooltipContent="General Standard of 20 Degree or .5 Diopter Sphere or Cylinder Change Met" [attr.data-test-id]="'l1Header'">L1</th>
						<th revTooltip tooltipContent="Replacement - Loss or Theft" [attr.data-test-id]="'l2Header'">L2</th>
						<th revTooltip tooltipContent="Replacement - Breakage or Damage" [attr.data-test-id]="'l3Header'">L3</th>
						<th revTooltip tooltipContent="Replacement - Patient Preference" [attr.data-test-id]="'l4Header'">L4</th>
						<th revTooltip tooltipContent="Replacement - Medical Reason" [attr.data-test-id]="'l5Header'">L5</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td class="nowrap" revTooltip tooltipContent="Loop 2300 Segment CRC (E3)" [attr.data-test-id]="'additionalClaimInfoFramesHeader'">Eyeglass Frames</td>
						<td class="nowrap" [attr.data-test-id]="'additionalClaimInfoFramesLabel'">
							<pms-enum-select-button
									*ngIf="!isReadOnly"
									[enumList]="claimVisionConditionApplyOptions"
									formControlName="conditionFramesApply">
							</pms-enum-select-button>
							<span *ngIf="isReadOnly">{{addlClaimInfo?.conditionFramesApply}}</span>
						</td>
						<td>
							<ejs-checkbox name="conditionFramesL1" formControlName="conditionFramesL1"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionFramesL2" formControlName="conditionFramesL2"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionFramesL3" formControlName="conditionFramesL3"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionFramesL4" formControlName="conditionFramesL4"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionFramesL5" formControlName="conditionFramesL5"></ejs-checkbox>
						</td>
					</tr>
					<tr>
						<td class="nowrap" revTooltip tooltipContent="Loop 2300 Segment CRC (E1)" [attr.data-test-id]="'additionalClaimInfoLensesHeader'">Eyeglass Lenses</td>
						<td class="nowrap" [attr.data-test-id]="'additionalClaimInfoLensesLabel'">
							<pms-enum-select-button
									*ngIf="!isReadOnly"
									[enumList]="claimVisionConditionApplyOptions"
									formControlName="conditionLensesApply">
							</pms-enum-select-button>
							<span *ngIf="isReadOnly">{{addlClaimInfo?.conditionLensesApply}}</span>
						</td>
						<td>
							<ejs-checkbox name="conditionLensesL1" formControlName="conditionLensesL1"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionLensesL2" formControlName="conditionLensesL2"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionLensesL3" formControlName="conditionLensesL3"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionLensesL4" formControlName="conditionLensesL4"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionLensesL5" formControlName="conditionLensesL5"></ejs-checkbox>
						</td>
					</tr>
					<tr>
						<td class="nowrap" revTooltip tooltipContent="Loop 2300 Segment CRC (E2)" [attr.data-test-id]="'additionalClaimInfoContactsHeader'">Contact Lenses</td>
						<td class="nowrap" [attr.data-test-id]="'additionalClaimInfoContactsLabel'">
							<pms-enum-select-button
									*ngIf="!isReadOnly"
									[enumList]="claimVisionConditionApplyOptions"
									formControlName="conditionContactsApply">
							</pms-enum-select-button>
							<span *ngIf="isReadOnly">{{addlClaimInfo?.conditionContactsApply}}</span>
						</td>
						<td>
							<ejs-checkbox name="conditionContactsL1" formControlName="conditionContactsL1"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionContactsL2" formControlName="conditionContactsL2"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionContactsL3" formControlName="conditionContactsL3"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionContactsL4" formControlName="conditionContactsL4"></ejs-checkbox>
						</td>
						<td>
							<ejs-checkbox name="conditionContactsL5" formControlName="conditionContactsL5"></ejs-checkbox>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<h4 class="section-title bg-default mrgn-btm" [attr.data-test-id]="'additionalClaimInfoEpsdt'">EPSDT Condition Codes</h4>
			<div class="table-responsive">
				<table class="table margin-bottom-sm">
					<thead>
					<tr>
						<th class="nowrap"></th>
						<th class="nowrap">Codes Apply</th>
						<th>Code 1</th>
						<th>Code 2</th>
						<th>Code 3</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td class="nowrap" revTooltip tooltipContent="Loop 2300 Segment CRC02" [attr.data-test-id]="'additionalClaimInfoEpsdtCodesApplyHeadingLabel'">EPSDT</td>
						<td class="nowrap" [attr.data-test-id]="'additionalClaimInfoEpsdtCodeApplyLabel'">
							<pms-enum-select-button
									*ngIf="!isReadOnly"
									[enumList]="claimEpsdtApplyOptions"
									formControlName="epsdtApply">
							</pms-enum-select-button>
							<span *ngIf="isReadOnly">{{addlClaimInfo?.epsdtApply}}</span>
						</td>
						<td>
							<pms-constant-dropdown
									[showClearButton]="true"
									[constantList]="claimEpsdtCodeList"
									formControlName="epsdtCode1">
							</pms-constant-dropdown>
						</td>
						<td>
							<pms-constant-dropdown
									[showClearButton]="true"
									[constantList]="claimEpsdtCodeList"
									formControlName="epsdtCode2">
							</pms-constant-dropdown>
						</td>
						<td>
							<pms-constant-dropdown
									[showClearButton]="true"
									[constantList]="claimEpsdtCodeList"
									formControlName="epsdtCode3">
							</pms-constant-dropdown>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</form>
<div class="rev-action-toolbar" *ngIf="!isReadOnly">
	<button type="button" ejs-button [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'additionalClaimInfoSaveButton'">Save</button>
	<button ejs-button type="button" iconCss="fa fa-close" (click)="cancel()" [attr.data-test-id]="'additionalClaimInfoCancelButton'">Cancel</button>
</div>
