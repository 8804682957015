<button
		type="{{buttonType}}"
		class="e-control e-btn e-lib"
		revTooltip
		[tooltipContent]="buttonTooltip"
		[tooltipDisabled]="buttonHidden"
		[ngClass]="buildNgClass()"
		[disabled]="buttonDisabled"
		[attr.data-test-id]="dataTestId"
		[attr.data-tooltip-content]="buttonTooltip"
		(click)="clickButton()"
>
	<i *ngIf="showIconLeft()" class="e-btn-icon fa fa-{{buttonIcon}}" [ngClass]="buildIconLeftNgClass()"></i>
	{{buttonLabel}}
	<i *ngIf="showIconRight()" class="e-btn-icon fa fa-{{buttonIcon}}" [ngClass]="buildIconRightNgClass()"></i>
</button>
