import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { NgxExtendedPdfViewerComponent } from 'ngx-extended-pdf-viewer';

@Component({
	selector: 'pms-pdf-viewer-modal',
	templateUrl: './pdf-viewer-modal.component.html',
})
export class PdfViewerModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('pdfViewer')
	pdfViewer: NgxExtendedPdfViewerComponent;

	title: string;
	base64Src: string;
	fileName: string;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
	) {
	}

	ngOnInit(): void {
		this.parseModalConfig(this.modalConfig.data);
	}

	ngOnDestroy(): Promise<void> {
		return this.pdfViewer.ngOnDestroy();
	}

	parseModalConfig(data: any) {
		this.title = data.title;
		this.base64Src = data.base64Src;
		this.fileName = data.fileName;
	}

	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}

}
