import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { _isEmpty } from '../../utils/lodash/lodash';

export type ButtonType = 'button' | 'submit' | 'reset';

export type ButtonStyle = 'default' | 'primary' | 'success' | 'danger';

export type ButtonIconPlacement = 'left' | 'right';

@Component({
	selector: 'rev-button',
	templateUrl: './button.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {

	@Input()
	buttonType: ButtonType = 'button';

	@Input()
	buttonStyle: ButtonStyle = 'default';

	@Input()
	buttonTooltip: string;

	@Output()
	buttonClick = new EventEmitter<void>();

	@Input()
	dataTestId: string;

	@Input()
	buttonLabel: string;

	@Input()
	buttonIcon: string;

	@Input()
	buttonIconPlacement: ButtonIconPlacement = 'left';

	@Input()
	buttonDisabled: boolean;

	@Input()
	buttonHidden: boolean;

	constructor() {
	}

	buildNgClass() {
		return {
			'e-disabled': this.buttonDisabled,
			'e-icon-btn': _isEmpty(this.buttonLabel),
			'e-primary': this.buttonStyle === 'primary',
			'e-success': this.buttonStyle === 'success',
			'e-danger': this.buttonStyle === 'danger',
			'invisible': !!this.buttonHidden,
		};
	}

	showIconLeft() {
		return !_isEmpty(this.buttonIcon) && this.buttonIconPlacement === 'left';
	}

	buildIconLeftNgClass() {
		return {
			'margin-right-xs': this.showIconLeft() && !_isEmpty(this.buttonLabel),
		};
	}

	showIconRight() {
		return !_isEmpty(this.buttonIcon) && this.buttonIconPlacement === 'right';
	}

	buildIconRightNgClass() {
		return {
			'margin-left-xs': this.showIconRight() && !_isEmpty(this.buttonLabel),
		};
	}

	clickButton() {
		this.buttonClick.emit();
	}
}
