import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { ApplicationService } from '@core/application/application.service';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { EVENT_MANAGER_CONSTANTS } from '@core/events-manager/events-manager.constants';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FeatureModificationService } from '@core/feature/feature-modification.service';
import { FileService } from '@core/file/file.service';
import { InventoryService } from '@core/inventory/inventory.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { LegacyEventService } from '@core/legacy/legacy-event.service';
import { LegacyNavigationService } from '@core/legacy/legacy-navigation.service';
import { LockManagerService } from '@core/lock-manager/lock-manager.service';
import { DialogUtil, ModalManagerService, TooltipService, TypeSafeModalManagerService } from 'morgana';
import { PrintService } from '@core/print/print.service';
import { StateManagementService } from '@core/state-management/state-management.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { UrlParamService } from '@core/url-util/url-param.service';
import { ImagePreviewModalComponent } from '@shared/component/image-preview-modal/image-preview-modal.component';
import { PdfViewerModalComponent } from '@shared/component/pdf-viewer-modal/pdf-viewer-modal.component';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { LicenseService } from '@core/license/license.service';
import { LetterEditorDockableModalComponent } from '@shared/component/letter-editor-dockable-modal/letter-editor-dockable-modal.component';
import { DateUtilsService } from '@core/date-utils/date-utils.service';
import { AdminFeatureSubscriptionService } from './admin/services/admin-feature-subscription.service';
import { EditProfileSubscriptionService } from './header/services/edit-profile-subscription.service';
import { ManageUpdatesSubscriptionService } from './header/services/manage-updates-subscription.service';
import { OrdersEventService } from './orders/order/orders-event.service';
import { PatientManagerService } from './patients/core/patient-manager/patient-manager.service';
import { FeatureFlagToastService } from './services/feature-flag-toast.service';
import { TodaysPatientsDockableModalComponent } from './header/todays-patients-dockable-modal/todays-patients-dockable-modal.component';
import { NavigationMenuService } from './header/services/navigation-menu.service';

@Component({
	selector: 'pms-root',
	templateUrl: './app.component.html',
	providers: [
		ModalManagerService,
		TypeSafeModalManagerService,
		{provide: 'todaysPatientsModalManagerService', useClass: TypeSafeModalManagerService},
		{provide: 'letterEditorModalManagerService', useClass: TypeSafeModalManagerService},
	],
})
export class AppComponent implements OnInit {

	@ViewChild('successToast', {read: ElementRef, static: true})
	successToast: ElementRef;

	@ViewChild('errorToast', {read: ElementRef, static: true})
	errorToast: ElementRef;

	@ViewChild('infoToast', {read: ElementRef, static: true})
	infoToast: ElementRef;

	@ViewChild('warningToast', {read: ElementRef, static: true})
	warningToast: ElementRef;

	@ViewChild('featureFlagModificationToast', {read: ElementRef, static: true})
	featureFlagModificationToast: ElementRef;

	@ViewChild('defaultNotificationToast', {read: ElementRef, static: true})
	defaultNotificationToast: ElementRef;

	@ViewChild('toastElement', {read: ElementRef, static: true})
	toastElement: ElementRef;

	@ViewChild('printSection', {read: ElementRef, static: true})
	printSection: ElementRef;

	isTodaysPatientsDockableModalOpen = false;
	agreements = true;
	isHeaderFeatureOn = false;
	isLetterEditorDockableModalOpen = false;

	constructor(
		private adminFeatureSubscriptionService: AdminFeatureSubscriptionService,
		private featureFlagToastService: FeatureFlagToastService,
		private featureModificationService: FeatureModificationService,
		private authenticationService: AuthenticationService,
		private manageUpdatesSubscriptionService: ManageUpdatesSubscriptionService,
		private editProfileSubscriptionService: EditProfileSubscriptionService,
		private toasterService: ToasterService,
		private lockManagerService: LockManagerService,
		private stateManagementService: StateManagementService,
		private tooltipService: TooltipService,
		private legacyEventService: LegacyEventService,
		private eventsManagerService: EventsManagerService,
		private legacyNavigationService: LegacyNavigationService,
		private urlParamService: UrlParamService,
		private patientManagerService: PatientManagerService,
		private ordersEventService: OrdersEventService,
		private applicationService: ApplicationService,
		public modalManagerService: ModalManagerService,
		public typeSafeModalManagerService: TypeSafeModalManagerService,
		@Inject('todaysPatientsModalManagerService') public todaysPatientsModalManagerService: TypeSafeModalManagerService,
		@Inject('letterEditorModalManagerService') public letterEditorModalManagerService: TypeSafeModalManagerService,
		private zone: NgZone,
		private fileService: FileService,
		private printService: PrintService,
		private inventoryService: InventoryService,
		private navigationMenuService: NavigationMenuService,
		private securityManagerService: SecurityManagerService,
		private licenseService: LicenseService,
		private featureService: FeatureService,
	) {
		this.authenticationService.subscribeToSessionEvents();
		this.adminFeatureSubscriptionService.subscribeToFeatureEvent();
		this.manageUpdatesSubscriptionService.subscribeToFeatureEvent(this.typeSafeModalManagerService);
		this.editProfileSubscriptionService.subscribeToFeatureEvent(this.typeSafeModalManagerService);
		this.lockManagerService.subscribeToLockEvent();
		this.stateManagementService.subscribeToRouteChange();
		this.tooltipService.setGlobalDefaults();
		this.legacyEventService.subscribeToActionEvent();
		this.urlParamService.subscribeForUrlParams();
		this.ordersEventService.subscribeToEvents();
		this.applicationService.monitorApplicationStableState();
		this.inventoryService.subscribeToLegacyEvents();

		this.authenticationService.isAuthenticated.subscribe(isAuthenticated => {
			if (isAuthenticated) {
				this.afterAuthenticationEvents();
			}
		});
	}

	ngOnInit() {
		this.setupToastElements();
		this.subscribeForToasts();
		this.subscribeForLegacyClose();
		this.subscribeForPdfViewer();
		this.subscribeForImageViewer();
		this.setupPrintElement();
		this.subscribeToTodaysPatients();
		this.subscribeToLetterEditor();
		this.subscribeToAgreementAccepted();
		window['applicationReady'] = true;
	}

	setupPrintElement() {
		this.printService.setPrintSection(this.printSection);
	}

	subscribeForToasts() {
		this.featureModificationService.flagsToTurnOn.subscribe(flags => {
			const flagsOn = flags.join(', ');
			if (flagsOn) {
				this.toasterService.showSavedSuccess({
					title: 'Flag modification successful!',
					content: flagsOn,
				});
			}
		});
	}

	setupToastElements() {
		this.toasterService.setSuccessToastElement(this.successToast);
		this.toasterService.setErrorToastElement(this.errorToast);
		this.toasterService.setInfoToastElement(this.infoToast);
		this.toasterService.setWarningToastElement(this.warningToast);
		this.toasterService.setFeatureFlagModificationToastElement(this.featureFlagModificationToast);
		this.toasterService.setDefaultNotificationToastElement(this.defaultNotificationToast);
		this.toasterService.setToastElement(this.toastElement);
	}

	subscribeForLegacyClose() {
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.ADMINISTRATION.ADMIN_MODULE_HIDE_LEGACY, () => {
			this.legacyNavigationService.hideLegacy('admin');
		}, this);
	}

	subscribeForPdfViewer() {
		this.eventsManagerService.subscribe(EVENT_MANAGER_CONSTANTS.GLOBAL.PDF_VIEWER, (event: any) => {
			this.zone.run(() => {
				this.fileService.downloadFileAndEncodeToBase64(event.argument.fileUrl, (fileEvent) => this.handlePdfLoaded(fileEvent, event.argument));
			});
		}, this);
	}

	handlePdfLoaded(event, data) {
		this.modalManagerService.open(PdfViewerModalComponent, {
			data: {
				title: data.fileName,
				base64Src: FileService.stripBase64Prefix(event.target.result),
				fileName: data.fileName,
			},
		});
	}

	subscribeForImageViewer() {
		this.eventsManagerService.subscribe(EVENT_MANAGER_CONSTANTS.GLOBAL.IMAGE_VIEWER, (event: any) => {
			this.zone.run(() => {
				this.modalManagerService.open(ImagePreviewModalComponent, {
					data: {
						filePath: event.argument.filePath,
					},
				});
			});
		}, this);
	}

	subscribeToTodaysPatients() {
		this.eventsManagerService.subscribe(EVENT_MANAGER_CONSTANTS.HEADER.TODAYS_PATIENTS_OPEN, () => {
			if (this.isTodaysPatientsDockableModalOpen) {
				this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.HEADER.TODAYS_PATIENTS_RESIZE);
			} else {
				this.openTodaysPatients();
			}
		});
	}

	openTodaysPatients() {
		this.isTodaysPatientsDockableModalOpen = true;
		this.todaysPatientsModalManagerService.open(TodaysPatientsDockableModalComponent, {}).onClose.subscribe(() => {
			this.isTodaysPatientsDockableModalOpen = false;
		});
	}

	subscribeToAgreementAccepted() {
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.AGREEMENTS.AGREEMENT_ACCEPTED, () => {
			this.agreements = false;
			this.featureFlagToastService.subscribe(this.typeSafeModalManagerService);
			this.patientManagerService.subscribeToLegacyEvents();
			this.navigationMenuService.subscribeToTwtUnreadConversationsInterval();
		});
	}

	headerFeatureFlagOrAgreements() {
		return !this.agreements || !this.isHeaderFeatureOn;
	}

	private afterAuthenticationEvents() {
		this.agreements = this.securityManagerService.sessionData.displayAgreementsScreen;
		this.isHeaderFeatureOn = this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.HEADER);
		if (this.agreements && this.isHeaderFeatureOn) {
			this.licenseService.getAgreementDetails().subscribe(response => {
				const licenseSignByDatePast = (response.licenseSignByDate != null && !response.licenseSigned)
					? DateUtilsService.dateIsBeforeOrTheSameAsToday(response.licenseSignByDate)
					: false;
				const baaSignByDatePast = (response.baaSignByDate != null && !response.baaSigned)
					? DateUtilsService.dateIsBeforeOrTheSameAsToday(response.baaSignByDate)
					: false;
				if ((licenseSignByDatePast || baaSignByDatePast)
					&& !this.securityManagerService.sessionData.canSignAgreements) {
					this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.AGREEMENTS.CUSTOMER_AGREEMENTS_EXPIRED);
				} else {
					this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.AGREEMENTS.SHOW_CUSTOMER_AGREEMENTS);
				}
			});
		} else if (!this.agreements || this.isHeaderFeatureOn) {
			this.featureFlagToastService.subscribe(this.typeSafeModalManagerService);
			this.patientManagerService.subscribeToLegacyEvents();
			this.navigationMenuService.subscribeToTwtUnreadConversationsInterval();
		}
	}

	subscribeToLetterEditor() {
		this.eventsManagerService.subscribe(EVENT_MANAGER_CONSTANTS.PATIENTS.LETTER_EDITOR, (data) => {
			this.letterEditorToggleOptions(data);
		});
	}

	letterEditorToggleOptions(data) {
		if (this.isLetterEditorDockableModalOpen) {
			DialogUtil.alert({
				title: 'Write Letter Alert',
				content: 'Only one communication letter may be edited at a time. Please finish the letter already in progress and try again.',
				showCloseIcon: false,
			});
		} else {
			this.openLetterEditor(data.argument.patientDocumentId, data.argument.existingTask);
		}
	}

	openLetterEditor(patientDocumentId: number, existingTask: boolean) {
		this.isLetterEditorDockableModalOpen = true;
		this.letterEditorModalManagerService.open(LetterEditorDockableModalComponent, {patientDocumentId, existingTask}).onClose.subscribe(() => {
			this.isLetterEditorDockableModalOpen = false;
		});
	}
}

