<ng-container *ngIf="userCanViewInvoices">
	<div class="mrgn-btm">
		<form class="form-horizontal" #templateForm="ngForm" [formGroup]="componentForm" (ngSubmit)="submitForm()" *ngIf="componentForm">
			<gandalf-lib-validation-messages
					*ngIf="templateForm.invalid && templateForm.submitted"
					[form]="componentForm"
					[requestObj]="invoiceSearchRequest"
					[attr.data-test-id]="'invoiceDashboardValidationMessage'">
			</gandalf-lib-validation-messages>
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="form-group" [attr.data-test-id]="'invoicesLocationFormGroup'">
						<label class="col-sm-4 control-label">Location</label>
						<div class="col-sm-8">
							<ejs-dropdownlist
									[dataSource]="locations"
									placeholder="All Locations"
									formControlName="locationId"
									[showClearButton]="true">
							</ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesStatusFormGroup'">
						<label class="col-sm-4 control-label">Status</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
									placeholder="All Statuses"
									[constantList]="statuses"
									formControlName="status"
									[showClearButton]="true">
							</pms-constant-dropdown>

						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesApprovalFormGroup'">
						<label class="col-sm-4 control-label">Approval</label>
						<div class="col-sm-8">
							<pms-enum-select-button
									[enumList]="approvalOptions"
									formControlName="authorized"
							>
							</pms-enum-select-button>

						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesOriginFormGroup'">
						<label class="col-sm-4 control-label">Origin</label>
						<div class="col-sm-8">
							<pms-constant-dropdown
									placeholder="All Origins"
									[constantList]="originOptions"
									formControlName="origin"
									[showClearButton]="true">
							</pms-constant-dropdown>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="form-group" [attr.data-test-id]="'invoicesPayerTypeFormGroup'">
						<label class="col-sm-4 control-label">Payer Type</label>
						<div class="col-sm-8">
							<ejs-dropdownlist
									placeholder="All Payer Types"
									[dataSource]="payerOptions"
									formControlName="payerType"
									[showClearButton]="true">
							</ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesTypeFormGroup'">
						<label class="col-sm-4 control-label">Type</label>
						<div class="col-sm-8">
							<ejs-dropdownlist
									placeholder="All Types"
									[dataSource]="payerInsuranceTypes"
									formControlName="payerInsuranceType"
									[showClearButton]="true">
							</ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesPayerNameFormGroup'">
						<label class="col-sm-4 control-label">Payer Name</label>
						<div class="col-sm-8">
							<input type="text" class="e-input" formControlName="payerName"/>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesProviderFormGroup'">
						<label class="col-sm-4 control-label">Provider</label>
						<div class="col-sm-8">
							<ejs-dropdownlist
									[dataSource]="providers"
									placeholder="All Providers"
									formControlName="providerId"
									[showClearButton]="true">
							</ejs-dropdownlist>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-6 col-lg-3" [attr.data-test-id]="'invoicesInvoiceNumberFormGroup'">
					<div class="form-group">
						<label class="col-sm-4 control-label">Invoice #</label>
						<div class="col-sm-8">
							<input revDigitOnlyInput class="e-input"
								   formControlName="invoiceId">
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesPatientNameFormGroup'">
						<label class="col-sm-4 control-label">Patient Name</label>
						<div class="col-sm-8">
							<input type="text" class="e-input" formControlName="patientLastName"/>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesPatientNumberFormGroup'">
						<label class="col-sm-4 control-label">Patient #</label>
						<div class="col-sm-8">
							<input revDigitOnlyInput class="e-input"
								   formControlName="patientId">
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesClaimNumberFormGroup'">
						<label class="col-sm-4 control-label">Claim #</label>
						<div class="col-sm-8">
							<input revDigitOnlyInput class="e-input"
								   formControlName="claimId">
						</div>
					</div>
				</div><!-- Third Column End -->
				<!-- Fourth Column Start -->
				<div class="col-sm-12 col-md-6 col-lg-3">
					<div class="form-group" [attr.data-test-id]="'invoicesInvoiceDateFormGroup'">
						<label class="col-sm-4 control-label">Invoice Date</label>
						<div class="col-sm-8">
							<div class="date-range">
								<div class="date-range-input">
									<ejs-datepicker formControlName="invoiceDateStart"
													placeholder="mm/dd/yyyy"
													[format]="dateFormat">
									</ejs-datepicker>
								</div>
								<div class="date-range-label">
									<label class="control-label">to</label>
								</div>
								<div class="date-range-input">
									<ejs-datepicker formControlName="invoiceDateEnd"
													placeholder="mm/dd/yyyy"
													[format]="dateFormat">
									</ejs-datepicker>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesServiceDateFormGroup'">
						<label class="col-sm-4 control-label">Service Date</label>
						<div class="col-sm-8">
							<div class="date-range">
								<div class="date-range-input">
									<ejs-datepicker formControlName="serviceDateStart"
													placeholder="mm/dd/yyyy"
													[format]="dateFormat">
									</ejs-datepicker>
								</div>
								<div class="date-range-label">
									<label class="control-label">to</label>
								</div>
								<div class="date-range-input">
									<ejs-datepicker formControlName="serviceDateEnd"
													placeholder="mm/dd/yyyy"
													[format]="dateFormat">
									</ejs-datepicker>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesStatementPrintDateFormGroup'">
						<label class="col-sm-4 control-label">Stmt. Print Date</label>
						<div class="col-sm-8">
							<div class="date-range">
								<div class="date-range-input">
									<ejs-datepicker formControlName="printDateStart"
													placeholder="mm/dd/yyyy"
													[format]="dateFormat">
									</ejs-datepicker>
								</div>
								<div class="date-range-label">
									<label class="control-label">to</label>
								</div>
								<div class="date-range-input">
									<ejs-datepicker formControlName="printDateEnd"
													placeholder="mm/dd/yyyy"
													[format]="dateFormat">
									</ejs-datepicker>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesClaimSubmitDateFormGroup'">
						<label class="col-sm-4 control-label">Claim Submit Date</label>
						<div class="col-sm-8">
							<div class="date-range">
								<div class="date-range-input">
									<ejs-datepicker formControlName="claimDateStart"
													placeholder="mm/dd/yyyy"
													[format]="dateFormat">
									</ejs-datepicker>
								</div>
								<div class="date-range-label">
									<label class="control-label">to</label>
								</div>
								<div class="date-range-input">
									<ejs-datepicker formControlName="claimDateEnd"
													placeholder="mm/dd/yyyy"
													[format]="dateFormat">
									</ejs-datepicker>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'invoicesInvoiceAgeFormGroup'">
						<label class="col-sm-4 control-label">Invoice Age</label>
						<div class="col-sm-8">
							<div class="row">
								<div class="col-lg-6">
									<pms-constant-dropdown
											[constantList]="invoiceAgeOptions"
											formControlName="invoiceAge"
											placeholder="All"
											[showClearButton]="true">
									</pms-constant-dropdown>
								</div>
								<div class="col-lg-6">
									<div class="date-range">
										<div class="date-range-input">
											<input type="number" revDigitOnlyInput class="e-input"
												   formControlName="invoiceAgeCustomStart">

										</div>
										<div class="date-range-label">
											<label class="control-label">to</label>
										</div>
										<div class="date-range-input">
											<input type="number" revDigitOnlyInput class="e-input"
												   formControlName="invoiceAgeCustomEnd">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row margin-top-sm">
				<div class="col-sm-12">
					<button ejs-button type="submit" iconCss="fa fa-search" [isPrimary]="true"
							[attr.data-test-id]="'invoiceDashboardSearchButton'">Search</button>
					<button ejs-button type="button" iconCss="fa fa-close" (click)="clear(templateForm)"
							[attr.data-test-id]="'invoiceDashboardClearButton'">Clear</button>
				</div>
			</div>
		</form>
	</div>

	<div class="panel panel-default">
		<div class="panel-body">
			<div *ngIf="userCanReceivePayment" class="form-inline margin-bottom-sm">
				<div class="form-group margin-0" [attr.data-test-id]="'receivePaymentButtonsInvoiceDashboard'">
					<label>Receive payment for:</label>
					<button ejs-button [isPrimary]="true" type="button" (click)="receivePayment(payerType.INSURANCE)" [attr.data-test-id]="'invoiceDashboardReceiveInsurancePaymentButton'">Insurance</button>
					<button ejs-button [isPrimary]="true" type="button" (click)="receivePayment(payerType.PATIENT)" [attr.data-test-id]="'invoiceDashboardReceivePatientPaymentButton'">Patient</button>
					<button ejs-button [isPrimary]="true" type="button" (click)="receivePayment(payerType.COLLECTIONS)" [attr.data-test-id]="'invoiceDashboardReceiveCollectionsPaymentButton'">Collections</button>
				</div>
			</div>
			<ng-container *ngIf="!isAgGridFeatureFlagOn">
				<ejs-grid #grid
						  autoFitColumns
						  class="table-rowlink"
						  (created)="gridCreated.next(true)"
						  (dataBound)="gridDatabind.next(true)"
						  [autoFitBlacklist]="['invoice.payerName', 'patientName']"
						  [staticColumns]="['checkbox', 'invoice.balance', 'print']"
						  [allowResizing]="true"
						  [dataSource]="invoices"
						  [allowSorting]="true"
						  [allowSelection]="true"
						  [pageSettings]="pageSettings"
						  [selectionSettings]="{type: 'Multiple', persistSelection: true, checkboxOnly: true}"
						  gridLines="Horizontal"
						  (rowSelected)="resetBatchActions()"
						  [allowPaging]="true"
						  (rowDeselected)="resetBatchActions()"
						  [aggregates]="aggregates"
						  [allowExcelExport]="true"
						  (excelQueryCellInfo)="exportFunction($event)"
						  [pmsGridSubmit]="isSearching"
						  (click)="rowClicked($event)"
						  [attr.data-test-id]="'invoiceDashboardItemsGrid'">
					<ng-template #toolbarTemplate let-data>
						<rev-table-action-menu [table]="grid" [items]="toolbar">
							<ng-container rev-table-action-menu-left>
								<div class="e-toolbar-item" *ngIf="getNumberOfSelectedRecords() > 0">
									{{ getItemsSelectedText() }}
								</div>
								<div class="e-toolbar-item">
									<ejs-dropdownlist #dropdownActions
													  [enabled]="actionDropdownEnabled"
													  [value]="actionValue"
													  placeholder="Actions"
													  [dataSource]="batchActions"
													  (valueChange)="applyAction($event)"></ejs-dropdownlist>
								</div>
							</ng-container>
						</rev-table-action-menu>
					</ng-template>
					<e-columns>
						<e-column field="checkbox" type="checkbox" [customAttributes]="{exportable: false}" [allowResizing]="false" [width]="37"></e-column>
						<e-column field="invoice.approval" headerText="Approval" [allowSorting]="true">
							<ng-template #template let-data>
								<div (click)="openInvoiceTab(data.invoice)">
									<span *ngIf="data.invoice.approval" class="text-success">Authorized</span>
									<span *ngIf="!data.invoice.approval">Pending</span>
								</div>
							</ng-template>
						</e-column>
						<e-column field="status" headerText="Status"></e-column>
						<e-column field="invoice.invoiceAge" headerText="Age">
							<ng-template #template let-data>
								<div [ngClass]="getInvoiceAgeClass(data.invoice.invoiceAge)" (click)="openInvoiceTab(data.invoice)">
									{{ data.invoice.invoiceAge }}
								</div>
							</ng-template>
						</e-column>
						<e-column field="id" headerText="#" [isPrimaryKey]="true"></e-column>
						<e-column field="invoice.payerName" headerText="Payer" clipMode="EllipsisWithTooltip"></e-column>
						<e-column field="patientName" headerText="Patient" clipMode="EllipsisWithTooltip">
							<ng-template #template let-data>
								<div *ngIf="!isGuestPayer(data.invoice)" (click)="openInvoiceTab(data.invoice)">{{ data.patientName }}</div>
							</ng-template>
						</e-column>
						<e-column field="invoice.invoiceDate" headerText="Invoice Date" [format]="dateFormatter"></e-column>
						<e-column field="invoice.serviceDate" headerText="Service Date" [format]="dateFormatter"></e-column>
						<e-column field="invoice.statementDate" headerText="Statement Date" [format]="dateFormatter"></e-column>
						<e-column field="invoice.total" headerText="Amount" type="number" format="C2"></e-column>
						<e-column width="122" field="invoice.balance" headerText="Balance" type="number" format="C2"></e-column>
						<e-column width="52" field="print" headerText="" [allowSorting]="false" [customAttributes]="{exportable: false}">
							<ng-template #template let-data>
								<rev-button
										*ngIf="isPrintable(data)"
										[revGridButton]="'print'"
										(buttonClick)="printInvoice(data)"
										dataTestId="invoiceListPrintButton">
								</rev-button>
							</ng-template>
						</e-column>
					</e-columns>
				</ejs-grid>
			</ng-container>

			<ng-container *ngIf="isAgGridFeatureFlagOn">
				<rev-ag-grid-container [agGrid]="agGrid">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item" *ngIf="getNumberOfSelectedRecords() > 0">
							{{ getItemsSelectedText() }}
						</div>
						<div class="e-toolbar-item">
							<ejs-dropdownlist #dropdownActions
											  [enabled]="actionDropdownEnabled"
											  [value]="actionValue"
											  placeholder="Actions"
											  [dataSource]="batchActions"
											  (valueChange)="applyAction($event)"
											  [attr.data-test-id]="'actionsDropdown'"></ejs-dropdownlist>
						</div>
					</ng-container>
					<ng-container rev-container-grid>
						<ag-grid-angular class="ag-theme-alpine"
										 pmsAgAutoFitColumns
										 #agGrid
										 [pmsAgGridSubmit]="isSearching"
										 (selectionChanged)="resetBatchActions()"
										 (rowDataUpdated)="setupAggregates()"
										 (rowClicked)="agGridRowClicked($event)"
										 [rowData]="invoices"
										 [domLayout]="'autoHeight'"
										 [gridOptions]="invoicesDashboardGridOptions"
										 [rowSelection]="'multiple'"
										 (gridReady)="onGridReady($event)"
										 [pagination]="true"
										 [attr.data-test-id]="'invoicesTable'">
						</ag-grid-angular>
					</ng-container>
				</rev-ag-grid-container>
			</ng-container>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="!userCanViewInvoices">
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="alert alert-danger" [attr.data-test-id]="'invoicesDetailPermissionDenialAlertMessageLabel'">You do not have permission to view this page. Please see the System Administrator for more information.</div>
		</div>
	</div>
</ng-container>


<ng-template #invoiceApprovalColumn let-data>
	<ng-container *ngIf="!data.isAggregate">
		<div>
			<span *ngIf="data.invoice.approval" class="text-success">Authorized</span>
			<span *ngIf="!data.invoice.approval">Pending</span>
		</div>
	</ng-container>
</ng-template>

<ng-template #invoiceAgeColumn let-data>
	<div [ngClass]="getInvoiceAgeClass(data.invoice.invoiceAge)">
		{{ data.invoice.invoiceAge }}
	</div>
</ng-template>

<ng-template #patientNameColumn let-data>
	<div *ngIf="!isGuestPayer(data.invoice)">{{ data.patientName }}</div>
</ng-template>

<ng-template #printColumn let-data>
	<rev-button
			*ngIf="isPrintable(data)"
			[revGridButton]="'print'"
			(buttonClick)="printInvoice(data)"
			dataTestId="invoiceListPrintButton">
	</rev-button>
</ng-template>
