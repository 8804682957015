import { Action } from '@ngrx/store';

export enum ResponseActionTypes {
	REMOVE_ENDPOINT_RESPONSE = '[Remove Endpoint Response] Remove the response associated with the endpoint.',
	UPDATE_ENDPOINT_RESPONSE = '[Update Endpoint Response] Update the response associated with an endpoint.',
}

export class RemoveEndpointResponse implements Action {
	type: string = ResponseActionTypes.REMOVE_ENDPOINT_RESPONSE;
	constructor(public payload: {endpointId: string}) {}
}

export class UpdateEndpointResponse<T = any> {
	readonly type = ResponseActionTypes.UPDATE_ENDPOINT_RESPONSE;
	constructor(public payload: {endpointId: string, response: T}) {}
}

export type ResponseActions =
	| RemoveEndpointResponse
	| UpdateEndpointResponse;
