<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-sm"
	[attr.data-test-id]="'selectLocationModal'">
	<ng-template #header>
		<div class="dlg-template">Select a Location</div>
	</ng-template>
	<ng-template #content>
		<form
			#templateForm="ngForm"
			(ngSubmit)="save()"
			[formGroup]="componentForm"
			class="form-horizontal">
			<gandalf-lib-validation-messages
				*ngIf="componentForm.invalid && templateForm.submitted"
				[form]="componentForm"
				[requestObj]="request"
				[attr.data-test-id]="'selectLocationModalValidationMessages'">
			</gandalf-lib-validation-messages>
			<div class="form-horizontal">
				<div class="form-group">
					<label for="location" class="col-sm-4 control-label">Location</label>
					<div class="col-sm-8">
						<ejs-dropdownlist
							id="location"
							placeholder="Select Location"
							[dataSource]="locationsList"
							formControlName="locationId">
						</ejs-dropdownlist>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectLocationCancelButton'">Cancel</button>
		<button type="button" ejs-button [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'selectLocationSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
