<ejs-dialog
		#modal
		[zIndex]="zIndexBehindHitPmsHtmlModal"
		(close)="closeDialog()"
		cssClass="modal-lg"
		[attr.data-test-id]="'selectProductItemModal'">
	<ng-template #header>
		<div class="dlg-template" [attr.data-test-id]="'selectProductItemModalHeader'">Select Product Item</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature" *ngIf="!onlyProductSelection">
			<pms-barcode-search
					[productCategoryId]="productCategoryId"
					[searchType]="searchType"
					[locationId]="locationId"
					[buttonLabel]="'Add'"
					[label]="'Add by Barcode'"
					[trialFilter]="contactLensFilter"
					[focusInput]="barcodeFocusPreference"
					(results)="handleBarcodeResults($event)">
			</pms-barcode-search>
		</div>
		<div>
			<pms-product-selection #productSelectionComponent
								   [onlyProductSelection]="onlyProductSelection"
								   [trialFilter]="contactLensFilter"
								   [contactLensType]="contactLensType"
								   (locationProductSelected)="onLocationProductSelection($event)"
								   (locationProductItemSelected)="onLocationProductItemSelection($event)"
								   [productCategory]="productCategory"
								   [doNotFocus]="barcodeFocusPreference"
								   [locationId]="locationId">
			</pms-product-selection>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button (click)="closeDialog()" iconCss="fa fa-close" [attr.data-test-id]="'selectProductItemModalCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>



