<div class="panel-body">
	<ejs-grid #grid
			  [allowExcelExport]="true"
			  [allowFiltering]="true"
			  [allowPaging]="true"
			  [pageSettings]="pageSettings"
			  [allowResizing]="true"
			  [allowSelection]="true"
			  [allowSorting]="true"
			  autoFitColumns
			  [autoFitBlacklist]="['shortDescription']"
			  class="table-rowlink"
			  [dataSource]="filteredDiagnoses"
			  gridLines="Horizontal"
			  [pmsGridSubmit]="isSearching"
			  (rowSelected)="openDiagnosisDetails($event.data)"
			  [staticColumns]="['statusButton']">
		<ng-template #toolbarTemplate let-data>
			<rev-table-action-menu [table]="grid">
				<ng-container rev-table-action-menu-left>
					<div class="e-toolbar-item">
						<input class="e-input" type="text" id="codeFilter" placeholder="Filter by Code/Description"
							   (input)="debounceFilter()" [(ngModel)]="codeFilter"/>
					</div>
					<div class="e-toolbar-item">
						<label>Status</label>
						<pms-enum-select-button
							[(ngModel)]="statusFilter"
							[enumList]="statusFilterOptions"
							[allOption]="true"
                            (valueChange)="debounceFilter()">
						</pms-enum-select-button>
					</div>
				</ng-container>
			</rev-table-action-menu>
		</ng-template>
		<e-columns>
			<e-column field="code" headerText="Code" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="shortDescription" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="carePlanTemplateName" headerText="Care Plan" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="activeText" headerText="Status" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="statusButton" headerText=""
					  [width]="50"
					  [customAttributes]="{exportable: false}"
					  [allowResizing]="false"
					  [allowSorting]="false">
				<ng-template #template let-data>
					<rev-button
							*ngIf="data.status === diagnosisStatus.INACTIVE"
							[revGridButton]="'activate'"
							(buttonClick)="activateDiagnosis(data)"
							dataTestId="diagnosisActivateButton">
					</rev-button>
					<rev-button
							*ngIf="data.status === diagnosisStatus.ACTIVE"
							[revGridButton]="'deactivate'"
							(buttonClick)="deactivateDiagnosis(data)"
							dataTestId="diagnosisDeactivateButton">
					</rev-button>
				</ng-template>
			</e-column>
		</e-columns>
	</ejs-grid>
</div>
