// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.AddInvoiceItemRequest */
/* istanbul ignore next */
@GandalfModel
export class AddInvoiceItemRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Item type is required' } })
	@GandalfProperty({ isRequired: true })
	itemType: constants.InvoiceItemType;

	@GandalfProperty()
	locationProductId: number;

	@GandalfProperty()
	locationProductItemId: number;

	@GandalfValidator({ notNull: { message: 'Item quantity is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Quantity must be at least {min}' } })
	@GandalfProperty({ isRequired: true })
	quantity: number;

	@GandalfProperty()
	serviceId: number;

}
