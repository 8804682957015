<rev-loading-overlay [loading]="_isLoading">
	<rev-button
			(buttonClick)="paymentComponentService.confirmClosePayment()"
			[buttonDisabled]="_isLoading"
			buttonIcon="close"
			buttonLabel="Cancel"
			dataTestId="paymentCancelButton">
	</rev-button>
	<rev-button
			*ngIf="showEditPayment()"
			(buttonClick)="launchEditPaymentModal()"
			dataTestId="editPaymentButton"
			[buttonDisabled]="_isLoading"
			buttonStyle="primary"
			buttonLabel="Edit Payment">
	</rev-button>
	<ng-container *ngIf="paymentComponentService.isPaymentFormGroupLoaded">
		<rev-button
				class="pull-left"
				*ngIf="showApplyPaymentWithoutProcessing()"
				(buttonClick)="applyPaymentWithoutProcessing($event)"
				[buttonDisabled]="disableApplyPaymentsWithoutProcessing()"
				dataTestId="applyPaymentsWithoutProcessingButton"
				buttonLabel="Apply payment without processing card">
		</rev-button>
		<rev-button
				(buttonClick)="saveOrApplyPayment($event, false, false)"
				[buttonDisabled]="_isLoading"
				dataTestId="paymentSaveAsPendingButton"
				buttonLabel="Save as Pending">
		</rev-button>
		<rev-button
				*ngIf="showPrintReceipts()"
				(buttonClick)="saveOrApplyPayment($event, true, true)"
				[buttonDisabled]="_isLoading"
				buttonStyle="primary"
				dataTestId="applyPaymentsAndPrintReceiptsButton"
				buttonLabel="Apply Payments & Print Receipts">
		</rev-button>
		<rev-button
				*ngIf="!showProcessPayment()"
				(buttonClick)="saveOrApplyPayment($event, true, false)"
				[buttonDisabled]="_isLoading"
				buttonStyle="primary"
				dataTestId="applyPaymentsButton"
				buttonLabel="Apply Payments">
		</rev-button>
		<rev-button
				*ngIf="showProcessPaymentAndPrintReceipts()"
				(buttonClick)="processPayment($event, true)"
				[buttonDisabled]="_isLoading"
				buttonStyle="primary"
				dataTestId="processPaymentAndPrintReceiptsButton"
				buttonLabel="Process Payment & Print Receipts">
		</rev-button>
		<rev-button
				*ngIf="showProcessPayment()"
				(buttonClick)="processPayment($event, false)"
				[buttonDisabled]="_isLoading"
				buttonStyle="primary"
				dataTestId="processPaymentsButton"
				buttonLabel="Process Payment">
		</rev-button>
	</ng-container>
</rev-loading-overlay>
