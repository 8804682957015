<rev-loading-overlay [loading]="_isLoading">
	<div class="mrgn-btm">
		<h4 class="media-heading margin-bottom-0" [attr.data-test-id]="'paymentTitleLabel'">{{paymentTitle}}</h4>
	</div>
	<div class="panel panel-default">
		<div class="panel-body">
			<rev-ag-grid-container *ngIf="!isPayerSelected && gridOptions" [agGrid]="payerGrid" [attr.data-test-id]="'payerGrid'">
				<ng-container rev-table-action-menu-left>
					<div class="e-toolbar-item">
						<input
								[(ngModel)]="nameFilter"
								[revFocusInput]="true"
								class="e-input"
								type="text"
								placeholder="Filter by Payer Name"
								(input)="debounceFilter()"/>
					</div>
					<div class="e-toolbar-item">
						<ejs-checkbox [(ngModel)]="manuallySelectInvoices" label="Use manually selected invoices" [attr.data-test-id]="'paymentModalUseManuallyCheckbox'"></ejs-checkbox>
					</div>
					<div class="e-toolbar-item">
						<button ejs-button type="button" iconCss="fa fa-close" (click)="clear()" [attr.data-test-id]="'paymentModalClearButton'">Clear</button>
					</div>
				</ng-container>
				<ng-container rev-container-grid>
					<ag-grid-angular #payerGrid
									 class="ag-theme-alpine"
									 pmsAgAutoFitColumns
									 [rowData]="payers"
									 [gridOptions]="gridOptions">
					</ag-grid-angular>
				</ng-container>
			</rev-ag-grid-container>
			<pms-payment-container
					*ngIf="isPayerSelected"
					[state]="state.receivePaymentState"
					[payer]="selectedPayer"
					[paymentGroupId]="paymentGroupId"
					[isModal]="false">
			</pms-payment-container>
		</div>
		<div class="panel-footer text-right">
			<pms-receive-payments-actions
					[paymentGroupId]="paymentGroupId">
			</pms-receive-payments-actions>
		</div>
	</div>
</rev-loading-overlay>
