// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.VoidInvoiceRequest */
/* istanbul ignore next */
@GandalfModel
export class VoidInvoiceRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Void Invoice Comments are required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 255, message: 'Comments must be between {min} and {max} characters' } })
	@GandalfProperty({ isRequired: true })
	comments: string;

	@GandalfValidator({ notNull: { message: 'Invoice Id must not be null' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

}
