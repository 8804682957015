<form [formGroup]="parentFormGroup">
	<div [attr.data-test-id]="'addressOneGroup'" class="form-group">
		<label for="addressLine1" class="control-label form-label-col" [class.required]="isAddressLine1Required">Address</label>
		<div class="form-input-col">
			<input id="addressLine1" type="text" class="e-input" formControlName="addressLine1" />
		</div>
	</div>

	<div [attr.data-test-id]="'addressTwoGroup'" class="form-group">
		<div class="form-input-col pull-right">
			<input id="addressLine2" type="text" class="e-input" formControlName="addressLine2" />
		</div>
	</div>

	<div [attr.data-test-id]="'cityGroup'" class="form-group">
		<label for="city" class="control-label form-label-col" [ngClass]="{'required' : isCityRequired || isCityConditionallyRequired}">City</label>
		<div class="form-input-col">
			<input id="city" type="text" class="e-input" formControlName="city" />
		</div>
	</div>

	<div [attr.data-test-id]="'stateGroup'" class="form-group">
		<label for="stateProvince" class="control-label form-label-col" [class.required]="isStateProvinceRequired">State/Prov</label>
		<div class="form-input-col">
			<ejs-dropdownlist
				*ngIf="stateProvinces"
				placeholder="Select State/Prov"
				formControlName="stateProvince"
				id="stateProvince"
				[dataSource]="stateProvinces"
				[attr.data-test-id]="'stateDropdown'">
			</ejs-dropdownlist>
		</div>
	</div>
	<div [attr.data-test-id]="'zipCodeGroup'" class="form-group">
		<label for="postalCode" class="control-label form-label-col" [class.required]="isPostalCodeRequired">Zip Code</label>
		<div class="form-input-col">
			<input *ngIf="!allowNonStandardZipCodes" id="postalCode" type="text" class="e-input" formControlName="postalCode" (keyup)="onPostalCodeChange()" (blur)="onPostalCodeBlur()" />
			<input *ngIf="allowNonStandardZipCodes" id="nonStandardPostalCode" type="text" class="e-input" formControlName="nonStandardPostalCode" (keyup)="onPostalCodeChange()" />
		</div>
	</div>
</form>

