/* eslint-disable max-len */
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
	CellFormattingUtils,
	DynamicModalRef,
	EnumUtil,
	GridUtil,
	GridUtilService,
	ModalConfig,
	ModalManagerService,
	SortingService,
	TooltipService
} from 'morgana';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { HIT_PMS_HTML_PREFERENCES } from '@core/legacy/hit-pms-html.constants';
import { _filter, _find, _findIndex, _isEmpty, _isNil, _map, _reduce } from '@core/lodash/lodash';
import { PatientService } from '@core/patient/patient.service';
import { LogMethodTime } from '@core/performance/log-method-time.decorator';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { InvoiceItemAdjustmentResponse } from '@gandalf/model/invoice-item-adjustment-response';
import { InvoiceItemPaymentRequest } from '@gandalf-black/model/invoice-item-payment-request';
import { InvoiceItemResponse } from '@gandalf/model/invoice-item-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { ReceivePaymentTransferInvoiceItemsRequest } from '@gandalf-black/model/receive-payment-transfer-invoice-items-request';
import { TransferItemRequest } from '@gandalf-black/model/transfer-item-request';
import {
	InvoiceItemAdjustmentStatus,
	InvoiceItemAdjustmentType,
	InvoiceItemStatus,
	InvoiceItemType,
	PayerType,
	PreferenceDefaults,
	PreferenceName,
	TransferType
} from '@gandalf/constants';
import { PatientSearchByIdsRequest } from '@gandalf/model/patient-search-by-ids-request';
import { PatientSearchResponse } from '@gandalf/model/patient-search-response';
import { TooltipCellRendererComponent } from '@shared/component/tooltip-cell-renderer/tooltip-cell-renderer.component';
import { conditionallyRequiredValidator } from '@shared/validators/conditionally-required-validation';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { GridComponent, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, TooltipEventArgs } from '@syncfusion/ej2-angular-popups';
import { AgGridAngular } from 'ag-grid-angular';
import {
	CellFocusedEvent,
	CellMouseDownEvent,
	ColDef,
	Column,
	ColumnApi,
	GridApi,
	GridOptions,
	GridReadyEvent,
	IRowNode
} from 'ag-grid-community';
import Big from 'big.js';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ReferenceDataResponse } from '@gandalf/model/reference-data-response';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import {
	AccountingService,
	FormattedInsuranceResponse,
	FormattedInvoiceDateResponse,
	FormattedInvoiceDetailsItem
} from '../../core/accounting/accounting.service';
import { InvoiceItemPricingUtil } from '../../core/accounting/invoice-item-pricing/invoice-item-pricing-util';

export interface TransferItem {
	invoiceItem: InvoiceItemResponse;
	invoiceItemDetails: FormattedInvoiceDetailsItem;
	subtotal: number;
	totalDiscount: number;
	tax: number;
	balance: number;
	extPrice: number;
	adjustmentTotal: number;
	paymentAmount: number;
	totalTransferAmount: number;
	paymentTotalAmount: number;
	autoPayBalance?: number;
	transferIds?: number[];
}

export interface Transfer {
	transferRequest: ReceivePaymentTransferInvoiceItemsRequest;
	id: number;
}

export interface ReceivePaymentsTransferItemsModalResponse {
	transferItemRequests: ReceivePaymentTransferInvoiceItemsRequest[];
	paymentItemRequests: InvoiceItemPaymentRequest[];
	itemsPaidTotal: number;
	transferTotal: number;
}

@Component({
	selector: 'pms-transfer-items-modal',
	templateUrl: './receive-payments-transfer-items-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class ReceivePaymentsTransferItemsModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	templateForm: NgForm;

	@ViewChild('grid')
	paymentItemSyncfusionGrid: GridComponent;

	@ViewChild('transferWriteOffGrid')
	transferWriteOffSyncfusionGrid: GridComponent;

	@ViewChild('adjustmentSyncfusionTooltip')
	adjustmentSyncfusionTooltip: ElementRef;

	@ViewChild('taxSyncfusionTooltip')
	taxSyncfusionTooltip: ElementRef;

	@ViewChild('paymentItemGrid')
	paymentItemGrid: AgGridAngular;

	@ViewChild('transferWriteOffGrid')
	transferWriteOffGrid: AgGridAngular;

	@ViewChild('adjustmentTooltip')
	adjustmentTooltip: TemplateRef<any>;

	@ViewChild('totalDiscountTooltip')
	totalDiscountTooltip: TemplateRef<any>;

	@ViewChild('taxTooltip')
	taxTooltip: TemplateRef<any>;

	@ViewChild('invoiceDropdown')
	invoiceDropdown: DropDownListComponent;

	@ViewChild('transferAmountColumn')
	transferAmountColumn: TemplateRef<any>;

	@ViewChild('paymentAmountColumn')
	paymentAmountColumn: TemplateRef<any>;

	@ViewChild('adjustmentsColumn')
	adjustmentsColumn: TemplateRef<any>;

	@ViewChild('paymentTotalColumn')
	paymentTotalColumn: TemplateRef<any>;

	@ViewChild('balanceColumn')
	balanceColumn: TemplateRef<any>;

	@ViewChild('transferNumberColumn')
	transferNumberColumn: TemplateRef<any>;

	@ViewChild('transferTypeColumn')
	transferTypeColumn: TemplateRef<any>;

	@ViewChild('transferReasonColumn')
	transferReasonColumn: TemplateRef<any>;

	@ViewChild('transferToColumn')
	transferToColumn: TemplateRef<any>;

	@ViewChild('transferRemoveColumn')
	transferRemoveColumn: TemplateRef<any>;

	private unsubscribe$ = new Subject<void>();

	paymentItemGridApi: GridApi;
	paymentItemGridColumnApi: ColumnApi;
	transferItemGridApi: GridApi;
	transferItemGridColumnApi: ColumnApi;
	focusedColumnKey: string;
	focusedRowId: number;
	invoiceId: number;
	invoice: InvoiceResponse;
	request: ReceivePaymentTransferInvoiceItemsRequest;
	formGroup: UntypedFormGroup;
	transferReasons: OptionItemResponse<ReferenceDataResponse>[];
	writeoffReasons: OptionItemResponse<ReferenceDataResponse>[];
	allAdjustmentReasons: OptionItemResponse<ReferenceDataResponse>[];
	transferTypes = TransferType.VALUES.values;
	transferType = TransferType;
	hasFinanceCharge = false;
	removeTransferToPatientOption = false;
	personInsuranceList: FormattedInsuranceResponse[];
	removeTransferToInsuranceOption = false;
	transferToInvoiceList: FormattedInvoiceDateResponse[];
	removeTransferToExistingInvoiceOption = false;
	TRANSFER_TO_NEW_INVOICE_LABEL = 'new';
	TRANSFER_TO_EXISTING_INVOICE_LABEL = 'existing';
	hideTransferToSection = false;
	showWriteOffOption = false;
	allowTransferByItem = false;
	isSearching = true;
	_invoiceItems: TransferItem[];
	totalCurrentBalance = Number(0);
	totalBalanceAfterTransferAndPayment = Number(0);
	patientCredit: number;
	staticColumns = ['invoiceItem.quantity', 'paymentAmount', 'adjustmentTotal', 'paymentTotalAmount', 'balance'];
	transfers: Transfer[] = [];
	showTable = true;
	showPaymentColumn = false;
	invoicePaymentAmount = Number(0);
	itemPaymentTotal = Number(0);
	itemTransfersTotal = Number(0);
	lastTransferId = 0;
	useItemTransfersOnPayments: boolean;
	patientInfo: PatientSearchResponse;
	readOnly = false;
	tooltipData: TransferItem;
	payerName: string;
	payerTypes = [PayerType.PATIENT, PayerType.INSURANCE];
	payerType = PayerType;
	paymentAmountSum = 0;
	adjustmentTotalSum = 0;
	paymentTotalAmountSum = 0;
	balanceSum = 0;
	tooltipDiscount: boolean;
	invoiceItemsMap: Map<number, TransferItem>;
	paymentGridOptions: GridOptions = GridUtil.buildGridOptions();
	transferGridOptions: GridOptions = GridUtil.buildGridOptions();
	lastFocusedCell: any;
	paymentItemColumns: ColDef[];
	transferItemColumns: ColDef[];
	cancelFocusEvent = false;
	isAgGridFeatureFlagOn: boolean;

	get invoiceItems() {
		return this._invoiceItems;
	}

	set invoiceItems(invoiceItems: TransferItem[]) {
		this._invoiceItems = invoiceItems;
		this.reinitInvoiceItemMap();
	}

	get showTransferReasonsDropdown() {
		return !_isEmpty(this.transferReasons) && EnumUtil.equals(this.formGroup.get('transferType').value, TransferType.TRANSFER);
	}

	get showWriteoffReasonsDropdown() {
		return !_isEmpty(this.writeoffReasons) && EnumUtil.equals(this.formGroup.get('transferType').value, TransferType.WRITEOFF);
	}

	get transferToText(): string {
		if (this.removeTransferToInsuranceOption && !this.removeTransferToPatientOption) {
			return 'Transfer To Patient';
		} else if (this.removeTransferToPatientOption && !this.removeTransferToInsuranceOption) {
			return 'Transfer To Insurance';
		}
		return 'Transfer To';
	}

	get showPayerInvoiceOptions() {
		return EnumUtil.equals(this.formGroup.get('payerType').value, PayerType.PATIENT);
	}

	get showExistingInvoiceOption() {
		return  !_isEmpty(this.transferToInvoiceList) && this.allowTransferByItem;
	}

	get showExistingInvoiceDropdown() {
		return this.formGroup.get('transferToNewOrExistingInvoice').value === this.TRANSFER_TO_EXISTING_INVOICE_LABEL
			&& this.showExistingInvoiceOption;
	}

	constructor(
		private ref: DynamicModalRef,
		private modalConfig: ModalConfig,
		private accountingService: AccountingService,
		private patientService: PatientService,
		public gandalfFormBuilder: GandalfFormBuilder,
		public formBuilder: UntypedFormBuilder,
		private securityManager: SecurityManagerService,
		private tooltipService: TooltipService,
		private cellFormattingUtils: CellFormattingUtils,
		private featureService: FeatureService,
		private gridUtilService: GridUtilService,
	) {
	}

	ngOnInit() {
		this.isAgGridFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.INVOICE.RECEIVE_PAYMENTS.AG_GRID);
		this.readOnly = !!this.modalConfig.data.readOnly;
		this.invoiceId = this.modalConfig.data.invoiceId;
		this.invoicePaymentAmount = this.modalConfig.data.invoicePaymentAmount;
		this.payerName = this.modalConfig.data.payerName;
		// These need to be determined upstream because if even one invoice has item payments or transfers
		// they should all work as if they do
		const allowPaymentByItem = this.modalConfig.data.allowPaymentByItem;
		this.useItemTransfersOnPayments = this.modalConfig.data.allowItemsTransfer;

		this.showWriteOffOption = this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_WRITEOFF);
		this.allowTransferByItem = this.securityManager.preferenceValueIsOn(
			PreferenceName.ACCOUNTING_TRANSFER_BY_ITEM.value,
			PreferenceDefaults.ACCOUNTING_TRANSFER_BY_ITEM.value,
		);

		const payByItem = this.securityManager.preferenceValue(HIT_PMS_HTML_PREFERENCES.ACCOUNTING_PAY_BY_ITEM.name);
		this.showPaymentColumn = allowPaymentByItem || payByItem !== HIT_PMS_HTML_PREFERENCES.ACCOUNTING_PAY_BY_ITEM.values.NONE;


		this.removeTransferToExistingInvoiceOption = !this.useItemTransfersOnPayments;

		this.getData();
		this.buildRequest();
		this.buildForm();
	}

	/* istanbul ignore next: buildColumn */
	buildPaymentItemColumns() {
		this.paymentItemColumns = [
			GridUtil.buildColumn('Code', 'invoiceItem.code', {
				width: 100,
				sortable: false,
				suppressNavigable: true,
				tooltipField: 'invoiceItem.code',
			}),
			GridUtil.buildColumn('Description', 'invoiceItem.description', {
				minWidth: 120,
				sortable: false,
				suppressNavigable: true,
				flex: 1,
				tooltipField: 'invoiceItem.description',
			}),
			GridUtil.buildNumericColumn('Qty', 'invoiceItem.quantity', {
				width: 45,
				sortable: false,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Unit Price', 'invoiceItem.unitPrice', {
				width: 85,
				sortable: false,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Sub-Total', 'subtotal', {
				width: 80,
				sortable: false,
				suppressNavigable: true,
			}),
			this.gridUtilService.buildCurrencyColumn('Discounts', 'totalDiscount', {
				width: 80,
				sortable: false,
				suppressNavigable: true,
				tooltipField: 'totalDiscount',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.totalDiscountTooltip,
				},
			}),
			this.gridUtilService.buildCurrencyColumn('Tax', 'tax', {
				width: 65,
				sortable: false,
				suppressNavigable: true,
				tooltipField: 'tax',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.taxTooltip,
				},
			}),
			this.gridUtilService.buildCurrencyColumn('Ext. Price', 'extPrice', {
				width: 80,
				sortable: false,
				suppressNavigable: true,
			}),
			GridUtil.buildTemplateColumn('Payment', 'paymentAmount', this.paymentAmountColumn,{
				width: 100,
				sortable: false,
				hide: !this.showPaymentColumn,
				valueGetter: params => this.getPaymentAmountFormControl(params.node)?.value,
			}),
			...this.transfers.map((transfer, index) => GridUtil.buildTemplateColumn(`Transfer ${index + 1}`, `transferAmount${transfer.id}`, this.transferAmountColumn, {
				width: 100,
				sortable: false,
				valueGetter: params => this.getTransferAmountFormControl(params.node)?.value,
			})),
			GridUtil.buildTemplateColumn('Adjustments', 'adjustmentTotal', this.adjustmentsColumn, {
				width: 100,
				sortable: false,
				suppressNavigable: true,
				tooltipField: 'adjustmentTotal',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.adjustmentTooltip,
				},
				type: 'numericColumn',
				hide: this.readOnly,
			}),
			GridUtil.buildTemplateColumn('Paid', 'paymentTotalAmount', this.paymentTotalColumn, {
				width: 100,
				type: 'numericColumn',
				hide: this.readOnly,
				suppressNavigable: true,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Balance', 'balance', this.balanceColumn, {
				width: 105,
				type: 'numericColumn',
				hide: this.readOnly,
				suppressNavigable: true,
				sortable: false,
			}),
		];
		this.mapTransferIds();
	}

	mapTransferIds() {
		const transferIds = this.transfers?.map((transfer) => transfer.id);
		this.invoiceItems?.forEach((invoiceItem) => invoiceItem.transferIds = transferIds);
	}

	onPaymentItemGridReady(params: GridReadyEvent) {
		this.paymentItemGridApi = params.api;
		this.paymentItemGridColumnApi = params.columnApi;
		this.mapTransferIds();
		this.buildPaymentItemColumns();

		this.paymentItemGridApi.setColumnDefs(this.paymentItemColumns);
		if (!_isNil(this.invoiceItems)) {
			this.buildAggregateData();
		}
	}

	onTransferItemGridReady(params: GridReadyEvent) {
		this.transferItemGridApi = params.api;
		this.transferItemGridColumnApi = params.columnApi;
		this.buildTransferItemColumns();

		this.transferItemGridApi.setColumnDefs(this.transferItemColumns);
	}

	buildTransferItemColumns() {
		this.transferItemColumns = [
			GridUtil.buildTemplateColumn('#', 'index', this.transferNumberColumn, {
				width: 35,
				suppressNavigable: true,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Type', 'transferRequest.transferType', this.transferTypeColumn, {
				width: 75,
				suppressNavigable: true,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Reason', 'transferRequest.reason', this.transferReasonColumn, {
				minWidth: 100,
				flex: 2,
				suppressNavigable: true,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('To', 'transferRequest.transferTo', this.transferToColumn, {
				minWidth: 100,
				flex: 1,
				suppressNavigable: true,
				sortable: false,
			}),
			GridUtil.buildColumn('All Items', 'transferRequest.includeAllItems', {
				valueFormatter: this.cellFormattingUtils.yesNo(),
				cellDataType: 'text',
				hide: !this.useItemTransfersOnPayments,
				width: 75,
				suppressNavigable: true,
				sortable: false,
			}),
			this.gridUtilService.buildCurrencyColumn('Amount', 'transferRequest.transferAmount', {
				hide: this.useItemTransfersOnPayments,
				suppressNavigable: true,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('', 'remove', this.transferRemoveColumn, {
				hide: this.readOnly,
				width: 45,
				suppressNavigable: true,
				sortable: false,
				resizable: false,
			}),
		];
	}

	isAggregateRow(data) {
		return !!data.isAggregateRow;
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	closeModal(save = false) {
		const results = save ? this.buildResults() : null;
		this.ref.close(this.modal, results);
	}

	getData() {
		combineLatest([
			this.accountingService.findAllTransferReasonsForDropdown(),
			this.accountingService.findAllWriteoffReasonsForDropdown(),
			this.accountingService.getInvoiceById(this.invoiceId),
			this.accountingService.findInvoiceDetailsItemsByInvoiceId(this.invoiceId),
		]).subscribe(([transferReasons, writeoffReasons, invoice, invoiceItemDetails]) => {
			this.handleFetchedData(transferReasons, writeoffReasons, invoice, invoiceItemDetails);
			this.getPatientData();
			if (!_isNil(this.paymentItemGridApi)) {
				this.buildAggregateData();
			}
		});
	}

	buildAggregateData() {
		const aggregateRow = [{
			isAggregateRow: true,
			paymentAmount: this.paymentAmountSum,
			adjustmentTotal: this.adjustmentTotalSum,
			paymentTotalAmount: this.paymentTotalAmountSum,
			balance: this.balanceSum,
		}];

		GridUtil.setAgGridAggregateRow(this.paymentItemGrid, aggregateRow);
	}

	handleFetchedData(allTransferReasons: OptionItemResponse<ReferenceDataResponse>[], allWriteoffReasons: OptionItemResponse<ReferenceDataResponse>[], invoice: InvoiceResponse, invoiceItemDetails: FormattedInvoiceDetailsItem[]) {
		this.isSearching = false;
		this.transferReasons = allTransferReasons.filter(reason => reason.active);
		this.writeoffReasons = allWriteoffReasons.filter(reason => reason.active);
		this.allAdjustmentReasons = allTransferReasons.concat(allWriteoffReasons);
		this.invoice = invoice;
		const transferableItems = _filter(this.invoice.items, item => this.canReceivePayment(item));
		this.prepareFormAndRequestWithItems(transferableItems);
		this.invoiceItems = _map(transferableItems, (item) => this.updateItem({
			invoiceItem: item,
			invoiceItemDetails: _find(invoiceItemDetails, (invoiceItemDetail) => invoiceItemDetail.invoiceItemId === item.id),
			subtotal: Number(InvoiceItemPricingUtil.calculateSubTotal(item.unitPrice, item.quantity)),
			totalDiscount: 0,
			tax: 0,
			balance: 0,
			extPrice: 0,
			adjustmentTotal: 0,
			paymentAmount: 0,
			totalTransferAmount: 0,
			paymentTotalAmount: 0,
		}, 0, null, true));
		this.mapTransferIds();
		this.updateTotals();
	}

	/**
	 * Determines if an invoice item can payment transfer
	 */
	canPaymentTransferItem(item: InvoiceItemResponse) {
		// Item must not be Discounts, Credits, Refunds, Write-offs, Transfer-in or Removed
		return ((item.type === InvoiceItemType.PRODUCT
				|| item.type === InvoiceItemType.SERVICE
				|| item.type === InvoiceItemType.AD_HOC)
			&& item.status === InvoiceItemStatus.ACTIVE
			&& (!item.split && !item.wasSplit));
	}

	canReceivePayment(item: InvoiceItemResponse) {
		// Item must be active
		return EnumUtil.equals(item.status, InvoiceItemStatus.ACTIVE);
	}

	transferTypeText(): string {
		if (this.canTransferAndWriteoff()) {
			return 'Transfer Type';
		} else if (!this.showWriteOffOption) {
			return 'Transfer';
		}
		return 'Write-off';
	}

	prepareFormAndRequestWithItems(items: InvoiceItemResponse[]) {
		const formArrayItems = [];
		items.forEach((item) => {
			const formArrayGroup = this.formBuilder.group({paymentAmount: 0, invoiceItemId: item.id});
			formArrayItems.push(formArrayGroup);
			formArrayGroup.get('paymentAmount').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
				this.inputAmountChange(item.id);
			});
		});
		const transferItemsFormArray = this.formBuilder.array(formArrayItems);
		this.formGroup.addControl('paymentTransferItems', transferItemsFormArray);
	}

	inputAmountChange(invoiceItemId: number) {
		const index = this.getItemIndexById(invoiceItemId);
		const item = this.invoiceItems[index];
		const paymentAmount = this.getInvoiceItemValue('paymentAmount', invoiceItemId);
		const updatedItem = this.updateItem(item, paymentAmount, index);
		if (!InvoiceItemPricingUtil.checkItemEquality(updatedItem.balance, item.balance)) {
			this.updateInvoiceItem(index, updatedItem);
			this.updateTotals();
		}
	}

	private updateInvoiceItem(index, updatedItem) {
		this.invoiceItems[index] = updatedItem;
		this.invoiceItemsMap.set(updatedItem.id, updatedItem);
	}

	updateTotals() {
		this.itemPaymentTotal = Number(GridUtil.sumCurrencyItems(this.invoiceItems, 'paymentAmount'));
		const extPriceTotal = GridUtil.sumCurrencyItems(this.invoiceItems, 'extPrice');
		const totalAdjustments = GridUtil.sumCurrencyItems(this.invoiceItems, 'invoiceItem.adjustmentTotal');
		this.totalCurrentBalance = Number(Big(extPriceTotal).plus(totalAdjustments).minus(this.invoice.amountPaid || 0));
		this.itemTransfersTotal = this.useItemTransfersOnPayments
			? Number(GridUtil.sumCurrencyItems(this.invoiceItems, 'totalTransferAmount'))
			: Number(GridUtil.sumCurrencyItems(this.transfers, 'transferRequest.transferAmount'));

		let balanceAfterTransferAndPayment = Big(this.totalCurrentBalance);

		// Check if there are any item payments that are non 0 (negative payments can exist too)
		const hasItemPayment = !!this.invoiceItems.find(item => !Big(item.paymentAmount).eq(0));

		if (this.useItemTransfersOnPayments && hasItemPayment) {
			balanceAfterTransferAndPayment = balanceAfterTransferAndPayment.minus(this.itemPaymentTotal);
		} else {
			// only use the invoice payment from receive payments if there are no item payments
			balanceAfterTransferAndPayment = balanceAfterTransferAndPayment.minus(this.invoicePaymentAmount);
		}

		this.totalBalanceAfterTransferAndPayment = Number(balanceAfterTransferAndPayment.minus(this.itemTransfersTotal));

		// Calculates basics sums for the table footer
		this.paymentAmountSum = this.getSum('paymentAmount');
		this.adjustmentTotalSum = this.getSum('adjustmentTotal');
		this.paymentTotalAmountSum = this.getSum('paymentTotalAmount');
		this.balanceSum = this.getSum('balance');

		this.buildAggregateData();
	}

	updateItem(transferItem: TransferItem, paymentAmount: number, index?: number, setAutoPayBalance?: boolean): TransferItem {
		const newItem = {...transferItem};
		let totalTransferAmount = Big(0);

		if (!_isNil(index)) {
			this.transfers.forEach((val) => {
				const field = `transferAmount${val.id}`;
				const transferVal = this.getInvoiceItemControlByIndex(index, field)?.value || 0;
				newItem[field] = transferVal;
				totalTransferAmount = totalTransferAmount.plus(transferVal);
			});
		}

		newItem.totalDiscount = newItem.invoiceItem.discountTotal;
		newItem.paymentAmount = paymentAmount;
		newItem.paymentTotalAmount = Number(InvoiceItemPricingUtil.sumBalances([newItem.invoiceItem.amountPaid, newItem.paymentAmount]));
		newItem.totalTransferAmount = Number(totalTransferAmount);
		newItem.tax = Number(GridUtil.sumCurrencyItems(newItem.invoiceItemDetails.itemTaxes, 'taxAmount'));
		newItem.extPrice = Number(InvoiceItemPricingUtil.calculateExtPriceWithTaxes(
			newItem.invoiceItem.unitPrice,
			newItem.invoiceItem.quantity,
			newItem.invoiceItem.discountTotal,
			newItem.invoiceItemDetails.itemTaxes,
		));
		newItem.adjustmentTotal = Number(InvoiceItemPricingUtil.sumBalances([newItem.invoiceItem.adjustmentTotal, -newItem.totalTransferAmount]));
		newItem.balance = Number(InvoiceItemPricingUtil.sumBalances([newItem.extPrice, newItem.adjustmentTotal, -newItem.paymentTotalAmount]));

		if (setAutoPayBalance) {
			newItem.autoPayBalance = newItem.balance;
		}

		return newItem;
	}

	getInvoiceItemControl(field: string, id) {
		const index = this.getItemIndexById(id);
		return index >= 0 ? this.getInvoiceItemControlByIndex(index, field) : null;
	}

	private getInvoiceItemControlByIndex(index, field: string) {
		return this.formGroup.get('paymentTransferItems').get(index.toString()).get(field);
	}

	private getItemIndexById(id) {
		return _findIndex(this.invoiceItems, (item) => item.invoiceItem.id === id);
	}

	getItemByItem(id) {
		const itemIndex = this.getItemIndexById(id);
		return itemIndex >= 0 ? this.invoiceItems[this.getItemIndexById(id)] : null;
	}

	getInvoiceItemValue(field: string, id): number {
		return this.getInvoiceItemControl(field, id).value;
	}

	getPatientData() {
		const patientSearch = new PatientSearchByIdsRequest();
		patientSearch.patientIds = [this.invoice.patientId];
		if (this.invoice.patientId) {
			combineLatest([
				this.patientService.findPatientsByIds(patientSearch).pipe(map(items => items[0])),
				this.accountingService.findActiveInsurancesByPatientId(this.invoice.patientId),
				this.accountingService.findPatientPendingInvoicesForDropdown(this.invoice.patientId),
				this.patientService.getPatientAccount(this.invoice.patientId).pipe(map(account => account.credit)),
			]).subscribe(([patientInfo, personInsurance, patientPendingInvoices, patientCredit]) => {
				this.handlePatientData(patientInfo, personInsurance, patientPendingInvoices, patientCredit);
			});
		} else {
			this.handlePatientData(null, [], [], 0);
		}
	}

	private handlePatientData(patientInfo, personInsurance, patientPendingInvoices, patientCredit) {
		this.patientInfo = patientInfo;
		this.personInsuranceList = personInsurance;
		this.transferToInvoiceList = patientPendingInvoices;
		this.patientCredit = patientCredit;
		this.handleInsuranceOptionsResults();
		this.handlePendingPatientInvoices();
		this.initFormValues();
		this.buildFromExisting(this.modalConfig.data.transfers, this.modalConfig.data.payments);
		this.disableReadOnlyForm();
	}

	disableReadOnlyForm() {
		if (this.readOnly) {
			this.formGroup.disable();
		}
	}

	buildRequest() {
		this.request = new ReceivePaymentTransferInvoiceItemsRequest();
	}

	buildForm() {
		const validators =  [
			conditionallyRequiredValidator(
				'transferReferenceId',
				() => this.formGroup && this.formGroup.get('transferReferenceId').enabled,
				'transferReferenceIdRequired',
				'Transfer reason is required',
			),
			conditionallyRequiredValidator(
				'writeoffReferenceId',
				() => this.formGroup && this.formGroup.get('writeoffReferenceId').enabled,
				'writeoffReferenceIdRequired',
				'Write-off reason is required',
			),
			conditionallyRequiredValidator(
				'personInsuranceId',
				() => this.formGroup && this.formGroup.get('personInsuranceId').enabled,
				'personInsuranceIdRequired',
				'Insurance Policy is required',
			),
		];
		if (!this.useItemTransfersOnPayments) {
			validators.push(
				conditionallyRequiredValidator(
					'transferAmount',
					() => this.formGroup && this.formGroup.get('transferAmount').enabled,
					'transferAmountRequired',
					'Transfer Amount is required',
				),
			);
		}
		this.formGroup = this.gandalfFormBuilder.group(this.request, {
			validators,
		});
		this.formGroup.addControl('transferToNewOrExistingInvoice', new UntypedFormControl());

		this.handleFormState();
	}

	/**
	 * if patient does not have insurance options payerType 'insurance' options is removed from the view
	 */
	handleInsuranceOptionsResults() {
		// need to filter out the insurance options that is applied to the current invoice as the item couldn't be transferred to that insurance
		this.personInsuranceList = _filter(this.personInsuranceList, insurance => insurance.value !== this.invoice.personInsuranceId);
		if (this.personInsuranceList.length === 0) {
			this.removeTransferToInsuranceOption = true;
		} else {
			this.updatePersonInsuranceDropdown();
		}
	}

	updatePersonInsuranceDropdown() {
		if (EnumUtil.equals(this.formGroup.get('payerType').value, PayerType.INSURANCE)) {
			if (this.personInsuranceList?.length === 1) {
				this.formGroup.get('personInsuranceId').setValue(this.personInsuranceList[0].id);
			}
		}
	}

	/**
	 * if patient does not have existing pending patient invoices transferTo 'existing' option is removed from view.
	 */
	handlePendingPatientInvoices() {
		if (this.transferToInvoiceList.length === 0) {
			this.removeTransferToExistingInvoiceOption = true;
		}
	}

	/**
	 * Initialized form values and sets class members to be used in the view
	 */
	initFormValues() {
		// if invoice type is patient payerType should default to Insurance and the 'patient' option is removed from the view
		this.removeTransferToPatientOption = EnumUtil.equals(this.invoice.payerType, PayerType.PATIENT);
		if (this.removeTransferToPatientOption) {
			this.formGroup.get('payerType').setValue(PayerType.INSURANCE);
		} else {
			this.formGroup.get('payerType').setValue(PayerType.PATIENT);
		}

		if (this.useItemTransfersOnPayments) {
			// if Include All Items for Transfer Items practice preference is enabled then include all items in transfer should be checked by default
			if (this.securityManager.preferenceValueIsOn(PreferenceName.ACCOUNTING_INVOICE_TRANSFER_ITEMS_DEFAULT_INCLUDE_ALL_ITEMS.value, 'false')) {
				this.formGroup.get('includeAllItems').setValue(true);
			} else {
				this.formGroup.get('includeAllItems').setValue(false);
			}
		} else {
			this.formGroup.get('transferAmount').setValue(0);
			this.formGroup.get('includeAllItems').disable();
		}

		// if patient does not have existing pending patient invoices patient transfer to 'new' invoice is selected by default
		// otherwise 'new' invoice is selected by default and the first invoice is the list is selected
		if (this.removeTransferToExistingInvoiceOption) {
			this.formGroup.get('transferToNewOrExistingInvoice').setValue(this.TRANSFER_TO_NEW_INVOICE_LABEL);
		} else {
			this.formGroup.get('transferToNewOrExistingInvoice').setValue(this.TRANSFER_TO_EXISTING_INVOICE_LABEL);
			this.formGroup.get('transferToExistingInvoiceId').setValue(this.transferToInvoiceList[0].value);
		}

		// Transfer To section should not appear if
		//  - the invoice has an Finance Charge plan item
		// 	- OR invoice is a patient invoices (removes transfer to Patient option)
		// 		- AND patient doesn't have insurance options available (removes transfer to insurance options)
		//  - OR this is a guest invoice
		this.hasFinanceCharge = !_isNil(_find(this.invoice.items, item => item.status === InvoiceItemStatus.ACTIVE && item.type === InvoiceItemType.FINANCE_CHARGE));
		this.hideTransferToSection = this.getHideTransferToSection(
			this.hasFinanceCharge,
			this.removeTransferToPatientOption,
			this.removeTransferToInsuranceOption,
			this.invoice.payerType,
		);
		// If transfer to is removed, transferType should default to writeoff
		if (this.hideTransferToSection) {
			this.formGroup.get('transferType').setValue(this.transferType.WRITEOFF);
		} else {
			this.formGroup.get('transferType').setValue(this.transferType.TRANSFER);
		}
	}

	getHideTransferToSection(hasFinanceCharges: boolean, removeTransferToPatient: boolean, removeTransferToInsurance: boolean, payerType: PayerType) {
		return hasFinanceCharges
			|| (removeTransferToPatient && removeTransferToInsurance)
			|| EnumUtil.equals(payerType, PayerType.ANONYMOUS);
	}

	/**
	 * Sets up on change events for various form fields
	 */
	/* istanbul ignore next */
	handleFormState() {
		// if 'transfer' is selected writeoff reason dropdown should be disabled
		// if 'writeoff' is selected transfer reason dropdown and person insurance dropdown should be disabled
		this.formGroup.get('transferType').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('transferReferenceId').enable();
				this.formGroup.get('writeoffReferenceId').enable();
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferReferenceId'),
					EnumUtil.equals(val, this.transferType.WRITEOFF),
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('writeoffReferenceId'),
					EnumUtil.equals(val, this.transferType.TRANSFER),
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('personInsuranceId'),
					EnumUtil.equals(val, this.transferType.WRITEOFF) ||
					(EnumUtil.equals(this.formGroup.get('payerType').value, PayerType.PATIENT)),
				);
			});

		// if payerType 'patient' is selected insurance dropdown should be disabled
		this.formGroup.get('payerType').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('personInsuranceId').enable();
				FormUtilsService.disabledWhen(
					this.formGroup.get('personInsuranceId'),
					EnumUtil.equals(val, PayerType.PATIENT) || this.formGroup.get('transferType').value === this.transferType.WRITEOFF,
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferToNewOrExistingInvoice'),
					EnumUtil.equals(val, PayerType.INSURANCE),
				);
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferToExistingInvoiceId'),
					EnumUtil.equals(val, PayerType.INSURANCE),
				);

				if (EnumUtil.equals(val, PayerType.INSURANCE)) {
					this.formGroup.get('includeAllItems').setValue(true);
				}
			});

		// if transfer to new invoice is selected existing invoice dropdown should be disabled
		this.formGroup.get('transferToNewOrExistingInvoice').valueChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(val => {
				this.formGroup.get('transferToExistingInvoiceId').enable();
				FormUtilsService.disabledWhen(
					this.formGroup.get('transferToExistingInvoiceId'),
					val === this.TRANSFER_TO_NEW_INVOICE_LABEL,
				);
			});
	}

	canTransferOrWriteoff() {
		return !this.hideTransferToSection || this.showWriteOffOption;
	}

	canTransferAndWriteoff() {
		return !this.hideTransferToSection && this.showWriteOffOption;
	}

	/* istanbul ignore next */
	submitForm(event) {
		this.templateForm.onSubmit(event);
	}

	addTransfer(event, existingTransfer?: Transfer, refresh = true) {
		let transfer = existingTransfer;
		// If this is a new transfer, use the form to build the transfer
		if (!transfer) {
			this.submitForm(event);
			if (this.formInvalid()) {
				return;
			}
			const transferItemRequest = {...this.formGroup.getRawValue()} as ReceivePaymentTransferInvoiceItemsRequest;
			if (!this.showIncludeAllItems()) {
				transferItemRequest.includeAllItems = false;
			}
			const transferId = ++this.lastTransferId;
			transfer = {
				transferRequest: transferItemRequest,
				id: transferId,
			};
		}

		// Remove transfer to existing id if it should transfer to new
		transfer.transferRequest.transferToExistingInvoiceId = transfer.transferRequest['transferToNewOrExistingInvoice'] !== this.TRANSFER_TO_NEW_INVOICE_LABEL
			? transfer.transferRequest.transferToExistingInvoiceId
			: null;

		this.transfers.push(transfer);
		const controlName = `transferAmount${transfer.id}`;
		if (this.useItemTransfersOnPayments) {
			// Add new column to static columns
			this.staticColumns.push(controlName);
			// Add new payment control
			const paymentTransferItemsArray = this.formGroup.get('paymentTransferItems') as UntypedFormArray;
			paymentTransferItemsArray.controls.forEach((group: UntypedFormGroup, index) => {
				const invoiceItemId = group.getRawValue().invoiceItemId;
				const transferItem = transfer.transferRequest.transferItems.find(searchItem => searchItem.invoiceItemId === invoiceItemId);
				const transferAmount = transferItem ? transferItem.transferAmount : 0;
				group.addControl(controlName, new UntypedFormControl(transferAmount));
				const newControl = group.get(controlName);
				// Disable payment control if the type doesn't allow transfer payments and this is a transfer
				if (!this.canPaymentTransferItem(this.invoiceItems[index].invoiceItem) && transfer.transferRequest.transferType === TransferType.TRANSFER) {
					newControl.disable();
				} else {
					newControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
						this.inputAmountChange(this.invoiceItems[index].invoiceItem.id);
					});
				}
			});
		}

		// Reset the transfer form
		this.templateForm.resetForm({
			...this.formGroup.getRawValue(),
			transferReferenceId: undefined,
			writeoffReferenceId: undefined,
		});
		if (this.personInsuranceList?.length === 1) {
			this.formGroup.get('personInsuranceId').setValue(this.personInsuranceList[0].id);
		}

		// Reset the form with the initial values
		this.initFormValues();

		// Rebuild the invoice item payments and transfers syncfusion table
		if (!this.isAgGridFeatureFlagOn) {
			this.toggleTable();
			if (refresh) {
				this.transferWriteOffSyncfusionGrid.refresh();
			}
		}

		if (!this.useItemTransfersOnPayments) {
			this.updateTotals();
		}

		// Update columns to include the new transfer
		if (this.isAgGridFeatureFlagOn) {
			this.mapTransferIds();
			this.buildPaymentItemColumns();
			this.buildTransferItemColumns();
			this.buildAggregateData();
			GridUtil.setDynamicColumns(this.paymentItemGrid, this.paymentItemColumns);
			GridUtil.setDynamicColumns(this.transferWriteOffGrid, this.transferItemColumns);

			this.transferWriteOffGrid.api.setRowData(this.transfers);
		}

		return transfer;
	}

	/**
	 * This causes the table to destroy and recreate with the new columns
	 */
	toggleTable() {
		this.showTable = false;
		setTimeout(() => {
			this.showTable = true;
		});
	}

	formInvalid() {
		return this.formGroup.invalid;
	}

	removeTransfer(index, transfer: Transfer) {
		const controlName = `transferAmount${transfer.id}`;
		this.transfers.splice(Number(index), 1);
		const transferItemsFormArray = this.formGroup.get('paymentTransferItems') as UntypedFormArray;
		transferItemsFormArray.controls.forEach((group: UntypedFormGroup, formControlIndex: number) => {
			group.removeControl(controlName);
			this.inputAmountChange(this.invoiceItems[formControlIndex].invoiceItem.id);
		});

		this.staticColumns = this.staticColumns.filter((item) => item !== controlName);

		if (!this.isAgGridFeatureFlagOn) {
			this.toggleTable();
			this.transferWriteOffSyncfusionGrid.refresh();
		} else {
			this.buildPaymentItemColumns();
			this.buildTransferItemColumns();
			this.buildAggregateData();
			GridUtil.setDynamicColumns(this.paymentItemGrid, this.paymentItemColumns);
			GridUtil.setDynamicColumns(this.transferWriteOffGrid, this.transferItemColumns);

			this.transferWriteOffGrid.api.setRowData(this.transfers);
		}

		if (!this.useItemTransfersOnPayments) {
			this.updateTotals();
		}
	}

	getReason(data: ReceivePaymentTransferInvoiceItemsRequest) {
		if (EnumUtil.equals(data.transferType, TransferType.WRITEOFF)) {
			return this.allAdjustmentReasons.find(reason => reason.value === data.writeoffReferenceId).label;
		} else if (EnumUtil.equals(data.transferType, TransferType.TRANSFER)) {
			return this.allAdjustmentReasons.find(reason => reason.value === data.transferReferenceId).label;
		}
	}

	getTransferTo(data: ReceivePaymentTransferInvoiceItemsRequest) {
		if (EnumUtil.equals(data.transferType, TransferType.WRITEOFF)) {
			return '';
		} else if (EnumUtil.equals(data.transferType, TransferType.TRANSFER)) {
			if (EnumUtil.equals(data.payerType, PayerType.PATIENT)) {
				if (data.transferToExistingInvoiceId) {
					return `Patient (invoice #${data.transferToExistingInvoiceId})`;
				} else {
					return 'Patient (new invoice)';
				}

			} else if (EnumUtil.equals(data.payerType, PayerType.INSURANCE)) {
				const insurance = this.personInsuranceList.find(ins => ins.value === data.personInsuranceId);
				return `${insurance.label} (${insurance.priority.value} ${insurance.type.value})`;
			}
		}
	}

	getTypeLabel(data: ReceivePaymentTransferInvoiceItemsRequest) {
		if (EnumUtil.equals(data.transferType, TransferType.WRITEOFF)) {
			return 'Write-off';
		} else if (EnumUtil.equals(data.transferType, TransferType.TRANSFER)) {
			return 'Transfer';
		}
	}

	autoPay() {
		this.invoiceItems.forEach((item, index) => {
			const autoPayBalance = Number(InvoiceItemPricingUtil.sumBalances([item.autoPayBalance, -item.totalTransferAmount]));
			this.getInvoiceItemControlByIndex(index, 'paymentAmount').setValue(autoPayBalance);
		});
		this.closeModal(true);
	}

	showIncludeAllItems() {
		return EnumUtil.equals(this.formGroup.get('transferType').value, TransferType.TRANSFER);
	}

	getTransferNumber(data) {
		return Number(this.getTransferIndexById(data.id)) + 1;
	}

	getTransferIndexById(id: number) {
		return _findIndex(this.transfers, transfer => transfer.id === id);
	}

	buildResults(): ReceivePaymentsTransferItemsModalResponse {
		const transferItemRequests: ReceivePaymentTransferInvoiceItemsRequest[] = [];
		const paymentItemRequests: InvoiceItemPaymentRequest[] = [];
		this.transfers.forEach(transfer => {
			const transferRequest = transfer.transferRequest;

			if (EnumUtil.equals(transferRequest.transferType, TransferType.TRANSFER)) {
				if (EnumUtil.equals(transferRequest.payerType, PayerType.INSURANCE)) {
					const insurance = this.personInsuranceList.find(insuranceItem => insuranceItem.value === transferRequest.personInsuranceId);
					transferRequest.payerEntityId = insurance.practiceInsuranceCompanyId;
				} else if (EnumUtil.equals(transferRequest.payerType, PayerType.PATIENT)) {
					transferRequest.payerEntityId = this.patientInfo.personId;
				}
			}
			if (this.useItemTransfersOnPayments) {
				transferRequest.transferItems = [];
				this.invoiceItems.forEach((invoiceItem, invoiceItemIndex) => {
					const transferItemRequest = new TransferItemRequest();
					transferItemRequest.invoiceItemId = invoiceItem.invoiceItem.id;
					transferItemRequest.transferAmount = this.getInvoiceItemControlByIndex(invoiceItemIndex, `transferAmount${transfer.id}`).value;
					transferRequest.transferItems.push(transferItemRequest);
				});
			}
			// if the control doesn't render because item transfers and payments is off, this needs to be set to false
			if (_isNil(transferRequest.includeAllItems)) {
				transferRequest.includeAllItems = false;
			}
			transferItemRequests.push(transferRequest);
		});
		(this.formGroup.get('paymentTransferItems') as UntypedFormArray).value.forEach((invoiceItemPayment) => {
			const paymentItem = new InvoiceItemPaymentRequest();
			paymentItem.invoiceItemId = invoiceItemPayment.invoiceItemId;
			paymentItem.paymentAmount = invoiceItemPayment.paymentAmount;
			paymentItemRequests.push(paymentItem);
		});
		return {
			transferItemRequests,
			paymentItemRequests,
			itemsPaidTotal: this.itemPaymentTotal,
			transferTotal: this.itemTransfersTotal,
		};
	}

	filterAdjustments(itemAdjustments: InvoiceItemAdjustmentResponse[], isDiscount: boolean): InvoiceItemAdjustmentResponse[] {
		return _filter(itemAdjustments, adjustment =>
			isDiscount === EnumUtil.equals(adjustment.type, InvoiceItemAdjustmentType.DISCOUNT)
			&& EnumUtil.equals(adjustment.status, InvoiceItemAdjustmentStatus.ACTIVE));
	}

	sortBy<T>(collection: Array<T>, properties: any | Array<any>): Array<T> {
		return SortingService.sortBy(collection, properties);
	}

	setTooltip(event: QueryCellInfoEventArgs) {
		let content: ElementRef;
		let isDiscount = false;
		switch (event.column.field) {
			case 'adjustmentTotal':
				if (_isEmpty(this.filterAdjustments(event.data['invoiceItemDetails'].itemAdjustments, false))) {
					return;
				}
				content = this.adjustmentSyncfusionTooltip;
				isDiscount = false;
				break;
			case 'totalDiscount':
				if (_isEmpty(this.filterAdjustments(event.data['invoiceItemDetails'].itemAdjustments, true))) {
					return;
				}
				content = this.adjustmentSyncfusionTooltip;
				isDiscount = true;
				break;
			case 'tax':
				if (_isEmpty(event.data['invoiceItemDetails'].itemTaxes)) {
					return;
				}
				content = this.taxSyncfusionTooltip;
				break;
			default:
				return;
		}
		const cellElement = (event.cell as unknown) as HTMLElement;
		this.tooltipService.buildTooltip(content.nativeElement, cellElement, (renderEvent) => {
			this.beforeRender(renderEvent, isDiscount);
			setTimeout(() => {
				cellElement['_tippy'].setContent((content.nativeElement as HTMLElement).innerHTML);
			});
		});
	}

	beforeRender(event: TooltipEventArgs, isDiscount: boolean) {
		const row = this.paymentItemSyncfusionGrid.getRowInfo(event.target);
		if (row) {
			this.tooltipDiscount = isDiscount;
			this.tooltipData = row.rowData as TransferItem;
		}
	}

	buildFromExisting(transferItemRequests: ReceivePaymentTransferInvoiceItemsRequest[], paymentItemRequests: InvoiceItemPaymentRequest[]) {
		if (transferItemRequests) {
			transferItemRequests.forEach(transfer => {
				this.addTransfer(null, {transferRequest: transfer, id: ++this.lastTransferId}, false);
			});

			if (!this.isAgGridFeatureFlagOn) {
				this.transferWriteOffSyncfusionGrid.refresh();
			}
		}
		if (this.showPaymentColumn && paymentItemRequests) {
			paymentItemRequests.forEach(paymentItem => {
				this.getInvoiceItemControl('paymentAmount', paymentItem.invoiceItemId).setValue(paymentItem.paymentAmount);
				this.inputAmountChange(paymentItem.invoiceItemId);
			});
		}
	}

	getSum(fieldName: string) {
		return Number(GridUtil.sumCurrencyItems(this.invoiceItems, fieldName));
	}

	reinitInvoiceItemMap() {
		this.invoiceItemsMap = _reduce(this.invoiceItems, (newMap, value) => {
			const key = value['id'];
			newMap.set(key, value);
			return newMap;
		}, new Map());
	}

	retainInvoiceDropdownInvoiceId() {
		// addresses issue where value for invoiceDropdown would display as having an invoice selected but would actually be null after being ngIf'ed
		setTimeout(() => {
			this.formGroup.get('transferToExistingInvoiceId').setValue(this.invoiceDropdown.value);
		});
	}

	isCellSelected(id: number, field: string) {
		if (!_isNil(id) && !_isNil(this.focusedRowId) && !_isNil(this.focusedColumnKey)) {
			return this.focusedRowId === id && this.focusedColumnKey === field;
		}

		return false;
	}

	onInputBlur() {
		this.focusedRowId = null;
		this.focusedColumnKey = null;
	}

	onTabKeydown(event: KeyboardEvent) {
		if (this.isAgGridFeatureFlagOn) {
			event.preventDefault();
			this.paymentItemGrid.api.ensureIndexVisible(0);

			// sets focus into the first row payment column
			const firstRow = this.paymentItemGrid.api.getDisplayedRowAtIndex(0);
			this.focusedRowId = !_isNil(firstRow) ? firstRow.data.invoiceItem.id : null;
			this.focusedColumnKey = 'paymentAmount';
		}
	}

	onCellClick(event: CellMouseDownEvent) {
		const rowNode = this.paymentItemGrid.api.getDisplayedRowAtIndex(event.rowIndex);
		const isEditable = this.isCellEditable(event.column.getColId(), rowNode);

		this.cancelFocusEvent = !isEditable;
	}

	@LogMethodTime('onCellFocus')
	onCellFocus(event: CellFocusedEvent) {
		const column = event?.column as Column;
		if (!column?.getColId() || this.cancelFocusEvent) {
			this.cancelFocusEvent = false;
			return;
		}

		const focusedCell = {
			rowIndex: event.rowIndex,
			colId: column.getColId(),
		};

		if (
			focusedCell.rowIndex === this.lastFocusedCell?.rowIndex &&
			focusedCell.colId === this.lastFocusedCell?.colId
		) {
			if (event.rowPinned !== 'bottom') {
				this.lastFocusedCell = {
					rowIndex: event.rowIndex,
					colId: column.getColId(),
				};
				this.paymentItemGrid.api.stopEditing();
			} else {
				this.lastFocusedCell = {
					rowIndex: null,
					colId: null,
				};
			}

			return;
		}

		const rowNode = this.paymentItemGrid.api.getDisplayedRowAtIndex(event.rowIndex);

		if (column.isSuppressNavigable(rowNode)) {
			this.lastFocusedCell = {
				rowIndex: event.rowIndex,
				colId: column.getColId(),
			};
			this.paymentItemGridApi.tabToNextCell();
			return;
		}

		// pinned rows are aggregates and shouldn't be editable
		const isRowPinned = this.isAggregateRow(rowNode.data);

		const isEditable = this.isCellEditable(column.getColId(), rowNode);

		this.lastFocusedCell = {
			rowIndex: event.rowIndex,
			colId: column.getColId(),
		};

		if (!isRowPinned && isEditable) {
			this.paymentItemGrid.api.ensureIndexVisible(event.rowIndex);
			this.focusedRowId = rowNode.data.invoiceItem.id;
			this.focusedColumnKey = column.getColId();
		} else {
			this.paymentItemGridApi.tabToNextCell();
		}
	}

	@LogMethodTime('isCellEditable')
	isCellEditable(colKey: string, rowNode: IRowNode) {
		if (colKey === 'paymentAmount') {
			return this.isPaymentAmountCellEditable(rowNode, colKey);
		}

		if (colKey.indexOf('transfer') !== -1) {
			return this.isTransferAmountCellEditable(rowNode, colKey);
		}
	}

	getPaymentAmountFormControl = (rowNode: IRowNode, _colId?: string) => this.getInvoiceItemControl('paymentAmount', rowNode?.data?.invoiceItem?.id);

	isPaymentAmountCellEditable = (rowNode: IRowNode, colId?: string) => !this.isAggregateRow(rowNode?.data) ? this.getPaymentAmountFormControl(rowNode, colId)?.enabled : false;

	getTransferAmountFormControl = (rowNode: IRowNode, colId?: string) => {
		if (_isNil(rowNode?.data?.transferIds)) {
			return null;
		}
		return this.getInvoiceItemControl(colId, rowNode?.data?.invoiceItem?.id);
	};

	isTransferAmountCellEditable = (rowNode: IRowNode, colId?: string) => !this.isAggregateRow(rowNode.data) ? this.getTransferAmountFormControl(rowNode, colId)?.enabled : false;

	getTransferAmountValue = (colId) => {
		const aggregateValue = this.getSum(colId);
		return this.cellFormattingUtils.currency(aggregateValue);
	};

}
