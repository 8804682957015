
// @ts-ignore
import { AddAdhocInvoiceItemRequest } from './add-adhoc-invoice-item-request';

// @ts-ignore
import { AddBillableItemsBillingRequest } from './add-billable-items-billing-request';

// @ts-ignore
import { AddDiscountRequest } from './add-discount-request';

// @ts-ignore
import { AddInvoiceItemRequest } from './add-invoice-item-request';

// @ts-ignore
import { AdditionalClaimInformationRequest } from './additional-claim-information-request';

// @ts-ignore
import { AdditionalClaimInformationResponse } from './additional-claim-information-response';

// @ts-ignore
import { AdditionalDiagnosisResponse } from './additional-diagnosis-response';

// @ts-ignore
import { ApproveInvoiceRequest } from './approve-invoice-request';

// @ts-ignore
import { ChangeEncounterProviderRequest } from './change-encounter-provider-request';

// @ts-ignore
import { ChangeInvoiceAppointmentRequest } from './change-invoice-appointment-request';

// @ts-ignore
import { CollectionsInvoiceTransferSplitRequest } from './collections-invoice-transfer-split-request';

// @ts-ignore
import { ContinuityOfCareImportResultResponse } from './continuity-of-care-import-result-response';

// @ts-ignore
import { CreateInvoiceBillingRequest } from './create-invoice-billing-request';

// @ts-ignore
import { CreateInvoiceRequest } from './create-invoice-request';

// @ts-ignore
import { CreatePatientCreditRequest } from './create-patient-credit-request';

// @ts-ignore
import { CreatePatientDocumentRequest } from './create-patient-document-request';

// @ts-ignore
import { CreatePaymentGroupRequest } from './create-payment-group-request';

// @ts-ignore
import { CreatePaymentRequest } from './create-payment-request';

// @ts-ignore
import { CreateRefundPatientRequest } from './create-refund-patient-request';

// @ts-ignore
import { DiagnosisResponse } from './diagnosis-response';

// @ts-ignore
import { DiscountItemRequest } from './discount-item-request';

// @ts-ignore
import { DiscountItemsRequest } from './discount-items-request';

// @ts-ignore
import { FamilyCreditRequest } from './family-credit-request';

// @ts-ignore
import { FamilyRefundRequest } from './family-refund-request';

// @ts-ignore
import { FindActiveLocationProductSummariesRequest } from './find-active-location-product-summaries-request';

// @ts-ignore
import { FindPayersForApprovedOutstandingInvoicesRequest } from './find-payers-for-approved-outstanding-invoices-request';

// @ts-ignore
import { GrantCreditsRequest } from './grant-credits-request';

// @ts-ignore
import { HeuristicPatientSearchRequest } from './heuristic-patient-search-request';

// @ts-ignore
import { ImportResultResponse } from './import-result-response';

// @ts-ignore
import { InfoButtonResponse } from './info-button-response';

// @ts-ignore
import { InsuranceInvoiceTransferSplitRequest } from './insurance-invoice-transfer-split-request';

// @ts-ignore
import { InsuranceRefundRequest } from './insurance-refund-request';

// @ts-ignore
import { InvoiceDetailsItemResponse } from './invoice-details-item-response';

// @ts-ignore
import { InvoiceDiagnosesRequest } from './invoice-diagnoses-request';

// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './invoice-item-adjustment-response';

// @ts-ignore
import { InvoiceItemDetailResponse } from './invoice-item-detail-response';

// @ts-ignore
import { InvoiceItemPaymentRequest } from './invoice-item-payment-request';

// @ts-ignore
import { InvoiceItemPaymentResponse } from './invoice-item-payment-response';

// @ts-ignore
import { InvoiceItemResponse } from './invoice-item-response';

// @ts-ignore
import { InvoicePayerResponse } from './invoice-payer-response';

// @ts-ignore
import { InvoiceProviderRequest } from './invoice-provider-request';

// @ts-ignore
import { InvoiceResponse } from './invoice-response';

// @ts-ignore
import { InvoiceSearchRequest } from './invoice-search-request';

// @ts-ignore
import { InvoiceSummaryResponse } from './invoice-summary-response';

// @ts-ignore
import { InvoiceTransferRequest } from './invoice-transfer-request';

// @ts-ignore
import { InvoiceTransferResponse } from './invoice-transfer-response';

// @ts-ignore
import { InvoicesForPayerRequest } from './invoices-for-payer-request';

// @ts-ignore
import { InvoicesUpdatedResponse } from './invoices-updated-response';

// @ts-ignore
import { LegacyAddressResponse } from './legacy-address-response';

// @ts-ignore
import { LegacyDrugAllergyResponse } from './legacy-drug-allergy-response';

// @ts-ignore
import { LegacyMedicationPrescriptionResponse } from './legacy-medication-prescription-response';

// @ts-ignore
import { LegacyPatientInsuranceAccountSummaryResponse } from './legacy-patient-insurance-account-summary-response';

// @ts-ignore
import { LegacyPatientNameResponse } from './legacy-patient-name-response';

// @ts-ignore
import { LegacyPatientSearchResponse } from './legacy-patient-search-response';

// @ts-ignore
import { LegacyPersonDiagnosisResponse } from './legacy-person-diagnosis-response';

// @ts-ignore
import { LegacyPracticeDiagnosisResponse } from './legacy-practice-diagnosis-response';

// @ts-ignore
import { LegacyProviderResponse } from './legacy-provider-response';

// @ts-ignore
import { LegacyReferenceDataResponse } from './legacy-reference-data-response';

// @ts-ignore
import { LegacyServiceSummaryResponse } from './legacy-service-summary-response';

// @ts-ignore
import { LocationProductItemSummaryResponse } from './location-product-item-summary-response';

// @ts-ignore
import { LocationProductSummaryResponse } from './location-product-summary-response';

// @ts-ignore
import { ManualProcessPaymentRequest } from './manual-process-payment-request';

// @ts-ignore
import { ModifierResponse } from './modifier-response';

// @ts-ignore
import { NewGuestInvoiceRequest } from './new-guest-invoice-request';

// @ts-ignore
import { ParseExternalPatientDataFileRequest } from './parse-external-patient-data-file-request';

// @ts-ignore
import { PatientCreditRequest } from './patient-credit-request';

// @ts-ignore
import { PatientDocumentSummaryResponse } from './patient-document-summary-response';

// @ts-ignore
import { PatientFileDigestResponse } from './patient-file-digest-response';

// @ts-ignore
import { PatientInvoiceTransferSplitRequest } from './patient-invoice-transfer-split-request';

// @ts-ignore
import { PatientPaymentResponse } from './patient-payment-response';

// @ts-ignore
import { PatientPortionRequest } from './patient-portion-request';

// @ts-ignore
import { PatientRefundRequest } from './patient-refund-request';

// @ts-ignore
import { PaymentGroupListResponse } from './payment-group-list-response';

// @ts-ignore
import { PaymentGroupResponse } from './payment-group-response';

// @ts-ignore
import { PaymentHistoryResponse } from './payment-history-response';

// @ts-ignore
import { PaymentResponse } from './payment-response';

// @ts-ignore
import { PrintStatementsRequest } from './print-statements-request';

// @ts-ignore
import { QueryResultFieldResponse } from './query-result-field-response';

// @ts-ignore
import { QueryResultResponse } from './query-result-response';

// @ts-ignore
import { QueryResultValueResponse } from './query-result-value-response';

// @ts-ignore
import { QueryResultsResponse } from './query-results-response';

// @ts-ignore
import { ReceivePaymentTransferInvoiceItemsRequest } from './receive-payment-transfer-invoice-items-request';

// @ts-ignore
import { ReceivePaymentTransferInvoiceItemsResponse } from './receive-payment-transfer-invoice-items-response';

// @ts-ignore
import { RemoveInvoiceItemRequest } from './remove-invoice-item-request';

// @ts-ignore
import { RunQueryRequest } from './run-query-request';

// @ts-ignore
import { SearchPaymentGroupRequest } from './search-payment-group-request';

// @ts-ignore
import { SendStatementsRequest } from './send-statements-request';

// @ts-ignore
import { SignEncounterRequest } from './sign-encounter-request';

// @ts-ignore
import { SignEncounterResponse } from './sign-encounter-response';

// @ts-ignore
import { SignEncounterSsoRequest } from './sign-encounter-sso-request';

// @ts-ignore
import { SplitPaymentRequest } from './split-payment-request';

// @ts-ignore
import { StatementInvoiceResponse } from './statement-invoice-response';

// @ts-ignore
import { StatementPayerResponse } from './statement-payer-response';

// @ts-ignore
import { TaxDetailResponse } from './tax-detail-response';

// @ts-ignore
import { TransferInvoiceBalanceToPatientRequest } from './transfer-invoice-balance-to-patient-request';

// @ts-ignore
import { TransferInvoiceItemsRequest } from './transfer-invoice-items-request';

// @ts-ignore
import { TransferItemRequest } from './transfer-item-request';

// @ts-ignore
import { TransferItemResponse } from './transfer-item-response';

// @ts-ignore
import { TransferPatientCreditRequest } from './transfer-patient-credit-request';

// @ts-ignore
import { UnsignEncounterRequest } from './unsign-encounter-request';

// @ts-ignore
import { UnsignEncounterSsoRequest } from './unsign-encounter-sso-request';

// @ts-ignore
import { UpdateInvoiceDateRequest } from './update-invoice-date-request';

// @ts-ignore
import { UpdateInvoiceEncounterRequest } from './update-invoice-encounter-request';

// @ts-ignore
import { UpdateInvoiceFinanceChargePlanRequest } from './update-invoice-finance-charge-plan-request';

// @ts-ignore
import { UpdateInvoiceItemDetailsRequest } from './update-invoice-item-details-request';

// @ts-ignore
import { UpdateInvoiceItemQuantityRequest } from './update-invoice-item-quantity-request';

// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonListRequest } from './update-invoice-item-responsible-person-list-request';

// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonRequest } from './update-invoice-item-responsible-person-request';

// @ts-ignore
import { UpdateInvoiceLocationRequest } from './update-invoice-location-request';

// @ts-ignore
import { UpdatePaymentGroupRequest } from './update-payment-group-request';

// @ts-ignore
import { VoidInsuranceCreditRequest } from './void-insurance-credit-request';

// @ts-ignore
import { VoidInsuranceRefundRequest } from './void-insurance-refund-request';

// @ts-ignore
import { VoidInvoiceRequest } from './void-invoice-request';

// @ts-ignore
import { VoidPatientCreditRequest } from './void-patient-credit-request';

// @ts-ignore
import { VoidPatientRefundRequest } from './void-patient-refund-request';

// @ts-ignore
import { VoidPaymentRequest } from './void-payment-request';

// @ts-ignore
import { WriteoffInvoiceTransferSplitRequest } from './writeoff-invoice-transfer-split-request';


/* istanbul ignore next */
export const MODEL_STORE: any = {

	AddAdhocInvoiceItemRequest,

	AddBillableItemsBillingRequest,

	AddDiscountRequest,

	AddInvoiceItemRequest,

	AdditionalClaimInformationRequest,

	AdditionalClaimInformationResponse,

	AdditionalDiagnosisResponse,

	ApproveInvoiceRequest,

	ChangeEncounterProviderRequest,

	ChangeInvoiceAppointmentRequest,

	CollectionsInvoiceTransferSplitRequest,

	ContinuityOfCareImportResultResponse,

	CreateInvoiceBillingRequest,

	CreateInvoiceRequest,

	CreatePatientCreditRequest,

	CreatePatientDocumentRequest,

	CreatePaymentGroupRequest,

	CreatePaymentRequest,

	CreateRefundPatientRequest,

	DiagnosisResponse,

	DiscountItemRequest,

	DiscountItemsRequest,

	FamilyCreditRequest,

	FamilyRefundRequest,

	FindActiveLocationProductSummariesRequest,

	FindPayersForApprovedOutstandingInvoicesRequest,

	GrantCreditsRequest,

	HeuristicPatientSearchRequest,

	ImportResultResponse,

	InfoButtonResponse,

	InsuranceInvoiceTransferSplitRequest,

	InsuranceRefundRequest,

	InvoiceDetailsItemResponse,

	InvoiceDiagnosesRequest,

	InvoiceItemAdjustmentResponse,

	InvoiceItemDetailResponse,

	InvoiceItemPaymentRequest,

	InvoiceItemPaymentResponse,

	InvoiceItemResponse,

	InvoicePayerResponse,

	InvoiceProviderRequest,

	InvoiceResponse,

	InvoiceSearchRequest,

	InvoiceSummaryResponse,

	InvoiceTransferRequest,

	InvoiceTransferResponse,

	InvoicesForPayerRequest,

	InvoicesUpdatedResponse,

	LegacyAddressResponse,

	LegacyDrugAllergyResponse,

	LegacyMedicationPrescriptionResponse,

	LegacyPatientInsuranceAccountSummaryResponse,

	LegacyPatientNameResponse,

	LegacyPatientSearchResponse,

	LegacyPersonDiagnosisResponse,

	LegacyPracticeDiagnosisResponse,

	LegacyProviderResponse,

	LegacyReferenceDataResponse,

	LegacyServiceSummaryResponse,

	LocationProductItemSummaryResponse,

	LocationProductSummaryResponse,

	ManualProcessPaymentRequest,

	ModifierResponse,

	NewGuestInvoiceRequest,

	ParseExternalPatientDataFileRequest,

	PatientCreditRequest,

	PatientDocumentSummaryResponse,

	PatientFileDigestResponse,

	PatientInvoiceTransferSplitRequest,

	PatientPaymentResponse,

	PatientPortionRequest,

	PatientRefundRequest,

	PaymentGroupListResponse,

	PaymentGroupResponse,

	PaymentHistoryResponse,

	PaymentResponse,

	PrintStatementsRequest,

	QueryResultFieldResponse,

	QueryResultResponse,

	QueryResultValueResponse,

	QueryResultsResponse,

	ReceivePaymentTransferInvoiceItemsRequest,

	ReceivePaymentTransferInvoiceItemsResponse,

	RemoveInvoiceItemRequest,

	RunQueryRequest,

	SearchPaymentGroupRequest,

	SendStatementsRequest,

	SignEncounterRequest,

	SignEncounterResponse,

	SignEncounterSsoRequest,

	SplitPaymentRequest,

	StatementInvoiceResponse,

	StatementPayerResponse,

	TaxDetailResponse,

	TransferInvoiceBalanceToPatientRequest,

	TransferInvoiceItemsRequest,

	TransferItemRequest,

	TransferItemResponse,

	TransferPatientCreditRequest,

	UnsignEncounterRequest,

	UnsignEncounterSsoRequest,

	UpdateInvoiceDateRequest,

	UpdateInvoiceEncounterRequest,

	UpdateInvoiceFinanceChargePlanRequest,

	UpdateInvoiceItemDetailsRequest,

	UpdateInvoiceItemQuantityRequest,

	UpdateInvoiceItemResponsiblePersonListRequest,

	UpdateInvoiceItemResponsiblePersonRequest,

	UpdateInvoiceLocationRequest,

	UpdatePaymentGroupRequest,

	VoidInsuranceCreditRequest,

	VoidInsuranceRefundRequest,

	VoidInvoiceRequest,

	VoidPatientCreditRequest,

	VoidPatientRefundRequest,

	VoidPaymentRequest,

	WriteoffInvoiceTransferSplitRequest,

};
