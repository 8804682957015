// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.PatientPortionRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientPortionRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	coInsuranceAmount: number;

	@GandalfValidator({ notNull: { message: 'Co Insurance Selection is required' } })
	@GandalfProperty({ isRequired: true })
	coInsuranceSelected: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensFittingCoPay: number;

	@GandalfValidator({ notNull: { message: 'Contact Lens Fitting CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	contactLensFittingCoPaySelected: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	deductible: number;

	@GandalfValidator({ notNull: { message: 'Deductible Selection is required' } })
	@GandalfProperty({ isRequired: true })
	deductibleSelected: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay: number;

	@GandalfValidator({ notNull: { message: 'General CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	generalCoPaySelected: boolean;

	@GandalfProperty()
	invoiceId: number;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay: number;

	@GandalfValidator({ notNull: { message: 'Material CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	materialCoPaySelected: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay: number;

	@GandalfValidator({ notNull: { message: 'Medical Exam CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	medicalExamCoPaySelected: boolean;

	@GandalfProperty()
	removeTaxesSelected: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay: number;

	@GandalfValidator({ notNull: { message: 'Routine Exam CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	routineExamCoPaySelected: boolean;

	@GandalfProperty()
	transferTaxInvoiceId: number;

	@GandalfProperty()
	transferTaxesSelected: boolean;

	@GandalfProperty()
	transferToExistingInvoiceId: number;

}
