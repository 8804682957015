<div class="panel panel-default">
	<div class="panel-heading">
		<h4 class="panel-title">{{ title }}</h4>
		<ul class="nav-tools">
			<li>
				<a class="e-control e-btn e-lib e-success" (click)="openReferenceData(null)" revTooltip tooltipContent="Add">
					<i class="fa fa-plus"></i>
				</a>
			</li>
		</ul>
	</div>
	<pms-reference-data-list #list
					   [hidden]="detailMode"
					   [category]="category"
					   (openEvent)="openReferenceData($event)">
	</pms-reference-data-list>
	<pms-reference-data-detail
		*ngIf="detailMode"
		[referenceData]="referenceData"
		[category]="category"
		[headerText]="detailHeader"
		(returnEvent)="closeReferenceData($event)">
	</pms-reference-data-detail>
</div>
