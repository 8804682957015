import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { DirectAddressStatus } from '@gandalf/constants';
import { AddressOptionalRequest } from '@gandalf/model/address-optional-request';
import { AdminAccessTimeRequest } from '@gandalf/model/admin-access-time-request';
import { AdminAccessTimeResponse } from '@gandalf/model/admin-access-time-response';
import { AdminEmployeeDetailedResponse } from '@gandalf/model/admin-employee-detailed-response';
import { AdminEmployeeExternalSystemsRequest } from '@gandalf/model/admin-employee-external-systems-request';
import { AdminEmployeeExternalSystemsResponse } from '@gandalf/model/admin-employee-external-systems-response';
import { AdminEmployeeLocationAccessRequest } from '@gandalf/model/admin-employee-location-access-request';
import { AdminEmployeeLocationAccessResponse } from '@gandalf/model/admin-employee-location-access-response';
import { AdminEmployeeLoginDetailsResponse } from '@gandalf/model/admin-employee-login-details-response';
import { AdminEmployeeLoginRestrictionRequest } from '@gandalf/model/admin-employee-login-restriction-request';
import { AdminEmployeeLoginRestrictionResponse } from '@gandalf/model/admin-employee-login-restriction-response';
import { AdminEmployeeProviderDetailsRequest } from '@gandalf/model/admin-employee-provider-details-request';
import { AdminEmployeeProviderDetailsResponse } from '@gandalf/model/admin-employee-provider-details-response';
import { AdminEmployeeRolesRequest } from '@gandalf/model/admin-employee-roles-request';
import { AdminEmployeeRolesResponse } from '@gandalf/model/admin-employee-roles-response';
import { AdminUpdateEmployeeLoginDetailsRequest } from '@gandalf/model/admin-update-employee-login-details-request';
import { AdminUpdateEmployeeRequest } from '@gandalf/model/admin-update-employee-request';
import { EmployeeEditProfileRequest } from '@gandalf/model/employee-edit-profile-request';
import { EmployeeEditProfileResponse } from '@gandalf/model/employee-edit-profile-response';
import { EmployeePreferencesRequest } from '@gandalf/model/employee-preferences-request';
import { EmployeePreferencesResponse } from '@gandalf/model/employee-preferences-response';
import { PersonDetailsRequest } from '@gandalf/model/person-details-request';
import { PersonDetailsResponse } from '@gandalf/model/person-details-response';
import { ScheduleFilterRequest } from '@gandalf/model/schedule-filter-request';
import { StringAdditionalPreferenceRequest } from '@gandalf/model/string-additional-preference-request';
import { StringAdditionalPreferenceResponse } from '@gandalf/model/string-additional-preference-response';

@Injectable({
	providedIn: 'root',
})
export class MapEmployeeUtilsService {

	constructor() {
	}

	static mapEmployeeEditProfileToRequest(employeeEditProfileResponse: EmployeeEditProfileResponse, isCanada: boolean) {
		const employeeRequest = new EmployeeEditProfileRequest();
		employeeRequest.personDetails = MapEmployeeUtilsService.mapPersonDetailsToRequest(employeeEditProfileResponse.personDetails, isCanada);
		employeeRequest.employeePreferences = MapEmployeeUtilsService.mapToEmployeePreferencesRequest(employeeEditProfileResponse.employeePreferences);
		return employeeRequest;
	}

	static mapEmployeeToUpdateRequest(employeeResponse: AdminEmployeeDetailedResponse, isCanada: boolean) {
		const employeeRequest = new AdminUpdateEmployeeRequest();
		employeeRequest.employeeId = employeeResponse.employeeId;
		if (isCanada || _isNil(employeeResponse.employeeExternalSystems)) {
			employeeRequest.adminEmployeeExternalSystems = new AdminEmployeeExternalSystemsRequest();
			employeeRequest.adminEmployeeExternalSystems.directAddressStatus = DirectAddressStatus.INACTIVE;
			employeeRequest.adminEmployeeExternalSystems.rxntIsActive = false;
		} else {
			employeeRequest.adminEmployeeExternalSystems =
				MapEmployeeUtilsService.mapEmployeeExternalSystemToRequest(employeeResponse.employeeExternalSystems);
		}
		employeeRequest.adminEmployeeProviderDetails = MapEmployeeUtilsService.mapProviderDetailsToRequest(employeeResponse.employeeProviderDetails, isCanada);
		employeeRequest.employeeIsProvider = employeeRequest.adminEmployeeProviderDetails.employeeIsProvider;
		employeeRequest.personDetails = MapEmployeeUtilsService.mapPersonDetailsToRequest(employeeResponse.personDetails, isCanada);
		employeeRequest.adminEmployeeLocationAccess = MapEmployeeUtilsService.mapLocationAccessToRequest(employeeResponse.employeeLocationAccess);
		employeeRequest.adminUpdateEmployeeLoginDetails = MapEmployeeUtilsService.mapLoginDetailsToRequest(employeeResponse.employeeLoginDetails);
		employeeRequest.adminEmployeeLoginRestriction = MapEmployeeUtilsService.mapLoginRestrictionsToRequest(employeeResponse.employeeLoginRestriction);
		employeeRequest.adminEmployeeRoles = MapEmployeeUtilsService.mapToEmployeeRolesRequest(employeeResponse.employeeRoles);
		employeeRequest.employeePreferences = MapEmployeeUtilsService.mapToEmployeePreferencesRequest(employeeResponse.employeePreferences);

		return employeeRequest;
	}

	static mapToEmployeeRolesRequest(employeeRoles: AdminEmployeeRolesResponse) {
		const employeeRolesRequest = new AdminEmployeeRolesRequest();
		employeeRolesRequest.securityRoleIds = employeeRoles.securityRoleIds;
		employeeRolesRequest.encounterRoleIds = employeeRoles.encounterRoleIds;

		return employeeRolesRequest;
	}

	static mapToEmployeePreferencesRequest(employeePreferences: EmployeePreferencesResponse) {
		const employeePreferencesRequest = new EmployeePreferencesRequest();
		employeePreferencesRequest.scheduleFilters = new ScheduleFilterRequest();
		employeePreferencesRequest.stringAdditionalPreferences = [];
		const scheduleFilters = employeePreferences.scheduleFilters;
		employeePreferencesRequest.scheduleFilters.sortPersonIds = scheduleFilters?.sortPersonIds || [];
		employeePreferencesRequest.scheduleFilters.roleIds = scheduleFilters?.roleIds || [];
		employeePreferencesRequest.scheduleFilters.personIds = scheduleFilters?.personIds || [];
		employeePreferencesRequest.scheduleFilters.statuses = scheduleFilters?.statuses || [];
		employeePreferencesRequest.scheduleFilters.itemTypes = scheduleFilters?.itemTypes || [];
		employeePreferencesRequest.stringAdditionalPreferences = employeePreferences.stringAdditionalPreferences.map(
			MapEmployeeUtilsService.createStringAdditionalPreferenceRequest,
		);

		return employeePreferencesRequest;
	}

	static mapLoginDetailsToRequest(employeeLoginDetails: AdminEmployeeLoginDetailsResponse) {
		const loginDetails = new AdminUpdateEmployeeLoginDetailsRequest();
		loginDetails.emergencyAccess = employeeLoginDetails.emergencyAccess;
		loginDetails.username = employeeLoginDetails.username;

		return loginDetails;
	}

	static mapLoginRestrictionsToRequest(employeeLoginRestriction: AdminEmployeeLoginRestrictionResponse) {
		const loginRestriction = new AdminEmployeeLoginRestrictionRequest();
		loginRestriction.monday = MapEmployeeUtilsService.createNewAccessTimeRequest(employeeLoginRestriction.monday);
		loginRestriction.tuesday = MapEmployeeUtilsService.createNewAccessTimeRequest(employeeLoginRestriction.tuesday);
		loginRestriction.wednesday = MapEmployeeUtilsService.createNewAccessTimeRequest(employeeLoginRestriction.wednesday);
		loginRestriction.thursday = MapEmployeeUtilsService.createNewAccessTimeRequest(employeeLoginRestriction.thursday);
		loginRestriction.friday = MapEmployeeUtilsService.createNewAccessTimeRequest(employeeLoginRestriction.friday);
		loginRestriction.saturday = MapEmployeeUtilsService.createNewAccessTimeRequest(employeeLoginRestriction.saturday);
		loginRestriction.sunday = MapEmployeeUtilsService.createNewAccessTimeRequest(employeeLoginRestriction.sunday);

		return loginRestriction;
	}

	static mapLocationAccessToRequest(employeeLocationAccess: AdminEmployeeLocationAccessResponse) {
		const locationAccess = new AdminEmployeeLocationAccessRequest();
		locationAccess.primaryLocationId = employeeLocationAccess.primaryLocationId;
		locationAccess.accessibleLocationIds = employeeLocationAccess.accessibleLocationsIds;

		return locationAccess;
	}

	static mapPersonDetailsToRequest(personDetailsResponse: PersonDetailsResponse, isCanada: boolean) {
		const personDetailsRequest = new PersonDetailsRequest();
		const address = new AddressOptionalRequest();
		personDetailsRequest.titleId = personDetailsResponse.titleId;
		personDetailsRequest.firstName = personDetailsResponse.firstName;
		personDetailsRequest.lastName = personDetailsResponse.lastName;
		personDetailsRequest.middleName = personDetailsResponse.middleName;
		personDetailsRequest.suffix = personDetailsResponse.suffix;
		personDetailsRequest.credentials = personDetailsResponse.credentials;
		personDetailsRequest.nickName = personDetailsResponse.nickname;
		if (isCanada) {
			personDetailsRequest.sin = personDetailsResponse.ssn;
		} else {
			personDetailsRequest.ssn = personDetailsResponse.ssn;
		}
		personDetailsRequest.dateOfBirth = personDetailsResponse.dateOfBirth;
		personDetailsRequest.gender = personDetailsResponse.gender;
		personDetailsRequest.photoId = personDetailsResponse.photoId;
		if (!_isNil(personDetailsResponse.address)) {
			address.addressLine1 = personDetailsResponse.address.address1;
			address.addressLine2 = personDetailsResponse.address.address2;
			address.city = personDetailsResponse.address.city;
			address.stateProvince = personDetailsResponse.address.stateProvince;
			address.postalCode = personDetailsResponse.address.postalCode;
		}
		personDetailsRequest.address = address;
		personDetailsRequest.homePhone = personDetailsResponse.phoneNumbers.homePhone;
		personDetailsRequest.workPhone = personDetailsResponse.phoneNumbers.workPhone;
		personDetailsRequest.cellPhone = personDetailsResponse.phoneNumbers.cellPhone;
		personDetailsRequest.pager = personDetailsResponse.phoneNumbers.pager;
		personDetailsRequest.preferredPhoneType = personDetailsResponse.phoneNumbers.preferredPhoneType;
		personDetailsRequest.email = personDetailsResponse.email;

		return personDetailsRequest;
	}

	static createStringAdditionalPreferenceRequest = (preference: StringAdditionalPreferenceResponse) => {
		const preferenceRequest = new StringAdditionalPreferenceRequest();
		preferenceRequest.preferenceName = preference.preferenceName;
		preferenceRequest.value = preference.value;

		return preferenceRequest;
	};

	static mapProviderDetailsToRequest(employeeProviderDetails: AdminEmployeeProviderDetailsResponse, isCanada: boolean) {
		const providerDetails = new AdminEmployeeProviderDetailsRequest();
		if (!_isNil(employeeProviderDetails)) {
			providerDetails.deaNumber = employeeProviderDetails.deaNumber;
			providerDetails.portalDescription = employeeProviderDetails.portalDescription;
			providerDetails.displayColor = employeeProviderDetails.displayColor;
			providerDetails.licenseNumber = employeeProviderDetails.licenseNumber;
			providerDetails.employeeIsProvider = employeeProviderDetails.employeeIsProvider;
			providerDetails.providerType = employeeProviderDetails.providerTypeId;
			providerDetails.showPublic = employeeProviderDetails.showPublic;
			providerDetails.signatureId = employeeProviderDetails.signatureId;
			providerDetails.externalPhotoId = employeeProviderDetails.externalPhotoId;
			providerDetails.upin = employeeProviderDetails.upin;
			providerDetails.showPublic = !!employeeProviderDetails.showPublic;
			if (isCanada) {
				providerDetails.practitionerNum = employeeProviderDetails.practitionerNum;
				providerDetails.apiKey = employeeProviderDetails.apiKey;
				providerDetails.registrationNum = employeeProviderDetails.registrationNum;
			} else {
				providerDetails.npi = employeeProviderDetails.npi;
				providerDetails.einNumber = employeeProviderDetails.einNumber;
			}

		} else {
			providerDetails.employeeIsProvider = false;
			providerDetails.showPublic = false;
		}

		return providerDetails;
	}

	static mapEmployeeExternalSystemToRequest(employeeExternalSystems: AdminEmployeeExternalSystemsResponse) {
		const externalSystemsRequest = new AdminEmployeeExternalSystemsRequest();
		externalSystemsRequest.rxntIsActive = !!employeeExternalSystems.rxntIsActive;
		externalSystemsRequest.rxntUsername = employeeExternalSystems.rxntUsername;
		externalSystemsRequest.rxntPassword = employeeExternalSystems.rxntPassword;
		externalSystemsRequest.directAddressStatus = employeeExternalSystems.directAddressStatus == null
			? DirectAddressStatus.INACTIVE
			: employeeExternalSystems.directAddressStatus;

		return externalSystemsRequest;
	}

	static createNewAccessTimeRequest(adminAccessTimeResponse: AdminAccessTimeResponse) {
		const accessTimeRequest = new AdminAccessTimeRequest();
		accessTimeRequest.hasAccess = adminAccessTimeResponse.hasAccess;
		accessTimeRequest.startTime = adminAccessTimeResponse.startTime;
		accessTimeRequest.endTime = adminAccessTimeResponse.endTime;

		return accessTimeRequest;
	}
}
