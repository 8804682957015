<ejs-dialog
		#modal
		cssClass="modal-md"
		(close)="closeDialog()"
		[attr.data-test-id]="'insuranceVerifiedModal'">
	<ng-template #header>
		<div class="dlg-template">Insurance Verified</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal" [formGroup]="componentForm" #templateForm="ngForm" (ngSubmit)="save()">
			<gandalf-lib-validation-messages
					*ngIf="componentForm.invalid && templateForm.submitted"
					[form]="componentForm"
					[requestObj]="verifyInsuranceRequest"
					[attr.data-test-id]="'insuranceVerifiedModalValidationMessages'">
			</gandalf-lib-validation-messages>
			<div class="row">
				<div class="col-sm-12">
					<div class="form-group">
						<label class="col-sm-4 control-label required">Insurance Validity</label>
						<div class="col-sm-8">
							<rev-status-select-button [options]="validityOptions" formControlName="status"></rev-status-select-button>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-4 control-label">Comments</label>
						<div class="col-sm-8">
							<ejs-textbox [revFocusInput]="true" class="h-rows-5" [multiline]="true" formControlName="comments"></ejs-textbox>
						</div>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button (click)="closeDialog()" iconCss="fa fa-times" [attr.data-test-id]="'insuranceVerifiedCancelButton'">Cancel</button>
		<button type="button" ejs-button [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'insuranceVerifiedSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>

