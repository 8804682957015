<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-md"
	[attr.data-test-id]="'printOrSendStatementsModal'">
	<ng-template #header>
		<div class="dlg-template">{{printOrSend()}} Statements</div>
	</ng-template>
	<ng-template #content>
		<div class="form-horizontal">
			<div class="alert alert-danger validationMessages" #validationMessages *ngIf="this.submitted && !isValid()"
				 [attr.data-test-id]="'printOrSendStatementsValidationMessages'">
				<ul>
					<li>
						<strong>Statement print date is required</strong>
					</li>
				</ul>
			</div>
			<div class="form-group" *ngIf="useFileFormat && !sendStatements" [attr.data-test-id]="'statementFormatButtonGroup'">
				<label class="col-sm-4 control-label">Format</label>
				<div class="col-sm-8">
					<pms-enum-select-button
							[(ngModel)]="statementFormat"
							[enumList]="statementFormats">
					</pms-enum-select-button>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'statementDueDateFormGroup'">
				<label class="col-sm-4 control-label">Due Date</label>
				<div class="col-sm-8">
					<div class="row">
						<div class="col-sm-6 margin-bottom-xs">
							<ejs-dropdownlist
								#dueDateDropDown
								[showClearButton]="true"
								[(ngModel)]="dueDateReason"
								[dataSource]="dueDateReasons">
							</ejs-dropdownlist>

						</div>
						<div class="col-sm-6 margin-bottom-xs">
							<ejs-datepicker
									[hidden]="!isCustomDueDate"
									[(ngModel)]="dueDate"
									placeholder="mm/dd/yyyy"
									[format]="dateFormat"
									[attr.data-test-id]="'printOrSendStatementsDueDatePicker'">
							</ejs-datepicker>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'statementMessageFormGroup'">
				<label class="col-sm-4 control-label">Message</label>
				<div class="col-sm-8">
					<div class="margin-bottom-sm">
						<ejs-dropdownlist
							#messageDropDown
							[showClearButton]="true"
							(valueChange)="setMessageBox($event)"
							[(ngModel)]="messageReason"
							[dataSource]="messageReasons">
						</ejs-dropdownlist>
					</div>
					<ejs-textbox multiline="true" [(ngModel)]="message"></ejs-textbox>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'statementMarkAsPrintedFormGroup'">
				<div class="col-sm-8 col-sm-offset-4">
					<div class="row">
						<div class="col-sm-6 margin-bottom-xs">
							<div class="margin-top-xs">
								<ejs-checkbox
										[disabled]="sendStatements"
										label="Mark as printed"
										[(ngModel)]="markAsPrinted"
										[attr.data-test-id]="'printOrSendStatementsMarkAsPrintedCheckbox'"></ejs-checkbox>
							</div>
						</div>
						<div class="col-sm-6 margin-bottom-xs">
							<ejs-datepicker
									[hidden]="!markAsPrinted"
									[(ngModel)]="printDate"
									placeholder="mm/dd/yyyy"
									[format]="dateFormat"
									[attr.data-test-id]="'printOrSendStatementsPrintDatePicker'">
							</ejs-datepicker>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'statementCancelButton'">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submit()" [attr.data-test-id]="'printOrSendButton'">{{printOrSend()}}</button>
	</ng-template>
</ejs-dialog>
