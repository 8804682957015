// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { LegacyReferenceDataResponse } from './legacy-reference-data-response';

/** See com.rev360.legacy.api.controller.patient.LegacyDrugAllergyResponse */
/* istanbul ignore next */
@GandalfModel
export class LegacyDrugAllergyResponse extends GandalfModelBase {

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	drugCode: string;

	@GandalfProperty()
	drugName: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lastModificationDate: Date;

	@GandalfArray(LegacyReferenceDataResponse)
	reactions: LegacyReferenceDataResponse[];

	@GandalfProperty()
	snomedCodeCode: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.AllergyStatus;

}
