<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'editProfileModal'">
	<ng-template #header>
		<div class="dlg-template">Edit Profile</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  *ngIf="formGroup"
			  [formGroup]="formGroup"
			  (ngSubmit)="submit()"
			  #templateForm="ngForm">
			<div class="row">
				<div class="col-sm-12 col-md-7 col-lg-6">
					<gandalf-lib-validation-messages
							*ngIf="templateForm.invalid && templateForm.submitted"
							[form]="formGroup"
							[requestObj]="request"
							[attr.data-test-id]="'editProfileModalValidationMessages'">
					</gandalf-lib-validation-messages>
				</div>
			</div>
			<ejs-tab [animation]="tabAnimation">
				<e-tabitems>
					<e-tabitem [header]="{text: 'Personal Details'}">
						<ng-template #content>
							<pms-employee-personal-details
									[employeeId]="employeeId"
									[formGroup]="getPersonalDetailFormGroup()">
							</pms-employee-personal-details>
						</ng-template>
					</e-tabitem>
					<e-tabitem>
						<ng-template #headerText>
							<span [attr.data-test-id]="'editProfileModalPreferencesTab'">Preferences</span>
						</ng-template>
						<ng-template #content>
							<pms-employee-preferences
									[isUserProfile]="true"
									[formGroup]="getEmployeePreferencesFormGroup()">
							</pms-employee-preferences>
						</ng-template>
					</e-tabitem>
				</e-tabitems>
			</ejs-tab>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'editProfileModalCancelButton'">Cancel</button>
		<button ejs-button type="button" (click)="submitForm($event)" [isPrimary]="true" [attr.data-test-id]="'editProfileModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
