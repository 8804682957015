<div class="panel-feature">
	<button ejs-button iconCss="fa fa-chevron-left"
			(click)="returnToList()">All {{codeSet}} Diagnoses
	</button>
</div>
<form #diagnosisForm="ngForm" class="form-horizontal" [formGroup]="formGroup">
	<div class="panel-body">
		<gandalf-lib-validation-messages
				*ngIf="formGroup.invalid && diagnosisForm.submitted"
				[form]="formGroup" [requestObj]="createUpdateRequest">
		</gandalf-lib-validation-messages>
		<div class="row">
			<div class="col-md-6 col-lg-4">
				<div class="form-group">
					<label class="control-label col-sm-4 required">{{ codeSet }}</label>
					<div class="col-sm-8">
						<div class="e-input-group" *ngIf="isCreating">
							<input type="text" class="e-input" placeholder="Search Diagnoses"
								   *ngIf="!diagnosisData?.code"
								   [(ngModel)]="query" [ngModelOptions]="{standalone: true}"
								   (keydown.enter)="openAddDiagnosisModal($event)"
								   [attr.data-test-id]="'adminDiagnosisSearchText'"/>
							<input [readonly]="true" class="form-control-static e-input" *ngIf="diagnosisData?.code"
								   [value]="diagnosisData?.code"/>
							<span revTooltip tooltipContent="Search"
								  *ngIf="!diagnosisData.code"
								  (click)="openAddDiagnosisModal($event)"
								  class="e-input-group-icon fa fa-search"
								  [attr.data-test-id]="'adminDiagnosisSearchButton'">
					</span>
							<span revTooltip tooltipContent="Clear"
								  *ngIf="showClearButton()"
								  (click)="clearForm()"
								  class="e-input-group-icon fa fa-close">
					</span>
						</div>
						<p class="form-control-static" *ngIf="!isCreating"> {{ diagnosisData.code }} </p>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-sm-4" [attr.data-test-id]="'adminDiagnosisShortDescriptionLabel'">Short Description</label>
					<div class="col-sm-8">
						<p class="form-control-static" [attr.data-test-id]="'adminDiagnosisShortDescriptionValue'"> {{ diagnosisData.shortDescription }} </p>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-sm-4" [attr.data-test-id]="'adminDiagnosisLongDescriptionLabel'">Long Description</label>
					<div class="col-sm-8">
						<p class="form-control-static" [attr.data-test-id]="'adminDiagnosisLongDescriptionValue'"> {{ diagnosisData.longDescription }} </p>
					</div>
				</div>
				<div class="form-group">
					<label class="control-label col-sm-4"  [attr.data-test-id]="'adminDiagnosisCarePlanLabel'">Care Plan</label>
					<div class="col-sm-8">
						<ejs-dropdownlist *ngIf="diagnosisData?.code"
										  [dataSource]="carePlanTemplates"
										  formControlName="carePlanTemplateId"
										  [showClearButton]="true"
										  placeholder="Select Care Plan"
						></ejs-dropdownlist>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="panel-footer">
		<button ejs-button type="submit" [isPrimary]="true" (click)="saveChanges()">Save
		</button>
		<button ejs-button type="button" iconCss="fa fa-close" (click)="returnToList()">Cancel</button>
	</div>
</form>
