<button
		type="button"
		class="e-control e-btn e-lib"
		revTooltip
		[matMenuTriggerFor]="buttonMenu"
		[tooltipContent]="buttonTooltip"
		[tooltipDisabled]="buttonHidden"
		[ngClass]="buildNgClass()"
		[disabled]="buttonDisabled"
		[attr.data-test-id]="dataTestId"
		[attr.data-tooltip-content]="buttonTooltip"
>
	<i *ngIf="showIconLeft()" class="e-btn-icon fa fa-{{buttonIcon}}" [ngClass]="buildIconLeftNgClass()"></i>
	{{buttonLabel}}
	<i *ngIf="showIconRight()" class="e-btn-icon fa fa-{{buttonIcon}}" [ngClass]="buildIconRightNgClass()"></i>
</button>

<mat-menu #buttonMenu="matMenu">
	<ng-container *ngFor="let buttonMenuItem of buttonMenuItems">
		<mat-divider *ngIf="buttonMenuItem.isDivider; else menuItem"></mat-divider>
		<ng-template #menuItem>
			<button
					mat-menu-item
					[disableRipple]="true"
					[disabled]="buttonMenuItem.disabled"
					(click)="clickMenuItem(buttonMenuItem)"
			>
				{{buttonMenuItem.text}}
			</button>
		</ng-template>
	</ng-container>
</mat-menu>
