<mat-select #matSelect
		[formControl]="ngControl.control"
		multiple
		(focus)="onFocus()"
		[class.disabled]="isDisabled()"
		(keydown.Tab)="onKeyDownTab($event)"
		(keydown.ArrowLeft)="onKeydownLeftArrow($event)"
		(keydown.ArrowRight)="onKeydownRightArrow($event)"
		disableRipple
		[disableOptionCentering]="true"
		[panelClass]="panelClass"
		[placeholder]="inputPlaceholder"
		(openedChange)="onOpenChanged($event)">
	<mat-select-trigger class="hide-border mat-select-trigger">
		<div class="e-multiselect e-input-group e-control-wrapper e-checkbox e-extra-small" tabindex="-1" #tooltipElement>
			<div class="e-multi-select-wrapper e-delimiter">
				<span *ngIf="selectedAbbreviations?.length > 0" class="e-delim-view e-delim-values">
					{{selectedAbbreviations.join(', ')}}
				</span>
				<span class="e-chips-close e-close-hooker" (click)="clear($event)" *ngIf="!ngControl.control.disabled"></span>
			</div>
		</div>
	</mat-select-trigger>
	<div class="e-ddl e-popup e-multi-select-list-wrapper">
		<span class="e-filter-parent">
			<span class="e-input-group e-control-wrapper e-input-focus">
				<input [revFocusInput]="focusSearchBar"
					   class="e-input-filter e-input focus-visible"
					   [placeholder]="filterBarPlaceholder"
					   (keydown.Space)="onSpacebarKeydown($event)"
					   (input)="filter()"
					   [ngModelOptions]="{standalone: true}"
					   [(ngModel)]="filterText"/>
			</span>
		</span>
		<div *ngIf="showSelectAll" class="e-selectall-parent">
			<mat-checkbox class="mat-option"
					[disableRipple]="true"
					(click)="$event.stopPropagation()"
					(change)="toggleSelection($event)"
					[indeterminate]="isIntermediate"
					[checked]="allSelected">
			Select All
			</mat-checkbox>
		</div>
		<div class="e-content e-dropdownbase">
			<ng-container *ngIf="!filteredOptions">
				<mat-option *ngFor="let option of dataSource; trackBy:trackByValue" [value]="option[valueField]">{{option[labelField]}}</mat-option>
			</ng-container>
			<ng-container *ngIf="filteredOptions">
				<ng-container *ngFor="let option of filteredOptions; trackBy:trackByValue">
					<mat-option [class.hide]="option.hide" [value]="option[valueField]">{{option[labelField]}}</mat-option>
				</ng-container>
			</ng-container>
		</div>
	</div>
</mat-select>
