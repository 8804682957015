<div class="e-input-group" [class.e-disabled]="disabled">
	<input [id]="htmlId"
		   [revFocusInput]="true"
		   type="text" class="e-input"
		   revTooltip [tooltipContent]="drugText"
		   [tooltipDisabled]="!isDrugSelected"
		   [(ngModel)]="drugText"
		   [ngModelOptions]="{standalone: true}"
		   [placeholder]="placeholder"
		   [disabled]="disabled"
		   [readOnly]="isDrugSelected"
		   (keydown.enter)="triggerDrugSelection($event)">
	<span revTooltip tooltipContent="Search"
		  *ngIf="!isDrugSelected"
		  (click)="triggerDrugSelection()"
		  class="e-input-group-icon fa fa-search"
		  [attr.data-test-id]="'drugLookupButton'">
	</span>
	<span revTooltip tooltipContent="Clear"
		  *ngIf="isDrugSelected"
		  (click)="clearSelectedDrug()"
		  class="e-input-group-icon fa fa-close">
	</span>
</div>
