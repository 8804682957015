<ng-container *ngIf="!isExternalAuth()">
	<h4 class="section-title bg-default mrgn-btm">Electronic Delivery of Rx</h4>
	<p>Patient consent obtained for electronic delivery of Rx by:</p>
	<div class="form-group" [attr.data-test-id]="'contactLensElectronicDeliveryMethodFormGroup'">
		<label class="col-sm-4 control-label">Delivery Method</label>
		<div class="col-sm-8">
			<p *ngIf="!editMode else editTemplate" class=" form-control-static">{{ contactLensPrescription.deliveryMethod ? contactLensPrescription.deliveryMethod.value : "None" }}
				<a revTooltip tooltipContent="Edit" (click)="editModeOn()" class="margin-left-xs"><i class="fa fa-pencil"></i></a>
			</p>
		</div>
	</div>
	<ng-template #editTemplate>
		<div class="inplace-editor">
			<div class="inplace-editor-wrapper">
				<div class="inplace-editor-component">
					<ejs-dropdownlist
						[dataSource]="deliveryMethodOptions"
						[(ngModel)]="deliveryMethodReferenceId"
						[showClearButton]="true"
						placeholder="Select Delivery Method"
						[attr.data-test-id]="'contactLensElectronicDeliveryMethodDropdown'">
					</ejs-dropdownlist>
				</div>
				<div class="inplace-editor-actions">
					<button revTooltip tooltipContent="Save" ejs-button type="button" iconCss="fa fa-check" (mousedown)="save()" [attr.data-test-id]="'contactLensElectronicDeliveryMethodSaveButton'"></button>
					<button revTooltip tooltipContent="Cancel" ejs-button type="button" iconCss="fa fa-times" (mousedown)="cancel()" [attr.data-test-id]="'contactLensElectronicDeliveryMethodCancelButton'"></button>
				</div>
			</div>
		</div>
	</ng-template>
</ng-container>
