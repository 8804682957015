<ejs-dialog #modal
			(close)="closeModal()"
			cssClass="modal-lg"
			[attr.data-test-id]="'transferItemsModal'">
	<ng-template #header>
		<div class="dlg-template">Transfer Items</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="formGroup"
			  (ngSubmit)="transferItems()"
			  #templateForm="ngForm">
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && templateForm.submitted"
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'transferItemsModalValdationMessages'"></gandalf-lib-validation-messages>
			<h4 class="section-title bg-default mrgn-btm">Invoice #{{invoiceId}} for <strong>{{patientName | patientName}}</strong></h4>
			<div class="margin-bottom-sm">
				<ng-template #cannotTransferOrWriteOff>
					<span>You do not have permission to write-off items associated with this invoice. Contact your administrator to get permission.</span>
				</ng-template>
				<ng-container *ngIf="canTransferOrWriteoff() else cannotTransferOrWriteOff">
					<div class="form-group">
						<label class="col-sm-2 control-label required">{{ transferTypeText() }}</label>
						<div class="col-sm-10">
							<div class="form-inline">
								<span class="display-inline-block margin-right-md" *ngIf="canTransferAndWriteoff()">
									<pms-enum-select-button
											formControlName="transferType"
											[enumList]="transferTypes"
											[allOption]="false">
									</pms-enum-select-button>
								</span>
								<span class="display-inline-block margin-right-sm">
									<ejs-dropdownlist id="transferReason"
											*ngIf="showTransferReasonsDropdown"
											[dataSource]="transferReasons"
											formControlName="transferReferenceId"
											placeholder="Select Transfer Reason"
										  	[width]="400"
											[showClearButton]="true">
									</ejs-dropdownlist>
									<ejs-dropdownlist id="writeoffReason"
											*ngIf="showWriteoffReasonsDropdown"
											[dataSource]="writeoffReasons"
											formControlName="writeoffReferenceId"
											placeholder="Select Write-off Reason"
										  	[width]="400"
											[showClearButton]="true">
									</ejs-dropdownlist>
								</span>
								<span class="display-inline-block">
									<ejs-checkbox #checkbox label="Include All Items in Transfer" name="includeAllItems"
											formControlName="includeAllItems" (keydown.tab)="onTabKeydown($event, false, true)"></ejs-checkbox>
								</span>
							</div>
						</div>
					</div>
					<div class="form-group" *ngIf="showTransferReasonsDropdown">
						<label class="col-sm-2 control-label required">{{ transferToText }}</label>
						<div class="col-sm-10">
							<div class="form-inline">
								<span class="display-inline-block margin-right-md vertical-align-middle"
									  *ngIf="!removeTransferToInsuranceOption && !removeTransferToPatientOption">
									<pms-enum-select-button
											formControlName="payerType"
											(click)="updatePersonInsuranceDropdown()"
											[enumList]="payerTypes"
											[allOption]="false">
									</pms-enum-select-button>
								</span>
								<span class="display-inline-block" *ngIf="!showPayerInvoiceOptions">
									<ejs-dropdownlist id="insurance"
											*ngIf="personInsuranceList"
											[dataSource]="personInsuranceList"
											formControlName="personInsuranceId"
											placeholder="Select Insurance"
										  	[width]="400"
											[showClearButton]="true"
											(keydown.tab)="onTabKeydown($event, false, false)">
									</ejs-dropdownlist>
								</span>
								<ng-container *ngIf="showPayerInvoiceOptions">
									<span class="display-inline-block margin-x-xs margin-right-sm">
										<ejs-radiobutton label="New Invoice" name="transferToNewOrExistingInvoice"
												[value]="TRANSFER_TO_NEW_INVOICE_LABEL"
												formControlName="transferToNewOrExistingInvoice"
												(keydown.tab)="onTabKeydown($event, false, false)"
												[attr.data-test-id]="'transferToNewInvoiceRadioButton'">
										</ejs-radiobutton>
									</span>
									<span class="display-inline-block margin-x-xs margin-right-sm" *ngIf="showExistingInvoiceOption">
										<ejs-radiobutton label="Existing Invoice"
												name="transferToNewOrExistingInvoice" [value]="TRANSFER_TO_EXISTING_INVOICE_LABEL"
												formControlName="transferToNewOrExistingInvoice"
												(keydown.tab)="onTabKeydown($event, false, false)"
												[attr.data-test-id]="'transferToExistingInvoiceRadioButton'">
										</ejs-radiobutton>
									</span>
									<span class="display-inline-block margin-left-sm" *ngIf="showExistingInvoiceDropdown">
										<ejs-dropdownlist id="invoices" #invoiceDropdown
												[dataSource]="transferToInvoiceList"
												formControlName="transferToExistingInvoiceId"
												placeholder="Select Invoice"
											  	[width]="275"
												(keydown.tab)="onTabKeydown($event, true, false)">
										</ejs-dropdownlist>
									</span>
								</ng-container>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="margin-bottom-sm">
				<ag-grid-angular class="ag-theme-alpine"
								 pmsAgAutoFitColumns
								 #agGrid
								 *ngIf="isAgGridFeatureFlagOn"
								 [domLayout]="'autoHeight'"
								 [gridOptions]="transferItemsGridOptions"
								 [rowData]="invoiceItems"
								 (gridReady)="onGridReady($event)"
								 (cellFocused)="onCellFocus($event)"
								 (cellMouseDown)="onCellClick($event)"
								 [pagination]="false"
								 [attr.data-test-id]="'transferItemsModalItemsGrid'">
				</ag-grid-angular>

				<ejs-grid #grid
						  *ngIf="!isAgGridFeatureFlagOn"
						  pmsInputSpecialKeyHandlerDirective
						  [pmsGridSubmit]="isSearching"
						  [dataSource]="invoiceItems"
						  [staticColumns]="['invoiceItem.quantity', 'transferAmount', 'adjustmentTotal', 'invoiceItem.amountPaid', 'balance']"
						  autoFitColumns
						  [autoFitBlacklist]="['invoiceItem.description']"
						  [allowPaging]="false"
						  [allowSelection]="false"
						  [allowSorting]="true"
						  [allowResizing]="true"
						  [aggregates]="aggregates"
						  gridLines="Horizontal"
						  allowPaging="true">
					<e-columns>
						<e-column field="invoiceItem.id" headerText="ID" [visible]="false" [isPrimaryKey]="true"></e-column>
						<e-column field="invoiceItem.code" headerText="Code"></e-column>
						<e-column clipMode="EllipsisWithTooltip" field="invoiceItem.description" headerText="Description"
								  [customAttributes]="{ autofitMinWidth: 50 }"></e-column>
						<e-column width="63" field="invoiceItem.quantity" headerText="Qty"></e-column>
						<e-column headerText="Unit Price" field="invoiceItem.unitPrice" format="C2"></e-column>
						<e-column headerText="Sub-Total" field="subtotal" format="C2"></e-column>
						<e-column headerText="Discounts" field="invoiceItem.discountTotal" format="C2"></e-column>
						<e-column headerText="Tax" field="tax" format="C2"></e-column>
						<e-column headerText="Ext. Price" field="extPrice" format="C2"></e-column>
						<e-column width="150" headerText="Transfer" format="C2" type="number" field="transferAmount">
							<ng-template #template let-data>
								<pms-always-set-currency-input min="0"
										[formControl]="getInvoiceItemControl('transferAmount', data.invoiceItem.id)"
										(click)="$event.target.select()">
								</pms-always-set-currency-input>
							</ng-template>
						</e-column>
						<e-column width="120" headerText="Adjustments" type="number" field="adjustmentTotal" format="C2"></e-column>
						<e-column width="100" headerText="Paid" type="number" field="invoiceItem.amountPaid" format="C2"></e-column>
						<e-column width="100" headerText="Balance" type="number" field="balance" textAlign="Right">
							<ng-template #template let-data>
								<div [class.text-danger]="getItemByItem(data.invoiceItem.id).balance < 0"> {{ getItemByItem(data.invoiceItem.id).balance | currency }}</div>
							</ng-template>
						</e-column>
					</e-columns>
				</ejs-grid>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-8 col-lg-9">
					<div class="margin-bottom-sm">
					</div>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-3">
					<table class="table table-bordered table-condensed text-right margin-bottom-0">
						<tbody>
						<tr class="bg-default text-uppercase">
							<td>Current Balance</td>
							<td [attr.data-test-id]="'currentBalanceAmount'">{{totalCurrentBalance}}</td>
						</tr>
						<tr>
							<td>Amount Transferred</td>
							<td [attr.data-test-id]="'amountTransferAmount'">{{totalAmountTransferred}}</td>
						</tr>
						</tbody>
						<tfoot>
						<tr class="bg-info text-uppercase">
							<td><strong>After Payments &amp; Transfers</strong></td>
							<td [attr.data-test-id]="'totalBalanceAfterTransferAmount'"><strong>{{totalBalanceAfterTransfer}}</strong></td>
						</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button
				ejs-button iconCss="fa fa-times"
				(keydown.tab)="onTabKeydown($event, false, false)"
				type="button" (click)="closeModal()"
				[attr.data-test-id]="'transferItemsModalCancelButton'">Cancel</button>
		<button [disabled]="!canTransferOrWriteoff()"
				ejs-button [isPrimary]="true"
				type="submit"
				(click)="submitForm($event)"
				[attr.data-test-id]="'transferItemsModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>

<ng-template #noOptions>
	You do not have permission to write-off items associated with this invoice. Contact your administrator to get permission.
</ng-template>

<ng-template #transferColumn let-data>
	<ng-container *ngIf="getInvoiceItemControl('transferAmount', data?.invoiceItem?.id)">
		<pms-always-set-currency-input
				*ngIf="!isAggregateRow(data)"
				[revFocusInput]="isCellSelected(data?.invoiceItem?.id, 'transferAmount')"
				[highlightInputOnFocus]="true"
                (inputBlur)="onInputBlur()"
				[formControl]="getInvoiceItemControl('transferAmount', data.invoiceItem.id)">
		</pms-always-set-currency-input>
	</ng-container>
	<span *ngIf="isAggregateRow(data)">{{data.transferAmount | currency}}</span>
</ng-template>

<ng-template #adjustmentsColumn let-data>
	<ng-container *ngIf="getItemByItem(data?.invoiceItem?.id)">
		<div *ngIf="!isAggregateRow(data)">{{getItemByItem(data.invoiceItem.id).adjustmentTotal| currency}}</div>
	</ng-container>
	<span *ngIf="isAggregateRow(data)" class="text-right">{{data.adjustmentTotal | currency}}</span>
</ng-template>

<ng-template #paymentTotalColumn let-data>
	<ng-container *ngIf="getItemByItem(data?.invoiceItem?.id)">
		<div *ngIf="!isAggregateRow(data)">{{getItemByItem(data.invoiceItem.id)?.invoiceItem.amountPaid | currency}}</div>
	</ng-container>
	<span *ngIf="isAggregateRow(data)" class="text-right">{{data.invoiceItem?.amountPaid | currency}}</span>
</ng-template>

<ng-template #balanceColumn let-data>
	<ng-container *ngIf="getItemByItem(data?.invoiceItem?.id)">
		<div *ngIf="!isAggregateRow(data)" [class.text-danger]="getItemByItem(data.invoiceItem.id).balance < 0"> {{ getItemByItem(data.invoiceItem.id).balance | currency }}</div>
	</ng-container>
	<span *ngIf="isAggregateRow(data)" class="text-right">{{data.balance | currency}}</span>
</ng-template>

<ng-template #adjustmentTooltip let-data>
	<div class="rev-custom-tooltip-content" *ngIf="shouldShowTooltip(data, false)" [attr.data-test-id]="'transferItemsAdjustmentTooltip'">
		<table class="table table-bordered table-condensed margin-bottom-0">
			<thead>
			<tr>
				<th>Adjustment</th>
				<th>Details</th>
				<th class="text-right">Amount</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let adjustment of sortBy(filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, false), 'id')">
				<td [attr.data-test-id]="'transferItemsAdjustmentTooltipTypeLabel'">{{adjustment.type}}</td>
				<td [attr.data-test-id]="'transferItemsAdjustmentTooltipDetailsLabel'">{{adjustment.details}}</td>
				<td class="text-right" [attr.data-test-id]="'transferItemsAdjustmentTooltipAmountLabel'">{{adjustment.amount | currency}}</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>

<ng-template #totalDiscountTooltip let-data>
	<div class="rev-custom-tooltip-content" *ngIf="shouldShowTooltip(data, true)" [attr.data-test-id]="'transferItemsDiscountTooltip'">
		<table class="table table-bordered table-condensed margin-bottom-0">
			<thead>
			<tr>
				<th>Adjustment</th>
				<th>Details</th>
				<th class="text-right">Amount</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let adjustment of sortBy(filterAdjustments(data?.invoiceItemDetails?.itemAdjustments, true), 'id')">
				<td [attr.data-test-id]="'transferItemsDiscountTooltipTypeLabel'">{{adjustment.type}}</td>
				<td [attr.data-test-id]="'transferItemsDiscountTooltipDetailsLabel'">{{adjustment.details}}</td>
				<td class="text-right" [attr.data-test-id]="'transferItemsDiscountTooltipAmountLabel'">{{adjustment.amount | currency}}</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>
