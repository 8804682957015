<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-lg"
	[attr.data-test-id]="'discountItemsModal'">
	<ng-template #header>
		<div class="dlg-template">Discount Items</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="formGroup"
			  (ngSubmit)="submit()"
			  #templateForm="ngForm">
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && templateForm.submitted"
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'discountItemsModalValidationMessage'">
			</gandalf-lib-validation-messages>
			<div class="row">
				<div class="col-md-10 col-lg-6">
					<div class="form-group" [attr.data-test-id]="'discountReasonFormGroup'">
						<label for="discountReason" class="col-sm-4 control-label required">Discount Reason</label>
						<div class="col-sm-8">
							<ejs-dropdownlist id="discountReason"
											  *ngIf="discountReasons"
											  [dataSource]="discountReasons"
											  formControlName="discountReferenceId"
											  placeholder="Select Discount Reason"
											  [showClearButton]="true">
							</ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'discountDescriptionFormGroup'">
						<label for="discountReasonDescription" class="col-sm-4 control-label">Description</label>
						<div class="col-sm-8">
							<input id="discountReasonDescription" type="text" class="e-input" formControlName="description"/>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'discountTypeButtonFormGroup'">
						<label class="col-sm-4 control-label required">Discount Type</label>
						<div class="col-sm-8">
							<rev-status-select-button
								#discountType
								[ngModelOptions]="{standalone: true}"
								[ngModel]="'dollar'"
								(onChange)="changeDiscountType($event)"
								(keydown.tab)="onTabKeydownDiscountType($event)"
								[options]="discountTypeOptions">
							</rev-status-select-button>
							<ejs-numerictextbox format=".## '%'"
												[ngModelOptions]="{standalone: true}"
												[min]="0"
												[(ngModel)]="percentInputValue"
												[disabled]="percentInputDisabled"
												[showSpinButton]="false"
												(valueChange)="setPercentages($event)"
												(keydown.tab)="onTabKeydownPercent($event)"
												cssClass="display-inline-block w100 margin-left-sm"
												[attr.data-test-id]="'discountItemsPercentField'">
							</ejs-numerictextbox>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12">
					<ng-container *ngIf="!isAgGridFeatureFlagOn">
						<ejs-grid #grid
								  [pmsGridSubmit]="isSearching"
								  [dataSource]="invoiceItems"
								  [staticColumns]="['discountPercent', 'discountAmount', 'invoiceItem.quantity']"
								  autoFitColumns
								  [autoFitBlacklist]="['invoiceItem.description']"
								  [allowPaging]="false"
								  [allowSelection]="false"
								  [allowSorting]="true"
								  [allowResizing]="true"
								  gridLines="Horizontal"
								  allowPaging="true"
								  [attr.data-test-id]="'discountItemsModalGrid'">
							<e-columns>
								<e-column field="invoiceItem.id" headerText="ID" [visible]="false" [isPrimaryKey]="true"></e-column>
								<e-column field="invoiceItem.code" headerText="Code"></e-column>
								<e-column clipMode="EllipsisWithTooltip" field="invoiceItem.description" headerText="Description"
										  [customAttributes]="{ autofitMinWidth: 50 }"></e-column>
								<e-column width="63" field="invoiceItem.quantity" headerText="Qty"></e-column>
								<e-column headerText="Unit Price" field="invoiceItem.unitPrice" format="C2"></e-column>
								<e-column headerText="Sub-Total" field="subtotal" format="C2"></e-column>
								<e-column width="92" headerText="Percent" field="percent" [allowSorting]="false">
									<ng-template #template let-data>
										<ejs-numerictextbox format=".## '%'" [min]="0" [showSpinButton]="false"
															[formControl]="getInvoiceItemControl('discountPercent', data.invoiceItem.id)"></ejs-numerictextbox>
									</ng-template>
								</e-column>
								<e-column width="160" headerText="Dollars" field="amount" [allowSorting]="false">
									<ng-template #template let-data>
										<pms-always-set-currency-input min="0"
																	   [formControl]="getInvoiceItemControl('discountAmount', data.invoiceItem.id)"></pms-always-set-currency-input>
									</ng-template>
								</e-column>
								<e-column headerText="Discounts" field="totalDiscount" format="C2"></e-column>
								<e-column headerText="Taxes" field="tax" format="C2"></e-column>
								<e-column headerText="Ext. Price" field="extPrice" format="C2"></e-column>
								<e-column headerText="Adjustments" field="invoiceItem.adjustmentTotal" format="C2"></e-column>
								<e-column headerText="Balance" field="balance" textAlign="Right">
									<ng-template #template let-data>
										<div [class.text-danger]="data.balance < 0"> {{ data.balance | currency }}</div>
									</ng-template>
								</e-column>
							</e-columns>
						</ejs-grid>
					</ng-container>

					<ng-container *ngIf="isAgGridFeatureFlagOn">
						<ag-grid-angular class="ag-theme-alpine"
										 pmsAgAutoFitColumns
										 #agGrid
										 [pmsAgGridSubmit]="isSearching"
										 [gridOptions]="discountItemsGridOptions"
										 [rowData]="invoiceItems"
										 (gridReady)="onGridReady($event)"
										 (cellFocused)="onCellFocus($event)"
										 (cellMouseDown)="onCellClick($event)"
										 [pagination]="false"
										 [attr.data-test-id]="'discountItemsTable'">
						</ag-grid-angular>
					</ng-container>

				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'discountItemsCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" type="submit" (click)="submitForm($event)" [attr.data-test-id]="'discountItemsSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>

<ng-template #balanceTemplate let-data>
	<div [class.text-danger]="data.balance < 0"> {{ data.balance | currency }}</div>
</ng-template>

<ng-template #discountPercentColumn let-data>
	<ng-container *ngIf="getInvoiceItemControl('discountPercent', data?.invoiceItem?.id)">
		<ejs-numerictextbox
				format=".## '%'"
				[min]="0"
				[showSpinButton]="false"
				[formControl]="getInvoiceItemControl('discountPercent', data.invoiceItem.id)"
				[revFocusInput]="isCellSelected(data.invoiceItem.id, 'editablePercent')"
				[highlightInputOnFocus]="true"
				(blur)="onInputBlur()">
		</ejs-numerictextbox>
	</ng-container>
</ng-template>

<ng-template #discountAmountColumn let-data>
	<ng-container *ngIf="getInvoiceItemControl('discountAmount', data?.invoiceItem?.id)">
		<pms-always-set-currency-input
				[formControl]="getInvoiceItemControl('discountAmount', data.invoiceItem.id)"
				[revFocusInput]="isCellSelected(data.invoiceItem.id, 'editableDollars')"
				[highlightInputOnFocus]="true"
                [min]="0"
				(inputBlur)="onInputBlur()">
		</pms-always-set-currency-input>
	</ng-container>
</ng-template>
