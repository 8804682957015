<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-sm"
		[attr.data-test-id]="'cancelAppointmentModal'">
	<ng-template #header>
		<div class="dlg-template">Cancel Appointment</div>
	</ng-template>
	<ng-template #content>
		<p>Are you sure you want to cancel this appointment?</p>
		<form [formGroup]="componentForm"
			  (ngSubmit)="setCancelAppointment()"
			  #templateForm="ngForm">
			<div class="row">
				<div class="col-sm-12">
					<gandalf-lib-validation-messages
						*ngIf="componentForm.invalid && templateForm.submitted"
						[form]="componentForm"
						[requestObj]="cancelAppointmentRequest"
						[attr.data-test-id]="'cancelAppointmentModalValidationMessages'">
					</gandalf-lib-validation-messages>
				</div>
			</div>
			<ejs-radiobutton
				#cancelThisAppointmentOnlyRadioButton
				*ngIf="relatedAppointmentId"
				[checked]="true"
				cssClass="margin-bottom-sm"
				name="cancelThisAppointmentOnly"
				(change)="setRadioButtonToCancelThisAppointmentOnly()"
				label="Cancel this appointment only"
				[attr.data-test-id]="'cancelThisAppointmentOnlyRadioButton'"
			></ejs-radiobutton>
			<ejs-radiobutton
				#cancelRecurringAppointmentsRadioButton
				*ngIf="relatedAppointmentId"
				cssClass="margin-bottom-sm"
				name="cancelFutureRelatedAppointments"
				(change)="setRadioButtonToCancelFutureRelatedAppointments()"
				label="Cancel this appointment and future related appointments"
				[attr.data-test-id]="'cancelAppointmentAndFutureAppointmentsCheckbox'"
			></ejs-radiobutton>
			<div class="form-group" [attr.data-test-id]="'cancelAppointmentReason'">
				<label class="required">Reason</label>
				<ejs-textbox class="h-rows-5" [multiline]="true" formControlName="reason"></ejs-textbox>
			</div>
			<div class="form-group">
				<ejs-checkbox label="Patient Initiated" formControlName="patientInitiated"></ejs-checkbox>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" [attr.data-test-id]="'cancelAppointmentNoButton'">No</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'cancelAppointmentYesButton'">Yes</button>
	</ng-template>
</ejs-dialog>
