// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.DiscountItemRequest */
/* istanbul ignore next */
@GandalfModel
export class DiscountItemRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Discount Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	discountAmount: number;

	@GandalfProperty()
	discountPercent: number;

	@GandalfValidator({ notNull: { message: 'Invoice Item ID is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceItemId: number;

}
