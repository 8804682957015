<div class="panel-body">
	<ejs-grid #grid
			  [allowExcelExport]="true"
			  [allowFiltering]="true"
			  [allowPaging]="false"
			  [allowResizing]="false"
			  [allowRowDragAndDrop]="true"
			  [allowSelection]="true"
			  [allowSorting]="true"
			  class="table-rowlink"
			  (created)="gridCreated()"
			  [dataSource]="referenceDataList"
			  gridLines="Horizontal"
			  [pmsGridSubmit]="isSearching"
			  (rowDrag)="rowDrag()"
			  (rowDragStartHelper)="rowDragStartHelper($event)"
			  (rowDrop)="rowDropReorder()"
			  (rowSelected)="openReferenceDataDetails($event.data)"
			  [attr.data-test-id]="'standardReferenceDataTable'">
		<ng-template #toolbarTemplate let-data>
			<rev-table-action-menu [table]="grid">
				<ng-container rev-table-action-menu-left>
					<div class="e-toolbar-item">
						<label>Status</label>
						<pms-enum-select-button
							[(ngModel)]="statusFilter"
							[enumList]="statusFilterOptions"
							[allOption]="true"
                            (valueChange)="filterTable()">
						</pms-enum-select-button>
					</div>
					<div class="e-toolbar-item" *ngIf="statusFilter.value !== null">(Re-ordering disabled while filtering)</div>
				</ng-container>
			</rev-table-action-menu>
		</ng-template>
		<e-columns>
			<e-column field="value" headerText="Name" clipMode="EllipsisWithTooltip"></e-column>
			<e-column field="description" headerText="Description (Internal Use Only)" clipMode="EllipsisWithTooltip" [width]="300"></e-column>
			<e-column field="editableText" headerText="Editable" clipMode="EllipsisWithTooltip" [width]="100"></e-column>
			<e-column field="active" headerText="Status" clipMode="EllipsisWithTooltip" [width]="100">
				<ng-template #template let-data>
					<span>{{ data.active | yesNo : 'Active' : 'Inactive' }}</span>
				</ng-template>
			</e-column>
		</e-columns>
	</ejs-grid>
</div>
