import { Injectable, NgZone } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { PrintService } from '@core/print/print.service';
import { SortingService } from 'morgana';
import { FindActiveLocationProductsRequest } from '@gandalf/model/find-active-location-products-request';
import { LegacyServiceSummaryResponse } from '@gandalf-black/model/legacy-service-summary-response';
import { LegacyInventoryGandalfService } from '@gandalf-black/services';
import { InventoryReconciliationPrintComponent } from '@shared/component/inventory-reconciliation-print/inventory-reconciliation-print.component';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { _find } from '@core/lodash/lodash';
import { MasterProductCategory } from '@gandalf/constants';
import { ProductCategoryResponse } from '@gandalf/model/product-category-response';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { InventoryGandalfService } from '@gandalf/services';
import { InventoryServiceResponse } from '@gandalf/model/inventory-service-response';
import { LocationProductItemTableResponse } from '@gandalf/model/location-product-item-table-response';

@Injectable({
	providedIn: 'root',
})
export class InventoryService {

	constructor(
		public legacyInventoryGandalfService: LegacyInventoryGandalfService,
		private eventsManagerService: EventsManagerService,
		private ngZone: NgZone,
		private printService: PrintService,
		private securityManagerService: SecurityManagerService,
		private featureService: FeatureService,
		private inventoryGandalfService: InventoryGandalfService,
	) {
	}

	findActiveLocationProductSummaries(request: FindActiveLocationProductsRequest) {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.INVENTORY.UPLIFT)) {
			return this.inventoryGandalfService.findActiveLocationProducts(request)
				.pipe(map(items => SortingService.sortBy(items, ['manufacturerName', 'name', 'id'], ['asc', 'asc', 'desc'])));
		} else {
			return this.legacyInventoryGandalfService.findActiveLocationProductSummaries(request)
				.pipe(map(items => SortingService.sortBy(items, ['manufacturerName', 'name', 'id'], ['asc', 'asc', 'desc'])));
		}
	}

	findActiveLocationProductItems(id: number) {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.INVENTORY.UPLIFT)) {
			return this.inventoryGandalfService.findActiveLocationProductItems(id)
				.pipe(map((items: LocationProductItemTableResponse[]) => SortingService.sortBy(items, ['upc', 'sku', 'id'], ['asc', 'asc', 'desc'])));
		} else {
			return this.legacyInventoryGandalfService.findActiveProductLocationItems(id);
		}
	}

	getLocationProductItemById(id: number) {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.INVENTORY.UPLIFT)) {
			return this.inventoryGandalfService.getLocationProductItemById(id);
		} else {
			return this.legacyInventoryGandalfService.getLocationProductItemById(id);
		}
	}

	getLocationProductById(id: number) {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.INVENTORY.UPLIFT)) {
			return this.inventoryGandalfService.getLocationProductById(id);
		} else {
			return this.legacyInventoryGandalfService.getLocationProductById(id);
		}
	}

	findActiveCPTServices(locationId: number) {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.INVENTORY.UPLIFT)) {
			return this.inventoryGandalfService.findActiveCptServices(locationId)
				.pipe(map((items: InventoryServiceResponse[]) => SortingService.sortBy(items, ['name', 'id'], ['asc', 'desc'])));
		} else {
			return this.legacyInventoryGandalfService.findActiveCPTServices(locationId)
				.pipe(map((items: LegacyServiceSummaryResponse[]) => SortingService.sortBy(items, ['name', 'id'], ['asc', 'desc'])));
		}
	}

	findActiveOtherServices(locationId: number) {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.MODULES.INVENTORY.UPLIFT)) {
			return this.inventoryGandalfService.findActiveOtherServices(locationId)
				.pipe(map((items: InventoryServiceResponse[]) => SortingService.sortBy(items, ['name', 'id'], ['asc', 'desc'])));
		} else {
			return this.legacyInventoryGandalfService.findActiveOtherServices(locationId)
				.pipe(map((items: LegacyServiceSummaryResponse[]) => SortingService.sortBy(items, ['name', 'id'], ['asc', 'desc'])));
		}
	}

	findAllActiveServices(locationId: number) {
		return combineLatest([
			this.findActiveCPTServices(locationId),
			this.findActiveOtherServices(locationId),
		]).pipe(map(([cptServices, otherServices]) => [...cptServices, ...otherServices]));
	}

	subscribeToLegacyEvents() {
		this.eventsManagerService.subscribe(INTER_APP_CONSTANTS.REV360.RECONCILIATION.OPEN_PRINT_VIEW, (_event) => {
			this.ngZone.run(() => {
				this.printService.openPrint(InventoryReconciliationPrintComponent);
			});
		});
	}

	getMasterProductCategory(masterProductCategory: MasterProductCategory): ProductCategoryResponse {
		return _find(this.securityManagerService.environmentData.masterProductCategories, ['masterCategory', masterProductCategory]);
	}
}
