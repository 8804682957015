<ejs-dialog
		#modal
		(close)="closeDialog()"
		[attr.data-test-id]="'addEditNoteModal'">
	<ng-template #header>
		<div>{{modalTitle}}</div>
	</ng-template>
	<ng-template #content>
		<form [formGroup]="componentForm" (ngSubmit)="saveNote()" #templateForm="ngForm" class="form-horizontal">
			<div class="row">
				<div class="col-sm-12">
					<gandalf-lib-validation-messages
							*ngIf="componentForm.invalid && templateForm.submitted"
							[form]="componentForm"
							[requestObj]="noteRequest"
							[attr.data-test-id]="'addEditNoteModalValidationMessages'">
					</gandalf-lib-validation-messages>
				</div>
			</div>
			<div class="form-group">
				<div class="col-sm-12">
					<ejs-textbox
							[revFocusInput]="true"
							[multiline]="true"
							class="h-rows-5"
							formControlName="text"
							[readonly]="!isNoteEditable()">
					</ejs-textbox>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeDialog()" type="button" [attr.data-test-id]="'cancelAddEditNoteButton'">{{cancelButtonText}}</button>
		<button *ngIf="isNoteEditable()" ejs-button [isPrimary]="true" (click)="templateForm.onSubmit($event)" type="submit" [attr.data-test-id]="'saveAddEditNoteButton'">Save</button>
	</ng-template>
</ejs-dialog>
