// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeePreferencesRequest } from './employee-preferences-request';

// @ts-ignore
import { PersonDetailsRequest } from './person-details-request';

/** See com.rev360.pms.api.controller.header.EmployeeEditProfileRequest */
/* istanbul ignore next */
@GandalfModel
export class EmployeeEditProfileRequest extends GandalfModelBase {

	@GandalfSubRequestLabel('Preferences')
	@GandalfValidator({ notNull: { message: 'Employee Preferences are required' } })
	@GandalfProperty({ isRequired: true })
	employeePreferences: EmployeePreferencesRequest;

	@GandalfSubRequestLabel('Personal Details')
	@GandalfValidator({ notNull: { message: 'Personal details are required' } })
	@GandalfProperty({ isRequired: true })
	personDetails: PersonDetailsRequest;

}
