<div class="margin-top-sm">
	<div class="pull-right margin-left-sm">
		<button
			*ngIf="isInvoicePayerTypeInsurance()"
			[disabled]="isPaymentProcessing"
			ejs-button
			type="button"
			(click)="openPreviewClaim()"
			[attr.data-test-id]="'invoiceHeaderPreviewClaimButton'">
			Preview Claim
		</button>
		<button
			*ngIf="canPrintClaim()"
			[disabled]="isPaymentProcessing"
			ejs-button
			type="button"
			(click)="openPrintClaim()"
			[attr.data-test-id]="'invoiceHeaderPrintClaimButton'">
			Print Claim
		</button>
		<button
			*ngIf="canPrintClaim()"
			[disabled]="isPaymentProcessing"
			ejs-button type="button"
			(click)="openViewDataFile()"
			[attr.data-test-id]="'invoiceHeaderViewDataFileButton'">
			View Data File
		</button>
		<button
			*ngIf="canPrintInvoice()"
			[disabled]="isPaymentProcessing"
			ejs-button
			type="button"
			iconCss="fa fa-print"
			revTooltip
			tooltipContent="Print Invoice"
			[isPrimary]="true"
			(click)="openPrintInvoice()"
			[attr.data-test-id]="'invoicePrintButton'">
		</button>
	</div>
	<div>
		<div class="margin-right-md display-inline-block vertical-align-middle">
			<rev-status-select-button
				[disabled]="!canChangeAuthorizedStatus()"
				[options]="approvedStatusSelectOptions"
				[(ngModel)]="invoice.approved"
				[ngModelOptions]="{standalone: true}"
				(onChange)="updateApproved($event)"
				[attr.data-test-id]="'invoiceReceiveUpdateButton'">
			</rev-status-select-button>
		</div>
		<button
				ejs-button
				type="button"
				(click)="openReceivePaymentModal()"
				[disabled]="isPaymentProcessing"
				*ngIf="canReceivePayment()"
				[attr.data-test-id]="'invoiceReceivePaymentButton'">Receive Payment
		</button>
		<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="openDiagnosesModal()" [attr.data-test-id]="'invoiceHeaderDiagnosisButton'">Diagnoses</button>
		<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="addPatientPortion()" *ngIf="canAddPatientPortion()" [attr.data-test-id]="'invoiceHeaderPatientPortionButton'">Patient Portion</button>
		<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="addFeeSchedule()" *ngIf="canAddFeeSchedule()" [attr.data-test-id]="'invoiceHeaderAddFeeButton'">Add Fee Schedule</button>
		<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="removeFeeSchedule()" *ngIf="canRemoveFeeSchedule()" [attr.data-test-id]="'invoiceHeaderRemoveFeeButton'">Remove Fee Schedule</button>
		<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="discountItems()" *ngIf="canDiscountItems()" [attr.data-test-id]="'invoiceHeaderDiscountButton'">Discount Items</button>
		<button ejs-button type="button" [disabled]="isPaymentProcessing" (click)="transferItems()" *ngIf="canTransferItems()" [attr.data-test-id]="'invoiceHeaderTransferItemsButton'">Transfer Items</button>
		<button
			ejs-dropdownbutton
			[disabled]="isPaymentProcessing"
			[items]="ellipsisItemList"
			(select)="onEllipsisItemSelect($event.item.id)"
			cssClass="e-caret-hide"
			iconCss="fa fa-ellipsis-h"
			[attr.data-test-id]="'invoiceHeaderEllipsisButton'">
		</button>
	</div>
</div>
