// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { EyefinityOrderItemRequest } from './eyefinity-order-item-request';

// @ts-ignore
import { UpdateEyefinityOrderRequest } from './update-eyefinity-order-request';

// @ts-ignore
import { UpdateEyeglassLensMeasurementsRequest } from './update-eyeglass-lens-measurements-request';

/** See com.rev360.pms.api.controller.order.eyefinity.UpdateEyefinityEyeglassOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEyefinityEyeglassOrderRequest extends UpdateEyefinityOrderRequest {

	@GandalfLabel('AR Coating')
	@GandalfProperty()
	arCoatingId: number;

	@GandalfLabel('Associated Authorization')
	@GandalfValidator({ sizeString: { message: 'Associated Authorization cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	associatedAuthorization: string;

	@GandalfLabel('Contact Lens Benefit')
	@GandalfProperty()
	benefitContactLens: boolean;

	@GandalfLabel('Exam Benefit')
	@GandalfProperty()
	benefitExam: boolean;

	@GandalfLabel('Frame Benefit')
	@GandalfProperty()
	benefitFrame: boolean;

	@GandalfLabel('Lens Benefit')
	@GandalfProperty()
	benefitLens: boolean;

	@GandalfLabel('Lens Bevel')
	@GandalfValidator({ sizeString: { message: 'Lens Bevel cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	bevelExternalIdentifier: string;

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment: string;

	@GandalfLabel('Exam Copay')
	@GandalfProperty({ propertyType: 'Money' })
	copayExam: number;

	@GandalfLabel('Materials Copay')
	@GandalfProperty({ propertyType: 'Money' })
	copayMaterials: number;

	@GandalfLabel('DPD')
	@GandalfProperty()
	distancePd: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate: Date;

	@GandalfLabel('Lens Edge')
	@GandalfProperty()
	edgeId: number;

	@GandalfProperty()
	encounterId: number;

	@GandalfLabel('Estimate Discounts')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts: number;

	@GandalfLabel('Estimated Insurance Benefit')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit: number;

	@GandalfLabel('Frame Supplier')
	@GandalfValidator({ sizeString: { message: 'Frame Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalFrameSupplierIdentifier: string;

	@GandalfLabel('Frame Supplier Name')
	@GandalfValidator({ sizeString: { message: 'Frame Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalFrameSupplierName: string;

	@GandalfValidator({ sizeString: { message: 'Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalSupplierIdentifier: string;

	@GandalfValidator({ sizeString: { message: 'Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalSupplierName: string;

	@GandalfLabel('Fee Schedule')
	@GandalfProperty()
	eyefinityFeeScheduleId: number;

	@GandalfLabel('Order Items')
	@GandalfArray(EyefinityOrderItemRequest)
	eyefinityOrderItems: EyefinityOrderItemRequest[];

	@GandalfLabel('Allowance')
	@GandalfProperty({ propertyType: 'Money' })
	frameAllowance: number;

	@GandalfLabel('Frame Color')
	@GandalfProperty()
	frameColorId: number;

	@GandalfLabel('Frame Cost')
	@GandalfProperty({ propertyType: 'Money' })
	frameCost: number;

	@GandalfLabel('Frame Product')
	@GandalfProperty()
	frameId: number;

	@GandalfLabel('Frame Inventory')
	@GandalfProperty()
	frameItemId: number;

	@GandalfLabel('Frame Remake Cost')
	@GandalfProperty({ propertyType: 'Money' })
	frameRemakeCost: number;

	@GandalfLabel('Frame Size')
	@GandalfProperty()
	frameSizeId: number;

	@GandalfLabel('Wholesale Allowance')
	@GandalfProperty({ propertyType: 'Money' })
	frameWholesaleAllowance: number;

	@GandalfLabel('Glass Coating')
	@GandalfProperty()
	glassCoatingId: number;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions: string;

	@GandalfLabel('Is Valid')
	@GandalfProperty()
	isValid: boolean;

	@GandalfLabel('Lens Cost')
	@GandalfProperty({ propertyType: 'Money' })
	lensCost: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Lens Eye')
	@GandalfValidator({ notNull: { message: 'Lens Eye is required' } })
	@GandalfProperty({ isRequired: true })
	lensEye: constants.EyefinityLensLocation;

	@GandalfLabel('Lens Material')
	@GandalfValidator({ sizeString: { message: 'Lens Material cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	lensMaterialExternalIdentifier: string;

	@GandalfLabel('Lens Remake Cost')
	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Lens Request')
	@GandalfValidator({ notNull: { message: 'Lens Request is required' } })
	@GandalfProperty({ isRequired: true })
	lensRequest: constants.OrderLensRequest;

	@GandalfLabel('Lens Style')
	@GandalfProperty()
	lensStyleId: number;

	@GandalfLabel('Lens Type')
	@GandalfValidator({ sizeString: { message: 'Lens Type cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	lensTypeExternalIdentifier: string;

	@GandalfLabel('Tint Sample %')
	@GandalfValidator({ min: { min: 0, message: '{javax.validation.constraints.Min.message}' } })
	@GandalfValidator({ max: { max: 100, message: '{javax.validation.constraints.Max.message}' } })
	@GandalfProperty()
	lightDarkPercentage: number;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId: number;

	@GandalfLabel('Mirror Coating')
	@GandalfProperty()
	mirrorCoatingId: number;

	@GandalfLabel('NPD')
	@GandalfProperty()
	nearPd: number;

	@GandalfLabel('Lens Nose Pads')
	@GandalfProperty()
	nosePads: boolean;

	@GandalfLabel('Lens Nose Pads Other')
	@GandalfValidator({ sizeString: { message: 'Lens Nose Pads Other cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	nosePadsOther: string;

	@GandalfValidator({ sizeString: { message: 'Notification Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	notificationComments: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate: Date;

	@GandalfSubRequestLabel('OD')
	@GandalfLabel('OD Measurements')
	@GandalfValidator({ notNull: { message: 'OD Measurements is required' } })
	@GandalfProperty({ isRequired: true })
	odLensMeasurements: UpdateEyeglassLensMeasurementsRequest;

	@GandalfConstantDecorator()
	@GandalfLabel('Optical Center Type')
	@GandalfProperty()
	opticalCenterType: constants.OrderOpticalCenterType;

	@GandalfConstantDecorator()
	@GandalfLabel('Frame Indicator')
	@GandalfValidator({ notNull: { message: 'Frame Indicator is required' } })
	@GandalfProperty({ isRequired: true })
	orderFrameIndication: constants.OrderFrameIndication;

	@GandalfConstantDecorator()
	@GandalfLabel('Frame Source')
	@GandalfValidator({ notNull: { message: 'Frame Source is required' } })
	@GandalfProperty({ isRequired: true })
	orderFrameSource: constants.OrderFrameSource;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfProperty({ isRequired: true })
	orderId: number;

	@GandalfSubRequestLabel('OS')
	@GandalfLabel('OS Measurements')
	@GandalfValidator({ notNull: { message: 'OS Measurements is required' } })
	@GandalfProperty({ isRequired: true })
	osLensMeasurements: UpdateEyeglassLensMeasurementsRequest;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost: number;

	@GandalfLabel('Panto')
	@GandalfProperty()
	pantoscopicTilt: number;

	@GandalfValidator({ notNull: { message: 'Patient Notified is required' } })
	@GandalfProperty({ isRequired: true })
	patientNotified: boolean;

	@GandalfLabel('Eyeglass Prescription')
	@GandalfProperty()
	prescriptionId: number;

	@GandalfLabel('Lens Press-on')
	@GandalfProperty()
	pressOn: boolean;

	@GandalfLabel('Lens Press-on Other')
	@GandalfValidator({ sizeString: { message: 'Lens Press-on Other cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	pressOnOther: string;

	@GandalfProperty()
	processorId: number;

	@GandalfLabel('Quantity')
	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfProperty({ isRequired: true })
	quantity: number;

	@GandalfLabel('Tint Sample')
	@GandalfValidator({ sizeString: { message: 'Tint Sample cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	sampleExternalIdentifier: string;

	@GandalfLabel('Scratch Coating')
	@GandalfProperty()
	scratchCoatingId: number;

	@GandalfConstantDecorator()
	@GandalfLabel('Seg Height Type')
	@GandalfProperty()
	segHeightType: constants.OrderSegHeightType;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost: number;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost: number;

	@GandalfProperty()
	shipToOtherAddress: AddressOptionalRequest;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Ship To is required' } })
	@GandalfProperty({ isRequired: true })
	shipToType: constants.OrderShipToType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	shippingCompany: constants.OrderShipmentCompany;

	@GandalfLabel('Lens Slab Off')
	@GandalfProperty()
	slabOff: boolean;

	@GandalfLabel('Lens Slab Off Other')
	@GandalfValidator({ sizeString: { message: 'Lens Slab Off Other cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	slabOffOther: string;

	@GandalfLabel('Lens Thickness')
	@GandalfValidator({ sizeString: { message: 'Lens Thickness cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	thicknessExternalIdentifier: string;

	@GandalfLabel('Tint Color')
	@GandalfValidator({ sizeString: { message: 'Tint Color cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	tintColorExternalIdentifier: string;

	@GandalfLabel('Tint Color Other')
	@GandalfValidator({ sizeString: { message: 'Tint Color Other cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	tintColorOther: string;

	@GandalfLabel('Tint Type')
	@GandalfProperty()
	tintTypeId: number;

	@GandalfValidator({ sizeString: { message: 'Tracking # cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	trackingNumber: string;

	@GandalfLabel('Tray')
	@GandalfValidator({ sizeString: { message: 'Tray cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	tray: string;

	@GandalfLabel('UV Coating')
	@GandalfProperty()
	uvCoatingId: number;

	@GandalfProperty()
	vendorId: number;

	@GandalfValidator({ sizeString: { message: 'Vendor Order # cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	vendorOrderIdentifier: string;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version: number;

	@GandalfLabel('Wrap')
	@GandalfProperty()
	wrapAngle: number;

}
