import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CategoryService } from '@core/category/category.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _cloneDeep, _isNil } from '@core/lodash/lodash';
import { ModalManagerService, SortingService } from 'morgana';
import { CategoryEntityType } from '@gandalf/constants';
import { CategoryResponse } from '@gandalf/model/category-response';
import { CreateCategoryRequest } from '@gandalf/model/create-category-request';
import { UpdateCategoryRequest } from '@gandalf/model/update-category-request';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { GandalfFormBuilder } from 'gandalf';
import { NewEntityUniqueIdService } from '../../../../services/new-entity-unique-id-service/new-entity-unique-id.service';
import { SubCategoryModalComponent } from '../sub-category-modal/sub-category-modal.component';

@Component({
	selector: 'pms-advanced-category-detail',
	templateUrl: './advanced-category-detail.component.html',
	providers: [ModalManagerService],
})
export class AdvancedCategoryDetailComponent implements OnInit {

	@Input()
	categoryTitle: string;

	@ViewChild('grid')
	grid: GridComponent;

	@Input()
	categoryData: CategoryResponse;

	@Input()
	entityType: CategoryEntityType;

	@Output()
	returnEvent = new EventEmitter<boolean>();

	createOrUpdateRequest: CreateCategoryRequest | UpdateCategoryRequest;
	isCreating: boolean;
	formGroup: UntypedFormGroup;
	dragStarted = false;
	subCategoryResponses: CategoryResponse[];

	constructor(
		private gandalfFormBuilder: GandalfFormBuilder,
		private categoryService: CategoryService,
		private modalManagerService: ModalManagerService,
		private newEntityUniqueIdService: NewEntityUniqueIdService,
	) {
	}

	ngOnInit(): void {
		this.subCategoryResponses = _cloneDeep(this.categoryData?.subCategories);
		this.createForm();
	}

	createForm() {
		this.isCreating = !this.categoryData;
		this.createOrUpdateRequest = this.isCreating
			? new CreateCategoryRequest()
			: new UpdateCategoryRequest();
		this.formGroup = this.gandalfFormBuilder.group(this.createOrUpdateRequest);

		if (this.isCreating) {
			this.formGroup.get('entityType').setValue(this.entityType);
			this.formGroup.get('active').setValue(true);
			this.categoryData = new CategoryResponse();
			this.subCategoryResponses = [];
		} else {
			this.subCategoryResponses = SortingService.sortBy(
				this.subCategoryResponses, ['sortOrder', 'id'], ['asc', 'asc'],
			);
			this.formGroup.reset(this.categoryData);
			FormUtilsService.enabledWhen(this.formGroup.get('name'), this.categoryData.editable);
			FormUtilsService.enabledWhen(this.formGroup.get('active'), this.categoryData.editable);
		}
	}

	saveChanges() {
		if (this.formGroup.invalid) {
			return;
		}
		const createOrUpdateRequest = this.formGroup.getRawValue();
		createOrUpdateRequest.subCategoriesCreate = this.mapSubCategoryResponseToRequestCreate();
		if (this.isCreating) {
			this.categoryService.createCategory(createOrUpdateRequest).subscribe(() => {
				this.returnToList(true);
			});
		} else {
			createOrUpdateRequest.subCategoriesUpdate = this.mapSubCategoryResponseToRequestUpdate();
			this.categoryService.updateCategory(createOrUpdateRequest).subscribe(() => {
				this.returnToList(true);
			});
		}
	}

	mapSubCategoryResponseToRequestCreate() {
		return this.subCategoryResponses.filter(subCategory => subCategory.id <= 0)
			.map(subCategory => {
				const createCategoryRequest = new CreateCategoryRequest();
				createCategoryRequest.active = subCategory.active;
				createCategoryRequest.name = subCategory.name;
				createCategoryRequest.description = subCategory.description;
				createCategoryRequest.entityType = this.entityType;
				createCategoryRequest.sortOrder = subCategory.sortOrder;
				return createCategoryRequest;
			});
	}

	mapSubCategoryResponseToRequestUpdate() {
		return this.subCategoryResponses.filter(subCategory => subCategory.id > 0)
			.map(subCategory => {
				const updateCategoryRequest = new UpdateCategoryRequest();
				updateCategoryRequest.active = subCategory.active;
				updateCategoryRequest.name = subCategory.name;
				updateCategoryRequest.description = subCategory.description;
				updateCategoryRequest.id = subCategory.id;
				updateCategoryRequest.sortOrder = subCategory.sortOrder;
				return updateCategoryRequest;
			});
	}

	returnToList(refreshRequested: boolean) {
		this.returnEvent.emit(refreshRequested);
	}

	/* istanbul ignore next */
	rowDragStartHelper() {
		this.dragStarted = true;
	}

	/* istanbul ignore next */
	rowDrag() {
		this.dragStarted = true;
	}

	/* istanbul ignore next */
	rowDrop() {
		this.dragStarted = false;
	}

	rowDropReorder() {
		this.grid.clearSorting();
	}

	reorderCategories() {
		const currentView = this.grid.getCurrentViewRecords();
		currentView.forEach((category: CategoryResponse, index) => {
			category.sortOrder = index;
			this.subCategoryResponses.find(subCategory => subCategory.id === category.id)
				.sortOrder = index;
		});
	}

	openSubCategory(rowData?) {
		this.modalManagerService.open(SubCategoryModalComponent, {
			data: {
				category: rowData,
				entityType: this.entityType,
			},
		})
			.onClose.subscribe((returnedCategory) => {
				this.onSubCategoryModalClose(returnedCategory);
			});
	}

	private onSubCategoryModalClose(returnedCategory) {
		if (!_isNil(returnedCategory)) {
			let response = new CategoryResponse();
			if (returnedCategory.id) {
				response = this.subCategoryResponses
					.find(subcategory => subcategory.id === returnedCategory.id);
			} else {
				response.id = this.newEntityUniqueIdService.nextId;
				response.sortOrder = this.subCategoryResponses.length;
				this.subCategoryResponses.push(response);
			}
			response.active = returnedCategory.active;
			response.name = returnedCategory.name;
			response.description = returnedCategory.description;
			this.grid.refresh();
		}
	}
}
