// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.PatientRefundRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientRefundRequest extends GandalfModelBase {

	@GandalfValidator({ min: { min: 0, message: 'Amount cannot be negative' } })
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount: number;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId: number;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Method is required' } })
	@GandalfProperty({ isRequired: true })
	method: constants.RefundFrom;

	@GandalfValidator({ sizeString: { message: 'Ref # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	referenceNumber: string;

}
