import { Injectable } from '@angular/core';
import { ContactLensEyeOptionsResponse } from '@gandalf/model/contact-lens-eye-options-response';
import { ContactLensPrescriptionGandalfService } from '@gandalf/services';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { OptionItemResponse, OptionItemService } from '@core/option-item/option-item.service';
import { _assign } from '@core/lodash/lodash';
import { MasterReferenceDataResponse } from '@gandalf/model/master-reference-data-response';

export interface ContactLensEyeOptionsResponseWithOptionItems extends ContactLensEyeOptionsResponse {
	peripheralEdges: OptionItemResponse<MasterReferenceDataResponse, number>[];
	profiles: OptionItemResponse<MasterReferenceDataResponse, number>[];
	edgeLifts: OptionItemResponse<MasterReferenceDataResponse, number>[];
}

@Injectable({
	providedIn: 'root',
})
export class ContactLensService {

	clDataLoadedSubject = new Subject<boolean>();

	constructor(private contactLensPrescriptionGandalfService: ContactLensPrescriptionGandalfService) { }

	getContactLensEyeOptionsResponse(): Observable<ContactLensEyeOptionsResponseWithOptionItems> {
		return this.contactLensPrescriptionGandalfService.getContactLensEyeOptions().pipe(
			map(response => this.formatContactLensEyeOptions(response)),
		);
	}

	formatContactLensEyeOptions(response: ContactLensEyeOptionsResponse): ContactLensEyeOptionsResponseWithOptionItems {
		return _assign(response, {
			peripheralEdges: OptionItemService.toOptionItems(response.peripheralEdges, item => item.id, item => item.description),
			profiles: OptionItemService.toOptionItems(response.profiles, item => item.id, item => item.description),
			edgeLifts: OptionItemService.toOptionItems(response.edgeLifts, item => item.id, item => item.description),
		});
	}
}
