// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { InvoiceItemPaymentResponse } from './invoice-item-payment-response';

// @ts-ignore
import { InvoiceSummaryResponse } from './invoice-summary-response';

// @ts-ignore
import { ReceivePaymentTransferInvoiceItemsResponse } from './receive-payment-transfer-invoice-items-response';

/** See com.rev360.legacy.api.controller.accounting.PaymentResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount: number;

	@GandalfProperty()
	invoice: InvoiceSummaryResponse;

	@GandalfProperty()
	invoiceId: number;

	@GandalfProperty()
	paymentId: number;

	@GandalfArray(InvoiceItemPaymentResponse)
	paymentItems: InvoiceItemPaymentResponse[];

	@GandalfArray(ReceivePaymentTransferInvoiceItemsResponse)
	paymentTransfers: ReceivePaymentTransferInvoiceItemsResponse[];

}
