<div class="e-btn-group">
	<button
		class="e-control e-btn e-lib"
		*ngFor="let option of enumList; let index = index"
		#btn
		[ngClass]="{
			'e-active':isSelected(option),
			'e-focus': btn === focusedItem
		}"
		type="button"
		[attr.data-test-id]="buildDataTestIdForOption(option)"
		(click)="onItemClick($event,option,index)"
		[disabled]="disabled"
		(keydown.enter)="onItemClick($event,option,index)"
		[attr.aria-label]="option.label"
		(focus)="onFocus($event)"
		(blur)="onBlur()">
		<ng-container>
			{{option.label}}
		</ng-container>
	</button>
</div>
