import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { EnumUtil, SortingService } from 'morgana';
import { _assign, _chunk, _map } from '@core/lodash/lodash';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ToasterService } from '@core/toaster/toaster.service';
import { UrlService } from '@core/url-util/url.service';
import { AddAdhocInvoiceItemRequest } from '@gandalf/model/add-adhoc-invoice-item-request';
import { AddDiscountItemsRequest } from '@gandalf/model/add-discount-items-request';
import { AddDiscountRequest } from '@gandalf/model/add-discount-request';
import { AddInvoiceItemRequest } from '@gandalf/model/add-invoice-item-request';
import { ApproveInvoiceRequest } from '@gandalf/model/approve-invoice-request';
import { CreatePatientCreditRequest } from '@gandalf/model/create-patient-credit-request';
import { CreatePaymentGroupRequest } from '@gandalf/model/create-payment-group-request';
import { CreateRefundPatientRequest } from '@gandalf/model/create-refund-patient-request';
import { InvoiceItemDiagnosisResponse } from '@gandalf/model/invoice-item-diagnosis-response';
import { GrantCreditsRequest } from '@gandalf/model/grant-credits-request';
import { InvoiceItemListResponse } from '@gandalf/model/invoice-item-list-response';
import { InvoiceDiagnosesRequest } from '@gandalf/model/invoice-diagnoses-request';
import { InvoiceItemDetailResponse } from '@gandalf/model/invoice-item-detail-response';
import { InvoiceItemResponse } from '@gandalf-black/model/invoice-item-response';
import { ReceivePaymentPayerResponse } from '@gandalf/model/receive-payment-payer-response';
import { InvoiceTransferRequest } from '@gandalf/model/invoice-transfer-request';
import { InvoicesForPayerRequest } from '@gandalf-black/model/invoices-for-payer-request';
import { PatientPortionRequest } from '@gandalf/model/patient-portion-request';
import { RemoveInvoiceItemRequest } from '@gandalf/model/remove-invoice-item-request';
import { SendStatementsRequest } from '@gandalf-black/model/send-statements-request';
import { StatementPayerResponse } from '@gandalf/model/statement-payer-response';
import { TransferInvoiceBalanceToPatientRequest } from '@gandalf/model/transfer-invoice-balance-to-patient-request';
import { TransferInvoiceItemsRequest } from '@gandalf/model/transfer-invoice-items-request';
import { TransferPatientCreditRequest } from '@gandalf/model/transfer-patient-credit-request';
import { UpdateInvoiceDateRequest } from '@gandalf/model/update-invoice-date-request';
import { UpdateInvoiceEncounterRequest } from '@gandalf/model/update-invoice-encounter-request';
import { UpdateInvoiceFinanceChargePlanRequest } from '@gandalf/model/update-invoice-finance-charge-plan-request';
import { UpdateInvoiceItemQuantityRequest } from '@gandalf/model/update-invoice-item-quantity-request';
import { UpdateInvoiceItemRequest } from '@gandalf/model/update-invoice-item-request';
import { UpdateInvoiceItemResponsiblePersonListRequest } from '@gandalf/model/update-invoice-item-responsible-person-list-request';
import { UpdateInvoiceLocationRequest } from '@gandalf/model/update-invoice-location-request';
import { UpdatePaymentGroupRequest } from '@gandalf/model/update-payment-group-request';
import { VoidInvoiceRequest } from '@gandalf/model/void-invoice-request';
import { VoidPatientCreditRequest } from '@gandalf/model/void-patient-credit-request';
import { VoidPatientRefundRequest } from '@gandalf/model/void-patient-refund-request';
import { VoidPaymentRequest } from '@gandalf/model/void-payment-request';
import { LegacyAccountingGandalfService } from '@gandalf-black/services';
import { CodeSet, InsuranceCompanyStatus, InvoiceItemType, PayerType, ReferenceDataMasterCategory } from '@gandalf/constants';
import { AccountingCheckIfInvoiceHasDirectPaymentRequest } from '@gandalf/model/accounting-check-if-invoice-has-direct-payment-request';
import { AccountingInvoicePaymentResponse } from '@gandalf/model/accounting-invoice-payment-response';
import { AccountingPaymentPreferencesResponse } from '@gandalf/model/accounting-payment-preferences-response';
import { AdditionalClaimInformationCanadaRequest } from '@gandalf/model/additional-claim-information-canada-request';
import { FinanceChargePlanNameResponse } from '@gandalf/model/finance-charge-plan-name-response';
import { FindAnonymousPaymentInvoicesRequest } from '@gandalf/model/find-anonymous-payment-invoices-request';
import { FindCollectionsPaymentInvoicesRequest } from '@gandalf/model/find-collections-payment-invoices-request';
import { FindInsurancePaymentInvoicesRequest } from '@gandalf/model/find-insurance-payment-invoices-request';
import { FindPatientPaymentInvoicesRequest } from '@gandalf/model/find-patient-payment-invoices-request';
import { InvoiceAppointmentResponse } from '@gandalf/model/invoice-appointment-response';
import { InvoiceDateResponse } from '@gandalf/model/invoice-date-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { LocationTaxResponse } from '@gandalf/model/location-tax-response';
import { ModifierResponse } from '@gandalf/model/modifier-response';
import { PaymentInvoiceListResponse } from '@gandalf/model/payment-invoice-list-response';
import { PaymentTransactionReceiptResponse } from '@gandalf/model/payment-transaction-receipt-response';
import { PersonInsuranceSummaryResponse } from '@gandalf/model/person-insurance-summary-response';
import { PracticeInsuranceCompanySummaryResponse } from '@gandalf/model/practice-insurance-company-summary-response';
import { RefundPatientResponse } from '@gandalf/model/refund-patient-response';
import { VendorSummaryResponse } from '@gandalf/model/vendor-summary-response';
import {
	AccountingGandalfService,
	AccountingPaymentGandalfService,
	InvoiceClaimGandalfService,
	InvoiceGandalfService,
	PatientAccountGandalfService,
	PatientCreditHistoryGandalfService,
	PatientExternalProviderGandalfService,
	PatientInvoiceGandalfService,
	PatientRefundHistoryGandalfService
} from '@gandalf/services';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { URL_ACCOUNTING_ENDPOINTS } from '@shared/constants/url.constants';
import { BusinessNamePipe } from '@shared/pipes/business-name/business-name.pipe';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import { PersonNamePipe } from '@shared/pipes/person-name/person-name.pipe';
import { PrintUtilService } from '@shared/Utils/print-util.service';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaymentTransactionProcessorResponse } from '@gandalf/model/payment-transaction-processor-response';
import { AccountingUpdatePaymentGroupRequest } from '@gandalf/model/accounting-update-payment-group-request';
import { HumanReadableInsuranceRemittanceResponse } from '@gandalf/model/human-readable-insurance-remittance-response';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { StatementInvoiceResponse } from '@gandalf/model/statement-invoice-response';
import { PatientInvoiceDashboardSearchRequest } from '@gandalf/model/patient-invoice-dashboard-search-request';
import { InvoiceDashboardResponse } from '@gandalf/model/invoice-dashboard-response';
import { InvoiceDashboardSearchRequest } from '@gandalf/model/invoice-dashboard-search-request';
import { PaymentManualInvoiceSearchRequest } from '@gandalf/model/payment-manual-invoice-search-request';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { CreateGuestInvoiceRequest } from '@gandalf/model/create-guest-invoice-request';
import { UpdateInvoiceAppointmentRequest } from '@gandalf/model/update-invoice-appointment-request';
import { UpdateInvoiceProviderRequest } from '@gandalf/model/update-invoice-provider-request';
import { PaymentDashboardSearchRequest } from '@gandalf/model/payment-dashboard-search-request';
import { CreateInvoiceRequest } from '@gandalf/model/create-invoice-request';
import { ReceivePaymentPayerRequest } from '@gandalf/model/receive-payment-payer-request';
import { SplitPaymentRequest } from '@gandalf/model/split-payment-request';
import { UpdateAdditionalClaimInformationRequest } from '@gandalf/model/update-additional-claim-information-request';
import { PatientPracticeInsuranceCompanyDropdownResponse } from '../../../patients/core/patient-external-provider/patient-external-provider.service';

export interface FinanceChargePlanForDropdownResponse extends FinanceChargePlanNameResponse {
	label: string;
	value: any;
}

export interface FormattedTaxResponse extends LocationTaxResponse {
	label: string;
	value: number;
}

export interface FormattedCollectionAgencyResponse extends VendorSummaryResponse {
	label: string;
	value: number;
}

export interface FormattedInsuranceResponse extends PersonInsuranceSummaryResponse {
	label: string;
	value: number;
}

export interface FormattedInvoiceDateResponse {
	label: string;
	value: number;
}

export interface FormattedVendorSummaryResponse extends VendorSummaryResponse {
	label: string;
	value: number;
}

export interface FormattedModifierResponse extends ModifierResponse {
	label: string;
	value: number;
}

export interface FormattedInvoiceDetailsItem extends InvoiceItemListResponse {
	formattedDiagnoses: InvoiceItemDiagnosisResponse[][];
	formattedModifiers: string;
}

export interface TableFormattedInvoiceSummary {
	invoice: InvoiceDashboardResponse;
	patientName: string;
	status: string;
	id: number;
}

/* Must match com.hit.core.services.pms.accounting.reports.SendStatementsRequest */
export class LegacySendStatementsRequest {
	invoiceIds: number[];
	printDateText: string;
	dueDateText: string;
	messageText: string;
	formatType: number;
}

export interface FormattedInvoiceAppointmentResponse extends InvoiceAppointmentResponse {
	formattedEmployeeOrProviderOrRole: string;
}

@Injectable({
	providedIn: 'root',
})
export class AccountingService {

	readonly accountingUpliftFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.ACCOUNTING.UPLIFT_ACCOUNTING));

	invoiceDetailsModalInvoice: Subject<InvoiceResponse> = new Subject<InvoiceResponse>();

	constructor(
		public accountingGandalfService: AccountingGandalfService,
		public invoiceGandalfService: InvoiceGandalfService,
		public legacyAccountingGandalfService: LegacyAccountingGandalfService,
		public referenceDataService: ReferenceDataService,
		public datePipe: DatePipe,
		public patientNamePipe: PatientNamePipe,
		private urlService: UrlService,
		private patientExternalProviderGandalfService: PatientExternalProviderGandalfService,
		private personNamePipe: PersonNamePipe,
		private patientCreditHistoryGandalfService: PatientCreditHistoryGandalfService,
		private patientRefundHistoryGandalfService: PatientRefundHistoryGandalfService,
		private businessNamePipe: BusinessNamePipe,
		private accountingPaymentGandalfService: AccountingPaymentGandalfService,
		private printUtilService: PrintUtilService,
		private toasterService: ToasterService,
		private patientAccountGandalfService: PatientAccountGandalfService,
		private patientInvoiceGandalfService: PatientInvoiceGandalfService,
		private invoiceClaimGandalfService: InvoiceClaimGandalfService,
		private gandalfTheGreyService: GandalfTheGreyService,
	) {
	}

	findActiveFinanceChargePlansForDropdown(): Observable<FinanceChargePlanForDropdownResponse[]> {
		return this.accountingGandalfService.findActiveFinanceChargePlansForDropdown().pipe(
			map(financeChargePlans => financeChargePlans.map(financeChargePlan => this.formatFinanceChargePlanForDropdown(financeChargePlan))),
		);
	}

	formatFinanceChargePlanForDropdown(fcp: FinanceChargePlanNameResponse): FinanceChargePlanForDropdownResponse {
		const formattedResponse = fcp as FinanceChargePlanForDropdownResponse;
		formattedResponse.label = fcp.name;
		formattedResponse.value = fcp.id;
		return formattedResponse;
	}

	/* istanbul ignore next: gandalf */
	getFinanceChargePlanById(financeChargePlanId: number): Observable<FinanceChargePlanNameResponse> {
		return this.accountingGandalfService.getFinanceChargePlanById(financeChargePlanId);
	}

	updateInvoiceFinanceChargePlan(request: UpdateInvoiceFinanceChargePlanRequest): Observable<InvoiceResponse | void> {
		if (this.accountingUpliftFeatureOn) {
			return this.accountingGandalfService.updateInvoiceFinanceChargePlan(request);
		} else {
			return this.legacyAccountingGandalfService.updateInvoiceFinanceChargePlan(request);
		}
	}

	createNewInvoice(request: CreateInvoiceRequest) {
		if (this.accountingUpliftFeatureOn) {
			const serviceName = 'Accounting';
			const endpointName = 'createInvoice';
			return this.gandalfTheGreyService.execute(serviceName, endpointName, request).pipe(
				map(id => _assign(new InvoiceResponse(), {id})),
			);
		} else {
			return this.legacyAccountingGandalfService.createInvoice(request);
		}
	}

	createGuestInvoice(request: CreateGuestInvoiceRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.createGuestInvoice(request);
		} else {
			return this.legacyAccountingGandalfService.createGuestInvoice(request);
		}
	}

	findPaymentsByPatient(patientId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.patientAccountGandalfService.findPaymentsByPatient(patientId).pipe(
				map(payments => SortingService.sortBy(payments, ['paymentDate', 'paymentId'], ['desc', 'desc'])),
			);
		} else {
			return this.legacyAccountingGandalfService.findPaymentsByPatient(patientId).pipe(
				map(payments => SortingService.sortBy(payments, ['paymentDate', 'paymentId'], ['desc', 'desc'])),
			);
		}
	}

	@ShowSavedSuccessToast()
	refundPatient(request: CreateRefundPatientRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.patientAccountGandalfService.refundPatient(request);
		} else {
			return this.legacyAccountingGandalfService.refundPatient(request);
		}
	}

	transferPatientCredit(request: TransferPatientCreditRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.patientAccountGandalfService.transferPatientCredit(request);
		} else {
			return this.legacyAccountingGandalfService.transferPatientCredit(request);
		}
	}

	creditPatient(request: CreatePatientCreditRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.patientAccountGandalfService.creditPatient(request);
		} else {
			return this.legacyAccountingGandalfService.creditPatient(request);
		}
	}

	findNonCanceledEncountersByPatientId(patientId: number) {
		return this.accountingGandalfService.findNonCanceledEncountersByPatientIdForTable(patientId).pipe(
			map(encounters => SortingService.sortBy(encounters, ['encounterDate', 'id'], ['desc', 'desc'])),
		);
	}

	findTaxesByPracticeLocationId(practiceLocationId: number): Observable<FormattedTaxResponse[]> {
		return this.accountingGandalfService.findTaxesByPracticeLocationId(practiceLocationId).pipe(
			map(taxLists => taxLists.map(tax => this.formatTaxForDropdown(tax))),
		);
	}

	formatTaxForDropdown(tax: LocationTaxResponse): FormattedTaxResponse {
		const formattedTax = tax as FormattedTaxResponse;
		formattedTax.label = tax.name;
		formattedTax.value = tax.id;
		return formattedTax;
	}

	addInvoiceItemAdhoc(request: AddAdhocInvoiceItemRequest): Observable<InvoiceResponse | void> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.addInvoiceItemAdhoc(request);
		} else {
			return this.legacyAccountingGandalfService.addInvoiceItemAdhoc(request);
		}
	}

	transferInvoice(request: InvoiceTransferRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.transferInvoice(request);
		} else {
			return this.legacyAccountingGandalfService.transferInvoice(request);
		}
	}

	transferInvoiceToPatient(request: TransferInvoiceBalanceToPatientRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.transferInvoiceBalanceToPatient(request);
		} else {
			return this.legacyAccountingGandalfService.transferInvoiceToPatient(request);
		}
	}

	updateInvoiceEncounter(request: UpdateInvoiceEncounterRequest): Observable<void | InvoiceResponse> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.updateInvoiceEncounter(request);
		} else {
			return this.legacyAccountingGandalfService.updateInvoiceEncounter(request);
		}
	}

	/* istanbul ignore next: gandalf */
	unassignInvoiceEncounter(invoiceId: number) {
		return this.invoiceGandalfService.unassignInvoiceEncounter(invoiceId);
	}

	voidPayment(request: VoidPaymentRequest) {
		if (this.accountingUpliftFeatureOn) {
			const serviceName = 'Accounting';
			const endpointName = 'voidPayment';
			return this.gandalfTheGreyService.execute(serviceName, endpointName, request);
		} else {
			return this.legacyAccountingGandalfService.voidPayment(request);
		}
	}

	voidInvoice(request: VoidInvoiceRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.gandalfTheGreyService.execute('Accounting', 'voidInvoice', request);
		} else {
			return this.legacyAccountingGandalfService.voidInvoice(request);
		}
	}

	updateInvoiceProvider(request: UpdateInvoiceProviderRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.updateInvoiceProvider(request);
		} else {
			return this.legacyAccountingGandalfService.updateInvoiceProvider(request);
		}
	}

	/* istanbul ignore next: gandalf */
	unassignInvoiceProvider(invoiceId: number) {
		return this.invoiceGandalfService.unassignInvoiceProvider(invoiceId);
	}

	/* istanbul ignore next: gandalf */
	findDiscountReasonsForDropdown() {
		return this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.ACCOUNTING_DISCOUNT_REASONS.value);
	}

	grantInvoiceCredits(request: GrantCreditsRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.grantInvoiceCredits(request);
		} else {
			return this.legacyAccountingGandalfService.grantInvoiceCredits(request);
		}
	}

	removeInvoiceItem(request: RemoveInvoiceItemRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.removeInvoiceItem(request);
		} else {
			return this.legacyAccountingGandalfService.removeInvoiceItem(request);
		}
	}

	@ShowSavedSuccessToast()
	updateInvoiceDate(request: UpdateInvoiceDateRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.accountingGandalfService.updateInvoiceDate(request);
		} else {
			return this.legacyAccountingGandalfService.updateInvoiceDate(request);
		}
	}

	getInvoiceById(invoiceId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.getInvoiceById(invoiceId);
		} else {
			return this.legacyAccountingGandalfService.getInvoiceById(invoiceId);
		}
	}

	addDiscountItems(addDiscountItemsRequest: AddDiscountItemsRequest): Observable<void | InvoiceResponse> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.addDiscountItems(addDiscountItemsRequest);
		} else {
			return this.legacyAccountingGandalfService.addDiscountItems(addDiscountItemsRequest);
		}
	}

	updateInvoiceLocation(request: UpdateInvoiceLocationRequest) {
		if (this.accountingUpliftFeatureOn) {
			const serviceName = 'Accounting';
			const endpointName = 'updateInvoiceLocation';
			return this.gandalfTheGreyService.execute(serviceName, endpointName, request);
		} else {
			return this.legacyAccountingGandalfService.updateInvoiceLocation(request);
		}
	}

	getActiveAlphabetizedProductCategories() {
		return this.accountingGandalfService.getActiveProductCategories().pipe(map(items => SortingService.sortBy(items, ['name'])));
	}

	addDiscount(request: AddDiscountRequest): Observable<void | InvoiceResponse> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.addDiscount(request);
		} else {
			return this.legacyAccountingGandalfService.addDiscount(request);
		}
	}

	updateInvoiceItemResponsiblePersonList(request: UpdateInvoiceItemResponsiblePersonListRequest): Observable<InvoiceResponse | void> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.updateItemResponsiblePersons(request);
		} else {
			return this.legacyAccountingGandalfService.updateItemResponsiblePersons(request);
		}
	}

	findAvailableDiagnosesByInvoiceId(invoiceId: number) {
		return this.accountingGandalfService.findMasterPersonDiagnosesByInvoiceId(invoiceId).pipe(
			map(diagnoses => SortingService.sortBy(diagnoses, [personDiagnosis =>
				this.getDiagnosisCodeSetSortOrder(personDiagnosis.practiceDiagnosis.codeSet.value), 'diagnosisDate'], ['asc', 'asc']),
			),
		);
	}

	getDiagnosisCodeSetSortOrder(masterCodeSetId: number) {
		const codeSet = EnumUtil.findEnumByValue(masterCodeSetId, CodeSet);
		if (EnumUtil.equals(codeSet, CodeSet.ICD10)) {
			return 1;
		} else if (EnumUtil.equals(codeSet, CodeSet.ICD9)) {
			return 2;
		} else if (EnumUtil.equals(codeSet, CodeSet.SNOMED)) {
			return 3;
		} else {
			return 4;
		}
	}

	updateInvoiceDiagnosis(request: InvoiceDiagnosesRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.updateInvoiceDiagnoses(request);
		} else {
			return this.legacyAccountingGandalfService.updateInvoiceDiagnoses(request);
		}
	}

	transferInvoiceItems(request: TransferInvoiceItemsRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.transferInvoiceItems(request);
		} else {
			return this.legacyAccountingGandalfService.transferInvoiceItems(request);
		}
	}

	addPatientPortion(request: PatientPortionRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.addPatientPortion(request).pipe(
				map(invoiceIds => invoiceIds.map(invoiceId => {
					const invoiceResponse = new InvoiceResponse();
					invoiceResponse.id = invoiceId;
					return invoiceResponse;
				})),
			);
		} else {
			return this.legacyAccountingGandalfService.addPatientPortion(request);
		}
	}

	splitInvoiceItemPayment(request: SplitPaymentRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.splitInvoiceItemPayment(request);
		} else {
			return this.legacyAccountingGandalfService.splitInvoiceItemPayment(request);
		}
	}

	@ShowSavedSuccessToast()
	addInvoiceItem(request: AddInvoiceItemRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.addInvoiceItem(request);
		} else {
			return this.legacyAccountingGandalfService.addInvoiceItem(request);
		}
	}

	/* istanbul ignore next: gandalf */
	findInsuranceByPatientId(patientId: number) {
		return this.accountingGandalfService.findInsuranceByPatientId(patientId);
	}

	findPatientPendingInvoicesForDropdown(patientId: number): Observable<FormattedInvoiceDateResponse[]> {
		return this.invoiceGandalfService.findPendingPatientInvoicesByPatientId(patientId).pipe(
			map(items => SortingService.sortBy(items, ['date'], ['asc'])),
			map(invoices => invoices.map(invoice => this.formatPatientPendingInvoicesForDropdown(invoice))),
		);
	}

	formatPatientPendingInvoicesForDropdown(invoice: InvoiceDateResponse): FormattedInvoiceDateResponse {
		const formattedResponse = {
			label: `${invoice.id} (${this.datePipe.transform(invoice.date, DATE_FORMATS.MM_DD_YYYY)})`,
			value: invoice.id,
		};
		return formattedResponse;
	}

	/* istanbul ignore next: gandalf */
	findActiveTransferReasonsForDropdown() {
		return this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.ACCOUNTING_TRANSFER_REASONS.value);
	}

	/* istanbul ignore next: gandalf */
	findActiveWriteoffReasonsForDropdown() {
		return this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.ACCOUNTING_WRITEOFF_REASONS.value);
	}

	/* istanbul ignore next: gandalf */
	findAllTransferReasonsForDropdown() {
		return this.referenceDataService.getReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.ACCOUNTING_TRANSFER_REASONS.value, true);
	}

	/* istanbul ignore next: gandalf */
	findAllWriteoffReasonsForDropdown() {
		return this.referenceDataService.getReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.ACCOUNTING_WRITEOFF_REASONS.value, true);
	}

	findCollectionAgencies() {
		return this.accountingGandalfService.findCollectionAgencies().pipe(
			map(collectionAgencies => collectionAgencies.map(collectionAgency => this.formatCollectionAgencyForDropdown(collectionAgency))),
			map(collectionAgencies => SortingService.sortBy(collectionAgencies, ['name'])),
		);
	}

	formatCollectionAgencyForDropdown(collectionAgency: VendorSummaryResponse): FormattedCollectionAgencyResponse {
		const formattedAgency = collectionAgency as FormattedCollectionAgencyResponse;
		formattedAgency.label = this.businessNamePipe.transform(collectionAgency.status, collectionAgency.name);
		formattedAgency.value = collectionAgency.id;
		return formattedAgency;
	}

	/* istanbul ignore next: gandalf */
	findPaymentPreferences(): Observable<AccountingPaymentPreferencesResponse> {
		return this.accountingGandalfService.findPaymentPreferences();
	}

	findActiveInsurancesByPatientId(patientId: number): Observable<FormattedInsuranceResponse[]> {
		return this.accountingGandalfService.findActiveInsuranceByPatientId(patientId).pipe(
			map(insurances => insurances.map(insurance => this.formatInsuranceForDropdown(insurance))),
		);
	}

	findPracticeInsuranceCompaniesForDropdown() {
		return this.patientExternalProviderGandalfService.findPracticeInsuranceCompanies().pipe(
			map(companies => SortingService.sortBy(companies, ['status', 'name', 'id'], ['asc', 'asc', 'asc'])),
			map(companies => companies.map(company => this.formatInsuranceCompanyForDropdown(company))),
		);
	}

	formatInsuranceCompanyForDropdown(insuranceCompany: PracticeInsuranceCompanySummaryResponse): PatientPracticeInsuranceCompanyDropdownResponse {
		const practiceInsuranceCompanyDropdownResponse = insuranceCompany as PatientPracticeInsuranceCompanyDropdownResponse;
		if (EnumUtil.equals(insuranceCompany.status, InsuranceCompanyStatus.INACTIVE)) {
			practiceInsuranceCompanyDropdownResponse.label = `(Inactive) ${insuranceCompany.name}`;
		} else {
			practiceInsuranceCompanyDropdownResponse.label = insuranceCompany.name;
		}
		practiceInsuranceCompanyDropdownResponse.value = insuranceCompany.id;

		return practiceInsuranceCompanyDropdownResponse;
	}

	findActiveInsurancesByPatientIdWithPracticeInsuranceId(patientId: number): Observable<FormattedInsuranceResponse[]> {
		return this.accountingGandalfService.findActiveInsuranceByPatientId(patientId).pipe(
			map(insurances => insurances.map(insurance => this.formatInsuranceForDropdown(insurance))),
		);
	}

	formatInsuranceForDropdown(insurance: PersonInsuranceSummaryResponse): FormattedInsuranceResponse {
		const formattedInsurance = insurance as FormattedInsuranceResponse;
		formattedInsurance.label = `${insurance.name} (${insurance.priority.value} ${insurance.type.value})`;
		formattedInsurance.value = insurance.id;
		return formattedInsurance;
	}

	getInvoiceItemDetail(invoiceItemId: number): Observable<InvoiceItemDetailResponse> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.getInvoiceItemDetailById(invoiceItemId).pipe(
				map(invoiceItem => {
					invoiceItem.additionalDiagnoses = SortingService.sortBy(
						invoiceItem.additionalDiagnoses,
						[personDiagnosis => this.getDiagnosisCodeSetSortOrder(personDiagnosis.practiceDiagnosis.masterCodeSetId), 'diagnosisDate', 'personDiagnosisId'],
						['asc', 'asc', 'asc'],
					);
					invoiceItem.itemAdjustments = SortingService.sortBy(invoiceItem.itemAdjustments, ['id'], ['asc']);
					return invoiceItem;
				}),
			);
		} else {
			return this.legacyAccountingGandalfService.findInvoiceItemDetailById(invoiceItemId).pipe(
				map(invoiceItemDetailResponse => {
					invoiceItemDetailResponse.additionalDiagnoses = SortingService.sortBy(invoiceItemDetailResponse.additionalDiagnoses, [personDiagnosis =>
						this.getDiagnosisCodeSetSortOrder(personDiagnosis.practiceDiagnosis.masterCodeSetId), 'diagnosisDate'], ['asc', 'asc'],
					);
					return invoiceItemDetailResponse;
				}),
			);
		}
	}

	/* istanbul ignore next: gandalf */
	findInvoiceItemById(id: number): Observable<InvoiceItemResponse> {
		return this.legacyAccountingGandalfService.findInvoiceItemById(id);
	}

	findAlternateFacilities(): Observable<FormattedVendorSummaryResponse[]> {
		return this.accountingGandalfService.findAlternateServiceLocations().pipe(
			map(vendors => vendors.map(vendor => this.formatVendorForDropdown(vendor))),
			map(vendors => SortingService.sortBy(vendors, ['status', 'name'])),
		);
	}

	formatVendorForDropdown(vendor: VendorSummaryResponse): FormattedVendorSummaryResponse {
		const formattedVendor = vendor as FormattedVendorSummaryResponse;
		formattedVendor.label = this.businessNamePipe.transform(vendor.status, vendor.name);
		formattedVendor.value = vendor.id;
		return formattedVendor;
	}

	/* istanbul ignore next: gandalf */
	getApprovedOutstandingInvoicesForPayer(locationId: number, entityId: number, payerType: PayerType) {
		const request = new InvoicesForPayerRequest();
		request.locationId = locationId;
		request.entityId = entityId;
		request.payerType = payerType;
		return this.legacyAccountingGandalfService.getApprovedOutstandingInvoicesForPayer(request);
	}

	@ShowSavedSuccessToast()
	savePayments(request: CreatePaymentGroupRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.accountingGandalfService.savePayments(request);
		} else {
			return this.legacyAccountingGandalfService.savePayments(request);
		}
	}

	@ShowSavedSuccessToast()
	saveExistingPayments(request: UpdatePaymentGroupRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.accountingGandalfService.updatePayments(request);
		} else {
			return this.legacyAccountingGandalfService.updatePayments(request);
		}
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	applyPayments(request: CreatePaymentGroupRequest) {
		return this.legacyAccountingGandalfService.applyPayments(request);
	}

	/* istanbul ignore next: gandalf */
	applyInsurancePayments(request: CreatePaymentGroupRequest) {
		return this.legacyAccountingGandalfService.applyInsurancePayments(request).pipe(
			tap(() => this.toasterService.showSavedSuccess({
				title: 'Payment Processing',
				content: 'Your payment is processing. When your payment is finished processing, you will receive a message in your Inbox with the payment status.',
				timeOut: 0,
			})),
		);
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	applyExistingPayments(request: UpdatePaymentGroupRequest) {
		return this.legacyAccountingGandalfService.applyExistingPayments(request);
	}

	/* istanbul ignore next: gandalf */
	applyExistingInsurancePayments(request: UpdatePaymentGroupRequest) {
		return this.legacyAccountingGandalfService.applyExistingInsurancePayments(request).pipe(
			tap(() => this.toasterService.showSavedSuccess({
				title: 'Payment Processing',
				content: 'Your payment is processing. When your payment is finished processing, you will receive a message in your Inbox with the payment status.',
				timeOut: 0,
			})),
		);
	}

	searchPaymentGroups(request: PaymentDashboardSearchRequest) {
		if(this.accountingUpliftFeatureOn) {
			return this.accountingPaymentGandalfService.findByPaymentDashboardSearch(request).pipe(
				map(paymentGroups => SortingService.sortBy(paymentGroups, ['paymentDate', 'paymentGroupId'], ['desc', 'desc'])),
			);
		} else {
			return this.legacyAccountingGandalfService.searchPaymentGroups(request);
		}
	}

	@ShowSavedSuccessToast()
	activatePaymentGroup(paymentGroupId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.accountingPaymentGandalfService.activatePaymentGroup(paymentGroupId);
		} else {
			return this.legacyAccountingGandalfService.activatePaymentGroup(paymentGroupId);
		}
	}

	@ShowSavedSuccessToast()
	deactivatePaymentGroup(paymentGroupId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.accountingPaymentGandalfService.deactivatePaymentGroup(paymentGroupId);
		} else {
			return this.legacyAccountingGandalfService.deactivatePaymentGroup(paymentGroupId);
		}
	}

	findPracticeModifiers(): Observable<FormattedModifierResponse[]> {
		return this.accountingGandalfService.findPracticeModifiers().pipe(
			map(modifiers => modifiers.map(modifier => this.formatModifierForDropdown(modifier))),
			map(modifiers => SortingService.sortBy(modifiers, ['code'])),
		);
	}

	getModifierByInvoiceItemId(invoiceItemId: number): Observable<FormattedModifierResponse> {
		return this.accountingGandalfService.getModifierByInvoiceItemId(invoiceItemId).pipe(
			map(modifier => this.formatModifierForDropdown(modifier)),
		);
	}

	/* istanbul ignore next: gandalf */
	getActivePracticeLocationsExternalCredentials() {
		return this.accountingGandalfService.getActivePracticeLocationsExternalCredentials();
	}

	getPaymentGroupById(paymentGroupId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.accountingPaymentGandalfService.getPaymentGroupById(paymentGroupId)
				.pipe(tap(paymentGroup => {
					paymentGroup.payments = SortingService.sortBy(paymentGroup.payments, ['invoice.invoiceAge', 'paymentId'], ['desc', 'desc']);
				}));
		} else {
			return this.legacyAccountingGandalfService.getPaymentGroupById(paymentGroupId)
				.pipe(tap(paymentGroup => {
					paymentGroup.payments = SortingService.sortBy(paymentGroup.payments, ['invoice.invoiceAge'], ['desc']);
				}));
		}
	}

	formatModifierForDropdown(modifier: ModifierResponse): FormattedModifierResponse {
		const formattedModifier = modifier as FormattedModifierResponse;
		formattedModifier.value = modifier.id;
		formattedModifier.label = `${modifier.code} : ${modifier.description}`;
		return formattedModifier;
	}

	findAdditionalClaimInformationByInvoiceId(invoiceId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceClaimGandalfService.findAdditionalClaimInformationByInvoiceId(invoiceId);
		} else {
			return this.legacyAccountingGandalfService.findAdditionalClaimInformationByInvoiceId(invoiceId);
		}
	}

	/* istanbul ignore next: gandalf */
	findAdditionalClaimInformationCanadaByInvoiceId(invoiceId: number) {
		return this.accountingGandalfService.findAdditionalClaimInformationCanadaByInvoiceId(invoiceId);
	}

	@ShowSavedSuccessToast()
	updateAdditionalClaimInformation(request: UpdateAdditionalClaimInformationRequest) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceClaimGandalfService.updateAdditionalClaimInformation(request);
		} else {
			return this.legacyAccountingGandalfService.updateAdditionalClaimInformation(request);
		}
	}

	@ShowSavedSuccessToast()
	/* istanbul ignore next: gandalf */
	updateAdditionalClaimInformationCanada(request: AdditionalClaimInformationCanadaRequest) {
		return this.accountingGandalfService.updateAdditionalClaimInformationCanada(request);
	}

	@ShowSavedSuccessToast()
	updateInvoiceItemDetail(request: UpdateInvoiceItemRequest): Observable<void | InvoiceResponse> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.updateInvoiceItem(request);
		} else {
			return this.legacyAccountingGandalfService.updateInvoiceItemDetail(request);
		}
	}

	@ShowSavedSuccessToast()
	markInvoicePaid(invoiceId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.markInvoicePaid(invoiceId);
		} else {
			return this.legacyAccountingGandalfService.markInvoicePaid(invoiceId);
		}
	}

	@ShowSavedSuccessToast()
	markInvoiceUnpaid(invoiceId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.markInvoiceUnpaid(invoiceId);
		} else {
			return this.legacyAccountingGandalfService.markInvoiceUnpaid(invoiceId);
		}
	}

	@ShowSavedSuccessToast()
	putInvoiceOnHold(invoiceId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.putInvoiceOnHold(invoiceId);
		} else {
			return this.legacyAccountingGandalfService.putInvoiceOnHold(invoiceId);
		}
	}

	@ShowSavedSuccessToast()
	approveInvoice(request: ApproveInvoiceRequest): Observable<void | InvoiceResponse> {
		if (this.accountingUpliftFeatureOn) {
			const serviceName = 'Accounting';
			const endpointName = 'approveInvoice';
			return this.gandalfTheGreyService.execute(serviceName, endpointName, request);
		} else {
			return this.legacyAccountingGandalfService.approveInvoice(request);
		}
	}

	@ShowSavedSuccessToast()
	unapproveInvoice(invoiceId: number): Observable<void | InvoiceResponse> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.unapproveInvoice(invoiceId);
		} else {
			return this.legacyAccountingGandalfService.unapproveInvoice(invoiceId);
		}
	}

	findInvoiceStatementsByInvoiceId(invoiceId: number): Observable<StatementInvoiceResponse[]> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.findInvoiceStatementsByInvoiceId(invoiceId).pipe(
				map(statementInvoice => SortingService.sortBy(statementInvoice, ['printDate', 'id'], ['desc', 'asc'])),
			);
		} else {
			return this.legacyAccountingGandalfService.findInvoiceStatementsByInvoiceId(invoiceId);
		}
	}

	findPayerStatementsByPersonId(personId: number): Observable<StatementPayerResponse[]> {
		if (this.accountingUpliftFeatureOn) {
			return this.patientAccountGandalfService.findPayerStatementsByPersonId(personId).pipe(
				map(statementPayer => SortingService.sortBy(statementPayer, ['printDate', 'id'], ['desc', 'desc'])),
			);
		} else {
			return this.legacyAccountingGandalfService.findPayerStatementsByPersonId(personId).pipe(map(
				statements => SortingService.sortBy(statements, ['printDate', 'id'], ['desc', 'desc']),
			));
		}
	}

	/* istanbul ignore next: gandalf */
	findInvoicePaymentHistory(invoiceId: number): Observable<AccountingInvoicePaymentResponse[]> {
		return this.accountingPaymentGandalfService.findNonCanceledByInvoiceId(invoiceId);
	}

	/* istanbul ignore next: gandalf */
	findReceiptsRelatedToPaymentTransaction(paymentTransactionId: number): Observable<PaymentTransactionReceiptResponse[]> {
		return this.accountingPaymentGandalfService.findReceiptsRelatedToPaymentTransaction(paymentTransactionId);
	}

	printReceiptsRelatedToPaymentTransaction(paymentTransactionId: number) {
		this.findReceiptsRelatedToPaymentTransaction(paymentTransactionId).subscribe(
			(paymentTransactionReceiptResponses: PaymentTransactionReceiptResponse[]) => {
				this.printUtilService.printFormattedText(...paymentTransactionReceiptResponses.map(receipt => receipt.customerReceiptText));
			});
	}

	findReceivePaymentPayers(locationId: number, payerType: PayerType): Observable<ReceivePaymentPayerResponse[]> {
		const request = new ReceivePaymentPayerRequest();
		request.practiceLocationId = locationId;
		request.payerType = payerType;
		if (this.accountingUpliftFeatureOn) {
			return this.accountingPaymentGandalfService.findReceivePaymentPayers(request).pipe(
				map(payers => SortingService.sortBy(payers, ['name', 'id'], ['asc', 'asc'])),
			);
		} else {
			return this.legacyAccountingGandalfService.findPayersForApprovedOutstandingInvoices(request).pipe(
				map(payers => SortingService.sortBy(payers, ['name', 'id'])),
			);
		}
	}

	assignInvoiceFeeSchedule(invoiceId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.gandalfTheGreyService.execute('Accounting', 'assignInvoiceFeeSchedule', {id: invoiceId});
		} else {
			return this.legacyAccountingGandalfService.assignInvoiceFeeSchedule(invoiceId);
		}
	}

	removeInvoiceFeeSchedule(invoiceId: number) {
		if (this.accountingUpliftFeatureOn) {
			return this.gandalfTheGreyService.execute('Accounting', 'removeInvoiceFeeSchedule', {id: invoiceId});
		} else {
			return this.legacyAccountingGandalfService.removeInvoiceFeeSchedule(invoiceId);
		}
	}

	findInvoiceDetailsItemsByInvoiceId(invoiceId: number): Observable<FormattedInvoiceDetailsItem[]> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.findInvoiceItemsByInvoiceId(invoiceId).pipe(
				map(invoiceItems => invoiceItems.map(invoiceItem => this.formatInvoiceItemListResponse(invoiceItem))),
				map(invoiceItems => SortingService.sortBy(invoiceItems, [item => this.getInvoiceItemTypeOrder(item.type), 'code', 'invoiceItemId'], ['asc', 'asc', 'asc'])),
			);
		} else {
			return this.legacyAccountingGandalfService.findInvoiceDetailsItemsByInvoiceId(invoiceId).pipe(
				map(invoiceItems => invoiceItems.map(invoiceItem => this.formatInvoiceItemListResponse(invoiceItem))),
			);
		}
	}

	getInvoiceItemTypeOrder(invoiceItemType: InvoiceItemType) {
		switch (invoiceItemType?.value) {
			case InvoiceItemType.SERVICE.value:
				return 1;
			case InvoiceItemType.PRODUCT.value:
				return 2;
			case InvoiceItemType.AD_HOC.value:
				return 3;
			case InvoiceItemType.INFO.value:
				return 4;
			default:
				return 5;
		}
	}

	formatInvoiceItemListResponse(invoiceItem: InvoiceItemListResponse): FormattedInvoiceDetailsItem {
		const formattedInvoiceItem = invoiceItem as FormattedInvoiceDetailsItem;
		const itemModifiers = _map(invoiceItem.additionalModifiers, 'code');

		if (this.accountingUpliftFeatureOn) {
			invoiceItem.additionalDiagnoses = SortingService.sortBy(invoiceItem.additionalDiagnoses, ['isPrimary', 'id'], ['desc', 'asc']);
			invoiceItem.itemAdjustments = SortingService.sortBy(invoiceItem.itemAdjustments, ['id'], ['asc']);
			invoiceItem.itemTaxes = SortingService.sortBy(invoiceItem.itemTaxes, ['taxId'], ['asc']);
		}

		formattedInvoiceItem.formattedDiagnoses = _chunk(invoiceItem.additionalDiagnoses, 4);
		formattedInvoiceItem.formattedModifiers = itemModifiers.join(', ');

		return formattedInvoiceItem;
	}

	setDiagnosisCode(diagnosis: InvoiceItemDiagnosisResponse): string {
		let code = diagnosis.code;
		if (diagnosis.isPrimary) {
			code = code.concat('<i class="fa fa-star margin-left-xs"></i>');
		}
		return code;
	}

	searchInvoices(invoiceSearchRequest: InvoiceDashboardSearchRequest): Observable<TableFormattedInvoiceSummary[]> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.findByInvoiceDashboardSearch(invoiceSearchRequest).pipe(
				map(invoices => SortingService.sortBy(invoices, ['invoiceDate', 'id'], ['asc', 'asc'])),
				map(invoices => this.formatForTable(invoices)),
			);
		} else {
			return this.legacyAccountingGandalfService.searchInvoices(invoiceSearchRequest).pipe(
				map(dataArray => SortingService.sortBy(dataArray, ['invoiceDate'], ['asc'])),
				map(dataArray => this.formatForTable(dataArray)),
			);
		}
	}

	findByPatientInvoiceDashboardSearch(invoiceSearchRequest: PatientInvoiceDashboardSearchRequest): Observable<TableFormattedInvoiceSummary[]> {
		return this.patientInvoiceGandalfService.findByPatientInvoiceDashboardSearch(invoiceSearchRequest).pipe(
			map(invoices => SortingService.sortBy(invoices, ['invoiceDate', 'id'], ['asc', 'asc'])),
			map(invoices => this.formatForTable(invoices)),
		);
	}

	searchInvoicesForManualSelection(invoiceSearchRequest: PaymentManualInvoiceSearchRequest): Observable<TableFormattedInvoiceSummary[]> {
		if (this.accountingUpliftFeatureOn) {
			return this.accountingPaymentGandalfService.findByPaymentManualInvoiceSearch(invoiceSearchRequest).pipe(
				map(invoices => SortingService.sortBy(invoices, ['invoiceAge', 'id'], ['desc', 'asc'])),
				map(invoices => this.formatForTable(invoices)),
			);
		} else {
			return this.legacyAccountingGandalfService.searchInvoicesForManualSelection(invoiceSearchRequest).pipe(
				map(dataArray => SortingService.sortBy(dataArray, ['invoiceAge'], ['desc'])),
				map(dataArray => this.formatForTable(dataArray)),
			);
		}
	}

	formatForTable(invoiceSummaries: InvoiceDashboardResponse[]): TableFormattedInvoiceSummary[] {
		return invoiceSummaries.map(invoice => ({
			invoice,
			id: invoice.id,
			patientName: this.patientNamePipe.transform(invoice.patientName),
			status: invoice.status.label,
		}));
	}

	/* istanbul ignore next: gandalf */
	sendStatements(sendStatementsRequest: SendStatementsRequest) {
		const legacyRequest: LegacySendStatementsRequest = new LegacySendStatementsRequest();
		legacyRequest.invoiceIds = sendStatementsRequest.invoiceIds;
		legacyRequest.printDateText = this.datePipe.transform(sendStatementsRequest.printStatementRequest.printDate, DATE_FORMATS.MM_DD_YYYY);
		legacyRequest.dueDateText = sendStatementsRequest.printStatementRequest.dueDateText;
		legacyRequest.messageText = sendStatementsRequest.printStatementRequest.messageText;
		legacyRequest.formatType = sendStatementsRequest.printStatementRequest.format.value;
		return this.urlService.makeStrutsRequest(URL_ACCOUNTING_ENDPOINTS.SEND_STATEMENTS, legacyRequest, null, null);
	}

	/* istanbul ignore next: gandalf */
	findPracticeStatementProcessorId() {
		return this.accountingGandalfService.findPracticeStatementsProcessorId();
	}

	updateInvoiceItemQuantity(request: UpdateInvoiceItemQuantityRequest): Observable<void | InvoiceResponse> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.updateInvoiceItemQuantity(request);
		} else {
			return this.legacyAccountingGandalfService.updateInvoiceItemQuantity(request);
		}
	}

	/* istanbul ignore next: gandalf */
	findActivePatientAppointments(patientId: number): Observable<FormattedInvoiceAppointmentResponse[]> {
		return this.invoiceGandalfService.findActiveAppointmentsByPatientId(patientId).pipe(
			map(appointments => appointments.map(appointment => this.formatAppointmentForTable(appointment))),
		);
	}

	formatAppointmentForTable(appointmentResponse: InvoiceAppointmentResponse): FormattedInvoiceAppointmentResponse {
		const formattedResponse = appointmentResponse as FormattedInvoiceAppointmentResponse;

		if (appointmentResponse.providerName) {
			formattedResponse.formattedEmployeeOrProviderOrRole = this.personNamePipe.transform(appointmentResponse.providerName);
		} else if (appointmentResponse.employeeName) {
			formattedResponse.formattedEmployeeOrProviderOrRole = this.personNamePipe.transform(appointmentResponse.employeeName);
		} else {
			formattedResponse.formattedEmployeeOrProviderOrRole = appointmentResponse.roleName;
		}

		return formattedResponse;
	}

	changeInvoiceAppointment(request: UpdateInvoiceAppointmentRequest): Observable<void | InvoiceResponse> {
		if (this.accountingUpliftFeatureOn) {
			return this.invoiceGandalfService.updateInvoiceAppointment(request);
		} else {
			return this.legacyAccountingGandalfService.changeInvoiceAppointment(request);
		}
	}

	/* istanbul ignore next: gandalf */
	unassignInvoiceAppointment(invoiceId: number) {
		return this.invoiceGandalfService.unassignInvoiceAppointment(invoiceId);
	}

	/* istanbul ignore next: gandalf */
	findCreditsForPatient(patientId: number) {
		return this.patientCreditHistoryGandalfService.findCreditsForPatient(patientId).pipe(
			map(payments => SortingService.sortBy(payments, ['creditDate', 'creditId'], ['desc', 'desc'])),
		);
	}

	/* istanbul ignore next: gandalf */
	findRefundsForPatient(patientId: number): Observable<RefundPatientResponse[]> {
		return this.patientRefundHistoryGandalfService.findPatientRefunds(patientId).pipe(
			map(payments => SortingService.sortBy(payments, ['date', 'refundId'], ['desc', 'desc'])),
		);
	}

	voidPatientCredit(request: VoidPatientCreditRequest): Observable<void> {
		if (this.accountingUpliftFeatureOn) {
			return this.patientAccountGandalfService.voidPatientCredit(request);
		} else {
			return this.legacyAccountingGandalfService.voidPatientCredit(request);
		}
	}

	voidRefund(patientId: number, reason: string, refundId: number) {
		const voidPatientRefundRequest = new VoidPatientRefundRequest();
		voidPatientRefundRequest.patientId = patientId;
		voidPatientRefundRequest.reason = reason;
		voidPatientRefundRequest.refundId = refundId;

		if (this.accountingUpliftFeatureOn) {
			return this.gandalfTheGreyService.execute('Accounting', 'voidPatientRefund', voidPatientRefundRequest);
		} else {
			return this.legacyAccountingGandalfService.voidPatientRefund(voidPatientRefundRequest);
		}
	}

	/* istanbul ignore next: gandalf */
	checkIfInvoiceHasDirectPayment(invoiceIds: number[]) {
		return this.invoiceGandalfService.checkIfInvoiceHasDirectPayment({invoiceIds} as AccountingCheckIfInvoiceHasDirectPaymentRequest);
	}

	/* istanbul ignore next: gandalf */
	findPaymentProcessorForPaymentGroup(paymentGroupId: number): Observable<PaymentTransactionProcessorResponse> {
		return this.accountingPaymentGandalfService.findPaymentProcessorForPaymentGroup(paymentGroupId);
	}

	/* istanbul ignore next: gandalf */
	updatePaymentGroup(request: AccountingUpdatePaymentGroupRequest): Observable<void> {
		return this.accountingPaymentGandalfService.updatePaymentGroup(request);
	}

	/* istanbul ignore next: gandalf */
	findInsuranceInvoicesForPayment(practiceLocationId: number, practiceInsuranceCompanyId: number): Observable<PaymentInvoiceListResponse[]> {
		const request = new FindInsurancePaymentInvoicesRequest();
		request.practiceLocationId = practiceLocationId;
		request.practiceInsuranceCompanyId = practiceInsuranceCompanyId;
		return this.accountingPaymentGandalfService.findInsuranceInvoicesForPayment(request);
	}

	/* istanbul ignore next: gandalf */
	findPatientInvoicesForPayment(practiceLocationId: number, personId: number): Observable<PaymentInvoiceListResponse[]> {
		const request = new FindPatientPaymentInvoicesRequest();
		request.practiceLocationId = practiceLocationId;
		request.personId = personId;
		return this.accountingPaymentGandalfService.findPatientInvoicesForPayment(request);
	}

	/* istanbul ignore next: gandalf */
	findCollectionsInvoicesForPayment(practiceLocationId: number, vendorId: number): Observable<PaymentInvoiceListResponse[]> {
		const request = new FindCollectionsPaymentInvoicesRequest();
		request.practiceLocationId = practiceLocationId;
		request.vendorId = vendorId;
		return this.accountingPaymentGandalfService.findCollectionsInvoicesForPayment(request);
	}

	/* istanbul ignore next: gandalf */
	findAnonymousInvoicesForPayment(practiceLocationId: number, payerAnonymousId: number): Observable<PaymentInvoiceListResponse[]> {
		const request = new FindAnonymousPaymentInvoicesRequest();
		request.practiceLocationId = practiceLocationId;
		request.payerAnonymousId = payerAnonymousId;
		return this.accountingPaymentGandalfService.findAnonymousInvoicesForPayment(request);
	}

	/* istanbul ignore next: gandalf */
	findHumanReadableInsuranceRemittancesByPaymentGroup(paymentGroupId: number): Observable<HumanReadableInsuranceRemittanceResponse[]> {
		return this.accountingPaymentGandalfService.findInsuranceRemittancesByPaymentGroup(paymentGroupId);
	}
}
