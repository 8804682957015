<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-md"
		[attr.data-test-id]="'fileUploadModal'">
	<ng-template #header>
		<div class="dlg-template">Upload File(s)</div>
	</ng-template>
	<ng-template #content>
		<div *ngIf="errorMessage" class="alert alert-danger">
			{{errorMessage}}
		</div>
		<form class="form-horizontal">
			<div class="form-group" [attr.data-test-id]="'fileUpload'">
				<label class="col-sm-3 control-label required">File</label>
				<div class="col-sm-9">
					<div #uploadDropArea>
						<ejs-uploader
								#uploader
								[autoUpload]="false"
								[buttons]="buttons"
								[maxFileSize]="maxFileUploadSize"
								[asyncSettings]="settings"
								(success)="uploadSuccess($event, uploader)"
								(selected)="filesSelected($event)"
								class="hide-upload-actions"
								[dropArea]="uploadDropArea"
								[multiple]="true">
						</ejs-uploader>
					</div>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'saveToFolder'">
				<label class="col-sm-3 control-label required">Save To</label>
				<div class="col-sm-9">
					<div class="panel panel-default h350 overflow-auto">
						<ejs-treeview (nodeSelected)="selectValue($event)"
									  [fields]="treeFields">
						</ejs-treeview>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" iconCss="fa fa-close" [attr.data-test-id]="'fileModalCancelButton'">Cancel</button>
		<button ejs-button type="button" [disabled]="!uploadEnabled()" (click)="upload()" [isPrimary]="true" [attr.data-test-id]="'fileModalUploadButton'">Upload</button>
	</ng-template>

</ejs-dialog>
