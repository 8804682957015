import { UntypedFormControl } from '@angular/forms';

/**
 * A type-safe wrapper for working with an Angular {@link FormControl}.
 * A GandalfFormControl represents a "terminal" control in the GandalfFormGroup structure, meaning that
 * there are no additional FormGroups or FormArrays, just the underlying control's value.
 */
export class GandalfFormControl<T> {

	constructor(readonly formControl: UntypedFormControl) {
	}

	/**
	 * Gets the current value of the control.
	 */
	get value(): Readonly<T> {
		return this.formControl.value;
	}

	/**
	 * Sets the value of the control.
	 */
	setValue(value: T) {
		this.formControl.setValue(value);
	}
}
