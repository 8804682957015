// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryCriteriaFieldRequest } from './query-criteria-field-request';

/** See com.rev360.pms.api.controller.query.CreateQueryTriggerRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateQueryTriggerRequest extends GandalfModelBase {

	@GandalfArray(Number)
	carePlanItemTemplateIds: number[];

	@GandalfArray(Number)
	carePlanTemplateIds: number[];

	@GandalfProperty()
	categoryId: number;

	@GandalfValidator({ notNull: { message: 'Search Criteria Field is required' } })
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Search Criteria Field is required' } })
	@GandalfArray(QueryCriteriaFieldRequest)
	criteriaFields: QueryCriteriaFieldRequest[];

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	description: string;

	@GandalfValidator({ sizeString: { message: 'Bibliographic Citation cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	interventionBibliographicCitation: string;

	@GandalfValidator({ sizeString: { message: 'Developer cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	interventionDeveloper: string;

	@GandalfValidator({ sizeString: { message: 'Developer Funding cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	interventionDeveloperFunding: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	interventionReleaseDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	interventionRevisionDate: Date;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Template Category is required' } })
	@GandalfProperty({ isRequired: true })
	templateCategory: constants.QueryTemplateCategory;

}
