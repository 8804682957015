<div class="live-chat" [hidden]="!shouldShow()">
	<a #liveAgentOnline style="display: none;" (click)="startLiveAgentChat()" title="Live Chat is Online">
		<img src="assets/liveChat/live-chat-icon-online.png" width="45px" height="34px" alt="">
	</a>
	<div #liveAgentOffline style="" title="Live Chat is Offline">
		<img src="assets/liveChat/live-chat-icon-offline.png" width="45px" height="34px" alt="">
	</div>
</div>
<div class="live-chat" [hidden]="!shouldShowMigrated()">
	<a #liveAgentOnlineMigrated style="display: none;" (click)="startLiveAgentChatMigrated()" title="Live Chat is Online">
		<img src="assets/liveChat/live-chat-icon-online.png" width="45px" height="34px" alt="">
	</a>
	<div #liveAgentOfflineMigrated style="" title="Live Chat is Offline">
		<img src="assets/liveChat/live-chat-icon-offline.png" width="45px" height="34px" alt="">
	</div>
</div>
