import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridUtil, TypeSafeModalManagerService, YesNoPipe } from 'morgana';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { _isNil } from '@core/lodash/lodash';
import { RevClearExternalCredentialResponse } from '@gandalf/model/rev-clear-external-credential-response';
import { RevClearExternalCredentialModalComponent } from './rev-clear-external-credential-modal/rev-clear-external-credential-modal.component';
import { FormattedRevClearExternalCredentialResponse, RevClearTemplateService } from './rev-clear-template.service';


export enum FilterOptions {
	ALL = 'All',
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
}

@Component({
	selector: 'pms-rev-clear-template',
	templateUrl: './rev-clear-template.component.html',
	providers: [TypeSafeModalManagerService],
})
export class RevClearTemplateComponent implements OnInit {

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@ViewChild('enableDisableButtonColumn')
	enableDisableButtonColumn: TemplateRef<any>;

	hasAutoPosting: boolean;
	gridOptions = GridUtil.buildGridOptions({
		pagination: false,
		rowClass: 'row-link',
	});
	revClearExternalCredentials: FormattedRevClearExternalCredentialResponse[];
	gridColumns: ColDef[];
	isUpdating: boolean;
	locations: OptionItemResponse<PracticeLocation, number>[];
	statusFilter: string;
	statusFilterOptions = [
		{label: 'All', value: FilterOptions.ALL},
		{label: 'Active', value: FilterOptions.ACTIVE},
		{label: 'Inactive', value: FilterOptions.INACTIVE},
	];

	constructor(
		private revClearTemplateService: RevClearTemplateService,
		private userLocationsService: UserLocationsService,
		private typeSafeModalManagerService: TypeSafeModalManagerService,
		private yesNoPipe: YesNoPipe,
	) {
	}

	ngOnInit() {
		this.statusFilter = FilterOptions.ACTIVE;
		this.hasAutoPosting = this.revClearTemplateService.hasAutoPosting();
		this.getData();
	}

	getData() {
		this.revClearTemplateService.findRevClearExternalCredentials().subscribe(credentials => {
			this.revClearExternalCredentials = credentials;
			this.locations = this.getAvailableLocations();
		});
	}

	getAvailableLocations() {
		return this.userLocationsService.getAllActiveLocations()
			// Filter out locations already assigned a credential
			.filter(location => !this.revClearExternalCredentials.find(credential => credential.locations.find(credentialLocation => credentialLocation.id === location.id)));
	}

	onGridReady(_event: GridReadyEvent) {
		this.buildGridColumns();
		this.filterTable();
	}

	buildGridColumns() {
		this.gridColumns = [
			GridUtil.buildColumn('Site ID', 'userName', {
				minWidth: 105,
			}),
			GridUtil.buildColumn('Password', 'password', {
				minWidth: 105,
			}),
			GridUtil.buildColumnWithEllipses('Location(s)', 'formattedLocationListString', {
				flex: 1,
				minWidth: 150,
			}),
			GridUtil.buildColumn('Auto-Posting', 'autoPostingEnabled', {
				width: 125,
				hide: !this.hasAutoPosting,
				suppressExport: !this.hasAutoPosting,
				valueGetter: (params) => this.yesNoPipe.transform(params.data.autoPostingEnabled),
			}),
			GridUtil.buildColumn('Status', 'active', {
				width: 105,
				valueGetter: (params) => this.yesNoPipe.transform(params.data.active, 'Active', 'Inactive'),
			}),
			GridUtil.buildButtonColumn('', this.enableDisableButtonColumn, {
				width: 45,
			}),
		];

		this.agGrid.api.setColumnDefs(this.gridColumns);
	}

	filterTable() {
		GridUtil.applyFilter(this.agGrid, this.applyStatusFilter, 'active', this.statusFilter);
	}

	applyStatusFilter = () => !_isNil(this.statusFilter) && this.statusFilter !== FilterOptions.ALL;

	openModalWithRefresh(data?: RevClearExternalCredentialResponse) {
		this.openRevClearCredentialDetailModal(data).onClose.subscribe(refresh => {
			if (refresh) {
				this.getData();
			}
		});
	}

	updateStatus(data: RevClearExternalCredentialResponse) {
		if (this.isUpdating) {
			return;
		}

		this.isUpdating = true;
		if (data.active) {
			this.disableRevClearExternalCredential(data.revClearExternalCredentialId);
		} else {
			this.enableRevClearExternalCredential(data);
		}
	}

	disableRevClearExternalCredential(credentialId: number) {
		this.revClearTemplateService.disableRevClearExternalCredential(credentialId).subscribe(() => {
			this.getData();
			this.isUpdating = false;
		});
	}

	enableRevClearExternalCredential(data: RevClearExternalCredentialResponse) {
		this.openRevClearCredentialDetailModal(data).onClose.subscribe(() => {
			this.getData();
			this.isUpdating = false;
		});
	}

	private openRevClearCredentialDetailModal(data?: RevClearExternalCredentialResponse) {
		return this.typeSafeModalManagerService.open(RevClearExternalCredentialModalComponent, {
			hasAutoPosting: this.hasAutoPosting,
			revClearExternalCredentialId: data?.revClearExternalCredentialId,
			userName: data?.userName,
			password: data?.password,
			autoPostingEnabled: data?.autoPostingEnabled,
			assignedLocations: data?.locations,
			availableLocations: this.locations,
		});
	}
}
