import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { HIT_PMS_HTML_PREFERENCES } from '@core/legacy/hit-pms-html.constants';
import { _isNil } from '@core/lodash/lodash';
import { NavigationService } from '@core/navigation/navigation.service';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { SummaryPodService } from '@core/summary-pod/summary-pod.service';
import { UrlService } from '@core/url-util/url.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import {
	ContactLensHistoryType,
	ContactLensPrescriptionType,
	ContactLensType,
	OrderType,
	PreferenceDefaults,
	PreferenceName,
	PrescriptionAuthorizationType,
	PrescriptionStatus
} from '@gandalf/constants';
import { CancelPrescriptionRequest } from '@gandalf/model/cancel-prescription-request';
import { ContactLensEyeHybridResponse } from '@gandalf/model/contact-lens-eye-hybrid-response';
import { ContactLensEyeResponse } from '@gandalf/model/contact-lens-eye-response';
import { ContactLensEyeRgpResponse } from '@gandalf/model/contact-lens-eye-rgp-response';
import { ContactLensEyeSoftResponse } from '@gandalf/model/contact-lens-eye-soft-response';
import { ContactLensHistoryResponse } from '@gandalf/model/contact-lens-history-response';
import { ContactLensPrescriptionResponse } from '@gandalf/model/contact-lens-prescription-response';
import { ContactLensTrialListResponse } from '@gandalf/model/contact-lens-trial-list-response';
import { CreateContactLensTrialOrderRequest } from '@gandalf/model/create-contact-lens-trial-order-request';
import { CreatePrescriptionOrderRequest } from '@gandalf/model/create-prescription-order-request';
import { ProductForPrescriptionResponse } from '@gandalf/model/product-for-prescription-response';
import { ProviderResponse } from '@gandalf/model/provider-response';
import { BaseComponent } from '@shared/component/base.component';
import { CancelPrescriptionModalComponent } from '@shared/component/cancel-prescription-modal/cancel-prescription-modal.component';
import { ContactLensPrescriptionDetailsService } from '@shared/component/contact-lens-prescription-details/contact-lens-prescription-details.service';
import { OpticalHistoryService } from '@shared/component/optical-history/optical-history.service';
import { TooltipCellRendererComponent } from '@shared/component/tooltip-cell-renderer/tooltip-cell-renderer.component';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { URL_PRINT_ENDPOINTS } from '@shared/constants/url.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import {
	ButtonMenuItem,
	EnumUtil,
	GRID_MULTILINE_ROW_HEIGHT,
	GridUtil,
	GridUtilService,
	ModalManagerService,
	ObjectUtils,
	PAGE_LENGTH,
	PAGE_LENGTH_LIST,
	SortingService,
	YesNoPipe
} from 'morgana';
import { combineLatest, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventService } from '@core/event/event.service';
/* eslint-disable-next-line max-len */
import { ContactLensPrescriptionModalComponent } from '../../../../patients/encounters/contact-lens/contact-lens-trials/contact-lens-prescription-modal/contact-lens-prescription-modal.component';
import { ContactLensTrialService } from '../../../../patients/encounters/contact-lens/contact-lens-trials/contact-lens-trial.service';

export type ContactLensTypedEyeResponse = (ContactLensEyeRgpResponse | ContactLensEyeSoftResponse | ContactLensEyeHybridResponse);

export enum RecordType {
	HISTORY,
	TRIAL,
	PRESCRIPTION,
}

export interface HistoryTableRecord {
	// For table use
	startDate: Date;
	status: string;
	authorizedBy: ProviderResponse;
	hasOs: boolean;
	hasOd: boolean;
	osLocationProductName: string;
	odLocationProductName: string;
	lensType: ContactLensType;
	osIsMono: boolean;
	odIsMono: boolean;
	osBaseCurve: number;
	odBaseCurve: number;
	osSphere: number;
	odSphere: number;
	osCylinder: number;
	odCylinder: number;
	osAxis: number;
	odAxis: number;
	osAddDesignation: string;
	odAddDesignation: string;
	osDiameter: number;
	odDiameter: number;
	isPHREnabled: boolean;
	osDispensed: boolean;
	odDispensed: boolean;
	osComments: string;
	odComments: string;
	commentsOdOrOs: string;
	authType?: PrescriptionAuthorizationType;
	prescriptionStatus?: PrescriptionStatus;
	odProduct?: ProductForPrescriptionResponse;
	osProduct?: ProductForPrescriptionResponse;
	buttonOptions: ButtonMenuItem[];
	expirationDate?: Date;

	// For response
	odEyeResponse: ContactLensEyeResponse;
	osEyeResponse: ContactLensEyeResponse;
	id: number;
	type: RecordType;
}

export enum FilterOptions {
	ALL = 0,
	RX = 1,
	TRIAL = 2,
	HISTORY = 3,
}

export interface ClRxHistoryResult {
	lensType: ContactLensType;
	osLocationProductName: string;
	odLocationProductName: string;
	type: RecordType;
	id: number;

	odEyeResponse: ContactLensEyeResponse;
	osEyeResponse: ContactLensEyeResponse;
}


@Component({
	selector: 'pms-contact-lens-history-table',
	templateUrl: './contact-lens-history-table.component.html',
})
export class ContactLensHistoryTableComponent extends BaseComponent implements OnInit {

	@Input()
	selectedContactLensId: number;

	@Input()
	patientId: number;

	@Input()
	encounterId: number;

	@Output()
	selection = new EventEmitter<ClRxHistoryResult>();

	@Input()
	useButtons = false;

	@Input()
	showExtraColumns = false;

	@ViewChild('grid')
	grid: GridComponent;

	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};
	isSearching = true;
	clPrescriptions: ContactLensPrescriptionResponse[];
	PrescriptionUtil = PrescriptionUtil;
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	clTrials: ContactLensTrialListResponse[];
	tableList: HistoryTableRecord[];
	filterOptions = [
		{label: 'All', value: FilterOptions.ALL},
		{label: 'Rx', value: FilterOptions.RX},
		{label: 'Trial', value: FilterOptions.TRIAL},
	];
	currentFilter = FilterOptions.ALL;
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;

	showDiscontinued = false;
	clHistory: ContactLensHistoryResponse;
	cancelRxButtonOption = new ButtonMenuItem(2, 'Cancel Rx');
	enablePhrButtonOption = new ButtonMenuItem(3, 'Enable PHR');
	disablePhrButtonOption = new ButtonMenuItem(4, 'Disable PHR');
	refillRxButtonOption = new ButtonMenuItem(5, 'Refill Rx');

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@ViewChild('authBy')
	authByColumn: TemplateRef<any>;

	@ViewChild('authByTooltip')
	authByTooltip: TemplateRef<any>;

	@ViewChild('osOd')
	osOdColumn: TemplateRef<any>;

	@ViewChild('model')
	modelColumn: TemplateRef<any>;

	@ViewChild('type')
	typeColumn: TemplateRef<any>;

	@ViewChild('mv')
	mvColumn: TemplateRef<any>;

	@ViewChild('bc')
	bcColumn: TemplateRef<any>;

	@ViewChild('sph')
	sphColumn: TemplateRef<any>;

	@ViewChild('cyl')
	cylColumn: TemplateRef<any>;

	@ViewChild('axis')
	axisColumn: TemplateRef<any>;

	@ViewChild('add')
	addColumn: TemplateRef<any>;

	@ViewChild('addTooltipTemplate')
	addTooltipTemplate: TemplateRef<any>;

	@ViewChild('diam')
	diamColumn: TemplateRef<any>;

	@ViewChild('disp')
	dispColumn: TemplateRef<any>;

	@ViewChild('phr')
	phrColumn: TemplateRef<any>;

	@ViewChild('comments')
	commentsColumn: TemplateRef<any>;

	@ViewChild('commentsTooltip')
	commentsTooltipColumn: TemplateRef<any>;

	@ViewChild('actions')
	actionsColumn: TemplateRef<any>;

	private gridColumns: ColDef[];

	contactLensPrescriptionGridOptions = GridUtil.buildGridOptions({
		suppressRowClickSelection: true,
		rowClass: 'row-link',
		rowHeight: GRID_MULTILINE_ROW_HEIGHT,
	});

	isAgGridFeatureFlagOn: boolean;


	constructor(
		public contactLensPrescriptionDetailsService: ContactLensPrescriptionDetailsService,
		public contactLensTrialService: ContactLensTrialService,
		public opticalHistoryService: OpticalHistoryService,
		public yesNoPipe: YesNoPipe,
		public securityManagerService: SecurityManagerService,
		public userLocationsService: UserLocationsService,
		public urlService: UrlService,
		public modalManagerService: ModalManagerService,
		public summaryPodService: SummaryPodService,
		public navigationService: NavigationService,
		private featureService: FeatureService,
		private gridUtilService: GridUtilService,
		private eventService: EventService,
	) {
		super();
	}

	ngOnInit() {
		this.isAgGridFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PATIENTS.RX.CONTACTLENS.AG_GRID);
		if (this.isEMR()) {
			this.filterOptions.push(
				{label: 'History', value: FilterOptions.HISTORY},
			);
		}
		this.getData(true);
	}

	getData(initial = false) {
		this.isSearching = true;
		combineLatest([
			this.contactLensPrescriptionDetailsService.findContactLensPrescriptionsByPatient(this.patientId),
			this.opticalHistoryService.findContactLensTrialsForPatient(this.patientId),
			this.isEMR() ? this.opticalHistoryService.findContactLensHistoryByEncounterId(this.encounterId) : of(null),
		]).subscribe(([prescriptions, trials, history]) => {
			this.onDataResult(trials, history, prescriptions, initial);
		});
	}

	onDataResult(trials, history, prescriptions, initial: boolean) {
		this.clTrials = trials;
		this.clHistory = history;
		this.clPrescriptions = prescriptions;
		this.filterTable();
		this.isSearching = false;
		if (initial) {
			this.subscribeToPrescriptionUpdates();
			this.subscribeToTrialUpdates();
			this.checkForSelectedContactLens();
		}
	}

	checkForSelectedContactLens() {
		if (this.selectedContactLensId) {
			const selectedContactLensPrescription = this.tableList.find(contactLensPrescription => contactLensPrescription.id === this.selectedContactLensId);
			this.rowSelected(selectedContactLensPrescription);
		}
	}

	subscribeToPrescriptionUpdates() {
		this.contactLensPrescriptionDetailsService.prescriptionsUpdated.pipe(takeUntil(this.unsubscribe)).subscribe(
			() => {
				this.isSearching = true;
				this.contactLensPrescriptionDetailsService.findContactLensPrescriptionsByPatient(this.patientId).subscribe(
					prescriptions => {
						this.clPrescriptions = prescriptions;
						this.isSearching = false;
						this.filterTable();
					});
			},
		);
	}

	subscribeToTrialUpdates() {
		this.opticalHistoryService.trialsUpdated.pipe(takeUntil(this.unsubscribe)).subscribe(
			() => {
				this.isSearching = true;
				this.opticalHistoryService.findContactLensTrialsForPatient(this.patientId).subscribe(
					trials => {
						this.clTrials = trials;
						this.isSearching = false;
						this.filterTable();
					});
			},
		);
	}

	getContactLensPrescriptionEye(clRx: ContactLensPrescriptionResponse, isOs: boolean) {
		let response: ContactLensTypedEyeResponse;
		if (EnumUtil.equals(clRx.overallLensType, ContactLensPrescriptionType.SOFT)) {
			response = isOs ? clRx.contactLensEyeSoftOs : clRx.contactLensEyeSoftOd;
		} else if (EnumUtil.equals(clRx.overallLensType, ContactLensPrescriptionType.RGP)) {
			response = isOs ? clRx.contactLensEyeRgpOs : clRx.contactLensEyeRgpOd;
		} else {
			response = isOs ? clRx.contactLensEyeHybridOs : clRx.contactLensEyeHybridOd;
		}
		return response;
	}

	rowSelected(data: HistoryTableRecord) {
		this.selection.emit(data);
	}

	discontinuedChange(showDiscontinued: boolean) {
		this.showDiscontinued = showDiscontinued;
		this.filterTable();
	}

	filterTable() {
		if (!this.clTrials || !this.clPrescriptions) {
			return;
		}
		let clPrescriptions = this.clPrescriptions;
		if (!this.showDiscontinued) {
			clPrescriptions = clPrescriptions.filter(clrx => !EnumUtil.equals(clrx.status, PrescriptionStatus.STOPPED));
		}
		switch (this.currentFilter) {
			case FilterOptions.ALL: {
				this.tableList = [
					...this.clTrials.map(results => this.formatTrial(results)),
					...clPrescriptions.map(results => this.formatCLRX(results)),
				];
				if (!_isNil(this.clHistory) && !EnumUtil.equals(this.clHistory.overallLensType, ContactLensHistoryType.UNKNOWN)) {
					this.tableList.push(this.formatHistory(this.clHistory));
				}
				break;
			}
			case FilterOptions.HISTORY: {
				this.tableList = [];
				if (!_isNil(this.clHistory) && !EnumUtil.equals(this.clHistory.overallLensType, ContactLensHistoryType.UNKNOWN)) {
					this.tableList.push(this.formatHistory(this.clHistory));
				}
				break;
			}
			case FilterOptions.TRIAL: {
				this.tableList = [
					...this.clTrials.map(results => this.formatTrial(results)),
				];
				break;
			}
			case FilterOptions.RX: {
				this.tableList = [
					...clPrescriptions.map(results => this.formatCLRX(results)),
				];
				break;
			}
		}
		// CL History should be last so sort that status to last
		this.tableList = SortingService.sortBy(this.tableList, [item => item.type !== RecordType.HISTORY, 'startDate', 'id'], ['desc', 'desc', 'desc']);
		if (this.grid && !this.isAgGridFeatureFlagOn) {
			this.grid.refresh();
		}

		this.updateContactLensPrescriptionList(this.tableList);
	}

	formatTrial(trial: ContactLensTrialListResponse): HistoryTableRecord {
		const odLens = trial.od || {} as ContactLensEyeResponse;
		const osLens = trial.os || {} as ContactLensEyeResponse;
		return this.buildHistoryRecord(
			osLens,
			odLens,
			trial.overallLensType,
			trial.encounterDate,
			PrescriptionUtil.CL_TRIAL_STATUS,
			null,
			trial.isDispensedOs,
			trial.isDispensedOd,
			trial.commentsOs,
			trial.commentsOd,
			null,
			trial.id,
			RecordType.TRIAL,
		);
	}

	formatCLRX(clRx: ContactLensPrescriptionResponse): HistoryTableRecord {
		const osLens = this.getContactLensPrescriptionEye(clRx, true) || {} as ContactLensEyeResponse;
		const odLens = this.getContactLensPrescriptionEye(clRx, false) || {} as ContactLensEyeResponse;
		const record = this.buildHistoryRecord(
			osLens,
			odLens,
			clRx.overallLensType,
			clRx.startDate,
			clRx.derivedStatus.label,
			clRx.authorizedBy,
			null,
			null,
			null,
			clRx.description,
			clRx.isPHREnabled,
			clRx.id,
			RecordType.PRESCRIPTION,
			// Prescriptions have additional checks for their buttons so we need to pass the extra optional fields to them
			{
				expirationDate: clRx.expirationDate,
				authType: clRx.authorizationType,
				prescriptionStatus: clRx.status,
				odProduct: clRx.productOd,
				osProduct: clRx.productOs,
			},
		);
		return record;
	}

	buildHistoryRecord(
		osLens: ContactLensEyeResponse,
		odLens: ContactLensEyeResponse,
		overallLensType: ContactLensType,
		startDate: Date,
		status: string,
		provider: ProviderResponse,
		osDispensed: boolean,
		odDispensed: boolean,
		osComments: string,
		odComments: string,
		isPHREnabled: boolean,
		id: number,
		recordType: RecordType,
		otherProperties = {},
	): HistoryTableRecord {
		const record = {
			startDate,
			status,
			authorizedBy: provider,
			hasOs: !!osLens.id,
			hasOd: !!odLens.id,
			osLocationProductName: osLens.locationProductName,
			odLocationProductName: odLens.locationProductName,
			lensType: overallLensType,
			osIsMono: osLens.isMonovision,
			odIsMono: odLens.isMonovision,
			osBaseCurve: osLens.baseCurve,
			odBaseCurve: odLens.baseCurve,
			osSphere: osLens.sphere,
			odSphere: odLens.sphere,
			osCylinder: osLens.cylinder,
			odCylinder: odLens.cylinder,
			osAxis: osLens.axis,
			odAxis: odLens.axis,
			osAddDesignation: this.buildAddDesigString(osLens, overallLensType),
			odAddDesignation: this.buildAddDesigString(odLens, overallLensType),
			osDiameter: osLens.diameter,
			odDiameter: odLens.diameter,
			osDispensed,
			odDispensed,
			osComments,
			odComments,
			commentsOdOrOs: ObjectUtils.isNotEmptyString(odComments) ? odComments : osComments,
			isPHREnabled,
			type: recordType,
			id,
			buttonOptions: [],

			odEyeResponse: odLens,
			osEyeResponse: osLens,
			...otherProperties,
		};

		this.addAllButtonOptions(record);

		return record;
	}

	addAllButtonOptions(record: HistoryTableRecord) {
		if (this.showCancel(record)) {
			record.buttonOptions.push(this.cancelRxButtonOption);
		}
		if (this.showEnablePHR(record)) {
			record.buttonOptions.push(this.enablePhrButtonOption);
		}

		if (this.showDisablePHR(record)) {
			record.buttonOptions.push(this.disablePhrButtonOption);
		}

		if (this.showRefillRx(record)) {
			record.buttonOptions.push(this.refillRxButtonOption);
		}
	}

	buildAddDesigString(lensEye: ContactLensEyeResponse, overallLensType: ContactLensType) {
		let power = lensEye.addPower as any;
		power = power ? PrescriptionUtil.formatAddPower(power) : '';
		let addDesignationLabel = '';
		if (EnumUtil.equals(overallLensType, ContactLensType.SOFT)) {
			const addDesignation = lensEye ? (lensEye as ContactLensEyeSoftResponse).addDesignation : {value: ''};
			addDesignationLabel = addDesignation ? addDesignation.value : '';
		}
		return `${power} ${addDesignationLabel}`;
	}

	private formatHistory(history: ContactLensHistoryResponse) {
		const odLens = history.od || {} as ContactLensEyeResponse;
		const osLens = history.os || {} as ContactLensEyeResponse;
		return this.buildHistoryRecord(
			osLens,
			odLens,
			history.overallLensType,
			null,
			'CL History',
			null,
			null,
			null,
			null,
			history.comments,
			null,
			history.id,
			RecordType.HISTORY,
		);
	}

	authorizeAndVerifyProduct(historyTableRecord: HistoryTableRecord) {
		const callback = () => this.authorize(historyTableRecord);
		this.contactLensTrialService.checkProductsActiveAndCallOnConfirm(
			historyTableRecord.odEyeResponse.locationProductId,
			historyTableRecord.osEyeResponse.locationProductId,
			callback);
	}

	authorize(historyTableRecord: HistoryTableRecord) {
		this.opticalHistoryService.authorizeContactLensPrescription(historyTableRecord.id).subscribe(() => {
			this.prescriptionUpdated();
			this.getData();
		});
	}

	authorizeAndSign(historyTableRecord: HistoryTableRecord) {
		this.opticalHistoryService.authorizeAndSignContactLensPrescription(historyTableRecord.id).subscribe(() => {
			this.prescriptionUpdated();
			this.getData();
		});
	}

	authorizeAndSignAndVerifyProduct(historyTableRecord: HistoryTableRecord) {
		const callback = () => this.authorizeAndSign(historyTableRecord);
		this.contactLensTrialService.checkProductsActiveAndCallOnConfirm(
			historyTableRecord.odEyeResponse.locationProductId,
			historyTableRecord.osEyeResponse.locationProductId,
			callback);
	}

	showAuthorize(data: HistoryTableRecord): boolean {
		return data.type !== RecordType.HISTORY
			&& EnumUtil.equals(PrescriptionAuthorizationType.PROVIDER, data.authType)
			&& EnumUtil.equals(PrescriptionStatus.ACTIVE, data.prescriptionStatus)
			&& this.securityManagerService.userIsProvider();
	}

	createOrder(historyTableRecord: HistoryTableRecord) {
		let createOrderRequest;

		if (PrescriptionUtil.isContactLensTrial(historyTableRecord)) {
			createOrderRequest = new CreateContactLensTrialOrderRequest();
			createOrderRequest.encounterId = this.encounterId;
			createOrderRequest.contactLensTrialId = historyTableRecord.id;
			createOrderRequest.patientId = this.patientId;

			this.createOrderFromContactLensTrial(createOrderRequest);
		} else {
			createOrderRequest = new CreatePrescriptionOrderRequest();
			createOrderRequest.patientId = this.patientId;
			createOrderRequest.encounterId = this.encounterId;
			createOrderRequest.prescriptionId = historyTableRecord.id;
			createOrderRequest.locationId = this.userLocationsService.getCurrentUserLocation().id;

			this.createOrderFromPrescription(createOrderRequest);
		}
	}

	createOrderFromContactLensTrial(createOrderRequest: CreateContactLensTrialOrderRequest) {
		if (this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ORDERS_VIEW)) {
			this.contactLensTrialService.createOrderFromContactLensTrialNoToast(createOrderRequest).subscribe(async (id) => {
				await this.navigationService.openOrder(id, OrderType.CONTACT_LENS_TRIAL, true, null, null);
				this.orderUpdated();
			});
		} else {
			this.contactLensTrialService.createOrderFromContactLensTrialToast(createOrderRequest).subscribe(() => {
				this.orderUpdated();
			});
		}
	}

	createOrderFromPrescription(createOrderRequest: CreatePrescriptionOrderRequest) {
		if (this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ORDERS_VIEW)) {
			this.contactLensTrialService.createOrderFromPrescriptionNoToast(createOrderRequest).subscribe(async (id) => {
				await this.navigationService.openOrder(id, OrderType.CONTACT_LENS_TRIAL, true, null, null);
				this.orderUpdated();
			});
		} else {
			this.contactLensTrialService.createOrderFromPrescription(createOrderRequest).subscribe(() => {
				this.orderUpdated();
			});
		}
	}

	showRefillRx(historyTableRecord: HistoryTableRecord) {
		return historyTableRecord
			&& PrescriptionUtil.isAuthorizedByInternalProvider(historyTableRecord.prescriptionStatus, historyTableRecord.authType)
			&& this.securityManagerService.userIsProvider();
	}

	showAuthAndSign(data: HistoryTableRecord) {
		return this.showAuthorize(data) && !this.securityManagerService.preferenceValueIsOn(
			HIT_PMS_HTML_PREFERENCES.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE.name,
			HIT_PMS_HTML_PREFERENCES.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE.defaultValue,
		);
	}

	showPrint(historyTableRecord: HistoryTableRecord) {
		return historyTableRecord.type
			&& PrescriptionUtil.isAuthorizedByInternalProvider(historyTableRecord.prescriptionStatus, historyTableRecord.authType);
	}

	print(data: HistoryTableRecord) {
		const params: any = {
			rxid: data.id,
			locationid: this.userLocationsService.getCurrentUserLocation().id,
		};
		this.urlService.openTabWithPost(URL_PRINT_ENDPOINTS.CONTACT_LENS_PRESCRIPTION_PRINT, params);
	}

	private showCancel(data: HistoryTableRecord) {
		return this.isPrescription(data)
			&& !EnumUtil.equals(data.prescriptionStatus, PrescriptionStatus.STOPPED)
			&& !PrescriptionUtil.isExpired(data.expirationDate);
	}

	private isPrescription(data: HistoryTableRecord) {
		return data.type === RecordType.PRESCRIPTION;
	}

	private showEnablePHR(data: HistoryTableRecord) {
		return this.allowPHRChanges(data)
			&& !data.isPHREnabled;
	}

	private allowPHRChanges(data: HistoryTableRecord) {
		return data.type === RecordType.PRESCRIPTION
			&& this.securityManagerService.preferenceValueIsOn(
				PreferenceName.PHR_PATIENT_CONTACT_LENS_ENABLED.value,
				PreferenceDefaults.PHR_PATIENT_CONTACT_LENS_ENABLED.value,
			)
			&& !EnumUtil.equals(data.authType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER)
			&& EnumUtil.equals(data.prescriptionStatus, PrescriptionStatus.AUTHORIZED);
	}

	ellipsesButtonAction(data: HistoryTableRecord, option: ButtonMenuItem) {
		switch (option.id) {
			case this.cancelRxButtonOption.id:
				this.cancelPrescription(data.id);
				break;

			case this.enablePhrButtonOption.id:
				this.enablePHR(data);
				break;

			case this.disablePhrButtonOption.id:
				this.disablePHR(data);
				break;

			case this.refillRxButtonOption.id:
				this.refillRx(data.id);
				break;
		}
	}

	refillRx(contactLensPrescriptionId: number) {
		this.contactLensTrialService.getContactLensPrescriptionById(contactLensPrescriptionId).subscribe((contactLensPrescription) => {
			this.modalManagerService.open(ContactLensPrescriptionModalComponent, {
				data: {
					encounterId: this.encounterId,
					patientId: this.patientId,
					clrxForRefill: contactLensPrescription,
					isRefilling: true,
				},
			}).onClose.subscribe((refresh) => {
				if (refresh) {
					this.contactLensPrescriptionDetailsService.markPrescriptionsUpdated();
				}
			});
		});
	}

	cancelPrescription(id: number) {
		this.modalManagerService.open(CancelPrescriptionModalComponent, {
			data: {
				prescriptionId: id,
				onSave: (request: CancelPrescriptionRequest) => this.cancelContactLensPrescription(request),
			},
		}).onClose.subscribe((result: CancelPrescriptionRequest) => {
			if (result) {
				this.prescriptionUpdated();
				this.getData();
			}
		});
	}

	private cancelContactLensPrescription(request: CancelPrescriptionRequest) {
		return this.contactLensPrescriptionDetailsService.cancelPrescription(request);
	}

	private showDisablePHR(data: HistoryTableRecord) {
		return this.allowPHRChanges(data)
			&& data.isPHREnabled;
	}

	enablePHR(data: HistoryTableRecord) {
		this.contactLensPrescriptionDetailsService.enablePHR(data.id).subscribe(() => this.getData());
	}

	disablePHR(data: HistoryTableRecord) {
		this.contactLensPrescriptionDetailsService.disablePhr(data.id).subscribe(() => this.getData());
	}

	private isEMR() {
		return !_isNil(this.encounterId);
	}


	updateContactLensPrescriptionList(contactLensPrescriptions) {
		if (this.isAgGridFeatureFlagOn && !_isNil(this.agGrid?.api)) {
			// if contact lens prescription list is undefined, set empty instead to prevent grid from saying 'loading'
			this.agGrid.api.setRowData(contactLensPrescriptions || []);
		}
	}

	onGridReady(_event: GridReadyEvent) {
		this.buildGridColumns();
		this.agGrid.api.setColumnDefs(this.gridColumns);
		// on init we want to update the grid correctly
		this.updateContactLensPrescriptionList(this.tableList);
	}

	buildGridColumns() {
		this.gridColumns = [
			this.gridUtilService.buildDateColumn('Date', 'startDate', DATE_FORMATS.MM_DD_YYYY, {
				width: 90,
			}),
			GridUtil.buildColumn('Status', 'status', {
				width: 100,
			}),
			GridUtil.buildTemplateColumn('Auth By', '', this.authByColumn, {
				minWidth: 150,
				sortable: false,
				flex: 1,
				tooltipField: 'authorizedBy',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.authByTooltip,
				},
			}),
			GridUtil.buildTemplateColumn('', '', this.osOdColumn, {
				width: 50,
				sortable: false,
				resizable: false,
			}),
			GridUtil.buildTemplateColumn('Model', 'locationProductName', this.modelColumn, {
				tooltipField: 'locationProductName',
			}),
			GridUtil.buildTemplateColumn('Type', 'overallLensType', this.typeColumn, {
				width: 65,
			}),
			GridUtil.buildTemplateColumn('MV', 'isMonovision', this.mvColumn, {
				width: 60,
			}),
			GridUtil.buildTemplateColumn('BC', 'baseCurve', this.bcColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Sph', 'sphere', this.sphColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Cyl', 'cylinder', this.cylColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Axis', 'axis', this.axisColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Add', 'addDesignation', this.addColumn, {
				width: 80,
				sortable: false,
				tooltipField: 'odAddDesignation',
				tooltipComponent: TooltipCellRendererComponent,
				tooltipComponentParams: {
					ngTemplate: this.addTooltipTemplate,
				},
			}),
			GridUtil.buildTemplateColumn('Diam', 'diameter', this.diamColumn, {
				width: 70,
				sortable: false,
			}),
			...this.buildExtraColumns(),
			...this.buildActionColumn(),
		];
	}

	buildExtraColumns() {
		if (this.showExtraColumns) {
			return [
				GridUtil.buildTemplateColumn('Disp', 'odDispensed', this.dispColumn, {
					width: 65,
				}),
				GridUtil.buildTemplateColumn('PHR', 'phr', this.phrColumn, {
					width: 65,
				}),
				GridUtil.buildTemplateColumn('Comments', 'comments', this.commentsColumn, {
					minWidth: 120,
					flex: 1,
					tooltipField: 'commentsOdOrOs',
					tooltipComponent: TooltipCellRendererComponent,
					tooltipComponentParams: {
						ngTemplate: this.commentsTooltipColumn,
					},
				}),
			];
		}

		return [];
	}

	buildActionColumn() {
		if (this.useButtons) {
			return [
				GridUtil.buildTemplateColumn('', '', this.actionsColumn, {
					width: 110,
					sortable: false,
					resizable: false,
				}),
			];
		}

		return [];
	}

	agGridRowClicked(event: RowClickedEvent) {
		const rowData = event.data;
		if (!_isNil(rowData)) {
			this.selection.emit(rowData);
		}
	}

	shouldDisplayOdComments(odComments) {
		return ObjectUtils.isNotEmptyString(odComments);
	}

	prescriptionUpdated() {
		this.eventService.publishUpdatePatientContactLensPrescriptions(this.patientId);
		this.summaryPodService.updateRxSummaryPod(this.patientId);
	}

	orderUpdated() {
		this.eventService.publishUpdatePatientOrders(this.patientId);
		this.summaryPodService.updateOrdersOpticalSummaryPod(this.patientId);
	}
}
