<ejs-dialog
	#modal
	(close)="closeDialog()">
	<ng-template #header>
		<div class="dlg-template">Printing Barcode Labels</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-body">
			<div class="alert alert-danger" [hidden]="errorArray.length === 0">
				<ul>
					<li *ngFor="let error of errorArray"><strong>{{error}}</strong></li>
				</ul>
			</div>
			<div class="text-center">
				<div *ngIf="!printingComplete()">
					<i class="fa fa-spinner fa-spin" style="font-size: 32px;" aria-hidden="true"></i>
					<br>
				</div>
				<i *ngIf="printingComplete() && noErrors()" class="fa fa-check text-success"
				   style="font-size: 32px; "></i>
				<i *ngIf="!noErrors()" class="fa fa-times text-danger" style="font-size: 32px;"></i>
				<div> {{complete}} of {{total}}</div>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button (click)="cancel()" *ngIf="!printingComplete()" icon="fa fa-times">Cancel</button>
		<button type="button" ejs-button (click)="closeDialog()" *ngIf="printingComplete()">Close</button>
	</ng-template>
</ejs-dialog>
