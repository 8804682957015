<ejs-dialog #diagnosisAddModal
			(close)="closeModal()">
	<ng-template #header>
		<div class="dlg-template">Select Diagnosis</div>
	</ng-template>
	<ng-template #content>
		<div class="modal-feature">
			<form class="form-inline" [formGroup]="formGroup" #diagnosisAddForm="ngForm" (ngSubmit)="searchDiagnoses()">
				<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && diagnosisAddForm.submitted"
					[form]="formGroup" [requestObj]="searchRequest">
				</gandalf-lib-validation-messages>
				<div class="form-group">
					<label>Search</label>
					<input class="e-input" type="text"
						   placeholder="Search"
						   (keyup.enter)="searchDiagnoses()"
						   [(ngModel)]="query" [ngModelOptions]="{standalone: true}"/>
				</div>
				<div class="form-group">
					<button ejs-button type="submit" iconCss="fa fa-search" [isPrimary]="true">Search</button>
					<button ejs-button type="button" iconCss="fa fa-close"
							(click)="clearSearch()">
						Clear
					</button>
				</div>
			</form>
		</div>
		<div *ngIf="searchResults?.length >= 100" class="alert alert-warning">
			Search returned more than 100 results, please refine your search.
		</div>
		<ejs-grid #searchResultsGrid
				  [allowFiltering]="false"
				  [allowExcelExport]="false"
				  [allowPaging]="true"
				  [pageSettings]="pageSettings"
				  [allowReordering]="false"
				  [allowResizing]="false"
				  [allowRowDragAndDrop]="false"
				  [allowSelection]="true"
				  [allowSorting]="true"
				  class="table-rowlink"
				  [dataSource]="searchResults"
				  gridLines="Horizontal"
				  [pmsGridSubmit]="isSearching"
				  (queryCellInfo)="applyBackgroundColor($event)"
				  (rowSelected)="closeModal($event.data)">
			<e-columns>
				<e-column field="code" headerText="Code" width="75">
					<ng-template #template let-data>
						<div *ngIf="showDisabledWarning(data)">
							<span revTooltip
								  tooltipContent="This code is no longer billable on/after {{data.disabledDate | date: dateFormat}}">
								{{ data.code }}
							</span>
						</div>
						<div *ngIf="!showDisabledWarning(data)">
							<span>{{ data.code }}</span>
						</div>
					</ng-template>
				</e-column>
				<e-column field="shortDescription" headerText="Description"
						  clipMode="EllipsisWithTooltip"></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<div>
			<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()">Cancel</button>
		</div>
	</ng-template>
</ejs-dialog>

