import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { AgGridAngular } from 'ag-grid-angular';
import { PAGE_LENGTH, PAGE_LENGTH_DROPDOWN_OPTIONS } from '../../constants/pageLength.constants';
import { _isNil } from '../../utils/lodash/lodash';

@Component({
	selector: 'rev-ag-grid-pagination',
	templateUrl: './ag-grid-pagination.component.html',
	styles: [],
})
export class AgGridPaginationComponent implements AfterViewInit {

	@Input()
	agGrid: AgGridAngular;

	@ViewChild('dropdown')
	dropdown: DropDownListComponent;

	pageOptions = PAGE_LENGTH_DROPDOWN_OPTIONS;
	itemsPerPage = PAGE_LENGTH.PAGE_10;
	isGridReady = false;

	get visiblePages() {
		if (_isNil(this.agGrid)) {
			return [];
		}

		const currentPage = this.getCurrentPage();
		const totalPages = this.getTotalPages();
		const firstDisplayedPage = this.getPageGroup(currentPage);

		// On the last 10 or fewer pages
		if (this.isOnLastPageSet()) {
			return Array.from(Array((totalPages - firstDisplayedPage) + 1)).map((e, i) => firstDisplayedPage + i);
		} else {
			// else calculate which 10's group we're in
			return Array.from(Array(10)).map((e, i) => firstDisplayedPage + i);
		}
	}

	constructor() {
	}

	ngAfterViewInit(): void {
		this.agGrid.gridReady.subscribe(() => {
			this.isGridReady = true;
			this.preparePaginationDropdown();
		});
	}

	preparePaginationDropdown() {
		this.updateItemsPerPage();
		this.subscribeToPaginationChange();
	}

	updateItemsPerPage() {
		if (this.itemsPerPage !== this.agGrid.api.paginationGetPageSize()) {
			this.itemsPerPage = this.agGrid.api.paginationGetPageSize() || 0;
		}
		this.dropdown?.refresh();
	}

	subscribeToPaginationChange() {
		this.agGrid.paginationChanged.subscribe(() => {
			this.updateItemsPerPage();
		});
	}

	getCurrentPage() {
		return this.agGrid?.api?.paginationGetCurrentPage();
	}

	getCurrentPageDisplay() {
		return this.getTotalPages() === 0 ? 0 : (this.getCurrentPage() + 1);
	}

	/**
	 * Returns the first page of the page group based on the page input.
	 * i.e page 13 will return 11. page 28 will return 21.
	 */
	getPageGroup(page) {
		return (Math.floor((page / 10)) * 10) + 1;
	}

	getTotalItems() {
		return this.agGrid?.api?.paginationGetRowCount();
	}

	getTotalPages() {
		if (_isNil(this.agGrid?.api)) {
			return 0;
		}

		return this.agGrid.api.paginationGetTotalPages();
	}

	goToFirstPage() {
		this.goToPage(0);
	}

	goToNextPage() {
		this.goToPage(this.getCurrentPage() + 1);
	}

	goToPreviousPage() {
		this.goToPage(this.getCurrentPage() - 1);
	}

	goToLastPage() {
		this.goToPage(this.getTotalPages());
	}

	goToNextPageSet() {
		this.goToPage(this.getPageGroup((this.getCurrentPage() + 10)) - 1);
	}

	isOnFirstPage() {
		return this.getCurrentPage() === 0 || _isNil(this.agGrid?.api);
	}

	isOnLastPage() {
		return this.getCurrentPage() === this.getTotalPages() - 1;
	}

	isOnLastPageSet() {
		return (this.getTotalPages() - this.getCurrentPage()) < 10;
	}

	isOnPage(page: number) {
		return this.getCurrentPage() === page;
	}

	itemsPerPageChanged(event) {
		this.agGrid?.api?.paginationSetPageSize(event.value);
		if (event.isInteracted) {
			this.goToFirstPage();
		}
	}

	displayPagination() {
		if (!this.isGridReady || _isNil(this.agGrid?.gridOptions)) {
			return false;
		}

		return this.agGrid.gridOptions.suppressPaginationPanel && this.agGrid.gridOptions.pagination;
	}

	goToPage(page: number) {
		this.agGrid?.api?.paginationGoToPage(page);
	}

}
