<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-lg">
	<ng-template #header>
		<div class="dlg-template">Add a Patient</div>
	</ng-template>
	<ng-template #content>
		<form [formGroup]="componentForm" (ngSubmit)="save()" #templateForm="ngForm">
			<div class="row">
				<div class="col-sm-12">
					<gandalf-lib-validation-messages
							*ngIf="componentForm.invalid && templateForm.submitted"
							[form]="componentForm"
							[requestObj]="quickAddRequest">
					</gandalf-lib-validation-messages>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-8">
					<h4 class="section-title bg-default mrgn-btm">Basic Information</h4>
					<pms-basic-information-form formGroupName="patientBasicInformation"></pms-basic-information-form>
				</div>
				<div class="col-sm-12 col-md-4">
					<h4 class="section-title bg-default mrgn-btm">Home Address</h4>
					<div class="form-horizontal">
						<pms-address-form [isCreating]="true" [autoselectStateProvince]="true" formGroupName="address" class="form-layout-col-sm-4-8-md-3-9"></pms-address-form>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6 col-lg-4">
					<h4 class="section-title bg-default mrgn-btm">Other Information</h4>
					<pms-other-information-form formGroupName="patientOtherInformation"></pms-other-information-form>
				</div>
				<div class="col-md-6 col-lg-4">
					<h4 class="section-title bg-default mrgn-btm">Phone/Email</h4>
					<pms-phone-information-form formGroupName="patientPhoneInformation"></pms-phone-information-form>
				</div>
				<div class="col-md-6 col-lg-4">
					<h4 class="section-title bg-default mrgn-btm">Communication Opt-in</h4>
					<pms-communication-preferences-form formGroupName="patientCommunicationPreferences"
														[phoneInformationForm]="componentForm.get('patientPhoneInformation')">
					</pms-communication-preferences-form>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'quickAddPatientCancel'">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'quickAddPatientAdd'">Add</button>
	</ng-template>
</ejs-dialog>
