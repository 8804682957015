// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.TransferInvoiceBalanceToPatientRequest */
/* istanbul ignore next */
@GandalfModel
export class TransferInvoiceBalanceToPatientRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

	@GandalfValidator({ sizeString: { min: 1, max: 187, message: 'Comments must be between {min} and {max} characters' } })
	@GandalfValidator({ notNull: { message: 'Comments are required' } })
	@GandalfProperty({ isRequired: true })
	reason: string;

}
