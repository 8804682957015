// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { LegacyPracticeDiagnosisResponse } from './legacy-practice-diagnosis-response';

/** See com.rev360.legacy.api.controller.accounting.AdditionalDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class AdditionalDiagnosisResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate: Date;

	@GandalfProperty()
	personDiagnosisId: number;

	@GandalfProperty()
	practiceDiagnosis: LegacyPracticeDiagnosisResponse;

}
