import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { VoidPaymentRequest } from '@gandalf/model/void-payment-request';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GandalfFormBuilder } from 'gandalf';
import { AccountingService } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';

@Component({
	selector: 'pms-void-payment-with-comment-modal',
	templateUrl: './void-payment-with-comment-modal.component.html',
	styles: [],
})
export class VoidPaymentWithCommentModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;
	@ViewChild('templateForm')
	templateForm: NgForm;
	componentForm: UntypedFormGroup;
	voidPaymentRequest: VoidPaymentRequest;

	constructor(
		public ref: DynamicModalRef,
		public modalConfig: ModalConfig,
		public invoiceService: InvoiceService,
		private gandalfFormBuilder: GandalfFormBuilder,
		public accountingService: AccountingService,
	) { }

	ngOnInit() {
		this.createForm();
		this.initializeForm();
	}

	createForm() {
		this.voidPaymentRequest = new VoidPaymentRequest();
		this.voidPaymentRequest.paymentId = this.modalConfig.data.paymentId;
		this.componentForm = this.gandalfFormBuilder.group(this.voidPaymentRequest);
	}

	/**
	 * Used to initialize any form values
	 *   - set comments value to empty string so that it's length starts at 0 as this is used in the view
	 */
	initializeForm() {
		this.componentForm.controls.comments.setValue('');
	}

	submitForm(event: any) {
		this.templateForm.onSubmit(event);
	}

	closeModal() {
		this.ref.close(this.modal);
	}

	/**
	 * Checks the validity of the form.  If invalid leaves the method.
	 * If valid it used Gandalf the Black to call the legacy java code and void the invoice. Once complete
	 * the invoice is refreshed with the updated data and the modal is closed.
	 */
	voidPayment() {
		if (this.componentForm.invalid) {
			return;
		}

		const request: VoidPaymentRequest = new VoidPaymentRequest();
		request.paymentId = this.modalConfig.data.paymentId;
		request.comments = this.componentForm.controls.comments.value;

		this.accountingService.voidPayment(request).subscribe(() => {
			this.invoiceService.refreshInvoice(this.modalConfig.data.invoiceId);
			this.closeModal();
		});
	}
}
