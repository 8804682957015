<ejs-dialog #modal
			cssClass="modal-sm"
			(close)="closeModal()"
			[attr.data-test-id]="'trizettoCredentialModal'">
	<ng-template #header>
		<div class="dlg-template">{{(isCreate ? "New" : "Edit") + " Credentials"}}</div>
	</ng-template>
	<ng-template #content>
		<form #ngForm="ngForm" [formGroup]="formGroup" class="form-horizontal" (ngSubmit)="saveCredential()">
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && ngForm.submitted"
					[form]="formGroup"
					[requestObj]="request">
			</gandalf-lib-validation-messages>
			<div class="col-sm-12">
				<div class="form-group" [attr.data-test-id]="'trizettoCredentialModalUserNameFormGroup'">
					<label class="col-sm-4 control-label required">Site ID</label>
					<div class="col-sm-8">
						<input type="text" class="e-input" formControlName="userName">
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'trizettoCredentialModalPasswordFormGroup'">
					<label class="col-sm-4 control-label required">Password</label>
					<div class="col-sm-8">
						<input type="text" class="e-input" formControlName="password">
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'trizettoCredentialModalLocationsFormGroup'">
					<label class="col-sm-4 control-label required">Location(s)</label>
					<div class="col-sm-8">
						<pms-multiselect
								formControlName="locationIds"
								[dataSource]="availableLocations"
								inputPlaceholder="Select Location(s)"
								filterBarPlaceholder="Search"
								labelField="name"
								valueField="id"
								[showSelectAll]="false">
						</pms-multiselect>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<rev-button buttonIcon="times" buttonLabel="Cancel" (buttonClick)="closeModal()" dataTestId="trizettoCredentialModalCancelButton"></rev-button>
		<rev-button buttonType="submit" buttonStyle="primary" buttonLabel="Save" (buttonClick)="submitForm($event)" dataTestId="trizettoCredentialModalSaveButton"></rev-button>
	</ng-template>
</ejs-dialog>
