<div class="form-horizontal" [formGroup]="componentForm">
	<div class="form-group" [attr.data-test-id]="'preferredPhoneFormGroup'">
		<label class="col-sm-3 control-label required">Pref.</label>
		<div class="col-sm-9">
			<pms-enum-select-button
				[enumList]="preferredPhoneTypeOptions"
				formControlName="preferredPhoneType">
			</pms-enum-select-button>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'homePhoneFormGroup'">
		<label class="col-sm-3 control-label" [class.required]="isHomePhoneRequired">Home</label>
		<div class="col-sm-9">
			<div class="row">
				<div class="col-xs-7">
					<pms-phone-number-input formControlName="homePhone" ></pms-phone-number-input>
				</div>
				<div class="col-xs-5">
					<div class="e-input-group">
						<span *ngIf="componentForm.get('homePhoneExtension').enabled" class="e-input-group-icon">x</span>
						<input class="e-input" type="text" formControlName="homePhoneExtension"/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'workPhoneFormGroup'">
		<label class="col-sm-3 control-label" [class.required]="isWorkPhoneRequired">Work</label>
		<div class="col-sm-9">
			<div class="row">
				<div class="col-xs-7">
					<pms-phone-number-input formControlName="workPhone"></pms-phone-number-input>
				</div>
				<div class="col-xs-5">
					<div class="e-input-group">
						<span *ngIf="componentForm.get('workPhoneExtension').enabled" class="e-input-group-icon">x</span>
						<input class="e-input" type="text" formControlName="workPhoneExtension"/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'cellPhoneFormGroup'">
		<label class="col-sm-3 control-label" [class.required]="isCellPhoneRequired">Cell</label>
		<div class="col-sm-9">
			<div *ngIf="showCellPhoneDeactivated()" class="alert alert-danger">
				Flagged as deactivated by cell phone carrier on {{patientResponse.cellPhoneDeactivatedDate | date : dateFormat}}. <a (click)="resolveCellPhone()">Resolve</a>
			</div>
			<div class="row">
				<div class="col-xs-7">
					<pms-phone-number-input [class.with-e-error]="showCellPhoneDeactivated()" formControlName="cellPhone"></pms-phone-number-input>
				</div>
				<div class="col-xs-5">
					<div class="e-input-group">
						<span *ngIf="componentForm.get('cellPhoneExtension').enabled" class="e-input-group-icon">x</span>
						<input class="e-input" type="text" formControlName="cellPhoneExtension"/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'pagerFormGroup'">
		<label class="col-sm-3 control-label">Pager</label>
		<div class="col-sm-9">
			<pms-phone-number-input formControlName="pager"></pms-phone-number-input>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'emailFormGroup'">
		<label class="col-sm-3 control-label" [class.required]="isEmailRequired">Email</label>
		<div class="col-sm-9">
			<input class="e-input margin-bottom-sm" type="text" formControlName="email"/>
			<ejs-checkbox label="Declined to provide email" formControlName="emailDeclined"></ejs-checkbox>
		</div>
	</div>
</div>
