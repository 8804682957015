export const INTER_APP_CONSTANTS = {
	REV360: {
		ORDERS: {
			OPEN_ORDER: 'REV360_OPEN_ORDER',
			CLOSE_ORDER: 'REV360_CLOSE_ORDER',
			OPEN_ORDER_FROM_SUMMARY_POD: 'REV360_OPEN_ORDER_FROM_SUMMARY_POD',
		},
		SCHEDULE: {
			OPEN_SCHEDULE_TO_DATE: 'OPEN_SCHEDULE_TO_DATE',
			OPEN_SCHEDULE_TO_APPOINTMENT: 'OPEN_SCHEDULE_TO_APPOINTMENT',
			NEW_APPOINTMENT_FROM_WAITLIST: 'NEW_APPOINTMENT_FROM_WAITLIST',
			NEW_APPOINTMENT_FOR_DATE: 'NEW_APPOINTMENT_FOR_DATE',
			NEW_APPOINTMENT_FOR_PATIENT: 'NEW_APPOINTMENT_FOR_PATIENT',
		},
		PATIENTS: {
			PATIENT_APP_LOADED: 'REV_360_PATIENT_APP_LOADED',
			SELECT_PATIENT_MODULE: 'REV_360_SELECT_PATIENT_MODULE',
			OPEN_PATIENT_MODULE: 'REV_360_OPEN_PATIENT_MODULE',
			CLOSE_PATIENT_MODULE: 'REV_360_CLOSE_PATIENT_MODULE',
			SYNC_PATIENT_FLEX: 'REV_360_SYNC_PATIENT_FLEX',
			REFRESH_PATIENT_APPOINTMENTS: 'PATIENTS_MODULE_REFRESH_PATIENT_APPOINTMENTS',
			REFRESH_PATIENT_EXAM_HISTORY_POD: 'HISTORY_SUMMARY_UPDATED',
			FAMILY_MEMBER_QUICK_ADD: 'REV_360_FAMILY_MEMBER_QUICK_ADD',
			CONTACT_QUICK_ADD: 'REV_360_CONTACT_QUICK_ADD',
			ENCOUNTER_QUICK_ADD: 'REV_360_ENCOUNTER_QUICK_ADD',
			OPEN_ENCOUNTER: 'REV_360_OPEN_ENCOUNTER',
			OPEN_APPOINTMENT: 'REV_360_OPEN_APPOINTMENT',
			PATIENT_FLEX_COMPONENT_SELECT: 'REV_360_PATIENT_FLEX_COMPONENT_SELECT',
			PATIENTS_SEARCH_DASHBOARD_SELECTED: 'REV_360_PATIENTS_SEARCH_DASHBOARD_SELECTED',
			OPEN_POD: 'REV_360_OPEN_POD',
			PATIENTS_SUMMARY_DASHBOARD_SELECT_TAB_FOR_PATIENT: 'REV_360_PATIENTS_SUMMARY_DASHBOARD_SELECT_TAB_FOR_PATIENT',
			PATIENT_TAB_MODULE_CLOSED: 'REV_360_PATIENT_TAB_MODULE_CLOSED',
			CREATE_LEGACY_PATIENT_DASHBOARD: 'CREATE_LEGACY_PATIENT_DASHBOARD',
			GET_PATIENT_SCREEN: 'GET_PATIENT_SCREEN',
			PATIENTS_MODULE_CONVERT_DIAGNOSIS_CODES: 'REV_360_SWITCH_TO_DIAGNOSES_CONVERSION',
			EXPAND_POD: 'REV_360_EXPAND_POD',
			ADD_POD: 'REV_360_ADD_POD',
			ADD_POD_LEGACY: 'REV_360_POD_ADD_LEGACY',
			OPEN_POD_ITEM: 'REV_360_OPEN_POD_ITEM',
			COMPONENT_ADD: 'REV_360_COMPONENT_ADD',
			REMOVING_DASHBOARD: 'REV_360_REMOVING_DASHBOARD',
			NOTES: {
				NEW_NOTE: 'REV_360_PATIENTS_NEW_NOTE',
			},
			PATIENTS_MODULE_OPEN_PATIENT: 'REV_360_PATIENTS_MODULE_OPEN_PATIENT',
			OPEN_COMMUNICATION_MODAL: 'REV_360_OPEN_COMMUNICATION_MODAL',
			OPEN_PATIENT_ITEM: 'REV_360_OPEN_PATIENT_ITEM',
			PATIENT_COMPONENT_SELECT: 'REV_360_PATIENT_COMPONENT_SELECT',
			PATIENT_TAB_CLOSED: 'REV_360_PATIENT_TAB_CLOSED',
			FLEX_PATIENT_TAB_CHANGED: 'REV_360_FLEX_PATIENT_TAB_CHANGED',
			ENCOUNTER_CHECKOUT_TASK_ASSIGNED: 'REV_360_ENCOUNTER_CHECKOUT_TASK_ASSIGNED',
		},
		ADMIN: {
			OPEN_LOCATION: 'REV_360_OPEN_LOCATION',
		},
		CONNECT: {
			TWO_WAY_TEXTING_UNREAD_CONVERSATIONS_COUNT: 'TWO_WAY_TEXTING_UNREAD_CONVERSATIONS_COUNT',
		},
		RECONCILIATION: {
			OPEN_PRINT_VIEW: 'REV_360_OPEN_RECONCILIATION_PRINT_VIEW',
			LOAD_DATA: 'REV_360_LOAD_RECONCILIATION_DATA',
		},
	},
};

