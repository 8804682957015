import { AfterViewInit, Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';

@Directive({
	/* eslint-disable-next-line @angular-eslint/directive-selector */
	selector: 'ejs-datepicker:not([autoFormatDateOverride])',
})
export class AutoFormatDateDirective implements OnInit, AfterViewInit {
	private isFlagOn = false;
	private previousValue = '';

	constructor(
		private featureService: FeatureService,
		private elementRef: ElementRef,
		private renderer2: Renderer2,
	) {
	}

	ngOnInit() {
		this.isFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.AUTOFORMAT_DATEPICKER);
	}

	ngAfterViewInit() {
		// First child node is a span. its first childnode is the input we care about
		this.renderer2.setAttribute(this.elementRef.nativeElement.childNodes[0].childNodes[0], 'inputmode', 'numeric');
	}

	@HostListener('keyup', ['$event'])
	onKeyUp(e) {
		if (this.isFlagOn) {
			const input = e.target;
			if (input) {
				const currentValue = input.value;
				const newValue = this.processValue(currentValue, this.previousValue);
				if (newValue !== currentValue) {
					input.value = newValue;
				}
				this.previousValue = input.value;
			}
		}
	}

	processValue(currentValue: string, previousValue: string) {
		// If any character is not a digit or /  then return previousValue
		const characterRestrictionRegex = /^[0-9/]*$/;
		if (!currentValue.match(characterRestrictionRegex)) {
			return previousValue;
		}

		// If length is 2 or 5 add a /
		// if length is 3 or 6 and 2 or 5 is not / insert it in the correct spot
		if (previousValue?.slice(-1) !== '/') {
			if (currentValue?.length === 2 || currentValue?.length === 5) {
				currentValue += '/';
			} else if (currentValue?.length === 3 && currentValue[2] !== '/') {
				currentValue = currentValue.substring(0, 2) + '/' + currentValue.slice(-1);
			} else if (currentValue?.length === 6 && currentValue[5] !== '/') {
				currentValue = currentValue.substring(0, 5) + '/' + currentValue.slice(-1);
			}
		}

		// Check if value matches the pattern dd/dd/dddd if it doesn't return previous value
		const validString = '00/00/0000';
		const lengthDiff = validString.length - currentValue.length;
		const amalgamation = currentValue + (lengthDiff <= 0 ? '' : validString.substring(validString.length - lengthDiff));

		const patternRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
		if (!amalgamation.match(patternRegex)) {
			return previousValue;
		}
		return currentValue;
	}
}
