import { Component, OnInit, ViewChild } from '@angular/core';
import { DiagnosisService, SortablePersonDiagnosisResponse } from '@core/diagnosis/diagnosis.service';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FileService } from '@core/file/file.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { _isNil, _map } from '@core/lodash/lodash';
import { DialogUtil, DynamicModalRef, ModalConfig, PAGE_LENGTH, PAGE_LENGTH_LIST } from 'morgana';
import { PatientService } from '@core/patient/patient.service';
import { ContinuityOfCareImportResultResponse } from '@gandalf/model/continuity-of-care-import-result-response';
import { ContinuityOfCareImportResultResponse as LegacyContinuityOfCareImportResultResponse } from '@gandalf-black/model/continuity-of-care-import-result-response';
import { DrugAllergyImportResponse } from '@gandalf/model/drug-allergy-import-response';
import { DrugAllergyResponse } from '@gandalf/model/drug-allergy-response';
import { IncorporateDrugAllergyRequest } from '@gandalf/model/incorporate-drug-allergy-request';
import { IncorporateDrugAllergyUpdateRequest } from '@gandalf/model/incorporate-drug-allergy-update-request';
import { IncorporateExternalDataRequest } from '@gandalf/model/incorporate-external-data-request';
import { IncorporateMedicationPrescriptionRequest } from '@gandalf/model/incorporate-medication-prescription-request';
import { IncorporatePersonDiagnosisRequest } from '@gandalf/model/incorporate-person-diagnosis-request';
import { MedicationPrescriptionImportResponse } from '@gandalf/model/medication-prescription-import-response';
import { PersonDiagnosisImportResponse } from '@gandalf/model/person-diagnosis-import-response';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { ClipboardUtil } from '@shared/Utils/ClipboardUtil';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel, SortSettingsModel } from '@syncfusion/ej2-grids';
import { combineLatest } from 'rxjs';
import { AllergyService } from '../../../patients/allergies/allergy.service';
import { MedicationPrescriptionService, PrescriptionForTable } from '../../../patients/medication-prescription/medication-prescription.service';
import { NewEntityUniqueIdService } from '../../../services/new-entity-unique-id-service/new-entity-unique-id.service';


// Imported results don't have an identifier but we need one to identify them so we generate one on all of them
export interface FormattedPersonDiagnosisImportResponse extends PersonDiagnosisImportResponse {
	id: number;
}

export interface FormattedDrugAllergyImportResponse extends DrugAllergyImportResponse {
	id: number;
	reactionsDisplayValue: string;
}

export interface FormattedDrugAllergyResponse extends DrugAllergyResponse {
	reactionsDisplayValue: string;
}

export interface FormattedMedicationPrescriptionImportResponse extends MedicationPrescriptionImportResponse {
	id: number;
}

export enum IncorporateDocumentState {
	PARSING = 'PARSING',
	RECOVERABLE_PARSING_ERROR = 'ERROR',
	SEVERE_ERROR = 'SEVERE_ERROR',
	UNKNOWN_FILE_RESULT = 'UNKNOWN_FILE_RESULT',
	RECONCILIATION = 'RECONCILIATION',
	FINISH = 'FINISH',
}

@Component({
	selector: 'pms-incorporate-document-modal',
	templateUrl: './incorporate-document-modal.component.html',
})
export class IncorporateDocumentModalComponent implements OnInit {
	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('reconciliationTabs')
	reconciliationTabs: TabComponent;

	private patientFileId: number;
	private patientId: number;
	importResult: ContinuityOfCareImportResultResponse | LegacyContinuityOfCareImportResultResponse;
	currentState: IncorporateDocumentState = IncorporateDocumentState.PARSING;
	tabAnimation = TabAnimationDefaults;
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;

	currentReconciliationTab = 0;
	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};
	originalPatientDiagnoses: SortablePersonDiagnosisResponse[];
	currentPatientDiagnoses: SortablePersonDiagnosisResponse[];
	originalMedicationPrescriptions: PrescriptionForTable[];
	currentMedicationPrescriptions: PrescriptionForTable[];
	originalDrugAllergies: DrugAllergyResponse[];
	currentDrugAllergies: FormattedDrugAllergyResponse[];

	importedDiagnoses: FormattedPersonDiagnosisImportResponse[];
	importedAllergies: FormattedDrugAllergyImportResponse[];
	importedMedicationPrescriptions: FormattedMedicationPrescriptionImportResponse[];
	sortSettings: SortSettingsModel = {
		columns: [
			{field: 'startDate', direction: 'Descending'},
		],
	};
	consolidatedDiagnoses: FormattedPersonDiagnosisImportResponse[] = null;
	consolidatedMedications: FormattedMedicationPrescriptionImportResponse[] = null;
	consolidatedAllergies: FormattedDrugAllergyImportResponse[] = null;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		public config: ModalConfig,
		public patientService: PatientService,
		private eventsManagerService: EventsManagerService,
		private diagnosisService: DiagnosisService,
		private medicationPrescriptionService: MedicationPrescriptionService,
		private allergyService: AllergyService,
		private newEntityUniqueIdService: NewEntityUniqueIdService,
		private fileService: FileService,
	) {
	}

	ngOnInit() {
		this.parseModalConfig(this.config.data);
		this.parsePatientFile(false);
	}

	private parsePatientFile(isCdaValidation: boolean) {
		this.patientService.parseExternalPatientDataFile(this.patientFileId, isCdaValidation).subscribe({
			next: result => {
				this.handleParseResult(result);
			},
			error: () => {
				this.currentState = IncorporateDocumentState.SEVERE_ERROR;
			},
		});
	}

	handleParseResult(result) {
		if (result.hasHaltingError) {
			this.currentState = IncorporateDocumentState.SEVERE_ERROR;
			this.showAlertAndClose('Error Parsing Document', 'The was an error parsing the document.');
			return;
		}
		if (result instanceof ContinuityOfCareImportResultResponse || result instanceof LegacyContinuityOfCareImportResultResponse) {
			this.importResult = result;
			if (result.isSuccessful) {
				this.resetImportLists();
				this.currentState = IncorporateDocumentState.RECONCILIATION;
				this.prepareForReconciliation();
			} else {
				this.currentState = IncorporateDocumentState.RECOVERABLE_PARSING_ERROR;
			}
		} else {
			this.currentState = IncorporateDocumentState.UNKNOWN_FILE_RESULT;
			this.showAlertAndClose('Unknown Result Type', 'The file could not be parsed.');
		}
	}

	private prepareForReconciliation() {
		combineLatest([
			this.diagnosisService.findActiveMasterDiagnosesByPatientId(this.patientId),
			this.medicationPrescriptionService.findActiveByPatientId(this.patientId),
			this.allergyService.findActiveDrugAllergies(this.patientId),
		]).subscribe(([diagnoses, prescriptions, drugAllergies]) => {
			this.originalPatientDiagnoses = diagnoses;
			this.resetCurrentPatientDiagnoses();
			this.originalMedicationPrescriptions = prescriptions;
			this.resetCurrentPatientMedicationPrescriptions();
			this.originalDrugAllergies = drugAllergies;
			this.resetCurrentPatientDrugAllergies();
		},
		);

	}

	private resetCurrentPatientDrugAllergies() {
		this.currentDrugAllergies = this.originalDrugAllergies.map(allergy => this.formatDrugAllergy(allergy));
	}

	formatDrugAllergy(drugAllergy: DrugAllergyResponse): FormattedDrugAllergyResponse {
		return {
			...drugAllergy,
			reactionsDisplayValue: this.getReactionDisplayValue(drugAllergy.reactions),
		};
	}

	private resetCurrentPatientMedicationPrescriptions() {
		this.currentMedicationPrescriptions = [...this.originalMedicationPrescriptions];
	}

	private resetCurrentPatientDiagnoses() {
		this.currentPatientDiagnoses = [...this.originalPatientDiagnoses];
	}

	/**
	 * Create a duplicate of all of the table lists to mutate but keep the original to reset back to
	 */
	private resetImportLists() {
		this.resetImportedDiagnoses();
		this.resetImportedAllergies();
		this.resetImportedMedications();
	}

	private resetImportedMedications() {
		this.importedMedicationPrescriptions = this.importResult.medicationPrescriptions.map(
			rx => this.formatLegacyMedicationRx(rx));
	}

	private resetImportedAllergies() {
		this.importedAllergies = this.importResult.drugAllergies.map(allergy => this.formatLegacyDrugAllergy(allergy));
	}

	private resetImportedDiagnoses() {
		this.importedDiagnoses = this.importResult.diagnoses.map(dx => this.formatLegacyDx(dx));
	}

	private showAlertAndClose(title: string, content: string) {
		DialogUtil.alert({
			title,
			content,
		});
		this.closeDialog();
	}

	parseModalConfig(data: any) {
		this.patientFileId = data.patientFileId;
		this.patientId = data.patientId;
	}

	closeDialog() {
		this.dynamicModalRef.close(this.modal);
	}

	copyWarningsAndErrorsToClipboard() {
		const text = [...this.importResult.errorMessages, ...this.importResult.warningMessages].join('\n');
		ClipboardUtil.copyTextToClipboard(text);
	}

	isParsing() {
		return this.currentState === IncorporateDocumentState.PARSING;
	}

	isShowingParsingErrors() {
		return this.currentState === IncorporateDocumentState.RECOVERABLE_PARSING_ERROR;
	}

	isReconciling() {
		return this.currentState === IncorporateDocumentState.RECONCILIATION;
	}

	isFinishing() {
		return this.currentState === IncorporateDocumentState.FINISH;
	}

	continueToNextStep() {
		if (this.isShowingParsingErrors()) {
			this.currentState = IncorporateDocumentState.PARSING;
			this.parsePatientFile(true);
		} else if (this.isReconciling()) {
			this.goToNextTab();
		}
	}

	goToNextTab() {
		if (this.currentReconciliationTab < 3) {
			const nextTab = this.currentReconciliationTab + 1;
			this.reconciliationTabs.enableTab(nextTab, true);
			this.reconciliationTabs.select(nextTab);
			this.reconciliationTabs.enableTab(this.currentReconciliationTab, false);
			this.currentReconciliationTab = nextTab;
		} else {
			this.confirmIncorporation();
		}
	}

	confirmIncorporation() {
		const confirmDialog = DialogUtil.confirm({
			title: 'Confirm Incorporation',
			content: 'Are you sure you want to proceed with incorporation of the clinical data verified in the previous steps?',
			okButton: {
				click: () => {
					this.currentState = IncorporateDocumentState.FINISH;
					confirmDialog.close();
					this.finishIncorporation();
				},
			},
		});
	}

	backStep() {
		if (this.isReconciling()) {
			if (this.currentReconciliationTab > 0) {
				const nextTab = this.currentReconciliationTab - 1;
				this.reconciliationTabs.enableTab(nextTab, true);
				this.reconciliationTabs.select(nextTab);
				this.reconciliationTabs.enableTab(this.currentReconciliationTab, false);
				this.currentReconciliationTab = nextTab;
			}
		}
	}

	viewDocument() {
		const request = {patientId: this.importResult.patientId, fileId: this.importResult.fileId, patientFileId: this.importResult.patientFileId};
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.PATIENTS.PATIENTS_MODULE_VIEW_HTML_CCDA, request);
	}

	getTitle() {
		if (this.isParsing()) {
			return 'Processing Document';
		} else if (this.isShowingParsingErrors()) {
			return 'Errors';
		} else if (this.isReconciling()) {
			return 'Incorporate Document';
		} else if (this.isFinishing()) {
			return 'Incorporating Document';
		}
	}

	removeFromPatientDiagnoses(data: SortablePersonDiagnosisResponse) {
		this.currentPatientDiagnoses = this.currentPatientDiagnoses.filter(diagnosis => diagnosis.id !== data.id);
	}

	removeFromImportedDiagnoses(data: FormattedPersonDiagnosisImportResponse) {
		this.importedDiagnoses = this.importedDiagnoses.filter(diagnosis => diagnosis.id !== data.id);
	}

	removeFromPatientMedicationPrescriptions(data: PrescriptionForTable) {
		this.currentMedicationPrescriptions = this.currentMedicationPrescriptions.filter(rx => rx.id !== data.id);
	}

	removeFromImportedMedicationPrescriptions(data: FormattedMedicationPrescriptionImportResponse) {
		this.importedMedicationPrescriptions = this.importedMedicationPrescriptions.filter(rx => rx.id !== data.id);
	}

	removeFromPatientDrugAllergies(data: DrugAllergyResponse) {
		this.currentDrugAllergies = this.currentDrugAllergies.filter(allergy => allergy.id !== data.id);
	}

	removeFromImportedDrugAllergies(data: FormattedDrugAllergyImportResponse) {
		this.importedAllergies = this.importedAllergies.filter(allergy => allergy.id !== data.id);
	}

	private formatLegacyDx(dx: PersonDiagnosisImportResponse): FormattedPersonDiagnosisImportResponse {
		return {
			...dx,
			id: this.newEntityUniqueIdService.nextId,
		};
	}

	private formatLegacyDrugAllergy(dx: DrugAllergyImportResponse): FormattedDrugAllergyImportResponse {
		return {
			...dx,
			id: this.newEntityUniqueIdService.nextId,
			reactionsDisplayValue: this.getReactionDisplayValue(dx.reactions),
		};
	}

	private getReactionDisplayValue(reactions): string {
		return reactions?.map(reaction => reaction.value).join(', ') ?? '';
	}

	private formatLegacyMedicationRx(dx: MedicationPrescriptionImportResponse): FormattedMedicationPrescriptionImportResponse {
		return {
			...dx,
			id: this.newEntityUniqueIdService.nextId,
		};
	}

	mergeDiagnoses(data: FormattedPersonDiagnosisImportResponse, currentPatientDxGrid: GridComponent) {
		this.removeFromImportedDiagnoses(data);
		currentPatientDxGrid.clearSelection();
	}

	mergeMedications(data: FormattedMedicationPrescriptionImportResponse, currentPatientMedicationsGrid: GridComponent) {
		this.removeFromImportedMedicationPrescriptions(data);
		currentPatientMedicationsGrid.clearSelection();
	}

	mergeAllergies(data: FormattedDrugAllergyImportResponse, currentPatientDrugAllergiesGrid: GridComponent) {
		const currentSelectedDrugAllergies = currentPatientDrugAllergiesGrid.getSelectedRecords();
		this.mergeReactions(data, currentSelectedDrugAllergies[0] as DrugAllergyResponse);

		this.removeFromImportedDrugAllergies(data);
		currentPatientDrugAllergiesGrid.clearSelection();
	}

	mergeReactions(data: FormattedDrugAllergyImportResponse, currentSelectedDrugAllergy: DrugAllergyResponse) {
		const patientAllergyReactionIds = currentSelectedDrugAllergy.reactionIds;

		const newReactions = data.reactions.filter(reaction => !patientAllergyReactionIds?.includes(reaction.id));

		const selectedAllergyIndex = this.currentDrugAllergies.findIndex(drugAllergy => drugAllergy.id === currentSelectedDrugAllergy.id);
		if (_isNil(this.currentDrugAllergies[selectedAllergyIndex].reactions)) {
			this.currentDrugAllergies[selectedAllergyIndex].reactions = [
				...newReactions,
			];
		} else {
			this.currentDrugAllergies[selectedAllergyIndex].reactions = [
				...this.currentDrugAllergies[selectedAllergyIndex].reactions,
				...newReactions,
			];
		}
	}

	resetDiagnoses() {
		this.resetImportedDiagnoses();
		this.resetCurrentPatientDiagnoses();
	}

	resetMedications() {
		this.resetImportedMedications();
		this.resetCurrentPatientMedicationPrescriptions();
	}

	resetDrugAllergies() {
		this.resetImportedAllergies();
		this.resetCurrentPatientDrugAllergies();
	}

	consolidateDiagnoses() {
		this.consolidatedDiagnoses = [
			...this.importedDiagnoses,
			...this.currentPatientDiagnoses.map((dx) => this.diagnosesToLegacyFormat(dx)),
		];
	}

	consolidateMedications() {
		this.consolidatedMedications = [
			...this.importedMedicationPrescriptions,
			...this.currentMedicationPrescriptions.map((rx) => this.medicationToLegacyFormat(rx)),
		];
	}

	consolidateAllergies() {
		this.consolidatedAllergies = [
			...this.importedAllergies,
			...this.currentDrugAllergies.map((allergy) => this.allergiesToLegacyFormat(allergy)),
		];
	}

	removeFromConsolidatedDiagnoses(data: FormattedPersonDiagnosisImportResponse) {
		this.consolidatedDiagnoses = this.consolidatedDiagnoses.filter(diagnoses => diagnoses.id !== data.id);
	}
	removeFromConsolidatedDrugAllergies(data: FormattedDrugAllergyImportResponse) {
		this.consolidatedAllergies = this.consolidatedAllergies.filter(diagnoses => diagnoses.id !== data.id);
	}
	removeFromConsolidatedMedicationPrescriptions(data: FormattedMedicationPrescriptionImportResponse) {
		this.consolidatedMedications = this.consolidatedMedications.filter(diagnoses => diagnoses.id !== data.id);
	}

	private diagnosesToLegacyFormat(data: SortablePersonDiagnosisResponse): FormattedPersonDiagnosisImportResponse {
		return {
			...new PersonDiagnosisImportResponse(),
			id: data.id,
			diagnosisDate: data.diagnosisDate,
			code: data.code,
			shortDescription: data.shortDescription,
			status: data.status,
			lastModificationDate: data.updatedOn,
		} as FormattedPersonDiagnosisImportResponse;
	}

	private medicationToLegacyFormat(data: PrescriptionForTable): FormattedMedicationPrescriptionImportResponse {
		return {
			...new MedicationPrescriptionImportResponse(),
			id: data.id,
			code: data.drugCode,
			description: data.description,
			lastModificationDate: data.modifiedDate,
			derivedStatus: data.statusForDisplay,
			startDate: data.startDate,
		} as FormattedMedicationPrescriptionImportResponse;
	}

	private allergiesToLegacyFormat(data: DrugAllergyResponse): FormattedDrugAllergyImportResponse {
		return {
			...new DrugAllergyImportResponse(),
			id: data.id,
			drugCode: data.drugCode,
			drugName: data.displayName,
			lastModificationDate: data.updatedDate,
			startDate: data.startDate,
			status: data.status,
			endDate: data.endDate,
			description: data.description,
			reactions: data.reactions,
			reactionsDisplayValue: this.getReactionDisplayValue(data.reactions),
		} as FormattedDrugAllergyImportResponse;
	}

	undoAllergyConsolidation() {
		this.consolidatedAllergies = null;
	}
	undoMedicationConsolidation() {
		this.consolidatedMedications = null;
	}
	undoDiagnosesConsolidation() {
		this.consolidatedDiagnoses = null;
	}

	showContinueButton() {
		return !this.isParsing()
			&& (!this.isReconciling() || this.currentReconciliationTab < 3 || this.consolidated)
			&& !this.isFinishing();
	}

	get consolidated() {
		return !!(this.consolidatedMedications && this.consolidatedAllergies && this.consolidatedDiagnoses);
	}

	private finishIncorporation() {
		const request = this.prepareIncorporationRequest();

		this.fileService.incorporateExternalData(request).subscribe(() => {
			this.closeDialog();
		});
	}

	private prepareIncorporationRequest() {
		const request = new IncorporateExternalDataRequest();

		request.diagnosesToAdd = this.consolidatedDiagnoses.filter(dx => dx.id < 0).map((dx) => this.prepareDiagnosisForAdd(dx));
		request.personDiagnosisIdsToRemove = _map(this.originalPatientDiagnoses, 'id')
			.filter(originalDxId => !_map(this.consolidatedDiagnoses, 'id').includes(originalDxId));

		request.drugAllergiesToAdd = this.consolidatedAllergies
			.filter(allergy => allergy.id < 0).map((allergy) => this.prepareAllergyForAdd(allergy));
		request.drugAllergiesToUpdate = this.consolidatedAllergies
			.filter(allergy => allergy.id > 0).map((allergy) => this.prepareAllergyForUpdate(allergy));
		request.drugAllergyIdsToRemove = _map(this.originalDrugAllergies, 'id')
			.filter(originalDxId => !_map(this.consolidatedAllergies, 'id').includes(originalDxId));

		request.medicationPrescriptionsToAdd = this.consolidatedMedications
			.filter(rx => rx.id < 0).map((rx) => this.prepareMedicationForAdd(rx));
		request.medicationPrescriptionIdsToRemove = _map(this.originalMedicationPrescriptions, 'id')
			.filter(originalDxId => !_map(this.consolidatedMedications, 'id').includes(originalDxId));

		request.patientId = this.patientId;
		return request;
	}

	private prepareDiagnosisForAdd(imported: FormattedPersonDiagnosisImportResponse): IncorporatePersonDiagnosisRequest {
		const addRequest = new IncorporatePersonDiagnosisRequest();
		addRequest.code = imported.code;
		addRequest.diagnosisDate = imported.diagnosisDate;
		addRequest.longDescription = imported.longDescription;
		addRequest.masterCodeSet = imported.codeSet;
		addRequest.shortDescription = imported.shortDescription;
		return addRequest;
	}

	private prepareAllergyForAdd(drugAllergy: FormattedDrugAllergyImportResponse): IncorporateDrugAllergyRequest {
		const addRequest = new IncorporateDrugAllergyRequest();
		addRequest.startDate = drugAllergy.startDate;
		addRequest.allergyDescription = drugAllergy.description;
		addRequest.endDate = drugAllergy.endDate;
		if (!_isNil(drugAllergy.snomedCodeCode)) {
			addRequest.snomedCodeCode = drugAllergy.snomedCodeCode;
		} else {
			addRequest.rxNormConceptId = drugAllergy.drugCode;
		}
		addRequest.reactionIds = drugAllergy.reactions?.map(allergy => allergy.id) ?? [];
		return addRequest;
	}

	private prepareAllergyForUpdate(drugAllergy: FormattedDrugAllergyImportResponse): IncorporateDrugAllergyUpdateRequest {
		const updateRequest = new IncorporateDrugAllergyUpdateRequest();
		updateRequest.allergyId = drugAllergy.id;
		updateRequest.reactionIds = drugAllergy.reactions?.map(allergy => allergy.id) ?? [];
		return updateRequest;
	}

	private prepareMedicationForAdd(medication: FormattedMedicationPrescriptionImportResponse): IncorporateMedicationPrescriptionRequest {
		const addRequest = new IncorporateMedicationPrescriptionRequest();
		addRequest.rxNormConceptId = medication.code;
		addRequest.startDate = medication.startDate;
		addRequest.stopDate = medication.stopDate;
		return addRequest;
	}
}
