<ejs-dialog #modal (close)="closeModal()" cssClass="modal-md" [attr.data-test-id]="'selectExternalProviderModal'">
	<ng-template #header>
		<div class="dlg-template">Select Provider</div>
	</ng-template>
	<ng-template #content>
		<ejs-grid
				#grid
				[dataSource]="externalProviders"
				*ngIf="externalProviders"
				autoFitColumns
				gridLines="Horizontal"
				class="table-rowlink"
				[allowFiltering]="true"
				[allowSelection]="true"
				[allowResizing]="true"
				[allowSorting]="true"
				[sortSettings]="sortOptions"
				(created)="filterTable()"
				(rowSelected)="closeModal($event.data)"
				[attr.data-test-id]="'selectExternalProviderModalGrid'">
			<ng-template #toolbarTemplate>
				<rev-table-action-menu [table]="grid">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item">
							<label>Status</label>
							<pms-enum-select-button
									name="status"
									[(ngModel)]="statusFilter"
									[enumList]="statusFilterOptions"
									[allOption]="true"
                                    (valueChange)="filterTable()">
							</pms-enum-select-button>
						</div>
					</ng-container>
				</rev-table-action-menu>
			</ng-template>
			<e-columns>
				<e-column headerText="Name" field="lastName">
					<ng-template #template let-data>
						{{ getProviderName(data) }}
					</ng-template>
				</e-column>
				<e-column headerText="Type" field="providerType.description"></e-column>
				<e-column headerText="Alt. Practice" field="altPracticeName"></e-column>
				<e-column headerText="Status" field="status.label"></e-column>
			</e-columns>
		</ejs-grid>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'selectExternalProviderCancelButton'">Cancel</button>
	</ng-template>
</ejs-dialog>

