<div class="col-xs-6 col-sm-4 col-lg-3">
	<div class="form-group margin-bottom-0" [attr.data-test-id]="'invoiceServiceDateFormGroup'">
		<label class="col-sm-4 control-label">Service Date</label>
		<div class="col-sm-8">
			<p class="form-control-static">
				<a *ngIf="displayServiceDateLink()" revTooltip [tooltipContent]="serviceDateTooltipContent" [attr.data-test-id]="'invoiceNavigateServiceDateButton'"
				   (click)="navigateToEncounterOrAppointment()">{{invoice.serviceDate | date: dateFormat}}</a>
				<span *ngIf="!displayServiceDateLink()">{{invoice.serviceDate | date: dateFormat}}</span>
				<a (click)="openEncounterOrAppointmentSelectModal()" *ngIf="canChangeEncounter()" class="margin-left-xs" revTooltip tooltipContent="Edit" [attr.data-test-id]="'invoiceEditServiceDateButton'"><i class="fa fa-pencil"></i></a>
				<a (click)="openDisassociateModal(true)" *ngIf="canDisassociateEncounter()" class="margin-left-xs" revTooltip tooltipContent="Remove" [attr.data-test-id]="'invoiceRemoveServiceDateButton'">
					<i class="fa fa-close"></i>
				</a>
				<a (click)="openDisassociateModal(false)" *ngIf="canDisassociateAppointment()" class="margin-left-xs" revTooltip tooltipContent="Remove">
					<i class="fa fa-close"></i>
				</a>
			</p>
		</div>
	</div>
	<div *ngIf="isInvoicePayerType(payerType.PATIENT)" class="form-group margin-bottom-0" [attr.data-test-id]="'financeChargePlanFormGroup'">
		<label class="col-sm-4 control-label">Finance Charge Plan</label>
		<div class="col-sm-8">
			<p class="form-control-static">
				<span *ngIf="financeChargePlan">{{financeChargePlan.name}}</span>
				<span *ngIf="!financeChargePlan">None</span>
				<a (click)="openFinanceChargePlanSelectModal()" *ngIf="canChangeFinanceChargePlan()" class="margin-left-xs" revTooltip tooltipContent="Edit" [attr.data-test-id]="'invoiceEditFinanceChargePlanButton'"><i class="fa fa-pencil"></i></a>
			</p>
		</div>
	</div>
	<div *ngIf="isInvoicePayerType(payerType.INSURANCE)" class="form-group margin-bottom-0" [attr.data-test-id]="'feeScheduleNameFormGroup'">
		<label class="col-sm-4 control-label">Fee Schedule</label>
		<div class="col-sm-8">
			<p class="form-control-static">
				<span *ngIf="invoice.feeScheduleName">{{invoice.feeScheduleName}}</span>
				<span *ngIf="!invoice.feeScheduleName">None</span>
			</p>
		</div>
	</div>
	<div *ngIf="isInvoicePayerType(payerType.INSURANCE)" class="form-group margin-bottom-0" [attr.data-test-id]="'feeScheduleDateFormGroup'">
		<label class="col-sm-4 control-label">Fee Date</label>
		<div class="col-sm-8">
			<p class="form-control-static">
				<span *ngIf="invoice.feeScheduleDate">{{invoice.feeScheduleDate | date: dateFormat}}</span>
				<span *ngIf="!invoice.feeScheduleDate">None</span>
			</p>
		</div>
	</div>
</div>
<div class="col-xs-6 col-sm-4 col-lg-3">
	<div *ngIf="invoice.firstStatementPrintDate" class="form-group margin-bottom-0" [attr.data-test-id]="'firstStatementPrintDateFormGroup'">
		<label class="col-sm-4 control-label">First Stmt Print Date</label>
		<div class="col-sm-8">
			<p class="form-control-static">{{invoice.firstStatementPrintDate | date: dateFormat}}</p>
		</div>
	</div>
	<div *ngIf="invoice.statementPrintDate" class="form-group margin-bottom-0" [attr.data-test-id]="'lastStatementPrintDateFormGroup'">
		<label class="col-sm-4 control-label">Last Stmt Print Date</label>
		<div class="col-sm-8">
			<p class="form-control-static">{{invoice.statementPrintDate | date: dateFormat}}</p>
		</div>
	</div>
</div>
<div *ngIf="patientCredit" class="col-xs-12 col-sm-8 col-lg-3 text-right" [attr.data-test-id]="'availableCreditSection'">
	<div *ngIf="isInvoicePayerType(payerType.PATIENT)" class="alert alert-success display-inline-block text-nowrap margin-bottom-0 padding-sm">
		{{patientCredit | currency}} Available Credit
	</div>
</div>
