import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EVENT_MANAGER_CONSTANTS } from '@core/events-manager/events-manager.constants';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { UrlService } from '@core/url-util/url.service';
import { FileExtensionBlacklist } from '@gandalf/constants';
import { IncorporateExternalDataRequest } from '@gandalf/model/incorporate-external-data-request';
import { PatientFileInfoResponse } from '@gandalf/model/patient-file-info-response';
import { PatientFileListResponse } from '@gandalf/model/patient-file-list-response';
import { PracticeFileInfoResponse } from '@gandalf/model/practice-file-info-response';
import { PracticeFileResponse } from '@gandalf/model/practice-file-response';
import { IncorporateExternalDataGandalfService } from '@gandalf/services';
import { URL_FILE_WEBSERVICE_ENDPOINTS, URL_PATIENT_FILE_ENDPOINTS, URL_PRACTICE_FILE_ENDPOINTS } from '@shared/constants/url.constants';
import { EnumUtil } from 'morgana';

export enum FileType {
	FOLDER = 'folder',
	DOC = 'doc',
	PDF = 'pdf',
	POSTSCRIPT = 'ps',
	EXCEL = 'xls',
	POWERPOINT = 'ppt',
	FLASH = 'swf',
	XML = 'xml',
	ZIP = 'zip',
	JPEG = 'jpg',
	BITMAP = 'bmp',
	GIF = 'gif',
	PNG = 'png',
	SVG = 'svg',
	TIFF = 'tiff',
	MP3 = 'mp3',
	HTML = 'html',
	PLAINTEXT = 'txt',
	RICHTEXT = 'rtx',
	RTF = 'rtf',
	CSS = 'css',
	VRML = 'vrml',
	WML = 'wml',
	MPEG = 'mpeg',
	MOV = 'mov',
	AVI = 'avi',
	MOVIE = 'movie',
	DCM = 'dcm',
	OTHER = 'other',
}

@Injectable({
	providedIn: 'root',
})
export class FileService {

	private static fileTypeMap = new Map<string, FileType>([
		['application/msword', FileType.DOC],
		['application/pdf', FileType.PDF],
		['application/postscript', FileType.POSTSCRIPT],
		['application/vnd.ms-excel', FileType.EXCEL],
		['application/vnd.ms-powerpoint', FileType.POWERPOINT],
		['application/x-shockwave-flash', FileType.FLASH],
		['application/xml', FileType.XML],
		['application/zip', FileType.ZIP],
		['image/jpeg', FileType.JPEG],
		['image/bmp', FileType.BITMAP],
		['image/gif', FileType.GIF],
		['image/png', FileType.PNG],
		['image/svg+xml', FileType.SVG],
		['image/tiff', FileType.TIFF],
		['audio/mpeg', FileType.MP3],
		['text/html', FileType.HTML],
		['text/plain', FileType.PLAINTEXT],
		['text/richtext', FileType.RICHTEXT],
		['text/rtf', FileType.RTF],
		['text/css', FileType.CSS],
		['model/vrml', FileType.VRML],
		['vnd.wap.wml', FileType.WML],
		['video/mpeg', FileType.MPEG],
		['video/quicktime', FileType.MOV],
		['video/x-msvideo', FileType.AVI],
		['video/x-sgi-movie', FileType.MOVIE],
		['application/dicom', FileType.DCM],
	]);

	constructor(
		private securityManagerService: SecurityManagerService,
		private urlService: UrlService,
		private incorporateExternalDataGandalfService: IncorporateExternalDataGandalfService,
		public httpClient: HttpClient,
		private eventsManagerService: EventsManagerService,
		public featureService: FeatureService,
	) {

	}

	static getFileTypeFromMimeType(mimeType: string): FileType {
		let type = FileService.fileTypeMap.get(mimeType);
		if (_isNil(type)) {
			type = FileType.OTHER;
		}
		return type;
	}

	static mimeTypeIsPdf(mimeType: string) {
		return FileService.getFileTypeFromMimeType(mimeType) === FileType.PDF;
	}

	static mimeTypeIsImage(mimeType: string) {
		const fileType = FileService.getFileTypeFromMimeType(mimeType);
		return fileType === FileType.GIF || fileType === FileType.JPEG || fileType === FileType.PNG;
	}

	/**
	 * Strips the mimetype and base64 prefix from a base64 encoded string
	 */
	static stripBase64Prefix(data: string) {
		if (!_isNil(data) && data.split(';base64,').length > 1) {
			return data.split(';base64,')[1];
		} else {
			return data;
		}
	}

	static getFileExtensionFromFileName(fileName: string): string {
		const extensionIndex = _isNil(fileName) ? -1 : fileName.lastIndexOf('.');
		return extensionIndex === -1 ? undefined : fileName.substring(extensionIndex + 1).toLocaleLowerCase();
	}

	static fileExtensionIsBlacklisted(fileName: string): boolean {
		return !_isNil(EnumUtil.findEnumByValue(FileService.getFileExtensionFromFileName(fileName), FileExtensionBlacklist));
	}

	downloadPatientFile(patientId: number, patientFileId: number) {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.FILE.PATIENT_FILE_WEBSERVICE)) {
			this.urlService.openTabWithPost(
				`${URL_FILE_WEBSERVICE_ENDPOINTS.PATIENT_DOWNLOAD}/${patientFileId}`,
				{},
				false,
			);
		} else {
			this.urlService.openTabWithPost(URL_PATIENT_FILE_ENDPOINTS.DOWNLOAD, {
				patientId,
				patientFileId,
			});
		}
	}

	downloadPracticeFile(practiceFileId: number) {
		this.urlService.openTabWithPost(URL_PRACTICE_FILE_ENDPOINTS.DOWNLOAD, {
			practiceId: this.securityManagerService.getUserSession().practiceId,
			practiceFileId,
		});
	}

	@ShowSavedSuccessToast()
	incorporateExternalData(request: IncorporateExternalDataRequest) {
		return this.incorporateExternalDataGandalfService.incorporateExternalData(request);
	}

	/**
	 * Downloads a file from the server and encodes it into a base64 string
	 * @param fileUrl The URL for downloading the file from the server
	 * @param callback A function that accepts a FileReaderEvent and will read event.target.result for the base64 encoded string
	 */
	downloadFileAndEncodeToBase64(fileUrl: string, callback: any) {
		this.httpClient.get(fileUrl, {responseType: 'blob'}).subscribe((data) => {
			this.readBlobToBase64(data, callback);
		});
	}

	/**
	 * Reads a Blob returned by a server call and encodes it into a base64 string
	 * @param data The Blob returned from the server
	 * @param callback A function that accepts a FileReaderEvent and will read event.target.result for the base64 encoded string
	 */
	readBlobToBase64(data: Blob, callback: any) {
		const reader = new FileReader();
		reader.readAsDataURL(data);
		reader.onload = callback;
	}

	/**
	 * Determines if the mime-type for a given file allows for a preview action (PDF/Image)
	 */
	isMimeTypePreviewable(mimeType: string): boolean {
		if (_isNil(mimeType)) {
			return false;
		}

		const pdfViewerFeatureEnabled = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PDF_VIEWER);
		if (pdfViewerFeatureEnabled && FileService.mimeTypeIsPdf(mimeType)) {
			return true;
		} else if (FileService.mimeTypeIsImage(mimeType)) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Determines if a patient file should be shown in a view window (PDF/Image) or should be downloaded to the user.
	 */
	viewOrDownloadPatientFile(patientId: number, patientFile: PatientFileInfoResponse | PatientFileListResponse) {
		if (_isNil(patientFile)) {
			return;
		}

		const pdfViewerFeatureEnabled = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PDF_VIEWER);
		if (pdfViewerFeatureEnabled && FileService.mimeTypeIsPdf(patientFile.mimeType)) {
			this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.GLOBAL.PDF_VIEWER, {
				fileName: patientFile.fileName,
				fileUrl: this.urlService.getPatientFileUrl(patientId, patientFile.patientFileId),
			});
		} else if (FileService.mimeTypeIsImage(patientFile.mimeType)) {
			this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.GLOBAL.IMAGE_VIEWER, {
				filePath: this.urlService.getPatientFileUrl(patientId, patientFile.patientFileId),
			});
		} else {
			this.downloadPatientFile(patientId, patientFile.patientFileId);
		}
	}

	/**
	 * Determines if a practice file should be shown in a view window (PDF/Image) or should be downloaded to the user.
	 */
	viewOrDownloadPracticeFile(practiceFile: PracticeFileInfoResponse | PracticeFileResponse) {
		if (_isNil(practiceFile)) {
			return;
		}

		const pdfViewerFeatureEnabled = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.PDF_VIEWER);
		const practiceId = this.securityManagerService.getUserSession().practiceId;
		if (pdfViewerFeatureEnabled && FileService.mimeTypeIsPdf(practiceFile.mimeType)) {
			this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.GLOBAL.PDF_VIEWER, {
				fileName: practiceFile.fileName,
				fileUrl: this.urlService.getPracticeFileUrl(practiceId, practiceFile.practiceFileId),
			});
		} else if (FileService.mimeTypeIsImage(practiceFile.mimeType)) {
			this.eventsManagerService.publish(EVENT_MANAGER_CONSTANTS.GLOBAL.IMAGE_VIEWER, {
				filePath: this.urlService.getPracticeFileUrl(practiceId, practiceFile.practiceFileId),
			});
		} else {
			this.downloadPracticeFile(practiceFile.practiceFileId);
		}
	}

}
