// @ts-ignore
import { Injectable } from '@angular/core';
// @ts-ignore
import { GandalfCacheableConfigurableOptions, GandalfCacheableOptions, GandalfCacheBuster, GandalfHttpUtilsService, GandalfStoreService } from 'gandalf';
// @ts-ignore
import { Observable } from 'rxjs';


// @ts-ignore
import { AddAdhocInvoiceItemRequest } from './model/add-adhoc-invoice-item-request';

// @ts-ignore
import { AddBillableItemsBillingRequest } from './model/add-billable-items-billing-request';

// @ts-ignore
import { AddDiscountRequest } from './model/add-discount-request';

// @ts-ignore
import { AddInvoiceItemRequest } from './model/add-invoice-item-request';

// @ts-ignore
import { AdditionalClaimInformationRequest } from './model/additional-claim-information-request';

// @ts-ignore
import { AdditionalClaimInformationResponse } from './model/additional-claim-information-response';

// @ts-ignore
import { AdditionalDiagnosisResponse } from './model/additional-diagnosis-response';

// @ts-ignore
import { ApproveInvoiceRequest } from './model/approve-invoice-request';

// @ts-ignore
import { ChangeEncounterProviderRequest } from './model/change-encounter-provider-request';

// @ts-ignore
import { ChangeInvoiceAppointmentRequest } from './model/change-invoice-appointment-request';

// @ts-ignore
import { CollectionsInvoiceTransferSplitRequest } from './model/collections-invoice-transfer-split-request';

// @ts-ignore
import { ContinuityOfCareImportResultResponse } from './model/continuity-of-care-import-result-response';

// @ts-ignore
import { CreateInvoiceBillingRequest } from './model/create-invoice-billing-request';

// @ts-ignore
import { CreateInvoiceRequest } from './model/create-invoice-request';

// @ts-ignore
import { CreatePatientCreditRequest } from './model/create-patient-credit-request';

// @ts-ignore
import { CreatePatientDocumentRequest } from './model/create-patient-document-request';

// @ts-ignore
import { CreatePaymentGroupRequest } from './model/create-payment-group-request';

// @ts-ignore
import { CreatePaymentRequest } from './model/create-payment-request';

// @ts-ignore
import { CreateRefundPatientRequest } from './model/create-refund-patient-request';

// @ts-ignore
import { DiagnosisResponse } from './model/diagnosis-response';

// @ts-ignore
import { DiscountItemRequest } from './model/discount-item-request';

// @ts-ignore
import { DiscountItemsRequest } from './model/discount-items-request';

// @ts-ignore
import { FamilyCreditRequest } from './model/family-credit-request';

// @ts-ignore
import { FamilyRefundRequest } from './model/family-refund-request';

// @ts-ignore
import { FindActiveLocationProductSummariesRequest } from './model/find-active-location-product-summaries-request';

// @ts-ignore
import { FindPayersForApprovedOutstandingInvoicesRequest } from './model/find-payers-for-approved-outstanding-invoices-request';

// @ts-ignore
import { GrantCreditsRequest } from './model/grant-credits-request';

// @ts-ignore
import { HeuristicPatientSearchRequest } from './model/heuristic-patient-search-request';

// @ts-ignore
import { ImportResultResponse } from './model/import-result-response';

// @ts-ignore
import { InfoButtonResponse } from './model/info-button-response';

// @ts-ignore
import { InsuranceInvoiceTransferSplitRequest } from './model/insurance-invoice-transfer-split-request';

// @ts-ignore
import { InsuranceRefundRequest } from './model/insurance-refund-request';

// @ts-ignore
import { InvoiceDetailsItemResponse } from './model/invoice-details-item-response';

// @ts-ignore
import { InvoiceDiagnosesRequest } from './model/invoice-diagnoses-request';

// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './model/invoice-item-adjustment-response';

// @ts-ignore
import { InvoiceItemDetailResponse } from './model/invoice-item-detail-response';

// @ts-ignore
import { InvoiceItemPaymentRequest } from './model/invoice-item-payment-request';

// @ts-ignore
import { InvoiceItemPaymentResponse } from './model/invoice-item-payment-response';

// @ts-ignore
import { InvoiceItemResponse } from './model/invoice-item-response';

// @ts-ignore
import { InvoicePayerResponse } from './model/invoice-payer-response';

// @ts-ignore
import { InvoiceProviderRequest } from './model/invoice-provider-request';

// @ts-ignore
import { InvoiceResponse } from './model/invoice-response';

// @ts-ignore
import { InvoiceSearchRequest } from './model/invoice-search-request';

// @ts-ignore
import { InvoiceSummaryResponse } from './model/invoice-summary-response';

// @ts-ignore
import { InvoiceTransferRequest } from './model/invoice-transfer-request';

// @ts-ignore
import { InvoiceTransferResponse } from './model/invoice-transfer-response';

// @ts-ignore
import { InvoicesForPayerRequest } from './model/invoices-for-payer-request';

// @ts-ignore
import { InvoicesUpdatedResponse } from './model/invoices-updated-response';

// @ts-ignore
import { LegacyAddressResponse } from './model/legacy-address-response';

// @ts-ignore
import { LegacyDrugAllergyResponse } from './model/legacy-drug-allergy-response';

// @ts-ignore
import { LegacyMedicationPrescriptionResponse } from './model/legacy-medication-prescription-response';

// @ts-ignore
import { LegacyPatientInsuranceAccountSummaryResponse } from './model/legacy-patient-insurance-account-summary-response';

// @ts-ignore
import { LegacyPatientNameResponse } from './model/legacy-patient-name-response';

// @ts-ignore
import { LegacyPatientSearchResponse } from './model/legacy-patient-search-response';

// @ts-ignore
import { LegacyPersonDiagnosisResponse } from './model/legacy-person-diagnosis-response';

// @ts-ignore
import { LegacyPracticeDiagnosisResponse } from './model/legacy-practice-diagnosis-response';

// @ts-ignore
import { LegacyProviderResponse } from './model/legacy-provider-response';

// @ts-ignore
import { LegacyReferenceDataResponse } from './model/legacy-reference-data-response';

// @ts-ignore
import { LegacyServiceSummaryResponse } from './model/legacy-service-summary-response';

// @ts-ignore
import { LocationProductItemSummaryResponse } from './model/location-product-item-summary-response';

// @ts-ignore
import { LocationProductSummaryResponse } from './model/location-product-summary-response';

// @ts-ignore
import { ManualProcessPaymentRequest } from './model/manual-process-payment-request';

// @ts-ignore
import { ModifierResponse } from './model/modifier-response';

// @ts-ignore
import { NewGuestInvoiceRequest } from './model/new-guest-invoice-request';

// @ts-ignore
import { ParseExternalPatientDataFileRequest } from './model/parse-external-patient-data-file-request';

// @ts-ignore
import { PatientCreditRequest } from './model/patient-credit-request';

// @ts-ignore
import { PatientDocumentSummaryResponse } from './model/patient-document-summary-response';

// @ts-ignore
import { PatientFileDigestResponse } from './model/patient-file-digest-response';

// @ts-ignore
import { PatientInvoiceTransferSplitRequest } from './model/patient-invoice-transfer-split-request';

// @ts-ignore
import { PatientPaymentResponse } from './model/patient-payment-response';

// @ts-ignore
import { PatientPortionRequest } from './model/patient-portion-request';

// @ts-ignore
import { PatientRefundRequest } from './model/patient-refund-request';

// @ts-ignore
import { PaymentGroupListResponse } from './model/payment-group-list-response';

// @ts-ignore
import { PaymentGroupResponse } from './model/payment-group-response';

// @ts-ignore
import { PaymentHistoryResponse } from './model/payment-history-response';

// @ts-ignore
import { PaymentResponse } from './model/payment-response';

// @ts-ignore
import { PrintStatementsRequest } from './model/print-statements-request';

// @ts-ignore
import { QueryResultFieldResponse } from './model/query-result-field-response';

// @ts-ignore
import { QueryResultResponse } from './model/query-result-response';

// @ts-ignore
import { QueryResultValueResponse } from './model/query-result-value-response';

// @ts-ignore
import { QueryResultsResponse } from './model/query-results-response';

// @ts-ignore
import { ReceivePaymentTransferInvoiceItemsRequest } from './model/receive-payment-transfer-invoice-items-request';

// @ts-ignore
import { ReceivePaymentTransferInvoiceItemsResponse } from './model/receive-payment-transfer-invoice-items-response';

// @ts-ignore
import { RemoveInvoiceItemRequest } from './model/remove-invoice-item-request';

// @ts-ignore
import { RunQueryRequest } from './model/run-query-request';

// @ts-ignore
import { SearchPaymentGroupRequest } from './model/search-payment-group-request';

// @ts-ignore
import { SendStatementsRequest } from './model/send-statements-request';

// @ts-ignore
import { SignEncounterRequest } from './model/sign-encounter-request';

// @ts-ignore
import { SignEncounterResponse } from './model/sign-encounter-response';

// @ts-ignore
import { SignEncounterSsoRequest } from './model/sign-encounter-sso-request';

// @ts-ignore
import { SplitPaymentRequest } from './model/split-payment-request';

// @ts-ignore
import { StatementInvoiceResponse } from './model/statement-invoice-response';

// @ts-ignore
import { StatementPayerResponse } from './model/statement-payer-response';

// @ts-ignore
import { TaxDetailResponse } from './model/tax-detail-response';

// @ts-ignore
import { TransferInvoiceBalanceToPatientRequest } from './model/transfer-invoice-balance-to-patient-request';

// @ts-ignore
import { TransferInvoiceItemsRequest } from './model/transfer-invoice-items-request';

// @ts-ignore
import { TransferItemRequest } from './model/transfer-item-request';

// @ts-ignore
import { TransferItemResponse } from './model/transfer-item-response';

// @ts-ignore
import { TransferPatientCreditRequest } from './model/transfer-patient-credit-request';

// @ts-ignore
import { UnsignEncounterRequest } from './model/unsign-encounter-request';

// @ts-ignore
import { UnsignEncounterSsoRequest } from './model/unsign-encounter-sso-request';

// @ts-ignore
import { UpdateInvoiceDateRequest } from './model/update-invoice-date-request';

// @ts-ignore
import { UpdateInvoiceEncounterRequest } from './model/update-invoice-encounter-request';

// @ts-ignore
import { UpdateInvoiceFinanceChargePlanRequest } from './model/update-invoice-finance-charge-plan-request';

// @ts-ignore
import { UpdateInvoiceItemDetailsRequest } from './model/update-invoice-item-details-request';

// @ts-ignore
import { UpdateInvoiceItemQuantityRequest } from './model/update-invoice-item-quantity-request';

// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonListRequest } from './model/update-invoice-item-responsible-person-list-request';

// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonRequest } from './model/update-invoice-item-responsible-person-request';

// @ts-ignore
import { UpdateInvoiceLocationRequest } from './model/update-invoice-location-request';

// @ts-ignore
import { UpdatePaymentGroupRequest } from './model/update-payment-group-request';

// @ts-ignore
import { VoidInsuranceCreditRequest } from './model/void-insurance-credit-request';

// @ts-ignore
import { VoidInsuranceRefundRequest } from './model/void-insurance-refund-request';

// @ts-ignore
import { VoidInvoiceRequest } from './model/void-invoice-request';

// @ts-ignore
import { VoidPatientCreditRequest } from './model/void-patient-credit-request';

// @ts-ignore
import { VoidPatientRefundRequest } from './model/void-patient-refund-request';

// @ts-ignore
import { VoidPaymentRequest } from './model/void-payment-request';

// @ts-ignore
import { WriteoffInvoiceTransferSplitRequest } from './model/writeoff-invoice-transfer-split-request';

/** See com.rev360.legacy.api.controller.accounting.LegacyAccountingController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LegacyAccountingGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	activatePaymentGroup(paymentGroupId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/activatePaymentGroup/${paymentGroupId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	addBillableItemsToInvoice(request: AddBillableItemsBillingRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/addBillableItemsToInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddBillableItemsBillingRequest, request );
		
		return configuredEndpoint;
	}

	
	addDiscount(request: AddDiscountRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/addDiscount`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, AddDiscountRequest, request );
		
		return configuredEndpoint;
	}

	
	addDiscountItems(request: DiscountItemsRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/addDiscountItems`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, DiscountItemsRequest, request );
		
		return configuredEndpoint;
	}

	
	addInvoiceItem(request: AddInvoiceItemRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/addInvoiceItem`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, AddInvoiceItemRequest, request );
		
		return configuredEndpoint;
	}

	
	addInvoiceItemAdhoc(request: AddAdhocInvoiceItemRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/addInvoiceItemAdhoc`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, AddAdhocInvoiceItemRequest, request );
		
		return configuredEndpoint;
	}

	
	addPatientPortion(request: PatientPortionRequest ): Observable<InvoiceResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/addPatientPortion`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, PatientPortionRequest, request );
		
		return configuredEndpoint;
	}

	
	applyExistingInsurancePayments(request: UpdatePaymentGroupRequest ): Observable<InvoicesUpdatedResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/applyExistingInsurancePayments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoicesUpdatedResponse, UpdatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

	
	applyExistingPayments(request: UpdatePaymentGroupRequest ): Observable<InvoicesUpdatedResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/applyExistingPayments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoicesUpdatedResponse, UpdatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

	
	applyInsurancePayments(request: CreatePaymentGroupRequest ): Observable<InvoicesUpdatedResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/applyInsurancePayments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoicesUpdatedResponse, CreatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

	
	applyPayments(request: CreatePaymentGroupRequest ): Observable<InvoicesUpdatedResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/applyPayments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoicesUpdatedResponse, CreatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

	
	approveInvoice(request: ApproveInvoiceRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/approveInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, ApproveInvoiceRequest, request );
		
		return configuredEndpoint;
	}

	
	assignInvoiceFeeSchedule(invoiceId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/assignInvoiceFeeSchedule/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	changeInvoiceAppointment(request: ChangeInvoiceAppointmentRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/changeInvoiceAppointment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, ChangeInvoiceAppointmentRequest, request );
		
		return configuredEndpoint;
	}

	
	createBillingInvoice(request: CreateInvoiceBillingRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/createBillingInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateInvoiceBillingRequest, request );
		
		return configuredEndpoint;
	}

	
	createGuestInvoice(request: NewGuestInvoiceRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/createGuestInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, NewGuestInvoiceRequest, request );
		
		return configuredEndpoint;
	}

	
	createInvoice(request: CreateInvoiceRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/createInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, CreateInvoiceRequest, request );
		
		return configuredEndpoint;
	}

	
	creditPatient(request: CreatePatientCreditRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/creditPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePatientCreditRequest, request );
		
		return configuredEndpoint;
	}

	
	deactivatePaymentGroup(paymentGroupId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/deactivatePaymentGroup/${paymentGroupId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findAdditionalClaimInformationByInvoiceId(invoiceId: number): Observable<AdditionalClaimInformationResponse> {
		const endpointUrl = `/legacy/ws/accounting/findAdditionalClaimInformationByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdditionalClaimInformationResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInvoiceDetailsItemsByInvoiceId(invoiceId: number): Observable<InvoiceDetailsItemResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/findInvoiceDetailsItemsByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceDetailsItemResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInvoiceItemById(invoiceItemId: number): Observable<InvoiceItemResponse> {
		const endpointUrl = `/legacy/ws/accounting/findInvoiceItemById/${invoiceItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceItemResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInvoiceItemDetailById(invoiceItemId: number): Observable<InvoiceItemDetailResponse> {
		const endpointUrl = `/legacy/ws/accounting/findInvoiceItemDetailById/${invoiceItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceItemDetailResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findInvoiceStatementsByInvoiceId(invoiceId: number): Observable<StatementInvoiceResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/findInvoiceStatementsByInvoiceId/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, StatementInvoiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPayerStatementsByPersonId(personId: number): Observable<StatementPayerResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/findPayerStatementsByPersonId/${personId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, StatementPayerResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findPayersForApprovedOutstandingInvoices(request: FindPayersForApprovedOutstandingInvoicesRequest ): Observable<InvoicePayerResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/findPayersForApprovedOutstandingInvoices`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoicePayerResponse, FindPayersForApprovedOutstandingInvoicesRequest, request );
		
		return configuredEndpoint;
	}

	
	findPaymentsByPatient(patientId: number): Observable<PatientPaymentResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/findPaymentsByPatient/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientPaymentResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getApprovedOutstandingInvoicesForPayer(request: InvoicesForPayerRequest ): Observable<InvoiceSummaryResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/getApprovedOutstandingInvoicesForPayor`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceSummaryResponse, InvoicesForPayerRequest, request );
		
		return configuredEndpoint;
	}

	
	getInvoiceById(invoiceId: number): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/getInvoiceById/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getPaymentGroupById(paymentGroupId: number): Observable<PaymentGroupResponse> {
		const endpointUrl = `/legacy/ws/accounting/getPaymentGroupById/${paymentGroupId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentGroupResponse, null, null );
		
		return configuredEndpoint;
	}

	
	grantInvoiceCredits(request: GrantCreditsRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/grantInvoiceCredits`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, GrantCreditsRequest, request );
		
		return configuredEndpoint;
	}

	
	markInvoicePaid(invoiceId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/markInvoicePaid/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	markInvoiceUnpaid(invoiceId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/markInvoiceUnpaid/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	putInvoiceOnHold(invoiceId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/putInvoiceOnHold/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	refundPatient(request: CreateRefundPatientRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/refundPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreateRefundPatientRequest, request );
		
		return configuredEndpoint;
	}

	
	removeInvoiceFeeSchedule(invoiceId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/removeInvoiceFeeSchedule/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	removeInvoiceItem(request: RemoveInvoiceItemRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/removeInvoiceItem`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, RemoveInvoiceItemRequest, request );
		
		return configuredEndpoint;
	}

	
	savePayments(request: CreatePaymentGroupRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/savePayments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, CreatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

	
	searchInvoices(request: InvoiceSearchRequest ): Observable<InvoiceSummaryResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/searchInvoices`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceSummaryResponse, InvoiceSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	searchInvoicesForManualSelection(request: InvoiceSearchRequest ): Observable<InvoiceSummaryResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/searchInvoicesForManualSelection`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceSummaryResponse, InvoiceSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	searchPaymentGroups(request: SearchPaymentGroupRequest ): Observable<PaymentGroupListResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/searchPaymentGroups`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PaymentGroupListResponse, SearchPaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

	
	splitInvoiceItemPayment(request: SplitPaymentRequest ): Observable<InvoiceResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/splitInvoiceItemPayment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, SplitPaymentRequest, request );
		
		return configuredEndpoint;
	}

	
	transferInvoice(request: InvoiceTransferRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/transferInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, InvoiceTransferRequest, request );
		
		return configuredEndpoint;
	}

	
	transferInvoiceItems(request: TransferInvoiceItemsRequest ): Observable<InvoiceResponse[]> {
		const endpointUrl = `/legacy/ws/accounting/transferInvoiceItems`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, TransferInvoiceItemsRequest, request );
		
		return configuredEndpoint;
	}

	
	transferInvoiceToPatient(request: TransferInvoiceBalanceToPatientRequest ): Observable<InvoiceTransferResponse> {
		const endpointUrl = `/legacy/ws/accounting/transferInvoiceBalanceToPatient`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceTransferResponse, TransferInvoiceBalanceToPatientRequest, request );
		
		return configuredEndpoint;
	}

	
	transferPatientCredit(request: TransferPatientCreditRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/transferPatientCredit`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, TransferPatientCreditRequest, request );
		
		return configuredEndpoint;
	}

	
	unapproveInvoice(invoiceId: number): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/unapproveInvoice/${invoiceId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, null, null );
		
		return configuredEndpoint;
	}

	
	updateAdditionalClaimInformation(request: AdditionalClaimInformationRequest ): Observable<AdditionalClaimInformationResponse> {
		const endpointUrl = `/legacy/ws/accounting/updateAdditionalClaimInformation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, AdditionalClaimInformationResponse, AdditionalClaimInformationRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceDate(request: UpdateInvoiceDateRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/updateInvoiceDate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, UpdateInvoiceDateRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceDiagnoses(request: InvoiceDiagnosesRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/updateInvoiceDiagnoses`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, InvoiceDiagnosesRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceEncounter(request: UpdateInvoiceEncounterRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/updateInvoiceEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, UpdateInvoiceEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceFinanceChargePlan(request: UpdateInvoiceFinanceChargePlanRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/updateInvoiceFinanceChargePlan`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, UpdateInvoiceFinanceChargePlanRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceItemDetail(request: UpdateInvoiceItemDetailsRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/updateInvoiceItemDetail`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, UpdateInvoiceItemDetailsRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceItemQuantity(request: UpdateInvoiceItemQuantityRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/updateInvoiceItemQuantity`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, UpdateInvoiceItemQuantityRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceLocation(request: UpdateInvoiceLocationRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/updateInvoiceLocation`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, UpdateInvoiceLocationRequest, request );
		
		return configuredEndpoint;
	}

	
	updateInvoiceProvider(request: InvoiceProviderRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/updateInvoiceProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, InvoiceProviderRequest, request );
		
		return configuredEndpoint;
	}

	
	updateItemResponsiblePersons(request: UpdateInvoiceItemResponsiblePersonListRequest ): Observable<InvoiceResponse> {
		const endpointUrl = `/legacy/ws/accounting/updateItemResponsiblePersons`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, InvoiceResponse, UpdateInvoiceItemResponsiblePersonListRequest, request );
		
		return configuredEndpoint;
	}

	
	updatePayments(request: UpdatePaymentGroupRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/updatePayments`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, UpdatePaymentGroupRequest, request );
		
		return configuredEndpoint;
	}

	
	voidInsuranceCompanyCredit(request: VoidInsuranceCreditRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/voidInsuranceCompanyCredit`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, VoidInsuranceCreditRequest, request );
		
		return configuredEndpoint;
	}

	
	voidInsuranceCompanyRefund(request: VoidInsuranceRefundRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/voidInsuranceCompanyRefund`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, VoidInsuranceRefundRequest, request );
		
		return configuredEndpoint;
	}

	
	voidInvoice(request: VoidInvoiceRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/voidInvoice`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, VoidInvoiceRequest, request );
		
		return configuredEndpoint;
	}

	
	voidPatientCredit(request: VoidPatientCreditRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/voidPatientCredit`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, VoidPatientCreditRequest, request );
		
		return configuredEndpoint;
	}

	
	voidPatientRefund(request: VoidPatientRefundRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/voidPatientRefund`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, VoidPatientRefundRequest, request );
		
		return configuredEndpoint;
	}

	
	voidPayment(request: VoidPaymentRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/accounting/voidPayment`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, VoidPaymentRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.legacy.api.controller.document.DocumentController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LegacyCommunicationTemplateGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createPatientDocumentFromTemplate(request: CreatePatientDocumentRequest ): Observable<PatientDocumentSummaryResponse> {
		const endpointUrl = `/legacy/ws/document/communicationTemplate/createPatientDocumentFromTemplate`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, PatientDocumentSummaryResponse, CreatePatientDocumentRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.legacy.api.controller.encounter.LegacyEncounterController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LegacyEncounterGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	changeEncounterProvider(request: ChangeEncounterProviderRequest ): Observable<void> {
		const endpointUrl = `/legacy/ws/encounter/changeEncounterProvider`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, ChangeEncounterProviderRequest, request );
		
		return configuredEndpoint;
	}

	
	signEncounter(request: SignEncounterRequest ): Observable<SignEncounterResponse> {
		const endpointUrl = `/legacy/ws/encounter/signEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SignEncounterResponse, SignEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	signEncounterSso(request: SignEncounterSsoRequest ): Observable<SignEncounterResponse> {
		const endpointUrl = `/legacy/ws/encounter/signEncounterSso`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SignEncounterResponse, SignEncounterSsoRequest, request );
		
		return configuredEndpoint;
	}

	
	unsignEncounter(request: UnsignEncounterRequest ): Observable<SignEncounterResponse> {
		const endpointUrl = `/legacy/ws/encounter/unsignEncounter`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SignEncounterResponse, UnsignEncounterRequest, request );
		
		return configuredEndpoint;
	}

	
	unsignEncounterSso(request: UnsignEncounterSsoRequest ): Observable<SignEncounterResponse> {
		const endpointUrl = `/legacy/ws/encounter/unsignEncounterSso`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, SignEncounterResponse, UnsignEncounterSsoRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.legacy.api.controller.eyefinity.EyefinityController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LegacyEyefinityGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	createOrderInvoices(orderId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/eyefinity/createOrderInvoices/${orderId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.legacy.api.controller.inventory.LegacyInventoryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LegacyInventoryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	findActiveCPTServices(practiceLocationId: number): Observable<LegacyServiceSummaryResponse[]> {
		const endpointUrl = `/legacy/ws/inventory/findActiveCPTServices/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LegacyServiceSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveLocationProductSummaries(request: FindActiveLocationProductSummariesRequest ): Observable<LocationProductSummaryResponse[]> {
		const endpointUrl = `/legacy/ws/inventory/findActiveProductSummaries`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductSummaryResponse, FindActiveLocationProductSummariesRequest, request );
		
		return configuredEndpoint;
	}

	
	findActiveOtherServices(practiceLocationId: number): Observable<LegacyServiceSummaryResponse[]> {
		const endpointUrl = `/legacy/ws/inventory/findActiveOtherServices/${practiceLocationId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LegacyServiceSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	findActiveProductLocationItems(locationProductId: number): Observable<LocationProductItemSummaryResponse[]> {
		const endpointUrl = `/legacy/ws/inventory/findActiveProductLocationItems/${locationProductId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductItemSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getLocationProductById(locationProductId: number): Observable<LocationProductSummaryResponse> {
		const endpointUrl = `/legacy/ws/inventory/getLocationProductById/${locationProductId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	getLocationProductItemById(locationProductItemId: number): Observable<LocationProductItemSummaryResponse> {
		const endpointUrl = `/legacy/ws/inventory/getLocationProductItemById/${locationProductItemId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LocationProductItemSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.legacy.api.controller.patient.LegacyPatientController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LegacyPatientGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	deletePatientFile(patientFileId: number): Observable<void> {
		const endpointUrl = `/legacy/ws/patient/deletePatientFile/${patientFileId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, null, null, null );
		
		return configuredEndpoint;
	}

	
	findPatientByHeuristic(request: HeuristicPatientSearchRequest ): Observable<LegacyPatientSearchResponse[]> {
		const endpointUrl = `/legacy/ws/patient/findPatientByHeuristic`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LegacyPatientSearchResponse, HeuristicPatientSearchRequest, request );
		
		return configuredEndpoint;
	}

	
	getAccountSummaryWithInsurance(patientId: number): Observable<LegacyPatientInsuranceAccountSummaryResponse> {
		const endpointUrl = `/legacy/ws/patient/getAccountSummaryWithInsurance/${patientId}`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, LegacyPatientInsuranceAccountSummaryResponse, null, null );
		
		return configuredEndpoint;
	}

	
	parseExternalPatientDataFile(request: ParseExternalPatientDataFileRequest ): Observable<ImportResultResponse> {
		const endpointUrl = `/legacy/ws/patient/parseExternalPatientDataFile`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, ImportResultResponse, ParseExternalPatientDataFileRequest, request );
		
		return configuredEndpoint;
	}

}

/** See com.rev360.legacy.api.controller.query.QueryController */
/* istanbul ignore next */
@Injectable({
	providedIn: 'root',
})
export class LegacyQueryGandalfService {

	constructor(private gandalfHttpUtils: GandalfHttpUtilsService) {}

	
	runQuery(request: RunQueryRequest ): Observable<QueryResultsResponse> {
		const endpointUrl = `/legacy/ws/query/runQuery`;
		const configuredEndpoint = this.gandalfHttpUtils.gandalfRequest( endpointUrl, QueryResultsResponse, RunQueryRequest, request );
		
		return configuredEndpoint;
	}

}


