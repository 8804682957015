<ejs-dialog #locationConfigModal
			cssClass="modal-sm"
			(close)="closeModal(false)">

	<ng-template #header >
		<div class="dlg-template" *ngIf="!isCreating" [attr.data-test-id]="'locationConfigurationModalEditTitleBar'">Edit Location Configuration</div>
		<div class="dlg-template" *ngIf="isCreating" [attr.data-test-id]="'locationConfigurationModalNewTitleBar'">New Location Configuration</div>
	</ng-template>

	<ng-template #content>
		<form #componentForm="ngForm"
			  [formGroup]="formGroup"
			  (ngSubmit)="saveChanges()"
			  class="form-horizontal">
			<div class="panel-body">
				<div class="row">
					<gandalf-lib-validation-messages
						*ngIf="formGroup.invalid && componentForm.submitted"
						[form]="formGroup"
						[requestObj]="createOrUpdateRequest"
						[attr.data-test-id]="'locationConfigurationModalValidation'">
					</gandalf-lib-validation-messages>
					<div class="form-group">
						<label class="col-sm-5 control-label required">Location</label>
						<div class="col-sm-7">
							<ejs-dropdownlist revOverrideDropdownlistBase
											  [dataSource]="locationsList"
											  [fields]="{text: 'name', value: 'id' }"
											  formControlName="practiceLocationId"
											  [allowFiltering]="shouldFilter(locationsList)">
							</ejs-dropdownlist>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-5 control-label">Account #</label>
						<div class="col-sm-7">
							<input type="text" class="e-input" formControlName="accountNumber"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-5 control-label">External/Ship-To #</label>
						<div class="col-sm-7">
							<input type="text" class="e-input" formControlName="externalLocationId"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-5 control-label">Username</label>
						<div class="col-sm-7">
							<input type="text" class="e-input" formControlName="userName"/>
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-5 control-label">Password</label>
						<div class="col-sm-7">
							<input type="text" class="e-input" formControlName="password"/>
						</div>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal(false)" [attr.data-test-id]="'locationConfigurationModalCancelButton'">Cancel</button>
		<button ejs-button isPrimary="true" type="submit" (click)="submitForm($event)" [attr.data-test-id]="'locationConfigurationModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>

