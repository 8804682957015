import { NavigationEnd, Router } from '@angular/router';
import { AddStatefulComponentChild } from '@app-store/actions/hierarchies.actions';
import { registerStatefulChildKey, RegisterStatefulChildParams, StatefulComponentWithParent } from '@app-store/decorators/register-stateful-child.decorator';
import { statefulPropertyKey } from '@app-store/decorators/stateful-property.decorator';
import { StatefulFormBase, statefulFormBaseKey } from '@app-store/mixins/stateful-form.mixin';
import { StatefulGridBase, statefulGridBaseKey } from '@app-store/mixins/stateful-grid.mixin';
import { GridState } from '@app-store/reducers/grid.reducer';
import { StatefulEventService } from '@app-store/services/stateful-event.service';
import { StatefulPropertyService } from '@app-store/services/stateful-property.service';
import { StatefulRouteService } from '@app-store/services/stateful-route.service';
import { StatefulBaseComponent } from '@app-store/stateful-base.component';
import { StatefulComponentUtil } from '@app-store/utils/stateful-component-util';
import { _get, _isNil } from '@core/lodash/lodash';
import { RouterUtilsService } from '@core/router-utils/router-utils.service';
import { Store } from '@ngrx/store';
import { RowHighlightPosition, RowNode } from 'ag-grid-community';
import { from, lastValueFrom, merge } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InjectorContainerModule } from '../injector-container.module';

export interface ComponentWithStatefulEventService extends StatefulBaseComponent {
	statefulEventService: StatefulEventService;
}

export interface ComponentWithStatefulPropertyService extends StatefulBaseComponent {
	statefulPropertyService: StatefulPropertyService;
}

export interface ComponentWithStatefulRouteService extends StatefulBaseComponent {
	statefulRouteService: StatefulRouteService;
}

export const HIGHLIGHTING_POSITION = RowHighlightPosition.Below;

export class StatefulBaseComponentUtil {

	/**
	 * Observes and updates the formSubmitted value within the component.
	 */
	static observeFormSubmitted(instance: StatefulFormBase) {
		return instance.statefulFormService.observeFormSubmitted().pipe(
			takeUntil(instance.unsubscribe),
			tap(submitted => instance.formSubmitted = submitted),
		);
	}

	/**
	 * Patch Properties
	 * Patches the values of the properties back into the new instance of the component.
	 */
	static patchProperties(instance: ComponentWithStatefulPropertyService) {
		return instance.statefulPropertyService.selectPropertiesState().pipe(
			take(1),
			tap(cachedProperties => {
				const statefulProperties = StatefulBaseComponentUtil.getStatefulProperties(instance);
				statefulProperties.forEach(property => instance[property] = _get(cachedProperties, [property]));
			}),
		);
	}

	static shouldOverridePersistedRoutes() {
		return window['revGlobals']?.overridePersistedRoutes;
	}

	/**
	 * Navigates to the persisted route of the component if one exists.
	 * @param instance Component instance to use.
	 */
	static async navigateToPersistedChildRoute(instance: ComponentWithStatefulRouteService): Promise<boolean> {
		if (this.shouldOverridePersistedRoutes()) {
			return Promise.resolve(false);
		}

		const router = InjectorContainerModule.getInjectable(Router);
		const route = await lastValueFrom(instance.statefulRouteService.selectRouteState().pipe(take(1)));
		return !!route && router.navigateByUrl(route);
	}

	/**
	 * Creates an observable of navigation events and updates the store upon each routing event
	 * with the specified component instance.
	 * @param instance Component instance to use.
	 */
	static observeAndPersistChildRoutes(instance: ComponentWithStatefulRouteService) {
		const router = InjectorContainerModule.getInjectable(Router);
		const routerUtilsService = InjectorContainerModule.getInjectable(RouterUtilsService);

		/* This is necessary when landing on a patient, as the NavigationEnd event happens before the subscription.
		 * We should be able to remove this when flex is removed.
		 */
		instance.statefulRouteService.updateRouteValue(router.url);

		return routerUtilsService.observeNavigationEvents(NavigationEnd, false).pipe(
			takeUntil(instance.unsubscribe),
			tap(event => instance.statefulRouteService.updateRouteValue(event.urlAfterRedirects)),
		);
	}

	/**
	 * Registers a component instance as a child of the specified parents. If no parent instance is found
	 * the child is not registered.
	 * @param instance Component instance to use.
	 */
	static registerStatefulChild(instance: StatefulComponentWithParent) {
		const params: RegisterStatefulChildParams = Reflect.getOwnMetadata(registerStatefulChildKey, instance.constructor);
		const childKey = StatefulComponentUtil.buildStoreKeyFromInstance(instance);

		params.parents.forEach(parentType => {
			const parentInstance = instance.injector.get(parentType, null);

			if (!_isNil(parentInstance)) {
				const key = StatefulComponentUtil.buildStoreKeyFromInstance(parentInstance);
				instance.injector.get(Store).dispatch(new AddStatefulComponentChild({key, childKey}));
			}
		});
	}

	static patchGridState(instance: StatefulGridBase) {
		return from(instance.agGrid.gridReady).pipe(take(1),
			switchMap(() => instance.statefulGridService.selectGridState().pipe(take(1))),
			tap((gridState: GridState) => {
				// Not filtering because we want this to emit even if it doesn't patch so the events that should wait until after patching
				// will be able to fire even if we don't need to patch
				if (gridState) {
					instance.agGrid.api.setFilterModel(gridState.filterState);

					gridState.selectionState?.forEach((nodeId) =>
						instance.agGrid.api.getRowNode(nodeId).setSelected(true, false),
					);
					instance.agGrid.columnApi.applyColumnState({
						state: gridState.columnsState,
						applyOrder: true,
					});
					setTimeout(() => {
						instance.agGrid.api.paginationSetPageSize(gridState.paginationState.pageSize);
						instance.agGrid.api.paginationGoToPage(gridState.paginationState.currentPage);

						const rowNode = instance.agGrid.api.getDisplayedRowAtIndex(gridState.rowHighlighted);
						(rowNode as RowNode)?.setHighlighted(HIGHLIGHTING_POSITION);
					});
				}
			}),
		);
	}

	static observeGridFilter(instance: StatefulGridBase) {
		return from(instance.agGrid.filterChanged).pipe(
			takeUntil(instance.unsubscribe),
			tap(() => instance.statefulGridService.updateGridFilterState(instance.agGrid.api.getFilterModel())));
	}

	static observeColumnState(instance: StatefulGridBase) {
		return merge(
			from(instance.agGrid.sortChanged),
			from(instance.agGrid.columnMoved),
			from(instance.agGrid.columnResized),
			from(instance.agGrid.displayedColumnsChanged),
		).pipe(
			takeUntil(instance.unsubscribe),
			tap(() =>
				instance.statefulGridService.updateGridColumnsState(instance.agGrid.columnApi.getColumnState()),
			));
	}

	/**
	 * Checks for savedMetaData to determine if given Component is a StatefulComponentWithParent
	 */
	static implementsRegisterStatefulChild = (instance: any): instance is StatefulComponentWithParent => Reflect.getMetadata(registerStatefulChildKey, instance.constructor);

	static implementsStatefulEvents = (instance: any): instance is ComponentWithStatefulEventService => !!instance.statefulEventService;

	static implementsStatefulForm = (instance: any): instance is StatefulFormBase => Reflect.getMetadata(statefulFormBaseKey, instance.constructor);

	static implementsStatefulGrid = (instance: any): instance is StatefulGridBase => Reflect.getMetadata(statefulGridBaseKey, instance.constructor);

	static implementsStatefulProperties = (instance: any): instance is ComponentWithStatefulPropertyService => !!instance.statefulPropertyService
			&& !!StatefulBaseComponentUtil.getStatefulProperties(instance);

	static implementsStatefulRoute = (instance: any): instance is ComponentWithStatefulRouteService => !!instance.statefulRouteService;

	static getStatefulProperties(instance: any) {
		return Reflect.getMetadata(statefulPropertyKey, instance);
	}

	/**
	 *	When landing on the stateful route component that we are ignoring the persisted route on and view is initialized, remove the override
	 */
	static statefulRoutingCheck(instance: any) {
		if (StatefulBaseComponentUtil.implementsStatefulRoute(instance) && window['revGlobals']) {
			window['revGlobals'].overridePersistedRoutes = false;
		}
	}
}
