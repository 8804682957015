import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { _padStart } from '@core/lodash/lodash';
import { OptionItemService } from '@core/option-item/option-item.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { OpenEdgeCardReaderResponse } from '@gandalf/model/open-edge-card-reader-response';
import { OpenEdgeCreatePayFieldsTransactionRequest } from '@gandalf/model/open-edge-create-pay-fields-transaction-request';
import { OpenEdgeCreateSavedCardRequest } from '@gandalf/model/open-edge-create-saved-card-request';
import { OpenEdgeCreateStoredCardTransactionRequest } from '@gandalf/model/open-edge-create-stored-card-transaction-request';
import { OpenEdgeFinishTransactionRequest } from '@gandalf/model/open-edge-finish-transaction-request';
import { OpenEdgePaymentTransactionResponse } from '@gandalf/model/open-edge-payment-transaction-response';
import { OpenEdgeSetupTransportKeysRequest } from '@gandalf/model/open-edge-setup-transport-keys-request';
import { OpenEdgeTransactionDetailsResponse } from '@gandalf/model/open-edge-transaction-details-response';
import { OpenEdgeVoidTransactionResponse } from '@gandalf/model/open-edge-void-transaction-response';
import { PaymentStoredTokenResponse } from '@gandalf/model/payment-stored-token-response';
import { OpenEdgePaymentGandalfService } from '@gandalf/services';
import { LOCAL_STORAGE_CODES } from '@shared/constants/local-storage.constants';
import dayjs from 'dayjs';
import { DialogUtil, OptionItem, SortingService } from 'morgana';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SalesResponse {
	Status: string;
	PaymentType: string;
	EntryMode: string;
	ErrorMessage: string;
	ValidationKey: string;
	Token: string;
	AmountApproved: string;
	AuthorizationCode: string;
	TransactionDate: string;
	AccountNumber: string;
}

export interface PaymentStoredTokenResponseForTable extends PaymentStoredTokenResponse {
	expirationDateString?: string;
	cardTypeString?: string;
	expired: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class OpenEdgePaymentService {

	globalPaymentsConfigured = false;
	globalPaymentsErrorSubject = new Subject<any>();

	constructor(
		private openEdgePaymentGandalfService: OpenEdgePaymentGandalfService,
		private securityManagerService: SecurityManagerService,
		private httpClient: HttpClient,
		private localStorageService: LocalStorageService,
		private currencyPipe: CurrencyPipe,
	) {
	}

	findActiveOpenEdgeCardReadersByLocationId(practiceLocationId: number) {
		return this.openEdgePaymentGandalfService.findActiveOpenEdgeCardReadersByLocationId(practiceLocationId).pipe(
			map(readers => SortingService.sortBy(readers, ['name', 'openEdgeCardReaderId'], ['asc', 'asc'])),
			map(readers => OptionItemService.toOptionItems(readers, item => item.openEdgeCardReaderId, item => item.name)),
		);
	}

	get GlobalPayments(): any {
		return GlobalPayments;
	}

	configureOpenEdgeGlobalPayments() {
		if (!this.globalPaymentsConfigured) {
			this.GlobalPayments.on('error', error => this.globalPaymentsErrorSubject.next(error));
			this.GlobalPayments.configure({
				'X-GP-Api-Key': this.securityManagerService.environmentData.openEdgeConfiguration.payFieldsApiKey,
				'X-GP-Environment': this.securityManagerService.environmentData.openEdgeConfiguration.payFieldsEnvironment,
			});
			this.globalPaymentsConfigured = true;
		}
	}

	/* istanbul ignore next: gandalf */
	createSaleTransaction(request: OpenEdgeCreatePayFieldsTransactionRequest) {
		return this.openEdgePaymentGandalfService.createSaleTransaction(request);
	}

	/* istanbul ignore next: gandalf */
	createStoredTokenSaleTransaction(request: OpenEdgeCreateStoredCardTransactionRequest) {
		return this.openEdgePaymentGandalfService.createStoredTokenSaleTransaction(request);
	}

	/* istanbul ignore next: gandalf */
	createStoredTokenRefundTransaction(request: OpenEdgeCreateStoredCardTransactionRequest) {
		return this.openEdgePaymentGandalfService.createStoredTokenRefundTransaction(request);
	}

	/* istanbul ignore next: gandalf */
	setupTransportKeysForSale(request: OpenEdgeSetupTransportKeysRequest) {
		return this.openEdgePaymentGandalfService.setupTransportKeysForSale(request);
	}

	/* istanbul ignore next: gandalf */
	finishTransaction(request: OpenEdgeFinishTransactionRequest) {
		return this.openEdgePaymentGandalfService.finishTransaction(request);
	}

	/* istanbul ignore next: gandalf */
	setupTransportKeysForRefund(request: OpenEdgeSetupTransportKeysRequest) {
		return this.openEdgePaymentGandalfService.setupTransportKeysForReturn(request);
	}

	getUrlForCardReader(transportKey: string, reader: OpenEdgeCardReaderResponse) {
		const urlPathAndParams = `/v2/pos?TransportKey=${transportKey}&Format=JSON`;
		return `${this.getHostname(reader)}${urlPathAndParams}`;
	}

	private getHostname(reader: OpenEdgeCardReaderResponse) {
		const openEdgeConfiguration = this.securityManagerService.environmentData.openEdgeConfiguration;
		if (openEdgeConfiguration.geniusSimulate) {
			return `${openEdgeConfiguration.simulatorEndpoint}`;
		} else {
			return `https://${reader.hostName}:8443`;
		}
	}

	callCardReader(transportKey: string, reader: OpenEdgeCardReaderResponse) {
		return this.httpClient.get(this.getUrlForCardReader(transportKey, reader));
	}

	cancelTransaction(reader: OpenEdgeCardReaderResponse) {
		return this.httpClient.get(`${this.getHostname(reader)}/v2/pos?Format=JSON&Action=Cancel`);
	}

	setMostRecentlyUsedCardReader(value) {
		this.localStorageService.setItem(LOCAL_STORAGE_CODES.MOST_RECENT_OPEN_EDGE_READER_LOCAL_STORAGE_KEY, value);
	}

	getMostRecentlyUsedCardReader() {
		return this.localStorageService.getItem(LOCAL_STORAGE_CODES.MOST_RECENT_OPEN_EDGE_READER_LOCAL_STORAGE_KEY);
	}

	/* istanbul ignore next: gandalf */
	findOpenEdgeSaleTransaction(paymentId: number): Observable<OpenEdgePaymentTransactionResponse> {
		return this.openEdgePaymentGandalfService.findOpenEdgeSaleTransaction(paymentId);
	}

	/* istanbul ignore next: gandalf */
	voidSaleTransaction(paymentId: number): Observable<OpenEdgeVoidTransactionResponse> {
		return this.openEdgePaymentGandalfService.voidSaleTransaction(paymentId);
	}

	/* istanbul ignore next: gandalf */
	existsActiveOpenEdgePaymentByInvoice(invoiceId: number) {
		return this.openEdgePaymentGandalfService.existsActiveOpenEdgePaymentByInvoice(invoiceId);
	}

	findSavedPaymentCardsByPersonId(personId: number): Observable<PaymentStoredTokenResponse[]> {
		return this.openEdgePaymentGandalfService.findSavedCardsByPersonId(personId);
	}

	findSavedPaymentCardsByPersonIdForTable(personId: number): Observable<PaymentStoredTokenResponseForTable[]> {
		return this.openEdgePaymentGandalfService.findSavedCardsByPersonId(personId).pipe(
			map(tokens => tokens.map(this.formatPaymentCardInformationForDisplay)),
		);
	}

	findSavedPaymentCardsByPersonIdForDropdown(personId: number): Observable<OptionItem[]> {
		return this.openEdgePaymentGandalfService.findSavedCardsByPersonId(personId).pipe(
			map(tokens => tokens.map(this.formatPaymentCardInformationForDisplay)),
			map(tokens => OptionItemService.toOptionItems(tokens, item => item.paymentStoredTokenId, item => item.cardTypeString)),
			map(tokens => SortingService.sortBy(tokens, ['paymentStoredTokenId'], ['desc'])),
		);
	}

	getActiveSavedTokensForPatientById(patientId: number) {
		return this.openEdgePaymentGandalfService.getActiveSavedTokensForPatientById(patientId).pipe(
			map(tokens => tokens.map(this.formatPaymentCardInformationForDisplay)),
			map(tokens => OptionItemService.toOptionItems(tokens, item => item.paymentStoredTokenId, item => item.cardTypeString)),
			map(tokens => SortingService.sortBy(tokens, ['paymentStoredTokenId'], ['desc'])),
		);
	}

	private formatPaymentCardInformationForDisplay = token => {
		const tokenForTable: PaymentStoredTokenResponseForTable = token as PaymentStoredTokenResponseForTable;
		tokenForTable.cardTypeString = `${token.cardType} ending in ${token.maskedCardNumber?.slice(-4)}`;
		tokenForTable.expired = this.cardIsExpired(token.expirationMonth, token.expirationYear);
		tokenForTable.expirationDateString = (`${tokenForTable.expired ? 'Expired ' : ''}` +
			`${_padStart(token.expirationMonth.toString(), 2, '0')}/${token.expirationYear}`).trim();
		return tokenForTable;
	};

	cardIsExpired(expirationMonth, expirationYear) {
		const expirationDate = dayjs(new Date(expirationYear, expirationMonth - 1, 1)).endOf('month');
		return dayjs().isAfter(expirationDate);
	}

	/* istanbul ignore next: openModal */
	showPaymentNotAppliedAlert(transaction: OpenEdgeTransactionDetailsResponse) {
		const approvedAmount = this.currencyPipe.transform(transaction.amount);
		DialogUtil.alert({
			title: 'Payment Not Applied',
			content: `<p>A partial payment of ${approvedAmount} was charged to the credit card but <b>this payment could not be applied in RevolutionEHR</b>. `
				+ `The transaction amount can be found in the Global Payments portal. The partial payment of ${approvedAmount} can be manually applied in `
				+ `RevolutionEHR or manually refunded in the Global Payments portal.</p>`,
		});
	}

	/* istanbul ignore next: gandalf */
	createOpenEdgeSavedCard(request: OpenEdgeCreateSavedCardRequest) {
		return this.openEdgePaymentGandalfService.createSavedCard(request);
	}

	/* istanbul ignore next: gandalf */
	removeStoredToken(paymentStoredTokenId: number) {
		return this.openEdgePaymentGandalfService.removeStoredToken(paymentStoredTokenId);
	}
}
