<ejs-dialog
		#modal
		cssClass="modal-md"
		(close)="closeModal()"
		[closeOnEscape]="!isProcessing"
		[showCloseIcon]="!isProcessing"
		[attr.data-test-id]="'onFileProcessCardModal'">
	<ng-template #header>
		<div class="dlg-template">Process {{transactionMode}}</div>
	</ng-template>
	<ng-template #content>
		<div *ngIf="error" class="alert alert-danger">
			<ul>
				<li><strong>{{error}}</strong></li>
			</ul>
		</div>
		<gandalf-lib-validation-messages
				*ngIf="formGroup.invalid"
				[form]="formGroup"
				[requestObj]="request">
		</gandalf-lib-validation-messages>
		<div class="text-center">
			<p>Total Amount<strong class="margin-left-sm">{{ amount | currency }}</strong></p>
		</div>
		<div [class.approving_loader]="isProcessing"></div>
	</ng-template>
	<ng-template #footerTemplate>
		<ng-container *ngIf="!isProcessing">
			<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'cancelOnFileProcessCardButton'">Cancel</button>
			<button *ngIf="showRetry" ejs-button
					[isPrimary]="true"
					type="button"
					(click)="createStoredTokenTransaction()"
					[attr.data-test-id]="'retryOnFileProcessCardButton'">Retry
			</button>
		</ng-container>
	</ng-template>
</ejs-dialog>

