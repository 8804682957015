<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-md"
		[attr.data-test-id]="'addAdhocItemModal'">
	<ng-template #header>
		<div class="dlg-template">Add Adhoc Item</div>
	</ng-template>
		<ng-template #content>
			<form
				#templateForm="ngForm"
				(ngSubmit)="save()"
				[formGroup]="componentForm"
				class="form-horizontal">
				<gandalf-lib-validation-messages
					*ngIf="componentForm.invalid && templateForm.submitted"
					[form]="componentForm"
					[requestObj]="addAdhocItemRequest"
					[attr.data-test-id]="'addAdhocItemModalValidationMessages'">
				</gandalf-lib-validation-messages>
				<div class="form-group">
					<label class="col-md-3 control-label required" for="postDate">Post Date</label>
					<div class="col-md-9">
						<ejs-datepicker
							class="e-input"
							formControlName="postDate"
							id="postDate"
							[format]="dateFormat"
							placeholder="mm/dd/yyyy"
							[attr.data-test-id]="'addAdhocItemModalPostDateInput'">
						</ejs-datepicker>
					</div>
				</div>
				<div class="form-group">
					<label for="code" class="col-md-3 control-label">Code</label>
					<div class="col-md-9">
						<input id="code" type="text" class="e-input" formControlName="code"/>
					</div>
				</div>
				<div class="form-group">
					<label for="description" class="col-md-3 control-label required">Description</label>
					<div class="col-md-9">
						<input id="description" type="text" class="e-input" formControlName="description"/>
					</div>
				</div>
				<div class="form-group">
					<label for="quantity" class="col-md-3 control-label required">Quantity</label>
					<div class="col-md-9">
						<input id="quantity" type="text" class="e-input" formControlName="quantity" revDigitOnlyInput/>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'addAdhocItemModalUnitPriceLabelGroup'">
					<label for="unitPrice" class="col-md-3 control-label required">Unit Price</label>
					<div class="col-md-9">
						<pms-always-set-currency-input
							htmlId="unitPrice"
							formControlName="unitPrice"
							min="0">
						</pms-always-set-currency-input>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'addAdhocItemModalSubTotalLabelGroup'">
					<label for="subTotal" class="col-md-3 control-label">Sub-Total</label>
					<div class="col-md-9">
						<p class="form-control-static" id="subTotal">${{subTotalString}}</p>
					</div>
				</div>
				<div class="form-group">
					<label for="TaxRate" class="col-md-3 control-label" [attr.data-test-id]="'addAdhocItemModalTaxRateLabel'">Tax Rate</label>
					<div class="col-sm-9">
						<ejs-dropdownlist
								id="TaxRate"
								[dataSource]="locationTaxList"
								formControlName="taxId"
								[showClearButton]="true"
								placeholder="Select Tax Rate">
						</ejs-dropdownlist>
					</div>
				</div>
				<div class="form-group" [attr.data-test-id]="'addAdhocItemModalTotalLabelGroup'">
					<label for="total" class="col-md-3 control-label">Total</label>
					<div class="col-md-9">
						<p class="form-control-static" id="total">${{totalString}}</p>
					</div>
				</div>
			</form>
		</ng-template>
		<ng-template #footerTemplate>
			<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'addAdhocItemModalCancelButton'">Cancel</button>
			<button
					type="button"
					ejs-button
					[isPrimary]="true"
					(click)="submitForm($event)"
					[attr.data-test-id]="'addAdhocSaveButton'">Save
			</button>
		</ng-template>
</ejs-dialog>
