import { Injectable } from '@angular/core';
import {
	AbstractControlOptions,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import { GANDALF_ARRAY_TYPE_METADATA_KEY } from '../decorators/gandalf-array.decorator';
import { GANDALF_PROPERTY_METADATA_KEY } from '../decorators/gandalf-property.decorator';
import { GANDALF_JSON_SUBTYPE_PROPERTY } from '../gandalf.constants';
import { GandalfModelBase } from '../gandalf-model-base.class';
import { enumerateMetadataPropertyNames, getMetadataPropertyValue } from '../metadata/gandalf-metadata-util';
import { GandalfFormArray } from './GandalfFormArray';
import { requiredValidator } from './validators/required.validator';
import { GandalfFormGroup } from './gandalf-form-group';

/**
 * To be used as the generic parameter for an Angular {@link FormGroup}.
 * From a {@link GandalfModelBase}, defines a type in the shape:
 * ```
 * {
 *     name: FormControl<string>;
 *     dateOfBirth: FormControl<Date>;
 *     currentAddress: FormGroup<{
 *         line1: FormControl<string>;
 *         state: FormControl<string>;
 *     }>;
 *     previousAddresses: FormArray<FormGroup<{
 *         line1: FormControl<string>;
 *         state: FormControl<string>;
 *     }>>;
 *    scores: FormArray<number>;
 * }
 * ```
 */
export type GandalfFormControls<T extends GandalfModelBase> = {
	// Iterate through all properties in T, omitting the GandalfModelBase keys
	[K in keyof OmitGandalfKeys<T>]: T[K] extends (infer U)[]
		// The property is an array
		? (
			U extends GandalfModelBase
				? TypedFormGroupArray<U> // Property is an array of GandalfModels
				: FormControl<T[K]> // Property is an array of primitives, we reached the end
		)
		// The property is not an array
		: T[K] extends GandalfModelBase
			? TypedFormGroup<T[K]> // Property is a GandalfModel, so we recurse to create additional FormGroups
			: FormControl<T[K]> // property is a primitive, we reached the end
};

/**
 * Type alias for a generic Angular {@link FormGroup} based on a {@link GandalfModelBase}
 */
export type TypedFormGroup<T extends GandalfModelBase> = FormGroup<GandalfFormControls<T>>;

/**
 * Type alias for a generic Angular {@link FormArray} that is composed of Angular {@link FormGroup}
 */
export type TypedFormGroupArray<T extends GandalfModelBase> = FormArray<TypedFormGroup<T>>;

/**
 * A type which omits the keys of {@link GandalfModelBase}
 */
export type OmitGandalfKeys<T extends GandalfModelBase> = Omit<T, keyof GandalfModelBase>;

@Injectable({
	providedIn: 'root',
})
export class GandalfFormBuilder {

	constructor(
		private formBuilder: UntypedFormBuilder,
		private typedFormBuilder: FormBuilder,
	) {
	}

	/**
	 * deprecated - Use {@link groupTyped}.
	 *
	 * Passing in a Gandalf request model instance will output an Angular FormBuilder FormGroup.
	 * @param requestObject - A Gandalf request model instance
	 * @param options Configuration options object for the `FormGroup`. The object can have two shapes:
	 *
	 * 1) `AbstractControlOptions` object (preferred), which consists of:
	 * * `validators`: A synchronous validator function, or an array of validator functions
	 * * `asyncValidators`: A single async validator or array of async validator functions
	 * * `updateOn`: The event upon which the control should be updated (options: 'change' | 'blur' |
	 * submit')
	 *
	 * 2) Legacy configuration object, which consists of:
	 * * `validator`: A synchronous validator function, or an array of validator functions
	 * * `asyncValidator`: A single async validator or array of async validator functions
	 */
	group(requestObject: GandalfModelBase, options: AbstractControlOptions|null = null, createSubRequests = true, validateArraySubrequests = true): UntypedFormGroup {
		const arrayFields = enumerateMetadataPropertyNames(requestObject, GANDALF_ARRAY_TYPE_METADATA_KEY);
		const gandalfProperties = enumerateMetadataPropertyNames(requestObject, GANDALF_PROPERTY_METADATA_KEY);
		let formElements = gandalfProperties ? gandalfProperties : [];
		formElements = arrayFields ? [...formElements, ...arrayFields] : formElements;

		const groupConfig = {};
		formElements.forEach((item) => {
			const arrayType = getMetadataPropertyValue(requestObject, GANDALF_ARRAY_TYPE_METADATA_KEY, item);
			const propertyReturnType = Reflect.getMetadata('design:type', requestObject, item);
			let validatorsList;
			/* istanbul ignore next : Else clause is not easy to test, and ignore else below is not working */
			if (this.isGandalfType(propertyReturnType) && createSubRequests) {
				let subitem = requestObject[item];
				if (!subitem) { // if there's no concrete instance make one for the validations
					subitem = new propertyReturnType();
				}
				groupConfig[item] = this.group(subitem); // do not apply the options to sub groups
			} else if (Array.isArray(propertyReturnType.prototype) && validateArraySubrequests && arrayType && this.isGandalfType(arrayType)) {
				const gandalfFormArray = new GandalfFormArray(this, arrayType, []);
				groupConfig[item] = gandalfFormArray;
				const array: any[] = requestObject[item];
				if (array) {
					array.forEach(subArrayItem => {
						if (this.isGandalfModel(subArrayItem)) {
							gandalfFormArray.pushRequestItem(subArrayItem);
						}
					});
				}
			} else {
				// Initialize the list of validators based on $validators
				if (requestObject.$validators[item]) {
					validatorsList = requestObject.$validators[item].validatorList;
				} else {
					validatorsList = [];
				}
				// Add required validator based on $isRequired
				if (requestObject.$isRequired[item]) {
					validatorsList = validatorsList.concat(requiredValidator);
				}
				groupConfig[item] = [requestObject[item], validatorsList];
			}
		});

		// Check for JSON subtype indicator
		if (requestObject[GANDALF_JSON_SUBTYPE_PROPERTY]) {
			groupConfig[GANDALF_JSON_SUBTYPE_PROPERTY] = [requestObject[GANDALF_JSON_SUBTYPE_PROPERTY], []];
		}

		return this.formBuilder.group(groupConfig, options);
	}

	groupTyped<T extends GandalfModelBase>(requestObject: T, options: AbstractControlOptions|null = null): TypedFormGroup<T> {
		const arrayFields = enumerateMetadataPropertyNames(requestObject, GANDALF_ARRAY_TYPE_METADATA_KEY);
		const gandalfProperties = enumerateMetadataPropertyNames(requestObject, GANDALF_PROPERTY_METADATA_KEY);
		let formElements: string[] = gandalfProperties ? gandalfProperties : [];
		formElements = arrayFields ? [...formElements, ...arrayFields] : formElements;

		const groupConfig = {};
		formElements.forEach((item) => {
			const arrayType = getMetadataPropertyValue(requestObject, GANDALF_ARRAY_TYPE_METADATA_KEY, item);
			const propertyReturnType = Reflect.getMetadata('design:type', requestObject, item);
			let validatorsList;
			/* istanbul ignore next : Else clause is not easy to test, and ignore else below is not working */
			if (this.isGandalfType(propertyReturnType)) {
				let subItem = requestObject[item];
				if (!subItem) { // if there's no concrete instance make one for the validations
					subItem = new propertyReturnType();
				}
				groupConfig[item] = this.groupTyped(subItem); // do not apply the options to subgroups
			} else if (Array.isArray(propertyReturnType.prototype) && arrayType && this.isGandalfType(arrayType)) {
				const formArray = this.typedFormBuilder.array<FormGroup>([]);
				groupConfig[item] = formArray;
				const array: any[] = requestObject[item];
				if (array) {
					array.forEach(subArrayItem => {
						if (this.isGandalfModel(subArrayItem)) {
							const thing = this.groupTyped(subArrayItem);
							formArray.push(thing);
						}
					});
				}
			} else {
				// Initialize the list of validators based on $validators
				if (requestObject.$validators[item]) {
					validatorsList = requestObject.$validators[item].validatorList;
				} else {
					validatorsList = [];
				}
				// Add required validator based on $isRequired
				if (requestObject.$isRequired[item]) {
					validatorsList = validatorsList.concat(requiredValidator);
				}
				groupConfig[item] = [requestObject[item], validatorsList];
			}
		});

		// Check for JSON subtype indicator
		if (requestObject[GANDALF_JSON_SUBTYPE_PROPERTY]) {
			groupConfig[GANDALF_JSON_SUBTYPE_PROPERTY] = [requestObject[GANDALF_JSON_SUBTYPE_PROPERTY], []];
		}

		return this.typedFormBuilder.group(groupConfig, options) as unknown as TypedFormGroup<T>;
	}

	private isGandalfType(propertyReturnType) {
		return propertyReturnType.prototype instanceof GandalfModelBase;
	}

	private isGandalfModel(model) {
		return model instanceof GandalfModelBase;
	}

	/**
	 * deprecated - Use {@link groupTyped}.
	 */
	groupGandalfControl<T extends GandalfModelBase>(
		requestObject: T,
		options: AbstractControlOptions | null = null,
		createSubRequests = true,
		validateArraySubrequests = true,
	): GandalfFormGroup<T> {
		const formGroup = this.group(requestObject, options, createSubRequests, validateArraySubrequests);
		return new GandalfFormGroup<T>(formGroup);
	}

}
