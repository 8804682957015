import { Injectable } from '@angular/core';
import { _isNil } from '@core/lodash/lodash';
import { CreateFolderRequest } from '@gandalf/model/create-folder-request';
import { DeleteFolderRequest } from '@gandalf/model/delete-folder-request';
import { FileFolderResponse } from '@gandalf/model/file-folder-response';
import { MoveFolderRequest } from '@gandalf/model/move-folder-request';
import { UpdateFolderRequest } from '@gandalf/model/update-folder-request';
import { FolderGandalfService } from '@gandalf/services';
import { TreeNode } from '@shared/interfaces/treeview';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { FieldsSettingsModel } from '@syncfusion/ej2-navigations/src/treeview/treeview-model';
import { DialogUtil } from 'morgana';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SimpleFolder {
	folderId: string;
	name: string;
	parentId?: string;
	children: TreeNode[];
}

@Injectable({
	providedIn: 'root',
})
export class FolderService {

	constructor(
		private folderGandalfService: FolderGandalfService,
	) {
	}

	static convertToFieldsSettingsModel(treeNodes: TreeNode[]): FieldsSettingsModel {
		return {
			id: 'data',
			text: 'label',
			tooltip: 'label',
			child: 'children',
			expanded: 'expanded',
			selected: 'selected',
			dataSource: treeNodes,
		};
	}

	getPatientFolders(includeFolderIcon = false) {
		return this.folderGandalfService.getPatientFolders().pipe(
			map(
				folders => folders.map(
					folder => this.convertToTreeNode(folder, includeFolderIcon),
				),
			),
		);
	}

	private convertToTreeNode(node: FileFolderResponse, includeFolderIcon = false) {
		if (_isNil(node)) {
			return;
		}
		let children: TreeNode[];
		if (!_isNil(node.folders)) {
			children = node.folders.map(folder => this.convertToTreeNode(folder, includeFolderIcon));
		}
		const treeNode: TreeNode = {
			label: node.name,
			data: node.id,
			children,
			selected: false,
			expanded: false,
			htmlAttributes: { 'data-test-id': `folder-${node.id}` },
		};
		if (includeFolderIcon) {
			treeNode.icon = 'fa fa-folder';
		}

		return treeNode;
	}

	deselectAndContractTree(node: TreeNode, contract: boolean) {
		node.selected = false;
		if (contract) {
			node.expanded = false;
		}
		if (!_isNil(node.children)) {
			node.children.forEach(child => this.deselectAndContractTree(child, contract));
		}
	}

	searchTree(nodes: TreeNode[], data: string, selectNode = false, contract = true) {
		if (nodes.length === 1) {
			this.deselectAndContractTree(nodes[0], contract);
			return this._searchTree(nodes[0], data, selectNode);
		}
		const root = {
			data: undefined,
			label: '',
			parentId: undefined,
			expanded: true,
			selected: false,
			children: nodes,
		};
		this.deselectAndContractTree(root, contract);
		return this._searchTree(root, data, selectNode);
	}

	private _searchTree(node: TreeNode, data: string, selectNode = false) {
		if (node.data === data) {
			if (selectNode) {
				node.selected = true;
			}
			return node;
		} else if (!_isNil(node.children)) {
			let result = null;
			for (let i = 0; result === null && i < node.children.length; i++) {
				result = this._searchTree(node.children[i], data, selectNode);
			}
			if (result !== null) {
				node.expanded = true;
				if (_isNil(result.parentId)) {
					result.parentId = node.data;
				}
			}
			return result;
		}
		return null;
	}

	deleteFolder(folderId: string, folderName: string) {
		const successSubject = new Subject();
		const confirmation = DialogUtil.confirm({
			title: `Delete Folder`,
			content: `Are you sure you want to delete the folder "${folderName}"?`,
			okButton: {
				click: () => this._deleteFolder(folderId, successSubject, confirmation),
			},
		});
		return successSubject.asObservable();
	}

	/* istanbul ignore next: gandalf */
	_deleteFolder(folderId: string, successSubject: Subject<any>, confirmation: Dialog) {
		const req = new DeleteFolderRequest();
		req.folderId = folderId;
		confirmation.close();
		return this.folderGandalfService.deletePatientFolder(req).subscribe(result => {
			successSubject.next(result);
		});
	}

	/* istanbul ignore next: gandalf */
	addFolder(request: CreateFolderRequest) {
		return this.folderGandalfService.createPatientFolder(request);
	}

	/* istanbul ignore next: gandalf */
	moveFolder(folderToMoveId: string, parentFolderId: string, destinationFolderId: string) {
		const request = new MoveFolderRequest();
		request.folderId = folderToMoveId;
		request.parentFolderId = parentFolderId;
		request.afterFolderId = destinationFolderId;
		return this.folderGandalfService.movePatientFolder(request);
	}

	/* istanbul ignore next: gandalf */
	updateFolder(request: UpdateFolderRequest) {
		return this.folderGandalfService.updatePatientFolder(request);
	}
}
