// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryResponse } from './query-response';

// @ts-ignore
import { QueryTriggerCarePlanItemTemplateResponse } from './query-trigger-care-plan-item-template-response';

// @ts-ignore
import { QueryTriggerCarePlanTemplateResponse } from './query-trigger-care-plan-template-response';

/** See com.rev360.pms.api.controller.query.QueryTriggerResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryTriggerResponse extends GandalfModelBase {

	@GandalfArray(QueryTriggerCarePlanItemTemplateResponse)
	carePlanItemTemplates: QueryTriggerCarePlanItemTemplateResponse[];

	@GandalfArray(QueryTriggerCarePlanTemplateResponse)
	carePlanTemplates: QueryTriggerCarePlanTemplateResponse[];

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	interventionBibliographicCitation: string;

	@GandalfProperty()
	interventionDeveloper: string;

	@GandalfProperty()
	interventionDeveloperFunding: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	interventionReleaseDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	interventionRevisionDate: Date;

	@GandalfProperty()
	query: QueryResponse;

}
