<div class="form-horizontal" *ngIf="contactLensPrescription">
	<div class="row">
		<div class="col-md-12" [ngClass]="{'col-lg-12' : isContactLensTrial(), 'col-lg-8' : !isContactLensTrial()}">
			<h4 class="section-title bg-default mrgn-btm">Rx</h4>
			<button ejs-button
					*ngIf="showViewRxHistoryButton"
					type="button"
					[isPrimary]="false"
					class="e-control e-btn e-lib margin-bottom-sm"
					(click)="viewRxHistory()" [attr.data-test-id]="'viewPrescriptionHistoryButton'">View Rx History
			</button>
			<div class="table-responsive margin-bottom-md">
				<table class="table table-hover table-nowrap table-bordered margin-0" [attr.data-test-id]="'contactLensPrescriptionDetailsTable'">
					<thead>
					<tr>
						<th></th>
						<th>Manufacturer</th>
						<th>Model</th>
						<th *ngIf="isOverallLensTypeRgp()">Material</th>
						<th>Type</th>
						<th *ngIf="!isContactLensTrial()">Dispense</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td class="nostretch">
							<span class="label label-success">OD</span><br>
							<span class="label label-info">OS</span>
						</td>
						<td>
							{{ contactLensPrescription.productOd?.manufacturer }}<br>
							{{ contactLensPrescription.productOs?.manufacturer }}
						</td>
						<td>
							{{ contactLensPrescription.productOd?.model }}<br>
							{{ contactLensPrescription.productOs?.model }}
						</td>
						<td *ngIf="isOverallLensTypeRgp()">
							{{ contactLensPrescription.contactLensEyeRgpOd?.lensMaterial?.value }}<br>
							{{ contactLensPrescription.contactLensEyeRgpOs?.lensMaterial?.value }}
						</td>
						<td>
							<ng-container *ngIf="contactLensPrescription.productOd">{{ contactLensPrescription.overallLensType }}</ng-container>
							<br>
							<ng-container *ngIf="contactLensPrescription.productOs">{{ contactLensPrescription.overallLensType }}</ng-container>
						</td>
						<td *ngIf="!isContactLensTrial()">
							<ng-container *ngIf="contactLensPrescriptionNoTrial?.dispenseOdAmount">
								{{ contactLensPrescriptionNoTrial?.dispenseOdAmount }} {{ contactLensPrescriptionNoTrial?.dispenseOdUnit?.value }}
							</ng-container>
							<br>
							<ng-container *ngIf="contactLensPrescriptionNoTrial?.dispenseOsAmount">
								{{ contactLensPrescriptionNoTrial?.dispenseOsAmount }} {{ contactLensPrescriptionNoTrial?.dispenseOsUnit?.value }}
							</ng-container>
						</td>
						<td class="nostretch">
							<a
								*ngIf="canShowEyedockButton(contactLensPrescription.productOd)"
								class="eyedock-icon"
								href="{{ createEyedockUrl(contactLensPrescription.productOd) }}"
								target="_blank"
								revTooltip
								tooltipContent="View Details"
								[attr.data-test-id]="'contactLensPrescriptionOdEyedocButton'"></a>
							<br>
							<a
								*ngIf="canShowEyedockButton(contactLensPrescription.productOs)"
								class="eyedock-icon"
								href="{{ createEyedockUrl(contactLensPrescription.productOs) }}"
								target="_blank"
								revTooltip
								tooltipContent="View Details"
								[attr.data-test-id]="'contactLensPrescriptionOsEyedocButton'"></a>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<pms-contact-lens-type-soft *ngIf="isOverallLensTypeSoft()" [contactLensPrescription]="contactLensPrescription"></pms-contact-lens-type-soft>
			<pms-contact-lens-type-rgp *ngIf="isOverallLensTypeRgp()" [contactLensPrescription]="contactLensPrescription"></pms-contact-lens-type-rgp>
			<pms-contact-lens-type-hybrid *ngIf="isOverallLensTypeHybrid()" [contactLensPrescription]="contactLensPrescription"></pms-contact-lens-type-hybrid>
			<div class="row" *ngIf="showAdditionalInfo">
				<div class="col-sm-6" [attr.data-test-id]="'schedulesSection'">
					<h4 class="section-title bg-default mrgn-btm">Schedules</h4>
					<div class="form-group" [attr.data-test-id]="'schedulesModality'">
						<label class="col-sm-5 control-label padding-x-0">Modality</label>
						<div class="col-sm-7" *ngIf="isModalityNotUnknown(contactLensPrescriptionNoTrial?.modality)">
							<p class="margin-0"> {{contactLensPrescriptionNoTrial?.modality}} </p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'odLabelGroup'">
						<div class="control-label col-sm-5 padding-x-0">
							<h4 class="margin-0"><span class="label label-success">OD</span>
							</h4>
						</div>
						<div class="col-sm-7"></div>
					</div>
					<div class="form-group" [attr.data-test-id]="'odOvernightGroup'">
						<label class="col-sm-5 control-label padding-x-0">Overnight</label>
						<div class="col-sm-7">
							<p class="margin-0"> {{getOvernightScheduleAmount(true)}} </p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'odReplacementGroup'">
						<label class="col-sm-5 control-label padding-x-0">Replacement</label>
						<div class="col-sm-7">
							<p class="margin-0"> {{getReplacementScheduleAmount(true)}} </p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'osLabelGroup'">
						<div class="control-label col-sm-5 padding-x-0">
							<h4 class="margin-0"><span class="label label-info">OS</span>
							</h4>
						</div>
						<div class="col-sm-7"></div>
					</div>
					<div class="form-group" [attr.data-test-id]="'osOvernightGroup'">
						<label class="col-sm-5 control-label padding-x-0">Overnight</label>
						<div class="col-sm-7">
							<p class="margin-0"> {{getOvernightScheduleAmount(false)}} </p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'osReplacementGroup'">
						<label class="col-sm-5 control-label padding-x-0">Replacement</label>
						<div class="col-sm-7">
							<p class="margin-0"> {{getReplacementScheduleAmount(false)}} </p>
						</div>
					</div>
				</div>
				<div class="col-sm-6" [attr.data-test-id]="'prescribedItemsSection'">
					<h4 class="section-title bg-default mrgn-btm">Prescribed Items</h4>
					<div class="form-group" [attr.data-test-id]="'disinfectionSolutionGroup'">
						<label class="col-sm-5 control-label padding-x-0">Disinfection Solution</label>
						<div class="col-sm-7">
							<p class="margin-0"> {{contactLensPrescriptionNoTrial?.solutionName}} </p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'lensCleanerGroup'">
						<label class="col-sm-5 control-label padding-x-0">Lens Cleaner</label>
						<div class="col-sm-7">
							<p class="margin-0"> {{contactLensPrescriptionNoTrial?.cleanerName}} </p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'lensDropGroup'">
						<label class="col-sm-5 control-label padding-x-0">Lens Drop</label>
						<div class="col-sm-7">
							<p class="margin-0"> {{contactLensPrescriptionNoTrial?.dropsName}} </p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'enzymaticCleanerGroup'">
						<label class="col-sm-5 control-label padding-x-0">Enzymatic Cleaner</label>
						<div class="col-sm-7">
							<p class="margin-0"> {{contactLensPrescriptionNoTrial?.enzymaticCleanerName}} </p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-lg-4" *ngIf="!isContactLensTrial()">
			<div class="row">
				<div class="col-sm-6" [attr.data-test-id]="'contactLensPrescriptionAuthorizationSection'">
					<h4 class="section-title bg-default mrgn-btm">Authorization</h4>
					<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionTypeFormGroup'">
						<label class="col-sm-5 control-label padding-x-0">Rx Type</label>
						<div class="col-sm-7">
							<p class="margin-0">{{ getRxType() }}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionAuthorizationTypeFormGroup'">
						<label class="col-sm-5 control-label padding-x-0">Auth. Type</label>
						<div class="col-sm-7">
							<p class="margin-0">{{ contactLensPrescriptionNoTrial?.authorizationType }}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionAuthorizationDateFormGroup'">
						<label class="col-sm-5 control-label padding-x-0">Auth. Date</label>
						<div class="col-sm-7">
							<p class="margin-0">{{ contactLensPrescriptionNoTrial?.authorizationDate | date:dateFormat }}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionAuthorizedByFormGroup'">
						<label class="col-sm-5 control-label padding-x-0">Auth. By</label>
						<div class="col-sm-7">
							<p class="margin-0">{{ getAuthByName() }}</p>
						</div>
					</div>
				</div>
				<div class="col-sm-6" [attr.data-test-id]="'contactLensPrescriptionDatesFormGroup'">
					<h4 class="section-title bg-default mrgn-btm">Dates</h4>
					<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionStartDateFormGroup'">
						<label class="col-sm-5 control-label padding-x-0">Start Date</label>
						<div class="col-sm-7">
							<p class="margin-0">{{ contactLensPrescriptionNoTrial?.startDate | date:dateFormat }}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionEndDateFormGroup'">
						<label class="col-sm-5 control-label padding-x-0">End Date</label>
						<div class="col-sm-7">
							<p class="margin-0">{{ contactLensPrescriptionNoTrial?.endDate | date:dateFormat }}</p>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionExpirationDateFormGroup'">
						<label class="col-sm-5 control-label padding-x-0">Expiration Date</label>
						<div class="col-sm-7">
							<p class="margin-0">{{ contactLensPrescriptionNoTrial?.expirationDate | date:dateFormat }}</p>
						</div>
					</div>
				</div>
			</div>
			<pms-contact-lens-electronic-delivery-method
					[contactLensPrescription]="contactLensPrescriptionNoTrial">
			</pms-contact-lens-electronic-delivery-method>
			<h4 class="section-title bg-default mrgn-btm">Comments</h4>
			<div class="form-group" [attr.data-test-id]="'contactLensPrescriptionNoTrialLabelGroup'">
				<div class="col-sm-12">
					<div class="margin-0">{{ contactLensPrescriptionNoTrial?.description }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
