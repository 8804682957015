import { AccountingViewService } from '@accounting/core/accounting/accounting-view-util/accounting-view.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnumUtil, ModalManagerService } from 'morgana';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { InvoiceStatus } from '@gandalf/constants';
import Big from 'big.js';
import { AccountingService } from '../../../../core/accounting/accounting.service';
import { InvoiceService } from '../../../../core/accounting/invoice-service/invoice.service';
import { VoidInvoiceCommentModalComponent } from '../../../void-invoice-comment-modal/void-invoice-comment-modal.component';
/* eslint-disable max-len */
import { VoidInvoiceWithPaymentWarningModalComponent } from '../../../void-invoice-with-payment-warning-modal/void-invoice-with-payment-warning-modal.component';

@Component({
	selector: 'pms-invoice-status',
	templateUrl: './invoice-status.component.html',
	styles: [],
})
export class InvoiceStatusComponent implements OnInit, OnChanges {

	@Input()
	isReadOnly: boolean;

	@Input()
	isPaymentProcessing: boolean;

	@Input()
	invoice: InvoiceResponse;

	allowEdit: boolean;
	editingStatus = false;
	statusList;
	// shadow of the invoice.status variable
	invoiceStatus;

	constructor(
		private modalManagerService: ModalManagerService,
		private accountingService: AccountingService,
		private accountingViewService: AccountingViewService,
		private invoiceService: InvoiceService,
	) {
	}

	ngOnInit() {
		this.prepareDropdown();
	}

	ngOnChanges(_changes: SimpleChanges): void {
		this.prepareDropdown();
	}

	prepareDropdown() {
		this.invoiceStatus = this.invoice.status;
		this.allowEdit = this.isEditAllowed();
		this.updateStatusList();
	}

	isEditAllowed(): boolean {
		return !this.isReadOnly && !this.isPaymentProcessing && !EnumUtil.equals(this.invoice.status, InvoiceStatus.VOIDED);
	}

	updateStatus() {
		if (EnumUtil.equals(this.invoiceStatus, this.invoice.status)) {
			// no change
			this.cancelEditStatus();
			return;
		}
		switch (this.invoiceStatus.value) {
			case InvoiceStatus.ACTIVE.value:
				this.accountingService.markInvoiceUnpaid(this.invoice.id).subscribe(() => {
					this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
				});
				break;
			case InvoiceStatus.PAID.value:
				this.accountingService.markInvoicePaid(this.invoice.id).subscribe(() => {
					this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
				});
				break;
			case InvoiceStatus.ON_HOLD.value:
				this.accountingService.putInvoiceOnHold(this.invoice.id).subscribe(() => {
					this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
				});
				break;
			case InvoiceStatus.VOIDED.value:
				if (Big(this.invoice.amountPaid).eq(0)) {
					this.modalManagerService.open(VoidInvoiceCommentModalComponent, {data: {invoiceId: this.invoice.id}}).onClose.subscribe(() => {
						this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
					});
				} else {
					this.modalManagerService.open(VoidInvoiceWithPaymentWarningModalComponent, {data: {invoiceId: this.invoice.id}});
				}
				break;
		}
		this.cancelEditStatus();
	}

	editStatus() {
		this.editingStatus = true;
	}

	cancelEditStatus() {
		this.editingStatus = false;
		this.invoiceStatus = this.statusList.values[0];
	}

	updateStatusList() {
		const newStatusList = [];
		if (this.accountingViewService.canResetActive(this.invoice)) {
			newStatusList.push(InvoiceStatus.ACTIVE);
		}
		if (this.accountingViewService.canMarkPaid(this.invoice)) {
			newStatusList.push(InvoiceStatus.PAID);
		}
		if (this.accountingViewService.canVoid(this.invoice)) {
			newStatusList.push(InvoiceStatus.VOIDED);
		}
		if (this.accountingViewService.canPutOnHold(this.invoice)) {
			newStatusList.push(InvoiceStatus.ON_HOLD);
		}
		this.statusList = {
			label: 'valid status actions',
			values: newStatusList,
		};
		this.invoiceStatus = this.statusList.values[0];
	}

}
