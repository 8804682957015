// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.SplitPaymentRequest */
/* istanbul ignore next */
@GandalfModel
export class SplitPaymentRequest extends GandalfModelBase {

	@GandalfProperty()
	encounterId: number;

	@GandalfProperty({ propertyType: 'Money' })
	insurancePortion: number;

	@GandalfValidator({ notNull: { message: 'Invoice Id is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

	@GandalfValidator({ notNull: { message: 'Invoice Item Id is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceItemId: number;

	@GandalfProperty()
	orderId: number;

	@GandalfProperty()
	patientId: number;

	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	patientPortion: number;

	@GandalfProperty()
	transferToExistingInvoiceId: number;

}
