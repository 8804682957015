<div class="panel panel-default panel-sm"
	 [ngClass]="{'widget widget-sm': !configuration.useDynamicHeight, 'widget-with-tabs': configuration.hasTabs}">
	<div class="panel-heading cursor-pointer" [attr.data-test-id]="getDataTestId('panelHeading')" (dblclick)="onHeadingDoubleClick()">
		<h4 class="panel-title">
			<i class="margin-right-xs fa" [ngClass]="configuration.icon"></i> <span>{{configuration.title}}</span><sub>{{configuration.subTitle}}</sub>
		</h4>
		<ul class="nav-tools">
			<ng-content select="[custom-add-button]"></ng-content>
			<li *ngIf="showBasicAddButton()">
				<a revTooltip [tooltipContent]="configuration.addTooltip"
				   [attr.data-test-id]="getDataTestId('addSingle')"
				   (click)="configuration.addCallback()"
				   class="e-control e-btn e-lib e-success">
					<i class="fa fa-plus"></i>
				</a>
			</li>
			<li *ngIf="configuration.addDropdown">
				<a ejs-dropdownbutton
				   [attr.data-test-id]="getDataTestId('addDropdown')"
				   [items]="configuration.addDropdown"
				   revEventStopPropagation
				   (select)="addMenuItemClick($event)"
				   revTooltip
				   [tooltipContent]="configuration.addTooltip"
				   iconCss="fa fa-plus"
				   cssClass="e-success e-caret-hide">
				</a>
			</li>

			<li *ngIf="configuration.refreshCallback">
				<a revTooltip
				   [tooltipContent]="configuration.refreshTooltip"
				   [attr.data-test-id]="getDataTestId('refresh')"
				   (click)="configuration.refreshCallback()">
					<i class="fa fa-refresh"></i>
				</a>
			</li>
			<li *ngIf="configuration.expandCallback">
				<a revTooltip
				   [tooltipContent]="configuration.expandTooltip"
				   [attr.data-test-id]="getDataTestId('expand')"
				   (click)="configuration.expandCallback()">
					<i class="fa fa-expand"></i>
				</a>
			</li>
		</ul>
	</div>
	<div class="panel-body">
		<ng-content></ng-content>
	</div>
</div>
