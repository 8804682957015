<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-lg"
		[attr.data-test-id]="'familyMemberQuickAddModal'">
	<ng-template #header>
		<div class="dlg-template">Family Quick Add</div>
	</ng-template>
	<ng-template #content>
		<form *ngIf="componentForm" [formGroup]="componentForm" (ngSubmit)="save()" #templateForm="ngForm" class="form-horizontal">
			<div class="row">
				<div class="col-sm-12">
					<gandalf-lib-validation-messages
							*ngIf="componentForm.invalid && templateForm.submitted"
							[form]="componentForm"
							[requestObj]="quickAddFamilyMemberRequest">
					</gandalf-lib-validation-messages>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<h4 class="section-title bg-default mrgn-btm">Patient Demographics</h4>
					<div [attr.data-test-id]="'familyMemberQuickAddModalDemographicsSection'">
						<pms-family-demographics-form formGroupName="createPatientFamilyDemographicsRequest"></pms-family-demographics-form>
					</div>
					<h4 class="section-title bg-default mrgn-btm">Family Information</h4>
					<div [attr.data-test-id]="'familyMemberQuickAddModalFamilyInformationSection'">
						<pms-family-information-form formGroupName="createPatientFamilyInformationRequest"></pms-family-information-form>
						<div class="form-group">
							<div *ngIf="emergencyContactFeature" class="col-sm-offset-4 col-md-offset-3 col-sm-8 col-md-9">
								<ejs-checkbox label="Emergency Contact" formControlName="isEmergencyContact"></ejs-checkbox>
								<ejs-checkbox *ngIf="isEmergencyContact" label="Set as Primary" formControlName="isPrimaryEmergencyContact" cssClass="margin-left-md"></ejs-checkbox>
							</div>
						</div>
					</div>
					<h4 class="section-title bg-default mrgn-btm">Patient Insurance</h4>
					<div [attr.data-test-id]="'familyMemberQuickAddModalPatientInsuranceSection'">
						<pms-family-patient-insurance-form formGroupName="createPatientFamilyInsuranceRequest"></pms-family-patient-insurance-form>
					</div>
				</div>
				<div class="col-md-6">
					<h4 class="section-title bg-default mrgn-btm">Address</h4>
					<div class="form-group" [attr.data-test-id]="'familyMemberQuickAddModalSameAddressSection'">
						<div class="col-sm-offset-4 col-md-offset-3 col-sm-8 col-md-9">
							<ejs-checkbox label="Same Address" formControlName="usePatientAddress"></ejs-checkbox>
							<i class="fa fa-info-circle text-info margin-left-xs"
							   revTooltip
							   tooltipContent="When checked, this option links the address between the patient and the family member, automatically updating both files when edits are made to the address in either file. Unchecking the box disables this feature.">
							</i>
						</div>
					</div>
					<pms-address-form [isCreating]="true" [autoselectStateProvince]="false" [parentFormGroup]="getFormGroup('addressOptionalRequest')" class="form-layout-col-sm-4-8-md-3-9"></pms-address-form>
					<div *ngIf="emergencyContactFeature">
						<h4 class="section-title bg-default mrgn-btm">Phone/Email</h4>
						<pms-family-member-phone-information-form></pms-family-member-phone-information-form>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'familyMemberQuickAddModalCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'familyMemberQuickAddModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
