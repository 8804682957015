<ejs-dialog
		#modal
		(close)="closeDialog()"
		[attr.data-test-id]="'clinicalSummaryModal'">
	<ng-template #header>
		<div class="dlg-template">{{summaryType['title']}}</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal" [formGroup]="clinicalSummaryFormGroup">
			<div class="row">
				<div class="col-sm-8">
					<div class="form-group" [attr.data-test-id]="'clinicalSummaryModalFileFormatFormGroup'">
						<label class="control-label col-sm-3 required">File Format</label>
						<div class="col-sm-9">
							<pms-enum-select-button
								[enumList]="fileFormats"
								formControlName="exportFileFormat">
							</pms-enum-select-button>
						</div>
					</div>
					<div class="form-group" [attr.data-test-id]="'clinicalSummaryModalSaveToFormGroup'">
						<label class="control-label col-sm-3">Save To </label>
						<div class="col-sm-9">
							<div class="panel panel-default margin-bottom-sm">
								<ejs-treeview *ngIf="patientFolders !== undefined"
											  (nodeSelected)="updateSelectedFolder($event)"
											  [(selectedNodes)]="selectedFolderId"
											  [fields]="fields">
								</ejs-treeview>
							</div>
							<button type="button" iconCss="fa fa-close" ejs-button (click)="clearSelectedFolder()" [attr.data-test-id]="'clinicalSummaryModalClearButton'">
								Clear
							</button>
						</div>
					</div>
				</div>
				<div class="col-sm-4" [attr.data-test-id]="'clinicalSummaryModalDocumentOptionsSection'">
					<ejs-listbox [dataSource]="sectionOptions"
								 formControlName="selectedOptions"
								 [selectionSettings]="{showCheckbox: true, showSelectAll: true}"
								 [fields]="{text: 'label', id: 'value', value: 'value'}">
					</ejs-listbox>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button (click)="closeDialog()" iconCss="fa fa-close" [attr.data-test-id]="'clinicalSummaryCancelButton'">Cancel</button>
		<button type="button" ejs-button (click)="generate()" [isPrimary]="true" [attr.data-test-id]="'clinicalSummaryGenerateButton'">Generate</button>
	</ng-template>
</ejs-dialog>
