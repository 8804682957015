<ng-container *ngIf="!editMode; else editTemplate">
	<i class="fa fa-calendar text-info margin-right-xs"></i>
	{{invoice?.invoiceDate | date:dateFormat}} (<strong *ngIf="invoice" [class]="'text-' + getAgeColorClass()">{{invoice?.age}}</strong> days)
	<a (click)="editInvoiceDate()" *ngIf="allowEdit" class="margin-left-xs" revTooltip tooltipContent="Edit" [attr.data-test-id]="'invoiceDateButton'"><i class="fa fa-pencil"></i></a>
</ng-container>

<ng-template #editTemplate>
	<form [formGroup]="componentForm" (ngSubmit)="updateInvoiceDate()">
		<div class="inplace-editor">
			<div class="inplace-editor-wrapper">
				<div class="inplace-editor-component">
					<ejs-datepicker class="e-input" formControlName="invoiceDate" [showClearButton]="false" [format]="dateFormat" placeholder="mm/dd/yyyy"></ejs-datepicker>
				</div>
				<div class="inplace-editor-actions">
					<button ejs-button iconCss="fa fa-check" type="submit" [attr.data-test-id]="'invoiceDateSubmitChangesButton'"></button>
					<button (click)="cancelEditInvoiceDate()" ejs-button iconCss="fa fa-times" type="button" [attr.data-test-id]="'invoiceDateDiscardChangesButton'"></button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
