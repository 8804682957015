// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonsToBeNotifiedResponse } from './persons-to-be-notified-response';

/** See com.rev360.pms.api.controller.admin.solutions.revclear.RevClearConfigurationResponse */
/* istanbul ignore next */
@GandalfModel
export class RevClearConfigurationResponse extends GandalfModelBase {

	@GandalfProperty()
	defaultPaymentLocationId: number;

	@GandalfProperty()
	personsToBeNotified: PersonsToBeNotifiedResponse;

	@GandalfProperty()
	siteId: string;

}
