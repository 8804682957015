<ejs-dialog
		#modal
		cssClass="modal-lg"
		(close)="closeModal()">
	<ng-template #header>
		<div class="dlg-template">Image Editor</div>
	</ng-template>
	<ng-template #content>
		<div class="row">
			<div class="col-sm-8 col-lg-9 margin-bottom-sm">
				<pintura-editor #doka
						  style="height: 70vh;"
						  [src]="src"
						  [options]="options">
				</pintura-editor>
			</div>
			<div class="col-sm-4 col-lg-3 margin-bottom-sm">
				<div>
					<ul class="list-inline margin-0">
						<ng-container *ngFor="let drawingTemplate of drawingTemplates">
							<li class="margin-xs padding-0">
								<a class="thumbnail margin-0" [class.active]="isCurrentDrawing(drawingTemplate)" (click)="applyDrawing(drawingTemplate)">
									<img src="{{drawingTemplate.filePath}}" alt="{{drawingTemplate.label}}" revTooltip tooltipContent="{{drawingTemplate.label}}">
								</a>
							</li>
						</ng-container>
					</ul>
				</div>
				<hr class="margin-x-md">
				<form #drawingForm="ngForm" [formGroup]="componentForm" *ngIf="componentForm" (ngSubmit)="saveDrawing()">
					<gandalf-lib-validation-messages *ngIf="componentForm.invalid && drawingForm.submitted"
													 [form]="componentForm"
													 [requestObj]="drawingRequest">
					</gandalf-lib-validation-messages>
					<div class="form-horizontal margin-top-lg">
						<div class="form-group">
							<label class="col-sm-4 control-label required">Name</label>
							<div class="col-sm-8">
								<input class="e-input form-control" formControlName="name" />
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-4 control-label">Description</label>
							<div class="col-sm-8">
								<ejs-textbox formControlName="description" class="h-rows-5" [multiline]="true"></ejs-textbox>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" iconCss="fa fa-times" (click)="closeModal()">Cancel</button>
		<button ejs-button type="button" [isPrimary]="true" (click)="submitForm($event)">Save</button>
	</ng-template>
</ejs-dialog>
