<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-sm"
		[attr.data-test-id]="'splitPaymentModal'">
	<ng-template #header>
		<div class="dlg-template">Split Payment</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal"
			  [formGroup]="formGroup"
			  (ngSubmit)="saveSplitPayment()"
			  #templateForm="ngForm">
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && templateForm.submitted"
					[form]="formGroup"
					[requestObj]="request"
					[attr.data-test-id]="'splitPaymentModalValidationMessages'">
			</gandalf-lib-validation-messages>
			<h4 class="section-title bg-default mrgn-btm">{{invoiceItem?.code}} - {{invoiceItem?.description}}</h4>
			<div class="form-group" [attr.data-test-id]="'splitTotalFormGroup'">
				<label class="col-sm-4 control-label">Total</label>
				<div class="col-sm-8">
					<p class="form-control-static">{{invoiceItem?.extendedPrice | currency}}</p>
				</div>
			</div>
			<hr>
			<div class="form-group" [attr.data-test-id]="'splitInsuranceFormGroup'">
				<label class="col-sm-4 control-label">Insurance</label>
				<div class="col-sm-8">
					<pms-currency-input
							htmlId="insurancePortion"
							formControlName="insurancePortion"
							min="0">
					</pms-currency-input>
				</div>
			</div>
			<hr>
			<div class="form-group" [attr.data-test-id]="'splitPatientFormGroup'">
				<label class="col-sm-4 control-label">Patient</label>
				<div class="col-sm-8">
					<pms-currency-input
							#patientPortionInput
							htmlId="patientPortion"
							formControlName="patientPortion">
					</pms-currency-input>
					<div class="margin-top-md" [attr.data-test-id]="'splitApplyToInvoiceFormGroup'">
						<label class="margin-bottom-sm">Apply to Invoice</label>
						<div class="row">
							<div class="col-sm-12 margin-bottom-xs">
						<span class="display-inline-block margin-x-xs margin-right-xs">
							<ejs-radiobutton label="New" name="transferToNewOrExistingInvoice" [value]="TRANSFER_TO_NEW_INVOICE_LABEL" formControlName="transferToNewOrExistingInvoice" [attr.data-test-id]="'applyToInvoiceNewRadio'"></ejs-radiobutton>
						</span>
						<span *ngIf="showTransferToExistingInvoiceOption" class="display-inline-block margin-x-xs margin-right-xs">
							<ejs-radiobutton label="Existing" name="transferToNewOrExistingInvoice" [value]="TRANSFER_TO_EXISTING_INVOICE_LABEL" formControlName="transferToNewOrExistingInvoice" [attr.data-test-id]="'applyToInvoiceExistingRadio'"></ejs-radiobutton>
						</span>
							</div>
							<div *ngIf="showTransferToExistingInvoiceOption" class="col-sm-12">
								<ejs-dropdownlist id="invoices"
												  [dataSource]="transferToInvoiceList"
												  formControlName="transferToExistingInvoiceId"
												  placeholder="Select Invoice">
								</ejs-dropdownlist>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'splitPaymentCancelButton'">Cancel</button>
		<button ejs-button [isPrimary]="true" type="submit" (click)="submitForm($event)" [attr.data-test-id]="'splitPaymentSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>
