// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.StatementInvoiceResponse */
/* istanbul ignore next */
@GandalfModel
export class StatementInvoiceResponse extends GandalfModelBase {

	@GandalfProperty()
	dueDate: string;

	@GandalfProperty()
	id: number;

	@GandalfProperty({ propertyType: 'Money' })
	invoiceBalance: number;

	@GandalfProperty()
	message: string;

	@GandalfProperty({ propertyType: 'Money' })
	payorBalance: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	printDate: Date;

}
