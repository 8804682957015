import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DynamicModalRef, EnumUtil, MODAL, ModalConfig, ModalManagerService, OptionItem } from 'morgana';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { HIT_PMS_HTML_PREFERENCES } from '@core/legacy/hit-pms-html.constants';
import { _isNil } from '@core/lodash/lodash';
import { ProviderDropdownResponse, ProviderService } from '@core/provider/provider.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SummaryPodService } from '@core/summary-pod/summary-pod.service';
import { UrlService } from '@core/url-util/url.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import {
	ContactLensModality,
	ContactLensPrescriptionType,
	ContactLensType,
	DurationUnit,
	EntityType,
	MasterProductCategory,
	PreferenceDefaults,
	PreferenceName,
	PrescriptionAuthorizationType,
	PrescriptionStatus,
	ReferenceDataMasterCategory
} from '@gandalf/constants';
import { ContactLensPrescriptionDetailsResponse } from '@gandalf/model/contact-lens-prescription-details-response';
import { CreateContactLensPrescriptionRequest } from '@gandalf/model/create-contact-lens-prescription-request';
import { RefillContactLensPrescriptionRequest } from '@gandalf/model/refill-contact-lens-prescription-request';
import { UpdateContactLensPrescriptionRequest } from '@gandalf/model/update-contact-lens-prescription-request';
import { ContactLensFilterOptions } from '@shared/component/barcode-search/barcode-search.component';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { TabAnimationDefaults } from '@shared/constants/tab.constants';
import { URL_PRINT_ENDPOINTS } from '@shared/constants/url.constants';
import { assertTrue } from '@shared/validators/assert-true.validation';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import dayjs from 'dayjs';
import { GandalfConstant, GandalfFormBuilder } from 'gandalf';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventoryService } from '@core/inventory/inventory.service';
import { EventService } from '@core/event/event.service';
import { DynamicScreenService } from '../../../../../dynamic-test-screen/dynamic-screen.service';
import { ContactLensService } from '../../contact-lens.service';
import { ContactLensTrialService } from '../contact-lens-trial.service';
import { ContactLensDetailsUtil } from '../shared/contact-lens-details/contact-lens-details-util';
import {
	ClProductSelectionResult,
	ProductSelectionModalComponent
} from '../shared/contact-lens-details/product-selection-modal/product-selection-modal.component';

@Component({
	selector: 'pms-contact-lens-prescription-modal',
	templateUrl: './contact-lens-prescription-modal.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class ContactLensPrescriptionModalComponent extends BaseComponent implements OnInit {
	zIndexBehindHitPmsHtmlModal: number = MODAL.Z_INDEX_BEHIND_HIT_PMS_HTML_MODAL;
	isUpdating = false;

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('templateForm')
	componentForm: NgForm;
	formGroup: UntypedFormGroup;
	tabAnimation = TabAnimationDefaults;
	request: CreateContactLensPrescriptionRequest | RefillContactLensPrescriptionRequest | UpdateContactLensPrescriptionRequest;
	locationId: number;
	encounterId: number;
	patientId: number;
	clrx: ContactLensPrescriptionDetailsResponse;
	deliveryMethodOptions: OptionItem[];
	dispensingUnits: OptionItem[];
	authTypes = [
		new GandalfConstant(PrescriptionAuthorizationType.PROVIDER.value, 'Internal'),
		new GandalfConstant(PrescriptionAuthorizationType.EXTERNAL_PROVIDER.value, 'External'),
	];
	modalities = [
		new GandalfConstant(ContactLensModality.CONTINUOUS_WEAR.value, 'CW'),
		ContactLensModality.DAILY_WEAR,
	];
	externalProviders: ProviderDropdownResponse[];
	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	contactLensFilter = ContactLensFilterOptions.RX;
	overnightDurations = [DurationUnit.DAY, DurationUnit.WEEK, DurationUnit.MONTH];
	replacementDurations = [DurationUnit.DAY, DurationUnit.WEEK, DurationUnit.MONTH, DurationUnit.YEAR];
	isDisabledForm = false;
	prescriptionEntityType = EntityType.PRESCRIPTION;
	isPatients = false;
	isRefilling: boolean;
	isClDataLoaded = true;
	isSignatureFlagOn: boolean;

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public gandalfFormBuilder: GandalfFormBuilder,
		public userLocationsService: UserLocationsService,
		public contactLensTrialService: ContactLensTrialService,
		public inventoryService: InventoryService,
		public contactLensService: ContactLensService,
		public dynamicScreenService: DynamicScreenService,
		public referenceDataService: ReferenceDataService,
		public providerService: ProviderService,
		public modalManagerService: ModalManagerService,
		public securityManagerService: SecurityManagerService,
		public urlService: UrlService,
		public summaryPodService: SummaryPodService,
		public featureService: FeatureService,
		private eventService: EventService,
	) {
		super();
	}

	ngOnInit() {
		this.isSignatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.EMPLOYEES.EMPLOYEE_PREFERENCES);
		this.parseModalConfigData(this.modalConfig.data);
		this.createForm(ContactLensPrescriptionType.SOFT);
		this.locationId = this.userLocationsService.getCurrentUserLocation().id;
		this.subscribeToClDataLoad();
		this.loadData();
	}

	loadData() {
		combineLatest([
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.CL_ELECTRONIC_DELIVERY_METHODS.value),
			this.referenceDataService.getActiveMasterReferenceDataByCategory(ReferenceDataMasterCategory.MED_DISPENSE_UNIT),
			this.providerService.findActiveExternalProvidersForDropdown(),
		]).subscribe(([deliveryMethods, units, providers]) => {
			this.deliveryMethodOptions = deliveryMethods;
			this.dispensingUnits = units;
			this.externalProviders = providers;
		});
	}

	/* istanbul ignore next */
	subscribeToClDataLoad() {
		this.contactLensService.clDataLoadedSubject
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(isDataLoaded => this.isClDataLoaded = isDataLoaded);
	}

	parseModalConfigData(data: any) {
		this.isUpdating = !!data.existingClrx;
		this.encounterId = data.encounterId;
		this.patientId = data.patientId;
		this.clrx = data.isRefilling ? data.clrxForRefill : data.existingClrx;
		this.isPatients = data.isPatients;
		this.isRefilling = data.isRefilling;
	}

	isCreating() {
		return !this.isUpdating && !this.isRefilling;
	}

	createForm(type: ContactLensType) {
		if (!this.isUpdating) {
			const request = new CreateContactLensPrescriptionRequest();
			request.overallLensType = type;
			if (this.isRefilling) {
				request.overallLensType = this.clrx.overallLensType;
			}
			this.request = request;
		} else {
			const request = new UpdateContactLensPrescriptionRequest();
			request.contactLensPrescriptionId = this.clrx.id;
			request.overallLensType = this.clrx.overallLensType;
			request.deliveryMethodReferenceId = this.clrx.deliveryMethodId;

			this.mapPrescriptionResponseToUpdateOrCreateRequest(request, this.clrx);

			this.request = request;
		}

		if (_isNil(this.request.startDate)) {
			this.request.startDate = new Date();
		}
		if (_isNil(this.request.expirationDate)) {
			this.request.expirationDate = this.getExpirationDate(new Date());
		}

		this.setUpdateAndCreateValues();
		this.request.authorizePrescription = false;
		this.request.useSignature = false;
		this.buildForm(this.request.overallLensType);
		this.disableEnableFormFields();
	}

	setUpdateAndCreateValues() {
		if (!this.isRefilling) {
			const updateOrCreateRequest = this.request as CreateContactLensPrescriptionRequest | UpdateContactLensPrescriptionRequest;
			updateOrCreateRequest.patientId = this.patientId;
			updateOrCreateRequest.encounterId = this.encounterId;
			updateOrCreateRequest.authorizationType = !updateOrCreateRequest.authorizationType
				? PrescriptionAuthorizationType.PROVIDER
				: updateOrCreateRequest.authorizationType;
			this.request = updateOrCreateRequest;
		}
	}

	disableEnableFormFields() {
		if (this.isAuthorizedOrStopped()) {
			if (this.isUpdating) {
				this.isDisabledForm = true;
				this.formGroup.disable();
			} else {
				if (this.isRefilling) {
					this.disableEnableRefillRxFields();
				}
			}
		}
	}

	disableEnableRefillRxFields() {
		const formControlsToDisable = [
			'os',
			'od',
			'authorizationType',
			'overallLensType',
		];

		const formControlsToEnable = [
			'od.overnightDurationAmount',
			'od.overnightDurationUnit',
			'od.replacementDurationAmount',
			'od.replacementDurationUnit',
			'os.overnightDurationAmount',
			'os.overnightDurationUnit',
			'os.replacementDurationAmount',
			'os.replacementDurationUnit',
		];

		formControlsToDisable.forEach((formControl) => {
			this.formGroup.get(formControl).disable();
		});

		formControlsToEnable.forEach((formControl) => {
			this.formGroup.get(formControl).enable();
		});
	}

	isAuthorizedOrStopped() {
		return !_isNil(this.clrx) && EnumUtil.equalsOneOf(this.clrx.status, PrescriptionStatus.AUTHORIZED, PrescriptionStatus.STOPPED);
	}

	displayReadOnlyForm() {
		return this.isAuthorizedOrStopped() && !this.isRefilling;
	}

	mapPrescriptionResponseToUpdateOrCreateRequest(
		request: CreateContactLensPrescriptionRequest | UpdateContactLensPrescriptionRequest,
		response: ContactLensPrescriptionDetailsResponse,
		copyId = true,
	) {
		request.authorizationType = response.authorizationType;
		request.authorizedByProviderId = response.authorizedBy ? response.authorizedBy.providerId : null;
		request.odDispenseUnitId = response.dispenseOdUnit ? response.dispenseOdUnit.id : null;
		request.osDispenseUnitId = response.dispenseOsUnit ? response.dispenseOsUnit.id : null;
		request.odDispenseAmount = response.dispenseOdAmount;
		request.osDispenseAmount = response.dispenseOsAmount;

		this.mapSharedResponseValuesToRequest(request, response, copyId);

		request.startDate = response.startDate;
		request.patientId = this.patientId;
		request.expirationDate = response.expirationDate;
	}

	mapPrescriptionResponseToRefillRequest(copyId = true) {
		this.request = new RefillContactLensPrescriptionRequest();
		this.request.contactLensPrescriptionId = this.clrx.id;
		this.request.encounterId = this.encounterId;
		this.request.overallLensType = this.clrx.overallLensType;

		this.mapSharedResponseValuesToRequest(this.request, this.clrx, copyId);

		this.request.startDate = new Date();
		this.request.expirationDate = this.getExpirationDate(new Date());
	}

	mapSharedResponseValuesToRequest(
		request: CreateContactLensPrescriptionRequest | UpdateContactLensPrescriptionRequest | RefillContactLensPrescriptionRequest,
		response: ContactLensPrescriptionDetailsResponse,
		copyId = true,
	) {
		request.description = response.description;

		request.od = ContactLensDetailsUtil.translateClEyeResponseToRequest(
			request.overallLensType,
			response.contactLensEyeOd,
			copyId,
		);
		request.os = ContactLensDetailsUtil.translateClEyeResponseToRequest(
			request.overallLensType,
			response.contactLensEyeOs,
			copyId,
		);

		request.dropsName = response.dropsName;
		request.lensCleanerName = response.lensCleanerName;
		request.enzymaticCleanerName = response.enzymaticCleanerName;
		request.solutionName = response.solutionName;
		request.modality = response.contactLensModality;
	}

	getExpirationDate(expirationDate) {
		let expirationMonths = this.securityManagerService.preferenceValue(HIT_PMS_HTML_PREFERENCES.PRESCRIPTION_CL_EXP_MONTHS.name)
			|| HIT_PMS_HTML_PREFERENCES.PRESCRIPTION_CL_EXP_MONTHS.defaultValue;
		if (!_isNil(expirationMonths)) {
			expirationMonths = parseInt(expirationMonths, 10);
			expirationDate = dayjs(expirationDate).add(expirationMonths, 'month').toDate();
		}
		return expirationDate;
	}

	buildForm(contactLensType: ContactLensPrescriptionType) {
		ContactLensDetailsUtil.setContactLensEyeRequests(this.request, contactLensType);
		let temporaryFormGroup;
		/* eslint-disable-next-line prefer-const */
		temporaryFormGroup = this.gandalfFormBuilder.group(this.request, {
			validators: [
				assertTrue(
					() => this.hasValidationMessage(temporaryFormGroup),
					[],
					'oneProductSelected',
					'Lens is required',
				),
			],
		});

		ContactLensDetailsUtil.addTransientFieldsToForm(temporaryFormGroup);

		if (this.isRefilling) {
			this.buildRefillFormGroup(temporaryFormGroup);
		} else {
			this.formGroup = temporaryFormGroup;
		}
	}

	hasValidationMessage(formGroup: UntypedFormGroup) {
		return !formGroup
			|| !formGroup.get('od')
			|| !formGroup.get('os')
			|| !_isNil(formGroup.get('os.locationProductId').value)
			|| !_isNil(formGroup.get('od.locationProductId').value);
	}

	buildRefillFormGroup(temporaryFormGroup: UntypedFormGroup) {
		this.mapPrescriptionResponseToRefillRequest();
		const refillFormGroup = this.gandalfFormBuilder.group(this.request);
		FormUtilsService.copyControls(temporaryFormGroup, refillFormGroup);
		this.formGroup = refillFormGroup;
	}

	closeModal(refresh = false) {
		this.dynamicModalRef.close(this.modal, refresh);
	}

	onAuthTypeChanged() {
		if (!this.isExternalAuth()) {
			this.formGroup.get('authorizedByProviderId').setValue(null);
		}
	}

	submit(event, isAuthorizing: boolean, isSigning: boolean) {
		this.componentForm.onSubmit(event);
		this.formGroup.get('authorizePrescription').setValue(isAuthorizing);
		this.formGroup.get('useSignature').setValue(isSigning);

		if (this.formGroup.invalid) {
			return;
		}

		const request = this.formGroup.getRawValue();
		const odProductId = this.getOdProductId();
		const osProductId = this.getOsProductId();

		this.contactLensTrialService.checkProductsActiveAndCallOnConfirm(odProductId, osProductId, () => this.saveClrx(request));
	}

	private getOsProductId() {
		return this.formGroup.get('os.locationProductId').value;
	}

	private getOdProductId() {
		return this.formGroup.get('od.locationProductId').value;
	}

	saveClrx(
		request: CreateContactLensPrescriptionRequest | UpdateContactLensPrescriptionRequest | RefillContactLensPrescriptionRequest,
	) {
		this.addClassAnnotation(request.od, request.overallLensType);
		this.addClassAnnotation(request.os, request.overallLensType);
		let serviceCall;

		if (this.isUpdating) {
			serviceCall = this.contactLensTrialService.updateContactLensPrescription(request as UpdateContactLensPrescriptionRequest);
		} else if (this.isRefilling) {
			serviceCall = this.contactLensTrialService.refillContactLensPrescription(request as RefillContactLensPrescriptionRequest);
		} else {
			serviceCall = this.contactLensTrialService.createContactLensPrescription(request as CreateContactLensPrescriptionRequest);
		}

		serviceCall.subscribe(() => {
			this.prescriptionUpdated();
			this.closeModal(true);
		});
	}

	copyDispensedODToOS() {
		this.formGroup.get('osDispenseAmount').setValue(this.formGroup.value.odDispenseAmount);
		this.formGroup.get('osDispenseUnitId').setValue(this.formGroup.value.odDispenseUnitId);
	}

	isExternalAuth() {
		if (!this.isRefilling) {
			return EnumUtil.equals(this.formGroup.getRawValue().authorizationType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER);
		}
	}

	copyScheduleODToOS() {
		this.formGroup.get('os.overnightDurationAmount').setValue(this.formGroup.value.od.overnightDurationAmount);
		this.formGroup.get('os.overnightDurationUnit').setValue(this.formGroup.value.od.overnightDurationUnit);
		this.formGroup.get('os.replacementDurationAmount').setValue(this.formGroup.value.od.replacementDurationAmount);
		this.formGroup.get('os.replacementDurationUnit').setValue(this.formGroup.value.od.replacementDurationUnit);
	}

	openSolutionSearch() {
		const productCategory = this.getSolutionProductCategory();
		const field = 'solutionName';

		this.openProductSelectionModal(productCategory, field);
	}

	private openProductSelectionModal(productCategory, field: string) {
		this.modalManagerService.open(
			ProductSelectionModalComponent,
			{data: {locationId: this.locationId, productCategory, onlyProductSelection: true}},
		)
			.onClose.subscribe((result: ClProductSelectionResult) => {
				if (result) {
					this.formGroup.get(field).setValue(result.productName);
				}
			},
			);
	}

	openLensCleanerSearch() {
		const productCategory = this.getSolutionProductCategory();
		const field = 'lensCleanerName';

		this.openProductSelectionModal(productCategory, field);
	}

	openDropsSearch() {
		const productCategory = this.inventoryService.getMasterProductCategory(MasterProductCategory.EYE_DROPS);
		const field = 'dropsName';

		this.openProductSelectionModal(productCategory, field);
	}

	openEnzCleanerSearch() {
		const productCategory = this.getSolutionProductCategory();
		const field = 'enzymaticCleanerName';

		this.openProductSelectionModal(productCategory, field);
	}

	addClassAnnotation(request, contactLensType: ContactLensType) {
		if (EnumUtil.equals(contactLensType, ContactLensPrescriptionType.SOFT)) {
			request['@class'] = 'ContactLensEyeSoftRequest';
		} else if (EnumUtil.equals(contactLensType, ContactLensPrescriptionType.RGP)) {
			request['@class'] = 'ContactLensEyeRgpRequest';
		} else if (EnumUtil.equals(contactLensType, ContactLensPrescriptionType.HYBRID)) {
			request['@class'] = 'ContactLensEyeHybridRequest';
		}
		return request;
	}

	private getSolutionProductCategory() {
		return this.inventoryService.getMasterProductCategory(MasterProductCategory.CONTACT_LENS_SOLUTION);
	}

	isCurrentUserAProvider() {
		return this.securityManagerService.userIsProvider();
	}

	canSign() {
		return this.canAuthorize()
			&& !this.alwaysSignWhenAuthorizing();
	}

	alwaysSignWhenAuthorizing(): boolean {
		const providerAlwaysUseSignatureImage = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE.value,
			PreferenceDefaults.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE.value,
		);
		const providerContactLensUseSignatureImage = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.PROVIDER_CONTACT_LENS_USE_SIGNATURE_IMAGE.value,
			PreferenceDefaults.PROVIDER_CONTACT_LENS_USE_SIGNATURE_IMAGE.value,
		);

		return this.isSignatureFlagOn ? providerContactLensUseSignatureImage : providerAlwaysUseSignatureImage;
	}

	canAuthorize() {
		return this.isCurrentUserAProvider()
			&& !this.isExternalAuth()
			&& !this.isDisabledForm;
	}

	canPrint() {
		return this.isAuthorized()
			&& !this.isExternalAuth();
	}

	print() {
		this.urlService.openTabWithPost(URL_PRINT_ENDPOINTS.CONTACT_LENS_PRESCRIPTION_PRINT, {
			rxid: this.clrx.id,
			locationid: this.userLocationsService.getCurrentUserLocation().id,
		});
	}

	isAuthorized() {
		return !_isNil(this.clrx) && EnumUtil.equals(this.clrx.status, PrescriptionStatus.AUTHORIZED);
	}

	prescriptionUpdated() {
		this.eventService.publishUpdatePatientContactLensPrescriptions(this.patientId);
		this.summaryPodService.updateRxSummaryPod(this.patientId);
	}
}
