// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { PaymentResponse } from './payment-response';

/** See com.rev360.legacy.api.controller.accounting.PaymentGroupResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentGroupResponse extends GandalfModelBase {

	@GandalfProperty()
	applyFull: boolean;

	@GandalfProperty()
	comment: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	creditCardType: constants.CreditCardType;

	@GandalfProperty()
	payerEntityId: number;

	@GandalfProperty()
	payerId: number;

	@GandalfProperty()
	payerName: string;

	@GandalfProperty()
	payerPersonInsuranceId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfProperty({ propertyType: 'Money' })
	paymentAmount: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	paymentDate: Date;

	@GandalfProperty()
	paymentGroupId: number;

	@GandalfProperty()
	paymentLocationId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	paymentMethodType: constants.PaymentMethodType;

	@GandalfArray(PaymentResponse)
	payments: PaymentResponse[];

	@GandalfProperty()
	referenceNumber: string;

	@GandalfProperty()
	sourcePracticeLocationId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	sourceType: constants.PaymentGroupSourceType;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.PaymentGroupStatus;

	@GandalfProperty()
	version: number;

}
