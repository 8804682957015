<div class="margin-bottom-sm overflow-hidden">
	<button ejs-button
			class="e-success"
			type="button"
			iconCss="fa fa-plus "
			*ngIf="canAddFolder"
			(click)="addFolderToSelectedFolder()"
			[disabled]="!selectedFolder"
			[attr.data-test-id]="'documentsImageAddFolderButton'">
		Add Folder
	</button>
	<button ejs-button cssClass="margin-left-sm pull-right" *ngIf="expanded" iconCss="fa fa-compress" [attr.data-test-id]="'documentsImageCollapseFoldersButton'" revTooltip tooltipContent="Collapse" (click)="collapseAll()">
	</button>
	<button ejs-button cssClass="margin-left-sm pull-right" *ngIf="!expanded" iconCss="fa fa-expand" [attr.data-test-id]="'documentsImageExpandFoldersButton'" revTooltip tooltipContent="Expand" (click)="expandAll()">
	</button>
</div>
<div class="panel panel-default" id="PatientDirectoryTreeViewComponent">
	<ejs-treeview #treeViewComponent *ngIf="!hideTree"
		(nodeSelected)="nodeSelected($event.nodeData)"
		[fields]="fieldSettingsModel">
	</ejs-treeview>
</div>
<ejs-contextmenu #menu (beforeOpen)="disableContextMenuItems(menu)" target='#PatientDirectoryTreeViewComponent' [items]= 'menuItems'
				 (select)="menuItemSelected($event)"
				 [attr.data-test-id]="'documentsImageFolderContextMenu'"></ejs-contextmenu>
