import { TemplateRef } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions, ProcessCellForExportParams, SuppressKeyboardEventParams, ValueGetterParams } from 'ag-grid-community';
import Big from 'big.js';
import { GandalfConstant } from 'gandalf';
import { ConditionalHyperlinkCellRendererComponent } from '../../components/conditional-hyperlink-cell-renderer/conditional-hyperlink-cell-renderer.component';
import { TemplateCellRendererComponent } from '../../components/template-cell-renderer/template-cell-renderer.component';
import { GridConfig } from '../../interfaces/grid';
import { _assign, _cloneDeep, _get, _isEmpty, _isNil, _reduce, _sum } from '../lodash/lodash';

export const GRID_STABILIZE_DEBOUNCE = 300;
export const GRID_MULTILINE_ROW_HEIGHT = 53;

export enum AggregateFunction {
	SUM,
}

export interface AggregateColumn {
	field: string;
	function: AggregateFunction;
	filter?: (...params) => string;
}

export const defaultColDef: ColDef = {
	sortable: true,
	suppressMenu: true,
};
const CURRENCY_PRECISION = 2;

export const SUPPRESS_EXPORT_OVERRIDE = 'suppressExport';
export const EXPORT_ONLY_OVERRIDE = 'exportOnly';
export const STRING_COMPARISON_FILTER = 'agTextColumnFilter';
export const NUMBER_COMPARISON_FILTER = 'agNumberColumnFilter';

export interface RevColDef<TData> extends Partial<ColDef<TData>> {
	[SUPPRESS_EXPORT_OVERRIDE]?: boolean;
	[EXPORT_ONLY_OVERRIDE]?: boolean;
	printWidth?: number;
}

export class GridUtil {

	static buildGridOptions<TData>(overrides?: Partial<GridOptions<TData>>): GridOptions<TData> {
		const gridOptions = _cloneDeep(DEFAULT_GRID_OPTIONS);
		return _assign(gridOptions, overrides);
	}

	static buildColumn<TData>(headerName: string, field: string, overrides?: RevColDef<TData>): RevColDef<TData> {
		const column = _cloneDeep(defaultColDef);
		column.headerName = headerName;
		column.field = field;
		column.filter = STRING_COMPARISON_FILTER;

		return this.applyOverrides(column, overrides);
	}

	static buildTemplateColumn<TData>(headerName: string, field: string, templateRef: TemplateRef<any>, overrides?: RevColDef<TData>): RevColDef<TData> {
		const column = _cloneDeep(defaultColDef);
		column.headerName = headerName;
		column.field = field;
		column.cellRenderer = TemplateCellRendererComponent;
		column.cellRendererParams = {
			ngTemplate: templateRef,
		};

		return this.applyOverrides(column, overrides);
	}

	static applyOverrides<TData>(column, overrides: RevColDef<TData>): RevColDef<TData> {
		column = _assign(column, overrides);

		// Hide the columns that will only be used in export
		if (column[EXPORT_ONLY_OVERRIDE]) {
			column.hide = true;
		}

		return column;
	}

	static buildColumnWithEllipses<TData>(headerName: string, field: string, overrides?: RevColDef<TData>): RevColDef<TData> {
		const column = _cloneDeep(defaultColDef);
		column.headerName = headerName;
		column.field = field;
		column.tooltipField = field;
		column.flex = 1;
		column.suppressMovable = true;
		column.filter = STRING_COMPARISON_FILTER;

		return this.applyOverrides(column, overrides);
	}

	static buildButtonColumn<TData>(field: string, templateRef: TemplateRef<any>, overrides?: RevColDef<TData>): RevColDef<TData> {
		const buttonColumn = _cloneDeep(defaultColDef);
		buttonColumn.field = field;
		buttonColumn.sortable = false;
		buttonColumn.suppressMovable = true;
		buttonColumn.resizable = false;
		buttonColumn.cellRenderer = TemplateCellRendererComponent;
		buttonColumn.cellRendererParams = {
			ngTemplate: templateRef,
		};

		return this.applyOverrides(buttonColumn, overrides);
	}

	static buildHyperlinkColumn<TData>(
		headerName: string,
		field: string,
		navigationCall: (rowData: TData) => void,
		assertion?: (rowData: TData) => boolean,
		overrides?: RevColDef<TData>,
	): RevColDef<TData> {
		const column = _cloneDeep(defaultColDef);
		column.headerName = headerName;
		column.field = field;
		column.suppressMovable = true;
		column.cellRenderer = ConditionalHyperlinkCellRendererComponent;
		column.cellRendererParams = {
			// a null assertion will break in agGrid when exporting / calling getColumnDefs()
			assertion: _isNil(assertion) ? this.defaultAssertion : assertion,
			navigationCall,
		};
		return this.applyOverrides(column, overrides);
	}

	private static defaultAssertion = () => true;

	static buildNumericColumn<TData>(headerName: string, field: string, overrides?: RevColDef<TData>): RevColDef<TData> {
		const numericColumn = _cloneDeep(defaultColDef);
		numericColumn.headerName = headerName;
		numericColumn.field = field;
		numericColumn.type = 'numericColumn';
		numericColumn.filter = NUMBER_COMPARISON_FILTER;

		return this.applyOverrides(numericColumn, overrides);
	}

	static buildEnumColumn<TData>(headerName: string, field: string, overrides?: RevColDef<TData>): RevColDef<TData> {
		const enumColumn = _cloneDeep(defaultColDef);
		enumColumn.headerName = headerName;
		enumColumn.field = field;
		enumColumn.filter = STRING_COMPARISON_FILTER;
		enumColumn.valueGetter = (params: ValueGetterParams) => params.data[field]?.label || '';

		return this.applyOverrides(enumColumn, overrides);
	}

	static isGridFilterReady(agGrid: AgGridAngular): boolean {
		return !_isEmpty(agGrid?.api?.getColumnDefs());
	}

	/**
	 * Applies filter changes for a column by either adding or clearing the filter, based on the outcome of the `applyIf` method
	 * @param agGrid the grid being filtered
	 * @param applyIf a method which returns `true` if the filter should be added or `false` if it should be cleared
	 * @param columnName the column to be filtered
	 * @param filterValue the value used for the filter
	 * @param filterType the type of filter to be applied, defaults to `equals`
	 * @param applyImmediate if `true` the filter will be applied immediately by refreshing the grid
	 */
	static applyFilter(
		agGrid: AgGridAngular,
		applyIf: () => boolean,
		columnName: string,
		filterValue: any,
		filterType: string = 'equals',
		applyImmediate = true,
	) {
		if (!GridUtil.isGridFilterReady(agGrid)) {
			return;
		}

		agGrid?.api?.setFilterModel({
			...agGrid.api.getFilterModel(), ...{
				[columnName]: applyIf() ? {
					type: filterType,
					filter: filterValue,
				} : {},
			},
		});

		if (applyImmediate) {
			agGrid?.api?.onFilterChanged();
		}
	}

	/**
	 * Applies filter changes for an Enum column by either adding or clearing the filter, based on the outcome of the `applyIf` method
	 * @param agGrid the grid being filtered
	 * @param applyIf a method which returns `true` if the filter should be added or `false` if it should be cleared
	 * @param columnName the Enum column to be filtered
	 * @param filterValue the Enum value used for the filter
	 * @param filterType the type of filter to be applied, defaults to `equals`
	 * @param applyImmediate if `true` the filter will be applied immediately by refreshing the grid
	 */
	static applyEnumFilter(
		agGrid: AgGridAngular,
		applyIf: () => boolean,
		columnName: string,
		filterValue: GandalfConstant<any>,
		filterType: string = 'equals',
		applyImmediate = true,
	) {
		GridUtil.applyFilter(agGrid, applyIf, columnName, filterValue?.label, filterType, applyImmediate);
	}

	/**
	 * Applies filter changes for a text column by either adding or clearing the filter, based on the outcome of the `applyIf` method
	 * @param agGrid the grid being filtered
	 * @param applyIf a method which returns `true` if the filter should be added or `false` if it should be cleared
	 * @param columnName the Enum column to be filtered
	 * @param text the text value used for the filter
	 * @param applyImmediate if `true` the filter will be applied immediately by refreshing the grid
	 */
	static applyTextContainsFilter(
		agGrid: AgGridAngular,
		applyIf: () => boolean,
		columnName: string,
		text: string,
		applyImmediate = true,
	) {
		GridUtil.applyFilter(agGrid, applyIf, columnName, text, 'contains', applyImmediate);
	}

	static clearFilters(agGrid: AgGridAngular, field?: string, applyImmediate = true) {
		if (field) {
			agGrid?.api?.destroyFilter(field);
		} else {
			agGrid?.api?.setFilterModel(null);
		}
		if (applyImmediate) {
			agGrid?.api?.onFilterChanged();
		}
	}

	static buildCheckboxSelectionColumn<TData>(overrides?: RevColDef<TData>) {
		const checkboxSelectionColumn = _cloneDeep(defaultColDef);
		checkboxSelectionColumn.headerCheckboxSelection = true;
		checkboxSelectionColumn.headerCheckboxSelectionFilteredOnly = true;
		checkboxSelectionColumn.checkboxSelection = true;
		checkboxSelectionColumn.sortable = false;
		checkboxSelectionColumn.suppressMovable = true;
		checkboxSelectionColumn.lockPosition = true;
		checkboxSelectionColumn.width = 37;
		checkboxSelectionColumn.resizable = false;

		return this.applyOverrides(checkboxSelectionColumn, overrides);
	}

	/**
	 * This will compare string numbers for to be used in the grid [sortComparator] option on e-columns
	 * This will work with Strings such as $1.09, +21.01, -21.0
	 */
	static gridNumberStringsComparator = (item1: string, item2: string) => {
		const num1 = Number(item1 ? item1.replace('$', '') : 0);
		const num2 = Number(item2 ? item2.replace('$', '') : 0);
		if (num1 < num2) {
			return -1;
		}
		if (num1 > num2) {
			return 1;
		}
		return 0;
	};

	static getGridOptions(grid: GridComponent) {
		return _isNil(grid) ? null : grid.getPersistData();
	}

	static parseGridOptions(options: string) {
		const gridConfig: GridConfig = {};
		if (options) {
			const jsonObject = JSON.parse(options);
			gridConfig.pageSettings = jsonObject.pageSettings;
			gridConfig.sortSettings = jsonObject.sortSettings;
		}

		return gridConfig;
	}

	/**
	 * Currently only supports, sort column, pageSize and current page
	 */
	static restoreGridOptions(grid: GridComponent, options: string) {
		if (!_isNil(options) && !_isNil(grid)) {
			const jsonObject = JSON.parse(options);
			const updatObj = {pageSettings: jsonObject.pageSettings, sortSettings: jsonObject.sortSettings};
			grid.setProperties(updatObj);
		}
	}

	static gridStringSearchDefaultContains(grid: GridComponent) {
		Object.assign((grid.filterModule as any).filterOperators, {startsWith: 'contains'});
	}

	/**
	 * This will set focus on the element with the id provided. This should probably be called from databind.
	 * The element name should be something like fieldname_filterBarcell
	 */
	static setFocus(elementId, tries = 0) {
		setTimeout(() => {
			const element = document.getElementById(elementId);
			if (element) {
				element.focus();
			} else if (tries < 20) { // if this doesn't find it in 1 second, give up
				this.setFocus(tries + 1);
			}
		}, 50);
	}

	/**
	 * This should be used with a syncfusion grid components click event output to get the row data from the click event
	 */
	static getRowDataFromClick(event, gridComponent: GridComponent) {
		const classList = _get(event, ['target', 'classList']);
		if (!_isNil(classList) && classList.contains('e-rowcell')) {
			const parentElement = _get(event, ['target', 'parentElement']);
			const dataId = parentElement ? parentElement.getAttribute('data-uid') : null;
			return dataId ? gridComponent.getRowObjectFromUID(dataId).data : null;
		}
		return null;
	}

	static setColumnVisiblity(grid: GridComponent, fieldKey: string, visibility: boolean) {
		visibility ? grid.showColumns(fieldKey, 'field') : grid.hideColumns(fieldKey, 'field');
		grid.refreshColumns();
	}

	static getGridSelections(grid: GridComponent) {
		const primaryKeys = grid.getPrimaryKeyFieldNames();
		const primaryKey = primaryKeys.length > 0 ? primaryKeys[0] : null;
		if (!primaryKey) {
			console.error('A primary key field must be designated for grid selection persistence to be applied');
			return;
		}
		return Object.keys(grid['selectionModule']['selectedRowState']);
	}

	static restoreGridSelections(grid: GridComponent, selections: string[]) {
		const primaryKeys = grid.getPrimaryKeyFieldNames();
		const primaryKeyField = primaryKeys.length > 0 ? primaryKeys[0] : null;
		selections.forEach(primaryKeyValue => {
			grid.selectionModule['selectedRowState'][primaryKeyValue] = true;
			(grid.dataSource as any[]).forEach(data => {
				if (data[primaryKeyField].toString() === primaryKeyValue.toString()) {
					grid.selectionModule['persistSelectedData'].push(data);
				}
			});
		});
	}

	static buildAgGridAggregateRow(columns: AggregateColumn[], data: any[]) {
		const aggregateObject = {
			isAggregateRow: true,
		};

		const aggregateData = [];

		columns.forEach((column) => {
			const fieldValues = data.map(row => row[column.field]);

			let total;

			switch (column.function) {
				case AggregateFunction.SUM: {
					total = this.sum(fieldValues);
				}
			}

			aggregateObject[column.field] = column.filter ? column.filter(total) : total;
		});

		aggregateData.push(aggregateObject);
	}

	static setAgGridAggregateRow(grid: AgGridAngular, rows: any[]) {
		if (!_isNil(grid)) {
			grid.api.setPinnedBottomRowData(rows);
		}
	}

	static setDynamicColumns(grid: AgGridAngular, columnDefinitions: ColDef[]) {
		if (_isNil(grid?.api)) {
			return;
		}

		grid.api.setColumnDefs([]);
		grid.api.setColumnDefs(columnDefinitions);
	}

	static showAgGridSpinner(grid: AgGridAngular) {
		grid?.api?.showLoadingOverlay();
	}

	static showAgGridNoRowOverlay(grid: AgGridAngular) {
		if (grid.rowData?.length === 0) {
			grid?.api?.showNoRowsOverlay();
		}
	}

	static hideAgGridSpinner(grid: AgGridAngular) {
		grid?.api?.hideOverlay();
	}

	static clearSelection(grid: AgGridAngular) {
		const selectedNodes = grid?.api?.getSelectedNodes();
		if (_isNil(selectedNodes)) {
			return;
		}

		selectedNodes.forEach((node) => {
			node.setSelected(false, true);
		});
	}

	private static sum(values: any[]) {
		return _sum(values);
	}

	/**
	 * Sanitizes the data in a grid cell for exporting
	 */
	static sanitizeCellDataForExport(value: any) {
		if (typeof value === 'string' || value instanceof String) {
			return value.replace(/^[=@\\|"]+/g, '');
		}
		return value;
	}

	static exportAGGridToCsv(grid: AgGridAngular) {
		// Grab all column keys, including those that are export only, excluding those that are should be suppressed for export
		const columnKeys = grid.api.getColumnDefs()
			.filter((col) => !col[SUPPRESS_EXPORT_OVERRIDE])
			.map(col => col['colId']);
		grid.api.exportDataAsCsv({processCellCallback: GridUtil.processExportData, columnKeys});
	}

	static processExportData = (params: ProcessCellForExportParams) => {
		if (!_isNil(params.column.getUserProvidedColDef().valueFormatter)) {
			return GridUtil.sanitizeCellDataForExport((params.column.getUserProvidedColDef().valueFormatter as any)(params));
		}
		return GridUtil.sanitizeCellDataForExport(params.value);
	};

	static getItemsSelectedText(selectedItems: any[]) {
		return `${selectedItems ? selectedItems.length : 0} ${selectedItems?.length !== 1 ? 'Items' : 'Item'} Selected`;
	}

	/**
	 * Sums specified currency items in provided list of currency items.
	 */
	static sumCurrencyItems(list: any[], accessor: string): Big {
		return _reduce(list, (sum, item) => {
			const existingSum = this.roundCurrency(sum);
			let value = _get(item, accessor);
			// if the value is undefined, treat it as 0 so the summation can continue
			value = _isNil(value) ? 0 : value;
			if (typeof value !== 'number') {
				throw new Error(`Identified item was not a number`);
			}
			const newItem = this.roundCurrency(value);
			return existingSum.plus(newItem);
		}, Big(0));
	}

	/**
	 * Rounds currency value to two decimal places using half-up rounding
	 * i.e. 1.235 will round to 1.24 and 1.232 will round to 1.23
	 */
	static roundCurrency(value: Big | number): Big {
		return _isNil(value) ? Big(0) : Big(value).round(CURRENCY_PRECISION, Big.roundHalfUp);
	}

	static suppressKeyboardEvent(params: SuppressKeyboardEventParams) {
		// tab or enter will trigger focus on the next column
		return params.event.code !== 'Tab' && params.event.key !== 'Enter';
	}

}

const DEFAULT_GRID_OPTIONS: GridOptions = {
	alignedGrids: [],
	enterNavigatesVertically: true,
	enterNavigatesVerticallyAfterEdit: true,
	stopEditingWhenCellsLoseFocus: true,
	suppressPaginationPanel: true,
	singleClickEdit: true,
	tooltipShowDelay: 300,
	paginationPageSize: 10,
	pagination: true,
	domLayout: 'autoHeight',
	popupParent: document.querySelector('body'),
	overlayNoRowsTemplate: `<span>No records to display</span>`,
	defaultColDef: {
		resizable: true,
		sortable: true,
		suppressMovable: true,
	},
};
