// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { LegacyPatientNameResponse } from './legacy-patient-name-response';

/** See com.rev360.legacy.api.controller.accounting.InvoiceSummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceSummaryResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentTotal: number;

	@GandalfProperty()
	approval: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfProperty()
	id: number;

	@GandalfProperty()
	invoiceAge: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDate: Date;

	@GandalfProperty()
	patientName: LegacyPatientNameResponse;

	@GandalfProperty()
	payerName: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	payerType: constants.PayerType;

	@GandalfProperty()
	processingPaymentGroupId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statementDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	status: constants.InvoiceStatus;

	@GandalfProperty({ propertyType: 'Money' })
	total: number;

}
