import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GandalfFormArray, GandalfModelBase } from 'gandalf';
import { TypedGandalfFormArray } from './typed-gandalf-form-array';
import { GandalfFormControl } from './gandalf-form-control';

export type GandalfFormModel<T> = {
	[K in keyof T as Exclude<K, keyof GandalfModelBase>]: T[K] extends (infer U)[]
		? GandalfFormModel<U>[]
		: T[K] extends GandalfModelBase
			? GandalfFormModel<T[K]>
			: T[K]
};

export type ReadonlyGandalfFormModel<T> = { readonly [K in keyof GandalfFormModel<T>]?: GandalfFormModel<T>[K] };

export type PatchGandalfFormModel<T> = { [K in keyof GandalfFormModel<T>]?: GandalfFormModel<T>[K] };

/**
 * This type represents a set of key-value pairs for Gandalf controls. Each property ("key") on the
 * generic type that extends {@link GandalfModelBase} will become a mapped-type. The mapped-type's
 * return value will be one of {@link GandalfFormControl}, {@link GandalfFormGroup}, or {@link TypedGandalfFormArray},
 * determined by the original model's property type.
 */
type GandalfControlProperty<T extends GandalfModelBase> = {
	[K in keyof T as Exclude<K, keyof GandalfModelBase>]: T[K] extends (infer U)[]
		? (
			U extends GandalfModelBase
				? TypedGandalfFormArray<U>
				: GandalfFormControl<T[K]>
			)
		: T[K] extends GandalfModelBase
			? GandalfFormGroup<T[K]>
			: GandalfFormControl<T[K]>
};

/**
 * A type-safe wrapper for working with {@link FormGroup}, {@link FormControl}, and {@link GandalfFormArray}.
 * This class exposes all properties ("keys") of a {@link GandalfModelBase} as type-safe form control fields.
 */
export class GandalfFormGroup<T extends GandalfModelBase> {

	/**
	 * The controls belonging to the type extending {@link GandalfModelBase}
	 */
	readonly controls: GandalfControlProperty<T> = {} as any;

	constructor(readonly formGroup: UntypedFormGroup) {
		for (const [key, control] of Object.entries(formGroup.controls)) {
			if (control instanceof UntypedFormGroup) {
				this.controls[key] = new GandalfFormGroup(control) as any;
			} else if (control instanceof UntypedFormControl) {
				this.controls[key] = new GandalfFormControl(control) as any;
			} else if (control instanceof GandalfFormArray) {
				this.controls[key] = new TypedGandalfFormArray(control) as any;
			}
		}
	}

	/**
	 * Gets the current value of the form. Disabled controls will not contain a value.
	 */
	get value(): ReadonlyGandalfFormModel<T> {
		return this.formGroup.value;
	}

	/**
	 * Returns true if the form validation is invalid
	 */
	get invalid(): boolean {
		return this.formGroup.invalid;
	}

	/**
	 * Returns true if the form validation is valid
	 */
	get valid(): boolean {
		return this.formGroup.valid;
	}

	/**
	 * Gets the aggregate value of the form, including any disabled controls.
	 */
	getRawValue(): GandalfFormModel<T> {
		return this.formGroup.getRawValue();
	}

	/**
	 * Sets the entire value of the form. Every control on the form must be supplied a value.
	 */
	setValue(value: GandalfFormModel<T>) {
		this.formGroup.setValue(value);
	}

	/**
	 * Patches a subset of values to the form.
	 */
	patchValue(value: PatchGandalfFormModel<T>) {
		this.formGroup.patchValue(value);
	}
}
