<ejs-dialog
	#modal
	(close)="closeModal()"
	cssClass="modal-md"
	[attr.data-test-id]="'sendFeedbackModal'">
	<ng-template #header>
		<div class="dlg-template">Send Feedback</div>
	</ng-template>
	<ng-template #content>
		<form [formGroup]="formGroup" #templateForm="ngForm" (ngSubmit)="submit()">
			<div class="alert alert-warning">
				In an effort to ensure patient privacy, please do not include any protected health information (PHI) in your message.
			</div>
			<gandalf-lib-validation-messages
					*ngIf="formGroup.invalid && templateForm.submitted"
					[form]="formGroup"
					[requestObj]="request">
			</gandalf-lib-validation-messages>
			<div class="form-group" [attr.data-test-id]="'feedbackFormGroup'">
				<label class="required">Feedback</label>
				<textarea formControlName="feedback" class="e-input" rows="10"></textarea>
			</div>
			<div class="form-group" [attr.data-test-id]="'emailAddressFormGroup'">
				<label class="required">Your Email Address</label>
				<input formControlName="emailAddress" class="e-input"/>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<rev-button buttonIcon="times" buttonLabel="Cancel" (buttonClick)="closeModal()" dataTestId="sendFeedbackCancelButton"></rev-button>
		<rev-button buttonType="submit" buttonStyle="primary" buttonLabel="Send" (buttonClick)="submitForm($event)" dataTestId="sendFeedbackSendButton"></rev-button>
	</ng-template>
</ejs-dialog>
