<form [formGroup]="formGroup">
	<div class="row">
		<div class="col-sm-12 col-lg-6">
			<h4 class="section-title bg-default mrgn-btm">Company Information</h4>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label required">Name</label>
				<div class="col-sm-8 col-md-9">
					<input type="text" class="e-input" revFocusInput
						   formControlName="name"/>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Abbreviation</label>
				<div class="col-sm-8 col-md-9">
					<input type="text" class="e-input"
						   formControlName="abbr"/>
				</div>
			</div>
			<pms-address-form [parentFormGroup]="formGroup" [isCreating]="isCreating"
							  [autoselectStateProvince]="false" class="form-layout-col-sm-4-8-md-3-9"></pms-address-form>

			<h4 class="section-title bg-default mrgn-btm">Contact Information</h4>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Phone</label>
				<div class="col-sm-8 col-md-9">
					<pms-phone-number-input formControlName="mainPhone"></pms-phone-number-input>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Fax</label>
				<div class="col-sm-8 col-md-9">
					<pms-phone-number-input formControlName="fax"></pms-phone-number-input>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Web Site</label>
				<div class="col-sm-8 col-md-9">
					<input type="text" class="e-input"
						   formControlName="webSite"/>
				</div>
			</div>

			<h4 class="section-title bg-default mrgn-btm">Policy Information</h4>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Default Priority</label>
				<div class="col-sm-8 col-md-9">
					<ejs-dropdownlist
						*ngIf="insurancePriorities"
						[dataSource]="insurancePriorities"
						formControlName="defaultPriorityReferenceId"
						[showClearButton]="true"
						placeholder="Select Default Priority">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Default Type</label>
				<div class="col-sm-8 col-md-9">
					<ejs-dropdownlist
						*ngIf="insuranceTypes"
						[dataSource]="insuranceTypes"
						formControlName="defaultInsuranceTypeReferenceId"
						[showClearButton]="true"
						placeholder="Select Default Type">
					</ejs-dropdownlist>
				</div>
			</div>

			<h4 class="section-title bg-default mrgn-btm">Notes</h4>
			<div class="form-group">
				<div class="col-sm-12">
					<ejs-textbox type="text" class="e-input h-rows-5"
								 [multiline]="true" formControlName="notes">
					</ejs-textbox>
				</div>
			</div>
		</div>

		<div class="col-sm-12 col-lg-6">
			<h4 class="section-title bg-default mrgn-btm">Claim Information</h4>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label required">Classification</label>
				<div class="col-sm-8 col-md-9">
					<ejs-dropdownlist
						*ngIf="insuranceClassifications"
						[dataSource]="insuranceClassifications"
						formControlName="insuranceClassMasterReferenceId"
						placeholder="Select Classification">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label required">Claim Submission</label>
				<div class="col-sm-8 col-md-9">
					<pms-constant-dropdown
						[constantList]="claimSubmissionMechanisms"
						formControlName="claimSubmissionMechanism"
						placeholder="Select Claim Submission">
					</pms-constant-dropdown>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Payer ID</label>
				<div class="col-sm-8 col-md-9">
					<input type="text" class="e-input"
						   formControlName="payerId"/>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Group Number</label>
				<div class="col-sm-8 col-md-9">
					<input type="text" class="e-input"
						   formControlName="groupNumber"/>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Qualifier</label>
				<div class="col-sm-8 col-md-9">
					<pms-constant-dropdown
						[constantList]="groupNumberQualifiers"
						formControlName="groupNumberQualifier"
						placeholder="Select Group Number Qualifier"
						showClearButton="true">
					</pms-constant-dropdown>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Claim Filing Code</label>
				<div class="col-sm-8 col-md-9">
					<pms-constant-dropdown
						[constantList]="claimFilingCodes"
						formControlName="claimFilingCode"
						placeholder="Select Claim Filing Code"
						showClearButton="true">
					</pms-constant-dropdown>
				</div>
			</div>
			<div class="form-group">
				<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
					<ejs-checkbox formControlName="participatingPractice" label="Participating Practice"></ejs-checkbox>
				</div>
			</div>
			<div class="form-group">
				<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
					<ejs-checkbox formControlName="npiOnly" label="NPI Only on claims"></ejs-checkbox>
				</div>
			</div>
			<div class="form-group">
				<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
					<ejs-checkbox formControlName="renderingIsReferringProvider"
								  label="Use rendering provider as referring provider"></ejs-checkbox>
				</div>
			</div>
			<div class="form-group">
				<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
					<ejs-checkbox formControlName="removeMatchingServiceNpi"
								  label="Remove matching service facility NPI"></ejs-checkbox>
				</div>
			</div>

			<h4 class="section-title bg-default mrgn-btm">Accounting Information</h4>
			<div class="form-group">
				<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
					<ejs-checkbox formControlName="chargedTax" label="Apply tax on insurance invoices"></ejs-checkbox>
				</div>
			</div>
			<div class="form-group">
				<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
					<ejs-checkbox formControlName="transferTax"
								  label="Allow tax to be transferred to patient"></ejs-checkbox>
				</div>
			</div>
			<div class="form-group">
				<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
					<ejs-checkbox formControlName="allowItemDiscounts"
								  label="Allow item discounts on invoices"></ejs-checkbox>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 col-md-3 control-label">Default Payment Method</label>
				<div class="col-sm-8 col-md-9">
					<pms-constant-dropdown
						[constantList]="paymentMethodTypes"
						formControlName="defaultPaymentMethod"
						placeholder="Select Default Payment Method">
					</pms-constant-dropdown>
				</div>
			</div>

			<ng-container *ngIf="!isCanada">
				<h4 class="section-title bg-default mrgn-btm">Miscellaneous</h4>
				<div class="form-group">
					<div class="col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3">
						<ejs-checkbox formControlName="includePqrsAlert" label="Include Quality Alert"></ejs-checkbox>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</form>
