import { Router, RouterEvent } from '@angular/router';
import { RouterEventConstructor } from '@core/router-utils/router-event-constructor';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export class RouterEventObserver {
	private readonly originatingLocation: string;

	constructor(
		private routerInstance: Router,
	) {
		this.originatingLocation = this.routerInstance.url;
	}

	/**
	 * Observe Router Events
	 * Configures and returns an Observable representing a stream of Router Events.
	 * @param eventClass RouterEvent to listen for.
	 * @param restrictContext Restrict the context of router events to sub-routes of the originating location.
	 */
	observeRouterEvents<T extends RouterEvent>(
		eventClass: RouterEventConstructor<T>,
		restrictContext: boolean,
	): Observable<T> {
		return this.routerInstance.events.pipe(
			filter((event: T) => event instanceof eventClass),
			filter((event: T) => restrictContext ? this.validateContext(event) : true),
		);
	}

	/**
	 * Validate Context
	 * Validate the context of the current event's route.
	 * @param event Event to validate.
	 */
	private validateContext<T extends RouterEvent>(event: T): boolean {
		return event.url.includes(this.originatingLocation);
	}
}
