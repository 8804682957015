import { Inject, Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { GANDALF_ARRAY_TYPE_METADATA_KEY, GANDALF_ARRAY_TYPE_QUALIFIER_METADATA_KEY } from './decorators/gandalf-array.decorator';
import { GANDALF_CONSTANT_METADATA_KEY } from './decorators/gandalf-constant.decorator';
import { GANDALF_TYPE_METADATA_KEY } from './decorators/gandalf-property.decorator';
import { ModelTypes, TypeTranslationService } from './model/type-translation.service';
import { getMetadataPropertyValue } from './metadata/gandalf-metadata-util';

@Injectable({
	providedIn: 'root',
})
export class ModelManipulationService {

	constructor(private typeTranslation: TypeTranslationService, @Inject('MODEL_STORE') private modelStore: any) {
	}

	/**
	 * Takes in a string name for a class, or an un-instantiated class, and a set of data.
	 * It creates a new model object from the data and the class.
	 */
	parseGandalfModel(modelClass: any, data) {

		// If there is no data returned, we don't need to manipulate it. Just pass the result along.
		if (data === null || data === undefined) {
			return data;
		}

		const concreteInstance = this.buildConcreteInstance(modelClass, data);

		const dataKeys = Object.keys(data);
		dataKeys.forEach((key) => {
			const dataValue = data[key];
			let propertyValue;

			if (dataValue === null) {
				concreteInstance[key] = null;
				return;
			}
			// Get metadata on concreteInstance for each key
			const keyConstantClass = getMetadataPropertyValue(concreteInstance, GANDALF_CONSTANT_METADATA_KEY, key);
			// Check to see if the key is a model OR an array of models;
			if (keyConstantClass) {
				const arrayType = getMetadataPropertyValue(concreteInstance, GANDALF_ARRAY_TYPE_METADATA_KEY, key);
				if (arrayType) {
					// Array of constants
					propertyValue = [];
					dataValue.forEach((item) => {
						const temp = this.parseGandalfConstant(arrayType, item);
						propertyValue.push(temp);
					});
					concreteInstance[key] = propertyValue;
					return;
				} else {
					// Assign some data to the model
					concreteInstance[key] = this.parseGandalfConstant(keyConstantClass, dataValue);
				}
			} else {
				// Getting the qualified type of the property
				let propertyType2 = getMetadataPropertyValue(concreteInstance, GANDALF_TYPE_METADATA_KEY, key);
				if (!propertyType2) {
					// No qualified type - get the basic type of the property and assigning the data in that format.
					propertyType2 = Reflect.getMetadata('design:type', concreteInstance, key);
				}

				if (propertyType2) {
					const typeTranslation = this.typeTranslation.propertyFactory[ propertyType2.name ];
					if (typeTranslation && typeTranslation.length > 0) {
						// We have a javascript type we know about.
						if (typeTranslation[ 0 ] === ModelTypes.literalType) {
							// We have a literal type that we can translate easily.
							propertyValue = this.typeTranslation.propertyFactory[ propertyType2.name ][ 1 ](
								dataValue);
						} else {
							// We have an array or object of something, and have to loop/recurse to fill it in.
							let arrayType = getMetadataPropertyValue(concreteInstance, GANDALF_ARRAY_TYPE_QUALIFIER_METADATA_KEY, key);
							if (!arrayType) {
								arrayType = getMetadataPropertyValue(concreteInstance, GANDALF_ARRAY_TYPE_METADATA_KEY, key);
							}

							const arrayTypeCreator = this.typeTranslation.propertyFactory[ arrayType.name ];
							propertyValue = [];

							// Check to see if it's a literal type, and if so, just create those.
							if (arrayTypeCreator && arrayTypeCreator[0] === ModelTypes.literalType) {
								dataValue.forEach((item) => {
									const arrayItem = arrayTypeCreator[ 1 ](item);
									propertyValue.push(arrayItem);
								});
							} else {
								dataValue.forEach((item) => {
									const temp = this.parseGandalfModel(arrayType, item);
									propertyValue.push(temp);
								});
							}
						}

					} else {
						// We have a Gandalf type
						propertyValue = this.parseGandalfModel(propertyType2, dataValue);
					}

					// Assign the data to the object now that it is the proper JavaScript type.
					concreteInstance[ key ] = propertyValue;

				} else {
					// We don't have a type from metadata, this may be due to a nested object or primitive type. So just return the original value.
					concreteInstance[ key ] = data[ key ];
				}
			}
		});

		return concreteInstance; // Returned populated model;
	}

	buildConcreteInstance(modelClass: any, data: any) {
		let concreteInstance;
		if (data['@class']) {
			// Determine if a polymorphic subtype is specified
			concreteInstance = this.classFactory(data['@class']);
		} else if (typeof modelClass === 'string') {
			if (this.isGandalfModel(modelClass)) {
				// this is the string name of a gandalf class
				concreteInstance = this.classFactory(modelClass);
			} else {
				throw new Error(`Type [${modelClass}] is not a gandalf class!`);
			}
		} else {
			const type = modelClass.name;
			if (!!this.typeTranslation.propertyFactory[type] && this.typeTranslation.propertyFactory[type].length > 1) {
				// Javascript built in object type
				concreteInstance = this.typeTranslation.propertyFactory[type][1](data);
			} else {
				// the type is instantiatable class
				concreteInstance = new modelClass();
			}
		}

		return concreteInstance;
	}

	/**
	 * When we have an array of Gandalf objects being returned from the server, this gets called first.
	 */
	parseGandalfList(responseType, data) {
		return data.map(item => this.parseGandalfModel(responseType, item));
	}

	private parseGandalfConstant(keyConstantClass, dataValue) {
		for (const gandalfConstant of keyConstantClass.VALUES.values) {
			if (gandalfConstant.value === dataValue) {
				return gandalfConstant;
			}
		}

		return null;
	}

	/**
	 * Clean a Gandalf model in preparation for sending to the server.  This will scan the entire data object
	 * and replace any LocalDate or LocalDateTime types with a formatted string value in the format expected
	 * by Jackson on the Java side.
	 */
	cleanGandalfModel(modelClass, data) {
		if (!data) {
			return data;
		}
		let concreteInstance;
		if (typeof modelClass === 'string') {
			concreteInstance = this.classFactory(modelClass);
		} else {
			concreteInstance = new modelClass();
		}

		const responseModel = {};

		const dataKeys = Object.keys(data);
		dataKeys.forEach((key) => {
			const dataValue = data[key];
			// Default to just copying the existing value to the new model - this will be the case the majority of the time
			responseModel[key] = dataValue;

			// Getting the qualified type of the property
			const propertyTypeQualifier = getMetadataPropertyValue(concreteInstance, GANDALF_TYPE_METADATA_KEY, key);
			if (propertyTypeQualifier) {
				if (propertyTypeQualifier.name === 'LocalDate') {
					responseModel[key] = this.formatDateToString(dataValue, 'YYYY-MM-DD');
				} else if (propertyTypeQualifier.name === 'LocalDateTime') {
					responseModel[key] = this.formatDateToString(dataValue, 'YYYY-MM-DD[T]HH:mm:ss.SSS');
				}
				// No special handling required for (propertyTypeQualifier.name === 'Money')
			} else {
				const keyConstantClass = getMetadataPropertyValue(concreteInstance, GANDALF_CONSTANT_METADATA_KEY, key);
				const arrayTypeQualifier = getMetadataPropertyValue(concreteInstance, GANDALF_ARRAY_TYPE_QUALIFIER_METADATA_KEY, key);
				const arrayType = getMetadataPropertyValue(concreteInstance, GANDALF_ARRAY_TYPE_METADATA_KEY, key);

				if (arrayType) {
					// Arrays of primitives are fine, but we need to clean arrays of Gandalf models or qualified types
					if ((this.hasGandalfModelProperty(arrayType) || arrayTypeQualifier) && Array.isArray(dataValue)) {
						responseModel[key] = this.cleanGandalfList(arrayType, dataValue, arrayTypeQualifier);
					}
				} else {
					// Check to see if the key is a constant - if it is, leave it alone - otherwise we have more work to do
					if (!keyConstantClass) {
						const propertyType = Reflect.getMetadata('design:type', concreteInstance, key);
						if (this.hasGandalfModelProperty(propertyType)) {
							responseModel[key] = this.cleanGandalfModel(propertyType, dataValue);
						}
					}
				}
			}
		});

		return responseModel;
	}

	/**
	 * When we have an array of Gandalf objects being sent to the server, this gets called first.
	 */
	cleanGandalfList(requestType, data, requestTypeQualifier?) {
		if (requestTypeQualifier && requestTypeQualifier.name === 'LocalDate') {
			return data.map(localDate => this.formatDateToString(localDate, 'YYYY-MM-DD'));
		} else if (requestTypeQualifier && requestTypeQualifier.name === 'LocalDateTime') {
			return data.map(localDateTime => this.formatDateToString(localDateTime, 'YYYY-MM-DD[T]HH:mm:ss.SSS'));
		}
		return data.map(item => this.cleanGandalfModel(requestType, item));
	}

	private formatDateToString(date: Date, format: string) {
		if (!date) {
			return null;
		}
		return dayjs(date).format(format);
	}

	/**
	 * Takes a class name and returns the class from the model store to be instantiated.
	 */
	/* istanbul ignore next */
	classFactory(className: string) {
		if (!this.isGandalfModel(className)) {
			throw new Error(`Class type of '${className}' is not in the model store`);
		}
		return new this.modelStore[className]();
	}

	/**
	 * Takes a concrete instance class and checks to see if the $isGandalfModel property is set
	 */
	hasGandalfModelProperty(propertyType) {
		return propertyType?.prototype?.$isGandalfModel;
	}

	/**
	 * This will look up the model store to see if the provided class name is a Gandalf Class
	 * This cannot be called with a design:type metadata, due to minification with the prod flag
	 */
	isGandalfModel(className: string) {
		return this.modelStore[className] !== undefined && this.modelStore[className] !== null;
	}

}
