import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogUtil, EnumUtil, GridUtil, ModalManagerService, SortingService, TooltipService } from 'morgana';
import { _filter, _isEmpty, _isNaN, _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { InvoiceItemDiagnosisResponse } from '@gandalf/model/invoice-item-diagnosis-response';
import { InvoiceItemListResponse } from '@gandalf/model/invoice-item-list-response';
import { InvoiceItemAdjustmentResponse } from '@gandalf/model/invoice-item-adjustment-response';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { RemoveInvoiceItemRequest } from '@gandalf/model/remove-invoice-item-request';
import { UpdateInvoiceItemQuantityRequest } from '@gandalf/model/update-invoice-item-quantity-request';
import {
	BetaSystemCode,
	CategoryEntityType,
	InvoiceItemAdjustmentStatus,
	InvoiceItemAdjustmentType,
	InvoiceItemStatus,
	InvoiceStatus,
	RelatedEntityType
} from '@gandalf/constants';
import { AssignCategoriesModalComponent } from '@shared/component/assign-categories-modal/assign-categories-modal.component';
import { BaseComponent } from '@shared/component/base.component';
import { DATE_FORMATS, TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog, TooltipEventArgs } from '@syncfusion/ej2-angular-popups';
import { QueryCellInfoEventArgs } from '@syncfusion/ej2-grids';
import dayjs from 'dayjs';
import { throwError } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { BACKGROUND_COLOR_CONSTANTS } from '@shared/constants/background-colors.constants';
import { AccountingViewService } from '../../../core/accounting/accounting-view-util/accounting-view.service';
import { AccountingService, FormattedInvoiceDetailsItem } from '../../../core/accounting/accounting.service';
import { InvoiceService } from '../../../core/accounting/invoice-service/invoice.service';
import { AddInvoiceItemModalComponent } from '../../add-invoice-item-modal/add-invoice-item-modal.component';
import { InvoiceItemDetailsModalComponent } from '../../invoice-item-details-modal/invoice-item-details-modal.component';
import { SplitPaymentModalComponent } from '../../split-payment-modal/split-payment-modal.component';

@Component({
	selector: 'pms-invoice-details-items-table',
	templateUrl: './invoice-details-items-table.component.html',
	styles: [],
	providers: [ModalManagerService],
})
export class InvoiceDetailsItemsTableComponent extends BaseComponent implements OnInit, OnDestroy {

	@Input()
	invoiceId: number;

	@Input()
	isReadOnly: boolean;

	@Input()
	isPaymentProcessing: boolean;

	@Input()
	isTabView: boolean;

	@ViewChild('taxTooltip')
	taxTooltip: ElementRef;

	@ViewChild('adjustmentTooltip')
	adjustmentTooltip: ElementRef;

	@ViewChild('inactiveICD10MasterDxTooltip')
	inactiveICD10MasterDxTooltip: ElementRef;

	@ViewChild('grid')
	grid: GridComponent;

	tooltipData: InvoiceItemListResponse;
	tooltipDiscount: boolean;
	invoiceItemsForTable: FormattedInvoiceDetailsItem[];
	filteredInvoiceItems: FormattedInvoiceDetailsItem[];
	allInvoiceItems: FormattedInvoiceDetailsItem[];
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY;
	invoice: InvoiceResponse;
	showAllInvoices = false;

	constructor(
		public modalManagerService: ModalManagerService,
		private tooltipService: TooltipService,
		private accountingService: AccountingService,
		private invoiceService: InvoiceService,
		private accountingViewService: AccountingViewService,
		private datePipe: DatePipe,
		private securityManagerService: SecurityManagerService,
	) {
		super();
	}

	ngOnInit() {
		this.listenForInvoiceUpdates();
		this.getShowAllState();
		this.applyShowAllInvoices();
	}

	getShowAllState() {
		// State managed show all flag when in a tab
		if (this.isTabView) {
			this.invoiceService.getInvoiceDetailsShowAllState(this.invoiceId).pipe(take(1)).subscribe(showAll => this.showAllInvoices = showAll);
		}
	}

	listenForInvoiceUpdates() {
		this.invoiceService.getInvoiceDetailsInvoiceState(this.invoiceId).pipe(
			takeUntil(this.unsubscribe),
		).subscribe(invoice => {
			if (!_isNil(invoice)) {
				this.invoice = invoice;
				this.getInvoiceDetailsItems(this.invoiceId);

				this.setActionColumnVisibility();
			}
		});
	}

	getInvoiceDetailsItems(invoiceId: number) {
		this.accountingService.findInvoiceDetailsItemsByInvoiceId(invoiceId).subscribe((items) => {
			this.allInvoiceItems = items;
			this.filteredInvoiceItems = _filter(items, item => !this.isItemRemoved(item));
			this.invoiceItemsForTable = this.filteredInvoiceItems;
			this.grid.refresh();
		});
	}

	handleQueryCellInfo(event: QueryCellInfoEventArgs) {
		this.setTooltip(event);
		this.addStrikeThrough(event);
		this.addDanger(event);
		this.addDiagnosisDanger(event);
	}

	addStrikeThrough(event: QueryCellInfoEventArgs) {
		if (this.isItemRemoved(event.data as InvoiceItemListResponse)) {
			if (
				event.column.field === 'code' ||
				event.column.field === 'formattedModifiers' ||
				event.column.field === 'formattedDiagnoses' ||
				event.column.field === 'description' ||
				event.column.field === 'unitPrice' ||
				event.column.field === 'discountTotal' ||
				event.column.field === 'taxAmount' ||
				event.column.field === 'extendedPrice' ||
				event.column.field === 'adjustmentTotal' ||
				event.column.field === 'amountPaid' ||
				event.column.field === 'balance'
			) {
				event.cell.classList.add('text-strike-through');
			}
		}
	}

	addDiagnosisDanger(event: QueryCellInfoEventArgs) {
		const data = event.data as InvoiceItemListResponse;
		const inactiveICD10MasterDxs = data.additionalDiagnoses.filter(diagnosis => this.showDisableWarning(diagnosis));
		if (event.column.field === 'formattedDiagnoses' && inactiveICD10MasterDxs.length > 0) {
			event.cell.classList.add(BACKGROUND_COLOR_CONSTANTS.DANGER);
			const formattedStrings = inactiveICD10MasterDxs.map(diagnosis => this.formatICD10MasterDxDisabledToolTipStr(diagnosis));
			const cellElement = (event.cell as unknown) as HTMLElement;
			this.tooltipService.buildTooltip(this.inactiveICD10MasterDxTooltip.nativeElement, cellElement, _renderEvent => {
				setTimeout(() => {
					cellElement['_tippy'].setContent(`Code(s) no longer billable (on/after):<br>${formattedStrings.join(', ')}`);
				});
			});
		}
	}

	showDisableWarning(diagnosis: InvoiceItemDiagnosisResponse): boolean {
		if (_isNil(diagnosis.masterDxICD10DisableDate) || _isNil(this.invoice.serviceDate)) {
			return false;
		}

		const serviceDate = new Date(this.invoice.serviceDate);
		const disableDate = new Date(diagnosis.masterDxICD10DisableDate);
		return dayjs(serviceDate).isAfter(disableDate, 'day');
	}

	formatICD10MasterDxDisabledToolTipStr(diagnosis: InvoiceItemDiagnosisResponse) {
		let formattedCode = diagnosis.code;
		if (diagnosis.isPrimary) {
			formattedCode += '*';
		}
		const formattedDate = this.datePipe.transform(diagnosis.masterDxICD10DisableDate, DATE_FORMATS.MM_DD_YYYY);
		return `${formattedCode} (${formattedDate})`;
	}

	addDanger(event: QueryCellInfoEventArgs) {
		if (event.column.field === 'balance' && event.data['balance'] < 0) {
			event.cell.classList.add('text-danger');
		}
	}

	setTooltip(event: QueryCellInfoEventArgs) {
		let content: ElementRef;
		let isDiscount = false;
		switch (event.column.field) {
			case 'adjustmentTotal':
				if (_isEmpty(this.filterAdjustments(event.data['itemAdjustments'], false))) {
					return;
				}
				content = this.adjustmentTooltip;
				isDiscount = false;
				break;
			case 'discountTotal':
				if (_isEmpty(this.filterAdjustments(event.data['itemAdjustments'], true))) {
					return;
				}
				content = this.adjustmentTooltip;
				isDiscount = true;
				break;
			case 'taxAmount':
				if (_isEmpty(event.data['itemTaxes'])) {
					return;
				}
				content = this.taxTooltip;
				break;
			default:
				return;
		}
		const cellElement = (event.cell as unknown) as HTMLElement;
		this.tooltipService.buildTooltip(content.nativeElement, cellElement, (renderEvent) => {
			this.beforeRender(renderEvent, isDiscount);
			setTimeout(() => {
				cellElement['_tippy'].setContent((content.nativeElement as HTMLElement).innerHTML);
			});
		});
	}

	/**
	 * filters itemAdjustments to return ACTIVE itemAdjustments that are or are not type DISCOUNT, based on the IsDiscount param
	 * used for calculating adjustmentTotal and discountTotal in table tool tips
	 */
	filterAdjustments(itemAdjustments: InvoiceItemAdjustmentResponse[], isDiscount: boolean): InvoiceItemAdjustmentResponse[] {
		return _filter(itemAdjustments, adjustment =>
			isDiscount === EnumUtil.equals(adjustment.type, InvoiceItemAdjustmentType.DISCOUNT)
			&& EnumUtil.equals(adjustment.status, InvoiceItemAdjustmentStatus.ACTIVE));
	}

	beforeRender(event: TooltipEventArgs, isDiscount: boolean) {
		const row = this.grid.getRowInfo(event.target);
		if (row) {
			this.tooltipDiscount = isDiscount;
			this.tooltipData = row.rowData as InvoiceItemListResponse;
		}
	}

	sortBy<T>(collection: Array<T>, properties: any | Array<any>): Array<T> {
		return SortingService.sortBy(collection, properties);
	}

	openInvoiceItemDetailsModal(event) {
		this.modalManagerService.open(InvoiceItemDetailsModalComponent, {
			data: {
				invoiceId: this.invoiceId,
				itemId: event.data.invoiceItemId,
				isInvoiceFrozen: false,
				openedFromInvoiceDetailsModal: true,
			},
		});
	}

	isItemRemoved(invoiceItem: InvoiceItemListResponse): boolean {
		return EnumUtil.equals(invoiceItem.status, InvoiceItemStatus.REMOVED);
	}

	toggleShowAllInvoiceItems(value: boolean) {
		this.showAllInvoices = value;
		this.applyShowAllInvoices();
	}

	canEditQuantity(item: InvoiceItemListResponse) {
		return this.accountingViewService.canEditQuantity(this.invoice, item) && !this.isPaymentProcessing;
	}

	updateQuantity(element: HTMLInputElement, item: InvoiceItemListResponse) {
		const value = parseInt(element.value, 10);

		if (_isNaN(value) || value <= 0) {
			element.value = item.quantity.toString(10);
			return;
		}

		const request = new UpdateInvoiceItemQuantityRequest();
		request.invoiceItemId = item.invoiceItemId;
		request.quantity = value;

		this.accountingService.updateInvoiceItemQuantity(request).subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoiceId);
		});
	}

	quantityTooltip(item: InvoiceItemListResponse) {
		if (item.split || item.wasSplit) {
			return `Quantity not editable for 'split' items`;
		}
		return '';
	}

	canAddItems() {
		return !this.isReadOnly && !this.isPaymentProcessing && this.accountingViewService.canAddItems(this.invoice);
	}

	addItem() {
		this.modalManagerService.open(AddInvoiceItemModalComponent, {
			data: {
				invoiceId: this.invoice.id,
				openedFromInvoiceDetailsModal: true,
			},
		});
	}

	canRemoveItem(item: InvoiceItemListResponse) {
		return !this.isReadOnly && !this.isPaymentProcessing && this.accountingViewService.canRemoveItem(this.invoice, item);
	}

	requestRemoveItem(item: InvoiceItemListResponse) {
		const dialog = DialogUtil.confirm({
			title: 'Remove Item',
			content: `Are you sure you want to remove this item?`,
			okButton: {
				click: () => this.removeItem(this.invoice.id, item.invoiceItemId, dialog),
			},
		});
	}

	removeItem(invoiceId: number, itemId: number, dialog: Dialog) {
		const request = new RemoveInvoiceItemRequest();
		request.invoiceId = invoiceId;
		request.invoiceItemId = itemId;
		this.accountingService.removeInvoiceItem(request).pipe(
			catchError((error: HttpErrorResponse) => {
				dialog.close();
				return throwError(() => error);
			}),
		).subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
			dialog.close();
		});
	}

	canSplitItem(item: InvoiceItemListResponse) {
		return !this.isReadOnly && !this.isPaymentProcessing && this.accountingViewService.canSplitItem(this.invoice, item);
	}

	splitItem(item: InvoiceItemListResponse) {
		const payload = {
			data: {
				invoiceId: this.invoice.id,
				itemId: item.invoiceItemId,
				patientId: this.invoice.patientId,
				encounterId: this.invoice.encounterId,
				orderId: this.invoice.orderId,
				payerEntityId: this.invoice.payerEntityId,
				invoiceItem: item,
			},
		};
		this.modalManagerService.open(SplitPaymentModalComponent, payload).onClose.subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
		});
	}

	checkRowSelection(event: any, grid: GridComponent) {
		// This checks if the click initiated within the actionsButton column and if it does, stops the rowSelected event from firing
		const field = grid.getRowInfo(event.target).column['field'];
		if (field === 'action' || field === 'secondaryAction') {
			event.cancel = true;
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		if (this.isTabView) {
			this.invoiceService.setInvoiceDetailsShowAllState(this.invoiceId, this.showAllInvoices);
		}
	}

	showActionColumn() {
		if (_isNil(this.invoice)) {
			return false;
		}

		return !(this.invoice.approved || EnumUtil.equalsOneOf(this.invoice.status, InvoiceStatus.ON_HOLD, InvoiceStatus.VOIDED));
	}

	setActionColumnVisibility() {
		if (!_isNil(this.grid)) {
			GridUtil.setColumnVisiblity(this.grid, 'action', this.showActionColumn());
		}
	}

	trackByDiagnosisId(diagnosis: InvoiceItemDiagnosisResponse) {
		return diagnosis.id;
	}

	openCategoriesModal(invoiceItemId: number) {
		this.modalManagerService.open(AssignCategoriesModalComponent, {
			data: {
				categoryType: CategoryEntityType.REPORTS_CATEGORY,
				relatedEntity: RelatedEntityType.INVOICE_ITEM,
				parentId: invoiceItemId,
			},
		});
	}

	canAssignCategories() {
		return this.securityManagerService.hasBeta(BetaSystemCode.ACCOUNTING_CATEGORIES) && !this.isPaymentProcessing;
	}

	private applyShowAllInvoices() {
		this.invoiceItemsForTable = this.showAllInvoices ? this.allInvoiceItems : this.filteredInvoiceItems;
	}
}
