<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-md"
		[attr.data-test-id]="'voidInvoiceModal'">
	<ng-template #header>
		<div class="dlg-template">Void Invoice</div>
	</ng-template>
	<ng-template #content>
		<p>Are you sure you want to void this invoice?</p>
		<form [formGroup]="componentForm"
			  (ngSubmit)="voidInvoice()"
			  #templateForm="ngForm">
			<div class="row">
				<div class="col-sm-12">
					<gandalf-lib-validation-messages
							*ngIf="componentForm.invalid && templateForm.submitted"
							[form]="componentForm"
							[requestObj]="voidInvoiceRequest"
							[attr.data-test-id]="'voidInvoiceModalValidationMessages'">
					</gandalf-lib-validation-messages>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'voidInvoiceModalComments'">
				<label class="required">Comments</label>
				<ejs-textbox class="h-rows-5" [multiline]="true" maxlength="255" formControlName="comments"></ejs-textbox>
			</div>
			<div [attr.data-test-id]="'voidInvoiceModalCommentCharacterCounter'">
				{{ componentForm.controls['comments'].value.length }} of 255 characters
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button type="button" (click)="closeModal()" [attr.data-test-id]="'voidInvoiceModalNoButton'">No</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'voidInvoiceModalYesButton'">Yes</button>
	</ng-template>
</ejs-dialog>
