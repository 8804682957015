// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.patient.LegacyPatientInsuranceAccountSummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class LegacyPatientInsuranceAccountSummaryResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	balance: number;

	@GandalfProperty({ propertyType: 'Money' })
	collectionsBalance: number;

	@GandalfProperty({ propertyType: 'Money' })
	credit: number;

	@GandalfProperty({ propertyType: 'Money' })
	familyBalance: number;

	@GandalfProperty({ propertyType: 'Money' })
	familyCollections: number;

	@GandalfProperty({ propertyType: 'Money' })
	familyCredit: number;

	@GandalfProperty({ propertyType: 'Money' })
	insuranceBalance: number;

}
