// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { DiscountItemRequest } from './discount-item-request';

/** See com.rev360.legacy.api.controller.accounting.DiscountItemsRequest */
/* istanbul ignore next */
@GandalfModel
export class DiscountItemsRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	description: string;

	@GandalfArray(DiscountItemRequest)
	discountItems: DiscountItemRequest[];

	@GandalfValidator({ notNull: { message: 'Discount Reason is required' } })
	@GandalfProperty({ isRequired: true })
	discountReferenceId: number;

	@GandalfValidator({ notNull: { message: 'Invoice ID is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

}
