<div class="panel panel-default">
	<div class="panel-heading">
		<h4 class="panel-title">RevClear</h4>
		<ul *ngIf="locations?.length" class="nav-tools">
			<li>
				<a class="e-control e-btn e-lib e-success" (click)="openModalWithRefresh()" revTooltip tooltipContent="Add">
					<i class="fa fa-plus"></i>
				</a>
			</li>
		</ul>
	</div>
	<div class="panel-body">
			<div class="row">
				<form #configurationForm="ngForm" [formGroup]="formGroup" (ngSubmit)="updateRevClearConfiguration()" *ngIf="formGroup">
					<gandalf-lib-validation-messages
							*ngIf="formGroup?.invalid && configurationForm.submitted"
							[form]="formGroup"
							[requestObj]="updateRevClearConfigurationRequest">
					</gandalf-lib-validation-messages>
					<div class="col-lg-4 margin-bottom-md">
						<div *ngIf="shouldShowDefaultPaymentPracticeLocation" class="form-horizontal">
							<h4 class="section-title bg-default mrgn-btm">ERA Auto-Posting Payment Location Consolidation</h4>
							<div class="form-group" [attr.data-test-id]="'defaultPaymentLocationFormGroup'">
								<label class="control-label col-sm-4 required">Default Payment Location</label>
								<div class="col-sm-8">
									<ejs-dropdownlist
											class="margin-bottom-sm"
											[dataSource]="activeLocations"
											placeholder="Select Location"
											[formControl]="formGroup.controls.defaultPaymentPracticeLocationId">
									</ejs-dropdownlist>
								</div>
							</div>
						</div>
						<div class="form-horizontal" *ngIf="shouldShowTasks">
							<h4 class="section-title bg-default mrgn-btm">ERA Auto-Posting Task Assignment</h4>
							<div class="form-group">
								<label class="control-label col-sm-4">Practice Site ID</label>
								<div class="col-sm-8">
									<p class="form-control-static">{{siteId}}</p>
								</div>
							</div>
							<div class="form-group" [attr.data-test-id]="'selectEmployeesFormGroup'">
								<label class="control-label col-sm-4">Select Employee(s)</label>
								<div class="col-sm-8">
									<ejs-dropdownlist
											class="margin-bottom-sm"
											[dataSource]="securityRoles"
											[showClearButton]="true"
											placeholder="Any Group"
											(change)="handleEmployeeSecurityRoleSelect($event)">
									</ejs-dropdownlist>
									<ejs-listbox
											height="350px"
											[formControl]="formGroup.controls.personIds"
											[dataSource]="employees"
											[selectionSettings]="{showCheckbox: true, showSelectAll: true}"
											[allowDragAndDrop]="true"
											[fields]="{text: 'label', value: 'value'}">
									</ejs-listbox>
								</div>
							</div>
						</div>
					</div>
				</form>
			<div class="col-lg-8">
				<h4 class="section-title bg-default mrgn-btm">Additional RevClear Credentials</h4>
				<div class="alert alert-warning">
					This should only be used if your Location has RevClear credentials
					which are not the same as your Practice level RevClear credentials
				</div>
				<rev-ag-grid-container [agGrid]="agGrid">
					<ng-container rev-table-action-menu-left>
						<div class="e-toolbar-item" [attr.data-test-id]="'revClearCredentialStatusSection'">
							<label>Status</label>
							<rev-status-select-button
									[(ngModel)]="statusFilter"
									[options]="statusFilterOptions"
									(onChange)="filterTable()"
									[attr.data-test-id]="'revClearCredentialStatusButton'">
							</rev-status-select-button>
						</div>
					</ng-container>
					<ng-container rev-container-grid>
						<ag-grid-angular
							#agGrid
							class="ag-theme-alpine"
							(gridReady)="onGridReady($event)"
							pmsAgAutoFitColumns
							[rowData]="revClearExternalCredentials"
							(rowDataChanged)="filterTable()"
							[gridOptions]="gridOptions"
							(rowClicked)="openModalWithRefresh($event.data)"
							[attr.data-test-id]="'revClearCredentialTable'">
						</ag-grid-angular>
					</ng-container>
				</rev-ag-grid-container>
			</div>
		</div>
	</div>
	<div class="panel-footer">
		<rev-button
				buttonStyle="primary"
				buttonLabel="Save"
				(buttonClick)="submitForm($event)"
				dataTestId="autopostingConfigurationRevClearSaveButton">
		</rev-button>
		<rev-button
				buttonType="submit"
				buttonIcon="times"
				buttonLabel="Cancel"
				(buttonClick)="getDataDebounced()"
				dataTestId="autopostingConfigurationRevClearCancelButton">
		</rev-button>
	</div>
</div>

<ng-template #enableDisableButtonColumn let-data>
	<rev-button
			*ngIf="!data.active"
			[revGridButton]="'activate'"
			(buttonClick)="updateStatus(data)"
			dataTestId="encounterRolesActivateButton">
	</rev-button>
	<rev-button
			*ngIf="data.active"
			[revGridButton]="'deactivate'"
			(buttonClick)="updateStatus(data)"
			dataTestId="encounterRolesDeactivateButton">
	</rev-button>
</ng-template>
