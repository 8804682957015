import { CurrencyPipe } from '@angular/common';
import { Component, Injector, OnInit, Self, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { UPDATE_PATIENT_INVOICES } from '@app-store/constants/event.constants';
import { StatefulComponent } from '@app-store/decorators/stateful-component.decorator';
import { StatefulProperty } from '@app-store/decorators/stateful-property.decorator';
import { StatefulForm } from '@app-store/mixins/stateful-form.mixin';
import { StatefulGrid } from '@app-store/mixins/stateful-grid.mixin';
import { StatefulFormService } from '@app-store/services/stateful-form.service';
import { StatefulGridService } from '@app-store/services/stateful-grid.service';
import { StatefulPropertyService } from '@app-store/services/stateful-property.service';
import { StatefulBaseComponent } from '@app-store/stateful-base.component';
import { StatefulEventUtil } from '@app-store/utils/stateful-event-util';
import { EnumUtil, EXPORT_TEXT, GridUtil, GridUtilService, ModalManagerService, PAGE_LENGTH, PAGE_LENGTH_LIST, TableToolItem } from 'morgana';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _find, _isNil } from '@core/lodash/lodash';
import { ProviderService } from '@core/provider/provider.service';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { PracticeLocation, SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { ToasterService } from '@core/toaster/toaster.service';
import { UrlService } from '@core/url-util/url.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { PrintStatementsRequest } from '@gandalf-black/model/print-statements-request';
import { SendStatementsRequest } from '@gandalf-black/model/send-statements-request';
import {
	InvoiceSearchAgeRange,
	InvoiceSearchAuthorization,
	InvoiceSearchOrigin,
	InvoiceSearchStatus,
	InvoiceStatus,
	PayerType,
	ReferenceDataMasterCategory,
	StatementFormat
} from '@gandalf/constants';
import { Store } from '@ngrx/store';
import { State } from '@patients-store/reducers';
import { DATE_FORMATS } from '@shared/constants/date-format.constants';
import { URL_ACCOUNTING_ENDPOINTS } from '@shared/constants/url.constants';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { AggregateRowModel, ExcelQueryCellInfoEventArgs, GridComponent } from '@syncfusion/ej2-angular-grids';
import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { ColDef, GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { GandalfFormBuilder } from 'gandalf';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, take, takeUntil } from 'rxjs/operators';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { AgGridAngular } from 'ag-grid-angular';
import { InvoiceDashboardResponse } from '@gandalf/model/invoice-dashboard-response';
import { InvoiceDashboardSearchRequest } from '@gandalf/model/invoice-dashboard-search-request';
import { AccountingService, TableFormattedInvoiceSummary } from '../../core/accounting/accounting.service';
import { InvoiceService } from '../../core/accounting/invoice-service/invoice.service';
import { PrintInvoiceStatementsModalComponent, StatementConfig } from '../print-invoice-statements-modal/print-invoice-statements-modal.component';

export enum InvoiceBatchActions {
	PRINT_INVOICES = 'Print Invoices',
	PRINT_STATEMENTS = 'Print Statements',
	PRINT_SUMMARIES = 'Print Summaries',
	SEND_STATEMENTS = 'Send Statements',
}

@Component({
	selector: 'pms-invoices-dashboard',
	templateUrl: './invoices-dashboard.component.html',
	styles: [],
	providers: [StatefulFormService, StatefulPropertyService, StatefulGridService],
})
// This MUST match the INVOICES_DASHBOARD_COMPONENT constant in InvoiceService
@StatefulComponent(
	{name: 'InvoicesDashboardComponent'},
)
export class InvoicesDashboardComponent extends StatefulGrid(StatefulForm(StatefulBaseComponent)) implements OnInit {
	static printInvoicesActions = {
		label: InvoiceBatchActions.PRINT_INVOICES,
		value: InvoiceBatchActions.PRINT_INVOICES,
	};

	static printStatementsActions = {
		label: InvoiceBatchActions.PRINT_STATEMENTS,
		value: InvoiceBatchActions.PRINT_STATEMENTS,
	};

	static printSummariesAction = {
		label: InvoiceBatchActions.PRINT_SUMMARIES,
		value: InvoiceBatchActions.PRINT_SUMMARIES,
	};

	static sendStatementsActions = {
		label: InvoiceBatchActions.SEND_STATEMENTS,
		value: InvoiceBatchActions.SEND_STATEMENTS,
	};

	@ViewChild('dropdownActions')
	batchActionDropdown: DropDownListComponent;

	@ViewChild('grid')
	syncfusionGrid: GridComponent;

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@ViewChild('invoiceApprovalColumn')
	invoiceApprovalColumn: TemplateRef<any>;

	@ViewChild('invoiceAgeColumn')
	invoiceAgeColumn: TemplateRef<any>;

	@ViewChild('patientNameColumn')
	patientNameColumn: TemplateRef<any>;

	@ViewChild('printColumn')
	printColumn: TemplateRef<any>;

	@StatefulProperty()
	invoices: TableFormattedInvoiceSummary[];

	@StatefulProperty()
	private lastValidSearchRequest: InvoiceDashboardSearchRequest;

	dateFormat = DATE_FORMATS.MM_DD_YYYY;
	locations: OptionItemResponse<PracticeLocation, number>[];
	providers = [];
	statuses = InvoiceSearchStatus.VALUES;
	approvalOptions = InvoiceSearchAuthorization.VALUES.values;
	originOptions = InvoiceSearchOrigin.VALUES;
	payerOptions = [PayerType.PATIENT, PayerType.INSURANCE, PayerType.ANONYMOUS, PayerType.COLLECTIONS];
	payerInsuranceTypes;
	invoiceAgeOptions = InvoiceSearchAgeRange.VALUES;
	componentForm: UntypedFormGroup;
	isSearching = false;
	invoiceSearchRequest: InvoiceDashboardSearchRequest;
	dateFormatter = {type: 'date', format: DATE_FORMATS.MM_DD_YYYY};
	aggregates: AggregateRowModel[];
	toolbar: TableToolItem[];
	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};

	batchActions = [];
	actionValue = null;
	userCanReceivePayment = false;
	userCanSendStatements = false;
	userCanViewInvoices = false;
	statementProcessorId: number;
	payerType = PayerType;
	gridCreated = new Subject<any>();
	gridDatabind = new Subject<any>();
	invoicesDashboardGridOptions = GridUtil.buildGridOptions({
		rowClass: 'row-link',
		suppressRowClickSelection: true,
	});
	private gridColumns: ColDef[];
	isAgGridFeatureFlagOn: boolean;

	constructor(
		private userLocationsService: UserLocationsService,
		public gandalfFormBuilder: GandalfFormBuilder,
		public providerService: ProviderService,
		public referenceDataService: ReferenceDataService,
		public accountingService: AccountingService,
		private currencyPipe: CurrencyPipe,
		public store: Store<State>,
		private urlService: UrlService,
		private securityManagerService: SecurityManagerService,
		public modalManagerService: ModalManagerService,
		private toasterService: ToasterService,
		private invoiceService: InvoiceService,
		private featureService: FeatureService,
		public injector: Injector,
		private gridUtilService: GridUtilService,
		@Self() public statefulGridService: StatefulGridService,
		@Self() public statefulFormService: StatefulFormService,
		@Self() public statefulPropertyService: StatefulPropertyService,
	) {
		super();
		this.userCanViewInvoices = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_VIEW_INVOICES);
		this.userCanReceivePayment = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_PAYMENT_RECEIVE);
		this.userCanSendStatements = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_STATEMENT_SEND);
	}

	async ngOnInit() {
		this.isAgGridFeatureFlagOn = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.INVOICE.INVOICE_DASHBOARD.AG_GRID);
		await super.ngOnInit();
		this.statefulComponentInit();
	}

	statefulComponentInit() {
		this.setupAggregates();
		this.setupToolBar();
		this.getData();
		this.buildForm().pipe(take(1)).subscribe(formGroup => {
			this.componentForm = formGroup;
		});
	}

	updateInvoiceList(invoices: TableFormattedInvoiceSummary[]) {
		this.invoices = invoices;
		if (this.isAgGridFeatureFlagOn && !_isNil(this.agGrid?.api)) {
			// if invoices list is undefined, set empty instead to prevent grid from saying 'loading'
			this.agGrid.api.setRowData(this.invoices || []);
		}
	}

	setupToolBar() {
		this.toolbar = [
			{
				text: EXPORT_TEXT,
				iconCss: 'fa fa-download',
				dataTestId: 'Export',
			},
		];
	}

	getData() {
		this.locations = this.userLocationsService.getUserActiveLocations();
		combineLatest([
			this.providerService.findPracticeProvidersForDropdown(),
			this.referenceDataService.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.INSURANCE_TYPE.value),
			this.accountingService.findPracticeStatementProcessorId(),
		])
			.subscribe(([providers, insuranceTypes, statementProcessorId]) => {
				this.handleDataResults(providers, insuranceTypes, statementProcessorId);
			});
	}

	handleDataResults(providers, insuranceTypes, statementProcessorId) {
		this.providers = providers;
		this.payerInsuranceTypes = insuranceTypes;
		this.statementProcessorId = statementProcessorId;
	}

	buildForm() {
		const formGroup = this.createFormGroup();
		return of(formGroup);
	}

	createFormGroup() {
		this.invoiceSearchRequest = new InvoiceDashboardSearchRequest();
		const formGroup = this.gandalfFormBuilder.group(this.invoiceSearchRequest);
		formGroup.get('invoiceAge').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
			this.handleCustomRange(value, formGroup);
		});
		formGroup.get('payerType').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
			this.handlePayerType(value, formGroup);
		});
		this.setInitialFormValues(formGroup);
		return formGroup;
	}

	setInitialFormValues(formGroup) {
		formGroup.get('payerType').setValue(null);
		formGroup.get('invoiceAge').setValue(null);
		formGroup.get('locationId').setValue(this.userLocationsService.getCurrentUserLocation().id);
		formGroup.get('status').setValue(InvoiceSearchStatus.ACTIVE);
		formGroup.get('origin').setValue(null);
		formGroup.get('authorized').setValue(InvoiceSearchAuthorization.ALL);
	}

	onGridReady(_event: GridReadyEvent) {
		this.buildGridColumns();
		this.agGrid.api.setColumnDefs(this.gridColumns);
		this.setupAggregates();
	}

	buildGridColumns() {
		this.gridColumns = [
			GridUtil.buildCheckboxSelectionColumn(),
			GridUtil.buildTemplateColumn('Approval', 'invoice.approval', this.invoiceApprovalColumn),
			GridUtil.buildColumn('Status', 'status', {}),
			GridUtil.buildTemplateColumn('Age', 'invoice.invoiceAge', this.invoiceAgeColumn),
			GridUtil.buildColumn('#', 'id', {}),
			GridUtil.buildColumn('Payer', 'invoice.payerName', {
				tooltipField: 'invoice.payerName',
				flex: 1,
			}),
			GridUtil.buildTemplateColumn('Patient', 'patientName', this.patientNameColumn, {
				flex: 2,
				tooltipField: 'patientName',
			}),
			this.gridUtilService.buildDateColumn('Invoice Date', 'invoice.invoiceDate', DATE_FORMATS.MM_DD_YYYY, {}),
			this.gridUtilService.buildDateColumn('Service Date', 'invoice.serviceDate', DATE_FORMATS.MM_DD_YYYY, {}),
			this.gridUtilService.buildDateColumn('Statement Date', 'invoice.statementDate', DATE_FORMATS.MM_DD_YYYY, {}),
			this.gridUtilService.buildCurrencyColumn('Amount', 'invoice.total', {}),
			this.gridUtilService.buildCurrencyColumn('Balance', 'invoice.balance', {
				width: 125,
			}),
			GridUtil.buildTemplateColumn('', 'print', this.printColumn, {
				width: 55,
				sortable: false,
			}),
		];
	}

	handleCustomRange(value, formGroup: UntypedFormGroup) {
		const invoiceAgeCustomStart = formGroup.get('invoiceAgeCustomStart');
		FormUtilsService.disabledWhen(invoiceAgeCustomStart, !EnumUtil.equals(InvoiceSearchAgeRange.CUSTOM, value));
		const invoiceAgeCustomEnd = formGroup.get('invoiceAgeCustomEnd');
		FormUtilsService.disabledWhen(invoiceAgeCustomEnd, !EnumUtil.equals(InvoiceSearchAgeRange.CUSTOM, value));
		if (invoiceAgeCustomStart.disabled) {
			invoiceAgeCustomStart.setValue(null);
		}
		if (invoiceAgeCustomEnd.disabled) {
			invoiceAgeCustomEnd.setValue(null);
		}
	}

	handlePayerType(value: number, formGroup: UntypedFormGroup) {
		const control = formGroup.get('payerInsuranceType');
		FormUtilsService.disabledWhen(control, value !== PayerType.INSURANCE.value);
		if (control.disabled) {
			control.setValue(null);
		}
	}

	submitForm() {
		if (this.componentForm.invalid) {
			return;
		}
		this.isSearching = true;
		this.accountingService.searchInvoices(this.componentForm.getRawValue()).subscribe(invoices => {
			this.lastValidSearchRequest = this.componentForm.getRawValue();
			this.handleSearchResults(invoices);
		});
	}

	handleSearchResults(invoices) {
		this.updateInvoiceList(invoices);
		this.clearTableSelection();
		this.isSearching = false;
	}

	clear(templateForm: FormGroupDirective) {
		templateForm.resetForm({});
		this.setInitialFormValues(this.componentForm);
		this.componentForm.get('locationId').setValue(null);
		this.componentForm.get('status').setValue(null);
		this.updateInvoiceList([]);
		this.clearTableSelection();
		this.lastValidSearchRequest = null;
	}

	clearTableSelection() {
		if (!this.isAgGridFeatureFlagOn) {
			this.syncfusionGrid.clearSelection();
		} else {
			GridUtil.clearSelection(this.agGrid);
		}
	}

	printInvoice(data: TableFormattedInvoiceSummary) {
		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.PRINT_INVOICE, {invoiceId: data.invoice.id});
	}

	isPrintable(data: TableFormattedInvoiceSummary) {
		return data.invoice.approval || this.isInsurancePayer(data.invoice);
	}

	isInsurancePayer(data: InvoiceDashboardResponse) {
		return EnumUtil.equals(data.payerType, PayerType.INSURANCE);
	}

	getInvoiceAgeClass(invoiceAge: number) {
		if (invoiceAge < 31) {
			return '';
		} else if (invoiceAge < 61) {
			return 'text-info';
		} else if (invoiceAge < 91) {
			return 'text-warning';
		}
		return 'text-danger';
	}

	setupAggregates() {
		if (this.isAgGridFeatureFlagOn) {
			if (this.invoices?.length > 0) {
				const balanceSum = Number(GridUtil.sumCurrencyItems(this.invoices, 'invoice.balance'));
				const aggregateRows = [{
					isAggregate: true,
					invoice: {
						balance: balanceSum,
					},
				}];
				GridUtil.setAgGridAggregateRow(this.agGrid, aggregateRows);
			} else {
				GridUtil.setAgGridAggregateRow(this.agGrid, []);
			}

		} else if (!this.isAgGridFeatureFlagOn) {
			this.aggregates = [{
				columns: [
					{
						type: 'Sum',
						field: 'invoice.balance',
						format: 'C2',
						footerTemplate: '${Sum}',
					},
				],
			}];
		}

	}

	exportFunction(cell: ExcelQueryCellInfoEventArgs) {
		if (cell.column.field === 'balance' || cell.column.field === 'total') {
			cell.value = this.currencyPipe.transform(cell.value as string);
		} else {
			cell.value = cell.value.toString();
		}
		return cell;
	}

	isGuestPayer(data: InvoiceDashboardResponse) {
		return EnumUtil.equals(data.payerType, PayerType.ANONYMOUS);
	}

	openInvoiceTab(invoice) {
		return this.invoiceService.openInvoiceTab(invoice.id);
	}

	printInvoices(ids) {
		this.urlService.openTabWithFormPost(URL_ACCOUNTING_ENDPOINTS.PRINT_INVOICE, {invoiceId: ids});
	}

	printSelectedInvoices() {
		const ids = this.getSelectedIdsAsString();
		this.printInvoices(ids);
		this.clearTableSelection();
		this.isSearching = false;
	}

	getSelectedIdsAsString() {
		return this.getSelectedIds().join(',');
	}

	getSelectedIds() {
		return this.getGridSelectedRecords().map(rec => rec.invoice.id);
	}

	getGridSelectedRecords() {
		let records = [];
		if (!this.isAgGridFeatureFlagOn) {
			records = this.syncfusionGrid.getSelectedRecords() as TableFormattedInvoiceSummary[];
		} else {
			records = !_isNil(this.agGrid?.api) ? this.agGrid.api.getSelectedRows() as TableFormattedInvoiceSummary[] : [];
		}
		return records;
	}

	printStatements() {
		const ids = this.getSelectedIdsAsString();
		this.modalManagerService.open(PrintInvoiceStatementsModalComponent, {}).onClose
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((printStatementConfig: StatementConfig) => {
				this.handlePrintStatementsModalResult(printStatementConfig, ids);
			});
	}

	handlePrintStatementsModalResult(printStatementConfig: StatementConfig, ids) {
		if (printStatementConfig) {
			const url = EnumUtil.equals(printStatementConfig.formatType, StatementFormat.XML)
				? URL_ACCOUNTING_ENDPOINTS.PRINT_STATEMENT_XML
				: URL_ACCOUNTING_ENDPOINTS.PRINT_STATEMENT_PDF;
			this.urlService.openTabWithFormPost(url, {...printStatementConfig, invoiceId: ids, printDate: printStatementConfig.printDateString});
			this.clearTableSelection();
			// We can't know when the statement has actually printed so we just delay 2 seconds
			setTimeout(() => {
				this.refreshSearch();
				// The ids in the param is a string for printing
				const invoiceIds = this.getSelectedIds();
				for (const id of invoiceIds) {
					StatefulEventUtil.publish(UPDATE_PATIENT_INVOICES, {payload: {invoiceId: id}});
				}
			}, 2000);
		} else {
			this.isSearching = false;
		}
	}

	printSummaries() {
		const ids = this.getSelectedIdsAsString();
		this.urlService.openTabWithFormPost(URL_ACCOUNTING_ENDPOINTS.PRINT_INVOICE_SUMMARIES, {invoiceId: ids});
		this.clearTableSelection();
		this.isSearching = false;
	}

	sendStatements() {
		const ids = this.getSelectedIds();
		this.modalManagerService.open(PrintInvoiceStatementsModalComponent, {data: {sendStatements: true}}).onClose
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((printStatementConfig: StatementConfig) => {
				this.handleSendStatementModalResult(printStatementConfig, ids);
			});
	}

	handleSendStatementModalResult(printStatementConfig: StatementConfig, ids) {
		if (printStatementConfig) {
			const sendStatementRequest = this.buildSendStatementRequest(ids, printStatementConfig);
			this.accountingService.sendStatements(sendStatementRequest).subscribe(() => {
				this.toasterService.showSavedSuccess({content: 'Statements sent successfully', timeOut: 0});
				this.clearTableSelection();
				this.refreshSearch();
				this.invoiceService.refreshInvoicesDetailsInvoiceTabs(ids);
			});
		} else {
			this.isSearching = false;
		}
	}

	buildSendStatementRequest(ids, printStatementConfig: StatementConfig) {
		const sendStatementRequest = new SendStatementsRequest();
		sendStatementRequest.invoiceIds = ids;
		sendStatementRequest.printStatementRequest = new PrintStatementsRequest();
		sendStatementRequest.printStatementRequest.dueDateText = printStatementConfig.dueDateText;
		sendStatementRequest.printStatementRequest.format = printStatementConfig.formatType;
		sendStatementRequest.printStatementRequest.messageText = printStatementConfig.messageText;
		sendStatementRequest.printStatementRequest.printDate = printStatementConfig.printDate;
		return sendStatementRequest;
	}

	// Allowed if approved and not on hold or voided
	allowInvoiceStatement(record: TableFormattedInvoiceSummary) {
		return !!record.invoice.approval
			&& !EnumUtil.equals(record.invoice.status, InvoiceStatus.ON_HOLD)
			&& !EnumUtil.equals(record.invoice.status, InvoiceStatus.VOIDED);
	}

	resetBatchActions() {
		const records = this.getGridSelectedRecords();
		this.batchActions = [];
		if (records.length !== 0) {
			const allowInvoiceStatements = !records.find(record => !this.allowInvoiceStatement(record));
			const allowPrintInvoices = !records.find(record => !this.isPrintable(record));
			if (allowPrintInvoices) {
				this.batchActions.push(
					InvoicesDashboardComponent.printInvoicesActions,
				);
			}
			if (allowInvoiceStatements) {
				this.batchActions.push(
					InvoicesDashboardComponent.printStatementsActions);
			}
			this.batchActions.push(InvoicesDashboardComponent.printSummariesAction);
			if (this.userCanSendStatements && allowInvoiceStatements && !_isNil(this.statementProcessorId)) {
				this.batchActions.push(InvoicesDashboardComponent.sendStatementsActions);
			}
		}
		this.batchActionDropdown.dataBind();
	}

	applyAction(action: InvoiceBatchActions) {
		if (action) {
			this.isSearching = true;
			switch (action) {
				case InvoiceBatchActions.PRINT_INVOICES:
					this.printSelectedInvoices();
					break;
				case InvoiceBatchActions.PRINT_STATEMENTS:
					this.printStatements();
					break;
				case InvoiceBatchActions.PRINT_SUMMARIES:
					this.printSummaries();
					break;
				case InvoiceBatchActions.SEND_STATEMENTS:
					this.sendStatements();
					break;
			}
			this.resetDropdown();
		}
	}

	resetDropdown() {
		setTimeout(() => {
			this.batchActionDropdown.writeValue(null);
			this.resetBatchActions();
		});
	}

	receivePayment(event: PayerType) {
		let selectedValueIndex = null;
		let selectedLocationId = null;
		if (!_isNil(this.componentForm.get('locationId').value)) {
			selectedValueIndex = _find(this.locations, (location) => location.value === this.componentForm.get('locationId').value);
			selectedLocationId = selectedValueIndex.value;
		}
		return this.invoiceService.openHTMLInvoicePaymentTab(event, selectedLocationId);
	}

	refreshSearch() {
		this.isSearching = true;
		// This is possible to be null when called if an invoice is refreshed from elsewhere causing it to appear on the dashboard with no search ever called
		// In this case we just don't refresh
		if (this.lastValidSearchRequest) {
			this.accountingService.searchInvoices(this.lastValidSearchRequest).subscribe(invoices => {
				this.handleSearchResults(invoices);
			});
		} else {
			this.isSearching = false;
		}
	}

	getItemsSelectedText() {
		return GridUtil.getItemsSelectedText(this.getGridSelectedRecords());
	}

	getNumberOfSelectedRecords() {
		return this.getGridSelectedRecords().length;
	}

	onGridCreated(): Observable<any> {
		return this.gridCreated.asObservable();
	}

	onGridPopulated(): Observable<any> {
		return this.gridCreated.asObservable().pipe(
			takeUntil(this.unsubscribe),
			switchMap(() => this.gridDatabind.asObservable().pipe(
				debounceTime(100),
				take(1),
			)),
		);
	}

	async rowClicked(event) {
		const rowData = GridUtil.getRowDataFromClick(event, this.syncfusionGrid);
		if (!_isNil(rowData)) {
			await this.openInvoiceTab(rowData);
		}
	}

	async agGridRowClicked(event: RowClickedEvent) {
		const rowData = event.data;
		if (!_isNil(rowData)) {
			await this.openInvoiceTab(rowData);
		}
	}

	get actionDropdownEnabled() {
		return this.getNumberOfSelectedRecords() > 0 && !this.isSearching;
	}
}
