<ng-container *ngFor="let era of eraResponses; let eraIndex = index">
	<ng-container *ngFor="let transaction of era.transactions; let transactionIndex = index">
		<table class="table table-condensed-xs" [attr.data-test-id]="'era'+ eraIndex + 'Transaction' + transactionIndex + 'HumanReadableRemittance'">
			<thead>
				<tr>
					<th class="vertical-align-top padding-bottom-md" colspan="3">
						<div class="margin-bottom-md">
							<h5 class="margin-top-0 margin-bottom-xs font-weight-bold" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'PayerName'">{{transaction.payerName}}</h5>
							<pms-address-display [address]="transaction.payerAddress" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'PayerAddress'"></pms-address-display>
						</div>
						<h5 class="margin-top-0 margin-bottom-xs font-weight-bold" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'ProviderName'">{{transaction.providerName}}</h5>
						<pms-address-display [address]="transaction.providerAddress" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'ProviderAddress'"></pms-address-display>
						<div class="margin-top-xs">
							Provider #: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'ProviderNpi'">{{transaction.providerNPI}}</span><br>
							Provider Tax ID #: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'ProviderTaxId'">{{transaction.providerTaxNumber}}</span>
						</div>
					</th>
					<th class="vertical-align-top padding-bottom-md text-center" colspan="3">
						<h2 class="margin-0 padding-0 font-weight-bold" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'RemittanceAdviceTitle'">Remittance Advice</h2>
					</th>
					<th class="vertical-align-top padding-bottom-md text-right" colspan="3">
						<div class="margin-bottom-lg">
							Date Generated: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'DateGenerated'">{{transaction.dateGenerated | date:dateFormat}}</span>
						</div>
						EFT #: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'EftNumber'">{{transaction.eftNumber}}</span><br>
						Check Date: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'CheckDate'">{{transaction.checkDate | date:dateFormat}}</span><br>
						Check Amount: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'CheckAmount'">{{transaction.checkAmount | currency}}</span><br>
						Provider Adjustment Amount: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'ProviderAdjustmentAmount'">{{transaction.providerAdjustmentAmount | currency}}</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let payment of transaction.paymentInfo; let paymentIndex = index">
					<tr>
						<td class="bg-default" colspan="9">
							<h5 class="margin-0 font-weight-bold">Patient Name:
								<span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'PatientName'">
									{{formatName(payment.patientLastName, payment.patientFirstName, payment.patientMiddleName, payment.patientSuffix)}}
								</span>
							</h5>
						</td>
					</tr>
					<tr>
						<td colspan="5">
							Insured Name:
							<span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'InsuredName'">
								{{formatName(payment.insuredLastName, payment.insuredFirstName, payment.insuredMiddleName, payment.insuredSuffix)}}
							</span><br>
							Claim ID: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'ClaimId'">{{payment.claimId}}</span><br>
							Claim Status: Processed as <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'ClaimStatus'">{{payment.claimStatus}}</span><br>
							Member Identification #: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'MemberIdentificationNumber'">{{payment.memberIdentificationNumber}}</span><br>
							Insured Identification #: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'InsuredIdentificationNumber'">{{payment.insuredIdentificationNumber}}</span>
						</td>
						<td colspan="4">
							Rendering Provider: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'RenderingProviderName'">
								{{formatName(payment.renderingProviderLastName, payment.renderingProviderFirstName, null, null)}}<br>
							</span>
							Rendering NPI: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'RenderingProviderNpi'">{{payment.renderingProviderNPI}}</span><br>
							Payer Claim Control/CN #: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'PayerClaimControlNumber'">{{payment.payerClaimControlNumber}}</span><br>
							Patient Responsibility: <span [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'PatientResponsibility'">{{payment.patientResponsibility | currency}}</span>
						</td>
					</tr>
					<tr class="font-weight-bold">
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'ServiceDateTitle'">Service Date</td>
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'ProcedureCodeTitle'">Procedure Code</td>
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'ModifiersTitle'">Modifiers</td>
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'UnitsTitle'">Units</td>
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'BilledTitle'">Billed</td>
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'ProviderPaidTitle'">Provider Paid</td>
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'AdjustmentCodesTitle'">Adjustment Codes</td>
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'AdjustmentAmountTitle'">Adjustment Amount</td>
						<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'RemarkCodesTitle'">Remark Codes</td>
					</tr>
					<ng-container *ngFor="let service of payment.serviceLines; let serviceIndex = index">
						<tr>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'ServiceDate'">
								{{service.serviceDate | date: dateFormat}}
							</td>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'ProcedureCode'">
								{{service.procedureCode}}
							</td>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Modifiers'">
								{{service.modifiers}}
							</td>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Units'">
								{{service.units}}
							</td>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Billed'">
								{{service.billed | currency}}
							</td>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'ProviderPaid'">
								{{service.providerPaid | currency}}
							</td>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'AdjustmentCodes'">
								<div *ngFor="let adjustment of service.adjustments; let adjustmentIndex = index">
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode1,adjustment.adjustmentAmount1)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentCode1'">
										{{defaultIfNull(adjustment.claimAdjustmentGroupCode, 'null')}}-{{defaultIfNull(adjustment.adjustmentReasonCode1, 'null')}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode2,adjustment.adjustmentAmount2)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentCode2'">
										{{defaultIfNull(adjustment.claimAdjustmentGroupCode, 'null')}}-{{defaultIfNull(adjustment.adjustmentReasonCode2, 'null')}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode3,adjustment.adjustmentAmount3)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentCode3'">
										{{defaultIfNull(adjustment.claimAdjustmentGroupCode, 'null')}}-{{defaultIfNull(adjustment.adjustmentReasonCode3, 'null')}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode4,adjustment.adjustmentAmount4)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentCode4'">
										{{defaultIfNull(adjustment.claimAdjustmentGroupCode, 'null')}}-{{defaultIfNull(adjustment.adjustmentReasonCode4, 'null')}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode5,adjustment.adjustmentAmount5)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentCode5'">
										{{defaultIfNull(adjustment.claimAdjustmentGroupCode, 'null')}}-{{defaultIfNull(adjustment.adjustmentReasonCode5, 'null')}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode6,adjustment.adjustmentAmount6)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentCode6'">
										{{defaultIfNull(adjustment.claimAdjustmentGroupCode, 'null')}}-{{defaultIfNull(adjustment.adjustmentReasonCode6, 'null')}}
									</div>
								</div>
							</td>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'AdjustmentAmount'">
								<div *ngFor="let adjustment of service.adjustments; let adjustmentIndex = index">
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode1,adjustment.adjustmentAmount1)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentAmount1'">
										{{defaultIfNull(adjustment.adjustmentAmount1, 0) | currency}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode2,adjustment.adjustmentAmount2)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentAmount2'">
										{{defaultIfNull(adjustment.adjustmentAmount2, 0) | currency}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode3,adjustment.adjustmentAmount3)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentAmount3'">
										{{defaultIfNull(adjustment.adjustmentAmount3, 0) | currency}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode4,adjustment.adjustmentAmount4)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentAmount4'">
										{{defaultIfNull(adjustment.adjustmentAmount4, 0) | currency}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode5,adjustment.adjustmentAmount5)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentAmount5'">
										{{defaultIfNull(adjustment.adjustmentAmount5, 0) | currency}}
									</div>
									<div *ngIf="displayAdjustment(adjustment.adjustmentReasonCode6,adjustment.adjustmentAmount6)"
										 [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'Adjustment' + adjustmentIndex + 'AdjustmentAmount6'">
										{{defaultIfNull(adjustment.adjustmentAmount6, 0) | currency}}
									</div>
								</div>
							</td>
							<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'RemarkCodes'">
								<div *ngFor="let remarkCode of service.remarkCodes; let remarkCodeIndex = index">
									<div [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'Service' + serviceIndex + 'RemarkCode' + remarkCodeIndex">{{remarkCode}}</div>
								</div>
							</td>
						</tr>
					</ng-container>
					<tr class="font-weight-bold">
						<td class="bg-info" colspan="4" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'ServiceLineTotals'">Service Line Totals</td>
						<td class="bg-info" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'BilledTotal'">
							{{findPaymentTotalByEraTransactionAndPayment(eraIndex, transactionIndex, paymentIndex).billed | currency}}
						</td>
						<td class="bg-info" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'ProviderPaidTotal'">
							{{findPaymentTotalByEraTransactionAndPayment(eraIndex, transactionIndex, paymentIndex).providerPaid | currency}}
						</td>
						<td class="bg-info"></td>
						<td class="bg-info" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'Payment' + paymentIndex + 'AdjustmentsTotal'">
							{{findPaymentTotalByEraTransactionAndPayment(eraIndex, transactionIndex, paymentIndex).adjustmentAmount | currency}}
						</td>
						<td class="bg-info"></td>
					</tr>
					<tr>
						<td class="padding-sm" colspan="9"></td>
					</tr>
				</ng-container>
			</tbody>
			<tfoot>
				<tr>
					<td class="bg-default" colspan="9">
						<h5 class="margin-0 font-weight-bold" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'PaymentTotals'">Payment Totals</h5>
					</td>
				</tr>
				<tr class="font-weight-bold">
					<td colspan="4"></td>
					<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'PaymentTotalsBilled'">Billed</td>
					<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'PaymentTotalsProviderPaid'">Provider Paid</td>
					<td></td>
					<td [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'PaymentTotalsAdjustmentAmount'">Adjustment Amount</td>
					<td></td>
				</tr>
				<tr class="font-weight-bold">
					<td class="bg-info" colspan="4">Totals</td>
					<td class="bg-info" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'BilledTotal'">
						{{getTransactionTotalBilled(eraIndex, transactionIndex) | currency}}
					</td>
					<td class="bg-info" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'ProviderPaidTotal'">
						{{getTransactionTotalProviderPaid(eraIndex, transactionIndex) | currency}}
					</td>
					<td class="bg-info"></td>
					<td class="bg-info" [attr.data-test-id]="'era' + eraIndex + 'Transaction' + transactionIndex + 'AdjustmentsTotal'">
						{{getTransactionTotalAdjustment(eraIndex, transactionIndex) | currency}}
					</td>
					<td class="bg-info"></td>
				</tr>
			</tfoot>
		</table>
	</ng-container>
</ng-container>
<div class="margin-top-lg">
	<p [attr.data-test-id]="'claimAdjustmentReasonCodes'">To view a full list of Claim Adjustment Reason Codes with descriptions, please visit <a href="https://x12.org/codes/claim-adjustment-group-codes" target="_blank">https://x12.org/codes/claim-adjustment-group-codes</a> and <a href="https://x12.org/codes/claim-adjustment-reason-codes" target="_blank">https://x12.org/codes/claim-adjustment-reason-codes</a>.
	<p [attr.data-test-id]="'remittanceAdviceRemarkCodes'">To view a full list of Remittance Advice Remark Codes with descriptions, please visit <a href="https://x12.org/codes/remittance-advice-remark-codes" target="_blank">https://x12.org/codes/remittance-advice-remark-codes</a>.</p>
</div>
