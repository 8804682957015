// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


// @ts-ignore
import { ImportResultResponse } from './import-result-response';

// @ts-ignore
import { LegacyDrugAllergyResponse } from './legacy-drug-allergy-response';

// @ts-ignore
import { LegacyMedicationPrescriptionResponse } from './legacy-medication-prescription-response';

// @ts-ignore
import { LegacyPersonDiagnosisResponse } from './legacy-person-diagnosis-response';

/** See com.rev360.legacy.api.controller.patient.ContinuityOfCareImportResultResponse */
/* istanbul ignore next */
@GandalfModel
export class ContinuityOfCareImportResultResponse extends ImportResultResponse {

	@GandalfProperty()
	cdaIncorporationAlertMessage: string;

	@GandalfArray(LegacyPersonDiagnosisResponse)
	diagnoses: LegacyPersonDiagnosisResponse[];

	@GandalfArray(LegacyDrugAllergyResponse)
	drugAllergies: LegacyDrugAllergyResponse[];

	@GandalfArray(String)
	errorMessages: string[];

	@GandalfProperty()
	fileId: number;

	@GandalfProperty()
	hasHaltingError: boolean;

	@GandalfProperty()
	isSuccessful: boolean;

	@GandalfArray(LegacyMedicationPrescriptionResponse)
	medicationPrescriptions: LegacyMedicationPrescriptionResponse[];

	@GandalfProperty()
	patientFileId: number;

	@GandalfProperty()
	patientId: number;

	@GandalfArray(String)
	warningMessages: string[];

}
