import { Injectable } from '@angular/core';
import { PatientDocumentGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';
import { PatientDocumentResponse } from '@gandalf/model/patient-document-response';
import { UpdatePatientDocumentContentRequest } from '@gandalf/model/update-patient-document-content-request';

@Injectable({
	providedIn: 'root',
})
export class LetterEditorDockableModalService {

	constructor(
		private patientDocumentGandalfService: PatientDocumentGandalfService,
	) { }

	/* istanbul ignore next: gandalf */
	getPatientDocument(patientDocumentId): Observable<PatientDocumentResponse> {
		return this.patientDocumentGandalfService.getPatientDocument(patientDocumentId);
	}

	/* istanbul ignore next: gandalf */
	updatePatientDocumentContent(updatePatientDocumentContentRequest: UpdatePatientDocumentContentRequest): Observable<void> {
		return this.patientDocumentGandalfService.updatePatientDocumentContent(updatePatientDocumentContentRequest);
	}
}
