<form class="form-horizontal" [formGroup]="componentForm">
	<div class="row">
		<div class="col-md-6">
			<div class="form-group" [attr.data-test-id]="'providerFormGroup'">
				<label class="col-sm-3 col-md-4 control-label required">Provider</label>
				<div class="col-sm-9 col-md-8">
					<ejs-dropdownlist [dataSource]="providers"
									  *ngIf="providers"
									  pmsAutoSelect
									  placeholder="Select Provider"
									  formControlName="providerId"
									  [showClearButton]="true">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'salutationFormGroup'">
				<label class="col-sm-3 col-md-4 control-label">Salutation</label>
				<div class="col-sm-9 col-md-8">
					<ejs-dropdownlist [dataSource]="titles"
									  *ngIf="titles"
									  placeholder="Select Salutation"
									  formControlName="titleId"
									  [showClearButton]="true">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'firstNameFormGroup'">
				<label class="col-sm-3 col-md-4 control-label required">First Name</label>
				<div class="col-sm-9 col-md-8">
					<input class="form-control e-input" pmsCapitalizeFirstLetter type="text" formControlName="firstName"/>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'lastNameFormGroup'">
				<label class="col-sm-3 col-md-4 control-label required">Last Name</label>
				<div class="col-sm-9 col-md-8">
					<input class="form-control e-input" pmsCapitalizeFirstLetter type="text" formControlName="lastName"/>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'middleNameFormGroup'">
				<label class="col-sm-3 col-md-4 control-label">Middle Name</label>
				<div class="col-sm-9 col-md-8">
					<input class="form-control e-input" pmsCapitalizeFirstLetter type="text" formControlName="middleName"/>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group" [attr.data-test-id]="'locationFormGroup'">
				<label class="control-label col-sm-3 col-md-4 required">Location</label>
				<div class="col-sm-9 col-md-8">
					<ejs-dropdownlist [dataSource]="locations"
									  formControlName="locationId"
									  [showClearButton]="true">
					</ejs-dropdownlist>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'suffixFormGroup'">
				<label class="col-xs-12 col-sm-3 col-md-4 control-label">Suffix</label>
				<div class="col-xs-7 col-sm-4 col-md-4">
					<input class="e-input" formControlName="suffix"/>
				</div>
				<div class="col-xs-5 col-sm-5 col-md-4">
					<span class="help-block">(eg. Jr, Sr)</span>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'credentialsFormGroup'">
				<label class="col-xs-12 col-sm-3 col-md-4 control-label">Cred.</label>
				<div class="col-xs-7 col-sm-4 col-md-4">
					<input type="text" class="e-input" formControlName="credentials"/>
				</div>
				<div class="col-xs-5 col-sm-5 col-md-4">
					<span class="help-block">(eg. OD, MD)</span>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'nicknameFormGroup'">
				<label class="col-sm-3 col-md-4 control-label">Nickname</label>
				<div class="col-sm-9 col-md-8">
					<input type="text" class="e-input" formControlName="nickname" pmsCapitalizeFirstLetter/>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'pronunciationFormGroup'">
				<label class="col-sm-3 col-md-4 control-label">Pronunciation</label>
				<div class="col-sm-9 col-md-8">
					<input type="text" class="e-input" formControlName="pronunciation" pmsCapitalizeFirstLetter/>
				</div>
			</div>
		</div>
	</div>
</form>
