// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.AdditionalClaimInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class AdditionalClaimInformationRequest extends GandalfModelBase {

	@GandalfProperty()
	alternateServiceLocationId: number;

	@GandalfValidator({ sizeString: { message: 'Attachment Control Number cannot be longer than 80 characters', minLength: 0, maxLength: 80 } })
	@GandalfProperty()
	attachmentControlNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	attachmentTransmission: constants.ClaimAttachmentTransmission;

	@GandalfConstantDecorator()
	@GandalfProperty()
	attachmentType: constants.ClaimAttachmentType;

	@GandalfProperty()
	authorizationId: number;

	@GandalfValidator({ sizeString: { message: 'Authorization Identifier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	authorizationNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	claimFrequencyCode: constants.ClaimFrequencyCode;

	@GandalfValidator({ sizeString: { message: 'CLIA Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	cliaNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	conditionContactsApply: constants.ClaimVisionConditionApply;

	@GandalfProperty()
	conditionContactsL1: boolean;

	@GandalfProperty()
	conditionContactsL2: boolean;

	@GandalfProperty()
	conditionContactsL3: boolean;

	@GandalfProperty()
	conditionContactsL4: boolean;

	@GandalfProperty()
	conditionContactsL5: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	conditionFramesApply: constants.ClaimVisionConditionApply;

	@GandalfProperty()
	conditionFramesL1: boolean;

	@GandalfProperty()
	conditionFramesL2: boolean;

	@GandalfProperty()
	conditionFramesL3: boolean;

	@GandalfProperty()
	conditionFramesL4: boolean;

	@GandalfProperty()
	conditionFramesL5: boolean;

	@GandalfConstantDecorator()
	@GandalfProperty()
	conditionLensesApply: constants.ClaimVisionConditionApply;

	@GandalfProperty()
	conditionLensesL1: boolean;

	@GandalfProperty()
	conditionLensesL2: boolean;

	@GandalfProperty()
	conditionLensesL3: boolean;

	@GandalfProperty()
	conditionLensesL4: boolean;

	@GandalfProperty()
	conditionLensesL5: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	currentIllnessDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	currentIllnessDateQualifier: constants.ClaimCurrentIllnessDateQualifier;

	@GandalfConstantDecorator()
	@GandalfValidator({ notNull: { message: 'Diagnosis Code Set is required' } })
	@GandalfProperty({ isRequired: true })
	diagnosisCodeSet: constants.CodeSet;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endCareDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	epsdtApply: constants.ClaimEpsdtApply;

	@GandalfConstantDecorator()
	@GandalfProperty()
	epsdtCode1: constants.ClaimEpsdtCode;

	@GandalfConstantDecorator()
	@GandalfProperty()
	epsdtCode2: constants.ClaimEpsdtCode;

	@GandalfConstantDecorator()
	@GandalfProperty()
	epsdtCode3: constants.ClaimEpsdtCode;

	@GandalfProperty({ propertyType: 'LocalDate' })
	hospitalizationEndDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	hospitalizationStartDate: Date;

	@GandalfValidator({ notNull: { message: 'Additional Claim ID is required' } })
	@GandalfProperty({ isRequired: true })
	id: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	initialTreatmentDate: Date;

	@GandalfValidator({ sizeString: { message: 'Local Use cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	localUse: string;

	@GandalfValidator({ sizeString: { message: 'Claim Codes cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	localUse10d: string;

	@GandalfValidator({ sizeString: { message: 'Medicaid Resubmit Code cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	medicaidResubmitCode: string;

	@GandalfProperty()
	orderingProviderId: number;

	@GandalfValidator({ sizeString: { message: 'Original Reference Number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	originalReferenceNumber: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	outOfWorkEndDate: Date;

	@GandalfProperty({ propertyType: 'LocalDate' })
	outOfWorkStartDate: Date;

	@GandalfValidator({ notNull: { message: 'Outside Lab is required' } })
	@GandalfProperty({ isRequired: true })
	outsideLab: boolean;

	@GandalfValidator({ notNull: { message: 'Outside Lab Charges is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	outsideLabCharges: number;

	@GandalfValidator({ sizeString: { message: 'Prior Authorization Number cannot be longer than 40 characters', minLength: 0, maxLength: 40 } })
	@GandalfProperty()
	priorAuthorizationNumber: string;

	@GandalfValidator({ sizeString: { message: 'Referral Number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	referralNumber: string;

	@GandalfProperty()
	referringProviderId: number;

	@GandalfProperty()
	secondaryPayerId: number;

	@GandalfProperty()
	signatureHandwritten: boolean;

	@GandalfProperty({ propertyType: 'LocalDate' })
	similarIllnessDate: Date;

	@GandalfConstantDecorator()
	@GandalfProperty()
	similarIllnessDateQualifier: constants.ClaimSimilarIllnessDateQualifier;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startCareDate: Date;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	visionPrescriptionDate: Date;

}
