import { Injectable } from '@angular/core';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { FullSsnResponse } from '@gandalf/model/full-ssn-response';
import { PatientGandalfService } from '@gandalf/services';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SocialSecurityNumberService {

	get canViewPatientSsn() {
		return this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_PATIENT_VIEW_SSN);
	}

	constructor(
		private patientGandalfService: PatientGandalfService,
		private securityManagerService: SecurityManagerService,
	) { }

	/* istanbul ignore next: gandalf */
	getPatientFullSsnById(patientId: number): Observable<FullSsnResponse> {
		return this.patientGandalfService.getPatientFullSsnById(patientId);
	}
}
