// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.PatientPortionRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientPortionRequest extends GandalfModelBase {

	@GandalfLabel('Co Insurance Amount')
	@GandalfValidator({ min: { min: 0, message: 'Co Insurance Amount cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money' })
	coInsuranceAmount: number;

	@GandalfLabel('Co Insurance Selection')
	@GandalfValidator({ notNull: { message: 'Co Insurance Selection is required' } })
	@GandalfProperty({ isRequired: true })
	coInsuranceSelected: boolean;

	@GandalfLabel('Contact Lens Fitting Co-Pay')
	@GandalfValidator({ min: { min: 0, message: 'Contact Lens Fitting Co-Pay cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money' })
	contactLensFittingCoPay: number;

	@GandalfLabel('Contact Lens Fitting CoPay Selection')
	@GandalfValidator({ notNull: { message: 'Contact Lens Fitting CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	contactLensFittingCoPaySelected: boolean;

	@GandalfLabel('Deductible')
	@GandalfValidator({ min: { min: 0, message: 'Deductible cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money' })
	deductible: number;

	@GandalfLabel('Deductible Selection')
	@GandalfValidator({ notNull: { message: 'Deductible Selection is required' } })
	@GandalfProperty({ isRequired: true })
	deductibleSelected: boolean;

	@GandalfLabel('General Co-Pay')
	@GandalfValidator({ min: { min: 0, message: 'General Co-Pay cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay: number;

	@GandalfLabel('General CoPay Selection')
	@GandalfValidator({ notNull: { message: 'General CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	generalCoPaySelected: boolean;

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId: number;

	@GandalfLabel('Material Co-Pay')
	@GandalfValidator({ min: { min: 0, message: 'Material Co-Pay cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay: number;

	@GandalfLabel('Material CoPay Selection')
	@GandalfValidator({ notNull: { message: 'Material CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	materialCoPaySelected: boolean;

	@GandalfLabel('Medical Co-Pay')
	@GandalfValidator({ min: { min: 0, message: 'Medical Co-Pay cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay: number;

	@GandalfLabel('Medical Exam CoPay Selection')
	@GandalfValidator({ notNull: { message: 'Medical Exam CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	medicalExamCoPaySelected: boolean;

	@GandalfLabel('Remove Tax Selected')
	@GandalfValidator({ notNull: { message: 'Remove Tax Selected is required' } })
	@GandalfProperty({ isRequired: true })
	removeTaxesSelected: boolean;

	@GandalfLabel('Routine Exam Co-Pay')
	@GandalfValidator({ min: { min: 0, message: 'Routine Exam Co-Pay cannot be negative' } })
	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay: number;

	@GandalfLabel('Routine Exam CoPay Selection')
	@GandalfValidator({ notNull: { message: 'Routine Exam CoPay Selection is required' } })
	@GandalfProperty({ isRequired: true })
	routineExamCoPaySelected: boolean;

	@GandalfLabel('Transfer Tax Invoice')
	@GandalfProperty()
	transferTaxInvoiceId: number;

	@GandalfLabel('Transfer Tax Selected')
	@GandalfValidator({ notNull: { message: 'Transfer Tax Selected is required' } })
	@GandalfProperty({ isRequired: true })
	transferTaxesSelected: boolean;

	@GandalfLabel('Transfer To Existing Invoice')
	@GandalfProperty()
	transferToExistingInvoiceId: number;

}
