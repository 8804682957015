import { Injectable } from '@angular/core';
import { _extend, _forOwn, _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { URL_FILE_WEBSERVICE_ENDPOINTS, URL_PATIENT_FILE_ENDPOINTS, URL_PRACTICE_FILE_ENDPOINTS } from '@shared/constants/url.constants';
import { GandalfHttpUtilsService } from 'gandalf';
import { FeatureService } from '@core/feature/feature.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';

@Injectable({
	providedIn: 'root',
})
export class UrlService {

	constructor(
		private gandalfHttpUtils: GandalfHttpUtilsService,
		private securityManager: SecurityManagerService,
		private featureService: FeatureService,
	) {
	}

	/* istanbul ignore next */
	openTabWithFormPost<T>(url: string, params: T) {
		this.standardUrlPost(params);
		const form = document.createElement('form');
		form.setAttribute('method', 'post');
		form.setAttribute('action', url);
		form.setAttribute('target', '_blank');
		_forOwn(params, (value, key) => {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = value as any;
			form.appendChild(input);
		});

		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}

	/* istanbul ignore next */
	openTabWithPost<T>(url: string, params: T, isStrutsCall: boolean = true) {
		const queryParams = Object.entries(params)
			.filter(param => !_isNil(param[1]))
			.map(param => this.buildQueryParam(param[0], param[1]));

		if (isStrutsCall) {
			queryParams.push(this.getStrutsParameters(new Date()));
		}

		let fullUrl = url;
		if (queryParams.length > 0) {
			fullUrl += '?' + queryParams.join('&');
		}

		this.openPopupWindow(
			fullUrl,
			'_blank',
		);
	}

	addQueryParam(url: string, queryParamKey: string, queryParamValue: string) {
		return (url.includes('?') ? (url + '&') : (url + '?')) + this.buildQueryParam(queryParamKey, queryParamValue);
	}

	buildQueryParam(key: string, value: string) {
		return key + '=' + encodeURIComponent(value);
	}

	/* istanbul ignore next */
	openPopupWindow(a_url: string, a_name: string): void {
		window.open(a_url, a_name, 'toolbar=0,location=0,directories=0,status=0,menubar=1,resizable=1,scrollbars=1');
	}

	/* istanbul ignore next */
	goToWebpage(url: string, target: string): void {
		window.open(url, target);
	}

	/* istanbul ignore next */
	standardUrlPost<T>(an_object: T) {
		_extend(an_object, {
			sessionId: this.securityManager.getUserSession().httpSessionId,
			userId: this.securityManager.getUserSession().user.id,
			currTime: new Date().getTime(),
		});
	}

	getStrutsParameters(currentTime: Date): string {
		let urlParams = '';
		urlParams += `sessionId=${this.securityManager.getUserSession().httpSessionId}`;
		urlParams += `&userId=${this.securityManager.getUserSession().user.id.toString()}`;
		urlParams += `&currTime=${currentTime.getTime()}`;
		return urlParams;
	}

	/**
	 * Gets a URL for the Struts endpoint to access a file
	 */
	getFileUrl<T>(url: string, params: T) {
		url += `?` + this.getStrutsParameters(new Date());
		_forOwn(params, (value, key) => {
			url += `&${key}=${value}`;
		});
		return url;
	}

	getPatientFileUrl(patientId: number, patientFileId: number) {
		if (this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.FILE.PATIENT_FILE_WEBSERVICE)) {
			return `${URL_FILE_WEBSERVICE_ENDPOINTS.PATIENT_DOWNLOAD}/${patientFileId}`;
		} else {
			return this.getFileUrl(URL_PATIENT_FILE_ENDPOINTS.DOWNLOAD, {
				patientId,
				patientFileId,
			});
		}
	}

	getPracticeFileUrl(practiceId: number, practiceFileId: number) {
		return this.getFileUrl(URL_PRACTICE_FILE_ENDPOINTS.DOWNLOAD, {
			practiceId,
			practiceFileId,
		});
	}

	getPracticeLocationLogo(practiceLocationId: number) {
		return !_isNil(practiceLocationId)
			? `${URL_PRACTICE_FILE_ENDPOINTS.LOGO}?locationId=${practiceLocationId}`
			: null;
	}

	/* istanbul ignore next */
	makeStrutsRequest<T>(url: string, params: T, responseType, requestType) {
		const fullUrl = url + '?' + this.getStrutsParameters(new Date());
		return this.gandalfHttpUtils.gandalfRequest(fullUrl, responseType, requestType, params);
	}
}
