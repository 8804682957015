// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.assessmentandplan.PatientTriggerOutcomeResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientTriggerOutcomeResponse extends GandalfModelBase {

	@GandalfConstantDecorator()
	@GandalfProperty()
	carePlanItemCategory: constants.CarePlanItemCategory;

	@GandalfProperty()
	clinicalExceptionComments: string;

	@GandalfProperty()
	clinicalExceptionSnomedCodeDescription: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	confirmationStatus: constants.PatientTriggerOutcomeConfirmation;

	@GandalfProperty()
	description: string;

	@GandalfProperty()
	id: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	outcomeType: constants.TriggerOutcomeType;

	@GandalfProperty()
	practiceFileId: number;

	@GandalfProperty()
	queryTriggerQueryName: string;

	@GandalfProperty()
	snomedCodeId: number;

	@GandalfProperty()
	webSite: string;

}
