// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateAttributeRequest } from './create-attribute-request';

// @ts-ignore
import { WorkflowTemplateStepRequest } from './workflow-template-step-request';

/** See com.rev360.pms.api.controller.admin.encounter.UpdateEncounterTemplateRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEncounterTemplateRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Abbreviation cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	abbreviation: string;

	@GandalfValidator({ notNull: { message: 'Allow PHR Scheduling is required' } })
	@GandalfProperty({ isRequired: true })
	allowPhrScheduling: boolean;

	@GandalfValidator({ notNull: { message: 'Calendar color is required' } })
	@GandalfProperty({ isRequired: true })
	displayColor: number;

	@GandalfValidator({ notNull: { message: 'Encounter Template Id is required' } })
	@GandalfProperty({ isRequired: true })
	encounterTemplateId: number;

	@GandalfValidator({ notNull: { message: 'In Clinic is required' } })
	@GandalfProperty({ isRequired: true })
	inClinic: boolean;

	@GandalfValidator({ notNull: { message: 'Include In MU Calculations is required' } })
	@GandalfProperty({ isRequired: true })
	includeInMuCalcs: boolean;

	@GandalfProperty()
	interviewTemplateId: number;

	@GandalfValidator({ notNull: { message: 'Is Comprehensive is required' } })
	@GandalfProperty({ isRequired: true })
	isComprehensive: boolean;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name: string;

	@GandalfValidator({ sizeString: { message: 'PHR Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	phrName: string;

	@GandalfArray(CreateAttributeRequest)
	subTypes: CreateAttributeRequest[];

	@GandalfValidator({ notNull: { message: 'Duration is required' } })
	@GandalfValidator({ min: { min: 0, message: '{javax.validation.constraints.Min.message}' } })
	@GandalfProperty({ isRequired: true })
	totalDuration: number;

	@GandalfArray(WorkflowTemplateStepRequest)
	workflowTemplateStepRequests: WorkflowTemplateStepRequest[];

}
