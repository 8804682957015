// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.encounter.SignEncounterRequest */
/* istanbul ignore next */
@GandalfModel
export class SignEncounterRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId: number;

	@GandalfValidator({ notNull: { message: 'Password is required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 255, message: 'Password length must be between {min} and {max}' } })
	@GandalfProperty({ isRequired: true })
	userPassword: string;

}
