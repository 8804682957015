<ejs-dialog #modal cssClass="modal-lg" (close)="closeModal()" [attr.data-test-id]="'createMultipleEyeglassRxModal'">
	<ng-template #header>
		Create Multiple Eyeglass Rxs
	</ng-template>
	<ng-template #content>
			<ag-grid-angular class="ag-theme-alpine"
							 pmsAgAutoFitColumns
							 #agGrid
							 [rowSelection]="'multiple'"
							 (rowSelected)="agGridRowSelected($event)"
							 [gridOptions]="eyeglassPrescriptionGridOptions"
							 (gridReady)="onGridReady($event)"
							 [pagination]="false"
							 [attr.data-test-id]="'createMultipleEyeglassRxGrid'">
			</ag-grid-angular>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'createMultipleEyeglassRxCancelButton'">Cancel</button>
		<button *ngIf="showCreateAuthAndSignButton" type="button" ejs-button [isPrimary]="true" (click)="create(true, true)" [attr.data-test-id]="'createMultipleEyeglassRxCreateAuthAndSignButton'">Create, Auth & Sign</button>
		<button *ngIf="showCreateAuthOnlyButton" type="button" ejs-button [isPrimary]="true" (click)="create(true, false)" [attr.data-test-id]="'createMultipleEyeglassRxCreateAndAuthButton'">Create & Auth</button>
		<button *ngIf="showCreateButton" type="button" ejs-button [isPrimary]="true" (click)="create(false, false)" [attr.data-test-id]="'createMultipleEyeglassRxCreateButton'">Create</button>

	</ng-template>
</ejs-dialog>

<ng-template #usedFor let-item>
		<ejs-dropdownlist
				[formControl]="getFormControl(item, 'usedForId')"
				[dataSource]="usedForValues"
				placeholder="Select Used For"
				showClearButton="true">
		</ejs-dropdownlist>
</ng-template>

<ng-template #nearAdd let-item>
	<ng-container *ngIf="item.add">
		<pms-number-stepper class="e-input" [formControl]="getFormControl(item, 'od.nearAdd')"
							[numberStepperOptions]="eyeGlassStepperOptions.ADD_POWER">
		</pms-number-stepper>
	</ng-container>
</ng-template>

<ng-template #eye let-data>
	<span class="label label-success">OD</span>
	<br/>
	<span class="label label-info">OS</span>
</ng-template>

<ng-template #sphere let-data>
	<div class="nostretch">
		<span>{{prescriptionUtil.formatSphere(data.od.sphere)}} </span>
		<br/>
		<span>{{prescriptionUtil.formatSphere(data.os.sphere)}} </span>
	</div>
</ng-template>

<ng-template #cylinder let-data>
		<span>{{prescriptionUtil.formatCylinder(data.od.cylinder)}} </span>
		<br/>
		<span>{{prescriptionUtil.formatCylinder(data.os.cylinder)}} </span>
</ng-template>

<ng-template #axis let-data>
		<span>{{prescriptionUtil.formatAxis(data.od.axis)}} </span>
		<br/>
		<span>{{prescriptionUtil.formatAxis(data.os.axis)}} </span>
</ng-template>

<ng-template #hPrism let-data>
		<span>{{prescriptionUtil.formatPrism(data.od.horizontalPrism)}} {{data.od.horizontalPrismOrientation}}</span>
		<br/>
		<span>{{prescriptionUtil.formatPrism(data.os.horizontalPrism)}} {{data.os.horizontalPrismOrientation}}</span>
</ng-template>

<ng-template #vPrism let-data>
		<span>{{prescriptionUtil.formatPrism(data.od.verticalPrism)}} {{data.od.verticalPrismOrientation}}</span>
		<br/>
		<span>{{prescriptionUtil.formatPrism(data.os.verticalPrism)}} {{data.os.verticalPrismOrientation}}</span>
</ng-template>
