<ejs-dialog #dialog
	cssClass="modal-md"
	(close)="close()"
	[attr.data-test-id]="'importQueriesModal'">
	<ng-template #header>
		<div class="dlg-template">Import Queries</div>
	</ng-template>
	<ng-template #content>
		<form class="form-horizontal" [formGroup]="formGroup" #form="ngForm" (ngSubmit)="importQueries()">
			<gandalf-lib-validation-messages
				*ngIf="formGroup.invalid && form.submitted"
				[form]="formGroup"
				[requestObj]="request">
			</gandalf-lib-validation-messages>
			<div class="row">
				<div class="col-sm-12">
					<ejs-grid #grid
						autoFitColumns
						[autoFitBlacklist]="['description']"
						[staticColumns]="['checkbox']"
						[autoFitTriggers]="['paging']"
						[pmsGridSubmit]="isSearching"
						[pmsGridSubmitDefaultSortOptions]="sortSettings"
						[dataSource]="masterQueries"
						gridLines="Horizontal"
						[allowSorting]="true"
						class="table-rowlink"
						[selectionSettings]="selectionSettings"
						(rowSelected)="updateSelectedQueries()"
						(rowDeselected)="updateSelectedQueries()"
						[allowPaging]="true"
						[pageSettings]="pageSettings"
						[allowResizing]="true"
						[attr.data-test-id]="'importQueriesTable'">
						<e-columns>
							<e-column field="checkbox" type="checkbox" [allowResizing]="false" [width]="37"></e-column>
							<e-column field="name" headerText="Name"></e-column>
							<e-column field="description" headerText="Description" clipMode="EllipsisWithTooltip"></e-column>
							<e-column field="id" [visible]="false" [isPrimaryKey]="true"></e-column>
						</e-columns>
					</ejs-grid>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" ejs-button (click)="close()" iconCss="fa fa-times" [attr.data-test-id]="'importQueriesCancelButton'">Cancel</button>
		<button type="button" ejs-button [isPrimary]="true" (click)="form.onSubmit($event)" [attr.data-test-id]="'importQueriesImportButton'">Import</button>
	</ng-template>
</ejs-dialog>
