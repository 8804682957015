<div>
	<h5 class="margin-top-0">{{patientName | patientName}}</h5>
	<dl *ngIf="patientPopoverData" class='dl-horizontal margin-0'>
		<dt>DOB</dt>
		<dd>{{patientPopoverData.dateOfBirth | date:dateFormat}}<br>({{patientPopoverData.dateOfBirth | personAge}})
		</dd>
		<dt>Sex</dt>
		<dd>{{patientPopoverData.gender.label}}</dd>
		<dt>Address</dt>
		<dd><pms-address-display [address]="patientPopoverData.address"></pms-address-display></dd>
		<dt>Home</dt>
		<dd>{{patientPopoverData.phoneNumbers.homePhone}} {{patientPopoverData.phoneNumbers.homePhoneExt}}
			<i *ngIf="isPreferredPhoneType(preferredPhoneType.HOME)"
			   class='fa fa-star text-success'></i></dd>
		<dt>Work</dt>
		<dd>{{patientPopoverData.phoneNumbers.workPhone}} {{patientPopoverData.phoneNumbers.workPhoneExt}}
			<i *ngIf="isPreferredPhoneType(preferredPhoneType.WORK)"
			   class='fa fa-star text-success'></i></dd>
		<dt>Cell</dt>
		<dd>{{patientPopoverData.phoneNumbers.cellPhone}} {{patientPopoverData.phoneNumbers.cellPhoneExt}}
			<i *ngIf="isPreferredPhoneType(preferredPhoneType.CELL)"
			   class='fa fa-star text-success'></i></dd>
		<dt>Email</dt>
		<dd>{{patientPopoverData.email}}</dd>
		<dt>PHR Login</dt>
		<dd>{{patientPopoverData.phrLogin | yesNo}}</dd>
	</dl>
</div>
