<ejs-dialog
	#modal
	(close)="closeModal()"
	id="todays-patients-dockable-modal"
	cssClass="dockable-modal"
	[ngClass]="{'dialog-maximized': maximized, 'dialog-minimized': !maximized}"
	[attr.data-test-id]="'dockableTodaysPatientsModal'"
	[width]="500"
	[height]="'85%'"
	[allowDragging]="false"
	[isModal]="false"
	[position]="{X: 'right', Y: 'top'}">
	<rev-loading-overlay [loading]="_isLoading">
		<ng-template #header>
			<span class='title'>Today's Patients</span>
			<span class="header-btns">
			<rev-button buttonIcon="window-maximize" *ngIf="!maximized" (click)='maximize()'></rev-button>
			<rev-button buttonIcon="window-minimize" *ngIf="maximized" (click)='minimize()'></rev-button>
			<rev-button (click)="getData()" buttonIcon="refresh"></rev-button>
		</span>
		</ng-template>
		<ng-template #content>
			<p *ngIf="showNoDataMessage()">There are currently no patients scheduled for today.</p>
			<div *ngIf="todaysPatientsAppointmentResponses" class="appointment-list">
				<div class="appointment-item" *ngFor="let appointment of todaysPatientsAppointmentResponses">
					<div class="appointment-time media-left" [style]="{'border-color': (appointment.templateDisplayColor | intToHexColor)}">
						<a (click)="goToScheduleItem(appointment.appointmentId)">
							<span class="appointment-time-start">{{appointment.startDate | date:'shortTime'}}</span>
							<span class="appointment-time-end">{{appointment.endDate | date:'shortTime'}}</span>
						</a>
					</div>
					<div class="appointment-info media-body container-fluid">
						<div class="row">
							<div class="col-sm-8 col-lg-9">
								<div class="appointment-name">
									<a (click)="goToPatient(appointment.patientName.patientId)" data-toggle="modal" data-target="#patient-overview-modal">
										{{appointment.patientName.lastName}}, {{appointment.patientName.firstName}}
										<span *ngIf="appointment.patientName.nickname">"{{appointment.patientName.nickname}}"</span>
									</a>
									<b *ngIf="isPatientNew(appointment.patientName.status)" class="text-large text-primary">*</b>
									<span class="appointment-name-age text-muted">
										{{appointment.patientDob | date:dateFormat }} ({{appointment.patientDob | yearsDiff}} yrs)
									</span>
								</div>
								<div class="appointment-meta">
									<span *ngIf="connectSubscriber">
										<i
												*ngIf="connectMessageExistsAndDelivered(appointment.connectMessageExists, appointment.connectMessageDelivered)"
												class="fa fa-comment text-success">
										</i>
										<i
												*ngIf="connectMessageExistsAndNotDelivered(appointment.connectMessageExists, appointment.connectMessageDelivered)"
												class="fa fa-comment text-danger">
										</i>
									</span>
									<span class="appointment-type">
										<a (click)="goToAppointmentOrEncounter(appointment)">{{appointment.templateDisplayName}}</a>
										<a class="margin-left-xs" *ngIf="appointment.encounterId" (click)="printEncounter(appointment.encounterId)"
										   target="_blank">
											<i class="fa fa-info-circle"></i>
										</a>
										<span class="small text-muted margin-left-xs"
											  *ngIf="appointment.appointmentSubTypeValue">({{appointment.appointmentSubTypeValue}})</span>
									</span>
									<span class="appointment-provider" *ngIf="appointment.provider?.personId">
										<i class="fa fa-circle"
										   [style]="{'color': (appointment.provider.providerDisplayColor | intToHexColor)}"></i>{{appointment.provider.lastName}}
										, {{appointment.provider.firstName}}
									</span>
									<span class="appointment-provider" *ngIf="!appointment.provider && appointment.employeeName">
										<i class="fa fa-circle"></i>{{appointment.employeeName.lastName}}, {{appointment.employeeName.firstName}}
									</span>
									<span class="appointment-provider" *ngIf="!appointment.provider && !appointment.employeeName">
										<i class="fa fa-circle"></i>{{appointment.roleName}}
									</span>
								</div>
							</div>
							<div class="appointment-actions col-sm-4 col-lg-3">
								<span class="appointment-statuses">
									<pms-confirmed-status-icon [status]="appointment.appointmentConfirmed"></pms-confirmed-status-icon>
									<pms-insurance-status-icon
											[status]="appointment.insuranceVerifiedStatus"
											[comment]="appointment.insuranceComment">
									</pms-insurance-status-icon>
									<pms-interview-status-icon [status]="appointment.interviewStatus"></pms-interview-status-icon>
									<pms-signed-status-icon [status]="appointment.scheduleItemStatus"
															[encounterApprovalStatus]="appointment.encounterApprovalStatus"></pms-signed-status-icon>
								</span>
								<rev-button
										*ngIf="appointment.scheduleItemStatus === scheduleItemStatus.ACTIVE"
										(buttonClick)="startAppointment(appointment)"
										buttonLabel="Start"
										dataTestId="todaysPatientsDockableModalStart">
								</rev-button>
								<rev-button
										*ngIf="appointment.scheduleItemStatus === scheduleItemStatus.IN_PROGRESS"
										(buttonClick)="completeEncounter(appointment.encounterId)"
										buttonLabel="Complete"
										dataTestId="todaysPatientsDockableModalComplete">
								</rev-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</rev-loading-overlay>
</ejs-dialog>
