<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-lg"
		[attr.data-test-id]="'selectADiagnosisModal'">
	<ng-template #header>
		<div class="dlg-template">Select a Diagnosis</div>
	</ng-template>
	<ng-template #content>
		<div class="row">
			<div class="col-sm-12 col-lg-6 margin-bottom-md">
				<form
						#templateForm="ngForm"
						(ngSubmit)="saveDiagnosesAndRefreshInvoice()"
						[formGroup]="componentForm"
						class="form-horizontal">
					<gandalf-lib-validation-messages
							*ngIf="componentForm.invalid && templateForm.submitted"
							[form]="componentForm"
							[requestObj]="invoiceDiagnosesRequest"
							[attr.data-test-id]="'selectADiagnosisModalValidationMessages'">
					</gandalf-lib-validation-messages>
					<ejs-grid *ngIf="!agGridFlagOn" id="selectedDiagnosesGrid" #selectedDiagnosesGrid
							  height="450"
							  class="table-rowlink"
							  [dataSource]="selectedDiagnoses"
							  [query]="sortQuery"
							  [allowSelection]="true"
							  [editSettings]="{ allowAdding: true, allowDeleting: true }"
							  [allowRowDragAndDrop]="true"
							  [rowDropSettings]="{ targetID: 'masterModifiersGrid' }"
							  (recordDoubleClick)="selectedDiagnosesGridDoubleclick($event)"
							  [selectionSettings]="{ type: 'Multiple' }"
							  (rowDataBound)="applyBackgroundColor($event)"
							  [attr.data-test-id]="'selectADiagnosisModalEjsGrid'">
						<e-columns>
							<e-column field="id" headerText="Selected Diagnoses" isPrimaryKey="true" width="80%">
								<ng-template #template let-data>
									<div *ngIf="isCodeIcd10AndDisabled(data)">
										<span revTooltip tooltipContent="This code is no longer billable on/after {{data.practiceDiagnosis.disabledDate | date: dateFormat}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
									</div>
									<div *ngIf="!isCodeIcd10AndDisabled(data)">
										<span revTooltip tooltipContent="{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
									</div>
								</ng-template>
							</e-column>
							<e-column headerText="" width="10%" textAlign="Right">
								<ng-template #template let-data>
									<rev-button
											[revGridButton]="'remove'"
											(buttonClick)="removeFromSelected(data)"
											dataTestId="diagnosesRemoveButton">
									</rev-button>
								</ng-template>
							</e-column>
						</e-columns>
					</ejs-grid>
					<div *ngIf="agGridFlagOn">
						<ag-grid-angular #selectedDiagnosesAgGrid
										 class="ag-theme-alpine"
										 pmsAgAutoFitColumns
										 [domLayout]="'autoHeight'"
										 [gridOptions]="selectedDxGridOptions"
										 [rowData]="selectedDiagnoses"
										 (rowDoubleClicked)="selectedDiagnosesGridDoubleclick($event)"
										 (gridReady)="onSelectedDiagnosesAgGridReady($event)"
										 [pagination]="false"
										 [attr.data-test-id]="'selectedDiagnosesTable'">
						</ag-grid-angular>
					</div>
				</form>
			</div>
			<div class="col-sm-12 col-lg-6">
				<ejs-grid *ngIf="!agGridFlagOn" id="availableDiagnosesGrid" #availableDiagnosesGrid
						  height="450"
						  class="table-rowlink"
						  [dataSource]="availableDiagnoses"
						  [query]="sortQuery"
						  [pmsGridSubmit]="isSearching"
						  [allowSelection]="true"
						  [editSettings]="{ allowAdding: true, allowDeleting: true }"
						  [allowRowDragAndDrop]="true"
						  [rowDropSettings]="{ targetID: 'selectedDiagnosesGrid' }"
						  (recordDoubleClick)="availableDiagnosesGridDoubleclick($event)"
						  [selectionSettings]="{ type: 'Multiple' }"
						  (rowDataBound)="applyBackgroundColor($event)"
						  [attr.data-test-id]="'availableDiagnosesEjsGrid'">
					<e-columns>
						<e-column field="id" headerText="Available Diagnoses" isPrimaryKey="true" width="80%">
							<ng-template #template let-data>
								<div *ngIf="isCodeIcd10AndDisabled(data)">
									<span revTooltip tooltipContent="This code is no longer billable on/after {{data.practiceDiagnosis.disabledDate | date: dateFormat}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
								</div>
								<div *ngIf="!isCodeIcd10AndDisabled(data)">
									<span revTooltip tooltipContent="{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}">{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}</span>
								</div>
							</ng-template>
						</e-column>
						<e-column headerText="" width="10%" textAlign="Right">
							<ng-template #template let-data>
								<rev-button
										[revGridButton]="'add'"
										(buttonClick)="addToSelected(data)"
										dataTestId="diagnosesAddButton">
								</rev-button>
							</ng-template>
						</e-column>
					</e-columns>
				</ejs-grid>
				<ng-container *ngIf="agGridFlagOn">
						<div class="margin-bottom-sm" *ngIf="showIcd9Filter()" rev-table-action-menu-left>
							<ejs-checkbox [(ngModel)]="gridIcd9Filter" (ngModelChange)="filterAvailableDiagnosesAgGrid()" label="Show ICD-9 Codes" [attr.data-test-id]="'showIcd9CodesCheckBox'"></ejs-checkbox>
						</div>
						<ag-grid-angular #availableDiagnosesAgGrid
										 class="ag-theme-alpine"
										 pmsAgAutoFitColumns
										 [domLayout]="'autoHeight'"
										 [gridOptions]="availableDxGridOptions"
										 [rowData]="availableDiagnoses"
										 (rowDoubleClicked)="availableDiagnosesGridDoubleclick($event)"
										 (gridReady)="onAvailableDiagnosesAgGridReady($event)"
										 [pagination]="false"
										 (rowDataUpdated)="filterAvailableDiagnosesAgGrid()"
										 [attr.data-test-id]="'availableDiagnosesTable'">
						</ag-grid-angular>
				</ng-container>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectADiagnosisCancelButton'">Cancel</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'selectADiagnosisSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>

<ng-template #diagnosesCodeColumnTemplate let-data>
		<span *ngIf="isCodeIcd10AndDisabled(data)" revTooltip tooltipContent="This code is no longer billable on/after {{data.practiceDiagnosis.disabledDate | date: dateFormat}}">
			{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}
		</span>
		<span *ngIf="!isCodeIcd10AndDisabled(data)" revTooltip tooltipContent="{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}">
			{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}
		</span>
</ng-template>
<ng-template #diagnosesRemoveButtonColumnTemplate let-data>
	<rev-button
			[revGridButton]="'remove'"
			(buttonClick)="removeFromSelected(data)"
			dataTestId="diagnosesRemoveButton">
	</rev-button>
</ng-template>
<ng-template #diagnosesAddButtonColumnTemplate let-data>
	<rev-button
			[revGridButton]="'add'"
			(buttonClick)="addToSelected(data)"
			dataTestId="diagnosesAddButton">
	</rev-button>
</ng-template>
