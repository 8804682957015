import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StatefulRouteService } from '@app-store/services/stateful-route.service';
import { NavigationService } from '@core/navigation/navigation.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { ToasterService } from '@core/toaster/toaster.service';
import { RemoveEncounterTab } from '@encounters-store/actions/encounter-tab.actions';
import { State } from '@encounters-store/reducers';
import { EncounterTabSubType } from '@encounters-store/reducers/encounter-tab.reducer';
import { LegacyEncounterGandalfService } from '@gandalf-black/services';
import { CancelEncounterRequest } from '@gandalf/model/cancel-encounter-request';
import { CreateEncounterRequest } from '@gandalf/model/create-encounter-request';
import { EncounterPullForwardTableResponse } from '@gandalf/model/encounter-pull-forward-table-response';
import { EncounterResponse } from '@gandalf/model/encounter-response';
import { PullForwardEncounterRequest } from '@gandalf/model/pull-forward-encounter-request';
import { EncounterGandalfService } from '@gandalf/services';
import { Store } from '@ngrx/store';
import { FormattedNamePipe } from '@shared/pipes/formatted-name/formatted-name.pipe';
import { DialogUtil, SortingService, YesNoPipe } from 'morgana';
import { combineLatest, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { UpdateEncounterProviderRequest } from '@gandalf/model/update-encounter-provider-request';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { APP_ROUTING_URL_CONSTANTS } from '../../app-routing.constants';

interface FormattedEncounterResponse extends EncounterResponse {
	wasSignedFormatted: string;
	formattedEmployeeOrProvider: string;
}

interface FormattedEncounterPullForwardTableResponse extends EncounterPullForwardTableResponse {
	formattedEmployeeOrProvider: string;
	formattedCPTCodes: string;
	formattedICDCodes: string;
}

@Injectable({
	providedIn: 'root',
})
export class EncounterService {

	readonly encounterUpliftFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.ENCOUNTERS.UPLIFT));

	constructor(
		private encounterGandalfService: EncounterGandalfService,
		private legacyEncounterGandalfService: LegacyEncounterGandalfService,
		private formattedNamePipe: FormattedNamePipe,
		private yesNoPipe: YesNoPipe,
		private store: Store<State>,
		private navigationService: NavigationService,
		private router: Router,
		private toasterService: ToasterService,
	) {
	}

	/* istanbul ignore next: gandalf */
	getEncounterById(encounterId: number) {
		return this.encounterGandalfService.getEncounterById(encounterId);
	}

	/* istanbul ignore next: gandalf */
	getEncounterStatusesById(encounterId: number) {
		return this.encounterGandalfService.getEncounterStatusesById(encounterId);
	}

	findEncountersByPatientId(patientId: number) {
		return this.encounterGandalfService.findEncountersByPatientId(patientId).pipe(
			map(encounters => SortingService.sortBy(encounters, ['encounterDate', 'id'], ['desc', 'desc'])),
			map(encounters => encounters.map(encounter => this.formatEncounterForTable(encounter))),
		);
	}

	/* istanbul ignore next: gandalf */
	findNonCanceledEncountersByPatientIdForTable(patientId: number) {
		return this.encounterGandalfService.findNonCanceledEncountersByPatientIdForTable(patientId);
	}

	findNonCanceledEncountersByPatientIdForPullForward(patientId: number, encounterId: number) {
		return this.encounterGandalfService.findNonCanceledEncountersByPatientIdForPullForward(patientId, encounterId).pipe(
			map(encounters => encounters.map(encounter => this.formatEncounterForPullForwardTable(encounter))),
		);
	}

	formatEncounterForPullForwardTable(encounterResponse: EncounterPullForwardTableResponse): FormattedEncounterPullForwardTableResponse {
		let formattedResponse = encounterResponse as FormattedEncounterPullForwardTableResponse;
		formattedResponse = this.formatProviderOrEmployee(formattedResponse);
		formattedResponse.formattedCPTCodes = encounterResponse.performedServiceCodes.join(', ');
		formattedResponse.formattedICDCodes = encounterResponse.personDiagnosisCodes.join(', ');

		return formattedResponse;
	}

	changeEncounterProvider(request: UpdateEncounterProviderRequest) {
		if (this.encounterUpliftFeatureOn) {
			return this.encounterGandalfService.updateEncounterProvider(request);
		} else {
			return this.legacyEncounterGandalfService.changeEncounterProvider(request);
		}
	}

	/* istanbul ignore next: gandalf */
	completeEncounter(encounterId: number) {
		return this.encounterGandalfService.completeEncounter(encounterId);
	}

	/* istanbul ignore next: gandalf */
	createEncounter(request: CreateEncounterRequest) {
		return this.encounterGandalfService.createEncounter(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	cancelEncounter(request: CancelEncounterRequest) {
		return this.encounterGandalfService.cancelEncounter(request);
	}

	formatEncounterForTable(encounterResponse: EncounterResponse): FormattedEncounterResponse {
		let formattedResponse = encounterResponse as FormattedEncounterResponse;
		formattedResponse.wasSignedFormatted = this.yesNoPipe.transform(encounterResponse.wasSigned);
		formattedResponse = this.formatProviderOrEmployee(formattedResponse);
		return formattedResponse;
	}

	formatProviderOrEmployee(encounterResponse: any): any {
		const formattedEncounterResponse = encounterResponse;
		if (encounterResponse.provider) {
			formattedEncounterResponse.formattedEmployeeOrProvider = this.formattedNamePipe.transform(
				encounterResponse.provider.firstName, encounterResponse.provider.lastName);
		} else if (encounterResponse.employee) {
			formattedEncounterResponse.formattedEmployeeOrProvider = this.formattedNamePipe.transform(
				encounterResponse.employee.firstName, encounterResponse.employee.lastName);
		} else {
			formattedEncounterResponse.formattedEmployeeOrProvider = '';
		}

		return formattedEncounterResponse;
	}

	/* istanbul ignore next: gandalf */
	pullForwardExamFindings(encounterId: number, patientId: number, request: PullForwardEncounterRequest) {
		const dialog = this.showSpinnerModal('Pulling forward data...');
		combineLatest([
			this.closeEncounter(encounterId, patientId),
			this.encounterGandalfService.pullForwardExamFindings(request),
		]).subscribe({
			next: () => {
				this.onSuccesfulEncounterChange(encounterId, patientId, dialog);
			},
			error: () => this.onUnSuccessfulEncounterChange(encounterId, patientId, dialog),
		});
	}

	private async onUnSuccessfulEncounterChange(encounterId: number, patientId: number, dialog) {
		await this.openEncounter(encounterId, patientId);
		dialog.close();
		this.toasterService.showError();
	}

	private onSuccesfulEncounterChange(encounterId: number, patientId: number, dialog) {
		// This ensure the routing away and the endpoint have both successfully finished
		// then gives one tick for the store action to cause the tab to close before reopening it
		setTimeout(async () => {
			await this.openEncounter(encounterId, patientId);
			dialog.close();
			this.toasterService.showSavedSuccess();
		});
	}

	/* istanbul ignore next: gandalf */
	clearExamFindings(encounterId: number, patientId: number) {
		const dialog = this.showSpinnerModal('Clearing exam data...');
		combineLatest([
			this.closeEncounter(encounterId, patientId),
			this.encounterGandalfService.clearExamFindings(encounterId),
		]).subscribe({
			next: () => {
				this.onSuccesfulEncounterChange(encounterId, patientId, dialog);
			},
			error: () => this.onUnSuccessfulEncounterChange(encounterId, patientId, dialog),
		});
	}

	private showSpinnerModal(title) {
		return DialogUtil.alert({
			title,
			content: '<div class="text-center"><i class="fa fa-spinner fa-pulse fa-spin fa-3x"></i></div>',
			showCloseIcon: false,
			closeOnEscape: false,
			okButton: {text: '', cssClass: 'hidden'},
			position: {X: 'center', Y: 'middle'},
		});
	}

	closeEncounter(encounterId: number, patientId: number) {
		return from(
			StatefulRouteService.routePastPersistedRoute(
				this.router,
				`${APP_ROUTING_URL_CONSTANTS.PATIENTS.url}/patient/${patientId?.toString()}/encounters`,
			),
		).pipe(
			tap(() => {
				this.store.dispatch(new RemoveEncounterTab({
					tabSubType: EncounterTabSubType.ENCOUNTER,
					patientId,
					objectId: encounterId,
				}));
			}),
		);
	}

	openEncounter(encounterId: number, patientId: number) {
		return this.navigationService.navigateToEncounter(encounterId, patientId);
	}
}
