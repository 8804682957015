import { Directive, HostListener } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { _cloneDeep, _filter, _isNil } from '../../utils/lodash/lodash';

@Directive({
	selector: 'ejs-dropdownlist:[revDropdownlistHideInactiveUnlessSelected]',
})
export class DropdownlistHideInactiveUnlessSelectedDirective {
	private unfilteredDataSource;

	constructor(
		private dropdownListComponent: DropDownListComponent,
	) {
	}

	@HostListener('open')
	onOpen() {
		if (_isNil(this.unfilteredDataSource)) {
			this.unfilteredDataSource = _cloneDeep(this.dropdownListComponent.dataSource);
		}
		this.dropdownListComponent.dataSource = _filter(this.unfilteredDataSource, data => !data.label.includes('(Inactive)') || data.value === this.dropdownListComponent.value);
	}
}
