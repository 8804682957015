// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.encounter.UnsignEncounterSsoRequest */
/* istanbul ignore next */
@GandalfModel
export class UnsignEncounterSsoRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId: number;

	@GandalfValidator({ sizeString: { message: 'Reason cannot be longer than 8000 characters', minLength: 0, maxLength: 8000 } })
	@GandalfProperty()
	reason: string;

}
