<div class="panel widget-sm widget-with-btn-group text-center" [ngClass]="bucket.color">
	<div class="panel-heading">
		<h2 class="panel-title">{{bucket.count}}</h2>
		<div class="nav-tools" *ngIf="bucket.displayPillCount">
			<span *ngIf="bucket.pillCount !== 0" class="badge badge-danger">{{bucket.pillCount}}</span>
		</div>
	</div>
	<div class="btn-group btn-group-justified" (click)="bucket.onTitleClick()">
		<a class="btn btn-sm btn-default text-uppercase small" [ngClass]="{'active':bucket.active}" [attr.data-test-id]="bucket.name + 'Bucket'">{{bucket.label | uppercase}}</a>
	</div>
	<div class="btn-group btn-group-justified hidden-xs">
		<a *ngFor="let subBucket of bucket.subBuckets" (click)="subBucket.onClick()" [ngClass]="{'active':subBucket.active}"  class="btn btn-xs btn-default"
		   [attr.data-test-id]="bucket.name + subBucket.label + 'SubBucket'">
			<strong>{{subBucket.count}}</strong>
			<span class="small text-muted text-uppercase hidden-md"> {{subBucket.label}}</span>
			<span class="small text-muted text-uppercase visible-md-inline"> {{subBucket.labelAbbreviated ? subBucket.labelAbbreviated:subBucket.label}}</span>
		</a>
	</div>
</div>
