import { Injectable } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { GandalfTheGreyService } from '@core/gandalf-the-grey/gandalf-the-grey.service';
import { _isNil, _map } from '@core/lodash/lodash';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ShowSavedSuccessToast } from '@core/toaster/toaster-decorators';
import { UrlService } from '@core/url-util/url.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import {
	IntakeReconciledStatus,
	IntakeStatus,
	MedicationPrescriptionType,
	PreferenceDefaults,
	PreferenceName,
	PrescriptionAuthorizationType,
	PrescriptionCurrentStatus,
	PrescriptionStatus
} from '@gandalf/constants';
import { AuthorizeMedicationPrescriptionRequest } from '@gandalf/model/authorize-medication-prescription-request';
import { CreateGeneralMedicationPrescriptionRequest } from '@gandalf/model/create-general-medication-prescription-request';
import { CreateMedicationPrescriptionRequest } from '@gandalf/model/create-medication-prescription-request';
import { CreateMedicationReviewHistoryRequest } from '@gandalf/model/create-medication-review-history-request';
import { ExternalMedicationPrescriptionResponse } from '@gandalf/model/external-medication-prescription-response';
import { IntakeMedicationResponse } from '@gandalf/model/intake-medication-response';
import { MedicationPrescriptionListResponse } from '@gandalf/model/medication-prescription-list-response';
import { MedicationPrescriptionRefillRequest } from '@gandalf/model/medication-prescription-refill-request';
import { MedicationPrescriptionResponse } from '@gandalf/model/medication-prescription-response';
import { MedicationReviewHistoryResponse } from '@gandalf/model/medication-review-history-response';
import { PersonNameResponse } from '@gandalf/model/person-name-response';
import { StopPrescriptionRequest } from '@gandalf/model/stop-prescription-request';
import { UpdateGeneralMedicationPrescriptionRequest } from '@gandalf/model/update-general-medication-prescription-request';
import { UpdateIntakeMedicationRequest } from '@gandalf/model/update-intake-medication-request';
import { UpdateMedicationPrescriptionRequest } from '@gandalf/model/update-medication-prescription-request';
import { UrlResponse } from '@gandalf/model/url-response';
import { InfoButtonGandalfService, MedicationGandalfService, MedicationReviewHistoryGandalfService } from '@gandalf/services';
import { URL_PRINT_ENDPOINTS } from '@shared/constants/url.constants';
import { PersonNamePipe } from '@shared/pipes/person-name/person-name.pipe';
import { EnumUtil, SortingService, YesNoPipe } from 'morgana';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export type PrescriptionTypeForDisplay = 'eRx' | 'Int' | 'Ext' | 'Gen';

export enum IntakeMedication {
	MEDICATION,
	COMMENT,
	CHECKBOX,
}

export interface IntakeMedicationList {
	id: number;
	prescriptionId: number;
	type: IntakeMedication;
	question: string;
	answer: string;
	status: IntakeStatus;
	answerFlag: boolean;
	isDisableCheckbox: boolean;
}

export interface PrescriptionForTable extends MedicationPrescriptionListResponse {
	typeForDisplay: PrescriptionTypeForDisplay;
	statusForDisplay: PrescriptionCurrentStatus;
	isActive: boolean;
	isExpired: boolean;
	authName: string;
	providerName: string;
}

@Injectable({
	providedIn: 'root',
})
export class MedicationPrescriptionService {
	private _intakeMedicationAccepted = new Subject<any>();

	constructor(
		private gandalfTheGreyService: GandalfTheGreyService,
		private medicationPrescriptionGandalfService: MedicationGandalfService,
		private medicationReviewHistoryGandalfService: MedicationReviewHistoryGandalfService,
		private infoButtonGandalfService: InfoButtonGandalfService,
		private personNamePipe: PersonNamePipe,
		private securityManagerService: SecurityManagerService,
		private urlService: UrlService,
		private yesNoPipe: YesNoPipe,
		private featureService: FeatureService,
		private userLocationsService: UserLocationsService,
	) {
	}

	/**
	 * Retrieves the prescriptions for a patient, and then adds some data to be displayed in the UI.
	 */
	findByPatientId(patientId: number): Observable<PrescriptionForTable[]> {
		return this.medicationPrescriptionGandalfService.findByPatientId(patientId)
			.pipe(map(responses => _map(responses, response => this.buildPrescriptionForTable(response))));
	}

	/**
	 * Retrieves the active prescriptions for a patient, and then adds some data to be displayed in the UI.
	 */
	findActiveByPatientId(patientId: number): Observable<PrescriptionForTable[]> {
		return this.medicationPrescriptionGandalfService.findActiveByPatientId(patientId)
			.pipe(
				map(responses => _map(responses, response => this.buildPrescriptionForTable(response))),
				map(responses => SortingService.sortBy(responses, ['startDate', 'id'], ['desc'])),
			);
	}

	/* istanbul ignore next: gandalf */
	getMedicationPrescriptionById(medicationPrescriptionId: number): Observable<MedicationPrescriptionResponse> {
		return this.medicationPrescriptionGandalfService.getMedicationPrescriptionById(medicationPrescriptionId);
	}

	/* istanbul ignore next: gandalf */
	getExternalMedicationPrescriptionById(externalMedicationPrescriptionId: number): Observable<ExternalMedicationPrescriptionResponse> {
		return this.medicationPrescriptionGandalfService.getExternalMedicationPrescriptionById(externalMedicationPrescriptionId);
	}

	/* istanbul ignore next: gandalf */
	findMostRecentMedicationReviewByEncounterId(encounterId: number): Observable<MedicationReviewHistoryResponse> {
		return this.medicationReviewHistoryGandalfService.findMostRecentMedicationReviewByEncounterId(encounterId);
	}

	/* istanbul ignore next: gandalf */
	findMostRecentMedicationReviewByPatientId(patientId: number): Observable<MedicationReviewHistoryResponse> {
		return this.medicationReviewHistoryGandalfService.findMostRecentMedicationReviewByPatientId(patientId);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	createMedicationReviewHistoryWithToaster(request: CreateMedicationReviewHistoryRequest): Observable<MedicationReviewHistoryResponse> {
		return this.medicationReviewHistoryGandalfService.createMedicationReviewHistory(request);
	}

	/* istanbul ignore next: gandalf */
	createMedicationReviewHistoryWithoutToaster(request: CreateMedicationReviewHistoryRequest): Observable<MedicationReviewHistoryResponse> {
		return this.medicationReviewHistoryGandalfService.createMedicationReviewHistory(request);
	}

	getMedicationInfoButtonUrl(medicationId: number): Observable<UrlResponse> {
		return this.infoButtonGandalfService.getMedicationInfoButtonUrl(medicationId);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	createMedicationPrescription(request: CreateMedicationPrescriptionRequest): Observable<MedicationPrescriptionResponse> {
		return this.medicationPrescriptionGandalfService.createMedicationPrescription(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updateMedicationPrescription(request: UpdateMedicationPrescriptionRequest): Observable<MedicationPrescriptionResponse> {
		return this.medicationPrescriptionGandalfService.updateMedicationPrescription(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	createGeneralMedicationPrescription(request: CreateGeneralMedicationPrescriptionRequest): Observable<MedicationPrescriptionResponse> {
		return this.medicationPrescriptionGandalfService.createGeneralMedicationPrescription(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	refillMedicationPrescription(request: MedicationPrescriptionRefillRequest): Observable<MedicationPrescriptionResponse> {
		return this.medicationPrescriptionGandalfService.refillMedicationPrescription(request);
	}

	/* istanbul ignore next: gandalf */
	@ShowSavedSuccessToast()
	updateGeneralMedicationPrescription(request: UpdateGeneralMedicationPrescriptionRequest): Observable<MedicationPrescriptionResponse> {
		return this.medicationPrescriptionGandalfService.updateGeneralMedicationPrescription(request);
	}

	private buildPrescriptionForTable(response: MedicationPrescriptionListResponse): PrescriptionForTable {
		const prescription = response as PrescriptionForTable;
		const isExpired = PrescriptionUtil.isExpired(response.expirationDate);
		prescription.typeForDisplay = this.determineType(response);
		prescription.statusForDisplay = this.determineStatus(response, isExpired);
		prescription.isActive = this.isActive(response, isExpired);
		prescription.isExpired = isExpired;

		const authOrProviderName = this.getAuthOrProviderName(prescription);
		if (EnumUtil.equals(prescription.type, MedicationPrescriptionType.INTERNAL)
			&& EnumUtil.equals(prescription.authorizationType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER)) {
			prescription.authName = '';
			prescription.providerName = authOrProviderName;
		} else {
			prescription.authName = authOrProviderName;
			prescription.providerName = '';
		}

		return prescription;
	}

	getAuthOrProviderName(prescription: MedicationPrescriptionListResponse): string {
		if (EnumUtil.equals(prescription.type, MedicationPrescriptionType.EXTERNAL)) {
			return this.getExternalMedAuthOrProviderName(prescription);
		} else {
			return this.getInternalMedAuthOrProviderName(prescription);
		}
	}

	private getExternalMedAuthOrProviderName(prescription: MedicationPrescriptionListResponse): string {
		if (prescription.authorizingProviderFirstName || prescription.authorizingProviderLastName) {
			const person = {
				firstName: prescription.authorizingProviderFirstName,
				lastName: prescription.authorizingProviderLastName,
			} as PersonNameResponse;
			return this.personNamePipe.transform(person);
		} else if (prescription.primaryProviderFirstName || prescription.primaryProviderLastName) {
			const person = {
				firstName: prescription.primaryProviderFirstName,
				lastName: prescription.primaryProviderLastName,
			} as PersonNameResponse;
			return this.personNamePipe.transform(person);
		} else {
			return '';
		}
	}

	private getInternalMedAuthOrProviderName(prescription: MedicationPrescriptionListResponse): string {
		if (prescription.authorizedBy) {
			const authorizedByName = this.personNamePipe.transform(prescription.authorizedBy);
			if (EnumUtil.equals(prescription.type, MedicationPrescriptionType.INTERNAL)
				&& EnumUtil.equals(prescription.authorizationType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER)) {
				// The medication is "internal" (not an eRx prescription managed by RxNT), but it has external authorization
				return `[Ext] ${authorizedByName}`;
			} else {
				return authorizedByName;
			}
		} else {
			return '';
		}
	}

	/**
	 * Figures out what should be displayed in the 'Type' column in the prescriptions list.
	 */
	determineType(prescription: MedicationPrescriptionListResponse): PrescriptionTypeForDisplay {
		if (EnumUtil.equals(prescription.type, MedicationPrescriptionType.GENERAL)) {
			return 'Gen';
		} else if (EnumUtil.equals(prescription.authorizationType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER)) {
			return 'Ext';
		} else if (EnumUtil.equals(prescription.type, MedicationPrescriptionType.EXTERNAL)) {
			return 'eRx';
		} else {
			return 'Int';
		}
	}

	/**
	 * Figures out what should be displayed in the 'Status' column in the prescriptions list.
	 */
	determineStatus(prescription: MedicationPrescriptionListResponse, isExpired: boolean): PrescriptionCurrentStatus {
		if (isExpired && !EnumUtil.equals(prescription.status, PrescriptionStatus.STOPPED)) {
			return PrescriptionCurrentStatus.EXPIRED;
		} else if (EnumUtil.equals(prescription.status, PrescriptionStatus.ACTIVE)) {
			if (EnumUtil.equals(prescription.authorizationType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER)
				|| EnumUtil.equals(prescription.type, MedicationPrescriptionType.GENERAL)) {
				return PrescriptionCurrentStatus.ACTIVE;
			} else {
				return PrescriptionCurrentStatus.PENDING;
			}
		} else if (EnumUtil.equals(prescription.status, PrescriptionStatus.AUTHORIZED)) {
			return PrescriptionCurrentStatus.ACTIVE;
		} else {
			return PrescriptionCurrentStatus.DISCONTINUED;
		}
	}

	/**
	 * Used for filtering on status for the prescriptions list.
	 */
	isActive(prescription: MedicationPrescriptionListResponse, isExpired: boolean): boolean {
		if (EnumUtil.equals(prescription.status, PrescriptionStatus.STOPPED)) {
			return false;
		} else {
			return EnumUtil.equals(prescription.type, MedicationPrescriptionType.EXTERNAL) || !isExpired;
		}
	}

	canAuthorizePrescription(
		type: MedicationPrescriptionType,
		status: PrescriptionStatus,
		authorizationType: PrescriptionAuthorizationType,
	): boolean {
		// Exclude general and eRx medications
		if (!EnumUtil.equals(type, MedicationPrescriptionType.INTERNAL)) {
			return false;
		}

		if (!EnumUtil.equals(status, PrescriptionStatus.ACTIVE)) {
			return false;
		}

		if (!EnumUtil.equals(authorizationType, PrescriptionAuthorizationType.PROVIDER)) {
			return false;
		}

		// Only providers can authorize a medication prescription
		if (!this.securityManagerService.userIsProvider()) {
			return false;
		}

		return true;
	}

	alwaysSignWhenAuthorizing(): boolean {
		const isSignatureFlagOn =  this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.EMPLOYEES.EMPLOYEE_PREFERENCES);
		const providerAlwaysUseSignatureImage = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE.value,
			PreferenceDefaults.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE.value,
		);
		const providerMedicationUseSignatureImage = this.securityManagerService.preferenceValueIsOn(
			PreferenceName.PROVIDER_MEDICATION_USE_SIGNATURE_IMAGE.value,
			PreferenceDefaults.PROVIDER_MEDICATION_USE_SIGNATURE_IMAGE.value,
		);

		return isSignatureFlagOn ? providerMedicationUseSignatureImage : providerAlwaysUseSignatureImage;
	}

	/* istanbul ignore next: gandalf */
	authorizePrescription(request: AuthorizeMedicationPrescriptionRequest): Observable<MedicationPrescriptionResponse> {
		return this.medicationPrescriptionGandalfService.authorizeMedicationPrescription(request);
	}

	canPrintPrescription(
		medicationPrescriptionType: MedicationPrescriptionType,
		status: PrescriptionStatus,
		authorizationType: PrescriptionAuthorizationType,
	): boolean {
		if (!EnumUtil.equals(medicationPrescriptionType, MedicationPrescriptionType.INTERNAL)) {
			return false;
		}

		return EnumUtil.equals(status, PrescriptionStatus.AUTHORIZED) && !EnumUtil.equals(authorizationType, PrescriptionAuthorizationType.EXTERNAL_PROVIDER);
	}

	print(prescriptionId: number) {
		const params: any = {
			rxid: prescriptionId,
			locationid: this.userLocationsService.getCurrentUserLocation().id,
		};
		this.urlService.openTabWithPost(URL_PRINT_ENDPOINTS.PRESCRIPTION_MED_PRINT, params);
	}

	buildLegacyStopRequest(serviceName: string, endpointName: string, stopPrescriptionRequest: StopPrescriptionRequest) {
		const legacyRequest = this.gandalfTheGreyService.createRequestInstance(serviceName, endpointName);
		legacyRequest.prescriptionId = stopPrescriptionRequest.prescriptionId;
		legacyRequest.reasonId = stopPrescriptionRequest.reasonId;
		legacyRequest.stopDate = GandalfTheGreyService.createLegacyRequestDate(stopPrescriptionRequest.stopDate);
		legacyRequest.comments = stopPrescriptionRequest.comments;
		return legacyRequest;
	}

	/**
	 * Stops a medication prescription for a patient with the server checking RxNT.  Does not return any data.
	 */
	stopMedicationPrescription(stopPrescriptionRequest: StopPrescriptionRequest) {
		const serviceName = 'Person';
		const endpointName = 'stopMedPrescription';
		const legacyRequest = this.buildLegacyStopRequest(serviceName, endpointName, stopPrescriptionRequest);
		return this.gandalfTheGreyService.execute(serviceName, endpointName, legacyRequest);
	}

	getMedicationPrescriptionType(id: number) {
		return this.medicationPrescriptionGandalfService.getMedicationPrescriptionType(id);
	}

	findIntakeMedicationsByPatientId(patientId: number) {
		return this.medicationPrescriptionGandalfService.findMostRecentIntakeMedicationByPatientId(patientId);
	}

	acceptIntakeMedications(request: UpdateIntakeMedicationRequest) {
		return this.medicationPrescriptionGandalfService.acceptIntakeMedications(request)
			.pipe(tap(() => this.markIntakeMedicationAccepted()));
	}

	declineIntakeMedications(request: UpdateIntakeMedicationRequest) {
		return this.medicationPrescriptionGandalfService.declineIntakeMedications(request);
	}

	formatIntakeMedicationResponse(
		intakeMedication: IntakeMedicationResponse,
		reviewHistory: MedicationReviewHistoryResponse,
	) {
		const comment = intakeMedication.comments;

		// Add intakeMedication items to the table data is being filtered from the server by its status;
		const medications: IntakeMedicationList[] = intakeMedication.intakeMedications.map(item => ({
			id: item.intakeMedicationId,
			type: IntakeMedication.MEDICATION,
			question: 'Medication/Drug Name',
			answer: item.description || item.drugName,
			status: item.status,
			prescriptionId: item.prescriptionId,
			answerFlag: false,
			isDisableCheckbox: this.isDisableCheckbox(item.type, item.status),
		}));

		// Add noKnownMedication checkbox value to the table if the checkbox is not being
		// accepted/declined and answer is different than existing record
		const reviewHistoryNoKnownMedications = reviewHistory ? reviewHistory.noKnownMedications : false;
		if (this.shouldAddNoKnownMedications(
			intakeMedication.noKnownMedications,
			intakeMedication.noKnownMedicationsReconciledStatus,
			reviewHistoryNoKnownMedications,
		)) {
			medications.push({
				id: null,
				type: IntakeMedication.CHECKBOX,
				question: 'No Known Medications',
				answer: this.yesNoPipe.transform(intakeMedication.noKnownMedications),
				status: IntakeStatus.NEW,
				prescriptionId: null,
				answerFlag: intakeMedication.noKnownMedications,
				isDisableCheckbox: false,
			});
		}

		// Add medication comment to table if the value is not being accepted/declined
		if (!_isNil(comment) && comment !== ''
			&& (EnumUtil.equals(intakeMedication.medicationCommentsReconciledStatus, IntakeReconciledStatus.PENDING)
				|| _isNil(intakeMedication.medicationCommentsReconciledStatus))
		) {
			medications.push({
				id: null,
				type: IntakeMedication.COMMENT,
				question: 'Comments',
				answer: comment,
				status: IntakeStatus.NEW,
				prescriptionId: null,
				answerFlag: false,
				isDisableCheckbox: false,
			});
		}

		return medications;
	}

	get intakeMedicationAccepted() {
		return this._intakeMedicationAccepted.asObservable();
	}

	markIntakeMedicationAccepted() {
		this._intakeMedicationAccepted.next(true);
	}

	shouldAddNoKnownMedications(
		noKnownMedications: boolean,
		noKnownMedicationsReconciledStatus: IntakeReconciledStatus,
		reviewHistoryNoKnownMedications: boolean,
	): boolean {
		return !_isNil(noKnownMedications)
			&& (EnumUtil.equals(noKnownMedicationsReconciledStatus, IntakeReconciledStatus.PENDING)
				|| _isNil(noKnownMedicationsReconciledStatus))
			&& reviewHistoryNoKnownMedications !== noKnownMedications;
	}

	isDisableCheckbox(
		medicationPrescriptionType: MedicationPrescriptionType,
		status: IntakeStatus,
	) {
		return EnumUtil.equals(medicationPrescriptionType, MedicationPrescriptionType.EXTERNAL)
			&& !this.securityManagerService.userIsProvider()
			&&  EnumUtil.equals(status, IntakeStatus.DISCONTINUED);
	}
}
