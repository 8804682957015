<h4 class="section-title bg-default mrgn-btm">Dates</h4>
<div *ngIf="eyeglassPrescription; else noData">
	<div class="form-group" [attr.data-test-id]="'startDateFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">Start Date</label>
		<div class="col-sm-7">
			<p class="margin-0">{{ eyeglassPrescription.startDate | date:dateFormat}}</p>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'endDateFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">End Date</label>
		<div class="col-sm-7">
			<p class="margin-0">{{ eyeglassPrescription.stopDate | date:dateFormat}}</p>
		</div>
	</div>
	<div class="form-group" [attr.data-test-id]="'expirationDateFormGroup'">
		<label class="col-sm-5 control-label padding-x-0">Expiration Date</label>
		<div class="col-sm-7">
			<p class="margin-0">{{ eyeglassPrescription.expirationDate | date:dateFormat}}</p>
		</div>
	</div>
</div>
<ng-template #noData>No Data</ng-template>
