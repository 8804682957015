// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../../../../../../pms/client/src/generated/gandalf/constants';


/** See com.rev360.legacy.api.controller.accounting.UpdateInvoiceItemDetailsRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateInvoiceItemDetailsRequest extends GandalfModelBase {

	@GandalfArray(Number)
	additionalDiagnoses: number[];

	@GandalfProperty()
	additionalModifierId0: number;

	@GandalfProperty()
	additionalModifierId1: number;

	@GandalfProperty()
	additionalModifierId2: number;

	@GandalfProperty()
	additionalModifierId3: number;

	@GandalfValidator({ sizeString: { message: 'Claim Note cannot be longer than 80 characters', minLength: 0, maxLength: 80 } })
	@GandalfProperty()
	claimNote: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	claimNoteCode: constants.ClaimNoteCode;

	@GandalfValidator({ sizeString: { message: 'Code cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	code: string;

	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	description: string;

	@GandalfValidator({ sizeString: { message: 'Dosage cannot be longer than 17 characters', minLength: 0, maxLength: 17 } })
	@GandalfProperty()
	dosageAmount: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	dosageUnit: constants.DosageUnitCode;

	@GandalfValidator({ sizeString: { message: 'Emerg. Svc. cannot be longer than 2 characters', minLength: 0, maxLength: 2 } })
	@GandalfProperty()
	emergencyService: string;

	@GandalfValidator({ sizeString: { message: 'EPSDT cannot be longer than 2 characters', minLength: 0, maxLength: 2 } })
	@GandalfProperty()
	epsdt: string;

	@GandalfProperty()
	externalProviderId: number;

	@GandalfConstantDecorator()
	@GandalfProperty()
	facilityType: constants.FacilityType;

	@GandalfValidator({ notNull: { message: 'Invoice Item is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceItemId: number;

	@GandalfArray(Number)
	itemAdjustmentIdsToRemove: number[];

	@GandalfValidator({ sizeString: { message: 'NDC cannot be longer than 13 characters', minLength: 0, maxLength: 13 } })
	@GandalfProperty()
	nationalDrugCode: string;

	@GandalfValidator({ notNull: { message: 'Posted On is required' } })
	@GandalfProperty({ isRequired: true })
	postedOn: Date;

	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfProperty({ isRequired: true })
	quantity: number;

	@GandalfProperty()
	responsiblePersonId: number;

	@GandalfValidator({ sizeString: { message: 'Rx # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	rxNumber: string;

	@GandalfConstantDecorator()
	@GandalfProperty()
	rxQualifier: constants.RxQualifier;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceEndDate: Date;

	@GandalfProperty()
	serviceLocationId: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceStartDate: Date;

}
