import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { GrantCreditModalComponent } from '@accounting/invoices/grant-credit-modal/grant-credit-modal.component';
import { PatientPortionModalComponent } from '@accounting/invoices/patient-portion-modal/patient-portion-modal.component';
import { TransferCollectionsInvoiceModalComponent } from '@accounting/invoices/transfer-collections-invoice-modal/transfer-collections-invoice-modal.component';
import { TransferItemsModalComponent } from '@accounting/invoices/transfer-items-modal/transfer-items-modal.component';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DialogUtil, EnumUtil, ModalManagerService, TypeSafeModalManagerService } from 'morgana';
import { _isNil } from '@core/lodash/lodash';
import { UrlService } from '@core/url-util/url.service';
import { ApproveInvoiceRequest } from '@gandalf/model/approve-invoice-request';
import { InvoiceResponse } from '@gandalf/model/invoice-response';
import { PayerType } from '@gandalf/constants';
import { URL_ACCOUNTING_ENDPOINTS } from '@shared/constants/url.constants';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { AccountingViewService } from '../../../core/accounting/accounting-view-util/accounting-view.service';
import { AccountingService } from '../../../core/accounting/accounting.service';
import { AddDiscountModalComponent } from '../../add-discount-modal/add-discount-modal.component';
import { AdhocItemModalComponent } from '../../adhoc-item-modal/adhoc-item-modal.component';
import { AssignEmployeesModalComponent } from '../../assign-employees-modal/assign-employees-modal.component';
import { DiagnosesModalComponent } from '../../diagnoses-modal/diagnoses-modal.component';
import { DiscountItemsModalComponent } from '../../discount-items-modal/discount-items-modal.component';
import { TransferInvoiceModalComponent } from '../../transfer-invoice-modal/transfer-invoice-modal.component';

@Component({
	selector: 'pms-invoice-details-button-bar',
	templateUrl: './invoice-details-button-bar.component.html',
	styles: [],
	providers: [ModalManagerService, TypeSafeModalManagerService],
})
export class InvoiceDetailsButtonBarComponent implements OnInit, OnChanges {

	@Input()
	invoice: InvoiceResponse;

	@Input()
	isReadOnly: boolean;

	@Input()
	isPaymentProcessing: boolean;

	ELLIPSIS_ITEM_IDS = {
		ASSIGN_EMPLOYEES: 'AssignEmployees',
		ADD_DISCOUNT: 'AddDiscount',
		ADD_ADHOC: 'AddAdhoc',
		TRANSFER: 'Transfer',
		GRANT_CREDIT: 'GrantCredit',
	};

	ellipsisItemList = [
		{
			text: 'Employees',
			id: this.ELLIPSIS_ITEM_IDS.ASSIGN_EMPLOYEES,
		},
	];

	approvedStatusSelectOptions = [
		{label: 'Pending', value: false, styleClass: 'e-warning'},
		{label: 'Authorized', value: true, styleClass: 'e-success'},
	];

	confirmDialog: Dialog;
	confirmDialogCancel = true;

	constructor(
		public modalManagerService: ModalManagerService,
		public typeSafeModalManagerService: TypeSafeModalManagerService,
		private urlService: UrlService,
		private accountingService: AccountingService,
		private accountingViewService: AccountingViewService,
		private invoiceService: InvoiceService,
	) {
	}

	ngOnInit() {
		this.updateEllipsisList();
	}

	ngOnChanges(_changes: SimpleChanges): void {
		this.updateEllipsisList();
	}

	updateEllipsisList() {
		this.ellipsisItemList = [];

		if (!this.isReadOnly && this.accountingViewService.canAddAdhoc(this.invoice)) {
			this.ellipsisItemList.push({
				text: 'Add Adhoc',
				id: this.ELLIPSIS_ITEM_IDS.ADD_ADHOC,
			});
		}

		if (!this.isReadOnly && this.accountingViewService.canDiscount(this.invoice)) {
			this.ellipsisItemList.push({
				text: 'Add Discount',
				id: this.ELLIPSIS_ITEM_IDS.ADD_DISCOUNT,
			});
		}

		// Add Employees button
		this.ellipsisItemList.push({
			text: 'Employees',
			id: this.ELLIPSIS_ITEM_IDS.ASSIGN_EMPLOYEES,
		});

		if (!this.isReadOnly && this.accountingViewService.canTransfer(this.invoice)) {
			this.ellipsisItemList.push({
				text: 'Transfer',
				id: this.ELLIPSIS_ITEM_IDS.TRANSFER,
			});
		}

		if (!this.isReadOnly && this.accountingViewService.canGrantCredit(this.invoice)) {
			this.ellipsisItemList.push({
				text: 'Grant Credit',
				id: this.ELLIPSIS_ITEM_IDS.GRANT_CREDIT,
			});
		}

	}

	openDiagnosesModal() {
		this.openModalWithInvoiceId(DiagnosesModalComponent);
	}

	openModalWithInvoiceId(modalComponent) {
		this.modalManagerService.open(modalComponent, {data: {invoiceId: this.invoice.id, openedFromInvoiceDetailsModal: true}}).onClose.subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
		});
	}

	onEllipsisItemSelect(ellipsisItemId: string) {
		switch (ellipsisItemId) {
			case this.ELLIPSIS_ITEM_IDS.ASSIGN_EMPLOYEES:
				this.openModalWithInvoiceId(AssignEmployeesModalComponent);
				break;
			case this.ELLIPSIS_ITEM_IDS.ADD_DISCOUNT:
				this.openModalWithInvoiceId(AddDiscountModalComponent);
				break;
			case this.ELLIPSIS_ITEM_IDS.ADD_ADHOC: {
				const payload = {
					data: {
						invoiceId: this.invoice.id,
						locationId: this.invoice.locationId,
					},
				};
				this.modalManagerService.open(AdhocItemModalComponent, payload).onClose.subscribe(() => {
					this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
				});
				break;
			}
			case this.ELLIPSIS_ITEM_IDS.GRANT_CREDIT:
				this.openModalWithInvoiceId(GrantCreditModalComponent);
				break;
			case this.ELLIPSIS_ITEM_IDS.TRANSFER:
				if (EnumUtil.equals(this.invoice.payerType, PayerType.COLLECTIONS)) {
					this.openModalWithInvoiceId(TransferCollectionsInvoiceModalComponent);
				} else {
					this.openModalWithInvoiceId(TransferInvoiceModalComponent);
				}
				break;
		}
	}

	isInvoicePayerTypeInsurance(): boolean {
		return this.invoice.payerType === PayerType.INSURANCE;
	}

	canPrintClaim() {
		return this.accountingViewService.canPrintClaim(this.invoice);
	}

	openPreviewClaim() {
		const params = {invoiceId: this.invoice.id};
		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.PREVIEW_CLAIM, params);
	}

	openViewDataFile() {
		const params = {
			invoiceId: this.invoice.id,
			fileName: `invoiceClaim${this.invoice.id}.txt`,
			fileExt: 'txt',
		};

		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.VIEW_DATA_FILE, params);
	}

	openPrintClaim() {
		const params = {invoiceId: this.invoice.id};
		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.PRINT_CLAIM, params);
	}

	openPrintInvoice() {
		const params = {invoiceId: this.invoice.id};
		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.PRINT_INVOICE, params);
	}

	updateApproved(event: any) {
		const invoiceId = this.invoice.id;
		if (event.value) {
			if (this.isFeeScheduleAssignable()) {
				this.confirmDialog = DialogUtil.confirm({
					title: 'Assign Fee Schedule',
					content: 'Would you like to assign a fee schedule before approving invoice?',
					okButton: {
						click: this.approveInvoiceCloseModal.bind(this, {invoiceId, assignFeeSchedule: true} as ApproveInvoiceRequest),
					},
					cancelButton: {
						click: this.approveInvoiceCloseModal.bind(this, {invoiceId, assignFeeSchedule: false} as ApproveInvoiceRequest),
					},
					close: this.onCloseConfirmDialog.bind(this),
				});
			} else {
				this.accountingService.approveInvoice({invoiceId, assignFeeSchedule: false} as ApproveInvoiceRequest).subscribe((_response) => {
					this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
				});
			}
		} else {
			this.accountingService.unapproveInvoice(this.invoice.id).subscribe((_response) => {
				this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
			});
		}
	}

	isFeeScheduleAssignable() {
		return EnumUtil.equals(this.invoice.payerType, PayerType.INSURANCE)
			&& !_isNil(this.invoice.payerPolicyFeeScheduleId)
			&& _isNil(this.invoice.feeScheduleId);
	}

	approveInvoiceCloseModal(request: ApproveInvoiceRequest) {
		this.confirmDialogCancel = false;
		this.confirmDialog.close();
		this.accountingService.approveInvoice(request).subscribe((_response) => {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
		});
	}

	/**
	 * If the confirm dialog is closed without choosing to press ok or cancel, then approved needs to be set to false,
	 * since this confirm dialog only happens when the user is attempting to set the value to true. This cancels the update.
	 */
	onCloseConfirmDialog() {
		if (this.confirmDialogCancel) {
			this.invoice.approved = false;
		}
		this.confirmDialogCancel = true;
	}

	discountItems() {
		this.openModalWithInvoiceId(DiscountItemsModalComponent);
	}

	canDiscountItems() {
		return !this.isReadOnly && this.accountingViewService.canDiscountItems(this.invoice);
	}

	transferItems() {
		this.openModalWithInvoiceId(TransferItemsModalComponent);
	}

	canTransferItems() {
		return !this.isReadOnly && this.accountingViewService.canTransferItems(this.invoice);
	}

	openReceivePaymentModal() {
		this.invoiceService.openReceivePaymentModal(this.typeSafeModalManagerService, this.invoice);
	}

	canReceivePayment() {
		return !this.isReadOnly && this.accountingViewService.canReceivePayment(this.invoice);
	}

	canAddPatientPortion() {
		return !this.isReadOnly && this.accountingViewService.canAddPatientPortion(this.invoice);
	}

	addPatientPortion() {
		const payload = {
			data: {
				invoiceId: this.invoice.id,
				personInsuranceId: this.invoice.personInsuranceId,
				patientId: this.invoice.patientId,
				encounterId: this.invoice.encounterId,
				payerEntityId: this.invoice.payerEntityId,
				currentInvoiceTotal: this.invoice.balance,
				allowTransferTax: this.accountingViewService.canTransferTax(this.invoice),
				transferTaxInvoiceId: this.invoice.transferTaxInvoiceId,
				transferTaxItemCount: this.accountingViewService.getTaxableItemCount(this.invoice),
			},
		};
		this.modalManagerService.open(PatientPortionModalComponent, payload).onClose.subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
		});
	}

	addFeeSchedule() {
		this.accountingService.assignInvoiceFeeSchedule(this.invoice.id).subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
		});
	}

	canAddFeeSchedule() {
		return !this.isReadOnly && this.accountingViewService.canAddFeeSchedule(this.invoice);
	}

	removeFeeSchedule() {
		this.accountingService.removeInvoiceFeeSchedule(this.invoice.id).subscribe(() => {
			this.invoiceService.refreshInvoiceDetailsInvoice(this.invoice.id);
		});
	}

	canRemoveFeeSchedule() {
		return !this.isReadOnly && this.accountingViewService.canRemoveFeeSchedule(this.invoice);
	}

	canPrintInvoice() {
		return this.invoice.approved || EnumUtil.equals(this.invoice.payerType, PayerType.INSURANCE);
	}

	canChangeAuthorizedStatus() {
		return !this.isReadOnly && !this.isPaymentProcessing && this.accountingViewService.canChangeAuthorizedStatus(this.invoice);
	}
}
