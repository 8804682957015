<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-md"
		[attr.data-test-id]="'voidPaymentModal'">
	<ng-template #header>
		<div class="dlg-template">Void Payment</div>
	</ng-template>
	<ng-template #content>
		<p>Are you sure you want to void this payment?</p>
		<form [formGroup]="componentForm"
			  (ngSubmit)="voidPayment()"
			  #templateForm="ngForm">
			<div class="row">
				<div class="col-sm-12">
					<gandalf-lib-validation-messages
							*ngIf="componentForm.invalid && templateForm.submitted"
							[form]="componentForm"
							[requestObj]="voidPaymentRequest"
							[attr.data-test-id]="'voidPaymentModalValidationMessage'">
					</gandalf-lib-validation-messages>
				</div>
			</div>
			<div class="form-group">
				<label class="required">Reason</label>
				<ejs-textbox class="h-rows-5" [multiline]="true" revFocusInput maxlength="255" formControlName="comments"></ejs-textbox>
			</div>
			<div>
				{{ componentForm.controls['comments'].value.length }} of 255 characters
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button
				ejs-button
				type="button"
				(click)="closeModal()"
				[attr.data-test-id]="'voidPaymentModalNoButton'">No
		</button>
		<button
				ejs-button
				type="submit"
				[isPrimary]="true"
				(click)="submitForm($event)"
				[attr.data-test-id]="'voidPaymentModalYesButton'">Yes
		</button>
	</ng-template>
</ejs-dialog>
